import { IField } from './field';

export class ICustomField extends IField {
    _id?: string;
    company?: string;
    table: string;

    constructor(options: any = {}) {
        super(options);
        this.table = options.table || "";

    }

    get translationLabel() {
        return `CUSTOM_FIELDS.${this.table}.${this.name}`
    }


}
