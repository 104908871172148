export const timezone = {

    "Pacific/Midway": "Pacific/Midway (UTC -11:00)",
    "Pacific/Niue": "Pacific/Niue (UTC -11:00)",
    "Pacific/Pago_Pago": "Pacific/Pago Pago (UTC -11:00)",
    "Pacific/Honolulu": "Pacific/Honolulu (UTC -10:00)",
    "Pacific/Rarotonga": "Pacific/Rarotonga (UTC -10:00)",
    "Pacific/Tahiti": "Pacific/Tahiti (UTC -10:00)",
    "Pacific/Marquesas": "Pacific/Marquesas (UTC -09:30)",
    "America/Adak": "America/Adak (UTC -09:00)",
    "Pacific/Gambier": "Pacific/Gambier (UTC -09:00)",
    "America/Juneau": "America/Juneau (UTC -08:00)",
    "America/Metlakatla": "America/Metlakatla (UTC -08:00)",
    "America/Nome": "America/Nome (UTC -08:00)",
    "America/Sitka": "America/Sitka (UTC -08:00)",
    "America/Yakutat": "America/Yakutat (UTC -08:00)",
    "Pacific/Pitcairn": "Pacific/Pitcairn (UTC -08:00)",
    "America/Anchorage": "America/Anchorage (UTC -08:00)",
    "America/Creston": "America/Creston (UTC -07:00)",
    "America/Dawson": "America/Dawson (UTC -07:00)",
    "America/Dawson_Creek": "America/Dawson Creek (UTC -07:00)",
    "America/Fort_Nelson": "America/Fort Nelson (UTC -07:00)",
    "America/Hermosillo": "America/Hermosillo (UTC -07:00)",
    "America/Los_Angeles": "America/Los Angeles (UTC -07:00)",
    "America/Phoenix": "America/Phoenix (UTC -07:00)",
    "America/Tijuana": "America/Tijuana (UTC -07:00)",
    "America/Vancouver": "America/Vancouver (UTC -07:00)",
    "America/Whitehorse": "America/Whitehorse (UTC -07:00)",
    "America/Belize": "America/Belize (UTC -06:00)",
    "America/Boise": "America/Boise (UTC -06:00)",
    "America/Cambridge_Bay": "America/Cambridge Bay (UTC -06:00)",
    "America/Chihuahua": "America/Chihuahua (UTC -06:00)",
    "America/Costa_Rica": "America/Costa Rica (UTC -06:00)",
    "America/Denver": "America/Denver (UTC -06:00)",
    "America/Edmonton": "America/Edmonton (UTC -06:00)",
    "America/El_Salvador": "America/El Salvador (UTC -06:00)",
    "America/Guatemala": "America/Guatemala (UTC -06:00)",
    "America/Inuvik": "America/Inuvik (UTC -06:00)",
    "America/Managua": "America/Managua (UTC -06:00)",
    "America/Mazatlan": "America/Mazatlan (UTC -06:00)",
    "America/Ojinaga": "America/Ojinaga (UTC -06:00)",
    "America/Regina": "America/Regina (UTC -06:00)",
    "America/Swift_Current": "America/Swift Current (UTC -06:00)",
    "America/Tegucigalpa": "America/Tegucigalpa (UTC -06:00)",
    "America/Yellowknife": "America/Yellowknife (UTC -06:00)",
    "Pacific/Easter": "Pacific/Easter (UTC -06:00)",
    "Pacific/Galapagos": "Pacific/Galapagos (UTC -06:00)",
    "America/Atikokan": "America/Atikokan (UTC -05:00)",
    "America/Bahia_Banderas": "America/Bahia Banderas (UTC -05:00)",
    "America/Bogota": "America/Bogota (UTC -05:00)",
    "America/Cancun": "America/Cancun (UTC -05:00)",
    "America/Cayman": "America/Cayman (UTC -05:00)",
    "America/Chicago": "America/Chicago (UTC -05:00)",
    "America/Eirunepe": "America/Eirunepe (UTC -05:00)",
    "America/Guayaquil": "America/Guayaquil (UTC -05:00)",
    "America/Indiana/Knox": "America/Indiana/Knox (UTC -05:00)",
    "America/Indiana/Tell_City": "America/Indiana/Tell City (UTC -05:00)",
    "America/Jamaica": "America/Jamaica (UTC -05:00)",
    "America/Lima": "America/Lima (UTC -05:00)",
    "America/Matamoros": "America/Matamoros (UTC -05:00)",
    "America/Menominee": "America/Menominee (UTC -05:00)",
    "America/Merida": "America/Merida (UTC -05:00)",
    "America/Mexico_City": "America/Mexico City (UTC -05:00)",
    "America/Monterrey": "America/Monterrey (UTC -05:00)",
    "America/North_Dakota/Beulah": "America/North Dakota/Beulah (UTC -05:00)",
    "America/North_Dakota/Center": "America/North Dakota/Center (UTC -05:00)",
    "America/North_Dakota/New_Salem": "America/North Dakota/New Salem (UTC -05:00)",
    "America/Panama": "America/Panama (UTC -05:00)",
    "America/Rainy_River": "America/Rainy River (UTC -05:00)",
    "America/Rankin_Inlet": "America/Rankin Inlet (UTC -05:00)",
    "America/Resolute": "America/Resolute (UTC -05:00)",
    "America/Rio_Branco": "America/Rio Branco (UTC -05:00)",
    "America/Winnipeg": "America/Winnipeg (UTC -05:00)",
    "America/Anguilla": "America/Anguilla (UTC -04:00)",
    "America/Antigua": "America/Antigua (UTC -04:00)",
    "America/Aruba": "America/Aruba (UTC -04:00)",
    "America/Asuncion": "America/Asuncion (UTC -04:00)",
    "America/Barbados": "America/Barbados (UTC -04:00)",
    "America/Blanc-Sablon": "America/Blanc-Sablon (UTC -04:00)",
    "America/Boa_Vista": "America/Boa Vista (UTC -04:00)",
    "America/Campo_Grande": "America/Campo Grande (UTC -04:00)",
    "America/Caracas": "America/Caracas (UTC -04:00)",
    "America/Cuiaba": "America/Cuiaba (UTC -04:00)",
    "America/Curacao": "America/Curacao (UTC -04:00)",
    "America/Detroit": "America/Detroit (UTC -04:00)",
    "America/Dominica": "America/Dominica (UTC -04:00)",
    "America/Grand_Turk": "America/Grand Turk (UTC -04:00)",
    "America/Grenada": "America/Grenada (UTC -04:00)",
    "America/Guadeloupe": "America/Guadeloupe (UTC -04:00)",
    "America/Guyana": "America/Guyana (UTC -04:00)",
    "America/Havana": "America/Havana (UTC -04:00)",
    "America/Indiana/Indianapolis": "America/Indiana/Indianapolis (UTC -04:00)",
    "America/Indiana/Marengo": "America/Indiana/Marengo (UTC -04:00)",
    "America/Indiana/Petersburg": "America/Indiana/Petersburg (UTC -04:00)",
    "America/Indiana/Vevay": "America/Indiana/Vevay (UTC -04:00)",
    "America/Indiana/Vincennes": "America/Indiana/Vincennes (UTC -04:00)",
    "America/Indiana/Winamac": "America/Indiana/Winamac (UTC -04:00)",
    "America/Iqaluit": "America/Iqaluit (UTC -04:00)",
    "America/Kentucky/Louisville": "America/Kentucky/Louisville (UTC -04:00)",
    "America/Kentucky/Monticello": "America/Kentucky/Monticello (UTC -04:00)",
    "America/Kralendijk": "America/Kralendijk (UTC -04:00)",
    "America/La_Paz": "America/La Paz (UTC -04:00)",
    "America/Lower_Princes": "America/Lower Princes (UTC -04:00)",
    "America/Manaus": "America/Manaus (UTC -04:00)",
    "America/Marigot": "America/Marigot (UTC -04:00)",
    "America/Martinique": "America/Martinique (UTC -04:00)",
    "America/Montserrat": "America/Montserrat (UTC -04:00)",
    "America/Nassau": "America/Nassau (UTC -04:00)",
    "America/New_York": "America/New York (UTC -04:00)",
    "America/Nipigon": "America/Nipigon (UTC -04:00)",
    "America/Pangnirtung": "America/Pangnirtung (UTC -04:00)",
    "America/Port-au-Prince": "America/Port-au-Prince (UTC -04:00)",
    "America/Port_of_Spain": "America/Port of Spain (UTC -04:00)",
    "America/Porto_Velho": "America/Porto Velho (UTC -04:00)",
    "America/Puerto_Rico": "America/Puerto Rico (UTC -04:00)",
    "America/Santiago": "America/Santiago (UTC -04:00)",
    "America/Santo_Domingo": "America/Santo Domingo (UTC -04:00)",
    "America/St_Barthelemy": "America/St Barthelemy (UTC -04:00)",
    "America/St_Kitts": "America/St Kitts (UTC -04:00)",
    "America/St_Lucia": "America/St Lucia (UTC -04:00)",
    "America/St_Thomas": "America/St Thomas (UTC -04:00)",
    "America/St_Vincent": "America/St Vincent (UTC -04:00)",
    "America/Thunder_Bay": "America/Thunder Bay (UTC -04:00)",
    "America/Toronto": "America/Toronto (UTC -04:00)",
    "America/Tortola": "America/Tortola (UTC -04:00)",
    "America/Araguaina": "America/Araguaina (UTC -03:00)",
    "America/Argentina/Buenos_Aires": "America/Argentina/Buenos Aires (UTC -03:00)",
    "America/Argentina/Catamarca": "America/Argentina/Catamarca (UTC -03:00)",
    "America/Argentina/Cordoba": "America/Argentina/Cordoba (UTC -03:00)",
    "America/Argentina/Jujuy": "America/Argentina/Jujuy (UTC -03:00)",
    "America/Argentina/La_Rioja": "America/Argentina/La Rioja (UTC -03:00)",
    "America/Argentina/Mendoza": "America/Argentina/Mendoza (UTC -03:00)",
    "America/Argentina/Rio_Gallegos": "America/Argentina/Rio Gallegos (UTC -03:00)",
    "America/Argentina/Salta": "America/Argentina/Salta (UTC -03:00)",
    "America/Argentina/San_Juan": "America/Argentina/San Juan (UTC -03:00)",
    "America/Argentina/San_Luis": "America/Argentina/San Luis (UTC -03:00)",
    "America/Argentina/Tucuman": "America/Argentina/Tucuman (UTC -03:00)",
    "America/Argentina/Ushuaia": "America/Argentina/Ushuaia (UTC -03:00)",
    "America/Bahia": "America/Bahia (UTC -03:00)",
    "America/Belem": "America/Belem (UTC -03:00)",
    "America/Cayenne": "America/Cayenne (UTC -03:00)",
    "America/Fortaleza": "America/Fortaleza (UTC -03:00)",
    "America/Glace_Bay": "America/Glace Bay (UTC -03:00)",
    "America/Goose_Bay": "America/Goose Bay (UTC -03:00)",
    "America/Halifax": "America/Halifax (UTC -03:00)",
    "America/Maceio": "America/Maceio (UTC -03:00)",
    "America/Moncton": "America/Moncton (UTC -03:00)",
    "America/Montevideo": "America/Montevideo (UTC -03:00)",
    "America/Paramaribo": "America/Paramaribo (UTC -03:00)",
    "America/Punta_Arenas": "America/Punta Arenas (UTC -03:00)",
    "America/Recife": "America/Recife (UTC -03:00)",
    "America/Santarem": "America/Santarem (UTC -03:00)",
    "America/Sao_Paulo": "America/Sao Paulo (UTC -03:00)",
    "America/Thule": "America/Thule (UTC -03:00)",
    "Antarctica/Palmer": "Antarctica/Palmer (UTC -03:00)",
    "Antarctica/Rothera": "Antarctica/Rothera (UTC -03:00)",
    "Atlantic/Bermuda": "Atlantic/Bermuda (UTC -03:00)",
    "Atlantic/Stanley": "Atlantic/Stanley (UTC -03:00)",
    "America/St_Johns": "America/St Johns (UTC -02:30)",
    "America/Miquelon": "America/Miquelon (UTC -02:00)",
    "America/Noronha": "America/Noronha (UTC -02:00)",
    "America/Nuuk": "America/Nuuk (UTC -02:00)",
    "Atlantic/South_Georgia": "Atlantic/South Georgia (UTC -02:00)",
    "Atlantic/Cape_Verde": "Atlantic/Cape Verde (UTC -01:00)",
    "UTC": "UTC (UTC +00:00)",
    "Africa/Abidjan": "Africa/Abidjan (UTC +00:00)",
    "Africa/Accra": "Africa/Accra (UTC +00:00)",
    "Africa/Bamako": "Africa/Bamako (UTC +00:00)",
    "Africa/Banjul": "Africa/Banjul (UTC +00:00)",
    "Africa/Bissau": "Africa/Bissau (UTC +00:00)",
    "Africa/Conakry": "Africa/Conakry (UTC +00:00)",
    "Africa/Dakar": "Africa/Dakar (UTC +00:00)",
    "Africa/Freetown": "Africa/Freetown (UTC +00:00)",
    "Africa/Lome": "Africa/Lome (UTC +00:00)",
    "Africa/Monrovia": "Africa/Monrovia (UTC +00:00)",
    "Africa/Nouakchott": "Africa/Nouakchott (UTC +00:00)",
    "Africa/Ouagadougou": "Africa/Ouagadougou (UTC +00:00)",
    "Africa/Sao_Tome": "Africa/Sao Tome (UTC +00:00)",
    "America/Danmarkshavn": "America/Danmarkshavn (UTC +00:00)",
    "America/Scoresbysund": "America/Scoresbysund (UTC +00:00)",
    "Atlantic/Azores": "Atlantic/Azores (UTC +00:00)",
    "Atlantic/Reykjavik": "Atlantic/Reykjavik (UTC +00:00)",
    "Atlantic/St_Helena": "Atlantic/St Helena (UTC +00:00)",
    "Africa/Algiers": "Africa/Algiers (UTC +01:00)",
    "Africa/Bangui": "Africa/Bangui (UTC +01:00)",
    "Africa/Brazzaville": "Africa/Brazzaville (UTC +01:00)",
    "Africa/Casablanca": "Africa/Casablanca (UTC +01:00)",
    "Africa/Douala": "Africa/Douala (UTC +01:00)",
    "Africa/El_Aaiun": "Africa/El Aaiun (UTC +01:00)",
    "Africa/Kinshasa": "Africa/Kinshasa (UTC +01:00)",
    "Africa/Lagos": "Africa/Lagos (UTC +01:00)",
    "Africa/Libreville": "Africa/Libreville (UTC +01:00)",
    "Africa/Luanda": "Africa/Luanda (UTC +01:00)",
    "Africa/Malabo": "Africa/Malabo (UTC +01:00)",
    "Africa/Ndjamena": "Africa/Ndjamena (UTC +01:00)",
    "Africa/Niamey": "Africa/Niamey (UTC +01:00)",
    "Africa/Porto-Novo": "Africa/Porto-Novo (UTC +01:00)",
    "Africa/Tunis": "Africa/Tunis (UTC +01:00)",
    "Atlantic/Canary": "Atlantic/Canary (UTC +01:00)",
    "Atlantic/Faroe": "Atlantic/Faroe (UTC +01:00)",
    "Atlantic/Madeira": "Atlantic/Madeira (UTC +01:00)",
    "Europe/Dublin": "Europe/Dublin (UTC +01:00)",
    "Europe/Guernsey": "Europe/Guernsey (UTC +01:00)",
    "Europe/Isle_of_Man": "Europe/Isle of Man (UTC +01:00)",
    "Europe/Jersey": "Europe/Jersey (UTC +01:00)",
    "Europe/Lisbon": "Europe/Lisbon (UTC +01:00)",
    "Europe/London": "Europe/London (UTC +01:00)",
    "Africa/Blantyre": "Africa/Blantyre (UTC +02:00)",
    "Africa/Bujumbura": "Africa/Bujumbura (UTC +02:00)",
    "Africa/Cairo": "Africa/Cairo (UTC +02:00)",
    "Africa/Ceuta": "Africa/Ceuta (UTC +02:00)",
    "Africa/Gaborone": "Africa/Gaborone (UTC +02:00)",
    "Africa/Harare": "Africa/Harare (UTC +02:00)",
    "Africa/Johannesburg": "Africa/Johannesburg (UTC +02:00)",
    "Africa/Juba": "Africa/Juba (UTC +02:00)",
    "Africa/Khartoum": "Africa/Khartoum (UTC +02:00)",
    "Africa/Kigali": "Africa/Kigali (UTC +02:00)",
    "Africa/Lubumbashi": "Africa/Lubumbashi (UTC +02:00)",
    "Africa/Lusaka": "Africa/Lusaka (UTC +02:00)",
    "Africa/Maputo": "Africa/Maputo (UTC +02:00)",
    "Africa/Maseru": "Africa/Maseru (UTC +02:00)",
    "Africa/Mbabane": "Africa/Mbabane (UTC +02:00)",
    "Africa/Tripoli": "Africa/Tripoli (UTC +02:00)",
    "Africa/Windhoek": "Africa/Windhoek (UTC +02:00)",
    "Antarctica/Troll": "Antarctica/Troll (UTC +02:00)",
    "Arctic/Longyearbyen": "Arctic/Longyearbyen (UTC +02:00)",
    "Europe/Amsterdam": "Europe/Amsterdam (UTC +02:00)",
    "Europe/Andorra": "Europe/Andorra (UTC +02:00)",
    "Europe/Belgrade": "Europe/Belgrade (UTC +02:00)",
    "Europe/Berlin": "Europe/Berlin (UTC +02:00)",
    "Europe/Bratislava": "Europe/Bratislava (UTC +02:00)",
    "Europe/Brussels": "Europe/Brussels (UTC +02:00)",
    "Europe/Budapest": "Europe/Budapest (UTC +02:00)",
    "Europe/Busingen": "Europe/Busingen (UTC +02:00)",
    "Europe/Copenhagen": "Europe/Copenhagen (UTC +02:00)",
    "Europe/Gibraltar": "Europe/Gibraltar (UTC +02:00)",
    "Europe/Kaliningrad": "Europe/Kaliningrad (UTC +02:00)",
    "Europe/Ljubljana": "Europe/Ljubljana (UTC +02:00)",
    "Europe/Luxembourg": "Europe/Luxembourg (UTC +02:00)",
    "Europe/Madrid": "Europe/Madrid (UTC +02:00)",
    "Europe/Malta": "Europe/Malta (UTC +02:00)",
    "Europe/Monaco": "Europe/Monaco (UTC +02:00)",
    "Europe/Oslo": "Europe/Oslo (UTC +02:00)",
    "Europe/Paris": "Europe/Paris (UTC +02:00)",
    "Europe/Podgorica": "Europe/Podgorica (UTC +02:00)",
    "Europe/Prague": "Europe/Prague (UTC +02:00)",
    "Europe/Rome": "Europe/Rome (UTC +02:00)",
    "Europe/San_Marino": "Europe/San Marino (UTC +02:00)",
    "Europe/Sarajevo": "Europe/Sarajevo (UTC +02:00)",
    "Europe/Skopje": "Europe/Skopje (UTC +02:00)",
    "Europe/Stockholm": "Europe/Stockholm (UTC +02:00)",
    "Europe/Tirane": "Europe/Tirane (UTC +02:00)",
    "Europe/Vaduz": "Europe/Vaduz (UTC +02:00)",
    "Europe/Vatican": "Europe/Vatican (UTC +02:00)",
    "Europe/Vienna": "Europe/Vienna (UTC +02:00)",
    "Europe/Warsaw": "Europe/Warsaw (UTC +02:00)",
    "Europe/Zagreb": "Europe/Zagreb (UTC +02:00)",
    "Europe/Zurich": "Europe/Zurich (UTC +02:00)",
    "Africa/Addis_Ababa": "Africa/Addis Ababa (UTC +03:00)",
    "Africa/Asmara": "Africa/Asmara (UTC +03:00)",
    "Africa/Dar_es_Salaam": "Africa/Dar es Salaam (UTC +03:00)",
    "Africa/Djibouti": "Africa/Djibouti (UTC +03:00)",
    "Africa/Kampala": "Africa/Kampala (UTC +03:00)",
    "Africa/Mogadishu": "Africa/Mogadishu (UTC +03:00)",
    "Africa/Nairobi": "Africa/Nairobi (UTC +03:00)",
    "Antarctica/Syowa": "Antarctica/Syowa (UTC +03:00)",
    "Asia/Aden": "Asia/Aden (UTC +03:00)",
    "Asia/Amman": "Asia/Amman (UTC +03:00)",
    "Asia/Baghdad": "Asia/Baghdad (UTC +03:00)",
    "Asia/Bahrain": "Asia/Bahrain (UTC +03:00)",
    "Asia/Beirut": "Asia/Beirut (UTC +03:00)",
    "Asia/Damascus": "Asia/Damascus (UTC +03:00)",
    "Asia/Famagusta": "Asia/Famagusta (UTC +03:00)",
    "Asia/Gaza": "Asia/Gaza (UTC +03:00)",
    "Asia/Hebron": "Asia/Hebron (UTC +03:00)",
    "Asia/Jerusalem": "Asia/Jerusalem (UTC +03:00)",
    "Asia/Kuwait": "Asia/Kuwait (UTC +03:00)",
    "Asia/Nicosia": "Asia/Nicosia (UTC +03:00)",
    "Asia/Qatar": "Asia/Qatar (UTC +03:00)",
    "Asia/Riyadh": "Asia/Riyadh (UTC +03:00)",
    "Europe/Athens": "Europe/Athens (UTC +03:00)",
    "Europe/Bucharest": "Europe/Bucharest (UTC +03:00)",
    "Europe/Chisinau": "Europe/Chisinau (UTC +03:00)",
    "Europe/Helsinki": "Europe/Helsinki (UTC +03:00)",
    "Europe/Istanbul": "Europe/Istanbul (UTC +03:00)",
    "Europe/Kiev": "Europe/Kiev (UTC +03:00)",
    "Europe/Kirov": "Europe/Kirov (UTC +03:00)",
    "Europe/Mariehamn": "Europe/Mariehamn (UTC +03:00)",
    "Europe/Minsk": "Europe/Minsk (UTC +03:00)",
    "Europe/Moscow": "Europe/Moscow (UTC +03:00)",
    "Europe/Riga": "Europe/Riga (UTC +03:00)",
    "Europe/Simferopol": "Europe/Simferopol (UTC +03:00)",
    "Europe/Sofia": "Europe/Sofia (UTC +03:00)",
    "Europe/Tallinn": "Europe/Tallinn (UTC +03:00)",
    "Europe/Uzhgorod": "Europe/Uzhgorod (UTC +03:00)",
    "Europe/Vilnius": "Europe/Vilnius (UTC +03:00)",
    "Europe/Volgograd": "Europe/Volgograd (UTC +03:00)",
    "Europe/Zaporozhye": "Europe/Zaporozhye (UTC +03:00)",
    "Indian/Antananarivo": "Indian/Antananarivo (UTC +03:00)",
    "Indian/Comoro": "Indian/Comoro (UTC +03:00)",
    "Indian/Mayotte": "Indian/Mayotte (UTC +03:00)",
    "Asia/Baku": "Asia/Baku (UTC +04:00)",
    "Asia/Dubai": "Asia/Dubai (UTC +04:00)",
    "Asia/Muscat": "Asia/Muscat (UTC +04:00)",
    "Asia/Tbilisi": "Asia/Tbilisi (UTC +04:00)",
    "Asia/Yerevan": "Asia/Yerevan (UTC +04:00)",
    "Europe/Astrakhan": "Europe/Astrakhan (UTC +04:00)",
    "Europe/Samara": "Europe/Samara (UTC +04:00)",
    "Europe/Saratov": "Europe/Saratov (UTC +04:00)",
    "Europe/Ulyanovsk": "Europe/Ulyanovsk (UTC +04:00)",
    "Indian/Mahe": "Indian/Mahe (UTC +04:00)",
    "Indian/Mauritius": "Indian/Mauritius (UTC +04:00)",
    "Indian/Reunion": "Indian/Reunion (UTC +04:00)",
    "Asia/Kabul": "Asia/Kabul (UTC +04:30)",
    "Asia/Tehran": "Asia/Tehran (UTC +04:30)",
    "Antarctica/Mawson": "Antarctica/Mawson (UTC +05:00)",
    "Asia/Aqtau": "Asia/Aqtau (UTC +05:00)",
    "Asia/Aqtobe": "Asia/Aqtobe (UTC +05:00)",
    "Asia/Ashgabat": "Asia/Ashgabat (UTC +05:00)",
    "Asia/Atyrau": "Asia/Atyrau (UTC +05:00)",
    "Asia/Dushanbe": "Asia/Dushanbe (UTC +05:00)",
    "Asia/Karachi": "Asia/Karachi (UTC +05:00)",
    "Asia/Oral": "Asia/Oral (UTC +05:00)",
    "Asia/Qyzylorda": "Asia/Qyzylorda (UTC +05:00)",
    "Asia/Samarkand": "Asia/Samarkand (UTC +05:00)",
    "Asia/Tashkent": "Asia/Tashkent (UTC +05:00)",
    "Asia/Yekaterinburg": "Asia/Yekaterinburg (UTC +05:00)",
    "Indian/Kerguelen": "Indian/Kerguelen (UTC +05:00)",
    "Indian/Maldives": "Indian/Maldives (UTC +05:00)",
    "Asia/Colombo": "Asia/Colombo (UTC +05:30)",
    "Asia/Calcutta": "Asia/Kolkata (UTC +05:30)",
    "Asia/Kathmandu": "Asia/Kathmandu (UTC +05:45)",
    "Antarctica/Vostok": "Antarctica/Vostok (UTC +06:00)",
    "Asia/Almaty": "Asia/Almaty (UTC +06:00)",
    "Asia/Bishkek": "Asia/Bishkek (UTC +06:00)",
    "Asia/Dhaka": "Asia/Dhaka (UTC +06:00)",
    "Asia/Omsk": "Asia/Omsk (UTC +06:00)",
    "Asia/Qostanay": "Asia/Qostanay (UTC +06:00)",
    "Asia/Thimphu": "Asia/Thimphu (UTC +06:00)",
    "Asia/Urumqi": "Asia/Urumqi (UTC +06:00)",
    "Indian/Chagos": "Indian/Chagos (UTC +06:00)",
    "Asia/Yangon": "Asia/Yangon (UTC +06:30)",
    "Indian/Cocos": "Indian/Cocos (UTC +06:30)",
    "Antarctica/Davis": "Antarctica/Davis (UTC +07:00)",
    "Asia/Bangkok": "Asia/Bangkok (UTC +07:00)",
    "Asia/Barnaul": "Asia/Barnaul (UTC +07:00)",
    "Asia/Ho_Chi_Minh": "Asia/Ho Chi Minh (UTC +07:00)",
    "Asia/Hovd": "Asia/Hovd (UTC +07:00)",
    "Asia/Jakarta": "Asia/Jakarta (UTC +07:00)",
    "Asia/Krasnoyarsk": "Asia/Krasnoyarsk (UTC +07:00)",
    "Asia/Novokuznetsk": "Asia/Novokuznetsk (UTC +07:00)",
    "Asia/Novosibirsk": "Asia/Novosibirsk (UTC +07:00)",
    "Asia/Phnom_Penh": "Asia/Phnom Penh (UTC +07:00)",
    "Asia/Pontianak": "Asia/Pontianak (UTC +07:00)",
    "Asia/Tomsk": "Asia/Tomsk (UTC +07:00)",
    "Asia/Vientiane": "Asia/Vientiane (UTC +07:00)",
    "Indian/Christmas": "Indian/Christmas (UTC +07:00)",
    "Asia/Brunei": "Asia/Brunei (UTC +08:00)",
    "Asia/Choibalsan": "Asia/Choibalsan (UTC +08:00)",
    "Asia/Hong_Kong": "Asia/Hong Kong (UTC +08:00)",
    "Asia/Irkutsk": "Asia/Irkutsk (UTC +08:00)",
    "Asia/Kuala_Lumpur": "Asia/Kuala Lumpur (UTC +08:00)",
    "Asia/Kuching": "Asia/Kuching (UTC +08:00)",
    "Asia/Macau": "Asia/Macau (UTC +08:00)",
    "Asia/Makassar": "Asia/Makassar (UTC +08:00)",
    "Asia/Manila": "Asia/Manila (UTC +08:00)",
    "Asia/Shanghai": "Asia/Shanghai (UTC +08:00)",
    "Asia/Singapore": "Asia/Singapore (UTC +08:00)",
    "Asia/Taipei": "Asia/Taipei (UTC +08:00)",
    "Asia/Ulaanbaatar": "Asia/Ulaanbaatar (UTC +08:00)",
    "Australia/Perth": "Australia/Perth (UTC +08:00)",
    "Australia/Eucla": "Australia/Eucla (UTC +08:45)",
    "Asia/Chita": "Asia/Chita (UTC +09:00)",
    "Asia/Dili": "Asia/Dili (UTC +09:00)",
    "Asia/Jayapura": "Asia/Jayapura (UTC +09:00)",
    "Asia/Khandyga": "Asia/Khandyga (UTC +09:00)",
    "Asia/Pyongyang": "Asia/Pyongyang (UTC +09:00)",
    "Asia/Seoul": "Asia/Seoul (UTC +09:00)",
    "Asia/Tokyo": "Asia/Tokyo (UTC +09:00)",
    "Asia/Yakutsk": "Asia/Yakutsk (UTC +09:00)",
    "Pacific/Palau": "Pacific/Palau (UTC +09:00)",
    "Australia/Adelaide": "Australia/Adelaide (UTC +09:30)",
    "Australia/Broken_Hill": "Australia/Broken Hill (UTC +09:30)",
    "Australia/Darwin": "Australia/Darwin (UTC +09:30)",
    "Antarctica/DumontDUrville": "Antarctica/DumontDUrville (UTC +10:00)",
    "Antarctica/Macquarie": "Antarctica/Macquarie (UTC +10:00)",
    "Asia/Ust-Nera": "Asia/Ust-Nera (UTC +10:00)",
    "Asia/Vladivostok": "Asia/Vladivostok (UTC +10:00)",
    "Australia/Brisbane": "Australia/Brisbane (UTC +10:00)",
    "Australia/Hobart": "Australia/Hobart (UTC +10:00)",
    "Australia/Lindeman": "Australia/Lindeman (UTC +10:00)",
    "Australia/Melbourne": "Australia/Melbourne (UTC +10:00)",
    "Australia/Sydney": "Australia/Sydney (UTC +10:00)",
    "Pacific/Chuuk": "Pacific/Chuuk (UTC +10:00)",
    "Pacific/Guam": "Pacific/Guam (UTC +10:00)",
    "Pacific/Port_Moresby": "Pacific/Port Moresby (UTC +10:00)",
    "Pacific/Saipan": "Pacific/Saipan (UTC +10:00)",
    "Australia/Lord_Howe": "Australia/Lord Howe (UTC +10:30)",
    "Antarctica/Casey": "Antarctica/Casey (UTC +11:00)",
    "Asia/Magadan": "Asia/Magadan (UTC +11:00)",
    "Asia/Sakhalin": "Asia/Sakhalin (UTC +11:00)",
    "Asia/Srednekolymsk": "Asia/Srednekolymsk (UTC +11:00)",
    "Pacific/Bougainville": "Pacific/Bougainville (UTC +11:00)",
    "Pacific/Efate": "Pacific/Efate (UTC +11:00)",
    "Pacific/Guadalcanal": "Pacific/Guadalcanal (UTC +11:00)",
    "Pacific/Kosrae": "Pacific/Kosrae (UTC +11:00)",
    "Pacific/Norfolk": "Pacific/Norfolk (UTC +11:00)",
    "Pacific/Noumea": "Pacific/Noumea (UTC +11:00)",
    "Pacific/Pohnpei": "Pacific/Pohnpei (UTC +11:00)",
    "Antarctica/McMurdo": "Antarctica/McMurdo (UTC +12:00)",
    "Asia/Anadyr": "Asia/Anadyr (UTC +12:00)",
    "Asia/Kamchatka": "Asia/Kamchatka (UTC +12:00)",
    "Pacific/Auckland": "Pacific/Auckland (UTC +12:00)",
    "Pacific/Fiji": "Pacific/Fiji (UTC +12:00)",
    "Pacific/Funafuti": "Pacific/Funafuti (UTC +12:00)",
    "Pacific/Kwajalein": "Pacific/Kwajalein (UTC +12:00)",
    "Pacific/Majuro": "Pacific/Majuro (UTC +12:00)",
    "Pacific/Nauru": "Pacific/Nauru (UTC +12:00)",
    "Pacific/Tarawa": "Pacific/Tarawa (UTC +12:00)",
    "Pacific/Wake": "Pacific/Wake (UTC +12:00)",
    "Pacific/Wallis": "Pacific/Wallis (UTC +12:00)",
    "Pacific/Chatham": "Pacific/Chatham (UTC +12:45)",
    "Pacific/Apia": "Pacific/Apia (UTC +13:00)",
    "Pacific/Enderbury": "Pacific/Enderbury (UTC +13:00)",
    "Pacific/Fakaofo": "Pacific/Fakaofo (UTC +13:00)",
    "Pacific/Tongatapu": "Pacific/Tongatapu (UTC +13:00)",
    "Pacific/Kiritimati": "Pacific/Kiritimati (UTC +14:00)",
}