import { NgModule, Optional, SkipSelf } from '@angular/core';

import { SettingsService } from './settings/settings.service';
import { TranslatorService } from './translator/translator.service';
import { MenuService } from './menu/menu.service';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { IdlemodalComponent } from './auth/idlemodal/idlemodal.component';
import {AuthService} from '../routes/user/auth.service';
import { ConfirmComponent } from './utils/confirm/confirm.component';
import {ConfirmService} from './utils/confirm/confirm.service';
import { LoaderService } from './utils/loader.service';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from '..';
import { HttpClient } from '@angular/common/http';
@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
    ],
    providers: [
        SettingsService,
        TranslatorService,
        MenuService,
        AuthService,
        ConfirmService,
        LoaderService,
    ],
    declarations: [
        IdlemodalComponent,
        ConfirmComponent
    ],
    exports: []
})
export class CoreModule {
    constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
