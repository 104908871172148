import {NgModule, APP_INITIALIZER} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppInitService} from './app-init.service';

export function initApp(service: AppInitService) {
  return () => service.initApplication();
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    AppInitService,
    {provide: APP_INITIALIZER, useFactory: initApp, deps: [AppInitService], multi: true},
  ]
})
export class AppLoadModule {
}
