import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.pug',
  styleUrls: ['./zoom.component.scss']
})
export class ZoomComponent implements OnInit {

  zoom: number = 1;

  @Output("onZoom") onZoom = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  getPercentage = () => {
    return `${Number((this.zoom * 100).toFixed(2))}%`;
  }

  handleZoomIn = ($event) => {
    $event.preventDefault();
    $event.stopPropagation();
    this.zoom = Number((this.zoom + 0.1).toFixed(2));
    this.onZoom.emit(this.zoom);
  }

  handleZoomOut = ($event) => {
    $event.preventDefault();
    $event.stopPropagation();
    this.zoom = Number((this.zoom - 0.1).toFixed(2));
    this.onZoom.emit(this.zoom);
  }

  handleZoomReset = ($event) => {
    $event.preventDefault();
    $event.stopPropagation();
    this.zoom = 1;
    this.onZoom.emit(this.zoom);
  }

}
