export class IField {
    name: string;
    label: string;
    type: string;
    required?: boolean = false;
    options?: any;
    readonly?: boolean;
    validators?: any;
    valueGetter?: Function;
    valueSetter?: Function;
    tooltipValueGetter?: Function;
    comparator?: Function;
    onChange?: Function;
    minWidth?: number;
    isAdminEditable?: boolean = false;
    isAdmin?: boolean = false;
    section?: string;
    hide?: boolean = false;
    fieldName?: string;
    sectionName?: string;
    multipleSelection?: boolean = false;
    chartDataType?: string = null;
    rules?: any;
    dontPresentOnModal?: boolean = false;
    preventEditOnModal?: boolean = false;
    isRegularColumn?: boolean = false;
    displayOnBothModal?: boolean = false;
    sendWhatsAppTo?: boolean = false;
    translationKey?: string = "";

    constructor(options: {
        name?: string,
        label?: string,
        type?: string,
        required?: boolean,
        options?: any,
        readonly?: boolean,
        validators?: any,
        valueGetter?: any,
        valueSetter?: any,
        tooltipValueGetter?: any,
        comparator?: any,
        onChange?: any,
        minWidth?: any,
        isAdminEditable?: any,
        isAdmin?: any,
        section?: string,
        hide?: boolean,
        fieldName?: string,
        sectionName?: string,
        multipleSelection?: boolean,
        chartDataType?: string,
        rules?: any,
        dontPresentOnModal?: boolean,
        preventEditOnModal?: boolean,
        isRegularColumn?: boolean,
        displayOnBothModal?: boolean,
        sendWhatsAppTo?: boolean,
        translationKey?: string,
    }) {
        this.name = options.name || "";
        this.label = options.label || "";
        this.type = options.type || "textbox";
        this.required = options.required || false;
        this.options = options.options || {};
        this.validators = options.validators || [];
        this.valueGetter = options.valueGetter || undefined;
        this.valueSetter = options.valueSetter || undefined;
        this.tooltipValueGetter = options.tooltipValueGetter || undefined;
        this.comparator = options.comparator || undefined;
        this.onChange = options.onChange || undefined;
        this.minWidth = options.minWidth || undefined;
        this.isAdminEditable = options.isAdminEditable || false;
        this.isAdmin = options.isAdmin || false;
        this.section = options.section || "";
        this.hide = options.hide || false;
        this.fieldName = options.fieldName || "";
        this.sectionName = options.sectionName || "";
        this.multipleSelection = options.multipleSelection || false;
        this.chartDataType = options.chartDataType || null;
        this.rules = options.rules || null;
        this.dontPresentOnModal = options.dontPresentOnModal || false;
        this.preventEditOnModal = options.preventEditOnModal || false;
        this.isRegularColumn = options.isRegularColumn || false;
        this.displayOnBothModal = options.displayOnBothModal || false;
        this.sendWhatsAppTo = options.sendWhatsAppTo || false;
        this.translationKey = options.translationKey || "";
    }
}