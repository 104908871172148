export const pmCalendarStatus = {
    "Skipped": "PM-CALENDAR.SKIPPED",
    "Pending": "PM-CALENDAR.PENDING",
    "Wait for QA": "PM-CALENDAR.WAIT-FOR-QA",
    "Wait for safety": "PM-CALENDAR.WAIT-FOR-SAFETY",
    "Done": "PM-CALENDAR.DONE",
};

export const pmCalendarStatusColors = {
    "Skipped": "#E13434",
    "Pending": "#0184BE",
    "No status": "#FFB900",
    "Wait for QA": "#0097f5",
    "Wait for safety": "#800080",
    "Done": "#29B466",
};