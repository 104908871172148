import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../routes/user/auth.service';
import { map } from 'rxjs/operators';
import { isArray } from 'lodash';
import { CommonService } from '../services/common.service';
import { ROLES } from '../constants/enums/enums';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private auth: AuthService, private router: Router, private commonService: CommonService) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.user$.pipe(
      map((user: any) => {
        const url = this.router.url;
        if (!user || !user._id) {
          if(url === '/login-ad' || url === '/login-saml' || url.includes('/user-login')) return false;
          this.router.navigate(['/login']);
          return false;
        }

        if (user.company && !user.company.timezone && !['/timezone-setting', '/login'].includes(state.url)) {
          this.router.navigate(['/timezone-setting'])
          return false;
        }

        if(state.url.includes('/setup-wizard') && ((isArray(user.roles) && !this.auth.hasPermission([ROLES.ADMIN, ROLES.TOOL_ADMIN])) || user.roles == null || typeof user.roles == 'undefined')) {
          this.router.navigate(['/main/dashboard'])
          return false;
        }

        if (
          ((state.url.includes('/admin/')) && !['/admin/pm-calendar'].includes(state.url) && ((isArray(user.roles) && !user.roles.includes(ROLES.ADMIN) && !user.roles.includes(ROLES.TOOL_ADMIN)) || user.roles == null || typeof user.roles == 'undefined'))
          || (['/admin/sla'].includes(state.url) && !this.commonService.hasSlaPermission())
          || (['/admin/workers'].includes(state.url) && !this.commonService.hasBigTeamPermission())
          || (['/admin/history-audit-trail'].includes(state.url) && !this.commonService.hasAuditTrailPermission())
          || (['/admin/ip-addresses'].includes(state.url) && !this.commonService.hasIpAddressPermission())
          || (['/admin/procedures', '/admin/tasks'].includes(state.url) && !this.commonService.hasProceduresAndTasksPermission())
          || (['/tables/inventory'].includes(state.url) && !this.commonService.hasInventoryPermission())
          || (['/admin/pm-calendar'].includes(state.url) && !this.commonService.hasPmCalenderPermission())
          || (['/admin/drawing-definitions'].includes(state.url) && !this.commonService.hasDrawingModePermission())
          || (['/admin/units'].includes(state.url) && !this.commonService.hasMeasurementsPermission() && !this.commonService.hasInventoryPermission())
          || (['/admin/data-formats', '/tables/measurements', '/tables/measurement-results', '/tables/measurement-result-logs'].includes(state.url) && !this.commonService.hasMeasurementsPermission())
          || (state.url.includes("/main/line-measurements") && !this.commonService.hasMeasurementsPermission())
          || (state.url.includes('/smart-dropdown') && !this.commonService.hasSmartDropDownPermission())
          || (state.url.includes('/main/calendar') && !this.commonService.hasCalendarPermission())
          || (state.url.includes('/smart-report') && !this.commonService.hasSmartDropDownPermission())
          || (state.url.includes('/root/') && !user.roles.includes("root"))
        ) {
          this.router.navigate(['/main/dashboard'])
          return false;
        }

        if ((user.company && !user.company.verified) || user.isDeleted) {
          this.auth.logout();
          this.router.navigate(['/login'])
          return false;
        }
        return true;
      })
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.user$.pipe(
      map(user => {
        if (!user || !user._id) {
          this.router.navigate(['/login']);
          return false;
        }

        if (user.company && !user.company.verified) {
          this.auth.logout();
          this.router.navigate(['/login'])
          return false;
        }
        return true;
      })
    );
  }
}
