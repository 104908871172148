export const newFieldSection = {
    'tool': 'menu.TOOLS',
    'pm': 'menu.PM',
    'history': 'menu.HISTORY',
    'inventory': 'menu.INVENTORY',
    'files': 'menu.FILES',
    'users': 'menu.USERS',
    'workers': 'menu.WORKERS',
    'pm-kind': 'menu.PM-KINDS',
    'machine-status': 'menu.MACHINE-STATUS',
    'departments': 'menu.DEPARTMENT',
};