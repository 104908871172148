import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../../routes/user/auth.service';
import FieldDefinition from '../constants/field-definitions/pm-calender';
import { PmCalender } from '../interfaces/pm-calender';
import { PmCalenderBackendService } from './backend/pm-calender-backend.service';
import { CommonService } from './common.service';
import { CustomFieldsStoreService } from './custom-fields-store.service';
import { TranslationsStoreService } from './translations-store.service';
import { pmKind } from '../constants/pmKind';
import { TranslateService } from '@ngx-translate/core';
import { EmailColumns } from '../interfaces/email-columns';
import moment from 'moment-timezone';
import { uniqBy } from 'lodash';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PmCalenderStoreService {

  private pmCalenderSubject: BehaviorSubject<List<PmCalender>> = new BehaviorSubject(List([]));
  public readonly pmCalenders$: Observable<List<PmCalender>> = this.pmCalenderSubject.asObservable();

  public pmCalenderDeletedSubject: BehaviorSubject<List<PmCalender>> = new BehaviorSubject(List([]));
  public readonly pmCalenderDeleted$: Observable<List<PmCalender>> = this.pmCalenderDeletedSubject.asObservable();

  private columnsSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public readonly columns$: Observable<any[]>;

  private lastFetchedTime: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    private customFieldsService: CustomFieldsStoreService,
    private backend: PmCalenderBackendService,
    private commonService: CommonService,
    private translationsStoreService: TranslationsStoreService,
    private translateService: TranslateService,
    private router: Router,
    private auth: AuthService) {

    auth.user$.subscribe(user => {
      if (!user || !user._id || user.isRoot()) {
        this.pmCalenderSubject.next(List([]));
        return;
      }
      if (this.commonService.hasPmCalenderPermission()) this.load();
    });

    this.columns$ = combineLatest([this.auth.user$, this.customFieldsService.get('pm-calender'), this.translationsStoreService.languageCode$]).pipe(
      map(([user, columns, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(col => (col.name != "company.name" || user.isRoot()) && (col.name != "isDeleted" || user.isAdminOrToolAdmin()));
        const fields = columns.toArray();
        const col = this.customFieldsService.toColumnDef(defaults, {
          rowSelector: 'id_num',
          table: 'pm',
          format: this.formatCell.bind(this)
        });
        const custom = this.customFieldsService.toColumnDef(fields, {
          format: this.customFieldsService.formatCellCustomField.bind(this)
        });
        return col.concat(custom);
      })
    );
  }

  load() {
    const time = moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss');
    this.backend.list(this.lastFetchedTime.getValue())
      .pipe(
        map(pmCalenders => pmCalenders.map((pmCalender: PmCalender) => new PmCalender(pmCalender))),
        map(pmCalenders => pmCalenders.map(this.remapPmCalender))
      )
      .subscribe((pmCalenders: Array<PmCalender>) => {
        this.lastFetchedTime.next(time);
        if(!this.lastFetchedTime.getValue()){
          this.pmCalenderSubject.next(List(pmCalenders));
        } else {
          const prev = this.pmCalenderSubject.getValue().toArray();
          const data = uniqBy([...pmCalenders, ...prev], '_id');
          this.pmCalenderSubject.next(List(data))
        }
      });
  }

  deletedList() {
    if (this.pmCalenderDeletedSubject.getValue().toArray().length > 0) {
      return of(false);
    } else {
      return this.subDeletedList().subscribe();
    }
  }

  subDeletedList() {
    return this.backend.deletedList()
      .pipe(
        map(pmCalenders => pmCalenders.map((pmCalender: PmCalender) => new PmCalender(pmCalender))),
        map(pmCalenders => pmCalenders.map(this.remapPmCalender)),
        tap((pmCalenders: Array<PmCalender>) => this.pmCalenderDeletedSubject.next(List(pmCalenders)))
      )
  }

  create(pmCalender: PmCalender) {
    return this.backend.create(pmCalender)
      .pipe(
        map(pmCalender => new PmCalender(pmCalender)),
        map(this.remapPmCalender),
        tap(ev => {
          this.pmCalenderSubject.next(this.pmCalenderSubject.getValue().unshift(ev));
        }));
  }

  syncPmCalendar(id: string) {
    return this.backend.getById(id)
      .pipe(
        map(pmCalender => new PmCalender(pmCalender)),
        map((pmCalender) => this.remapPmCalender(pmCalender)),
        tap(pmCalender => {
          const pmCalendars = this.pmCalenderSubject.getValue();
          const idx = pmCalendars.findIndex((p: PmCalender) => p._id === id);
          if(idx > -1) {
            this.pmCalenderSubject.next(pmCalendars.set(idx, pmCalender));
          } else {
            this.pmCalenderSubject.next(pmCalendars.unshift(pmCalender));
          }
        }));
  }

  getList() {
    return this.pmCalenderSubject.getValue();
  }

  getCalendarById(calendarId: string) {
    const calendars = this.getList().toArray();
    const calendarIndex = calendars.findIndex(calendar => calendar._id === calendarId);
    return calendarIndex > -1 ? calendars[calendarIndex] : null;
  }

  update(pmCalender: PmCalender) {
    return this.backend.update(pmCalender)
      .pipe(
        map(pmCalender => new PmCalender(pmCalender)),
        map(this.remapPmCalender),
        tap(pmCalender => {
          const pmCalenders = this.pmCalenderSubject.getValue();
          const idx = pmCalenders.findIndex((e: PmCalender) => e._id === pmCalender._id);
          this.pmCalenderSubject.next(pmCalenders.set(idx, pmCalender));
        }));
  }

  updateBulk(pmCalenders: PmCalender[]) {
    return this.backend.updateBulk(pmCalenders)
      .pipe(
        map(pmCalenders => pmCalenders.map((pmCalender: PmCalender) => new PmCalender(pmCalender))),
        map(pmCalenders => pmCalenders.map((pmCalender: PmCalender) => this.remapPmCalender(pmCalender))),
        tap((pmCalenders: Array<PmCalender>) => {
          pmCalenders.forEach(pmCalender => {
            const pms = this.pmCalenderSubject.getValue();
            const idx = pms.findIndex((t: PmCalender) => t._id === pmCalender._id);
            this.pmCalenderSubject.next(pms.set(idx, pmCalender));
          })
        })
      );
  }

  updateManually(pmCalender: PmCalender) {
    const pmCalenders = this.pmCalenderSubject.getValue();
    const idx = pmCalenders.findIndex((e: PmCalender) => e._id === pmCalender._id);
    this.pmCalenderSubject.next(pmCalenders.set(idx, pmCalender));
  }

  delete(pmCalender: PmCalender) {
    return this.backend.delete(pmCalender).pipe(
      tap(() => {
        const pmCalenders = this.pmCalenderSubject.getValue();
        const idx = pmCalenders.findIndex((e: PmCalender) => e._id === pmCalender._id);
        this.pmCalenderSubject.next(pmCalenders.set(idx, {...pmCalender, isDeleted: true}));
      }));
  }


  save(pmCalender: PmCalender) {
    if (pmCalender._id) {
      return this.update(pmCalender);
    }
    return this.create(pmCalender);
  }

  private remapPmCalender(pmCalender) {
    return pmCalender;
  }

  formatCell = (col, field) => {
    switch (field.name) {
      case "tool.name":
        return {
          ...col,
          cellRenderer: 'customClick',
          cellRendererParams: {
            onClick: this.toolLink.bind(this),
            field: field.name
          }
        }
      case "dueDate":
        return {
          ...col,
          valueFormatter: (params) => params.data.dueDate && this.commonService.getDate(params.data.dueDate),
          valueGetter: (params) => params.data.dueDate && this.commonService.getDate(params.data.dueDate)
        }
      case "dueDateMonth":
        return {
          ...col,
          valueFormatter: (params) => params.data.dueDate && this.commonService.getDateMonth(params.data.dueDate),
          valueGetter: (params) => params.data.dueDate && this.commonService.getDateMonth(params.data.dueDate)
        }
      case "dueDateWeek":
        return {
          ...col,
          valueFormatter: (params) => params.data.dueDate && this.commonService.getDateWeek(params.data.dueDate),
          valueGetter: (params) => params.data.dueDate && this.commonService.getDateWeek(params.data.dueDate)
        }
      case "actualDate":
        return {
          ...col,
          valueFormatter: (params) => params.data.actualDate && this.commonService.getDate(params.data.actualDate),
          valueGetter: (params) => params.data.actualDate && this.commonService.getDate(params.data.actualDate)
        }
      case "updatedAt":
        return {
          ...col,
          valueFormatter: (params) => params.data.updatedAt && this.commonService.getDateTime(params.data.updatedAt),
          valueGetter: (params) => params.data.updatedAt && this.commonService.getDateTime(params.data.updatedAt),
        }
      case "maintenance.type.pmKind":
        return {
          ...col,
          valueFormatter: (params) => params.data.maintenance && params.data.maintenance.type && params.data.maintenance.type.pmKind ? this.translateService.instant(pmKind[params.data.maintenance.type.pmKind]) : null,
          valueGetter: (params) => params.data.maintenance && params.data.maintenance.type && params.data.maintenance.type.pmKind ? this.translateService.instant(pmKind[params.data.maintenance.type.pmKind]) : null,
        }
      default:
        return col;
    }
  }

  toolLink(params) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/main/maintenance/tool/${params.rowData && params.rowData.tool ? params.rowData.tool.id_num : ""}`])
    );
    window.open(url, '_blank');
  }

  sendEmail(data: { email: string, file: string, type: string, columnSelector: EmailColumns}) {
    return this.backend.sendEmail(data);
  }

  sendReportEmail(report: EmailColumns) {
    return this.backend.sendReportEmail(report);
  }

  getPmCalendar = (id: string) => {
    const pmCalenders = this.pmCalenderSubject.getValue();
    return pmCalenders.find((pmCalender: PmCalender) => pmCalender._id === id);
  }
}
