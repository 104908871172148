import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasPermissionDirective } from './has-permission.directive';
import { HideUnauthorizedDirective } from './hide-unauthorized.directive';
import { ShowUnauthorizedDirective } from './show-unauthorized.directive';
import { DisableUnauthorizedDirective } from './disable-unauthorized.directive';
import { ClickOutsideDirective } from './click-outside.directive';

@NgModule({
  declarations: [
    HasPermissionDirective,
    HideUnauthorizedDirective,
    ShowUnauthorizedDirective,
    DisableUnauthorizedDirective,
    ClickOutsideDirective,
  ],
  imports: [
    CommonModule
  ],
  exports:[
    HideUnauthorizedDirective,
    DisableUnauthorizedDirective,
    ClickOutsideDirective,
  ]
})
export class DirectivesModule { }
