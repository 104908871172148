import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { SettingsService } from "../../../core/settings/settings.service";
import { AuthService } from "../auth.service";
import {
  Contact,
} from "../../../core/interfaces/recovery-contact";
import { List } from "immutable";
import { ToasterService } from "angular2-toaster";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "../../../core/services/common.service";

@Component({
  selector: "app-recover",
  templateUrl: "./recover.component.html",
  styleUrls: ["./recover.component.scss"],
})
export class RecoverComponent implements OnInit {
  valForm: UntypedFormGroup;
  contacts: List<Contact> = null;
  passwordSent: Boolean = false;

  constructor(
    private toaster: ToasterService,
    private translate: TranslateService,
    public settings: SettingsService,
    private fb: UntypedFormBuilder,
    public commonService: CommonService,
    private auth: AuthService
  ) {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.valForm = fb.group({
      company: [null, Validators.compose([Validators.required])],
      email: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(emailRegex),
        ]),
      ],
    });
  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.auth.recoveryContact(value.company, value.email).subscribe(
        ({ passwordSent, contacts }) => {
          this.passwordSent = passwordSent;
          this.contacts =
            contacts && contacts.length > 0 ? List(contacts) : null;
        },
        (error) => {
          this.toaster.pop(
            "error",
            this.translate.instant("toasters.USER.ERR_ACCOUNT_RECOVERY")
          );
        }
      );
    }
  }

  ngOnInit() {}
}
