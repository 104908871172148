import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";

import { ICellEditorAngularComp } from "ag-grid-angular";
import { isArray, isObject } from "lodash";
import { CHECKLIST_TYPE } from "../../../core/constants/enums/enums";
import { ChecklistItemsService } from "../../../core/services/checklist-items.service.service";
import { ProcedureStoreService } from "../../../core/services/procedure-store.service";

@Component({
  selector: "editor-cell",
  template: `
    <div #container class="search_select">
      <ngx-select
        [allowClear]="true"
        autocomplete="off"
        [items]="values"
        [placeholder]="'maintenance.form-labels.SELECT-PLACEHOLDER' | translate"
        [(ngModel)]="value"
        [disabled]="disable"
      >
      </ngx-select>
    </div>
  `,
  styles: [
    `
      .search_select,
      .search_select .ngx-select {
        min-width: 280px;
      }
      @media (max-width: 1199px) {
        .search_select,
        .search_select .ngx-select {
          min-width: 212px;
        }
      }
      .search_select ngx-select {
        width: 100%;
      }
      .search_select .ngx-select__search {
        min-width: 280px;
      }
      @media (max-width: 1199px) {
        .search_select .ngx-select__search {
          min-width: 212px;
        }
      }
    `,
  ],
})
export class SelectDropdownEditorComponent
  implements ICellEditorAngularComp, AfterViewInit
{
  private params: any;
  public values: any;
  public value: any;
  public model: any;
  public disable: any = false;
  public change: Function = null;
  @ViewChild("container", { read: ViewContainerRef }) public container;
  public happy: boolean = false;

  constructor(
    private checklistItemsService: ChecklistItemsService,
    private procedureStoreService: ProcedureStoreService
  ) {}

  ngAfterViewInit() {}

  agInit(params: any): void {
    this.params = params;
    if (
      params.values &&
      params.values.length > 0 &&
      typeof params.values[0].company != "undefined" &&
      params.data.company
    ) {
      this.values = params.values.filter(
        (val) => val.company && val.company._id == params.data.company._id
      );
    } else {
      this.values = params.values;
    }
    if (
      this.params.colDef.section == "events" &&
      params.colDef.field == "worker" &&
      isObject(params.data[params.colDef.field])
    ) {
      this.value =
        typeof params.data[params.colDef.field] != "undefined"
          ? params.data[params.colDef.field]["_id"]
          : "";
    } else {
      this.value =
        typeof params.data[params.colDef.field] != "undefined"
          ? params.data[params.colDef.field]
          : "";
    }
    if (
      this.params.colDef.section === "tools" &&
      params.colDef.field == "tool" &&
      !params.data.isSubSystem
    ) {
      this.disable = true;
    } else if (
      this.params.colDef.section === "checklist" &&
      params.colDef.field == "chapter"
    ) {
      if (params.data.taskType === CHECKLIST_TYPE.CHAPTER) {
        this.disable = true;
      }
      const filter = this.params.api.getFilterModel();
      const procedures = [];
      if (
        typeof filter["procedureName"] != "undefined" &&
        filter["procedureName"] &&
        isArray(filter["procedureName"].values)
      ) {
        for (const key in filter["procedureName"].values) {
          const value = filter["procedureName"].values[key];
          const splitted = value.split(", ");
          for (let i = 0; i < splitted.length; i++) {
            const p = splitted[i];
            const procedure = this.procedureStoreService
              .getList()
              .toArray()
              .filter((pr) => pr.name?.trim() == p?.trim());
            if (procedure) procedures.push(...procedure);
          }
        }
      }
      const procedure = this.checklistItemsService.selectedProcedure.getValue();
      if (procedure) {
        const procedureInfo = this.procedureStoreService
          .getList()
          .find((p) => p._id == procedure);
        if (procedureInfo) procedures.push(procedureInfo);
      }
      if (procedures.length > 0) {
        const checklistItems = procedures
          .map((p) =>
            p.tasks
              .filter(
                (t) => t?.checklistItem?.taskType === CHECKLIST_TYPE.CHAPTER
              )
              .map((t) => t?.checklistItem?._id)
          )
          .flat()
          .concat([this.value]);
        this.values = this.values.filter((val) =>
          checklistItems.includes(val.id)
        );
      }
    }
    this.change = params.onChange || null;
  }

  getValue(): any {
    return this.value;
  }

  isPopup(): boolean {
    return true;
  }
}
