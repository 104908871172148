import {
  Component,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
  selector: "checkbox-renderer",
  template: `
    <div #container>
      <div class="form-check container">
        <input
          class="form-check-input"
          type="checkbox"
          (change)="checkedHandler($event)"
          [checked]="params.value"
          [disabled]="disabled"
          [(ngModel)]="params.value"
          [id]="id"
        />
        <label class="form-check-label cursor-pointer checkmark" [for]="id">
        </label>
      </div>
    </div>
  `,
  styles: [
    `
      .checkbox_design {
        position: relative;
      }
      .checkbox_design input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        z-index: 11;
      }
      .checkbox_design label {
        position: relative;
        left: 0;
        padding-left: 25px;
        cursor: pointer;
      }
      .checkbox_design label:before {
        content: "";
        border: 1px solid #044968;
        border-radius: 3px;
        background-color: #0184be;
        height: 20px;
        width: 20px;
        display: block;
        position: absolute;
        left: 0;
      }
      .checkbox_design label:after {
        content: "";
        display: block;
        width: 8px;
        height: 13px;
        border: solid white;
        border-width: 0 4px 4px 0;
        position: absolute;
        top: 2px;
        left: 6px;
        transform: rotate(45deg);
      }
      .checkbox_design input:checked ~ .checkbox_design label:after {
        display: block;
      }
      .container.form-check {
        padding-left: 0px;
        padding-top: 7px;
        margin-bottom: 15px;
      }
      .container.form-check:last-child {
        margin-bottom: 34px;
      }
      .container.form-check input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        z-index: 11;
        margin: 0;
      }
      .container.form-check input:checked ~ .checkmark::after {
        display: block;
      }
      .container.form-check input:checked ~ .checkmark::before {
        background-color: #0184be;
      }
      form .container.form-check input:disabled:checked ~ .checkmark::before,
      .custom-check
        .container.form-check
        input:disabled:checked
        ~ .checkmark::before {
        opacity: 0.5;
      }
      .checkmark {
        position: relative;
        left: 0;
        padding-left: 25px;
        cursor: pointer;
      }
      .checkmark::before {
        content: "";
        border: 1px solid #044968;
        border-radius: 3px;
        background-color: transparent;
        height: 19px;
        width: 19px;
        display: block;
        position: absolute;
        left: 0;
      }
      .checkmark::after {
        content: "";
        display: none;
        width: 7px;
        height: 12px;
        border: solid white;
        border-width: 0 4px 4px 0;
        position: absolute;
        top: 2px;
        left: 6px;
        transform: rotate(45deg);
      }
      div[dir="rtl"] .checkmark::before {
        left: unset;
        right: 0;
      }
      div[dir="rtl"] .checkmark {
        left: unset;
        padding-right: 25px;
        right: 0;
        margin-right: unset !important;
      }
      div[dir="rtl"] .container.form-check {
        padding-right: 0px;
        margin-bottom: 15px;
        padding-left: unset;
      }
      div[dir="rtl"] .checkmark::after {
        left: unset;
        right: 6px;
      }
    `,
  ],
})
export class CheckboxRendererComponent implements ICellRendererAngularComp, OnDestroy{
  public params: any;
  public model: any;
  public id: any;
  section: string = "";
  field: string = "";
  disabled: boolean = false;
  @ViewChild("container", { read: ViewContainerRef }) public container;
  constructor() {}

  agInit(params: any): void {
    this.params = params;
    this.field = this.params.colDef.field;
    this.section = this.params.colDef.section || "";
    this.model = this.params.value;
    this.id = `${this.params.colDef.colId}-${Math.random()}`;
    this.disabled = !this.params.colDef.editable;
  }

  refresh(params: any): boolean {
    return false;
  }

  ngOnDestroy() {}

  isPopup(): boolean {
    return false;
  }

  getValue(): any {
    return this.params.value;
  }

  checkedHandler(event) {
    let checked = event.target.checked;
    const colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
    
    if(this.params.colDef.invokeOnChange){
      this.params.colDef.invokeOnChange(this.params);}

  }
}
