import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TaskTransaction } from '../../interfaces/task-transaction';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskTransactionBackendService {

  url = '/api/task-transactions';

  constructor(private http: HttpClient) { }

  toolTransactionCount(toolId: string): Observable<{ taskTransaction: Number }> {
    return this.http.get<{ taskTransaction: Number }>(`${this.url}/tool-transaction-count/${toolId}`);
  }

  toolTransaction(toolId: string, page: number, limit: number) {
    return this.http.get<Array<TaskTransaction>>(`${this.url}/tool-transaction/${toolId}?skip=${page * limit}&limit=${limit}`);
  }

  listByEvent(eventId: string) {
    return this.http.get<Array<TaskTransaction>>(`${this.url}/event/${eventId}`);
  }

  list() {
    return this.http.get<Array<TaskTransaction>>(this.url)
  }

  listByTool(tool:string) {
    return this.http.get<Array<TaskTransaction>>(`${this.url}/${tool}`)
  }

  listAll() {
    return this.http.get<Array<TaskTransaction>>(`${this.url}?show=all`)
  }

  update(data: TaskTransaction) {
    return this.http.put(`${this.url}/${data._id}`, data);
  }
}
