import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { ICalendarEvent } from '@trademe/ng-add-to-calendar';
// import { format } from 'date-fns';
// import { BsModalRef } from 'ngx-bootstrap/modal';
// import { SubscriptionPayment } from '../../../../core/interfaces/subscription-payment';

@Component({
  selector: 'app-subscription-payment',
  templateUrl: './subscription-payment.component.html',
  styleUrls: ['./subscription-payment.component.scss']
})
export class SubscriptionPaymentComponent implements OnInit {

  // @Output('onClose') onClose: EventEmitter<any> = new EventEmitter<any>();
  // @Input('subscriptionPayment') subscriptionPayment: SubscriptionPayment;

  // public calendarEvent: ICalendarEvent;
  // constructor(public bsModalRef: BsModalRef) {

  // }

  ngOnInit(): void {
    // const date = new Date();
    // date.setMonth(date.getMonth() + 1);

    // this.calendarEvent = {
    //   title: `Reminder of Anymaint end of Trial`,
    //   start: date,
    //   duration: 120,
    //   description: `Your trial license will expire on ${format(date, 'DD.MM.YYYY')}`,
    // };
  }

  close = () => {
    // this.bsModalRef.hide();
  }



}
