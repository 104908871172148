import { Component, OnInit, Input, Output, ViewChild, ElementRef, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ConfirmService } from '../../../core/utils/confirm/confirm.service';
import { Observable } from 'rxjs';
import { ExcelService } from '../../services/excel.service';
import { ToasterService } from 'angular2-toaster';
import { NumericEditorComponent } from '../grid-component/numeric-editor.component';
import { MultiselectEditorComponent } from '../grid-component/multiselect-editor.component';
import { ClipboardService } from '../../../core/utils/clipboard.service';
import { ConfirmResult } from '../../../core/constants/confirm-result.enum';
import { LinkRendererComponent } from '../grid-component/link-renderer.component';
import { ButtonRendererComponent } from '../grid-component/button-renderer.component';
import { ToggleEditorComponent } from '../grid-component/toggle-editor.component';
import { CheckboxRendererComponent } from '../grid-component/checkbox-renderer.component';
import { TranslateService } from '@ngx-translate/core';
import { SelectDropdownEditorComponent } from '../grid-component/select-dropdown-editor.component';
import { ChecklistEditorComponent } from '../grid-component/checklist-editor.component';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';
import { DatePickerEditorComponent } from '../grid-component/date-picker-editor.component';
import { DynamicDropdownEditorComponent } from '../grid-component/dynamic-dropdown-editor-component';
import { SmartDropdownEditorComponent } from '../grid-component/smart-dropdown-editor.component';
import { MultipleSelectDropdownEditorComponent } from '../grid-component/multiple-select-dropdown-editor.component';
import { CustomClickRendererComponent } from '../grid-component/custom-click-renderer.component';
import { CustomTooltipComponent } from '../grid-component/custom-tooltip.component';
import { cloneDeep, isArray, isNumber, isString, without } from 'lodash';
import { ChartComponent } from './chart/chart.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FileRendererComponent } from '../grid-component/file-renderer.component';
import { CommonService } from '../../../core/services/common.service';
import { SilentSignatureRendererComponent } from '../grid-component/silent-signature-renderer.component';
import { AuthService } from '../../../routes/user/auth.service';
import { SelectColumnComponent } from './select-column/select-column.component';
import { BooleanRendererComponent } from '../grid-component/boolean-renderer.component';
import { ChartOptionsChanged, ChartRangeSelectionChanged } from 'ag-grid-community';
import { ChipRendererComponent } from '../grid-component/chip-renderer.component';
import { BarRendererComponent } from '../grid-component/bar-render.component';
import { NavService } from '../../../routes/main/nav.service';
import { RemoveTextEditorComponent } from '../grid-component/remove-text-editor.component';
import { FileRedirectionRendererComponent } from '../grid-component/file-redirection-renderer.component';
import { CustomMultipleSelectDropdownEditorComponent } from '../grid-component/custom-multiple-select-dropdown-editor.component';
import { measurementLogLocalStorage, measurementResultToolLocalStorage } from '../../../core/constants/variable';
import { StatusRendererComponent } from '../grid-component/status-renderer.component';
import { StatusTypeRendererComponent } from '../grid-component/status-type-renderer.component';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.pug',
  styleUrls: ['./dynamic-table.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})

export class DynamicTableComponent implements OnInit {
  @Input() autoHeight: boolean = true;
  @Input() columnDefs = [];
  @Input() controlDefs = [];
  @Input() rowData: Observable<any>;
  @Input() tableName = "";
  @Input() totalCount: number = 0;
  @Input() externalExportToExcel: boolean = false;
  @Input() disableModificationActions: boolean = false;
  @Input() disableRowActions: boolean | TemplateRef<any> = false;
  @Input() disableInsertRow: boolean | TemplateRef<any> = false;
  @Input() disableDuplicateRow: boolean | TemplateRef<any> = true;
  @Input() disableDuplicate: boolean | TemplateRef<any> = true;
  @Input() disableDeleteRow: boolean | TemplateRef<any> = false;
  @Input() disableSave: boolean | TemplateRef<any> = false;
  @Input() disableCut: boolean | TemplateRef<any> = false;
  @Input() disableCopy: boolean | TemplateRef<any> = false;
  @Input() disableInsertCol: boolean | TemplateRef<any> = false;
  @Input() disableDeleteCol: boolean | TemplateRef<any> = false;
  @Input() disableEditColumn: boolean | TemplateRef<any> = false;
  @Input() disableUnhideColumn: boolean | TemplateRef<any> = false;
  @Input() disableGraphColumn: boolean | TemplateRef<any> = false;
  @Input() disableAssignColumn: boolean | TemplateRef<any> = true;
  @Input() dataCreator: Function = () => ({});
  @Input() rowValidation: Function = (params) => true;
  @Input() slaVarification: Function = (params) => false;
  @Input() latePM: Function = (params) => false;
  @Input() pmCalenderNormal: Function = (params) => false;
  @Input() isPMKindMissing: Function = (params) => false;  
  @Input() maintenanceTypesNames: Observable<any>;
  @Input() pmCalenderSuccess: Function = (params) => false;
  @Input() pmCalenderLate: Function = (params) => false;
  @Input() inventoryQty: Function = (params) => false;
  @Input() pmCalenderFinished: Function = (params) => false;
  @Input() missingData: Function = (params) => false;
  @Input() deleteValidation: Function = params => true;
  @Input() expiration: Function = (params) => false;
  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() duplicate: EventEmitter<any> = new EventEmitter<any>();
  @Output() undo: EventEmitter<any> = new EventEmitter<any>();
  @Output() add: EventEmitter<any> = new EventEmitter<any>();
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();
  @Output() insertCol: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteCol: EventEmitter<any> = new EventEmitter<any>();
  @Output() editCol: EventEmitter<any> = new EventEmitter<any>();
  @Output() unhideCol: EventEmitter<any> = new EventEmitter<any>();
  @Output() graphCol: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchMore: EventEmitter<string> = new EventEmitter<string>();
  @Output() assignColumn: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportToExcel: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetAllFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() externalFilter: Function = (param) => true;
  @ViewChild('agGrid') agGrid: ElementRef;
  tableHeight = '200px';
  rtl = null;
  isNeedToShow = false;
  cacheData = [];
  unchangedData = [];
  public defaultColDef = {
    resizable: true,
    editable: true,
    sortable: true,
    lockPosition: true,
    floatingFilter: true,
    filterParams: { newRowsAction: 'keep' }
  };

  public gridOptions = {
    rowSelection: 'multiple',
    enableSorting: true,
    enableRangeSelection: true,
    enableCharts: true,
    rowHeight: 50,
    suppressColumnVirtualisation: true,
    tooltipShowDelay: 0,
    rowClassRules: {
      'strike-throught': params => params.data.deleted || params.data.isDeleted || params.data.isNotInterested,
      'zt-row-invalid': params => {
        const e: any = this.checkRowInvalid(params);
        return this.rowValidation(params).length > 0 || e.length > 0
      },
      'sla-background': params => this.slaVarification(params),
      'pm-calender-normal': params => this.pmCalenderNormal(params),
      'pm-kind-need-creation': params => this.isPMKindMissing(params),
      'row-expiration': params => this.expiration(params),
      'pm-calender-success': params => this.pmCalenderSuccess(params),
      'pm-calender-late': params => this.pmCalenderLate(params),
      'inventory-qty': params => this.inventoryQty(params),
      'pm-calender-finished': params => this.pmCalenderFinished(params),
      'missing-data': params => this.missingData(params),
      'late-pm': params => this.latePM(params),
      'numeric-gray': params => this.checkCustomNumericLogic(params, "gray"),
      'numeric-yellow': params => this.checkCustomNumericLogic(params, "yellow"),
      'numeric-red': params => this.checkCustomNumericLogic(params, "red"),
    },
    enableFilter: true,
    suppressRowTransform: true,
    sortingOrder: ['desc', 'asc'],
    frameworkComponents: {
      numeric: NumericEditorComponent,
      multiselect: MultiselectEditorComponent,
      linkCell: LinkRendererComponent,
      button: ButtonRendererComponent,
      customClick: CustomClickRendererComponent,
      fileRedirection: FileRedirectionRendererComponent,
      checkbox: CheckboxRendererComponent,
      toggle: ToggleEditorComponent,
      selectdropdown: SelectDropdownEditorComponent,
      checklist: ChecklistEditorComponent,
      datepicker: DatePickerEditorComponent,
      dynamicdropdown: DynamicDropdownEditorComponent,
      smartdropdown: SmartDropdownEditorComponent,
      multipledropdown: MultipleSelectDropdownEditorComponent,
      customMultipleDropdown: CustomMultipleSelectDropdownEditorComponent,
      customToolTip: CustomTooltipComponent,
      file: FileRendererComponent,
      chip: ChipRendererComponent,
      bar: BarRendererComponent,
      boolean: BooleanRendererComponent,
      silentSignature: SilentSignatureRendererComponent,
      removeText: RemoveTextEditorComponent,
      toolStatus: StatusRendererComponent,
      toolStatusType: StatusTypeRendererComponent,
    },
    isExternalFilterPresent: () => true,
    doesExternalFilterPass: (param) => {
      return param.data.$row_status != 'deleted' && this.externalFilter(param);
    },
    onFilterChanged: (param) => {
      $('.filtered-count-records').text(param.api.getDisplayedRowCount());
      if (!this.size) this.size = 1;
    },
    onChartOptionsChanged: this.onChartOptionsChanged.bind(this),
    onChartRangeSelectionChanged: this.onChartRangeSelectionChanged.bind(this),
  }

  gridApi;
  RTL;

  col: any = {
    colId: "side-bar",
    field: "side-bar",
    headerName: "",
    headerKey: "",
    tooltip: (params) => params.value,
    editable: false,
    cellClass: '',
    maxWidth: 10,
    hide: false,
    valueGetter: null,
    tooltipValueGetter: null,
    comparator: undefined,
    minWidth: 10,
    section: "",
    fieldName: '',
    sectionName: '',
    multipleSelection: false,
    required: false,
    aggFunc: null,
    isCustom: false,
    isPmCustom: false,
    type: "",
    rules: null,
    cellRenderer: "bar"
  };

  public disableUndo: boolean = true;
  public size: number = 0;
  public filterOpenedIndex: number = 0;
  isInitFilter = false;
  isInitSort = false;
  isInitColumnVisible = false;
  firstRendered = false;
  isSave = false;

  constructor(private confirm: ConfirmService,
    private translate: TranslateService,
    private commonService: CommonService,
    private toaster: ToasterService,
    private authService: AuthService,
    private excel: ExcelService,
    public translationService: TranslationsStoreService,
    private clipboard: ClipboardService,
    private modal: BsModalService,
    private changeDetector: ChangeDetectorRef,
    private navService: NavService,
  ) {
  }

  ngOnInit() {
    this.rowData.subscribe(data => {
      this.cacheData = cloneDeep(data)
      this.unchangedData = cloneDeep(data)
      const d = data.size ? data.size : data.length;
      $('.total-count-records').text(this.totalCount > d ? this.totalCount : d);
    })
    this.translationService.rtl$.subscribe(data => {
      this.RTL = data;
      this.isNeedToShow = false;
      this.changeDetector.detectChanges();
      this.isNeedToShow = true;
    })
  }

  ngOnChanges(changes) {
    if (changes.columnDefs && changes.columnDefs.currentValue) {
      this.translationService.rtl$.subscribe(rtl => {
        this.columnDefs = [this.col].concat(changes.columnDefs.currentValue).map((c, index) => {
          if (index === 0 || index === 1) {
            c.pinned = rtl ? 'right' : 'left';
          }
          return c;
        })
      })
    }
  }

  checkRule = () => {
    $('.ag-floating-filter-body').each((e) => {
      const v = $($('.ag-floating-filter-body')[e]).find('input').val();
      if (v != "") {
        const v = Number($($('.ag-floating-filter-body')[e]).closest('.ag-header-cell').attr("aria-colindex")) - 1;
        if (this.filterOpenedIndex == v) {
          const left = $(".ag-tabs.ag-menu.ag-focus-managed.ag-popup-child").css("left");
          const a = $(".ag-tabs.ag-menu.ag-focus-managed.ag-popup-child").data("apply");
          if (left && !a) {
            let n = 0;
            if (this.RTL) {
              n = Number(left.replace("px", "")) + $($('.ag-floating-filter-body')[e]).width() + (this.size ? 0 : 20);
            } else {
              n = Number(left.replace("px", "")) - $($('.ag-floating-filter-body')[e]).width() + (this.size ? 0 : 20);
            }
            $(".ag-tabs.ag-menu.ag-focus-managed.ag-popup-child").data("apply", 1);
            $(".ag-tabs.ag-menu.ag-focus-managed.ag-popup-child").css("left", n);
            this.size = n;
          } else {
            $(".ag-tabs.ag-menu.ag-focus-managed.ag-popup-child").css("left", this.size);
          }
        }
      }
    })
  }

  ngAfterViewChecked() {
    $('.wrapper[dir="rtl"]').find('.ag-ltr').addClass('ag-rtl').removeClass('ag-ltr');
    $('.ag-floating-filter-body').each((e) => {
      const v = $($('.ag-floating-filter-body')[e]).find('input').val();
      if (v == "") {
        $($('.ag-floating-filter-body')[e]).css('display', 'none');
      } else {
        $($('.ag-floating-filter-body')[e]).css('display', 'block');
      }
    });
    const h = $(".ag-virtual-list-container").height();
    const a = $(".ag-virtual-list-container").data("apply");
    if (h && !a) {
      $(".ag-virtual-list-container").height($(".ag-virtual-list-container").height() + 80);
      $(".ag-virtual-list-container").data("apply", 1);
    }
  }

  onChartOptionsChanged(event: ChartOptionsChanged) {
    const d = {
      chartOptions: event.chartOptions,
      chartThemeName: event.chartThemeName,
      chartType: event.chartType,
    }
    const name = `ag-grid-chart-option-${this.tableName}`;
    const value = JSON.stringify(d);
    localStorage.setItem(name, value);
    this.authService.updateColumnState(name, value);
  }

  onChartRangeSelectionChanged(event: ChartRangeSelectionChanged) {
    const d = {
      cellRange: event.cellRange,
    }
    const aName = `ag-grid-select-column-${this.tableName}`;
    const bName = `ag-grid-chart-range-selection-${this.tableName}`;
    const aValue = JSON.stringify(event.cellRange.columns)
    const bValue = JSON.stringify(d);
    localStorage.setItem(aName, aValue);
    localStorage.setItem(bName, bValue);
    this.authService.updateColumnState(aName, aValue);
    this.authService.updateColumnState(bName, bValue);
  }

  checkSelectorValue = (selector, baseValue, actualValue) => {
    if (selector === ">") {
      return actualValue > baseValue
    } else if (selector === ">=") {
      return actualValue >= baseValue
    } else if (selector === "<") {
      return actualValue < baseValue
    } else if (selector === "<=") {
      return actualValue <= baseValue
    }
  }

  checkCustomNumericLogic = (params, color) => {
    const fields = this.columnDefs && this.columnDefs.filter(f => (f.isCustom || f.isPmCustom) && f.type === "numeric" && f.rules && f.rules.length > 0)
    if (fields.length > 0) {
      let colors = [];
      fields.forEach(f => {
        let val = null;
        if (typeof params.data.customFields != 'undefined' && params.data.customFields && typeof params.data.customFields[f.field] != 'undefined') {
          val = isArray(params.data.customFields[f.field]) ? params.data.customFields[f.field].join(', ') : params.data.customFields[f.field];
        } else if (typeof params.data.pmCustomFields != 'undefined' && params.data.pmCustomFields && typeof params.data.pmCustomFields[f.field] != 'undefined') {
          val = isArray(params.data.pmCustomFields[f.field]) ? params.data.pmCustomFields[f.field].join(', ') : params.data.pmCustomFields[f.field];
        }
        if (!val) {
          return false;
        }
        f.rules.filter(r => r.color === color).forEach(r => {
          const match = this.checkSelectorValue(r.selector, r.value, Number(val))
          if (match) colors.push(color);
        });
      })
      return colors.includes(color);
    }
    return false;
  }

  onFilterChanged(params) {
    if(!this.firstRendered || this.isSave) {
      this.isSave = false;
      return;
    }
    const name = `ag-grid-persistane-filtering-${this.tableName}`;
    const value = JSON.stringify(params.api.getFilterModel());
    localStorage.setItem(name, value);
    const n = this.authService.getColumnState(name);
    if (this.isInitFilter) {
      this.isInitFilter = false;
      if (!n) this.authService.updateColumnState(name, value);
    } else {
      this.authService.updateColumnState(name, value);
    }
  }

  onSortChanged(params) {
    if(!this.firstRendered || this.isSave) {
      this.isSave = false;
      return;
    }
    const name = `ag-grid-persistane-sorting-${this.tableName}`;
    const value = JSON.stringify(params.api.getSortModel());
    localStorage.setItem(name, value);
    const n = this.authService.getColumnState(name);
    if (this.isInitSort) {
      this.isInitSort = false;
      if (!n) this.authService.updateColumnState(name, value);
    } else {
      this.authService.updateColumnState(name, value);
    }
  }

  onColumnVisible(params, type) {
    if(!this.firstRendered || this.isSave) {
      this.isSave = false;
      return;
    }
    const name = `ag-grid-persistane-columns-${this.tableName}`;
    const value = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem(`ag-grid-persistane-columns-${this.tableName}`, value);
    const n = this.authService.getColumnState(name);
    if (this.isInitColumnVisible) {
      this.isInitColumnVisible = false;
      if (!n) this.authService.updateColumnState(name, value);
    } else {
      this.authService.updateColumnState(name, value);
    }
  }

  onFirstDataRendered(params): void {
    const fName = `ag-grid-persistane-filtering-${this.tableName}`;
    const sName = `ag-grid-persistane-sorting-${this.tableName}`;
    const cName = `ag-grid-persistane-columns-${this.tableName}`;
    const filterModel = localStorage.getItem(fName) ? localStorage.getItem(fName) : this.authService.getColumnState(fName);
    const sortModel = localStorage.getItem(sName) ? localStorage.getItem(sName) : this.authService.getColumnState(sName);
    const colState = localStorage.getItem(cName) ? localStorage.getItem(cName) : this.authService.getColumnState(cName);
    if (colState) {
      this.isInitColumnVisible = true;
      params.columnApi.setColumnState(JSON.parse(colState).map((c, index) => {
        if (index === 0 || index === 1) {
          delete c.pinned;
        }
        return c;
      }))
    };
    if (filterModel) {
      this.isInitFilter = true;
      params.api.setFilterModel(JSON.parse(filterModel));
    }
    if (sortModel) {
      this.isInitSort = true;
      params.api.setSortModel(JSON.parse(sortModel));
    }
    const measurementResult = localStorage.getItem(measurementResultToolLocalStorage);
    if (measurementResult) {
      params.api.setFilterModel({
        "toolObject.id_num": {
          "values": [measurementResult],
          "filterType": "set"
        }
      });
      params.api.onFilterChanged();
      localStorage.removeItem(measurementResultToolLocalStorage);
    }
    const measurementLog = localStorage.getItem(measurementLogLocalStorage);
    if (measurementLog) {
      params.api.setFilterModel({
        "section": {
          "values": ["Measurement"],
          "filterType": "set"
        }
      });
      params.api.onFilterChanged();
      localStorage.removeItem(measurementLogLocalStorage);
    }
    this.firstRendered = true;
  }

  onResize($event) {
    this.setGridHeight();
  }

  setGridHeight = () => {
    if (!this.autoHeight) return;

    const grid = document.getElementById('grid');
    if (grid) {
      const gridBody = grid.querySelector('.ag-body-viewport') as any;

      const top = gridBody.getBoundingClientRect().top;
      const screenheight = window.innerHeight;
      this.tableHeight = `${(screenheight - top)}px`;
    }
  }

  doResetFilters = () => {
    if (!this.gridApi) return;
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
    this.resetAllFilter.emit();
    $("#search").val("");
    setTimeout(() => this.gridApi.setQuickFilter(""), 0);
  }

  doSave = () => {
    if (!this.gridApi) return;
    this.gridApi.stopEditing();
    setTimeout(() => {

      const { invalidRows, ...data } = this.getChanges();

      if (invalidRows.length > 0) {
        this.toaster.pop("error", "Error", data.errors.join(', '))
      } else {
        if (data.deletedRows.length > 0 || data.modifiedRows.length > 0 || data.newRows.length > 0) {
          this.save.emit(data);
          this.isSave = true;
        }
      }
    }, 0);

  }

  checkRowInvalid = (params) => {
    if (!this.gridApi) return true;
    const res = this.commonService.checkGridRowValidation(params);
    return res.errors;
  }

  getChanges = () => {
    const modifiedRows: any[] = [];
    const newRows: any[] = [];
    const deletedRows: any[] = [];
    const invalidRows: any[] = [];
    const rows: any[] = [];
    const errors: any[] = [];
    const cols = this.columnDefs && this.columnDefs.filter(c => c.cellRenderer == "silentSignature");
    this.gridApi.forEachNode((node, index) => {
      let { $row_status, ...data } = node.data;
      const validation = this.rowValidation(node);
      const cValidation: any = this.checkRowInvalid(node);
      if (cols.length > 0) {
        cols.map(c => {
          data["customFields"] = {
            ...data["customFields"],
            [c.field]: this.authService.getId()
          }
        })
      }
      if (validation.length > 0) {
        errors.push(...validation);
        invalidRows.push(data);
      }
      else if (cValidation.length > 0) {
        errors.push(...cValidation);
        invalidRows.push(data);
      }
      else if ($row_status === 'modified') modifiedRows.push(data);
      else if ($row_status === 'new') newRows.push(data);
      else if ($row_status === 'deleted') deletedRows.push(data);
      rows.push(data);
    });
    const distinctErrors = Array.from(new Set(errors));
    return { newRows, modifiedRows, deletedRows, invalidRows, rows, errors: distinctErrors }
  }

  doUndo = () => {
    this.gridApi.stopEditing(true);
    setTimeout(() => {
      if (this.undo.observers.length > 0) {
        this.undo.emit(null);
      } else {
        this.cacheData = JSON.parse(JSON.stringify(this.unchangedData));
      }
      this.toaster.pop('info', this.translate.instant('toasters.GENERAL.INFO_DISCARD_CHANGES'))
    }, 0);
  };

  doNew = () => {
    const newRow = this.dataCreator();
    if (newRow) {
      newRow.$row_status = 'new';
      this.gridApi.updateRowData({ add: [newRow] });
      setTimeout(() => {
        this.checkChanges();
      }, 0);
    }
  }

  doDelete = () => {
    if (!this.gridApi) return;
    if (this.gridApi.getSelectedRows().length <= 0) return;
    if (!this.deleteValidation({ api: this.gridApi, rows: this.gridApi.getSelectedRows() })) return false;

    this.confirm.show(this.translate.instant('confirm.GENERAL.DELETE_ROW'), { 'confirmText': this.translate.instant('Delete'), 'declineText': this.translate.instant('Cancel'), 'isDelete': true }).subscribe(result => {
      if (result != ConfirmResult.CONFIRM) return;
      const rows = this.gridApi.getSelectedRows();
      rows.forEach(row => {
        if (row.$row_status != "new") row.$row_status = "deleted";
      });
      this.gridApi.updateRowData(
        {
          update: rows.filter(row => row.$row_status === "deleted"),
          remove: rows.filter(row => row.$row_status === "new")
        });
      setTimeout(() => {
        this.checkChanges();
      }, 0);
    });
  }

  doDuplicate = () => {
    if (!this.gridApi) return;
    if (this.gridApi.getSelectedRows().length <= 0) return;

    this.confirm.show(this.translate.instant('confirm.GENERAL.ARE_YOU_SURE_DUPLICATE')).subscribe(result => {
      if (result != ConfirmResult.CONFIRM) return;
      const rows = JSON.parse(JSON.stringify(this.gridApi.getSelectedRows()));
      rows.forEach(row => {
        delete row._id;
        row.id_num = null;
        row.$row_status = "new";
      });
      this.gridApi.updateRowData({
        add: rows
      });
      setTimeout(() => {
        this.checkChanges();
      }, 0);
    });
  }

  doDuplicateRow = () => {
    if (!this.gridApi) return;
    if (this.gridApi.getSelectedRows().length <= 0) return;

    this.confirm.show(this.translate.instant('confirm.GENERAL.ARE_YOU_SURE_DUPLICATE')).subscribe(result => {
      if (result != ConfirmResult.CONFIRM) return;
      const rows = JSON.parse(JSON.stringify(this.gridApi.getSelectedRows())).map(row => row._id);
      this.duplicate.emit(rows);
    });
  }

  doInsertCol = () => {
    this.insertCol.emit(null);
  }

  doDeleteCol = () => {
    if (!this.gridApi) return;
    const sort = this.gridApi.getSortModel();

    if (sort.length > 0) {
      this.deleteCol.emit(sort);
    } else {
      this.toaster.pop("warning", this.translate.instant('toasters.GENERAL.WARN_NO_COLUMN_SELECTED'))
    }
  }

  doEditCol = () => {
    if (!this.gridApi) return;
    const sort = this.gridApi.getSortModel();

    if (sort.length > 0) {
      this.editCol.emit(sort);
    } else {
      this.toaster.pop("warning", this.translate.instant('toasters.GENERAL.WARN_NO_COLUMN_SELECTED'))
    }
  }

  doUnhideCol = () => {
    if (!this.gridApi) return;
    // this.unhideCol.emit();
  }

  doExport = () => {
    if (!this.gridApi) return;

    if (this.externalExportToExcel) {
      this.exportToExcel.emit(true);
    } else {
      const data: any[] = [];
      this.gridApi.forEachNodeAfterFilter((node) => {
        data.push(this.columnDefs.filter(c => c.colId !== this.col.colId).reduce((acc, curr) => {
          if (!curr.field || !curr.headerName) return acc;
          let value: Date | string | number = "";
          if (["datepicker", "dateonlypicker"].includes(curr.type)) {
            if (typeof node.data.customFields != 'undefined' && node.data.customFields && typeof node.data.customFields[curr.field] != 'undefined') {
              value = isArray(node.data.customFields[curr.field]) ? node.data.customFields[curr.field].join(', ') : node.data.customFields[curr.field];
            } else if (typeof node.data.pmCustomFields != 'undefined' && node.data.pmCustomFields && typeof node.data.pmCustomFields[curr.field] != 'undefined') {
              value = isArray(node.data.pmCustomFields[curr.field]) ? node.data.pmCustomFields[curr.field].join(', ') : node.data.pmCustomFields[curr.field];
            } else if (curr.field.includes('.')) {
              const props = curr.field.split('.');
              value = props.reduce((acc, curr) => acc ? acc[curr] : {}, node.data);
            } else {
              value = node.data[curr.field]
            }
            if (value) {
              if (curr.type === "dateonlypicker") {
                value = new Date(this.commonService.getConvertTableDate(value));
              } else if (curr.type === "datepicker") {
                value = new Date(this.commonService.getConvertTableDateTime(value));
              }
            }
          } else if (typeof node.data.customFields != 'undefined' && node.data.customFields && typeof node.data.customFields[curr.field] != 'undefined') {
            value = isArray(node.data.customFields[curr.field]) ? node.data.customFields[curr.field].join(', ') : node.data.customFields[curr.field];
          } else if (typeof node.data.pmCustomFields != 'undefined' && node.data.pmCustomFields && typeof node.data.pmCustomFields[curr.field] != 'undefined') {
            value = isArray(node.data.pmCustomFields[curr.field]) ? node.data.pmCustomFields[curr.field].join(', ') : node.data.pmCustomFields[curr.field];
          }
          else if (curr.valueFormatter) value = curr.valueFormatter(node)
          else if (curr.valueGetter) value = curr.valueGetter(node)
          else if (curr.field.includes('.')) {
            const props = curr.field.split('.');
            value = props.reduce((acc, curr) => acc ? acc[curr] : {}, node.data);
          }else if(curr.type  ==='array'){
            value = Array.isArray( node.data[curr.field])?  node.data[curr.field].join(','):node.data[curr.field]
          }
          else value = node.data[curr.field];
          if (value && curr.type === "numeric") value = Number(value);
          if (value && curr.type === "timeonlypicker") value = this.commonService.getTime(value);
          return { ...acc, [curr.headerName]: value }
        }, {}))
      });

      if (data.length <= 0) {
        data.push(this.columnDefs.filter(c => c.colId !== this.col.colId).reduce((acc, curr) => {
          return { ...acc, [curr.headerName]: "" }
        }, {}))
      } else {
        data.push(this.columnDefs.filter(c => c.colId !== this.col.colId).reduce((acc, curr) => {
          return { ...acc, [curr.headerName]: "" }
        }, {}))
      }

      if (["General Logs"].includes(this.tableName)) {
        this.excel.exportAsExcelFileDateTime(data, this.navService.getPath().length > 0 ? this.translate.instant(this.navService.getPath()[0].title) : this.tableName)
      } else {
        this.excel.exportAsExcelFile(data, this.navService.getPath().length > 0 ? this.translate.instant(this.navService.getPath()[0].title) : this.tableName)
      }
    }

  }

  doCut = () => {
    const editing = this.gridApi.getEditingCells();
    if (editing.length > 0) {
      this.toaster.pop('info', this.translate.instant('toasters.GENERAL.INFO_CUT'));
    }
    else {
      const cell = this.gridApi.getFocusedCell();
      const node = this.gridApi.getRowNode(cell.rowIndex);
      const value = this.getCellValue(cell.column.colDef, node);
      this.clipboard.copy(value).then(() => {
        node.setDataValue(cell.column.colId, null)
        this.toaster.pop('info', this.translate.instant('toasters.GENERAL.INFO_CUT'));

      });
    }
  }

  doCopy = () => {
    const editing = this.gridApi.getEditingCells();
    if (editing.length > 0) {
      document.execCommand('copy');
      this.toaster.pop('info', this.translate.instant('toasters.GENERAL.INFO_COPY'));
    } else {
      const cell = this.gridApi.getFocusedCell();
      const value = this.getCellValue(cell.column.colDef, this.gridApi.getRowNode(cell.rowIndex));
      this.clipboard.copy(value).then(() => {
        this.toaster.pop('info', this.translate.instant('toasters.GENERAL.INFO_COPY'));
      })
    }
  }

  doGraphCol = () => {
    if (!this.gridApi) return;
    const sort = this.gridApi.getSortModel();

    if (sort.length > 0) {
      let data: any[] = [];
      let title = "";
      this.gridApi.forEachNodeAfterFilter((node) => {
        this.columnDefs.reduce((acc, curr) => {
          if (!curr.field || !curr.headerName) return acc;
          if (curr.field == sort[0].colId) {
            title = curr.headerName;
            let value = "";
            if (typeof node.data.customFields != 'undefined' && node.data.customFields && typeof node.data.customFields[curr.field] != 'undefined') {
              value = isArray(node.data.customFields[curr.field]) ? node.data.customFields[curr.field].join(', ') : node.data.customFields[curr.field];
            } else if (typeof node.data.pmCustomFields != 'undefined' && node.data.pmCustomFields && typeof node.data.pmCustomFields[curr.field] != 'undefined') {
              value = isArray(node.data.pmCustomFields[curr.field]) ? node.data.pmCustomFields[curr.field].join(', ') : node.data.pmCustomFields[curr.field];
            }
            else if (curr.valueFormatter) value = curr.valueFormatter(node)
            else if (curr.valueGetter) value = curr.valueGetter(node)
            else if (curr.field.includes('.')) {
              const props = curr.field.split('.');
              value = props.reduce((acc, curr) => acc ? acc[curr] : {}, node.data);
            }
            else value = node.data[curr.field];
            data.push(isString(value) ? value : null);
          }
        }, {});
      });
      data = without(data, undefined, null, "");
      let counter = {};
      for (let element of data) {
        if (counter[element]) {
          counter[element] += 1;
        } else {
          counter[element] = 1;
        }
      };
      this.modal.show(ChartComponent, { initialState: { x: Object.keys(counter), y: Object.values(counter), title: title }, class: "modal-lg on-boarding-wizard-modal modal-full modal-big-width-on-small" });
    } else {
      this.toaster.pop("warning", this.translate.instant('toasters.GENERAL.WARN_NO_COLUMN_SELECTED'))
    }
  }

  doGraphGenerateCol = () => {
    if (!this.gridApi) return;
    const observable = new Observable<string[]>(observer => {
      this.modal.show(SelectColumnComponent,
        {
          keyboard: false,
          ignoreBackdropClick: true,
          initialState: {
            observer: observer,
            columns: this.columnDefs,
            tableName: this.tableName,
          },
          class: "modal-lg"
        })
    });
    observable.subscribe(async (data: string[]) => {
      this.createChart(data);
    });
  }

  doAssignColumn = () => {
    if (!this.gridApi) return;
    const sort = this.gridApi.getSortModel();

    if (sort.length > 0) {
      this.assignColumn.emit(sort);
    } else {
      this.toaster.pop("warning", this.translate.instant('toasters.GENERAL.WARN_NO_COLUMN_SELECTED'))
    }
  }

  createChart = (columns: string[]) => {
    var params: any = {
      cellRange: {
        columns: columns,
      },
      chartType: 'groupedColumn',
      chartThemeName: 'ag-vivid',
    };
    const cName = `ag-grid-chart-option-${this.tableName}`;
    const crName = `ag-grid-chart-range-selection-${this.tableName}`;
    let chartOption: any = localStorage.getItem(cName) ? localStorage.getItem(cName) : this.authService.getColumnState(cName);
    if (chartOption) {
      chartOption = JSON.parse(chartOption);
      params = { ...params, ...chartOption };
    }
    let chartRangeSelection: any = localStorage.getItem(crName) ? localStorage.getItem(crName) : this.authService.getColumnState(crName);
    if (chartRangeSelection) {
      chartRangeSelection = JSON.parse(chartRangeSelection);
      params = { ...params, ...chartRangeSelection };
    }
    this.gridApi.createRangeChart(params);
    // this.gridApi.createPivotChart(params);
  }

  doPaste = () => {

  }

  onGridReady = (event) => {
    this.gridApi = event.api;
    this.setGridHeight();

  }

  onCellUpdated($event) {

    let newData = {...$event.data};
    
    if ((Array.isArray($event.newValue) && (!$event.newValue || !$event.oldValue || $event.newValue.length != $event.oldValue.length || $event.newValue.some(val => $event.oldValue.indexOf(val) === -1))) ||
      (!Array.isArray($event.newValue) && (this.nullString($event.newValue) != this.nullString($event.oldValue)))) {
        newData.$row_status = newData.$row_status ? newData.$row_status : 'modified';
    }
    if($event.colDef.colId === 'normalizedName'){
      newData.isOk = true;
    }
    $event.node.setData(newData)
    

    setTimeout(() => {
      this.onRowDataChanged($event);
    }, 0)
  }

  nullString = val => val === null || typeof val === "undefined" ? "" : val;

  quickSearch($event) {
    if (this.gridApi) {
      this.gridApi.setQuickFilter($event.target.value);
    }
  }

  quickSearchByText(text) {
    if (this.gridApi) {
      this.gridApi.setQuickFilter(text);
    }
  }

  doSearchMore(value) {
    this.searchMore.emit(value);
  }

  onRowDataChanged = (event) => {
    if (!this.gridApi) this.gridApi = event.api;
    $('.filtered-count-records').text(this.gridApi.getDisplayedRowCount());
    var allColumnIds = [];
    this.gridApi.columnController.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridApi.columnController.columnApi.autoSizeColumns(allColumnIds);

    setTimeout(() => {
      this.checkChanges();
    }, 0);
  }

  getCellValue = (column, node) => {
    let value = "";
    if (column.valueFormatter) value = column.valueFormatter(node)
    else if (column.valueGetter) value = column.valueGetter(node)
    else if (column.field.includes('.')) {
      const props = column.field.split('.');
      value = props.reduce((acc, curr) => acc ? acc[curr] : {}, node.data);
    }
    else value = node.data[column.field];
    return value;
  }

  checkChanges = () => {
    const data = this.getChanges();
    this.disableUndo = data.deletedRows.length === 0 && data.invalidRows.length === 0 && data.modifiedRows.length === 0 && data.newRows.length === 0;
  }

  createFlagImg = (flag) => {
    return (
      '<img border="0" width="15" height="10" src="https://flags.fmcdn.net/data/flags/mini/' +
      flag +
      '.png"/>'
    );
  }
  getContextMenuItems = (params) => {
    var result = [
      'copy',
      'chartRange',
    ];
    return result;
  }

  rowDoubleClicked(event) {
    this.gridApi.startEditingCell({
      rowIndex: event.rowIndex,
      colKey: event.colDef ? event.colDef.field : null,
    });
  }

  ngAfterContentChecked(): void {
    if ($(".ag-virtual-list-container").height() && $(".ag-virtual-list-container").height() < 120) {
      $(".ag-virtual-list-container").css('height', 120);
    }
  }

  onSelectionChanged(event) {
    this.selectionChanged.emit(event);
  }

}
