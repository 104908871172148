import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoaderService } from "../utils/loader.service";
import { TranslateService } from "@ngx-translate/core";


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private LOADING_MESSAGE = {
    }

    constructor(
        public loader: LoaderService,
        private translate: TranslateService
    ) {
        this.LOADING_MESSAGE = this.translate.instant('loaders');
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const pusher = localStorage.getItem('_at_pusher');
        const showLoader:boolean = !req.url.includes('/check_company/') && !req.url.includes('/api/parts?time=') && !req.url.includes('/api/user/update-state') && !req.url.includes('/api/events/search-problem-description') && !req.url.includes('/api/maintenance/update-next-pms') && !req.url.includes('/api/add-click') && !pusher && !req.url.includes('/api/download/signed-url/') && !req.url.includes('/api/download/file/');
        let message = this.urlMessage(req.url) || this.LOADING_MESSAGE[req.method] || this.LOADING_MESSAGE['DEFAULT'];
        if (req.url==='/api/login') message = this.LOADING_MESSAGE['DEFAULT'];

        showLoader && this.loader.add(message);
        return next.handle(req)
            .pipe(
                finalize(() => showLoader && this.loader.remove(message)
                )
            );
    }

    urlMessage = url =>{
        switch (url){
            case '/api/login':
                return this.LOADING_MESSAGE['DEFAULT'];
            default:
                return null;
        }
    }


}
