import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../core/utils/loader.service';
import { trigger, transition, style, animate, state } from '@angular/animations';
@Component({
  selector: 'app-loader',
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(500)),
    ]),
  ],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  public loading;
  constructor(private loaderService: LoaderService) { }

  ngOnInit() {
    this.loaderService.getLoader().subscribe(loading => {
      this.loading = loading;
    })
  }

  getMessage() {
    if (this.loading.length === 0) return "";

    const message = this.loading[this.loading.length - 1];
    return message || "Please wait..."
  }

}
