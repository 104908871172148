<div class="wrapper">
  <div class="block-center">
    <div class="logo text-center">
      <img src="assets/img/Logo land - Regular White@3x.png" alt="logo" class="brand-logo" />
    </div>
    <div class="card card-flat">
      <div class="">
        <div class="log-in-header">
          <h3 class="log-in-paragraph text-center">AnyMaint’s Data Processing Addendum</h3>
        </div>
        <div class="line"></div>
        <div class="block">
          <div class="text-center">
            <h3><u>Annex B</u></h3>
            <h4><u>Data Processing Addendum</u></h4>
          </div>
          <div class="content pl-4 pr-4">
            <app-dpa-content></app-dpa-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>