import { Directive, ElementRef, TemplateRef, ViewContainerRef, OnInit, Input } from '@angular/core';
import { User } from '../../routes/user/login.response';
// import { AuthService } from '../../routes/user/auth.service';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../routes/user/auth.service';

@Directive({
  selector: '[hideUnauthorized]'
})
export class HideUnauthorizedDirective implements OnInit {
  private user:User;
  private elseTemplate: TemplateRef<any>;

  private permissions:[] = [];

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private auth: AuthService,
  ) {

  }

  ngOnInit(){
    this.auth.user$.subscribe(user =>{
      this.user = user;
      this.updateView();
    });

    this.auth.roleUpdate$.subscribe(user =>{
      this.user = user;
      this.updateView();
    });
  }

  @Input()
  set hideUnauthorized(val) {
    this.permissions = val;
    this.updateView();
  }

  @Input()
  set hideUnauthorizedElse(val: TemplateRef<any>){
    this.elseTemplate = val;
    this.updateView();
  }

  private updateView = () =>{

    const match = this.permissions.some(
      permission=>this.user && this.user.roles.some(role => permission===role)
    );

    this.viewContainer.clear();
    if(match){
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    else if (this.elseTemplate){
        this.viewContainer.createEmbeddedView(this.elseTemplate);

    }
  }
}
