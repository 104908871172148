import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICustomField } from '../../interfaces/custom-field';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldsBackendService {
  url = '/api/custom-fields';
  constructor(private http: HttpClient) { }

  list(){
    return this.http.get<Array<ICustomField>>(this.url)
  }

  listAll(){
    return this.http.get<Array<ICustomField>>(`${this.url}?show=all`)
  }

  create(data: ICustomField) {
    return this.http.post(this.url, data);
  }

  update(data: ICustomField){
    return this.http.put(`${this.url}/${data.table}/${data.name}`, data);
  }

  updateField(data: any){
    return this.http.post(`${this.url}/update-fields`, data);
  }

  delete(table:string,name:string){
    return this.http.delete(`${this.url}/${table}/${name}`);
  }
}
