import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Maintenance } from '../../../../core/interfaces/maintenance';
import { CommonService } from '../../../../core/services/common.service';
import { TranslationsStoreService } from '../../../../core/services/translations-store.service';

@Component({
  selector: 'app-pm-checklist-detail',
  templateUrl: './pm-checklist-detail.component.pug',
  styleUrls: ['./pm-checklist-detail.component.scss']
})
export class PmChecklistDetailComponent implements OnInit {

  maintenance:Maintenance;
  lightScrollbarOptions = this.commonService.getLightScrollbarOptions();

  constructor(
    public bsModalRef: BsModalRef,
    public translationService: TranslationsStoreService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
  }

}
