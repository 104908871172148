import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './core/auth/token.interceptor';
import { AppComponent } from './app.component';

import { AppLoadModule } from './app-load/app-load.module';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { ToasterModule } from 'angular2-toaster';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoaderComponent } from './layout/loader/loader.component';
import { LoaderInterceptor } from './core/interceptors/loader.interceptor';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';
import { WINDOW_PROVIDERS } from './window.providers';
import { WindowsService } from './core/services/windows.service';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './core/auth/timeout.interceptor';


// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    LandingPageComponent
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    AppLoadModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule.forRoot({
    }),
    SharedModule.forRoot(),
    ToasterModule.forRoot(),
    RoutesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgAddToCalendarModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    WINDOW_PROVIDERS,
    WindowsService,
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 36000000 }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
