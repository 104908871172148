import { ruleSelector, ruleSelectorColor } from './../../../core/constants/rule-selector';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BodyOutputType, Toast, ToasterService } from 'angular2-toaster';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { combineLatest, Observer } from 'rxjs';
import { pairwise } from 'rxjs/operators';
import { newFieldDropDown } from '../../../core/constants/new-field-dropdown';
import { newFieldSection } from '../../../core/constants/new-field-section';
import { CommonService } from '../../../core/services/common.service';
import { CustomFieldsStoreService } from '../../../core/services/custom-fields-store.service';
import { DepartmentsStoreService } from '../../../core/services/departments-store.service';
import { DynamicDropdownStoreService } from '../../../core/services/dynamic-dropdown-store.service';
import { EventsStoreService } from '../../../core/services/events-store.service';
import { FilesStoreService } from '../../../core/services/files-store.service';
import { MachineStatusesStoreService } from '../../../core/services/machine-statuses-store.service';
import { MaintenanceTypesStoreService } from '../../../core/services/maintenance-types-store.service';
import { MenuStoreService } from '../../../core/services/menu-store.service';
import { PartsStoreService } from '../../../core/services/parts-store.service';
import { PreventiveStoreService } from '../../../core/services/preventive-store.service';
import { ToolsStoreService } from '../../../core/services/tools-store.service';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';
import { UsersStoreService } from '../../../core/services/users-store.service';
import { AuthService } from '../../../routes/user/auth.service';
import { ModalResult } from '../../models/modal-result';

@Component({
  selector: 'app-add-column',
  templateUrl: './add-column.component.pug',
  styleUrls: ['./add-column.component.scss']
})
export class AddColumnComponent implements OnInit {

  form: UntypedFormGroup;
  observer: Observer<ModalResult>
  table: string
  types: any;
  sections: any;
  fields: any = [];

  isShowSection: boolean = false;
  isShowField: boolean = false;
  maxRuleLength = 2;
  rules = [];
  rSelector: any
  rSelectorColor: any
  chartType: any
  needToShowRule: boolean = false;
  needToShowWhatsAppSend: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    public fb: UntypedFormBuilder,
    private commonService: CommonService,
    public authService: AuthService,
    private toolService: ToolsStoreService,
    private preventiveStoreService: PreventiveStoreService,
    private eventsStoreService: EventsStoreService,
    private partsStoreService: PartsStoreService,
    private filesStoreService: FilesStoreService,
    private usersStoreService: UsersStoreService,
    private maintenanceTypesStoreService: MaintenanceTypesStoreService,
    private machineStatusesStoreService: MachineStatusesStoreService,
    private departmentsStoreService: DepartmentsStoreService,
    public translationService: TranslationsStoreService,
    private translate: TranslateService,
    private menuStoreService: MenuStoreService,
    private dynamicDropdownStoreService: DynamicDropdownStoreService,
    private customFieldsService: CustomFieldsStoreService,
    private toaster: ToasterService,
  ) { }

  ngOnInit() {

    this.rSelector = Object.keys(ruleSelector).reduce((acc, key) => {
      acc.push({ id: key, text: ruleSelector[key] });
      return acc;
    }, []);

    this.rSelectorColor = Object.keys(ruleSelectorColor).reduce((acc, key) => {
      acc.push({ id: key, text: ruleSelectorColor[key] });
      return acc;
    }, []);

    this.types = Object.keys(newFieldDropDown).reduce((acc, key) => {
      if (key == 'smart-dropdown') {
        if (this.commonService.hasSmartDropDownPermission()) acc.push({ id: key, text: this.translate.instant(newFieldDropDown[key]) });
      } else {
        acc.push({ id: key, text: this.translate.instant(newFieldDropDown[key]) });
      }
      return acc;
    }, []);

    this.menuStoreService.menus$.subscribe(menus => {
      const m = menus.toArray().filter(m => m.type === 1).map((menu) => {
        return { id: menu.key, text: (this.translate.instant(menu.translationKey) != menu.translationKey ? this.translate.instant(menu.translationKey) : menu.name) }
      })
      this.sections = Object.keys(newFieldSection).reduce((acc, key) => {
        acc.push({ id: key, text: this.translate.instant(newFieldSection[key]) });
        return acc;
      }, []).concat(m);
    });

    this.form = this.fb.group({
      label: this.fb.control('', Validators.required),
      type: this.fb.control('', Validators.required),
      sectionName: this.fb.control(''),
      fieldName: this.fb.control(''),
      multipleSelection: this.fb.control(false),
      chartDataType: this.fb.control(null),
      required: this.fb.control(false),
      dontPresentOnModal: this.fb.control(false),
      preventEditOnModal: this.fb.control(false),
      displayOnBothModal: this.fb.control(false),
      sendWhatsAppTo: this.fb.control(false),
    });
    this.form.get('type')
      .valueChanges
      .pipe(pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (next == "numeric") {
          this.needToShowRule = true;
        } else {
          this.needToShowRule = false;
        }
        if (next == 'smart-dropdown') {
          this.form.controls.sectionName.setValidators([Validators.required])
          this.form.controls.fieldName.setValidators([Validators.required])
          this.isShowSection = true;
        } else {
          this.form.controls.sectionName.setValidators(null)
          this.form.controls.fieldName.setValidators(null)
          this.isShowSection = false;
          this.isShowField = false;
          this.form.controls.sectionName.setValue('');
          this.form.controls.fieldName.setValue('');
        }
      });
    this.form.get('sectionName')
      .valueChanges
      .pipe(pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (next) {
          this.isShowField = true;
          switch (next) {
            case 'tool':
              this.toolService.columns$.subscribe(data => {
                this.reduceFieldsValue(data);
              })
              break;
            case 'pm':
              this.preventiveStoreService.columns$.subscribe(data => {
                this.reduceFieldsValue(data);
              })
              break;
            case 'history':
              this.eventsStoreService.columns$.subscribe(data => {
                this.reduceFieldsValue(data);
              })
              break;
            case 'inventory':
              this.partsStoreService.columns$.subscribe(data => {
                this.reduceFieldsValue(data);
              })
              break;
            case 'files':
              this.filesStoreService.columns$.subscribe(data => {
                this.reduceFieldsValue(data);
              })
              break;
            case 'users':
            case 'workers':
              this.usersStoreService.columns$.subscribe(data => {
                this.reduceFieldsValue(data);
              })
              break;
            case 'pm-kind':
              this.maintenanceTypesStoreService.columns$.subscribe(data => {
                this.reduceFieldsValue(data);
              })
              break;
            case 'machine-status':
              this.machineStatusesStoreService.columns$.subscribe(data => {
                this.reduceFieldsValue(data);
              })
              break;
            case 'departments':
              this.departmentsStoreService.columns$.subscribe(data => {
                this.reduceFieldsValue(data);
              })
              break;
            default:
              this.reduceFieldsValue(next, 1);
          }
        }
      });
    this.form.controls.type.setValue('textbox');
    this.form.controls.sectionName.setValue('');
    this.form.get('fieldName')
      .valueChanges
      .subscribe((value: string) => {
        this.checkNeedToSendWhatsapp(value);
      });
  }

  reduceFieldsValue(data, isDyanamic = 0) {
    if (isDyanamic) {
      combineLatest([this.dynamicDropdownStoreService.columns$, this.customFieldsService.get(data)])
        .subscribe(([cols, columns]) => {
          const fields = columns.toArray();
          const custom = this.customFieldsService.toColumnDef(fields, {
            format: this.customFieldsService.formatCellCustomField.bind(this)
          });
          this.fields = cols.concat(custom).filter(d => !d.hide).reduce((acc, key) => {
            acc.push({ id: key.colId, text: key.headerName });
            return acc;
          }, [])
        });
    } else {
      this.fields = data.filter(d => !d.hide).reduce((acc, key) => {
        acc.push({ id: key.colId, text: key.headerName });
        return acc;
      }, []);
    }
  }

  checkNeedToSendWhatsapp(field: string) {
    if(["events", "pm"].includes(this.table) && ["users", "workers"].includes(this.form.value.sectionName) && ["username", "firstName", "lastName", "fullName"].includes(field)) {
      this.needToShowWhatsAppSend = true;
    }else{
      this.needToShowWhatsAppSend = false;
      this.form.controls.sendWhatsAppTo.setValue(false);
    }
  }

  save = () => {
    if (this.form.valid) {
      let exist = null;
      if (this.table == 'events') {
        exist = this.customFieldsService.getList().find(cf => (cf.table == this.table || cf.table == 'pm') && cf.label.trim() == this.form.value.label.trim());
      } else {
        exist = this.customFieldsService.getList().find(cf => cf.table == this.table && cf.label.trim() == this.form.value.label.trim());
      }
      if (exist) {
        this.toaster.pop('error', this.translate.instant('toasters.CUSTOM_FIELDS.CANNOT_SAME_IN_SECTION'))
      } else {
        let errors = [];
        if (this.needToShowRule && this.rules.length > 0) {
          this.rules.forEach(r => {
            if (!r.selector) errors.push("add-column.SELECTOR-IS-REQUIRED")
            if (!r.value) errors.push("add-column.VALUE-IS-REQUIRED")
            if (!r.color) errors.push("add-column.ALERT-COLOR-IS-REQUIRED")
          });
        }
        if (errors.length > 0) {
          const toast: Toast = {
            type: 'error',
            title: '',
            body: errors.join(' <br/> '),
            bodyOutputType: BodyOutputType.TrustedHtml,
            timeout: 5000
          };
          this.toaster.pop(toast);
        } else {
          const result = {
            result: true,
            payload: { ...this.form.value, rules: this.rules }
          }
          this.bsModalRef.hide();
          this.observer.next(result);
        }
      }
    }
  }

  addRule = ($event) => {
    if (this.rules.length <= this.maxRuleLength) {
      this.rules.push({
        selector: '',
        value: '',
        color: 'none',
      });
    }
  }

  removeRule = ($event, index) => {
    this.rules.splice(index, 1);
  }

  onKeyDown = ($event) => {
    if ($event.keyCode === 69) return false;
  }

  toggleChartType = ($event) => {
    if($event.target.checked){
      this.chartType = $event.target.value;
    }else{
      this.chartType = null;
    }
    this.form.controls.chartDataType.setValue(this.chartType);
  }

}
