import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Procedure } from '../../interfaces/procedure';

@Injectable({
  providedIn: 'root'
})
export class ProcedureBackendService {

  url = '/api/procedures';

  constructor(private http: HttpClient) { }

  list() {
    return this.http.get<Array<Procedure>>(this.url)
  }

  options() {
    return this.http.get<Array<Procedure>>(`${this.url}/options`)
  }

  // listProceduresDetails() {
  //   return this.http.get<Array<ProcedureDetail>>(`${this.url}/procedures-m`)
  // }

  listAll() {
    return this.http.get<Array<Procedure>>(`${this.url}?show=all`)
  }

  create(data: Procedure) {
    return this.http.post(this.url, data);
  }

  copyPasteProcedure(data: {name: string, tasks: string, isPM: boolean}) {
    return this.http.post(`${this.url}/copy-paste-procedure`, data);
  }

  update(data: Procedure) {
    return this.http.put(`${this.url}/${data._id}`, data);
  }

  delete(data: Procedure) {
    return this.http.delete(`${this.url}/${data._id}`);
  }
}
