import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-refresh',
  templateUrl: './refresh.component.pug',
  styleUrls: ['./refresh.component.scss']
})
export class RefreshComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    if(this.auth.getUser()){
      this.auth.updateLogout(3).subscribe(() => {
        this.clearData();
      })
    }else{
      this.clearData();
    }
  }

  clearData = () => {
    localStorage.clear();
    this.router.navigate(['/login'])
  }

}
