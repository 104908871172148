import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { AuthService } from '../../routes/user/auth.service';
import { map } from 'rxjs/operators';
import { CommonService } from '../services/common.service';
import { MenuStoreService } from '../services/menu-store.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsStoreService } from '../services/translations-store.service';

@Injectable()
export class MenuService {

    menuItemsSubject$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    menuItems$: Observable<any[]>
    constructor(
        private auth: AuthService,
        private commonService: CommonService,
        private menuStoreService: MenuStoreService,
        private translate: TranslateService,
        private translationsStoreService: TranslationsStoreService,
    ) {
        this.menuItems$ = combineLatest([this.auth.user$, this.menuItemsSubject$, this.menuStoreService.menus$, this.translationsStoreService.translations$, this.auth.refreshUser$]).pipe(
            map(([user, menuItems, menus, translations, refreshUser]) => {
                return menuItems.filter(m => {
                    if(m.link == '/smart-dropdown'){
                        return this.commonService.hasSmartDropDownPermission();
                    }else if (m.link == '/main/calendar'){
                        return this.commonService.hasCalendarPermission();
                    }
                    return true;
                }).filter(this.checkPermission(user)).map(menuItem => {
                    let menu = [];
                    if(menuItem.link == '/smart-dropdown'){
                        const dyanmicMenus = menus.toArray().map(v => {
                            return {
                                text: typeof v.translationKey != 'undefined' && this.translate.instant(v.translationKey) != v.translationKey ? this.translate.instant(v.translationKey) : v.name,
                                link: v.type == 1 ? ('/smart-dropdown/'+v.key) : ('/smart-report/'+v.key),
                                permissions: ["admin"]
                            };
                        });
                        menu = dyanmicMenus.concat(menuItem.submenu.filter(this.checkPermission(user)));
                    }else{
                        const subMenu = menuItem.submenu ? menuItem.submenu.filter(this.checkPermission(user)) : undefined;
                        if (subMenu) {
                            subMenu.forEach((sv, sk) => {
                                switch (sv.link) {
                                    case "/tables/pm":
                                        if (this.commonService.hasPmCalenderPermission()) menu.push({
                                            text: 'menu.PM-CALENDAR',
                                            link: '/admin/pm-calendar',
                                        });
                                        else menu.push(sv);
                                        break;
                                    case "/admin/workers":
                                        if (this.commonService.hasBigTeamPermission()) menu.push(sv);
                                        break;
                                    case "/admin/drawing-definitions":
                                        if (this.commonService.hasDrawingModePermission()) menu.push(sv);
                                        break;
                                    case "/admin/ip-addresses":
                                        if (this.commonService.hasIpAddressPermission()) menu.push(sv);
                                        break;
                                    case "/admin/procedures":
                                    case "/admin/tasks":
                                        if (this.commonService.hasProceduresAndTasksPermission()) menu.push(sv);
                                        break;
                                    case "/tables/inventory":
                                        if (this.commonService.hasInventoryPermission()) menu.push(sv);
                                        break;
                                    case "/admin/procedures-suggestions":
                                        if (this.commonService.hasAllowCollaboardativeMaintenance()) menu.push(sv);
                                        break;
                                    case "/admin/subsystem-table":
                                        if (this.commonService.hasAllowCollaboardativeMaintenance()) menu.push(sv);
                                        break;
                                    case "/admin/units":
                                        if (this.commonService.hasMeasurementsPermission() || this.commonService.hasInventoryPermission()) menu.push(sv);
                                        break;
                                    case "/admin/data-formats":
                                    case "/tables/measurements":
                                    case "/tables/measurement-results":
                                        if (this.commonService.hasMeasurementsPermission()) menu.push(sv);
                                        break;
                                    default:
                                        menu.push(sv);
                                }
                            });
                        }
                    }
                    return ({
                        ...menuItem,
                        submenu: menu.length > 0 ? menu : undefined
                    })
                })
            })
        )
    }

    checkPermission = user => item => {
        if (!user) return false;
        if (item.permissions) {
            return item.permissions.some(permission => user.roles.includes(permission))
        } else {
            return true;
        }
    }

    setMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>
    }>) {
        const menu: any[] = []
        items.forEach((item) => {
            menu.push(item);
        });

        this.menuItemsSubject$.next(menu);
    }

    getValue() {
        return this.menuItemsSubject$.getValue();
    }
}
