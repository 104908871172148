import { SelectFileComponent } from './../select-file/select-file.component';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { FilesStoreService } from '../../../core/services/files-store.service';

@Component({
  selector: 'app-boolean-renderer',
  template: `
    <div style="display: flex; align-items: center;">
      <img src="assets/img/right.png" width="22px" *ngIf="value">
      <img src="assets/img/close.png" *ngIf="!value">
    </div>
    `
})

export class BooleanRendererComponent implements ICellRendererAngularComp {
  params;
  value;
  @ViewChild('uploadctl') uploadctl: ElementRef;

  constructor(
    private filesStoreService: FilesStoreService,
    private modal: BsModalService,
  ) {
  }

  agInit(params): void {
    this.params = params;
    this.value = this.params.value;
  }

  refresh(params?: any): boolean {
    return false;
  }

  getValue(): any {
    return this.params.value;
  }
}