import { SECTION_NAME } from './enums/enums';
export const reportSection = {
    [SECTION_NAME.TOOL]: 'menu.TOOLS',
    [SECTION_NAME.PM]: 'menu.PM',
    [SECTION_NAME.HISTORY]: 'menu.HISTORY',
    [SECTION_NAME.INVENTORY]: 'menu.INVENTORY',
    [SECTION_NAME.TRANSACTION]: 'table.TRANSACTIONS',
    [SECTION_NAME.TASK_TRANSACTION]: 'menu.TASK-TRANSACTIONS',
    [SECTION_NAME.USER_LOGS]: 'menu.USERS-LOGS',
    [SECTION_NAME.SIGNATURE_AT]: 'menu.SIGNATURE-AT',
    [SECTION_NAME.HISTORY_AT]: 'menu.HISTORY-AT',
    [SECTION_NAME.SLA]: 'menu.SLA',
};

export const reportSectionKeywords = {
    'none': 'ADD-NEW-REPORT.NONE',
    'search': 'ADD-NEW-REPORT.SEARCH',
    'range': 'ADD-NEW-REPORT.RANGE',
    '>': '>',
    '>=': '>=',
    '<': '<',
    '<=': '<=',
    '=': '=',
};

export const reportRangeSectionKeywords = [
    'range',
    '>',
    '>=',
    '<',
    '<=',
];

export const toolNumberRange = [
    'id_num',
    'priorityOrder'
];

export const pmNumberRange = [
    'id_num',
    'tool.id_num',
    'lastMaintenance',
    'nextMaintenance'
];

export const eventNumberRange = [
    'id_num',
    'tool.id_num',
    'start',
    'finish',
    'scheduleWork',
];

export const inventoryNumberRange = [
    'id_num',
    'stockQuantity',
    'changeStockQuantity',
    'oldStockQuantity',
    'minimalQuantity',
    'tool.id_num',
    'updatedAt',
];

export const transactionNumberRange = [
    'id_num',
    'part.id_num',
    'stockQuantity',
    'changeStockQuantity',
    'oldStockQuantity',
    'minimalQuantity',
    'tool.id_num',
    'updatedAt',
];

export const taskTransactionNumberRange = [
    'id_num',
    'task.id_num',
    'task.tool.id_num',
    'task.group.id_num',
    'event.id_num',
    'tool.id_num',
    'worker.id_num',
    'user.id_num',
    'updatedAt',
];

export const userLogsNumberRange = [
    'id_num',
    'login',
    'logout'
];

export const signatureAtNumberRange = [
    'id_num',
    'checklistItems._id',
    'user.id_num',
    'createdAt',
    'event.id_num',
    'tool.id_num'
];

export const historyAtNumberRange = [
    'currentData.id_num',
    'tool.id_num',
    'currentData.start',
    'currentData.finish',
    'currentData.scheduleWork',
    'createdAt'
];

export const slaNumberRange = [
    'id_num',
    'tool.id_num',
    'start',
    'finish',
    'updatedAt',
];