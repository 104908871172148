import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {

  display: boolean = false;
  constructor() { }

  ngOnInit() {
    const cookie = localStorage.getItem('_cookie_statement');
    if (!cookie) this.display = true;
  }

  close = ($event) => {
    localStorage.setItem('_cookie_statement', 'true');
    this.display = false;
  }

}
