import { IField } from "../../interfaces/field";
import { machineStatusTypes } from "../machine-statuses";

const FieldDefinition: IField[] = [
    {
        name: 'id_num',
        label: 'table.machine-status.table-title.MACHINE-STATUS-ID',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'company.name',
        label: 'table.machine-status.table-title.COMPANY',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'severity',
        label: 'table.machine-status.table-title.TYPE',
        type: 'dropdown',
        options: machineStatusTypes.map(status => ({ key: status.severity, value: status.name })),
        required: true,
        readonly: false
    },
    {
        name: 'title',
        label: 'table.machine-status.table-title.TITLE',
        type: 'textbox',
        required: true,
        readonly: false
    },
    {
        name: 'mesTitle',
        label: 'table.machine-status.table-title.MES-TITLE',
        type: 'textbox',
        required: false,
        readonly: false
    },
    {
        name: 'toolObject.id_num',
        label: "table.machine-status.table-title.RELATED-TOOL-ID",
        type: 'textbox',
        required: false,
        readonly: true,
        valueGetter: params => (params.data) && params.data.toolObject && Object.keys(params.data.toolObject).length > 0 ? params.data.toolObject.map(d => d.id_num).join(',') : '',
    },
    {
        name: 'tool',
        label: "table.machine-status.table-title.RELATED-TOOL-NAME",
        type: 'customMultipleDropdown',
        required: false,
        readonly: false
    },
    {
        name: 'groupObject.id_num',
        label: "table.machine-status.table-title.RELATED-GROUP-ID",
        type: 'textbox',
        required: false,
        readonly: true,
        valueGetter: params => (params.data) && params.data.groupObject && Object.keys(params.data.groupObject).length > 0 ? params.data.groupObject.map(d => d.id_num).join(',') : '',
    },
    {
        name: 'group',
        label: "table.machine-status.table-title.RELATED-GROUP-NAME",
        type: 'customMultipleDropdown',
        required: false,
        readonly: false
    },
    {
        name: 'translationKey',
        label: "table.machine-status.table-title.TRANSLATION-KEY",
        type: 'textbox',
        required: false,
        readonly: true
    },
    {
        name: 'worker',
        label: "table.machine-status.table-title.WORKER-DEPARTMENT",
        type: 'selectdropdown',
        required: false,
        readonly: false,
        section: 'machine-status',
    },
    {
        name: 'frequency',
        label: 'table.machine-status.table-title.FREQUENCY',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'machine-status',
    },
    {
        name: 'isDeleted',
        label: 'general.DELETED',
        type: "checkbox",
        readonly: false,
        isAdmin: true,
    }
]

export default {
    FieldDefinition
}