import { User } from "../../routes/user/login.response";
import { KeyWord } from "./keywords";
import { Menu } from "./menu";
import { Part } from "./part";
import { Site } from "./site";

export interface BaseToolDragging {
  tool: string;
  group: string;
  isPointer: boolean;
  url: string;
}

export interface ToolDragging {
  tools: BaseToolDragging[];
  group: string | null;
}

export class Tool {
  _id?: string;
  id_num?: number;
  company?: string;
  name?: string;
  code?: string;
  group?: Tool;
  custom?: any;
  createdAt?: Date;
  updatedAt?: Date;
  image?: string;
  qrCode?: string;
  isGroup?: boolean;
  groupId?: string;
  children?: number;
  totalChildren?: number;
  problems?: number;
  imageUrl?: string;
  customFields?: Object;
  isDeleted?: boolean;
  priorityOrder?: number;
  site?: string;
  siteObject?: Site;
  keywords?: string[];
  keywordsObject?: KeyWord[];
  critical?: boolean;
  safety?: boolean;
  drawing?: boolean;
  lastUpdatedEvent?: Date;
  sendTo?: any;
  sendToObject?: User[];
  top?: number;
  left?: number;
  height?: number;
  width?: number;
  rotate?: number;
  color?: string;
  textColor?: string;
  borderColor?: string;
  opacity?: number;
  transparent?: boolean;
  isTextOnTransparent?: boolean;
  hideDataInGraphs?: boolean;
  customTable?: any;
  customTableObject?: Menu[];
  groupStatus?: any;
  isSubSystem?: boolean;
  tool?: string;
  toolObject?: Tool;
  inventoryParts?: string[];
  inventoryPartsObject?: Part[];
  pointers?: string[];
  pointersObject?: Tool[];

  constructor(options:{
    _id?: string,
    id_num?: number,
    company?: string,
    name?: string,
    code?: string,
    group?: Tool,
    custom?: any,
    createdAt?: Date,
    updatedAt?: Date,
    image?: string,
    qrCode?: string,
    isGroup?: boolean,
    groupId?: string,
    children?: number,
    totalChildren?: number,
    problems?: number,
    imageUrl?: string,
    customFields?: Object,
    isDeleted?: boolean,
    priorityOrder?: number,
    site?: string,
    siteObject?: Site,
    keywords?: string[],
    keywordsObject?: KeyWord[],
    critical?: boolean,
    safety?: boolean,
    drawing?: boolean,
    lastUpdatedEvent?: Date,
    sendTo?: any,
    sendToObject?: User[],
    top?: number,
    left?: number,
    height?: number,
    width?: number,
    rotate?: number,
    color?: string,
    textColor?: string,
    borderColor?: string,
    opacity?: number,
    transparent?: boolean,
    isTextOnTransparent?: boolean,
    hideDataInGraphs?: boolean,
    customTable?: any,
    customTableObject?: Menu[],
    groupStatus?: any,
    isSubSystem?: boolean,
    tool?: string,
    toolObject?: Tool,
    inventoryParts?: string[],
    inventoryPartsObject?: Part[],
    pointers?: string[],
    pointersObject?: Tool[],
  }){
    this._id = options._id || undefined;
    this.id_num = options.id_num || undefined;
    this.company = options.company || null;
    this.name = options.name || null;
    this.code = options.code || null;
    this.group = options.group || null;
    this.custom = options.custom || null;
    this.createdAt = options.createdAt || null;
    this.updatedAt = options.updatedAt || null;
    this.image = options.image || null;
    this.qrCode = options.qrCode || null;
    this.isGroup = options.isGroup || false;
    this.groupId = options.groupId || null;
    this.children = options.children || 0;
    this.totalChildren = options.totalChildren || 0;
    this.problems = options.problems || 0;
    this.imageUrl = options.imageUrl || null;
    this.customFields = options.customFields || {};
    this.isDeleted = options.isDeleted || false;
    this.priorityOrder = options.priorityOrder || null;
    this.site = options.site || null;
    this.siteObject = options.siteObject || null;
    this.keywords = options.keywords || null;
    this.keywordsObject = options.keywordsObject || null;
    this.critical = options.critical || false;
    this.safety = options.safety || false;
    this.drawing = options.drawing || false;
    this.lastUpdatedEvent = options.lastUpdatedEvent || null;
    this.sendTo = options.sendTo || null;
    this.sendToObject = options.sendToObject || null;
    this.top = options.top || null;
    this.left = options.left || null;
    this.height = options.height || null;
    this.width = options.width || null;
    this.rotate = options.rotate || 0;
    this.color = options.color || '#fff';
    this.textColor = options.textColor || '#000';
    this.borderColor = options.borderColor || '#000';
    this.opacity = options.opacity || 0.8;
    this.transparent = options.transparent;
    this.isTextOnTransparent = options.isTextOnTransparent || false;
    this.hideDataInGraphs = options.hideDataInGraphs;
    this.customTable = options.customTable || null;
    this.customTableObject = options.customTableObject || null;
    this.groupStatus = options.groupStatus || null;
    this.isSubSystem = options.isSubSystem || false;
    this.tool = options.tool || null;
    this.toolObject = options.toolObject || null;
    this.inventoryParts = options.inventoryParts || null;
    this.inventoryPartsObject = options.inventoryPartsObject || null;
    this.inventoryParts = options.inventoryParts || null;
    this.pointers = options.pointers || [];
    this.pointersObject = options.pointersObject || [];
  }

}
