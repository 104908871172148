<!-- <span>&copy; {{settings.getAppSetting('year')}} - {{ settings.getAppSetting('name') }}</span> -->

<div class="footer">
    <ul class="">
        <li class="" [routerLinkActive]="['active']">
            <a [routerLink]="'/main/dashboard'" [attr.route]="'/main/dashboard'">
                <div class="footer_section">
                    <div class="footer_icon">
                        <img src="assets/img/dashboard.svg" alt="img">
                    </div>
                    <span class="text-decoration-none">{{ "menu.DASHBOARD" | translate }}</span>
                </div>
            </a>
        </li>
        <li class="" [routerLinkActive]="['active']">
            <a [routerLink]="'/main/work-orders'" [attr.route]="'/main/work-orders'">
                <div class="footer_section">
                    <div class="footer_icon">
                        <img src="assets/img/work-orders.svg" alt="img">
                    </div>
                    <span class="text-decoration-none">{{ "menu.WORK-ORDERS" | translate }}</span>
                </div>
            </a>
        </li>
        <li class="" [routerLinkActive]="['active']">
            <a [routerLink]="'/main/maintenance'" [attr.route]="'/main/maintenance'">
                <div class="footer_section">
                    <div class="footer_icon">
                        <img src="assets/img/maintenance.svg" alt="img">
                    </div>
                    <span class="text-decoration-none">{{ "menu.MAINTENANCE" | translate }}</span>
                </div>
            </a>
        </li>
    </ul>
</div>