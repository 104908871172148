import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DynamicReport } from '../../interfaces/dynamic-report';

@Injectable({
  providedIn: 'root'
})
export class DynamicReportBackendService {
  url = '/api/dynamic-report';

  constructor(private http: HttpClient) { }

  getByKey(key:string) {
    return this.http.get<DynamicReport>(`${this.url}/${key}`);
  }

  getDataById(id:string) {
    return this.http.get<Array<any>>(`${this.url}/data/${id}`);
  }

  update(data: DynamicReport) {
    return this.http.put(`${this.url}/${data._id}`, data);
  }

  delete(data: DynamicReport) {
    return this.http.delete(`${this.url}/${data._id}`);
  }
}
