import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileUploader } from '../../utils/ng2-file-upload';
import { AuthService } from '../../../routes/user/auth.service';
import files from '../../constants/field-definitions/files';

@Injectable({
  providedIn: 'root'
})
export class FilesBackendService {
  url = '/api/download/';

  constructor(private http: HttpClient, private auth: AuthService) {
  }

  list() {
    return this.http.get<Array<any>>(`${this.url}list`);
  }

  search(search: object): Observable<Array<any>> {
    return this.http.post<Array<any>>(`${this.url}search`, search);
  }

  getById(id: string) {
    return this.http.get(`${this.url}file/${id}`);
  }

  signUrl(id: string, type: string | null = null) {
    return this.http.get<string>(`${this.url}signed-url/${id}/${type}`);
  }

  fileBuffer(id: string, type: string | null = null) {
    return this.http.get(`${this.url}file-buffer/${id}/${type}`, {
      responseType: 'blob'
    });
  }

  delete(statusId: number) {
    return this.http.delete(`${this.url}${statusId}`);
  }

  update(data) {
    return this.http.put(`${this.url}${data._id}`, data)
  }

  upload(data: any) {
    return new Observable((observer) => {
      const uploader = new FileUploader({
        url: `${this.url}`,
        method: 'POST',
        authToken: 'Bearer ' + this.auth.getToken(),
        headers: [
          { name: 'Company', value: this.auth.getCompany() },
        ],
        autoUpload: true
      })

      uploader.onCompleteItem = (item, response, status) => {
        if (status > 400) {
          return observer.error(new HttpErrorResponse({ error: response, status: status }))
        }
        observer.next(JSON.parse(response))
        observer.complete()
      }

      uploader.addToQueue(data)
    })
  }

  uploadCustomFiles(data: any) {
    return new Observable((observer) => {
      const uploader = new FileUploader({
        url: `${this.url}upload-custom-files`,
        method: 'POST',
        authToken: 'Bearer ' + this.auth.getToken(),
        headers: [
          { name: 'Company', value: this.auth.getCompany() },
        ],
        autoUpload: true
      })

      uploader.onCompleteItem = (item, response, status) => {
        if (status > 400) {
          return observer.error(new HttpErrorResponse({ error: response, status: status }))
        }
        observer.next(JSON.parse(response))
        observer.complete()
      }

      uploader.addToQueue(data)
    })
  }

  uploadCompanyFiles(files) {
    return new Observable((observer) => {
      const uploader = new FileUploader({
        url: `${this.url}upload-company-files`,
        method: 'POST',
        authToken: 'Bearer ' + this.auth.getToken(),
        headers: [
          { name: 'Company', value: this.auth.getCompany() },
        ],
        autoUpload: true
      });
      uploader.onCompleteItem = (item, response, status) => {
        if (status > 400) {
          return observer.error(new HttpErrorResponse({ error: response, status: status }));
        }
        observer.next(JSON.parse(response));
        observer.complete();
      };
      uploader.addToQueue(files);
    });

  }

  uploadTasks(data: any) {
    return this.http.post(`${this.url}upload-task-images`, data);
  }

}
