import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-idlemodal',
  templateUrl: './idlemodal.component.html',
  styleUrls: ['./idlemodal.component.scss']
})
export class IdlemodalComponent implements OnInit {
  timeout = 10;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

}
