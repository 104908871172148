import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../../routes/user/auth.service';
import { CustomFieldsStoreService } from './custom-fields-store.service';
import FieldDefinition from '../constants/field-definitions/transaction'
import FieldDefinitionPivot from '../constants/field-definitions/pivot-transaction'
import FieldDefinitionTool from '../constants/field-definitions/tool-transaction'
import { LoaderService } from '../utils/loader.service';
import { Transaction } from '../interfaces/transaction';
import { TransactionsBackendService } from './backend/transactions.backend.service';
import { CommonService } from './common.service';
import { timeCalculation } from '../../shared/comparators/date-comparator';
import { TranslationsStoreService } from './translations-store.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmService } from '../utils/confirm/confirm.service';
import { ConfirmType } from '../constants/confirm-result.enum';
import { Router } from '@angular/router';
import { ToolsStoreService } from './tools-store.service';
import { isObject } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TransactionssStoreService {
  private lastFetched: Date = null
  private transactionsSubject: BehaviorSubject<List<Transaction>> = new BehaviorSubject(List([]))
  public transactions$: Observable<List<Transaction>> = this.transactionsSubject.asObservable()
  private transactionsToolSubject: BehaviorSubject<List<any>> = new BehaviorSubject(List([]))
  public transactionsTool$: Observable<List<any>> = this.transactionsToolSubject.asObservable()
  public readonly columns$: Observable<any[]>
  public readonly columnsPivot$: Observable<any[]>
  public readonly columnsTool$: Observable<any[]>

  constructor(private backend: TransactionsBackendService,
    private customFields: CustomFieldsStoreService,
    private loader: LoaderService,
    private commonService: CommonService,
    private translationsStoreService: TranslationsStoreService,
    private translate: TranslateService,
    private router: Router,
    private confirm: ConfirmService,
    private toolsStoreService: ToolsStoreService,
    private auth: AuthService) {

    auth.user$.subscribe(user => {
      if (!user || !user._id || user.isRoot()) {
        this.transactionsSubject.next(List([]))
        return
      }

      // this.load(true).subscribe()
    })


    this.columns$ = combineLatest([this.auth.user$, this.customFields.get('transaction'), this.translationsStoreService.languageCode$]).pipe(
      map(([user, columns, languageCode]) => {
        if (!user) return []

        const defaults = FieldDefinition.FieldDefinition.filter(col => col.name != "company.name" || user.isRoot()).map(field => ({
          ...field,
          readonly: !this.auth.hasPermission(['inventory']) || field.readonly
        }))

        const fields = columns.toArray().map(field => ({
          ...field,
          readonly: !this.auth.hasPermission(['inventory']) || field.readonly
        }))

        const col = this.customFields.toColumnDef(defaults, {
          rowSelector: 'transactionId',
          format: this.formatCell.bind(this)
        })
        const custom = this.customFields.toColumnDef(fields, {
          format: this.customFields.formatCellCustomField.bind(this)
        });
        return col.concat(custom)
      })
    );

    this.columnsPivot$ = combineLatest([this.auth.user$]).pipe(
      map(([user]) => {
        if (!user) return []

        const defaults = FieldDefinitionPivot.FieldDefinition.filter(col => col.name != "company.name" || user.isRoot()).map(field => ({
          ...field,
          readonly: !this.auth.hasPermission(['inventory']) || field.readonly
        }))

        const col = this.customFields.toColumnDefPivot(defaults, {
          format: this.formatCell.bind(this)
        })
        return col
      })
    );

    this.columnsTool$ = combineLatest([this.auth.user$]).pipe(
      map(([user]) => {
        if (!user) return []

        const defaults = FieldDefinitionTool.FieldDefinition.map(field => ({
          ...field
        }))

        const col = this.customFields.toColumnDefPivot(defaults, {
          format: this.formatCell.bind(this)
        })
        return col
      })
    );

  }

  load = (force: boolean = false) => this.backend.list(this.lastFetched)
    .pipe(
      map(transactions => transactions.map(transaction => new Transaction(transaction))),
      tap(
        transactions => {
          if (!this.lastFetched) {
            this.transactionsSubject.next(List(transactions))
          } else {
            var transactionsList = this.transactionsSubject.getValue()
            transactions.forEach(transaction => {
              const idx = transactionsList.findIndex((t: Transaction) => t._id === transaction._id)
              if (idx < 0) {
                transactionsList = transactionsList.unshift(transaction)
              } else {
                transactionsList = transactionsList.set(idx, transaction)
              }
            })
            this.transactionsSubject.next(transactionsList)
          }
          this.lastFetched = new Date()
        }
      )
    )

  loadTool = () => this.backend.listTool()
    .pipe(
      tap(
        tools => {
          const list = [];
          for(const t in tools){
            const tool: any = tools[t];
            for(const p in tool.parts){
              const part: any = tool.parts[p];
              list.push({
                tool: tool.id_num,
                part: part.part?.id_num,
              })
            }
          }
          this.transactionsToolSubject.next(List(list))
        }
      )
    )

  save = (transaction: Transaction) => (transaction._id ? this.update(transaction) : this.create(transaction))

  getList() {
    return this.transactionsSubject.getValue();
  }

  getToolList() {
    return this.transactionsToolSubject.getValue();
  }

  update = (transaction: Transaction): Observable<Transaction> => this.backend.update(transaction)
    .pipe(
      tap((data: Transaction) => {
        const transactions = this.transactionsSubject.getValue()
        const idx = transactions.findIndex((t: Transaction) => t._id === data._id)
        this.transactionsSubject.next(transactions.set(idx, data))
      }))

  create = (transaction: Transaction) => this.backend.create(transaction)
    .pipe(
      tap(data => {
        this.transactionsSubject.next(this.transactionsSubject.getValue().unshift(data))
      }))

  delete = (transaction: Transaction) => this.backend.delete(transaction)
    .pipe(
      tap((transaction: Transaction) => {
        const transactions = this.transactionsSubject.getValue()
        const idx = transactions.findIndex((t: Transaction) => t._id === transaction._id)
        this.transactionsSubject.next(transactions.splice(idx, 1))
      }))

  toolLink(params) {
    if(params.rowData.tool){
      const tool = this.toolsStoreService.getToolById(isObject(params.rowData.tool) ? params.rowData.tool._id : params.rowData.tool);
      if(tool){
        if (tool.isDeleted) {
          this.confirm.show(this.translate.instant('confirm.TOOL.TOOL_DELETED'), { type: ConfirmType.CONFIRM_ONLY, confirmText: this.translate.instant('shared.OK'), defaultBtnClass: 'btn-danger' }).subscribe(() => { })
        } else {
          const url = this.router.serializeUrl(
            this.router.createUrlTree([`/main/maintenance/tool/${tool.id_num}`])
          );
          window.open(url, '_blank');
        }
      }
    }
  }

  formatCell = (col, field) => {
    switch (field.name) {
      case "tool.name":
        return {
          ...col,
          cellRenderer: 'customClick',
          cellRendererParams: {
            onClick: this.toolLink.bind(this),
            field: field.name
          }
        }
      case "updatedAt":
        return {
          ...col,
          valueFormatter: (params) => params.data && params.data[field.name] && this.commonService.getDateTime(params.data[field.name]),
          valueGetter: (params) => params.data && params.data[field.name] && this.commonService.getDateTime(params.data[field.name]),
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.updatedAt ? nodeA.data.updatedAt : null, nodeB && nodeB.data.updatedAt ? nodeB.data.updatedAt : null, nodeA, nodeB, isInverted);
          }
        }
      default:
        return col;
    }
  }
}

