<div class="wrapper">
  <div class="block-center">
    <div class="logo text-center">
      <img src="assets/img/Logo land - Regular White@3x.png" alt="logo" class="brand-logo" />
    </div>
    <div class="card card-flat">
      <div class="">
        <div class="log-in-header">
          <h3 class="log-in-paragraph text-center">TERMS OF SERVICE</h3>
        </div>
        <div class="line"></div>
        <div class="block">
          <div class="content pl-4 pr-4">
            <app-terms-and-agreement-content></app-terms-and-agreement-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>