import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { isArray, isObject, isString } from 'lodash';
import { GeneralService } from '../../../core/services/general.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-file-redirection-renderer',
  template: `
    <a href="#" (click)="onClick($event)" *ngIf="label">{{ label }}</a>
    <ng-container *ngIf="multipleValues?.length > 0">
      <ng-container *ngFor="let value of multipleValues; let last = last;">
        <a href="#" (click)="onClick($event, value._id)">{{ value.name }}</a>
        <span *ngIf="!last">, </span>
      </ng-container>
    </ng-container>
  `
})

export class FileRedirectionRendererComponent implements ICellRendererAngularComp {

  params;
  label: string;
  id = null;
  multipleValues = [];

  constructor(
    private generalService: GeneralService,
    private translateService: TranslateService,
  ) { }

  agInit(params): void {
    this.params = params;
    const field = this.params.colDef.field;
    const multiple = this.params.isMultiple ? this.params.isMultiple : false;
    const showMultiple = this.params.isShowMultiple ? this.params.isShowMultiple : false;
    if(showMultiple){
      let fieldValue = null;
      if(field.includes('.')){
        const data = { ...params.data }
        const props = field.split('.');
        fieldValue = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', data);
      } else {
        fieldValue = params.data[field] || null;
      }
      console.log( 'fieldValue', fieldValue );
      if(fieldValue){
        if(fieldValue && isArray(fieldValue) && fieldValue.length > 0){
          this.multipleValues = fieldValue;
        } else {
          this.multipleValues = fieldValue ? [fieldValue] : [];
        }
      }
    } else {
      let fieldValue = null;
      if(field.includes('.')){
        const data = { ...params.data }
        const props = field.split('.');
        fieldValue = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', data);
      }else{
        fieldValue = params.data[field];
      }
      if(multiple){
        if(fieldValue){
          if(fieldValue && isArray(fieldValue) && fieldValue.length > 0){
            this.label = fieldValue[0].name;
            this.id = fieldValue[0]._id;
          } else {
            this.label = fieldValue ? fieldValue.name : null;
            this.id = fieldValue ? fieldValue._id : null;
          }
        }
      } else {
        if(fieldValue && isString(fieldValue)){
          this.label = this.translateService.instant("general.SHOW-FILE");
          this.id = fieldValue;
        } else {
          this.label = fieldValue ? fieldValue.name : null;
          this.id = fieldValue ? fieldValue._id : null;
        }
      }
    }
  }

  refresh(params?: any): boolean {
    return true;
  }


  onClick($event, id: string = null) {
    $event.preventDefault();
    if(id){
      this.generalService.viewFile(id);
    } else if(this.id){
      this.generalService.viewFile(this.id);
    }
  }
}