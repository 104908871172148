import moment from "moment"
import { User } from "../../routes/user/login.response"
import { Company } from "./company"
import { Part } from "./part"
import { Tool } from "./tool"

export class Transaction{
    _id?: String
    id_num?: Number
    company: Company
    part: Part
    tool: Tool
    event: Event
    partId: String
    catalogNumber: String
    name: String
    stockQuantity: Number
    oldStockQuantity: Number
    changeStockQuantity: Number
    minimalQuantity: Number
    units: String
    moveKind: String
    warehouseName: String
    shelf: String
    fatherId: String
    fatherName: String
    updatedOn: Date
    updatedBy: User
    customFields?: Object
    image?: string
    createdAt?: Date
    updatedAt?: Date
    transactionMonth?: string

    constructor(options:{
        _id?: String,
        id_num?: Number
        company?: Company,
        part?: Part,
        tool?: Tool,
        event?: Event,
        partId?: String,
        catalogNumber?: String,
        name?: String,
        stockQuantity?: Number,
        oldStockQuantity?: Number,
        changeStockQuantity?: Number,
        minimalQuantity?: Number,
        units?: String,
        moveKind?: String,
        warehouseName?: String,
        shelf?: String,
        fatherId?: String,
        fatherName?: String,
        updatedOn?: Date,
        updatedBy?: User,
        customFields?: Object,
        image?: string,
        createdAt?: Date
        updatedAt?: Date
        transactionMonth?: string

    }){
        this._id = options._id || undefined
        this.id_num = options.id_num || undefined
        this.company = options.company || null
        this.tool = options.tool || undefined
        this.event = options.event || undefined
        this.part = options.part || undefined
        this.partId = options.partId || ""
        this.catalogNumber = options.catalogNumber || ""
        this.name = options.name || ""
        this.stockQuantity = options.stockQuantity || 0
        this.oldStockQuantity = options.oldStockQuantity || 0
        this.changeStockQuantity = options.changeStockQuantity || 0
        this.minimalQuantity = options.minimalQuantity || 0
        this.units = options.units || ""
        this.moveKind = options.moveKind || ""
        this.warehouseName = options.warehouseName || ""
        this.shelf = options.shelf || ""
        this.fatherId = options.fatherId || ""
        this.fatherName = options.fatherName || ""
        this.updatedBy = options.updatedBy || null
        this.updatedOn = options.updatedOn || null
        this.customFields = options.customFields || null
        this.image = options.image || null
        this.createdAt = options.createdAt || null
        this.updatedAt = options.updatedAt || null
        this.transactionMonth = this.updatedAt ? moment(this.updatedAt).format('MM-YYYY') : null;
    }
}