import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { List } from 'immutable';
import { finalize, map, tap } from 'rxjs/operators';
import { AuthService } from '../../routes/user/auth.service';
import { FilesBackendService } from './backend/files-backend.service';
import FieldDefinition from '../constants/field-definitions/files';
import { CustomFieldsStoreService } from './custom-fields-store.service';
import { EventsBackendService } from './backend/events-backend.service';
import { Tool } from '../interfaces/tool';
import { CommonService } from './common.service';
import { LoaderService } from '../utils/loader.service';
import { timeCalculation } from '../../shared/comparators/date-comparator';
import { UsersStoreService } from './users-store.service';
import { isObject, isString } from 'lodash';
import { TranslationsStoreService } from './translations-store.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ConfirmService } from '../utils/confirm/confirm.service';
import { ToolsStoreService } from './tools-store.service';
import { ConfirmType } from '../constants/confirm-result.enum';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class FilesStoreService {
  private filesSubject: BehaviorSubject<List<any>> = new BehaviorSubject(List([]));
  public files$: Observable<List<any>>;
  private fileCallSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private columnsSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public readonly columns$: Observable<any[]> = this.columnsSubject.asObservable();

  constructor(private backend: FilesBackendService,
    private eventsBackend: EventsBackendService,
    private auth: AuthService,
    private commonService: CommonService,
    private usersStoreService: UsersStoreService,
    private translationsStoreService: TranslationsStoreService,
    private loader: LoaderService,
    private translate: TranslateService,
    private router: Router,
    private confirm: ConfirmService,
    private toolsStoreService: ToolsStoreService,
    private generalService: GeneralService,
    private customFields: CustomFieldsStoreService) {

    this.files$ = combineLatest([this.filesSubject]).pipe(
      map(([files]) => files)
    );

    this.columns$ = combineLatest([this.auth.user$, this.customFields.get('files'), this.translationsStoreService.languageCode$]).pipe(
      map(([user, columns, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(col => (col.name != "deleted" || user.isAdmin()));
        const fields = columns.toArray();
        const col = this.customFields.toColumnDef(defaults, {
          rowSelector: 'id_num',
          format: this.formatCell.bind(this)
        });
        const custom = this.customFields.toColumnDef(fields, {
          format: this.customFields.formatCellCustomField.bind(this)
        });
        return col.concat(custom);
      })
    );
  }

  load() {
    if (this.fileCallSubject.getValue()) {
      return of(false);
    } else {
      return this.backend.list()
        .pipe(tap((files: Array<any>) => {
          this.filesSubject.next(List(files))
          this.fileCallSubject.next(true);
        })).subscribe();
    }
  }

  syncFile(id: string) {
    return this.backend.getById(id)
      .pipe(
        tap(file => {
          const files = this.filesSubject.getValue();
          const idx = files.findIndex((f) => f._id === id);
          if (idx > -1) {
            this.filesSubject.next(files.set(idx, file));
          } else {
            this.filesSubject.next(files.unshift(file));
          }
        }));
  }

  loadList() {
    return this.backend.list()
      .pipe(
        map(files => files.map((file) => this.remapFiles(file))),
        tap((files: Array<any>) => {
          this.filesSubject.next(List(files))
          this.fileCallSubject.next(true);
        }));
  }

  remapFiles(file) {
    if (file.worker && isString(file.worker)) {
      const users = this.usersStoreService.getList();
      file.worker = users.find(user => user._id === file.worker)
    }
    return file;
  }

  search(search: object) {
    return this.backend.search(search)
      .pipe(tap((files: Array<any>) => files));
  }

  signedUrl(id: string, type: string | null = null): Promise<string> {
    return new Promise((resolve, reject) => {
      this.backend.signUrl(id, type).subscribe({
        next: (url) => {
          resolve(url);
        },
        error: (err) => {
          reject(err);
        }
      })
    });
  }

  getList() {
    return this.filesSubject.getValue();
  }

  getFileBufferById(id: string, type: string | null = null): Promise<Blob> {
    return new Promise((resolve, reject) => {
      this.backend.fileBuffer(id, type).subscribe({
        next: (buffer) => {
          resolve(buffer);
        },
        error: (err) => {
          reject(err);
        }
      })
    });
  }

  isImageFile(name) {
    return name.match(/.(jpg|jpeg|png|gif)$/i);
  }


  upload(data: any, tool: Tool) {
    return this.eventsBackend.upload(data, tool).pipe(tap(ev => {
      this.filesSubject.next(this.filesSubject.getValue().push(ev));
    }));
  }

  uploadImage(data: any) {
    return this.backend.upload(data).pipe(tap((ev: any) => {
      this.filesSubject.next(this.filesSubject.getValue().push(ev.file));
    }));
  }

  uploadCustomFiles(data: any) {
    this.loader.add()
    return this.backend.uploadCustomFiles(data).pipe(
      tap((ev: any) => {
        this.filesSubject.next(this.filesSubject.getValue().push(ev.file));
      }),
      finalize(() => this.loader.remove())
    );
  }

  uploadTasks(data: any) {
    this.loader.add()
    return this.backend.uploadTasks(data).pipe(
      tap((ev: any) => {
        this.filesSubject.next(this.filesSubject.getValue().push(ev));
      }),
      finalize(() => this.loader.remove())
    );
  }

  toolLink(params) {
    if(params.rowData.tool){
      const tool = this.toolsStoreService.getToolById(isObject(params.rowData.tool) ? params.rowData.tool._id : params.rowData.tool);
      if(tool){
        if (tool.isDeleted) {
          this.confirm.show(this.translate.instant('confirm.TOOL.TOOL_DELETED'), { type: ConfirmType.CONFIRM_ONLY, confirmText: this.translate.instant('shared.OK'), defaultBtnClass: 'btn-danger' }).subscribe(() => { })
        } else {
          const url = this.router.serializeUrl(
            this.router.createUrlTree([`/main/maintenance/tool/${tool.id_num}`])
          );
          window.open(url, '_blank');
        }
      }
    }
  }

  imageLink(params) {
    if (params.rowData._id) {
      this.generalService.viewFile(params.rowData._id);
    }
  }

  formatCell = (col, field) => {
    switch (field.name) {
      case "tool.name":
        return {
          ...col,
          cellRenderer: 'customClick',
          cellRendererParams: {
            onClick: this.toolLink.bind(this),
            field: field.name
          }
        }
      case "name":
        return {
          ...col,
          cellRenderer: "customClick",
          cellRendererParams: {
            onClick: this.imageLink.bind(this),
            field: field.name
          },
        }
      case "createdAt":
        return {
          ...col,
          valueFormatter: (params) => params.data[field.name] && this.commonService.getDateTime(params.data[field.name]),
          valueGetter: (params) => params.data[field.name] && this.commonService.getDateTime(params.data[field.name]),
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.createdAt ? nodeA.data.createdAt : null, nodeB && nodeB.data.createdAt ? nodeB.data.createdAt : null, nodeA, nodeB, isInverted);
          }
        }
      default:
        return col;
    }
  }

  delete(statusId: number) {
    return this.backend.delete(statusId);
  }

  update(data: any) {
    return this.backend.update(data).pipe(
      map((file) => this.remapFiles(file)),
      tap((file: any) => {
        const files = this.filesSubject.getValue();
        const idx = files.findIndex((f) => f._id == file._id);
        this.filesSubject.next(files.set(idx, file));
        this.fileCallSubject.next(true);
      }));
  }

}
