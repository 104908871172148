import { Component, OnInit, Input } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsersStoreService } from '../../../core/services/users-store.service';
import { AuthService } from '../../../routes/user/auth.service';
import { User } from '../../../routes/user/login.response';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  @Input() src: string;
  @Input() name: string;
  @Input() size: string = "sm";

  public user$: Observable<User>;
  public imagePath = '../../../../assets/img/user/Default user image.png';
  constructor(private userService: AuthService, private users: UsersStoreService){

  }
  public ngOnInit() {
    this.user$ = combineLatest([this.userService.user$, this.users.users$]).pipe(
      map(([user, users])=>{
        if (!user) return null;
        if (!users) return null;

        return users.find(u => u._id === user._id)
      })
    )

    const isPhotoExist: boolean = Boolean(this.src);
    if (isPhotoExist) {
      this.imagePath = this.src;
    }
  }
}
