import { List } from "immutable";
import { AUTO_COMPLETE_TOOl } from "../constants/enums/enums";
import { encryptField } from "../utils/general";
import { Size } from "./size";
import { SubscriptionPayment } from "./subscription-payment";

export interface TABLE_PRESENTATION {
  section: string;
  records: number | string;
  column: string;
  sort: string;
}

export interface WhatsAppBySite {
  site?: string;
  number?: string;
}

export interface apiCredential {
    nameKey: { type: String, required: true },
    secretKey: { type: String, required: true },
    accessKey: { type: String, required: true },
    defaultUser: { type: String, required: true },
    defaultStatus: { type: String, required: true }
};

interface AiMaintenanceAssistant {
  start?: string;
  rule?: string;
  finish?: string;
}

export class Company {
  _id?: string;
  name: string;
  blocked: boolean;
  active: boolean;
  lastPayment: SubscriptionPayment | null;
  activePayments: List<SubscriptionPayment>;
  eulaAccepted: Boolean;
  eulaAcceptedAt?: Date | null;
  backupSchedule: Number;
  landingPage: String;
  defaultLanguage?: String;
  image: string;
  share: boolean;
  dailyWorkHours: {
    start: string;
    end: string;
  };
  shifts: {
    start: string;
    end: string;
  }[];
  daysOff: any[];
  timeFormat: string;
  timezone: string;
  shortDateFormat: string;
  longDateFormat: string;
  whatsAppMessageOn: [];
  whatsAppMessageFormat: string[];
  whatsAppGroupId: string;
  onboardingWizard: [];
  dayLight: boolean;
  dayLightManual: string;
  whatsAppFrom: string;
  wpDefaultTool: string | null;
  wpDefaultUser: string | null;
  whatsAppTriggerStartWord?: string | null;
  whatsAppTriggerFinishWord?: string | null;
  whatsAppUserField?: string | null;
  whatsAppWorkerField?: string | null;
  skipUpgradeBlocker: boolean;
  sendToAssignedWorker: boolean;
  openEventsFromWhatsApp: boolean;
  sendToAssignedWorkerByEmail: boolean;
  isEventReporterNumber: boolean;
  autoHelper: boolean;
  sendAllReportsToManagement: boolean;
  rejectUnknownWhatsAppNumber: boolean;
  slaTime: string | null;
  defaultWorkerPassword?: string;
  autoSignOut?: boolean;
  autoSignoutAfterMinutes: number | null;
  firstDayOfWeek?: number;
  freemiumPermissions: {
    signature: boolean;
    limitedIP: boolean;
    whatsApp: boolean;
    smartDropDown: boolean;
    bigTeam: boolean;
    sla: boolean;
    auditTrail: boolean;
    otp: boolean;
    otpByWhatsApp: boolean;
    otpByEmail: boolean;
    proceduresAndTasks: boolean;
    inventory: boolean;
    smartSchedule: boolean;
    measurements: boolean;
    pmCalender: boolean;
    api: boolean;
    automaticAssistant: boolean;
    drawingMode: boolean;
    allowSignAllTasks: boolean;
    hideDataInGraphs: boolean;
    hideDataInGraphsDefault: boolean;
    autoAdjustStartTimeBasedOnEstimation: boolean;
    quantityWaQuery: boolean;
    allowSmartAnswering: boolean;
    allowCollaboardativeMaintenance: boolean;
    moderator: boolean;
    hideChecklistPreview: boolean;
    scheduledReports: boolean;
    hideTasksFromFm: boolean;
    saveEventAsOpen: boolean;
    calendar: boolean;
    qaSignature: boolean;
    fmEventQa: boolean;
    pmEventQa: boolean;
    eventTypeQa?: string[];
    safetySignature: boolean;
    fmEventSafety: boolean;
    pmEventSafety: boolean;
    eventTypeSafety?: string[];
    openEventFromTask: boolean;
    translateAlwaysToggle: boolean;
    openEventType?: number | null;
    openEventId?: string | null;
    subSystems: boolean;
    connectedEvents: boolean;
    useToolAsWarehouse: boolean;
    groupPmUpdate: boolean;
    msTeams: boolean;
  };
  signatureCriticalText: string | null;
  accessKey?: string;
  secretKey?: string;
  defaultApiUser?: string;
  showRecentlyDoneEventInHours?: number;
  createdAt?: Date;
  updatedAt?: Date;
  size?: Size;
  nominalProductionTime?: number;
  shiftsInDay?: number;
  timeStep?: number;
  tablesPresentation?: TABLE_PRESENTATION[];
  defaultAutoCompleteTool?: AUTO_COMPLETE_TOOl;
  defaultMachineStatus?: string;
  prefixOnePrompt?: string;
  prefixTwoPrompt?: string;
  suffixPrompt?: string;
  aiPrefixPrompt?: string;
  customCharts?: {
    _id?: string;
    x?: string;
    y?: string;
    type?: string;
  }[];
  pptxTemplate?: string;
  pptxTemplateObject?: any;
  pptxTextColor?: string;
  eula?: any;
  joinWhatsAppMessageByTime?: number;
  isWhatsAppBySite?: boolean;
  whatsAppBySite?: WhatsAppBySite[];
  termAndCondition: {
    username: string;
    ip: string;
  };
  reports: {
    mailLanguage: string;
    dontSendOnHoliday: boolean;
    dashboardSendTo: string;
    dashboardEmailFrequency: string;
    dashboardDeliveryTime: string;
    dashboardWeekDay: string;
    dashboardWeekPeriod: string;
    dashboardMonthPeriod: string;
    showLogo: boolean;
    showReportName: boolean;
    showPageNumber: boolean;
    showGeneratedDate: boolean;
    attachXlsx: boolean;
    kpis: string[];
    charts: string[];
    reportSummaryCharts: string[];
    showLatestEvents: boolean;
    showTableReportSummary: boolean;
    dashboardFilter: string;
    dashboardMonthDay: string | number;
    customReportName: string;
    customReportSendTo: string;
    customReportMonthDay: string | number;
  };
  isActiveDirectory?: boolean;
  activeDirectory?: {
    clientId?: string;
    authority?: string;
    policy?: string;
    tenant?: string;
  };
  isSaml?: boolean;
  saml?: {
    entryPoint?: string;
    issuer?: string;
    cert?: string;
  };
  tooltips?: {
    events: string[];
    maintenances: string[];
    pmCalendars: string[];
  };
  emailSettings: {
    mailLanguage: string;
    dontSendOnHoliday: boolean;
    pmSendTo: string;
    inventorySendTo: string;
    pmEmailFrequency: string;
    inventoryEmailFrequency: string;
    pmDeliveryTime: string;
    inventoryDeliveryTime: string;
    dontSendMessageonBlankPM: boolean;
    dontSendMessageonBlankInventory: boolean;
    pmWeekDay: string;
    inventoryWeekDay: string;
  };
  defaultPhoneCode?: string;
  chartTargetValue?: {
    mttf: number;
    overallMttr: number;
  };
  msTeams?: {
    messageOn?: string[];
    incomingWebhook?: string | null;
    messageFormat?: string[];
  };
  aiMaintenanceAssistant?: {
    pm?: AiMaintenanceAssistant;
    troubleShooting?: AiMaintenanceAssistant;
    spareParts?: AiMaintenanceAssistant;
  };
  apiCredentials?: apiCredential[];

  constructor(options: {
    _id?: string;
    name?: string;
    blocked?: boolean;
    active?: boolean;
    lastPayment?: SubscriptionPayment;
    activePayments?: List<SubscriptionPayment>;
    eulaAccepted?: Boolean;
    eulaAcceptedAt?: Date;
    backupSchedule?: Number;
    landingPage?: String;
    defaultLanguage?: String;
    image?: string;
    share?: boolean;
    dailyWorkHours?: {
      start: string;
      end: string;
    };
    shifts: {
      start: string;
      end: string;
    }[];
    daysOff: any[];
    timeFormat: string;
    timezone: string;
    shortDateFormat: string;
    longDateFormat: string;
    whatsAppMessageOn: [];
    whatsAppMessageFormat: string[];
    whatsAppGroupId: string;
    onboardingWizard: [];
    dayLight: boolean;
    dayLightManual: string;
    whatsAppFrom: string;
    wpDefaultTool: string | null;
    wpDefaultUser: string | null;
    whatsAppTriggerStartWord?: string | null;
    whatsAppTriggerFinishWord?: string | null;
    whatsAppUserField?: string | null;
    whatsAppWorkerField?: string | null;
    skipUpgradeBlocker: boolean;
    sendToAssignedWorker: boolean;
    openEventsFromWhatsApp: boolean;
    sendToAssignedWorkerByEmail: boolean;
    isEventReporterNumber: boolean;
    autoHelper: boolean;
    sendAllReportsToManagement: boolean;
    rejectUnknownWhatsAppNumber: boolean;
    slaTime: string | null;
    defaultWorkerPassword?: string;
    autoSignOut?: boolean;
    autoSignoutAfterMinutes: number | null;
    firstDayOfWeek?: number;
    freemiumPermissions: {
      signature: boolean;
      limitedIP: boolean;
      whatsApp: boolean;
      smartDropDown: boolean;
      bigTeam: boolean;
      sla: boolean;
      auditTrail: boolean;
      otp: boolean;
      otpByWhatsApp: boolean;
      otpByEmail: boolean;
      proceduresAndTasks: boolean;
      inventory: boolean;
      smartSchedule: boolean;
      measurements: boolean;
      pmCalender: boolean;
      api: boolean;
      automaticAssistant: boolean;
      drawingMode: boolean;
      allowSignAllTasks: boolean;
      hideDataInGraphs: boolean;
      hideDataInGraphsDefault: boolean;
      autoAdjustStartTimeBasedOnEstimation: boolean;
      quantityWaQuery: boolean;
      allowSmartAnswering: boolean;
      allowCollaboardativeMaintenance: boolean;
      moderator: boolean;
      hideChecklistPreview: boolean;
      scheduledReports: boolean;
      hideTasksFromFm: boolean;
      saveEventAsOpen: boolean;
      calendar: boolean;
      qaSignature: boolean;
      fmEventQa: boolean;
      pmEventQa: boolean;
      eventTypeQa?: string[];
      safetySignature: boolean;
      fmEventSafety: boolean;
      pmEventSafety: boolean;
      eventTypeSafety?: string[];
      openEventFromTask: boolean;
      translateAlwaysToggle: boolean;
      openEventType?: number | null;
      openEventId?: string | null;
      subSystems: boolean;
      connectedEvents: boolean;
      useToolAsWarehouse: boolean;
      groupPmUpdate: boolean;
      msTeams: boolean;
    };
    signatureCriticalText: string | null;
    accessKey?: string;
    secretKey?: string;
    defaultApiUser?: string;
    showRecentlyDoneEventInHours?: number;
    createdAt?: Date;
    updatedAt?: Date;
    size?: Size;
    nominalProductionTime?: number;
    shiftsInDay?: number;
    timeStep?: number;
    tablesPresentation?: TABLE_PRESENTATION[];
    defaultAutoCompleteTool?: AUTO_COMPLETE_TOOl;
    prefixOnePrompt?: string;
    prefixTwoPrompt?: string;
    suffixPrompt?: string;
    aiPrefixPrompt?: string;
    customCharts?: {
      _id?: string;
      x?: string;
      y?: string;
      type?: string;
    }[];
    pptxTemplate?: string;
    pptxTemplateObject?: any;
    pptxTextColor?: string;
    eula?: any;
    joinWhatsAppMessageByTime?: number;
    isWhatsAppBySite?: boolean;
    whatsAppBySite?: WhatsAppBySite[];
    termAndCondition: {
      username: string;
      ip: string;
    };
    reports: {
      mailLanguage: string;
      dontSendOnHoliday: boolean;
      dashboardSendTo: string;
      dashboardEmailFrequency: string;
      dashboardDeliveryTime: string;
      dashboardWeekDay: string;
      dashboardWeekPeriod: string;
      dashboardMonthPeriod: string;
      showLogo: boolean;
      showReportName: boolean;
      showPageNumber: boolean;
      showGeneratedDate: boolean;
      attachXlsx: boolean;
      kpis: string[];
      charts: string[];
      reportSummaryCharts: string[];
      showLatestEvents: boolean;
      showTableReportSummary: boolean;
      dashboardFilter: string;
      dashboardMonthDay: string | number;
      customReportName: string;
      customReportSendTo: string;
      customReportMonthDay: string | number;
    };
    isActiveDirectory?: boolean;
    activeDirectory?: {
      clientId?: string;
      authority?: string;
      policy?: string;
      tenant?: string;
    };
    isSaml?: boolean;
    saml?: {
      entryPoint?: string;
      issuer?: string;
      cert?: string;
    };
    tooltips?: {
      events: string[];
      maintenances: string[];
      pmCalendars: string[];
    };
    emailSettings: {
      mailLanguage: string;
      dontSendOnHoliday: boolean;
      pmSendTo: string;
      inventorySendTo: string;
      pmEmailFrequency: string;
      inventoryEmailFrequency: string;
      pmDeliveryTime: string;
      inventoryDeliveryTime: string;
      dontSendMessageonBlankPM: boolean;
      dontSendMessageonBlankInventory: boolean;
      pmWeekDay: string;
      inventoryWeekDay: string;
    };
    defaultPhoneCode?: string;
    chartTargetValue?: {
      mttf: number;
      overallMttr: number;
    };
    aiMaintenanceAssistant?: {
      pm?: AiMaintenanceAssistant;
      troubleShooting?: AiMaintenanceAssistant;
      spareParts?: AiMaintenanceAssistant;
    };
    apiCredentials?: apiCredential[];
    msTeams?: {
      messageOn?: string[];
      incomingWebhook?: string | null;
      messageFormat?: string[];
    };
  }) {
    this._id = options._id || undefined;
    this.name = options.name || "";
    this.blocked = options.blocked || false;
    this.active = options.active || false;
    this.lastPayment = options.lastPayment || null;
    this.activePayments = options.activePayments || List([]);
    this.eulaAcceptedAt = options.eulaAcceptedAt || null;
    this.eulaAccepted = options.eulaAccepted || false;
    this.backupSchedule = options.backupSchedule || 0;
    this.landingPage = options.landingPage || "maintenance";
    this.defaultLanguage = options.defaultLanguage || "English";
    this.image = options.image || "";
    this.share = options.share || false;
    this.dailyWorkHours = options.dailyWorkHours || {
      start: "",
      end: "",
    };
    this.shifts = options.shifts || [];
    this.daysOff = options.daysOff || [];
    this.timeFormat = options.timeFormat || "";
    this.timezone = options.timezone || "";
    this.shortDateFormat = options.shortDateFormat || "";
    this.longDateFormat = options.longDateFormat || "";
    this.whatsAppMessageOn = options.whatsAppMessageOn || [];
    this.whatsAppMessageFormat = options.whatsAppMessageFormat || [];
    this.whatsAppGroupId = options.whatsAppGroupId || "";
    this.onboardingWizard = options.onboardingWizard || [];
    this.dayLight = options.dayLight || true;
    this.dayLightManual = options.dayLightManual || "summer";
    this.whatsAppFrom = options.whatsAppFrom || "";
    this.wpDefaultTool = options.wpDefaultTool || null;
    this.wpDefaultUser = options.wpDefaultUser || null;
    this.whatsAppTriggerStartWord = options.whatsAppTriggerStartWord || null;
    this.whatsAppTriggerFinishWord = options.whatsAppTriggerFinishWord || null;
    this.whatsAppUserField = options.whatsAppUserField || "solutionDesc";
    this.whatsAppWorkerField = options.whatsAppWorkerField || "problemDesc";
    this.skipUpgradeBlocker = options.skipUpgradeBlocker || false;
    this.sendToAssignedWorker = options.sendToAssignedWorker || false;
    this.openEventsFromWhatsApp = options.openEventsFromWhatsApp || true;
    this.sendToAssignedWorkerByEmail =
      options.sendToAssignedWorkerByEmail || false;
    this.isEventReporterNumber = options.isEventReporterNumber || false;
    this.autoHelper = options.autoHelper || false;
    this.sendAllReportsToManagement =
      options.sendAllReportsToManagement || false;
    this.rejectUnknownWhatsAppNumber =
      options.rejectUnknownWhatsAppNumber || false;
    this.defaultWorkerPassword =
      options.defaultWorkerPassword || encryptField("a");
    this.slaTime = options.slaTime || null;
    this.autoSignoutAfterMinutes = options.autoSignoutAfterMinutes || null;
    this.firstDayOfWeek = options.firstDayOfWeek || 0;
    this.autoSignOut = options.autoSignOut;
    this.freemiumPermissions = options.freemiumPermissions || null;
    this.signatureCriticalText = options.signatureCriticalText || null;
    this.accessKey = options.accessKey || null;
    this.secretKey = options.secretKey || null;
    this.defaultApiUser = options.defaultApiUser || null;
    this.showRecentlyDoneEventInHours =
      options.showRecentlyDoneEventInHours || 12;
    this.createdAt = options.createdAt || null;
    this.updatedAt = options.updatedAt || null;
    this.size = options.size || {};
    this.nominalProductionTime = options.nominalProductionTime || 1;
    this.shiftsInDay = options.shiftsInDay || 1;
    this.timeStep = options.timeStep || 30;
    this.tablesPresentation = options.tablesPresentation || [];
    this.defaultAutoCompleteTool =
      options.defaultAutoCompleteTool || AUTO_COMPLETE_TOOl.JS;
    this.prefixOnePrompt = options.prefixOnePrompt || "";
    this.prefixTwoPrompt = options.prefixTwoPrompt || "";
    this.suffixPrompt = options.suffixPrompt || "";
    this.aiPrefixPrompt = options.aiPrefixPrompt || "";
    this.customCharts = options.customCharts || null;
    this.pptxTemplate = options.pptxTemplate || null;
    this.pptxTemplateObject = options.pptxTemplateObject || null;
    this.pptxTextColor = options.pptxTextColor || "#ffffff";
    this.eula = options.eula || null;
    this.joinWhatsAppMessageByTime = options.joinWhatsAppMessageByTime || 60;
    this.isWhatsAppBySite = options.isWhatsAppBySite || false;
    this.whatsAppBySite = options.whatsAppBySite || [];
    this.termAndCondition = options.termAndCondition || null;
    this.reports = options.reports || null;
    this.isActiveDirectory = options.isActiveDirectory || false;
    this.activeDirectory = options.activeDirectory || null;
    this.isSaml = options.isSaml || false;
    this.saml = options.saml || null;
    this.tooltips = options.tooltips || {
      events: [],
      maintenances: [],
      pmCalendars: [],
    };
    this.emailSettings = options.emailSettings || null;
    this.defaultPhoneCode = options.defaultPhoneCode || "972";
    this.chartTargetValue = options.chartTargetValue || {
      mttf: 0,
      overallMttr: 0,
    };
    this.msTeams = options.msTeams || {
      messageOn: [],
      incomingWebhook: null,
      messageFormat: [],
    };
    this.aiMaintenanceAssistant = options.aiMaintenanceAssistant || {
      pm: {
        start: null,
        rule: null,
        finish: null,
      },
      troubleShooting: {
        start: null,
        rule: null,
        finish: null,
      },
      spareParts: {
        start: null,
        rule: null,
        finish: null,
      },
    };
    this.apiCredentials = options.apiCredentials || [];
  }
}
