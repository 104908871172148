import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { finalize } from 'rxjs/operators';
import { CommonService } from '../../../core/services/common.service';
import { decryptField } from '../../../core/utils/general';
import { AuthService } from '../auth.service';
import { User } from '../login.response';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent implements OnInit {
  defaultTime: number = 180
  resendSeconds: number = 0;
  resendSecondText: string = '';
  errorMessage: string | null = null;
  user: User;
  location:string = null;
  ip:string = null;
  otp:string=''
  lastOtp:string=''
  displayReset:boolean = true;
  constructor(
    private authService: AuthService,
    private router: Router,
    private toaster: ToasterService,
    private commonService: CommonService,
  ) {
    this.getClockChrome().then((data: any) => {
      if(data){
        this.ip = data.ip;
      }
    });
  }


  getClockChrome = async () => {
    let that = this;
    navigator.geolocation.getCurrentPosition(function (pos) {
      that.location = pos.coords.latitude+','+pos.coords.longitude;
    })
    return new Promise(async(resolve, reject) => {
      let res = await fetch('https://api.ipify.org/?format=json');
      res = await res.json();
      resolve(res);
    })
  }

  ngOnInit() {
    if(this.authService.getToken()){
      this.router.navigate(['/main']);
    } else {
      this.user = JSON.parse(localStorage.getItem('_user'));
      if(this.user && this.user.settings.resendOtpTime){
        this.defaultTime = Number(this.user.settings.resendOtpTime) * 60;
      }
      if(!this.user){
        this.router.navigate(['/login'])
      }
      this.sendOtp(0);
      this.setIntervalForOTP();
      localStorage.removeItem('_sign_out_counter');
    }
  }

  submitForm() {
    this.lastOtp = this.otp;
    this.authService.verifyOTP({ id: this.user._id, otp: this.otp, location: this.location, browser: navigator.userAgent, ip: this.ip })
      .pipe(
        finalize(() => { })
      )
      .subscribe((data) => {
        localStorage.removeItem('_user');
        location.reload();
      }, err => {
        if (!(err instanceof HttpErrorResponse) || err.status !== 401) {
          switch (err.error?.status) {
            case "otp-attemp":
              this.errorMessage = `OTP checking is blocked for ${err.error.minutes ? err.error.minutes : 1} minutes. Please try again after.`;
              break;
            default:
              this.errorMessage = null;
              this.toaster.pop("error", 'OTP is not valid. Try with valid OTP.');
              break;
          }
        }
      });
  }

  setIntervalForOTP = () => {
    setInterval(() => {
      this.resendSeconds = this.resendSeconds - 1
      this.resendSecondText = this.commonService.getTimeInStringSeconds(this.resendSeconds);
    }, 1000);
  }

  allowNumbers = ($event) => {
    if ($event.keyCode < 48 || $event.keyCode > 57 || $event.target.value.length >= 6) {
      return false;
    }
  }

  mobileNumberDisplay = () => {
    if (this.user && this.user.roles.includes('developer')) {
      const phone = decryptField(localStorage.getItem('_developer_sms_number'));
      return phone.slice(0, 2) + '*'.repeat(phone.length - 5) + phone.slice(-3)
    }else if (this.user && this.user.phone) {
      return this.user.phone.slice(0, 2) + '*'.repeat(this.user.phone.length - 5) + this.user.phone.slice(-3)
    }
    return '';
  }

  resendOTP = ($event) => {
    $event.stopPropagation();
    $event.preventDefault();
    this.sendOtp();
  }

  sendOtp = (toaster = 1) => {
    this.authService.resendOTP({ id: this.user._id })
      .subscribe((data) => {
        this.resendSeconds = this.defaultTime;
        this.displayReset = true;
        if(toaster) this.toaster.pop("success", 'OTP resend successfully to ' + this.mobileNumberDisplay());
      }, err => {
        this.displayReset = false;
        if(toaster) this.toaster.pop("error", 'Something went wrong. Please try again.');
      });
  }

  onOtpChange = ($event) => {
    this.otp = $event;
    if(this.otp.length == 6 && this.lastOtp != this.otp){
      this.submitForm();
    }
  }

}
