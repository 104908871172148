import { User } from "../../routes/user/login.response"
import { Company } from "./company";

export class Menu{
    _id?: string;
    company?: string | Company;
    id_num?: Number;
    name: string;
    key?: string;
    translationKey: string;
    type?: number;
    createdBy?: string| User;
    isDeleted?: boolean;

    constructor(options:{
        _id?: string,
        company?: string | Company,
        id_num?: Number,
        name: string,
        key?: string,
        translationKey?: string,
        type?: number,
        createdBy?: string| User,
        isDeleted?: boolean,
    }){
        this._id = options._id || null;
        this.company = options.company || null;
        this.id_num = options.id_num || 0;
        this.name = options.name || null;
        this.key = options.key || null;
        this.translationKey = options.translationKey || null;
        this.type = options.type || 1;
        this.createdBy = options.createdBy || null;
        this.isDeleted = options.isDeleted || false;
    }
}
