<resize-border [dragHolder]="draggableHandle">
  <div [dir]="translationService.dir$ | async">
    <div class="card shadow-none parts_popup">
      <div class="card-header bb" #draggableHandle>
        <h2
          class="w-fit-content"
          [ngClass]="!(translationService.rtl$ | async) ? 'mr-4' : ''"
        >
          {{ "maintenance.AI-MAINTENANCE-PARTS-ASSISTANCE" | translate }}
        </h2>
        <div class="modal-close">
          <img
            class="cursor-pointer"
            src="assets/img/close 2.svg"
            alt="img"
            (click)="bsModalRef.hide()"
          />
        </div>
      </div>
      <div class="card-body bt">
        <div class="chatgpt-suggestions" *ngIf="maintenanceSimulatorResult">
          <ng-container *ngIf="htmlBlocks?.length === 0">
            <div [innerHtml]="maintenanceSimulatorResult | safeHtml"></div>
          </ng-container>
          <ng-container *ngIf="htmlBlocks?.length > 0">
            <div [innerHtml]="htmlBlocks[0]"></div>
            <div class="table-responsive parts">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>{{ dataColumnsText.catalogNumber }}</th>
                    <th>{{ dataColumnsText.name }}</th>
                    <th>{{ dataColumnsText.stockQuantity }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let part of data; let i = index">
                    <td>
                      <div class="form-check container">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [value]="true"
                          (change)="onCheckChange($event, i)"
                          [id]="'ai-part-'+i"
                          [checked]="part.checked"
                        />
                        <label
                          class="form-check-label cursor-pointer checkmark"
                          [for]="'ai-part-'+i"
                        >
                        </label>
                      </div>
                    </td>
                    <td>
                      <ng-container *ngIf="!part.checked">
                        {{ part.catalogNumber }}
                      </ng-container>
                      <ng-container *ngIf="part.checked">
                        <div class="form-group mb-0">
                          <input
                            type="text"
                            class="form-control"
                            [(ngModel)]="part.catalogNumber"
                          />
                          <div *ngIf="part.catalogError && part.catalogNumber">
                            <small class="text-danger">
                              {{ "parts.THIS-CATALOG-NUMBER-ALREADY-EXIST" | translate }}
                            </small>
                          </div>
                          <div *ngIf="formSubmitted && !part.catalogNumber">
                            <small class="text-danger">
                              {{ "general.THIS-FIELD-IS-REQUIRED" | translate }}
                            </small>
                          </div>
                        </div>
                      </ng-container>
                    </td>
                    <td>
                      <ng-container *ngIf="!part.checked">
                        {{ part.name }}
                      </ng-container>
                      <ng-container *ngIf="part.checked">
                        <div class="form-group mb-0">
                          <input
                            type="text"
                            class="form-control"
                            [(ngModel)]="part.name"
                          />
                          <div *ngIf="formSubmitted && !part.name">
                            <small class="text-danger">
                              {{ "general.THIS-FIELD-IS-REQUIRED" | translate }}
                            </small>
                          </div>
                        </div>
                      </ng-container>
                    </td>
                    <td>
                      <ng-container *ngIf="!part.checked">
                        {{ part.stockQuantity }}
                      </ng-container>
                      <ng-container *ngIf="part.checked">
                        <div class="form-group mb-0">
                          <input
                            type="number"
                            class="form-control"
                            [(ngModel)]="part.stockQuantity"
                          />
                          <div *ngIf="formSubmitted && !part.stockQuantity">
                            <small class="text-danger">
                              {{ "general.THIS-FIELD-IS-REQUIRED" | translate }}
                            </small>
                          </div>
                        </div>
                      </ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div [innerHtml]="htmlBlocks[1]"></div>
          </ng-container>
        </div>
        <div class="form-group form-value" *ngIf="!maintenanceSimulatorResult">
          <label class="form-label no-changes" for="file">
            {{ "offsidebar.setting.FILE" | translate }}
          </label>
          <div class="t-presentation" *ngIf="file">
            <a href="#" (click)="viewMaintenanceFile($event)">
              <span class="font-italic link">{{ file?.name }}</span>
            </a>
            <a href="#" (click)="removeMaintenanceFile($event)">
              <img
                src="assets/img/close-back.svg"
                alt="remove"
                [ngClass]="(translationService.rtl$ | async) ? 'mr-2' : 'ml-2'"
                style="width: 15px"
              />
            </a>
          </div>
          <div class="d-flex align-item-center t-presentation">
            <ngx-file-drop
              dropZoneLabel="Drop files here"
              (onFileDrop)="dropped($event)"
              multiple="false"
              class="w-100"
            >
              <ng-template
                ngx-file-drop-content-tmp
                let-openFileSelector="openFileSelector"
              >
                <div class="text-center">
                  <div>
                    {{ "shared.DROP-FILE-HERE" | translate }}
                  </div>
                  <div>
                    <button
                      type="button"
                      class="btn btn-secondary base_btn"
                      (click)="openFileSelector()"
                    >
                      +
                      {{ "maintenance.form-labels.ADD-FILE" | translate }}
                    </button>
                  </div>
                </div>
              </ng-template>
            </ngx-file-drop>
          </div>
        </div>
      </div>
    </div>
    <div class="bt">
      <div class="container">
        <div class="row mt-3 mb-3">
          <div class="col-12 text-center d-flex gap-3 justify-content-center">
            <button
              class="update-button"
              (click)="getAiMaintenanceAssistanceFromFile($event)"
              *ngIf="!maintenanceSimulatorResult"
            >
              {{ "general.GENERATE" | translate }}
            </button>
            <button
              class="update-button"
              (click)="handleSubmit($event)"
              *ngIf="maintenanceSimulatorResult"
            >
              {{ "parts.ADD-TO-PARTS" | translate }}
            </button>
            <button
              class="base-icon-button min-width-160"
              (click)="bsModalRef.hide()"
            >
              {{ "general.CLOSE" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</resize-border>
