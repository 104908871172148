import { Injectable } from "@angular/core";
import { ColDef } from "ag-grid-community";
import { BehaviorSubject, Observable, combineLatest, map, tap } from "rxjs";
import { DataFormatsBackendService } from "./backend/data-formats-backend.service";
import { TranslationsStoreService } from "./translations-store.service";
import { CustomFieldsStoreService } from "./custom-fields-store.service";
import { DataFormat } from "../interfaces/data-format";
import { AuthService } from "../../routes/user/auth.service";
import FieldDefinition from "../constants/field-definitions/data-format";
import { List } from "immutable";

@Injectable({
  providedIn: "root",
})
export class DataFormatsStoreService {
  public columns$: Observable<ColDef[]>;
  private dataFormatsSubject: BehaviorSubject<List<DataFormat>> =
    new BehaviorSubject(List([]));
  public readonly dataFormats$: Observable<List<DataFormat>> =
    this.dataFormatsSubject.asObservable();

  constructor(
    private backend: DataFormatsBackendService,
    private authService: AuthService,
    private customFieldsService: CustomFieldsStoreService,
    private translationsStoreService: TranslationsStoreService
  ) {
    this.columns$ = combineLatest([
      this.authService.user$,
      this.translationsStoreService.languageCode$,
    ]).pipe(
      map(([user, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition;
        const col = this.customFieldsService.toColumnDef(defaults, {
          rowSelector: "id_num",
        });
        return col;
      })
    );
  }

  public getFormat(id: string) {
    const value = this.dataFormatsSubject
      .getValue()
      .find((dataFormat) => dataFormat._id === id);
    if (value) {
      if (value.formatText && value.formatText.includes(".")) {
        const splitted = value.formatText.split(".");
        return splitted[splitted.length - 1].length
      }
    }
    return 3;
  }

  public list() {
    return this.backend
      .list()
      .pipe(
        tap((dataFormats) => this.dataFormatsSubject.next(List(dataFormats)))
      );
  }

  public options() {
    return this.backend.options();
  }

  public create(dataFormat: DataFormat) {
    return this.backend.create(dataFormat).pipe(
      tap((dataFormat) => {
        this.dataFormatsSubject.next(
          this.dataFormatsSubject.getValue().push(dataFormat)
        );
      })
    );
  }

  public update(dataFormat: DataFormat, dataFormatId: string) {
    return this.backend.update(dataFormat, dataFormatId).pipe(
      tap((dataFormat) => {
        const dataFormats = this.dataFormatsSubject.getValue();
        const index = dataFormats.findIndex((u) => u._id === dataFormatId);
        if (index > -1) {
          this.dataFormatsSubject.next(dataFormats.set(index, dataFormat));
        }
      })
    );
  }

  public delete(dataFormatId: string) {
    return this.backend.delete(dataFormatId).pipe(
      tap(() => {
        const dataFormats = this.dataFormatsSubject.getValue();
        const index = dataFormats.findIndex((u) => u._id === dataFormatId);
        if (index > -1) {
          const dataFormat = dataFormats.get(index);
          dataFormat.isDeleted = true;
          this.dataFormatsSubject.next(dataFormats.set(index, dataFormat));
        }
      })
    );
  }
}
