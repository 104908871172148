import { ToolsStoreService } from './../../../core/services/tools-store.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BodyOutputType, Toast, ToasterService } from 'angular2-toaster';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { combineLatest } from 'rxjs';
import { pairwise } from 'rxjs/operators';
import { newFieldDropDown } from '../../../core/constants/new-field-dropdown';
import { newFieldSection } from '../../../core/constants/new-field-section';
import { CommonService } from '../../../core/services/common.service';
import { CustomFieldsStoreService } from '../../../core/services/custom-fields-store.service';
import { DynamicDropdownStoreService } from '../../../core/services/dynamic-dropdown-store.service';
import { MenuStoreService } from '../../../core/services/menu-store.service';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';
import { PreventiveStoreService } from '../../../core/services/preventive-store.service';
import { EventsStoreService } from '../../../core/services/events-store.service';
import { PartsStoreService } from '../../../core/services/parts-store.service';
import { FilesStoreService } from '../../../core/services/files-store.service';
import { UsersStoreService } from '../../../core/services/users-store.service';
import { MaintenanceTypesStoreService } from '../../../core/services/maintenance-types-store.service';
import { MachineStatusesStoreService } from '../../../core/services/machine-statuses-store.service';
import { DepartmentsStoreService } from '../../../core/services/departments-store.service';
import { AuthService } from '../../../routes/user/auth.service';
import { ruleSelector, ruleSelectorColor } from '../../../core/constants/rule-selector';

@Component({
  selector: 'app-edit-column-name',
  templateUrl: './edit-column-name.component.pug',
  styleUrls: ['./edit-column-name.component.scss']
})
export class EditColumnNameComponent implements OnInit {

  form: UntypedFormGroup;
  field: any;
  isUpdated: boolean = false;
  table: string;
  types: any;
  sections: any;
  fields: any = [];

  isShowSection: boolean = false;
  isShowField: boolean = false;
  maxRuleLength = 2;
  rules = [];
  rSelector: any;
  rSelectorColor: any;
  needToShowRule: boolean = false;
  chartType: any;
  needToShowWhatsAppSend: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    public fb: UntypedFormBuilder,
    public auth: AuthService,
    public translationService: TranslationsStoreService,
    public commonService: CommonService,
    private translate: TranslateService,
    private customFieldsStoreService: CustomFieldsStoreService,
    private toaster: ToasterService,
    private router: Router,
    private dynamicDropdownStoreService: DynamicDropdownStoreService,
    private menuStoreService: MenuStoreService,
    private toolService: ToolsStoreService,
    private preventiveStoreService: PreventiveStoreService,
    private eventsStoreService: EventsStoreService,
    private partsStoreService: PartsStoreService,
    private filesStoreService: FilesStoreService,
    private usersStoreService: UsersStoreService,
    private maintenanceTypesStoreService: MaintenanceTypesStoreService,
    private machineStatusesStoreService: MachineStatusesStoreService,
    private departmentsStoreService: DepartmentsStoreService,
  ) { }

  ngOnInit() {
    console.log( 'data', this.field );
    this.rSelector = Object.keys(ruleSelector).reduce((acc, key) => {
      acc.push({ id: key, text: ruleSelector[key] });
      return acc;
    }, []);

    this.rSelectorColor = Object.keys(ruleSelectorColor).reduce((acc, key) => {
      acc.push({ id: key, text: ruleSelectorColor[key] });
      return acc;
    }, []);

    this.form = this.fb.group({
      label: this.fb.control('', Validators.required),
      hide: this.fb.control(false),
      required: this.fb.control(false),
      type: this.fb.control('', Validators.required),
      sectionName: this.fb.control(''),
      fieldName: this.fb.control(''),
      multipleSelection: this.fb.control(false),
      chartDataType: this.fb.control(null),
      dontPresentOnModal: this.fb.control(false),
      preventEditOnModal: this.fb.control(false),
      isRegularColumn: this.fb.control(false),
      displayOnBothModal: this.fb.control(false),
      sendWhatsAppTo: this.fb.control(false),
    });
    this.form.patchValue({
      label: typeof this.field.translationLabel != 'undefined' && this.field.translationLabel != this.translate.instant(this.field.translationLabel) ? this.translate.instant(this.field.translationLabel) : this.field.label,
      required: this.field.required,
      type: this.field.type,
      sectionName: this.field.sectionName,
      fieldName: this.field.fieldName,
      multipleSelection: this.field.multipleSelection,
      dontPresentOnModal: this.field.dontPresentOnModal,
      preventEditOnModal: this.field.preventEditOnModal,
      isRegularColumn: this.field.isRegularColumn,
      displayOnBothModal: this.field.displayOnBothModal,
      sendWhatsAppTo: this.field.sendWhatsAppTo,
    });
    this.chartType = this.field.chartDataType;
    if(this.field.type === "numeric"){
      this.needToShowRule = true;
      this.rules = this.field.rules ? this.field.rules : []
    }
    this.types = Object.keys(newFieldDropDown).reduce((acc, key) => {
      if(key == 'smart-dropdown'){
        if(this.commonService.hasSmartDropDownPermission()) acc.push({ id: key, text: this.translate.instant(newFieldDropDown[key]) });
      }else{
        acc.push({ id: key, text: this.translate.instant(newFieldDropDown[key]) });
      }
      return acc;
    }, []);

    this.menuStoreService.menus$.subscribe(menus => {
      const m = menus.toArray().filter(m => m.type === 1).map((menu) => {
        return { id: menu.key, text: (this.translate.instant(menu.translationKey) != menu.translationKey ? this.translate.instant(menu.translationKey) : menu.name) }
      })
      this.sections = Object.keys(newFieldSection).reduce((acc, key) => {
        acc.push({ id: key, text: this.translate.instant(newFieldSection[key]) });
        return acc;
      }, []).concat(m);
    });
    this.form.get('type')
      .valueChanges
      .pipe(pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (next == "numeric") {
          this.needToShowRule = true;
        } else {
          this.needToShowRule = false;
        }
        if (next == 'smart-dropdown') {
          this.form.controls.sectionName.setValidators([Validators.required])
          this.form.controls.fieldName.setValidators([Validators.required])
          this.isShowSection = true;
        } else {
          this.form.controls.sectionName.setValidators(null)
          this.form.controls.fieldName.setValidators(null)
          this.isShowSection = false;
          this.isShowField = false;
          this.form.controls.sectionName.setValue('');
          this.form.controls.fieldName.setValue('');
        }
      });
    this.form.get('sectionName')
      .valueChanges
      .pipe(pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (next) {
          this.isShowField = true;
          this.updateFieldValue(next)
        }
      });

    if(this.field.sectionName) {
      this.isShowSection = true;
      this.updateFieldValue(this.field.sectionName)
    }
    if(this.field.fieldName) this.isShowField = true;
    this.form.get('fieldName')
      .valueChanges
      .subscribe((value: string) => {
        this.checkNeedToSendWhatsapp(value);
      });
    if(this.field.sendWhatsAppTo){
      this.needToShowWhatsAppSend = true;
    }
  }

  checkNeedToSendWhatsapp(field: string) {
    if(["events", "pm"].includes(this.table) && ["users", "workers"].includes(this.form.value.sectionName) && ["username", "firstName", "lastName", "fullName"].includes(field)) {
      this.needToShowWhatsAppSend = true;
    }else{
      this.needToShowWhatsAppSend = false;
      this.form.controls.sendWhatsAppTo.setValue(false);
    }
  }

  updateFieldValue = (type: string) => {
    switch (type) {
      case 'tool':
        this.toolService.columns$.subscribe(data => {
          this.reduceFieldsValue(data);
        })
        break;
      case 'pm':
        this.preventiveStoreService.columns$.subscribe(data => {
          this.reduceFieldsValue(data);
        })
        break;
      case 'history':
        this.eventsStoreService.columns$.subscribe(data => {
          this.reduceFieldsValue(data);
        })
        break;
      case 'inventory':
        this.partsStoreService.columns$.subscribe(data => {
          this.reduceFieldsValue(data);
        })
        break;
      case 'files':
        this.filesStoreService.columns$.subscribe(data => {
          this.reduceFieldsValue(data);
        })
        break;
      case 'users':
      case 'workers':
        this.usersStoreService.columns$.subscribe(data => {
          this.reduceFieldsValue(data);
        })
        break;
      case 'pm-kind':
        this.maintenanceTypesStoreService.columns$.subscribe(data => {
          this.reduceFieldsValue(data);
        })
        break;
      case 'machine-status':
        this.machineStatusesStoreService.columns$.subscribe(data => {
          this.reduceFieldsValue(data);
        })
        break;
      case 'departments':
        this.departmentsStoreService.columns$.subscribe(data => {
          this.reduceFieldsValue(data);
        })
        break;
      default:
        this.reduceFieldsValue(type, 1);
    }
  }

  reduceFieldsValue(data, isDyanamic = 0) {
    if(isDyanamic){
      combineLatest([this.dynamicDropdownStoreService.columns$, this.customFieldsStoreService.get(data)])
      .subscribe(([cols, columns]) => {
        const fields = columns.toArray();
        const custom = this.customFieldsStoreService.toColumnDef(fields, {
          format: this.customFieldsStoreService.formatCellCustomField.bind(this)
        });
        this.fields = cols.concat(custom).filter(d => !d.hide).reduce((acc, key) => {
          acc.push({ id: key.colId, text: key.headerName });
          return acc;
        }, [])
      });
    }else{
      this.fields = data.filter(d => !d.hide).reduce((acc, key) => {
        acc.push({ id: key.colId, text: key.headerName });
        return acc;
      }, []);
    }
  }

  save = () => {
    if (this.form.valid) {
      let errors = [];
      if (this.needToShowRule && this.rules.length > 0) {
        this.rules.forEach(r => {
          if (!r.selector) errors.push("add-column.SELECTOR-IS-REQUIRED")
          if (!r.value) errors.push("add-column.VALUE-IS-REQUIRED")
          if (!r.color) errors.push("add-column.ALERT-COLOR-IS-REQUIRED")
        });
      }
      if (errors.length > 0) {
        const toast: Toast = {
          type: 'error',
          title: '',
          body: errors.join(' <br/> '),
          bodyOutputType: BodyOutputType.TrustedHtml,
          timeout: 5000
        };
        this.toaster.pop(toast);
      } else {
        this.customFieldsStoreService.updateField({ ...this.form.value, field: this.field, rules: this.rules, translationKey: this.field.translationKey }).subscribe(
          (res) => {
            this.translationService.load().subscribe(() => {
              this.customFieldsStoreService.load()
            });
            if((typeof this.field.translationLabel != 'undefined' && this.field.translationLabel != this.translate.instant(this.field.translationLabel) ? this.translate.instant(this.field.translationLabel) : this.field.label) != this.form.value.label){
              this.isUpdated = true;
            }else{
              this.bsModalRef.hide();
            }
          },
          (err) => {
            this.toaster.pop('error', this.translate.instant('toasters.CUSTOM_FIELDS.ERR_UPDATE'))
          }
        )
      }
    }
  }

  navigate = ($event) => {
    $event.stopPropagation();
    this.router.navigate(['/admin', 'translation'], { queryParams: { search: this.field.translationLabel } });
    this.bsModalRef.hide();
  }

  addRule = ($event) => {
    if (this.rules.length <= this.maxRuleLength) {
      this.rules.push({
        selector: '',
        value: '',
        color: 'none',
      });
    }
  }

  removeRule = ($event, index) => {
    this.rules.splice(index, 1);
  }

  onKeyDown = ($event) => {
    if ($event.keyCode === 69) return false;
  }

  toggleChartType = ($event) => {
    if($event.target.checked){
      this.chartType = $event.target.value;
    }else{
      this.chartType = null;
    }
    this.form.controls.chartDataType.setValue(this.chartType);
  }

}
