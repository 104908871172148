import { Injectable } from "@angular/core";
import { List } from "immutable";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { AuthService } from "../../routes/user/auth.service";
import { SitesBackendService } from "./backend/sites.backend.service";
import { CustomFieldsStoreService } from "./custom-fields-store.service";
import FieldDefinition from "../constants/field-definitions/site";
import { Site } from "../interfaces/site";
import { TranslationsStoreService } from "./translations-store.service";

@Injectable({
  providedIn: "root",
})
export class SitesStoreService {
  protected sitesSubject = new BehaviorSubject(List([]));
  public readonly sites$: Observable<List<Site>> =
    this.sitesSubject.asObservable();
  public columns$: Observable<any[]>;

  constructor(
    protected backend: SitesBackendService,
    protected customFields: CustomFieldsStoreService,
    private translationsStoreService: TranslationsStoreService,
    protected auth: AuthService
  ) {
    this.columns$ = combineLatest([
      this.auth.user$,
      this.customFields.get("sites"),
      this.translationsStoreService.languageCode$,
    ]).pipe(
      map(([user, columns, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(
          (col) =>
            (col.name != "company.name" || user.isRoot()) &&
            (col.name != "isDeleted" || user.isAdmin())
        );
        const fields = columns.toArray();
        const col = this.customFields.toColumnDef(defaults, {
          rowSelector: "id_num",
        });
        const custom = this.customFields.toColumnDef(fields, {
          format: this.customFields.formatCellCustomField.bind(this),
        });

        return col.concat(custom);
      })
    );
  }

  load() {
    return this.backend.list().pipe(
      map((result: Array<Site>) => {
        this.sitesSubject.next(List(result));
        return result;
      })
    );
  }

  create(site: Site) {
    return this.backend.create(site).pipe(
      map((st: Site) => new Site(st)),
      tap((st) => {
        this.sitesSubject.next(this.sitesSubject.getValue().unshift(st));
      })
    );
  }

  update(data: Site) {
    return this.backend.update(data).pipe(
      map((st: Site) => new Site(st)),
      tap((st) => {
        const sites = this.sitesSubject.getValue();
        const idx = sites.findIndex((s: Site) => s._id === data._id);
        this.sitesSubject.next(sites.set(idx, st));
      })
    );
  }

  delete(data: Site) {
    return this.backend.delete(data);
  }

  getList() {
    return this.sitesSubject.getValue();
  }
}
