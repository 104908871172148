import { Injectable } from "@angular/core";
import { AuthService } from "../../routes/user/auth.service";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { DrawingTool } from "../interfaces/drawing-tool";
import { DrawingToolsBackendService } from "./backend/drawing-tools-backend.service";

@Injectable({
  providedIn: "root",
})
export class DrawingToolsStoreService {
  private drawingTools = [];
  public drawingToolsUpdated: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  constructor(
    private backend: DrawingToolsBackendService,
    private authService: AuthService
  ) {
    this.authService.user$.subscribe((user) => {
      if (!user || !user._id || user.isRoot()) {
        return;
      }
      this.get().subscribe();
    });
  }

  get(groupId: string = null) {
    return this.backend.get().pipe(
      tap((drawingTools) => {
        this.drawingTools = drawingTools;
        this.drawingToolsUpdated.next(true);
      })
    );
  }

  getToolsByGroup(group: string) {
    return this.drawingTools.filter((tool) => tool.group === group);
  }

  getTools() {
    return this.drawingTools;
  }

  multiple(tools: DrawingTool[]) {
    return this.backend.multiple(tools).pipe(
      tap((dTools: Array<DrawingTool>) => {
        const drawingToolsArray = [...this.drawingTools];
        dTools.forEach((tool) => {
          const idx = drawingToolsArray.findIndex(
            (t: DrawingTool) => t.tool === tool.tool && t.group === tool.group
          );
          if (idx > -1) {
            drawingToolsArray[idx] = tool;
          } else {
            drawingToolsArray.unshift(tool);
          }
        });
        this.drawingTools = drawingToolsArray;
        this.drawingToolsUpdated.next(true);
      })
    );
  }
}
