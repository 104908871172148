import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { AuthService } from '../../routes/user/auth.service';
import { UserLogs } from '../interfaces/user-logs';
import { UserLogsBackendService } from './backend/user-logs.backend.service';
import { CommonService } from './common.service';
import { CustomFieldsStoreService } from './custom-fields-store.service';
import FieldDefinition from '../constants/field-definitions/user-logs';
import { SitesStoreService } from './sites-store.service';
import { DepartmentsStoreService } from './departments-store.service';
import { timeCalculation } from '../../shared/comparators/date-comparator';
import { uniqBy } from 'lodash';
import { TranslationsStoreService } from './translations-store.service';

@Injectable({
  providedIn: 'root'
})
export class UserLogsStoreService {

  private skip = 0;
  private limit = 2000;
  private totalSubject: BehaviorSubject<Number> = new BehaviorSubject(0);
  protected userLogsSubject = new BehaviorSubject(List([]));
  public readonly userLogs$: Observable<List<UserLogs>> = this.userLogsSubject.asObservable();
  public columns$: Observable<any[]>;

  constructor(
    protected backend: UserLogsBackendService,
    protected customFields: CustomFieldsStoreService,
    protected commonService: CommonService,
    protected sitesStoreService: SitesStoreService,
    protected departmentsStoreService: DepartmentsStoreService,
    private translationsStoreService: TranslationsStoreService,
    protected auth: AuthService
  ) {
    this.onInit()
  }

  onInit() {
    this.columns$ = combineLatest([this.auth.user$, this.translationsStoreService.languageCode$]).pipe(
      map(([user, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(col => (col.name != "company.name" || user.isRoot()) && (col.name != "isDeleted" || user.isAdmin()));
        const col = this.customFields.toColumnDef(defaults, {
          rowSelector: "id_num",
          format: this.formatCell.bind(this)
        });
        return col;
      })
    );
  }

  load() {
    return this.backend.listByLimit(this.skip++, this.limit).pipe(
      tap((result: Array<UserLogs>) => {
        if (this.skip === 0 || this.limit == 0) {
          this.userLogsSubject.next(List(result));
        } else {
          const prev = this.userLogsSubject.getValue().toArray();
          const data = uniqBy([...result, ...prev], '_id');
          this.userLogsSubject.next(List(data))
        }
      }),
      flatMap(() => {
        return this.backend.count().pipe(
          tap(count => {
            this.totalSubject.next(count.total);
          })
        )
      })
    )
  }

  getList() {
    return this.userLogsSubject.getValue();
  }

  totalLogsCount() {
    return this.totalSubject.getValue();
  }

  getLoginMessage = (type) => {
    let message:string = '';
    switch (type) {
      case 1:
        message = 'Login from login screen';
        break;
      case 2:
        message = 'Login from OTP screen';
        break;
      case 3:
        message = 'Refresh page';
        break;
      default:
        break;
    }
    return message;
  }

  getLogoutMessage = (type) => {
    let message:string = '';
    switch (type) {
      case 1:
        message = 'User manually logged out';
        break;
      case 2:
        message = 'OTP user logged out due to close tab';
        break;
      case 3:
        message = 'Logged out on refresh';
        break;
      case 4:
        message = 'Logged out without inactivity';
        break;
      default:
        break;
    }
    return message;
  }

  private formatCell = (col, field) => {
    const sites = this.sitesStoreService.getList();
    const departments = this.departmentsStoreService.getList();
    switch (field.name) {
      case "login":
      case "logout":
        return {
          ...col,
          valueFormatter: (params) => params.data[field.name] && this.commonService.getDateTime(params.data[field.name]),
          valueGetter: (params) => params.data[field.name] && this.commonService.getDateTime(params.data[field.name]),
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data[field.name] ? nodeA.data[field.name] : null, nodeB && nodeB.data[field.name] ? nodeB.data[field.name] : null, nodeA, nodeB, isInverted);
          }
        }
      case "user.site":
        return {
          ...col,
          valueFormatter: (params) => params.data['user']['site'] && sites.filter(s => params.data['user']['site'].includes(s._id.toString())).map(s => s.name).join(', ')
        }
      case "user.department":
        return {
          ...col,
          valueFormatter: (params) => params.data['user']['department'] && departments.filter(d => params.data['user']['department'].includes(d._id.toString())).map(d => d.name).join(', ')
        }
      case "loginType":
        return {
          ...col,
          valueFormatter: (params) => params.data[field.name] && this.getLoginMessage(params.data[field.name])
        }
      case "logoutType":
        return {
          ...col,
          valueFormatter: (params) => params.data[field.name] && this.getLogoutMessage(params.data[field.name])
        }
      default:
        return col;
    }
  }

}
