<!-- START Top Navbar-->
<style>
  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #029dbf;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
</style>
<nav class="navbar topnavbar" role="navigation">
  <!-- START navbar header-->
  <div class="navbar-header d-flex align-items-center">
    <!-- <button class="zt_button--back btn float-left hide-on-med-and-up"
      *ngIf="(service.backLink$ | async) && (service.getPageSlug() !== 'maintenance')" (click)="goBack()">
      <i class="fas fa-chevron-left"></i>
    </button> -->
    <!-- <em class="fas fa-solid fa-bars fa-lg cursor-pointer hide-on-sm" (click)="closeMenu()" [ngClass]="(translationService.rtl$ | async) ? 'mr-2' : 'ml-2'"></em> -->
    <a class="navbar-brand" href="#" [routerLink]="['/main/maintenance']">
      <!--
                  <div class="brand-logo">
                      <img class="img-fluid" src="assets/img/logo.png" alt="App Logo" />
                  </div>
                  <div class="brand-logo-collapsed">
                      <img class="img-fluid" src="assets/img/logo-single.png" alt="App Logo" />
                  </div>
      -->
      <h4 class="brand-logo">
        <app-company-avatar></app-company-avatar>
        <!-- <img
          src="assets/img/Logo land - Regular White@3x.png"
          alt="logo"
          class="app-logo"
        /> -->
      </h4>
      <h2 class="brand-logo-collapsed">
        <app-company-avatar></app-company-avatar>
      </h2>
    </a>
    <!-- <div class="zt_header zt_header--main hide-on-med-and-up" *ngIf="service.getPageSlug() === 'maintenance'">
      <a [routerLink]="['/']" class="zt_brand zt_brand--main navbar-brand">
        <h4 class="brand-logo">Anymaint</h4>
      </a>
      <button class="zt_button zt_logout"
        (click)="dropdown.hide(); userService.logout()">
        Log out
      </button>
    </div> -->
  </div>

  <ul class="navbar-nav mr-auto flex-row hide-on-small"></ul>
  <ul class="navbar-nav flex-row hide-on-small">
    <li class="nav-item" *ngIf="!user">
      <a class="nav-link" [routerLink]="['/signup']">
        {{ 'header.SIGNUP' | translate }}
      </a>
    </li>
  </ul>
  <!-- END Right Navbar-->
  <div class="zt_navabar-right" [dir]="translationService.dir$ | async">
    <app-navsearch (onclose)="setNavSearchVisible(true)"></app-navsearch>

    <ng-container *ngIf="user$ | async as user">
      <span class="nav-item header-user-name" *ngIf="user">
        <span class="text-white mx-3 font-weight-bold" [ngClass]="{'ml-10': (translationService.rtl$ | async), 'mr-10': !(translationService.rtl$ | async)}">
          {{ user.firstName || user.email }}
        </span>
      </span>
    </ng-container>

    <ng-container *ngIf="screenWidth <= 600 || windowsService.getWindowSize() <= 600">
      <span
        class="nav-item dropdown dropdown-list zt_navabar-dropdown"
        (click)="menuPopup($event)"
      >
        <span class="zt_navabar-name" [routerLink]="['/login']" *ngIf="!user">
          {{ 'header.LOGIN' | translate }}
        </span>
        <span class="zt_navabar-profile">
          <span class="zt_navabar-name m-0"></span>
          <app-avatar [src]=""></app-avatar>
        </span>
      </span>
    </ng-container>

    <ng-container *ngIf="screenWidth > 600 || windowsService.getWindowSize() > 600">
      <span
        class="nav-item dropdown dropdown-list zt_navabar-dropdown"
        dropdown
        #dropdown="bs-dropdown"
      >
        <span class="zt_navabar-name" [routerLink]="['/login']" *ngIf="!user">
          {{ 'header.LOGIN' | translate }}
        </span>
        <span class="zt_navabar-profile dropdown-toggle" dropdownToggle>
          <span class="zt_navabar-name m-0"></span>
          <app-avatar [src]=""></app-avatar>
          <div
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right animated flipInX"
          >
            <span class="dropdown-menu-arrow" [ngClass]="(translationService.rtl$ | async) ? 'left-arrow' : ''"></span>
            <div class="dropdown-item">
              <div
                class="list-group"
                routerLink="/settings/"
              >
                <div class="list-group-item list-group-item-action">
                  <div class="media">
                    <div class="align-self-start mr-2">
                      <img src="assets/img/settings.svg" alt="image">
                    </div>
                    <div class="media-body">
                      <p href="#" class="m-0">
                        {{ 'header.SETTINGS' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="dropdown-item" *ngIf="userService.hasPermission(['admin', 'tool_admin']) && !userService.isRoot()">
              <div
                class="list-group"
                routerLink="/setup-wizard/"
              >
                <div class="list-group-item list-group-item-action">
                  <div class="media">
                    <div class="align-self-start mr-2">
                      <img src="assets/img/setup-wizard.svg" alt="image">
                    </div>
                    <div class="media-body">
                      <p href="#" class="m-0">
                        {{ 'on-boarding.SETUP-WIZARD' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="dropdown-item">
              <div
                class="list-group"
                (click)="dropdown.hide(); userService.logout()"
              >
                <div class="list-group-item list-group-item-action last-child">
                  <div class="media">
                    <div class="align-self-start mr-2">
                      <img src="assets/img/logout.svg" alt="image">
                    </div>
                    <div class="media-body">
                      <p href="#" class="m-0 red">
                        {{ 'header.SIGN-OUT' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      </span>
    </ng-container>
  </div>
</nav>
<!-- END Top Navbar-->
