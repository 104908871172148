import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslationsStoreService } from '../../../../core/services/translations-store.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.pug',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {

  chart = null;
  chartData: any;
  canvas: any;
  ctx: any;
  x: any;
  y: any;
  title: string;
  constructor(
    public translationService: TranslationsStoreService,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {

    Chart.pluginService.register({
      beforeDraw: function (chart, easing) {
        if (chart.config.options.chartArea && chart.config.options.chartArea.backgroundColor) {
          var ctx = chart.chart.ctx;
          ctx.save();
          ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
          ctx.fillRect(0, 0, 2500, 2500);
          ctx.restore();
        }
      }
    });

    this.canvas = $('.canvasChart').length > 1 ? $('.canvasChart')[1] : $('.canvasChart')[0];
    this.ctx = this.canvas.getContext('2d');
    this.chart = new Chart(this.ctx, {
      type: 'bar',
      data: {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: '#064a67',
          borderColor: '#064a67',
          borderWidth: 1
        }]
      },
      options: {
        legend: {
          display: false,
          align: 'center'
        },
        scales: {
          yAxes: [{
            ticks: {
              fontSize: 11,
              beginAtZero: true
            },
            gridLines: {
              borderDash: [1, 1],
              color: "#DEDEDE"
            }
          }],
          xAxes: [{
            ticks: {
              fontSize: 11,
              callback: function (value, index, values) {
                return value.length > 8 && values.length == 10 ? value.substring(0, 6) + '..' : value;
              }
            },
            gridLines: {
              display: false
            }
          }]
        },
        chartArea: {
          backgroundColor: 'white'
        }
      }
    });

    this.translationService.rtl$.subscribe(rtl => {
      if (rtl) {
        this.chart.data.labels = [...this.x].reverse();
        this.chart.data.datasets[0].data = [...this.y].reverse();
        this.chart.options.scales.yAxes[0].position = 'right';
      } else {
        this.chart.data.labels = [...this.x];
        this.chart.data.datasets[0].data = [...this.y];
      }
      this.chart.update()
    });

  }

}
