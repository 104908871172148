import { IFieldPivot } from "../../interfaces/field-pivot";

const FieldDefinition: IFieldPivot[] = [
  {
    name: 'part.catalogNumber',
    label: 'table.transaction.table-title.CATALOG-NUMBER',
    type: 'textbox',
    required: false,
    readonly: true,
    rowGroup: true,
    enableRowGroup: true,
    valueGetter: params => params.data && params.data.part ? `${params.data.part.catalogNumber} ${params.data.part.catalogNumber ? "|" : ""} ${params.data.part.name}` : "",
  },
  {
    name: 'transactionMonth',
    label: 'Month',
    type: 'textbox',
    required: false,
    readonly: true,
    pivot: true,
    enableRowGroup: true,
  },
  {
    name: 'part.id_num',
    label: 'table.transaction.table-title.PART-ID',
    type: 'textbox',
    required: false,
    readonly: true,
    enableRowGroup: true,
  },
  {
    name: 'part.name',
    label: 'table.transaction.table-title.PART-NAME',
    type: 'textbox',
    required: false,
    readonly: true,
    enableRowGroup: true,
  },
  {
    name: 'stockQuantity',
    label: 'table.transaction.table-title.STOCK-QUANTITY',
    type: 'textbox',
    required: false,
    readonly: true,
    aggFunc: "sum",
  },
  {
    name: 'changeStockQuantity',
    label: 'table.transaction.table-title.CHANGE-STOCK-QUANTITY',
    type: 'textbox',
    required: false,
    readonly: true,
    aggFunc: "sum",
  },
  {
    name: 'oldStockQuantity',
    label: 'table.transaction.table-title.OLD-STOCK-QUANTITY',
    type: 'textbox',
    required: false,
    readonly: true,
    aggFunc: "sum",
  },
  {
    name: 'part.minimalQuantity',
    label: 'table.transaction.table-title.MINIMAL-QUANTITY',
    type: 'textbox',
    required: false,
    readonly: true,
  },
  {
    name: 'part.units',
    label: 'table.transaction.table-title.UNITS',
    type: 'textbox',
    required: false,
    readonly: true,
    chartDataType: "series",
  },
  {
    name: 'moveKind',
    label: 'table.transaction.table-title.MOVE-KIND',
    type: 'textbox',
    required: false,
    readonly: true,
  },
  {
    name: 'part.shelf',
    label: 'table.transaction.table-title.SHELF',
    type: 'textbox',
    required: false,
    readonly: true,
  },
  {
    name: 'part.fatherId',
    label: 'table.transaction.table-title.FATHER-ID',
    type: 'textbox',
    required: false,
    readonly: true,
  },
  {
    name: 'part.fatherName',
    label: 'table.transaction.table-title.FATHER-NAME',
    type: 'textbox',
    required: false,
    readonly: true,
  },
  {
    name: 'updatedBy.username',
    label: 'table.transaction.table-title.UPDATED-BY',
    type: 'textbox',
    required: false,
    readonly: true,
    valueGetter: params => params.data && (!params.data.updatedBy ? "" : `${params.data.updatedBy.firstName} ${params.data.updatedBy.lastName} (${params.data.updatedBy.username})`)
  },
  {
    name: 'updatedAt',
    label: 'table.transaction.table-title.DATE-UPDATED',
    type: 'datepicker',
    required: false,
    readonly: true
  },
];

export default
  {
    FieldDefinition
  }