import { flatMap, map, tap } from 'rxjs/operators';
import { AiHelper } from './../interfaces/ai-helper';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { AiHelperBackendService } from './backend/ai-helper.backend.service';
import { CustomFieldsStoreService } from './custom-fields-store.service';
import { AuthService } from '../../routes/user/auth.service';
import FieldDefinition from '../constants/field-definitions/ai-helper';
import { KeyWord } from '../interfaces/keywords';
import { uniqBy } from 'lodash';
import { TranslationsStoreService } from './translations-store.service';

@Injectable({
  providedIn: 'root'
})
export class AiHelperStoreService {
  private skip = 0;
  private limit = 0;

  private allFetched: BehaviorSubject<boolean> = new BehaviorSubject(false);

  protected aiHelperSubject = new BehaviorSubject(List([]));
  public readonly aiHelpers$: Observable<List<AiHelper>> = this.aiHelperSubject.asObservable();

  protected keywordOptionsSubject = new BehaviorSubject(List([]));
  public readonly keywordOptions$: Observable<List<KeyWord>> = this.keywordOptionsSubject.asObservable();
  private totalSubject: BehaviorSubject<number> = new BehaviorSubject(0);

  public columns$: Observable<any[]>;

  constructor(
    protected backend: AiHelperBackendService,
    protected customFields: CustomFieldsStoreService,
    private translationsStoreService: TranslationsStoreService,
    protected auth: AuthService
  ) {
    this.onInit()
  }

  onInit() {
    this.auth.user$.subscribe(user => {
      if (user && user.isRoot()) {
        this.limit = 500;
      }
    });
    this.columns$ = combineLatest([this.auth.user$, this.customFields.get('ai-helper'), this.translationsStoreService.languageCode$]).pipe(
      map(([user, columns, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(col => (col.name != "company.name" || user.isRoot()) && (col.name != "isDeleted" || user.isAdmin()));
        const fields = columns.toArray();
        const col = this.customFields.toColumnDef(defaults, {
          format: this.formatCell.bind(this),
          rowSelector: "id_num"
        });
        const custom = this.customFields.toColumnDef(fields, {
          format: this.customFields.formatCellCustomField.bind(this)
        });

        return col.concat(custom);
      })
    );
  }
  private formatCell = (col, field) => {
    switch (field.name) {
      case "descriptionInNative":
        return {
          ...col,
          valueGetter: (params) => {
            return params.data.descriptionInNative ? params.data.descriptionInNative : (params.data?.toolObject?.name || null);
          },
        }
      default:
        return col;
    }
  }

  getTotalCount = () => {
    return this.totalSubject.getValue();
  }

  getAllFetched = () => {
    return this.allFetched.getValue();
  }

  count = () => {
    if (!this.getTotalCount()) {
      this.backend.count().pipe(
        tap(count => {
          this.totalSubject.next(count.total);
        })
      ).subscribe()
    }
  }

  updateLimit = (limit: number) => {
    this.skip = 0;
    this.limit = limit;
    this.allFetched.next(false);
    this.aiHelperSubject.next(List([]));
    this.count();
  }

  load(sortColumn: string, sortType: string) {
    return this.backend.list(this.skip++, this.limit, sortColumn, sortType).pipe(
      map(aiReports => aiReports.map((productionReport) => this.remapAiHelper(productionReport))),
      tap(aiReports => {
        if (aiReports.length < this.limit || this.limit === 0) this.allFetched.next(true);
        if (this.skip === 0 || this.limit == 0) {
          this.aiHelperSubject.next(List(aiReports));
        } else {
          const prev = this.aiHelperSubject.getValue().toArray();
          const data = uniqBy([...aiReports, ...prev], '_id');
          this.aiHelperSubject.next(List(data))
        }
      })
    )
  }

  remapAiHelper = (ai) => {
    if (ai.tool) {
      ai.toolObject = ai.tool;
      ai.tool = ai.toolObject._id;
    }
    ai.keywordsObject = ai.keywords || [];
    ai.keywords = ai.keywordsObject.length > 0 ? ai.keywordsObject.map((a: { _id: string; }) => a._id) : null;
    return ai;
  }

  create(data: AiHelper) {
    return this.backend.create(data).pipe(
      map((ai) => this.remapAiHelper(ai)),
      tap(ai => {
        this.aiHelperSubject.next(this.aiHelperSubject.getValue().unshift(ai));
      })
    );
  }

  update(data: AiHelper) {
    return this.backend.update(data).pipe(
      map((ss) => this.remapAiHelper(ss)),
      tap(ss => {
        const ais = this.aiHelperSubject.getValue();
        const idx = ais.findIndex((q) => q._id === ss._id);
        this.aiHelperSubject.next(ais.set(idx, ss));
      })
    );
  }

  delete(data: AiHelper) {
    return this.backend.delete(data).pipe(
      tap(() => {
        const ais = this.aiHelperSubject.getValue();
        const idx = ais.findIndex((u) => u._id === data._id);
        this.aiHelperSubject.next(ais.splice(idx, 1));
      })
    );
  }

  getKeyWordsOption = () => {
    return this.keywordOptionsSubject.getValue().toArray();
  }

  options = (isForcefully: boolean = false) => {
    if (isForcefully || this.getKeyWordsOption().length === 0) {
      this.backend.keyWordOptions().pipe(
        tap(options => {
          this.keywordOptionsSubject.next(List(options));
        })
      ).subscribe()
    }
  }

  syncTool() {
    return this.backend.syncTool()
  }

  generateFineTuningFile() {
    return this.backend.generateFineTuningFile()
  }

  generateFineTuning() {
    return this.backend.generateFineTuning()
  }

  checkFineTuneStatus() {
    return this.backend.checkFineTuneStatus()
  }

  checkSuggestions() {
    return this.backend.checkSuggestions()
  }

  checkFineTuneResult(ids: string[]) {
    return this.backend.checkFineTuneResult(ids).pipe(
      map((results) => {
        results.forEach((result: { aiHelper: AiHelper; text: number; }) => {
          const ais = this.aiHelperSubject.getValue();
          const idx = ais.findIndex((q) => q._id === result.aiHelper._id);
          if(result.aiHelper.company) delete result.aiHelper.company;
          this.aiHelperSubject.next(ais.set(idx, Object.assign(ais.get(idx), result.aiHelper)));
        })
        return results
      })
    )
  }

  exportToExcel() {
    return this.backend.exportToExcel()
  }

  getList() {
    return this.aiHelperSubject.getValue();
  }
}
