<div *ngIf="company$ | async as company">
  <ng-container *ngIf="company.image; else noImage">
    <app-image-presentation
      [fileId]="company.image"
      [alt]="company.name"
      class="app-logo"
    ></app-image-presentation>
  </ng-container>
</div>

<ng-template #noImage>
  <img
    src="assets/img/Logo land - Regular White@3x.png"
    alt="logo"
    class="app-logo"
  />
</ng-template>
