import { User } from "../../routes/user/login.response"
import { Company } from "./company"
import { Unit } from "./unit"
import { Warehouse } from "./warehouse"

export class Part {
    _id?: String
    company: Company
    id_num: Number
    catalogNumber: String
    name: String
    stockQuantity: Number
    oldStockQuantity: Number
    changeStockQuantity: Number
    minimalQuantity: Number
    unit: String
    unitObject?: Unit
    moveKind: String
    warehouse?: String
    warehouseObject?: Warehouse
    shelf: String
    fatherId: String
    fatherName: String
    expiration?: Date
    updatedOn: Date
    updatedBy: User
    customFields?: Object
    image?: string
    batchNumber?: string
    createdAt?: Date
    updatedAt?: Date
    isDeleted?: boolean

    constructor(options: {
        _id?: String,
        company?: Company,
        id_num?: Number,
        catalogNumber?: String,
        name?: String,
        stockQuantity?: Number,
        oldStockQuantity?: Number,
        changeStockQuantity?: Number,
        minimalQuantity?: Number,
        unitObject?: Unit,
        unit?: String,
        moveKind?: String,
        warehouse?: String
        warehouseObject?: Warehouse
        shelf?: String,
        fatherId?: String,
        fatherName?: String,
        expiration?: Date,
        updatedOn?: Date,
        updatedBy?: User,
        customFields?: Object,
        image?: string,
        batchNumber?: string,
        createdAt?: Date,
        updatedAt?: Date,
        isDeleted?: boolean,
    }) {
        this._id = options._id || undefined
        this.company = options.company || undefined
        this.id_num = options.id_num || 0
        this.catalogNumber = options.catalogNumber || ""
        this.name = options.name || ""
        this.stockQuantity = options.stockQuantity || 0
        this.oldStockQuantity = options.oldStockQuantity || 0
        this.changeStockQuantity = options.changeStockQuantity || 0
        this.minimalQuantity = options.minimalQuantity || 0
        this.unit = options.unit || ""
        this.unitObject = options.unitObject || null
        this.moveKind = options.moveKind || ""
        this.warehouse = options.warehouse || null
        this.warehouseObject = options.warehouseObject || null
        this.shelf = options.shelf || ""
        this.fatherId = options.fatherId || ""
        this.fatherName = options.fatherName || ""
        this.expiration = options.expiration || null,
        this.updatedBy = options.updatedBy || null
        this.updatedOn = options.updatedOn || null
        this.customFields = options.customFields || null
        this.image = options.image || null
        this.batchNumber = options.batchNumber || null
        this.updatedAt = options.updatedAt || null
        this.isDeleted = options.isDeleted || false
        this.createdAt = options.createdAt || null;
    }
}