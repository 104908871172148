import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public loading:Array<String> = [];
  constructor() { }

  getLoader (){
    return of(this.loading);
  }

  add (message:string = ""){
    this.loading.push(message);
  }

  remove (message:string = ""){
    const index = this.loading.lastIndexOf(message);
    if (index > -1){
      this.loading.splice(index, 1);
    }else{
      this.loading.pop();
    }
  }
}
