import { IField } from "../../interfaces/field";
import { timeCalculation } from '../../../shared/comparators/date-comparator';
const FieldDefinition: IField[] = [
    {
        name: 'company.name',
        label: 'table.events.table-title.COMPANY',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'event-logs',
    },
    {
        name: 'currentData.id_num',
        label: 'table.events.table-title.Event-ID',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'event-logs',
    },
    {
        name: 'tool.name',
        label: 'table.events.table-title.TOOL-NAME',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'event-logs',
    },
    {
        name: 'tool.id_num',
        label: 'table.pm.table-title.TOOL-ID',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'event-logs',
    },
    {
        name: 'currentData.type',
        label: 'table.events.table-title.TYPE',
        type: 'textbox',
        required: true,
        readonly: true,
        section: 'event-logs',
    },
    {
        name: 'currentData.worker.displayName',
        label: 'table.events.table-title.WORKER',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'event-logs',
    },
    {
        name: 'currentData.start',
        label: 'table.events.table-title.START',
        type: 'datepicker',
        required: false,
        readonly: true,
        section: 'event-logs',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.start, nodeB && nodeB.data.start, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'currentData.finish',
        label: 'table.events.table-title.FINISH',
        type: 'datepicker',
        required: false,
        readonly: true,
        section: 'event-logs',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.finish, nodeB && nodeB.data.finish, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'currentData.scheduleWork',
        label: 'maintenance.form-labels.SCHEDULE-WORK',
        type: 'datepicker',
        required: false,
        readonly: true,
        section: 'event-logs',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.scheduleWork ? nodeA.data.scheduleWork : null, nodeB && nodeB.data.scheduleWork ? nodeB.data.scheduleWork : null, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'currentData.problemDesc',
        label: 'table.events.table-title.PROBLEM',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'event-logs',
    },
    {
        name: 'currentData.solutionDesc',
        label: 'table.events.table-title.SOLUTION',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'event-logs',
    },
    {
        name: 'currentData.parts',
        label: 'table.events.table-title.PARTS-REPLACED',
        type: 'textbox',
        required: false,
        readonly: true,
        valueGetter: params => params.data && params.data.currentData.parts && params.data.currentData.parts.length ? params.data.currentData.parts.filter(part => part.part && part.part.name).map(part => part.part.catalogNumber + " | " + part.part.name + " (" + part.quantity + ")").join("; ") : "",
        tooltipValueGetter: params => params.data && params.data.currentData.parts && params.data.currentData.parts.length ? params.data.currentData.parts.filter(part => part.part && part.part.name).map(part => part.part.catalogNumber + " | " + part.part.name + " (" + part.quantity + ")").join("\n") : "",
        section: 'event-logs',
    },
    {
        name: 'currentData.eventType',
        label: 'table.events.table-title.PM/FM',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'even-logs',
        valueGetter: params => params.data.currentData.maintenance ? "PM" : "FM"
    },
    {
        name: 'currentData.responsiblePersonObject.displayName',
        label: 'table.pm.table-title.RESPONSIBLE-PERSON',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'event-logs',
    },
    {
        name: 'currentData.departmentObject.name',
        label: 'table.users.table-title.DEPARTMENT',
        type: 'textbox',
        options: [],
        required: false,
        readonly: true,
    },
    {
        name: 'currentData.file',
        label: 'table.pm.table-title.FILE-NAME',
        type: 'textbox',
        required: true,
        readonly: true,
        section: 'event-logs',
    },
    {
        name: 'currentData.isDeleted',
        label: 'general.DELETED',
        type: "checkbox",
        readonly: true,
        section: 'event-logs',
    },
    {
        name: 'currentData.isWaReporter',
        label: 'table.pm.table-title.WA-REPORTER',
        type: "checkbox",
        readonly: true,
        section: 'event-logs',
    },
    {
        name: 'createdAt',
        label: 'table.pm.table-title.TIME-OF-CHANGE',
        type: "datepicker",
        readonly: true,
        section: 'event-logs',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.createdAt, nodeB && nodeB.data.createdAt, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'changedData',
        label: 'table.pm.table-title.WHAT-CHANGED',
        type: "textbox",
        readonly: true,
        section: 'event-logs',
        valueGetter: params => {
            return params.data.changedData ? Object.keys(params.data.changedData).join(', ') : '';
        },
    },
    {
        name: 'currentData.updatedBy',
        label: 'table.pm.table-title.WHO-DID-CHANGE',
        type: "textbox",
        readonly: true,
        section: 'event-logs',
        valueGetter: params => {
            return params.data.currentData.updatedBy ? params.data.currentData.updatedBy.displayName : (params.data.currentData.createdBy ? params.data.currentData.createdBy.displayName : '');
        },
    },
    {
        name: 'event.createdAt',
        label: 'table.pm.table-title.HOW-MUCH-TIME-FROM-START',
        type: "textbox",
        readonly: true,
        section: 'event-logs'
    },
    {
        name: 'currentData.signaturePersonObject.displayName',
        label: 'table.pm.table-title.SIGNATURE-PERSON',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'event-logs',
        valueGetter: params => {
            return params.data.currentData.signaturePersonObject ? params.data.currentData.signaturePersonObject.displayName : '';
        },
    },
    {
        name: 'currentData.qaApprovalObject.displayName',
        label: 'table.events.table-title.QA-APPROVED-PERSON',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'event-logs',
        valueGetter: params => {
            return params.data.currentData.qaApprovalObject ? params.data.currentData.qaApprovalObject.displayName : '';
        },
    },
    {
        name: 'currentData.qaApprovalTime',
        label: 'table.events.table-title.QA-APPROVED-TIME',
        type: 'datepicker',
        required: false,
        readonly: true,
        section: 'event-logs',
    },
];

export default {
    FieldDefinition
}