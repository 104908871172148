<!-- START Sidebar (left)-->
<div class="aside-inner" [dir]="translationService.dir$ | async" appResizable [resizableMinWidth]=172 [resizableMaxWidth]=330 [resizableGrabWidth]=1 [isRTL]="translationService.rtl$ | async">
    <nav class="sidebar" sidebar-anyclick-close="" [class.show-scrollbar]="settings.getLayoutSetting('asideScrollbar')">
        <!-- START sidebar nav-->
        <ul class="sidebar-nav">
            <li *ngFor='let item of menuItems' [ngClass]="{'nav-heading': item.heading}" [routerLinkActive]="['active']">
                <!-- menu heading -->
                <span *ngIf="item.heading">{{item.text | translate}}</span>
                <!-- external links -->
                <a href *ngIf="!item.heading && !item.submenu && item.elink" [attr.target]="item.target" [attr.href]="item.elink" title="{{item.text | translate}}" class="mt-2 sidebar-menu-items">
                    <span class="float-right" *ngIf="item.alert" [ngClass]="item.label || 'badge badge-success'">{{item.alert}}</span>
                    <img *ngIf="item.img" src="assets/img/{{item.img}}" alt="{{item.label}}">
                    <!-- <em class="{{item.icon}}" *ngIf="item.icon"></em> -->
                    <span>{{item.text | translate}}</span>
                </a>
                <!-- single menu item -->
                <a href *ngIf="!item.heading && !item.submenu && !item.elink" [routerLink]="item.link" [attr.route]="item.link" title="{{item.text | translate}}"
                    (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)" class="mt-2 sidebar-menu-items">
                    <span class="float-right" *ngIf="item.alert" [ngClass]="item.label || 'badge badge-success'">{{item.alert}}</span>
                    <img *ngIf="item.img" src="assets/img/{{item.img}}" alt="{{item.label}}">
                    <!-- <em class="{{item.icon}}" *ngIf="item.icon"></em> -->
                    <span>{{item.text | translate}}</span>
                </a>
            </li>
        </ul>
        <hr>
        <ul class="sidebar-nav">
            <li *ngFor='let item of menuItems' [ngClass]="{'nav-heading': item.heading}" [routerLinkActive]="['active']">
                <a href *ngIf="!item.heading && item.submenu && item.submenu.length > 0" title="{{item.text | translate}}"
                    (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)" class="mt-2 sidebar-menu-items">
                        <span class="float-right" *ngIf="item.alert" [ngClass]="item.label || 'badge badge-success'">{{item.alert}}</span>
                        <!-- <em class="{{item.icon}}" *ngIf="item.icon"></em> -->
                        <span>{{item.text | translate}}</span>
                </a>
                <!-- SUBLEVEL -->
                <ul *ngIf="item.submenu" class="sidebar-nav sidebar-subnav submenu-bar sidebar-menu-open" [ngClass]="{'default-open opening': item.defaultOpen}" [routerLinkActive]="['opening']">
                    <li class="sidebar-subnav-header">{{item.text | translate}}</li>
                    <li *ngFor='let subitem of item.submenu' [routerLinkActive]="['active']">
                        <!-- sublevel: external links -->
                        <a href *ngIf="!subitem.heading && !subitem.submenu && subitem.elink && !subitem.click" [attr.target]="subitem.target" [attr.href]="subitem.elink" title="{{subitem.text | translate}}" class="sidebar-menu-items">
                            <span class="float-right" *ngIf="subitem.alert" [ngClass]="subitem.label || 'badge badge-success'">{{subitem.alert}}</span>
                            <em class="{{subitem.icon}}" *ngIf="subitem.icon"></em>
                            <span>{{subitem.text | translate}}</span>
                        </a>
                        <!-- sublevel: single menu item  -->
                        <a href *ngIf="!subitem.submenu && !subitem.elink && !subitem.click" [routerLink]="subitem.link" [attr.route]="subitem.link" title="{{subitem.text | translate}}" class="sidebar-menu-items">
                            <span class="float-right" *ngIf="subitem.alert" [ngClass]="subitem.label || 'badge badge-success'">{{subitem.alert}}</span>
                            <em class="{{subitem.icon}}" *ngIf="subitem.icon"></em>
                            <span>{{subitem.text | translate}}</span>
                        </a>
                        <!-- sublevel: single menu item click  -->
                        <a href="#" *ngIf="!subitem.submenu && !subitem.elink && subitem.click" title="{{subitem.text | translate}}" class="sidebar-menu-items" (click)="addNewSubMenu($event, subitem.click)">
                            <span class="float-right" *ngIf="subitem.alert" [ngClass]="subitem.label || 'badge badge-success'">{{subitem.alert}}</span>
                            <em class="{{subitem.icon}}" *ngIf="subitem.icon"></em>
                            <span>{{subitem.text | translate}}</span>
                        </a>
                        <!-- sublevel: has submenu -->
                        <a href *ngIf="subitem.submenu" title="{{subitem.text | translate}}"
                            (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)" class="sidebar-menu-items">
                                <span class="float-right" *ngIf="subitem.alert" [ngClass]="subitem.label || 'badge badge-success'">{{subitem.alert}}</span>
                                <em class="{{subitem.icon}}" *ngIf="subitem.icon"></em>
                                <span>{{subitem.text | translate}}</span>
                        </a>
                        <!-- SUBLEVEL 2 -->
                        <ul *ngIf="subitem.submenu" class="sidebar-nav sidebar-subnav level2" [routerLinkActive]="['opening']">
                            <li *ngFor='let subitem2 of subitem.submenu' [routerLinkActive]="['active']" class="subnav-item">
                                <!-- sublevel 2: single menu item  -->
                                <a href *ngIf="!subitem2.submenu" [routerLink]="subitem2.link" [attr.route]="subitem2.link" title="{{subitem2.text | translate}}" class="sidebar-menu-items">
                                    <span class="float-right" *ngIf="subitem2.alert" [ngClass]="subitem2.label || 'badge badge-success'">{{subitem2.alert}}</span>
                                    <em class="{{subitem2.icon}}" *ngIf="subitem2.icon"></em>
                                    <span>{{subitem2.text | translate}}</span>
                                </a>
                                <!-- sublevel2: has submenu -->
                                <a href *ngIf="subitem2.submenu" title="{{subitem2.text | translate}}"
                                    (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)" class="sidebar-menu-items">
                                        <span class="float-right" *ngIf="subitem2.alert" [ngClass]="subitem2.label || 'badge badge-success'">{{subitem2.alert}}</span>
                                        <em class="{{subitem2.icon}}" *ngIf="subitem2.icon"></em>
                                        <span>{{subitem2.text | translate}}</span>
                                </a>
                                <!-- SUBLEVEL 3 -->
                                <ul *ngIf="subitem2.submenu" class="sidebar-nav sidebar-subnav level3" [routerLinkActive]="['opening']">
                                    <li *ngFor='let subitem3 of subitem2.submenu' [routerLinkActive]="['active']">
                                        <!-- sublevel 2: single menu item  -->
                                        <a href *ngIf="!subitem3.submenu" [routerLink]="subitem3.link" [attr.route]="subitem3.link" title="{{subitem3.text | translate}}" class="sidebar-menu-items">
                                            <span class="float-right" *ngIf="subitem3.alert" [ngClass]="subitem3.label || 'badge badge-success'">{{subitem3.alert}}</span>
                                            <em class="{{subitem3.icon}}" *ngIf="subitem3.icon"></em>
                                            <span>{{subitem3.text | translate}}</span>
                                        </a>
                                        <!-- sublevel3: has submenu -->
                                        <a href *ngIf="subitem3.submenu" title="{{subitem3.text| translate}}"
                                            (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)" class="sidebar-menu-items">
                                                <span class="float-right" *ngIf="subitem3.alert" [ngClass]="subitem3.label || 'badge badge-success'">{{subitem3.alert}}</span>
                                                <em class="{{subitem3.icon}}" *ngIf="subitem3.icon"></em>
                                                <span>{{subitem3.text | translate}}</span>
                                        </a>
                                        <!-- SUBLEVEL 4 -->
                                        <ul *ngIf="subitem3.submenu" class="sidebar-nav sidebar-subnav level3" [routerLinkActive]="['opening']">
                                            <li *ngFor='let subitem4 of subitem3.submenu' [routerLinkActive]="['active']">
                                                <!-- sublevel 2: single menu item  -->
                                                <a href *ngIf="!subitem4.submenu" [routerLink]="subitem4.link" [attr.route]="subitem4.link" title="{{subitem4.text | translate}}" class="sidebar-menu-items">
                                                    <span class="float-right" *ngIf="subitem4.alert" [ngClass]="subitem4.label || 'badge badge-success'">{{subitem4.alert}}</span>
                                                    <em class="{{subitem4.icon}}" *ngIf="subitem4.icon"></em>
                                                    <span>{{subitem4.text | translate}}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            <li>
                        </ul>
                    </li>
                </ul>
            </li>

        </ul>
        <!-- END sidebar nav-->

    </nav>
</div>
<!-- END Sidebar (left)-->
