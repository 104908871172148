import { Company } from "./company";
export class SubscriptionPayment {
    _id?: String;
    company?: Company;
    plan: String;
    period: String;
    startDate: Date | string;
    paymentDate?: Date;
    nextPaymentDate?: Date;
    lastPaymentDate?: Date;
    endDate?: Date;
    regularUsers: Number;
    failureReportUsers: Number;
    amount: Number;
    comments: String;
    details: String;
    paypalSubscriptionId: String;
    paypalSubscriptionStatus: String;
    previousPaypalSubscriptionId: Array<any>;

    constructor(options: {
        _id?: String,
        company?: Company,
        plan?: String,
        period?: String,
        startDate?: Date | string,
        paymentDate?: Date,
        nextPaymentDate?: Date,
        lastPaymentDate?: Date,
        endDate?: Date,
        amount?: Number,
        comments?: String,
        regularUsers?: Number,
        failureReportUsers?: Number,
        details?: String,
        paypalSubscriptionId?: String,
        paypalSubscriptionStatus?: String,
        previousPaypalSubscriptionId?: Array<any>;
    }) {
        this._id = options._id || undefined;
        this.company = options.company || undefined;
        this.plan = options.plan || "";
        this.period = options.period || "";
        this.startDate = options.startDate || "";
        this.amount = options.amount || 0;
        this.paymentDate = options.paymentDate || undefined;
        this.nextPaymentDate = options.nextPaymentDate || undefined;
        this.lastPaymentDate = options.lastPaymentDate || undefined;
        this.comments = options.comments || "";
        this.regularUsers = options.regularUsers || 0;
        this.failureReportUsers = options.failureReportUsers || 0;
        this.details = options.details || "";
        this.paypalSubscriptionId = options.paypalSubscriptionId || "";
        this.paypalSubscriptionStatus = options.paypalSubscriptionStatus || "";
        this.endDate = options.endDate || undefined;
        this.previousPaypalSubscriptionId = options.previousPaypalSubscriptionId || []
    }

    status = () => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        if (this.endDate) {
            const endDate = new Date(this.endDate);
            endDate.setHours(0, 0, 0, 0);
            if (endDate < currentDate) return "EXPIRED";
        }

        if (this.nextPaymentDate && this.paypalSubscriptionId) {
            const nextPayment = this.nextPaymentDate ? new Date(this.nextPaymentDate) : new Date();
            nextPayment.setHours(0, 0, 0, 0);

            if (nextPayment.getTime() < currentDate.getTime()) return "PAST_DUE"
            return this.paypalSubscriptionStatus;
        } else {
            if (!this.endDate) return "INVALID"
            return "ACTIVE"
        }
    }

    subscriptionStatus = () => {
        if (!this.paypalSubscriptionId) return ""
        return this.paypalSubscriptionStatus
    }

    isActive = () => {
        return this.status() === "ACTIVE"
    }


}