import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { flatMap, map, shareReplay, tap } from 'rxjs/operators';
import { AuthService } from '../../routes/user/auth.service';
import { CommonService } from './common.service';
import { CustomFieldsStoreService } from './custom-fields-store.service';
import { MachineStatusesStoreService } from './machine-statuses-store.service';
import { MaintenanceTypesStoreService } from './maintenance-types-store.service';
import FieldDefinition from '../constants/field-definitions/event-logs';
import { EventLogs } from '../interfaces/event-logs';
import { EventLogsBackendService } from './backend/event-logs.backend.service';
import { isObject, uniqBy } from 'lodash';
import { ConfirmService } from '../utils/confirm/confirm.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmType } from '../constants/confirm-result.enum';
import { Router } from '@angular/router';
import { TranslationsStoreService } from './translations-store.service';

@Injectable({
  providedIn: 'root'
})
export class EventLogsStoreService {

  private skip = 0;
  private limit = 250;

  private eventLogsSubject: BehaviorSubject<List<EventLogs>> = new BehaviorSubject(List([]));
  private totalSubject: BehaviorSubject<Number> = new BehaviorSubject(0);

  public readonly eventLogs$: Observable<List<EventLogs>> = this.eventLogsSubject.pipe(
    map(eventLogs => this.normalizeData(eventLogs)),
    shareReplay(1)
  );
  public readonly columns$: Observable<any[]>;

  constructor(private backend: EventLogsBackendService,
    private customFields: CustomFieldsStoreService,
    private auth: AuthService,
    private commonService: CommonService,
    private maintenanceTypesService: MaintenanceTypesStoreService,
    private machineStatusService: MachineStatusesStoreService,
    private translationsStoreService: TranslationsStoreService,
    private confirm: ConfirmService,
    private translate: TranslateService,
    private router: Router,
  ) {
    auth.user$.subscribe(user => {
      if (!user || !user._id || user.isRoot()) {
        this.eventLogsSubject.next(List([]));
        return;
      }
      // this.load().subscribe();
    });

    this.columns$ = combineLatest([this.auth.user$, this.customFields.get('event-logs'), this.customFields.get('events'), this.translationsStoreService.languageCode$]).pipe(
      map(([user, columns, eventColumns, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(col => (col.name != "company.name" || user.isRoot()) && (col.name != "isDeleted" || user.isAdminOrToolAdmin()));
        const fields = columns.toArray()

        const col = this.customFields.toColumnDef(defaults, {
          rowSelector: 'currentData.id_num',
          table: 'event-logs',
          format: this.formatCell.bind(this)
        });

        const custom = this.customFields.toColumnDef(fields, {
          format: (col, field) => ({
            ...col,
            valueGetter: row => row.data.customFields ? row.data.customFields[field.name] : "",
            valueSetter: (row) => {
              let cf: any[string] = [];
              cf[row.colDef.colId] = row.newValue;
              row.data.customFields = { ...row.data.customFields, ...cf };
              return row;
            }
          })
        });

        const eventFields = eventColumns.toArray().filter(c => !c.isRegularColumn)
        const customEvents = this.customFields.toColumnDef(eventFields, {
          format: (col, field) => ({
            ...col,
            editable: false,
            valueGetter: row => row.data.currentData.customFields ? row.data.currentData.customFields[field.name] : "",
            valueSetter: (row) => {
              let cf: any[string] = [];
              cf[row.colDef.colId] = row.newValue;
              row.data.customFields = { ...row.data.customFields, ...cf };
              return row;
            }
          })
        });

        return col.concat(custom).concat(customEvents);
      })
    );
  }

  load() {
    return this.backend.listAll(this.skip++, this.limit)
      .pipe(
        map(events => events.map(event => new EventLogs(event))),
        map(events => events.map((event) => this.remapEvent(event))),
        tap((events) => {
          if (this.skip === 0 || this.limit == 0) {
            this.eventLogsSubject.next(List(events));

          } else {
            const prev = this.eventLogsSubject.getValue().toArray();
            const data = uniqBy([...events, ...prev], '_id');
            this.eventLogsSubject.next(List(data))
          }
        }),
        flatMap(() => {
          return this.backend.count().pipe(
            tap(count => {
              this.totalSubject.next(count.total);
            })
          )
        })
      )
  }

  search = (search: string) => this.backend.search(search).pipe(
    map(events => events.map(event => new EventLogs(event))),
    map(events => events.map((event) => this.remapEvent(event))),
    tap((events: Array<EventLogs>) => {
      let eventsValue = this.eventLogsSubject.getValue();
      events.forEach(event => {
        const idx = eventsValue.findIndex((e: EventLogs) => e._id === event._id)
        if (~idx) {
          eventsValue = eventsValue.set(idx, event)

        } else {
          eventsValue = eventsValue.push(event)
        }

      });

      this.eventLogsSubject.next(eventsValue);
    })
  )

  public normalizeData(events) {
    return events.map(ev => {
      ev.currentData.custom = ev.currentData.custom || {};
      ev.currentData.start = new Date(ev.currentData.start);
      ev.currentData.finish = ev.currentData.finish ? new Date(ev.currentData.finish) : null;
      ev.scheduleWork = ev.scheduleWork ? new Date(ev.scheduleWork) : null;
      ev.createdAt = ev.createdAt ? new Date(ev.createdAt) : null;
      return ev;
    });
  }

  private formatCell = (col, field) => {
    switch (field.name) {
      case "currentData.start":
      case "currentData.finish":
      case "currentData.scheduleWork":
      case "currentData.qaApprovalTime":
      case "createdAt":
        return {
          ...col,
          valueFormatter: (params) => {
            const event = { ...params.data }
            const props = field.name.split('.');
            const value = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', event);
            return value ? this.commonService.getDateTime(value) : null
          }
        }
      case "currentData.file":
        return {
          ...col,
          cellRenderer: 'fileRedirection',
          cellRendererParams: {
            isMultiple: true,
          }
        }
      case "event.createdAt":
        return {
          ...col,
          cellRenderer: this.timeBound.bind(this)
        }
      case "currentData.type":
        return {
          ...col,
          cellRenderer: this.eventType.bind(this)
        }
      case "tool.name":
        return {
          ...col,
          cellRenderer: 'customClick',
          cellRendererParams: {
            onClick: this.toolLink.bind(this),
            field: field.name
          }
        }
      default:
        return col;
    }
  }

  public remapEvent(event: any) {

    event.currentData.qaApprovalObject = event.currentData.qaApproval || null;
    event.currentData.qaApproval = event.currentData.qaApproval ? event.currentData.qaApproval._id : null;
    if (event.currentData.qaApprovalObject) event.currentData.qaApprovalObject.displayName = `${event.currentData.qaApprovalObject.firstName || ""} ${event.currentData.qaApprovalObject.lastName || ""} (${event.currentData.qaApprovalObject.username})`;
    event.currentData.signaturePersonObject = event.currentData.signaturePerson || null;
    event.currentData.signaturePerson = event.currentData.signaturePerson ? event.currentData.signaturePerson._id : null;
    if (event.currentData.signaturePersonObject) event.currentData.signaturePersonObject.displayName = `${event.currentData.signaturePersonObject.firstName || ""} ${event.currentData.signaturePersonObject.lastName || ""} (${event.currentData.signaturePersonObject.username})`;
    if (isObject(event.currentData.responsibleDepartment) && Object.keys(event.currentData.responsibleDepartment).length > 0) {
      event.currentData.responsiblePersonObject = event.currentData.responsibleDepartment || {};
      if (event.currentData.responsiblePersonObject) event.currentData.responsiblePersonObject.displayName = `${event.currentData.responsiblePersonObject.name || ""}`;
    } else {
      event.currentData.responsiblePersonObject = event.currentData.responsiblePerson || {};
      if (event.currentData.responsiblePersonObject) event.currentData.responsiblePersonObject.displayName = `${event.currentData.responsiblePersonObject.firstName || ""} ${event.currentData.responsiblePersonObject.lastName || ""} (${event.currentData.responsiblePersonObject.username})`;
    }
    event.currentData.responsiblePerson = typeof event.currentData.responsiblePersonObject._id != 'undefined' ? event.currentData.responsiblePersonObject._id : null;
    if (isObject(event.currentData.department) && Object.keys(event.currentData.department).length > 0) {
      event.currentData.departmentObject = event.currentData.department || {};
      event.currentData.department = event.currentData.departmentObject._id;
    } else {
      event.currentData.departmentObject = event.currentData.department || {};
      event.currentData.department = null;
    }
    if (event.currentData.finish) {
      event.currentData.isDirtyFinishDate = true;
    }

    if (event.currentData.worker && isObject(event.currentData.worker)) event.currentData.worker.displayName = `${event.currentData.worker.firstName || ""} ${event.currentData.worker.lastName || ""} (${event.currentData.worker.username})`;
    if (event.currentData.createdBy && isObject(event.currentData.createdBy)) event.currentData.createdBy.displayName = `${event.currentData.createdBy.firstName || ""} ${event.currentData.createdBy.lastName || ""} (${event.currentData.createdBy.username})`;
    if (event.currentData.updatedBy && isObject(event.currentData.updatedBy)) event.currentData.updatedBy.displayName = `${event.currentData.updatedBy.firstName || ""} ${event.currentData.updatedBy.lastName || ""} (${event.currentData.updatedBy.username})`;

    event.currentData.typeId = event.event ? (event.event.machineStatusObject ? event.event.machineStatusObject.id_num : (event.event.maintenanceTypeObject ? event.event.maintenanceTypeObject.id_num : null)) : null;

    if (event.currentData.maintenance && isObject(event.currentData.maintenance)) {
      event.currentData.maintenanceObject = event.currentData.maintenance;
      event.currentData.maintenance = event.currentData.maintenance._id;
    }

    return event;
  }

  eventType = (params) => {
    if (params.data.currentData) {
      if (params.data.currentData.maintenanceType) {
        const maintenanceTypes = this.maintenanceTypesService.getList();
        const matchRecord = maintenanceTypes.find(m => params.data.currentData && params.data.currentData.maintenanceType && m._id.toString() == params.data.currentData.maintenanceType.toString());
        if (matchRecord) {
          return matchRecord.name;
        }
      } else {
        const machineStatus = this.machineStatusService.getList();
        const matchRecord = machineStatus.find(m => params.data.currentData && params.data.currentData.machineStatus && m._id.toString() == params.data.currentData.machineStatus.toString());
        if (matchRecord) {
          return matchRecord.title;
        }
      }
    }
    return null;
  }

  toolLink(params) {
    if(params.rowData.tool && params.rowData.tool?.isDeleted){
      this.confirm.show(this.translate.instant('confirm.TOOL.TOOL_DELETED'), { type: ConfirmType.CONFIRM_ONLY, confirmText: this.translate.instant('shared.OK'), defaultBtnClass: 'btn-danger' }).subscribe(() => {})
    }else{
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/main/maintenance/tool/${params.rowData && params.rowData.tool ? params.rowData.tool.id_num : ""}`])
      );
      window.open(url, '_blank');
    }
  }

  totalLogsCount() {
    return this.totalSubject.getValue();
  }

  timeBound(params) {
    return this.commonService.getTimeDiff(params.data.event.createdAt, params.data.event.firstChangedAt ? params.data.event.firstChangedAt : new Date());
  }
}
