<ol class="normal-numbers remove-block-start">
  <li>
    <span><strong>General.</strong></span>
    <p>This Service Level Agreement (this &ldquo;<strong>SLA</strong>&rdquo;) set forth the relevant service
      levels with respect to the platform (the &ldquo;<strong>Platform</strong>&rdquo;) provided by AnyMaint
      Ltd. (the &ldquo;<strong>Company</strong>&rdquo; or &ldquo;<strong>AnyMaint</strong>&rdquo;) to its
      customers. This SLA is part of the Company&rsquo;s EULA (the
      &ldquo;<strong>Agreement</strong>&rdquo;). All capitalized terms used in this SLA that are not
      otherwise defined herein, shall have the meaning given to them in the Agreement.</p>
  </li>
  <li>
    <span><strong>Definitions.</strong></span>
    <ol class="normal-numbers">
      <li>
        <span>&ldquo;<strong>Availability&rdquo;</strong> refers to availability of the Platform for a given month that will be calculated according to the following formula: (TMM-TMU)/TMM x 100%.</span>
        <p><u>Where</u>:</p>
        <p>TMM = Total minutes in a certain month.</p>
        <p>TMU = Total minutes in a certain month in which the Platform is unavailable.</p>
      </li>
      <li>
        <span><strong>“Service Credit”</strong> shall mean one (1) day extension of the Subscription Period.</span>
      </li>
      <li>
        <span><strong>“Support”</strong> refers to the Company’s responsibilities to address questions and issues related to the Platform. Support will be provided remotely. Support may be provided by the Company’s authorized distributors or resellers.</span>
      </li>
    </ol>
  </li>
  <li>
    <span><strong>Support Process.</strong></span>
    <ol class="normal-numbers">
      <li>
        <span><u>Submitting Support Requests</u>. Customer that wish to request support must open a support ticket by sending an email to: <u><a href="mailto:info@anymaint.com">info@anymaint.com</a></u>. Critical incidents reported have to be followed by a phone call to <a href="tel:+972526174879">+972-526174879</a> (the &ldquo;<strong>Support Phone</strong>&rdquo;) in order to immediately determine the best communication line while handling the ticket.</span>
      </li>
      <li>
        <span><u>Support Process</u>. The Company will analyze the ticket and evaluate the completeness of the information submitted by the Customer. To the extent required the Company shall contact the Customer for additional information. If the Company determines that the root cause of the incident is the Platform, the Company will use its reasonable efforts to resolve the issue according to the assigned severity levels, as defined in the table in 3.3 below (the “<strong>Severity Levels</strong>”). If the root cause of a reported incident is deemed to be an external failure, the Company shall inform the Customer of such fact and the Company will have no obligation to resolve such issue.</span>
      </li>
      <li>
        <span><u>Severity Levels</u>. The ticket will be handled according to the ticket severity level in the table below:</span>
        <table class="table table-bordered mt-3" cellspacing="0" cellpadding="8">
          <thead>
            <tr>
              <th width="35%">Time to Resolution</th>
              <th width="20%">Initial Response Time</th>
              <th width="35%">Definition</th>
              <th width="10%">Severity Level</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowspan="3">As the resolution time depends on the type of problem or request, it cannot be determined in advance. However, AnyMaint will work to resolve the issue until the Platform is returned to normal operation. Customer will be notified of status changes.</td>
              <td>Up to 4 hours.</td>
              <td>A total failure of the Platform or the Customer’s software systems.</td>
              <td><strong>1<br/>(High)</strong></td>
            </tr>
            <tr>
              <td>Within 24 hours.</td>
              <td>Major function is down or not working as expected. Adversely affects and prevents the accomplishment of an operational or mission essential function. Typically, a workaround is not available.</td>
              <td><strong>2<br/>(Medium)</strong></td>
            </tr>
            <tr>
              <td>Up to 3 business days.</td>
              <td>Minor function is down or not working as expected / cosmetic issues. Adversely affects (but does not prevent) the accomplishment of an operational or mission. essential function. Typically, a workaround is available.</td>
              <td><strong>3<br/>(Low)</strong></td>
            </tr>
          </tbody>
        </table>
      </li>
      <li>
        <span><u>Support Service Hours</u>. Support Services will be provided during the support hours as set forth in the table below:</span>
        <table class="table table-bordered mt-3" cellspacing="0" cellpadding="8">
          <thead>
            <tr>
              <th width="40%">Comments</th>
              <th width="40%">Support Hours</th>
              <th width="20%">Support Hours Type</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Excluding national holidays in Israel.</td>
              <td>9:00am-6:00pm (IL Time) Monday-Friday</td>
              <td><strong>Normal Hours</strong></td>
            </tr>
            <tr>
              <td>Only available for Severity Level 1 via the Support Phone.</td>
              <td>6:00pm-9:00am (IL Time) Sunday-Friday</td>
              <td><strong>Out of Office Hours</strong></td>
            </tr>
          </tbody>
        </table>
      </li>
      <li>
        <span><u>Maintenance</u>.</span>
        <ol class="normal-numbers">
          <li><span>The Company shall make available to Customer any updates to the Platform if and when the Company makes such updates generally available to its other customers then covered by maintenance services with similar terms as the terms herein.</span></li>
          <li><span>The above notice period shall not be provided in case of urgent security updates or issues that are causing critical production issues or severe security threats.</span></li>
        </ol>
      </li>
      <li>
        <span><u>Availability</u>. Subject to the terms of this SLA, the Platform will have 99.5% Availability each calendar month (the “Uptime Commitment”). The Platform will not be deemed unavailable for any downtime or outages excluded from such calculation by reason of the exceptions set forth below in this SLA including any maintenance. AnyMaint’s records and data will be the sole basis for all SLA calculations and determinations.</span>
      </li>
      <li>
        <span><u>Limitations</u>. This SLA  do not apply to any performance or availability issues that: (i) is caused by factors outside of AnyMaint’s reasonable control, including, without limitation, telecommunications provider-related problems or issues, Internet access or related problems occurring beyond the point in the network where AnyMaint maintains access and control over the Platform; (ii) results from any actions or inactions of Customer or any third party (except for AnyMaint’s authorized distributors or resellers); (iii) results from the Customer Applications, Customer’s equipment, software or other technology, Add-on services, or third party equipment, software or other technology;</span>
      </li>
      <li>
        <span><u>Remedies</u>.</span>
        <ol class="normal-numbers">
          <li><span><u>Response Time</u>. In the event AnyMaint has not responded within the Response Time specified in 3.3 above then for each 4 hours of delay in response time, Customer shall be entitled, as its sole and exclusive remedy, to a Service Credit.</span></li>
          <li><span><u>Availability</u>. In the event Availability for a particular month is below 99.5% then for each 2% of availability below 99.5%, Customer will be entitled, as its sole and exclusive remedy, to a Service Credit.</span></li>
          <li><span>The SLA Credits specified above are Customer’s sole and exclusive remedies with respect to this SLA. To receive Service Credits, Customer must submit a credit request within 30 days of the event that gave rise to the Service Credit.</span></li>
        </ol>
      </li>
    </ol>
  </li>
</ol>