import { Component, Input, OnInit } from "@angular/core";
import { ToolEvent } from "../../../core/interfaces/tool-event";
import { Maintenance } from "../../../core/interfaces/maintenance";
import { ChecklistItem } from "../../../core/interfaces/checklist-item";
import { GeneralService } from "../../../core/services/general.service";
import { TranslationsStoreService } from "../../../core/services/translations-store.service";

@Component({
  selector: "app-event-card-checklist",
  templateUrl: "./event-card-checklist.component.html",
  styleUrls: ["./event-card-checklist.component.scss"],
})
export class EventCardChecklistComponent implements OnInit {
  @Input() event: ToolEvent = null;
  @Input() maintenance: Maintenance = null;
  @Input() chapters: ChecklistItem[] = [];
  @Input() files = [];

  chapterChecklistItems = [];

  constructor(
    private generalService: GeneralService,
    public translationService: TranslationsStoreService,
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.event && this.event.checklistItems) {
      this.chapterChecklistItems = this.generalService.getTasksByChapters(
        this.event.checklistItems,
        this.chapters
      );
    } else if (this.maintenance && this.maintenance.checklistItems) {
      this.chapterChecklistItems = this.generalService.getOnlyTasksByChapters(
        this.maintenance.checklistItems,
        this.chapters
      );
    }
  }

  getDisplayName(name){
    return name.substring(0, 100) + (this.needToDisplayMoreLess(name) ? '...' : '')
  }

  needToDisplayMoreLess(name){
    return name.length > 100
  }

  changeChecklistShow = ($event, checklist) => {
    $event.stopPropagation();
    $event.preventDefault();
    checklist.showMore = checklist.showMore ? false : true;
  }

  getFileName = (id) => {
    const file = this.files.find(f => f._id == id);
    if (file) {
      return file.name
    }
    return '';
  }

  viewFile = async($event, id) => {
    $event.stopPropagation();
    $event.preventDefault();
    this.generalService.viewFile(id);
  }
}
