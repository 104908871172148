import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import moment from "moment";
import { CommonService } from "../../../core/services/common.service";
import { TranslationsStoreService } from "../../../core/services/translations-store.service";
import { loadCldr } from '@syncfusion/ej2-base';

@Component({
    selector: 'datepicker-editor-cell',
    template: `
        <div #container class="base-div">
            <div class="event_wrapper">
              <div class="event_date" *ngIf="time" [ngClass]="!visible ? 'only-date' : ''">
                <ejs-datepicker id="eventStart" (change)="startDateChange($event)" [(value)]='start.date' [enableRtl]="translationService.rtl$ | async" [format]="dateFormat" (click)='onStartFocus($event)' [locale]="translationService.languageCode$ | async" [firstDayOfWeek]='firstDayOfWeek'></ejs-datepicker>
              </div>
              <div class="event_time" *ngIf="visible" [ngClass]="!visible ? 'only-time' : ''">
                <ejs-timepicker id="eventStartTime" (change)="startDateChange($event)" [(value)]="start.time" (click)='onStartTimeFocus($event)' [scrollTo]="scrollTo" [format]="timeFormat"  [step]="timeStep"></ejs-timepicker>
              </div>
            </div>
        </div>
    `,
    styles: [`
    :host ::ng-deep  span.e-input-group .e-clear-icon {
        display: none;
    }
    .event_wrapper {
        display: flex;
        border: 1px solid #bbbdbf;
        border-radius: 7px;
        max-width: 285px;
        width: 100%;
    }
    .event_wrapper .event_date span, .event_wrapper .event_time span {
        border: none !important;
        margin: 0 !important;
    }
    .event_wrapper .event_date input, .event_wrapper .event_time input {
        padding: 0 !important;
    }
    .event_date {
        width: 100%;
        padding: 8px 12px;
        border-right: 1px solid #bbbdbf;
    }
    .event_time {
        width: 100%;
        padding-right: 0;
        padding: 8px 12px;
    }
    .event_date.only-date, .event_time.only-time {
        width: 100%;
        border-right: unset !important;
    }
    .base-div{
        min-width: 230px;
        width: 100%;
        padding: 5px 5px 5px 5px;
    }
    `]
})
export class DatePickerEditorComponent implements ICellEditorAngularComp {
    private params: any;
    public values: any;
    public value: any;
    public model: any;
    public disable: any;
    timeFormat = { skeleton: 'Hm' };
    dateFormat = 'dd.MM.yyyy';
    start: any = {
        date: '',
        time: ''
    };
    langCode: string = 'en';
    @ViewChild('container', { read: ViewContainerRef }) public container;
    public happy: boolean = false;
    visible: boolean;
    time: boolean;
    scrollTo = null;
    timeStep: number = this.commonService.getTimeStep()
    firstDayOfWeek = this.commonService.getFirstDayOfWeek();

    constructor(
        public commonService: CommonService,
        public translationService: TranslationsStoreService,
    ) {
    }

    agInit(params: any): void {
        this.translationService.languageCode$.subscribe(data => {
            this.langCode = data;
        });
        if (this.langCode != 'en') {
            let caGregorian = require('../../../../assets/cldr-data/' + this.langCode + '/ca-gregorian.json');
            caGregorian['main'][this.langCode]['dates']['calendars']['gregorian']['dateFormats']['short'] = this.commonService.getDateFormatForPicker();
            loadCldr(
                caGregorian,
                require('../../../../assets/cldr-data/' + this.langCode + '/numbers.json'),
                require('../../../../assets/cldr-data/' + this.langCode + '/timeZoneNames.json'),
                require('../../../../assets/cldr-data/weekData.json') // To load the culture based first day of week
            );
        }
        const type = params.colDef.field === "finish" ? "end" : "start"
        this.params = params;
        const value = params.data && params.data[params.colDef.field] ? params.data[params.colDef.field] : (typeof params.data.customFields != "undefined" && params.data.customFields && params.data.customFields[params.colDef.field] ? params.data.customFields[params.colDef.field] : (typeof params.data.pmCustomFields != "undefined" && params.data.pmCustomFields && params.data.pmCustomFields[params.colDef.field] ? params.data.pmCustomFields[params.colDef.field] : null));
        if (value) {
            this.value = new Date(this.commonService.convertDateForDateObject(value));
            this.start.date = this.value;
            this.start.time = this.value;
            this.scrollTo = this.commonService.getScrollToTime(this.value, type);
        }else{
            this.scrollTo = this.commonService.getScrollToTime(null, type);
        }
        this.dateFormat = this.commonService.getDateFormatForPicker();
        this.timeFormat.skeleton = this.commonService.getTimeFormatForPicker();
        this.visible = typeof this.params.visible != 'undefined' ? this.params.visible : true;
        this.time = typeof this.params.time != 'undefined' ? this.params.time : true;
    }

    getValue(): any {
        this.updateValue();
        return this.value;
    }

    updateValue() {
        if(this.visible && this.time){
            if (this.start.date) {
                this.value = moment(this.commonService.getFormattedDate(this.start.date, 'YYYY-MM-DD') + ' ' + (this.start.time ? this.commonService.getFormattedDate(this.start.time, 'HH:mm') : this.commonService.getCompanyStartHour()));
            }else{
                this.value = null;
            }
        }
        if(this.visible && !this.time){
            if(this.start.time) {
                this.value = moment(this.commonService.getFormattedDate(moment(), 'YYYY-MM-DD') + ' ' + (this.start.time ? this.commonService.getFormattedDate(this.start.time, 'HH:mm') : this.commonService.getCompanyStartHour()));
            }else{
                this.value = null;
            }
        }
        if(this.time && !this.visible){
            if(this.start.date) {
                this.value = moment(this.commonService.getFormattedDate(this.start.date, 'YYYY-MM-DD')).add(this.commonService.getTzOffset(), "minutes");
            }else{
                this.value = null;
            }
        }
        if(this.value) this.value = this.commonService.getOriginalDateAfterDatePicker(this.value);
    }

    public startDateChange = (event) => {
        this.updateValue();
    }

    onStartFocus(args): void {
        let datepickerObject: any = document.getElementById("eventStart");
        datepickerObject.ej2_instances[0].show();
    }

    onStartTimeFocus(args): void {
        let datepickerObject: any = document.getElementById("eventStartTime");
        datepickerObject.ej2_instances[0].show();
    }

    isPopup(): boolean {
        return true;
    }
}