import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';

@Component({
  selector: 'app-bar-renderer',
  template: `<span class="bar" [dir]="translationService.dir$ | async"></span>`,
  styles: [`
    .bar{
      position: absolute;
      width: 6px;
      height: 36px;
      visibility: hidden;
      border-radius: 8px;
      top: 6px;
      left: 3px;
    }
    .bar[dir="rtl"] {
      left: 0px;
    }
  `]
})

export class BarRendererComponent implements ICellRendererAngularComp {
  params;

  constructor(
    public translationService: TranslationsStoreService,
  ) {
  }

  agInit(params): void {
    this.params = params;
  }

  refresh(params?: any): boolean {
    return false;
  }

  getValue(): any {
    return this.params.value;
  }
}