import { LayoutComponent } from '../layout/layout.component';
import { LoginComponent } from './user/login/login.component';
import { AuthGuard } from '../core/auth/auth.guard';
import { HomeGuard } from '../core/auth/home.guard';
import { LandingPageComponent } from '../landing-page/landing-page.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LandingPageGuard } from '../core/auth/landing-page.guard';
import { NotificationComponent } from './notification/notification.component';
import { SetupCompanyComponent } from './user/setup-company/setup-company.component';
import { TimezoneSettingComponent } from './settings/timezone-setting/timezone-setting.component';
import { RefreshComponent } from './user/refresh/refresh.component';
import { UserLoginComponent } from './user/user-login/user-login.component';

export const routes = [
    { path: '', component: LandingPageComponent, canActivate: [LandingPageGuard] },
    {
        path: '',
        component: NotificationComponent,
        children: [
            {
                path: '',
                component: LayoutComponent,
                children: [
                    { path: 'main', loadChildren: () => import('./main/main.module').then(m => m.MainModule) },
                    { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule), canActivate: [AuthGuard] },
                    { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
                    { path: 'smart-dropdown', loadChildren: () => import('./smart-dropdown/smart-dropdown.module').then(m => m.SmartDropdownModule), canActivate: [AuthGuard] },
                    { path: 'smart-report', loadChildren: () => import('./smart-report/smart-report.module').then(m => m.SmartReportModule), canActivate: [AuthGuard] },
                    { path: 'tables', loadChildren: () => import('./table/table.module').then(m => m.TableModule), canActivate: [AuthGuard] },
                    { path: 'root', loadChildren: () => import('./root/root.module').then(m => m.RootModule), canActivate: [AuthGuard] },
                    { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule), canActivate: [AuthGuard] },
                    { path: 'setup-wizard', loadChildren: () => import('./onboarding-wizard/onboarding-wizard.module').then(m => m.OnboardingWizardModule), canActivate: [AuthGuard] },
                    { path: 'search', loadChildren: () => import('./search/search.module').then(m => m.SearchModule), canActivate: [AuthGuard] },
                ]
            }
        ]

    },
    { path: 'timezone-setting', component: TimezoneSettingComponent, canActivate: [AuthGuard] },
    { path: 'setup', component: SetupCompanyComponent, canActivate: [AuthGuard] },
    { path: 'refresh', component: RefreshComponent },
    { path: 'user-login/:id', component: UserLoginComponent },
    { path: 'login', component: LoginComponent, canActivate: [HomeGuard] },
    { path: '**', component: NotFoundComponent }
];