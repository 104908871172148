import { ValidatorFn } from "@angular/forms";

export class FieldBase<T> {
    value: T;
    key: string;
    type: string;
    label: string;
    required: boolean;
    order: number;
    controlType: string;
    isCustom: boolean;
    readonly: boolean;
    multiple: boolean;
    dontPresentOnModal: boolean;
    preventEditOnModal: boolean;
    options?: any;
    validators: ValidatorFn [] | ValidatorFn;
    form?: string;
    controlKey: String;

    constructor(options: {
        value?: T,
        key?: string,
        type?: string,
        label?: string,
        required?: boolean,
        order?: number,
        controlType?: string,
        isCustom?: boolean,
        multiple?: boolean,
        dontPresentOnModal?: boolean,
        preventEditOnModal?: boolean,
        options?: any,
        readonly?: boolean,
        validators?: ValidatorFn [] | ValidatorFn,
      } = {}) {
      const [subgroup, subkey] = options.key.split(".");
      this.value = options.value;
      this.key = options.key;
      this.type = options.type;
      this.label = options.label || '';
      this.isCustom = options.isCustom || false;
      this.required = !!options.required;
      this.order = options.order === undefined ? 1 : options.order;
      this.controlType = options.controlType || '';
      this.options = options.options || [];
      this.readonly = options.readonly || options.preventEditOnModal;
      this.multiple = options.multiple || false;
      this.dontPresentOnModal = options.dontPresentOnModal || false;
      this.preventEditOnModal = options.preventEditOnModal || false;
      this.validators = options.validators || [];
      this.form = !!subkey ? subgroup : null;
      this.controlKey = !!subkey ? subkey : this.key;
    }
  }