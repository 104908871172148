import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { AuthService } from '../../routes/user/auth.service';
import { DynamicReport } from '../interfaces/dynamic-report';
import { DynamicReportBackendService } from './backend/dynamic-report.backend.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicReportStoreService {

  constructor(
    protected backend: DynamicReportBackendService,
    protected auth: AuthService
  ) {
  }

  getByKey(key:string) {
    if(key){
      return this.backend.getByKey(key).pipe(
        shareReplay(1)
      );
    }else{
      return of(null).pipe(
        shareReplay(1),
      );
    }
  }

  getDataById(id:string) {
    if(id){
      return this.backend.getDataById(id).pipe(
        shareReplay(1)
      );
    }else{
      return of([]).pipe(
        shareReplay(1),
      );
    }
  }

  update(data: DynamicReport) {
    return this.backend.update(data);
  }

  delete(data: DynamicReport) {
    return this.backend.delete(data);
  }
}
