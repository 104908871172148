import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  completed: Boolean;
  success: Boolean;

  constructor(private route: ActivatedRoute, private auth: AuthService) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.auth.verify(params.code).subscribe(
        () =>{
          this.success = true
          this.completed = true
        },
        () =>{
          this.success = false
          this.completed = true
        },
      );
    });
  }

}
