import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { List } from 'immutable';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { mergeMap, shareReplay } from 'rxjs/operators';
import { DynamicDropdown } from '../../../core/interfaces/dynamic-dropdown';
import { Menu } from '../../../core/interfaces/menu';
import { DynamicDropdownStoreService } from '../../../core/services/dynamic-dropdown-store.service';
import { MenuStoreService } from '../../../core/services/menu-store.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicListService {

  key$: BehaviorSubject<string> = new BehaviorSubject(null);
  menu$: Observable<Menu>;
  dynamicDrowdowns$: Observable<List<DynamicDropdown>>;

  constructor(
    private dynamicDropdownStoreService: DynamicDropdownStoreService,
    private menuStoreService: MenuStoreService,
    private router: Router
  ) {

    this.menu$ = this.key$.pipe(
      mergeMap(key => this.menuStoreService.getMenu(key)),
      shareReplay(1),
    );

    combineLatest([this.menuStoreService.menus$, this.key$, this.menuStoreService.menuFetched]).subscribe(([menus, key, menuFetched]) => {
      if(key && menuFetched){
        const mn = menus.find(m => m.key == key);
        if(!mn){
          this.router.navigate(['main', 'maintenance']);
        }
      }
    })

    this.dynamicDrowdowns$ = this.key$.pipe(
      mergeMap(key => this.dynamicDropdownStoreService.getListByKey(key)),
      shareReplay(1),
    );
  }

  setKey(key:string) {
    this.key$.next(key);
  }

  getKey() {
    return this.key$.getValue();
  }
}
