import { Injectable } from "@angular/core";
import { ColDef } from "ag-grid-community";
import { List } from "immutable";
import { BehaviorSubject, Observable, combineLatest, map, tap } from "rxjs";
import {
  MeasurementResult,
  MeasurementResultEvent,
} from "../interfaces/measurement-result";
import { MeasurementResultsBackendService } from "./backend/measurement-results.backend.service";
import { AuthService } from "../../routes/user/auth.service";
import { CustomFieldsStoreService } from "./custom-fields-store.service";
import { CommonService } from "./common.service";
import { TranslateService } from "@ngx-translate/core";
import { TranslationsStoreService } from "./translations-store.service";
import FieldDefinition from "../constants/field-definitions/measurement-result";
import { isObject } from "lodash";
import { timeCalculation } from "../../shared/comparators/date-comparator";
import { MEASUREMENT_STATUS } from "../constants/enums/enums";
import { ConfirmType } from "../constants/confirm-result.enum";
import { ConfirmService } from "../utils/confirm/confirm.service";
import { Router } from "@angular/router";
import { MeasurementsStoreService } from "./measurements-store.service";

@Injectable({
  providedIn: "root",
})
export class MeasurementResultsStoreService {
  public columns$: Observable<ColDef[]>;
  private measurementResultsSubject: BehaviorSubject<List<MeasurementResult>> =
    new BehaviorSubject(List([]));
  public readonly measurementResults$: Observable<List<MeasurementResult>> =
    this.measurementResultsSubject.asObservable();

  constructor(
    private backend: MeasurementResultsBackendService,
    private measurementsStoreService: MeasurementsStoreService,
    private authService: AuthService,
    private customFieldsService: CustomFieldsStoreService,
    private commonService: CommonService,
    private translateService: TranslateService,
    private translationsStoreService: TranslationsStoreService,
    private confirmService: ConfirmService,
    private router: Router
  ) {
    this.columns$ = combineLatest([
      this.authService.user$,
      this.translationsStoreService.languageCode$,
    ]).pipe(
      map(([user, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition;
        const col = this.customFieldsService.toColumnDef(defaults, {
          rowSelector: "id_num",
          format: this.formatCell.bind(this),
        });
        return col;
      })
    );
  }

  public relatedInventory(id: string) {
    return this.backend.relatedInventory(id);
  }

  public resultsByEvent(id: string) {
    return this.backend
      .resultsByEvent(id)
      .pipe(
        map((measurementResults) =>
          measurementResults.map((measurementResult: MeasurementResult) =>
            this.remapMeasurementResult(measurementResult)
          )
        )
      );
  }

  public measureAgain(id: string) {
    return this.backend.measureAgain(id);
  }

  public addNewMeasurement(id: string) {
    return this.backend.addNewMeasurement(id);
  }

  public openEvent(data: MeasurementResultEvent) {
    return this.backend.openEvent(data);
  }

  public list() {
    return this.backend.list().pipe(
      map((measurementResults) =>
        measurementResults.map((measurementResult: MeasurementResult) =>
          this.remapMeasurementResult(measurementResult)
        )
      ),
      tap((measurements) =>
        this.measurementResultsSubject.next(List(measurements))
      )
    );
  }

  public listByTool(toolId: string) {
    return this.backend
      .listByTool(toolId)
      .pipe(
        map((measurementResults) =>
          measurementResults.map((measurementResult: MeasurementResult) =>
            this.remapMeasurementResult(measurementResult)
          )
        )
      );
  }

  public listByToolManual(toolId: string) {
    return this.backend
      .listByToolManual(toolId)
      .pipe(
        map((measurementResults) =>
          measurementResults.map((measurementResult: MeasurementResult) =>
            this.remapMeasurementResult(measurementResult)
          )
        )
      );
  }

  public create(measurementResult: MeasurementResult) {
    return this.backend
      .create(measurementResult)
      .pipe(map((mr) => this.remapMeasurementResult(mr)));
  }

  public update(
    measurementResult: MeasurementResult,
    measurementResultId: string,
    isTable = false,
    isUserValue = false
  ) {
    return this.backend
      .update(measurementResult, measurementResultId, isTable, isUserValue)
      .pipe(
        map((mr) => this.remapMeasurementResult(mr)),
        tap((mr) => {
          if (isTable) {
            const measurementResults =
              this.measurementResultsSubject.getValue();
            const index = measurementResults.findIndex(
              (u) => u._id === measurementResultId
            );
            if (index > -1) {
              this.measurementResultsSubject.next(
                measurementResults.set(index, mr)
              );
            }
          }
          return mr;
        })
      );
  }

  public delete(measurementResultId: string) {
    return this.backend.delete(measurementResultId).pipe(
      tap(() => {
        const measurementResults = this.measurementResultsSubject.getValue();
        const index = measurementResults.findIndex(
          (u) => u._id === measurementResultId
        );
        if (index > -1) {
          const measurementResult = measurementResults.get(index);

          measurementResult.isDeleted = true;
          this.measurementResultsSubject.next(
            measurementResults.set(index, measurementResult)
          );
        }
      })
    );
  }

  remapMeasurementResult = (measurementResult: MeasurementResult) => {
    if (
      measurementResult.measurement &&
      isObject(measurementResult.measurement)
    ) {
      measurementResult.measurementObject = measurementResult.measurement;
      measurementResult.measurement = measurementResult.measurement._id;
    }
    if (measurementResult.tool && isObject(measurementResult.tool)) {
      measurementResult.toolObject = measurementResult.tool;
      measurementResult.tool = measurementResult.tool._id;
    }
    return measurementResult;
  };

  getStatus = (status: number) => {
    const statusText = {
      [MEASUREMENT_STATUS.MISSING_VALUE]: this.translateService.instant(
        "measurements.MISSING-VALUE"
      ),
      [MEASUREMENT_STATUS.WARNING]: this.translateService.instant(
        "measurements.WARNING"
      ),
      [MEASUREMENT_STATUS.IN_TOLERANCE]: this.translateService.instant(
        "measurements.IN-TOLERANCE"
      ),
    };
    return statusText[status] || null;
  };

  toolLink(params) {
    if (params.rowData.toolObject && params.rowData.toolObject?.isDeleted) {
      this.confirmService
        .show(this.translateService.instant("confirm.TOOL.TOOL_DELETED"), {
          type: ConfirmType.CONFIRM_ONLY,
          confirmText: this.translateService.instant("shared.OK"),
          defaultBtnClass: "btn-danger",
        })
        .subscribe(() => {});
    } else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          `/main/maintenance/tool/${
            params.rowData && params.rowData.toolObject
              ? params.rowData.toolObject.id_num
              : ""
          }`,
        ])
      );
      window.open(url, "_blank");
    }
  }

  getMeasurementTypeText = (params) => {
    return this.measurementsStoreService.measurementTypes.find(
      (type) => type.id === params.data?.measurementObject?.type
    )?.text;
  };

  getFrequencyText = (params) => {
    return params.data.measurementObject &&
      params.data.measurementObject.frequency
      ? this.measurementsStoreService.frequencies.find(
          (frequency) =>
            frequency.id === params.data.measurementObject.frequency
        )?.text
      : null;
  };

  private getFormulaText(param, field) {
    if (param.data && param.data[field] && param.data[field].length > 0) {
      return param.data[field]
        .map((formula) => this.commonService.getTextFromFormula(formula))
        .join(" ");
    }
    return null;
  }

  private getMaterialText(param) {
    if (param.data && param.data.isResponseRequired && param.data.material) {
      return this.measurementsStoreService.getReMeasurementText(
        param.data.material
      );
    }
    return null;
  }

  private formatCell = (col, field) => {
    switch (field.name) {
      case "formula":
      case "measuredFormula":
        return {
          ...col,
          valueFormatter: (params) => this.getFormulaText(params, field.name),
          valueGetter: (params) => this.getFormulaText(params, field.name),
          tooltipValueGetter: (params) =>
            this.getFormulaText(params, field.name),
        };
      case "material":
        return {
          ...col,
          valueFormatter: (params) => this.getMaterialText(params),
          valueGetter: (params) => this.getMaterialText(params),
          tooltipValueGetter: (params) => this.getMaterialText(params),
        };
      case "measurementObject.type":
        return {
          ...col,
          valueFormatter: (params) => this.getMeasurementTypeText(params),
          valueGetter: (params) => this.getMeasurementTypeText(params),
        };
      case "measurementObject.frequency":
        return {
          ...col,
          valueFormatter: (params) => this.getFrequencyText(params),
          valueGetter: (params) => this.getFrequencyText(params),
        };
      case "toolObject.name":
        return {
          ...col,
          cellRenderer: "customClick",
          cellRendererParams: {
            onClick: this.toolLink.bind(this),
            field: field.name,
          },
        };
      case "date":
        return {
          ...col,
          valueFormatter: (params) =>
            params.data.date && this.commonService.getDate(params.data.date),
          valueGetter: (params) =>
            params.data.date && this.commonService.getDate(params.data.date),
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(
              nodeA && nodeA.data.date ? nodeA.data.date : null,
              nodeB && nodeB.data.date ? nodeB.data.date : null,
              nodeA,
              nodeB,
              isInverted
            );
          },
        };
      case "status":
        return {
          ...col,
          valueFormatter: (params) =>
            params.data.status && this.getStatus(params.data.status),
          valueGetter: (params) =>
            params.data.status && this.getStatus(params.data.status),
        };
      default:
        return col;
    }
  };
}
