import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Menu } from '../../interfaces/menu';

@Injectable({
  providedIn: 'root'
})
export class MenuBackendService {

  url = '/api/menus';

  constructor(private http: HttpClient) { }

  list() {
    return this.http.get<Array<Menu>>(this.url)
  }

  create(data) {
    return this.http.post(this.url, data);
  }

  update(data) {
    return this.http.put(`${this.url}/${data._id}`, data);
  }

  delete(data: Menu) {
    return this.http.delete(`${this.url}/${data._id}`);
  }
}
