<div class="overlay bg-dark" *ngIf="show" [@fadeInOut]></div>
<div
  *ngIf="show"
  class="modal show right"
  tabindex="-1"
  role="dialog"
  [@slideInOut]
  (click)="close()"
  [dir]="translationService.dir$ | async"
  [ngClass]="size || 'md'"
>
  <div
    class="modal-dialog"
    role="document"
    (click)="$event.stopPropagation()"
    [ngClass]="'modal-dialog-' + (translationService.dir$ | async)"
  >
    <div class="modal-content">
      <div class="modal-header border-0 p-0 d-flex">
        <h5 class="modal-title flex-grow-1">{{ title | translate }}</h5>
        <a href="#" *ngIf="showResetFilter" (click)="resetFilter($event)">{{ 'calendar.RESET-FILTER' | translate }}</a>
        <img src="assets/img/close 2.svg" alt="close" (click)="close()" class="cursor-pointer">
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</div>