import { Component, OnInit, OnDestroy } from "@angular/core";
import { TranslationsStoreService } from "../../../core/services/translations-store.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { OpenaiStoreService } from "../../../core/services/openai-store.service";
import { LoaderService } from "../../../core/utils/loader.service";
import { ToasterService } from "angular2-toaster";
import { TranslateService } from "@ngx-translate/core";
import { CompaniesStoreService } from "../../../core/services/companies-store.service";
import { AuthService } from "../../../routes/user/auth.service";
import { PusherService } from "../../../core/services/pusher.service";
import { Company } from "../../../core/interfaces/company";
import { AiAssistantText } from "../../../core/interfaces/ai-assistant-text";
import { combineLatest } from "rxjs";
import { NgxFileDropEntry } from "ngx-file-drop";
import { CommonService } from "../../../core/services/common.service";
import { CopyPasteProcedureComponent } from "../../../routes/admin/data-tables/procedures/copy-paste-procedure/copy-paste-procedure.component";
import { Tool } from "../../../core/interfaces/tool";
import { Subscription } from "rxjs";

@Component({
  selector: "app-ai-maintenance-assistant-modal",
  templateUrl: "./ai-maintenance-assistant-modal.component.html",
  styleUrls: ["./ai-maintenance-assistant-modal.component.scss"],
})
export class AiMaintenanceAssistantModalComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  tool: Tool = null;
  isPM: boolean = false;
  eventName: string = null;
  file: File;
  company: Company;

  constructor(
    public translationService: TranslationsStoreService,
    public bsModalRef: BsModalRef,
    private openaiStoreService: OpenaiStoreService,
    private loaderService: LoaderService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private companiesStoreService: CompaniesStoreService,
    private auth: AuthService,
    private pusherService: PusherService,
    private commonService: CommonService,
    private modal: BsModalService
  ) {}

  ngOnInit(): void {
    this.pusherService.channel.bind(
      "ai-assistant-maintenance",
      (data: { aIAssistantTextId: string; user: string; company: string }) => {
        if (data.user && data.user.toString() && this.auth.getId().toString()) {
          this.loaderService.remove();
          if (data.aIAssistantTextId) {
            this.openaiStoreService
              .aiAssistantText(data.aIAssistantTextId)
              .subscribe({
                next: async (result: AiAssistantText) => {
                  if (result?.text) {
                    this.bsModalRef.hide();
                    this.modal.show(CopyPasteProcedureComponent, {
                      keyboard: false,
                      ignoreBackdropClick: true,
                      initialState: {
                        isPM: this.isPM,
                        tool: this.tool,
                        eventName: this.eventName,
                        taskText: result.text,
                      },
                      class: "modal-lg event-modal-content",
                    });
                  }
                },
                error: () => {
                  this.toaster.pop(
                    "error",
                    this.translate.instant(
                      "toasters.GENERAL.SOMETHING-WENT-WRONG-WHILE-FETCHING-CHATGPT"
                    )
                  );
                },
              });
          } else {
            this.toaster.pop(
              "error",
              this.translate.instant(
                "toasters.GENERAL.SOMETHING-WENT-WRONG-WHILE-FETCHING-CHATGPT"
              )
            );
          }
        }
      }
    ),
    this.subscriptions.push(
      combineLatest([
        this.auth.user$,
        this.companiesStoreService.companies$,
      ]).subscribe(([user, companies]) => {
        if (!user) return;
        const company = companies.find((c) => c._id === user.company._id);
        if (!company) return;
        this.company = company;
        this.checkForAiMaintenanceAssistance();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.pusherService.channel.unbind("ai-assistant-maintenance");
  }

  checkForAiMaintenanceAssistance() {
    if (
      !this.company ||
      !this.company?.aiMaintenanceAssistant?.pm?.start ||
      !this.company?.aiMaintenanceAssistant?.pm?.rule
    ) {
      this.toaster.pop(
        "error",
        this.translate.instant(
          "maintenance.START-OR-RULE-OR-EXTRACTION-RULE-NOT-FOUND"
        )
      );
      return false;
    }
    return true;
  }

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        if (this.commonService.checkAiAssistanceFile(file)) {
          this.file = file;
        }
      });
    }
  }

  viewMaintenanceFile = ($event: Event) => {
    $event.preventDefault();
    $event.stopPropagation();
    window.open(URL.createObjectURL(this.file), "_blank");
  };

  removeMaintenanceFile = ($event: Event) => {
    $event.preventDefault();
    $event.stopPropagation();
    this.file = null;
  };

  getAiMaintenanceAssistanceFromFile($event: Event) {
    $event.stopPropagation();
    $event.preventDefault();
    if (!this.file) {
      this.toaster.pop(
        "error",
        this.translate.instant("maintenance.FILE-NOT-FOUND")
      );
      return;
    }
    if (!this.checkForAiMaintenanceAssistance()) {
      return;
    }

    this.loaderService.add();
    const formData = new FormData();
    formData.append("file", this.file);
    this.openaiStoreService.aiMaintenanceAssistanceForFile(formData).subscribe({
      next: () => {
        setTimeout(() => {
          this.loaderService.remove();
        }, 500000);
      },
      error: () => {
        this.loaderService.remove();
        this.toaster.pop(
          "error",
          this.translate.instant(
            "toasters.GENERAL.SOMETHING-WENT-WRONG-WHILE-FETCHING-CHATGPT"
          )
        );
      },
    });
  }
}
