import { IField } from "../../interfaces/field";
const FieldDefinition: IField[] = [
    {
        name: 'company.name',
        label: 'table.translation.table-title.COMPANY',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'key',
        label: 'table.translation.table-title.CONTROL-KEY',
        type: 'textbox',
        required: false,
        readonly: true,
        minWidth: 500,
    },
    {
        name: 'English',
        label: 'table.translation.table-title.ENGLISH',
        type: 'textbox',
        required: false,
        readonly: false,
    }
];

export default
{
    FieldDefinition
}