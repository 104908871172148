import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../routes/user/auth.service';
import { timeCalculation } from '../../shared/comparators/date-comparator';
import { ConfirmType } from '../constants/confirm-result.enum';
import FieldDefinition from '../constants/field-definitions/sla';
import { ConfirmService } from '../utils/confirm/confirm.service';
import { CommonService } from './common.service';
import { CustomFieldsStoreService } from './custom-fields-store.service';
import { MachineStatusesStoreService } from './machine-statuses-store.service';
import { MaintenanceTypesStoreService } from './maintenance-types-store.service';
import { TranslationsStoreService } from './translations-store.service';

@Injectable({
  providedIn: 'root'
})
export class SlaStoreService {

  public readonly columns$: Observable<any[]>;

  constructor(
    private auth: AuthService,
    private customFields: CustomFieldsStoreService,
    private commonService: CommonService,
    private maintenanceTypesService: MaintenanceTypesStoreService,
    private machineStatusService: MachineStatusesStoreService,
    private confirm: ConfirmService,
    private translate: TranslateService,
    private translationsStoreService: TranslationsStoreService,
    private router: Router,
  ) {
    this.columns$ = combineLatest([this.auth.user$, this.translationsStoreService.languageCode$]).pipe(
      map(([user, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(col => (col.name != "company.name" || user.isRoot()) && (col.name != "isDeleted" || user.isAdminOrToolAdmin()));

        const col = this.customFields.toColumnDef(defaults, {
          rowSelector: 'id_num',
          table: 'event-logs',
          format: this.formatCell.bind(this)
        });

        return col
      })
    );

  }

  private formatCell = (col, field) => {
    switch (field.name) {
      case "start":
      case "finish":
      case "updatedAt":
      case "qaApprovalTime":
        return {
          ...col,
          valueFormatter: (params) => {
            const event = { ...params.data }
            const props = field.name.split('.');
            const value = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', event);
            return value && this.commonService.getDateTime(value)
          },
          valueGetter: (params) => {
            const event = { ...params.data }
            const props = field.name.split('.');
            const value = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', event);
            return value && this.commonService.getDateTime(value)
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.event[field.name] ? nodeA.data.event[field.name] : null, nodeB && nodeB.data.event[field.name] ? nodeB.data.event[field.name] : null, nodeA, nodeB, isInverted);
          }
        }
      case "file":
        return {
          ...col,
          cellRenderer: 'fileRedirection',
          cellRendererParams: {
            isMultiple: true,
          }
        }
      case "event.createdAt":
        return {
          ...col,
          cellRenderer: this.timeBound.bind(this)
        }
      case "type":
        return {
          ...col,
          cellRenderer: this.eventType.bind(this)
        }
      case "tool.name":
        return {
          ...col,
          cellRenderer: 'customClick',
          cellRendererParams: {
            onClick: this.toolLink.bind(this),
            field: field.name
          }
        }
      default:
        return col;
    }
  }

  eventType = (params) => {
    if (params.data.maintenanceType) {
      const maintenanceTypes = this.maintenanceTypesService.getList();
      const matchRecord = maintenanceTypes.find(m => params.data.maintenanceType && m._id.toString() == params.data.maintenanceType.toString());
      if (matchRecord) {
        return matchRecord.name;
      }
    } else {
      const machineStatus = this.machineStatusService.getList();
      const matchRecord = machineStatus.find(m => params.data.machineStatus && m._id.toString() == params.data.machineStatus.toString());
      if (matchRecord) {
        return matchRecord.title;
      }
    }
    return null;
  }

  toolLink(params) {
    if(params.rowData.tool && params.rowData.tool?.isDeleted){
      this.confirm.show(this.translate.instant('confirm.TOOL.TOOL_DELETED'), { type: ConfirmType.CONFIRM_ONLY, confirmText: this.translate.instant('shared.OK'), defaultBtnClass: 'btn-danger' }).subscribe(() => {})
    }else{
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/main/maintenance/tool/${params.rowData && params.rowData.tool ? params.rowData.tool.id_num : ""}`])
      );
      window.open(url, '_blank');
    }
  }

  timeBound(params) {
    return this.commonService.getTimeDiff(params.data.createdAt, params.data.firstChangedAt ? params.data.firstChangedAt : new Date());
  }

}
