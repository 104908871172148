<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="m-2">
        <h2>Service Level Agreement (SLA)</h2>

        <ol>
          <li>
            <p><u>General.</u></p>

            <p>
              This Service Level Agreement (this "
              <strong>SLA</strong>
              ") set forth the relevant service levels with respect to the
              services (the "
              <strong>Service</strong>
              ") provided by AiDock Ltd. (the "
              <strong>Company</strong>
              " or "
              <strong>AiDock</strong>
              ") to its customers. This SLA is part of the Company's Terms of
              Service (the "
              <strong>Agreement</strong>
              "). All capitalized terms used in this SLA that are not otherwise
              defined herein, shall have the meaning given to them in the
              Agreement.
            </p>
          </li>

          <li>
            <p><u>Definitions.</u></p>

            <ol>
              <li>
                <div>
                  <div>
                    "
                    <strong>Availability</strong>
                    " refers to availability of the Service for a given month
                    that will be calculated according to the following formula:
                    ((TMM-TMU) x 100)/TMM.
                  </div>
                  <div>
                    <p>Where:</p>
                    <p>TMM = Total minutes in a certain month.</p>
                    <p>
                      TMU = Total minutes in a certain month in which the
                      service are unavailable
                    </p>
                  </div>
                </div>
              </li>

              <li>
                <div>
                  "
                  <strong>Maintenance</strong>
                  " refers to the bug fixes and version upgrades for the
                  Service.
                </div>
              </li>

              <li>
                <div>
                  "
                  <strong>Service Credit</strong>
                  " shall mean one (1) day extension of the subscription term.
                </div>
              </li>

              <li>
                <div>
                  "
                  <strong>Support</strong>
                  " refers to the Company's responsibilities to address
                  questions and issues related to the service. Support will be
                  provided remotely. Support may be provided by the Company's
                  authorized distributors or resellers.
                </div>
              </li>
            </ol>
          </li>

          <li>
            <p><u>Support Process.</u></p>
            <ol>
              <li>
                <p>
                  <u>Submitting Support Requests.</u>
                  Customer that wish to request support must open a support
                  ticket by sending an email to: support@aidock.net. Critical
                  incidents reported have to be followed by a phone call to:
                  +972-52-654-2110 (the "
                  <strong>Support Phone</strong>
                  ") in order to immediately determine the best communication
                  line while handling the ticket.
                </p>
              </li>

              <li>
                <p>
                  <u>Support Process.</u>
                  The Company will analyze the ticket and evaluate the
                  completeness of the information submitted by the Customer. To
                  the extend required the Company shall contact the Customer for
                  additional information. If the Company determines that the
                  root cause of the incident is the Service, the Company will
                  use its reasonable efforts to resolve the issue according to
                  the assigned severity levels, as defined in the table in 3.3
                  below (the "
                  <strong>Severity Levels</strong>
                  "). If the root cause of a reported incident is deemed to be
                  an external failure, the Company shall inform the Customer of
                  such fact and the Company will have no obligation to resolve
                  such issue.
                </p>
              </li>

              <li>
                <p>
                  <u>Severity Levels.</u>
                  The ticket will be handled according to the ticket severity
                  level in the table below:
                </p>

                <table>
                  <thead>
                    <tr>
                      <th>Severity Level</th>
                      <th>Definition</th>
                      <th>Initial Response Time</th>
                      <th>Time of Resolution</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        <div>1</div>
                        <div>(High)</div>
                      </th>
                      <td>
                        A total failute of the Service
                      </td>
                      <td>
                        Up to 4 hours.
                      </td>
                      <td>
                        As the resolution time depends
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <div>2</div>
                        <div>(Medium)</div>
                      </th>
                      <td>
                        Major function is down or not working as expected.
                        Adversely affects and prevents the accomplishment of an
                        operational or mission essential function. Typically, a
                        workaround is not available.
                      </td>
                      <td>
                        Within 24 hours.
                      </td>
                      <td rowspan="2">
                        <p>
                          on the type of problem or request, it cannot be
                          determined in advance.
                        </p>

                        <p>
                          However, the Company will work to resolve the issue
                          until the Service is returned to normal operation.
                        </p>

                        <p>Customer Will be notified of the status changes.</p>
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <div>3</div>
                        <div>(Low)</div>
                      </th>

                      <td>
                        <p>
                          Minor function is down or not working as
                          expected/cosmetic issues. Adversely affects(but does
                          not prevent) the accomplishment of an operational or
                          mission. essential function.
                        </p>
                        <p>Typically, a workaround is available.</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>

              <li>
                <p>
                  <u>Support Service Hours.</u>
                  Support Services will be provided during the support hours as
                  set forth in the table below:
                </p>

                <table>
                  <thead>
                    <tr>
                      <th>Support Hours Type</th>
                      <th>Support Hours</th>
                      <th>Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Normal Hours</th>
                      <td>9:00am-6:00pm local time.</td>
                      <td>Excluding national holidays.</td>
                    </tr>
                    <tr>
                      <th>Out of Office Hours</th>
                      <td>6:00pm-9:00am local time.</td>
                      <td>
                        Only available for Severity Level 1 via the Support
                        Phone.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ol>
          </li>

          <li>
            <p><u>Maintenance:</u></p>
            <ol>
              <li>
                The Company shall make available to Customer any updates to the
                Service if and when the Company makes such updates generally
                available for no additional charge to its other customers then
                covered by maintenance services with similar terms as the terms
                herein.
              </li>
              <li>
                <p>Upgrades of Version:</p>
                <ol>
                  <li>
                    Major vresion upgrade may include significant capabilities
                    or feature enhancements. Major upgrades may also include bug
                    fixes. The Company will provide Customer with a prior notice
                    of at least 7 days before the release of a Major version.
                  </li>
                  <li>
                    Minor version upgrades may include bug and/or minor
                    functionality enhancements. The Company will provide
                    Customer with a prior notice of at least 3 days before the
                    release of a Minor version.
                  </li>
                </ol>
              </li>
              <li>
                The above notice period shall not be provided in case of urgent
                security updates or issues that a causing critical production
                issues or severe security threats.
              </li>
            </ol>
          </li>
          <li>
            <p>
              <u>Availability.</u>
              Subject to the terms of this SLA, the Service will have 99.5%
              Availability each calendar month (the "
              <strong>Uptime Commitment</strong>
              "). The Service will not be deemed unavailable for any downtime or
              outages excluded from such calculation by reason of the exceptions
              set forth below in this SLA including any Maintenance. Aidock's
              records and data will be the sole basis for all SLA calculations
              and determinations.
            </p>
          </li>
          <li>
            <p>
              <u>Limitations.</u>
              this SLA do not apply to any performance or availability issues
              that: (i) is caused by factors outside of Aidock's reasonable
              control, including, without limitation, telecommunications
              provider-related problems or issues, internet access or related
              problems occuring beyong the point in the network where Aidock
              maintains access and control over the Service; (ii) results from
              any actions or inactions of Customer or any third party (except
              for Aidock's auhorized distributors or resellers); (iii) results
              from the Customer Applications, Customer's equipment, software or
              other technology; (iv) occurs during Aidock's scheduled
              Maintenance; (v) occurs during Aidock's emergency Maintenance; or
              (vi) results from any alpha, beta, developer preview, development
              test or pilot version of the Service provided to Customer.
            </p>
          </li>

          <li>
            <p><u>Remedies.</u></p>
            <ol>
              <li>
                <p>
                  <u>Response Time.</u>
                  In the event Aidock has not responded within the Response Time
                  specified in 3.3 above then for each 4 hours of delay in
                  response time, Customer shall be entitled, as its sole and
                  exclusive remedy, to a Service Credit.
                </p>
              </li>
              <li>
                <p>
                  <u>Availability.</u>
                  In the event Availability for a particular month is below
                  99.5% then for each 2% of availability below 99.5% Customer
                  will be entitled, as its sole and exclusive remedy, to a
                  Service Credit.
                </p>
              </li>

              <li>
                <p>
                  The SLA Credits specified above are Customer's sole and
                  exclusive remedies with respect to this SLA. To receive
                  Service Credits, Customer must submit a credit request within
                  30 days of the event that gave rise to the SErvice Credit
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>
