import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../../../routes/user/auth.service';
import { Part } from '../../interfaces/part';
import { Transaction } from '../../interfaces/transaction';

@Injectable({
  providedIn: 'root'
})
export class TransactionsBackendService {
  url = '/api/transactions';

  constructor(private http: HttpClient, private auth: AuthService) { }

  list(lastFetched: Date){
    const url = lastFetched ? `${this.url}?time=${lastFetched.getTime()}` : this.url;
    return this.http.get<Array<Transaction>>(url)
  }

  listTool(){
    return this.http.get<Array<Transaction>>(`${this.url}/tool-list`)
  }

  create(part: Transaction) {
    return this.http.post<Transaction>(this.url, part);
  }

  update(part: Transaction) {
    return this.http.put<Transaction>(`${this.url}/${part._id}`, part);
  }

  delete(part: Transaction) {
    return this.http.delete(`${this.url}/${part._id}`);
  }
}
