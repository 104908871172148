import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFormat } from '../../interfaces/data-format';

@Injectable({
  providedIn: 'root'
})
export class DataFormatsBackendService {
  url = '/api/data-formats';
  constructor(private http: HttpClient) {}

  list() {
    return this.http.get<Array<DataFormat>>(`${this.url}`);
  }

  options() {
    return this.http.get<Array<DataFormat>>(`${this.url}/options`);
  }

  create(dataFormat: DataFormat) {
    return this.http.post<DataFormat>(`${this.url}`, dataFormat);
  }

  update(dataFormat: DataFormat, dataFormatId: string) {
    return this.http.put<DataFormat>(`${this.url}/${dataFormatId}`, dataFormat);
  }

  delete(dataFormatId: string) {
    return this.http.delete(`${this.url}/${dataFormatId}`);
  }
}
