export enum ROLES {
  FAILURE_REPORTING = "failure_reporting",
  DEFINE_OPERATIONAL = "define_operational",
  REPORT_MAINTENANCE = "report_maintenance",
  CREATE_MAINTENANCE = "create_maintenance",
  ADMIN = "admin",
  TOOL_ADMIN = "tool_admin",
  ROOT = "root",
  INVENTORY = "inventory",
  MODERATOR = "moderator",
  BILLING = "billing",
  REPORT_MEASUREMENTS = "report_measurements",
  EDIT_MEASUREMENTS = "edit_measurements",
  QA = "qa",
  SAFETY = "safety",
  DEVELOPER = "developer",
}

export enum EVENT_STATUS{
  ACTIVE = "active",
  PENDING_MODERATION = "pending-moderation",
  WAITING_FOR_QA = "waiting-for-qa",
  WAITING_FOR_SAFETY = "waiting-for-safety",
  CLOSED = "closed",
}

export enum MONTH_PERIOD{
  LAST_MONTH = "last-month",
  THIS_MONTH = "this-month",
  PREVIOUS_MONTH = "previous-month"
}

export enum YEAR_PERIOD{
  LAST_YEAR = "last-year",
  THIS_YEAR = "this-year",
  PREVIOUS_YEAR = "previous-year"
}

export enum WEEK_PERIOD{
  LAST_WEEK = "last-week",
  THIS_WEEK = "this-week",
  PREVIOUS_WEEK = "previous-week"
}

export enum REPORT_CHART{
  MONTHLY_DOWNTIME = 'monthlyDowntime',
  PLANNED_VS_ACTUAL_PM = 'plannedVsActualPm',
  PM_FM_WORK_HOURS = 'pmFmWorkHours',
}

export enum PM_CALENDAR_STATUS {
  SKIPPED = "Skipped",
  PENDING = "Pending",
  NO_STATUS = "No status",
  WAIT_FOR_QA = "Wait for QA",
  WAIT_FOR_SAFETY = "Wait for safety",
  DONE = "Done",
}

export enum SECTION_NAME {
  TOOL = "tool",
  PM = "pm",
  HISTORY = "history",
  INVENTORY = "inventory",
  TRANSACTION = "transaction",
  TASK_TRANSACTION = "task-transaction",
  USER_LOGS = "user-logs",
  SIGNATURE_AT = "signature-at",
  HISTORY_AT = "history-at",
  AI_HELPER = "ai-helper",
  SLA = "sla",
}

export enum AUTO_COMPLETE_TOOl {
  NONE = "none",
  JS = "js",
  CHAT_GPT = "chat-gpt",
  GOOGLE = "google",
}

export enum KPI_TYPE {
  FAILURES = "FAILURES",
  IDLE = "IDLE",
  PM = "PM",
  FM_LAST_MONTH = "FMLastMonth",
  PM_LAST_MONTH = "PMLastMonth",
  DOWN_TIME_HOURS = "downTimeHours",
}

export enum CHART_TYPE {
  BAR = "bar",
  PIE = "pie",
  DOUGHNUT = "doughnut",
}

export enum AI_HELPER_TABLE {
  TOOL = "tool",
}

export enum EMAIL_FREQUENCY {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}

export enum SORT_BY {
  ASC = "asc",
  DESC = "desc",
}

export enum LSL_KIND {
  GREATER = '>',
  GREATER_EQUAL = '>=',
}

export enum USL_KIND {
  LESSER = '<',
  LESSER_EQUAL = '<=',
}

export enum MEASUREMENT_STATUS {
  MISSING_VALUE = 1,
  IN_TOLERANCE = 2,
  WARNING = 3,
}

export enum MEASUREMENT_TYPE {
  AUTO_SCHEDULED = 'auto-scheduled',
  MANUALLY = 'manually',
}

export enum MEASUREMENT_EDIT_TYPE {
  NO_LIMIT = 'no-limit',
  NO_EDIT = 'no-edit',
}

export enum AZURE_BACKUP_TYPE {
  MANUAL = 'manual',
  MONTHLY = 'monthly',
  DAILY = 'daily',
}

export enum FUNCTION_TYPE {
  AVERAGE = "average",
  MAX = "max",
  MIN = "min",
  RANGE = "range",
  SQRT = "sqrt",
  ST_DEV = "stDev",
  ABS = "abs",
}

export enum FORMULA_MEASUREMENT_TYPE {
  TARGET = "target",
  RESULT = "result",
  LSL = "lsl",
  USL = "usl",
  LCL = "lcl",
  UCL = "ucl",
}

export enum CHECKLIST_TYPE {
  CHECKLIST = 'checklist',
  MEASUREMENT = 'measurement',
  COMMENT = 'comment',
  CHAPTER = 'chapter',
}