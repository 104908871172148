import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PartsStoreService } from '../../../../core/services/parts-store.service';
import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslationsStoreService } from '../../../../core/services/translations-store.service';
import { CommonService } from '../../../../core/services/common.service';
import { ToolService } from '../../../../routes/main/tool/tool.service';

@Component({
  selector: 'app-related-inventory',
  templateUrl: './related-inventory.component.pug',
  styleUrls: ['./related-inventory.component.scss']
})
export class RelatedInventoryComponent implements OnInit, OnDestroy {
  @Input() selectedParts: string[] = [];

  private subscriptions: Subscription[] = [];
  toolParts = [];
  parts = [];
  lightScrollbarOptions = this.commonService.getLightScrollbarOptions();

  constructor(
    public bsModalRef: BsModalRef,
    private partsStoreService: PartsStoreService,
    public translationService: TranslationsStoreService,
    private commonService: CommonService,
    private toolService: ToolService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.toolService.toolParts$.subscribe((parts) => {
        this.toolParts = parts.toArray().map((part) => part._id.toString());
        this.filterParts();
      }),
    )
  }

  filterParts() {
    const parts = (this.selectedParts || []).concat(this.toolParts);
    this.partsStoreService.multiple(parts).subscribe((parts) => {
      this.parts = parts;
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
