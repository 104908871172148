import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { isArray } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomFieldsStoreService } from '../../../core/services/custom-fields-store.service';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';

@Component({
  selector: 'app-unhide-column',
  templateUrl: './unhide-column.component.pug',
  styleUrls: ['./unhide-column.component.scss']
})
export class UnhideColumnComponent implements OnInit {

  form: UntypedFormGroup;
  fields: any
  isUpdated: boolean = false;
  list:any

  constructor(
    public bsModalRef: BsModalRef,
    public fb: UntypedFormBuilder,
    public translationService: TranslationsStoreService,
    private translate: TranslateService,
    private customFieldsStoreService: CustomFieldsStoreService,
    private toaster: ToasterService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      column: this.fb.control('', Validators.required),
    });
    this.list = this.fields.filter(f => f.hide).map(f => ({ id: f.name, text: (typeof f.translationLabel != 'undefined' && f.translationLabel != this.translate.instant(f.translationLabel) ? this.translate.instant(f.translationLabel) : f.label) }));
    if(!isArray(this.list)){
      this.list = this.list.toArray();
    }
  }

  save = () => {
    if (this.form.valid) {
      const field = this.fields.find(f => f.hide && this.form.value.column == f.name);
      if(field){
        this.customFieldsStoreService.update({...field, hide: false}).subscribe(
          () => {
            this.toaster.pop('success', this.translate.instant('toasters.CUSTOM_FIELDS.OK_UPDATE'))
            this.customFieldsStoreService.load();
            this.bsModalRef.hide();
          },
          (err) => {
            this.toaster.pop('error', this.translate.instant('toasters.CUSTOM_FIELDS.ERR_UPDATE'))
          }
        )
      }else{
        this.toaster.pop('error', this.translate.instant('toasters.CUSTOM_FIELDS.ERR_UPDATE'))
      }
    }
  }

}
