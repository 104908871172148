import { Company } from "./company";
import { Menu } from "./menu";

export class DynamicDropdown{
    _id?: String;
    company?: String | Company;
    menu?: String | Menu;
    id_num?: Number;
    key?: String;
    customFields?: Object;
    name: String;
    isDeleted?: boolean;
    createdAt?: Date;
    updatedAt?: Date;

    constructor(options:{
        _id?: String,
        company?: String | Company,
        menu?: String | Menu,
        id_num?: Number,
        key?: String,
        customFields?: Object;
        name: String,
        isDeleted?: boolean,
        createdAt?: Date,
        updatedAt?: Date,
    }){
        this._id = options._id || null;
        this.company = options.company || null;
        this.menu = options.menu || null;
        this.id_num = options.id_num || 0;
        this.key = options.key || null;
        this.customFields = options.customFields || {};
        this.name = options.name || null;
        this.isDeleted = options.isDeleted || false;
        this.createdAt = options.createdAt || null;
        this.updatedAt = options.updatedAt || null;
    }
}
