import { IField } from "../../interfaces/field";

const section = "measurements";

const FieldDefinition: IField[] = [
  {
    name: "id_num",
    label: "general.ID",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "name",
    label: "measurements.NAME",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "type",
    label: "measurements.TYPE",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "toolObject.name",
    label: "measurements.TOOL-NAME",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "target",
    label: "measurements.TARGET",
    type: "numeric",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "frequency",
    label: "measurements.FREQUENCY",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "lslKind",
    label: "measurements.LSL-KIND",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "lsl",
    label: "measurements.LSL",
    type: "numeric",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "uslKind",
    label: "measurements.USL-KIND",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "usl",
    label: "measurements.USL",
    type: "numeric",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "lclKind",
    label: "measurements.LCL-KIND",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "lcl",
    label: "measurements.LCL",
    type: "numeric",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "uclKind",
    label: "measurements.UCL-KIND",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "ucl",
    label: "measurements.UCL",
    type: "numeric",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "unitObject.name",
    label: "measurements.UNIT",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "formatObject.formatText",
    label: "measurements.FORMAT",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "editHours",
    label: "measurements.CAN-BE-UPDATED-TILL-HOURS",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "isReMeasurementRequiredOnOutOfTarget",
    label: "measurements.IS-RE-MEASUREMENT-REQUIRED-ON-OUT-OF-TARGET",
    type: "checkbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "isReMeasurementRequiredOnOutOfSpec",
    label: "measurements.IS-RE-MEASUREMENT-REQUIRED-ON-OUT-OF-SPEC",
    type: "checkbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "isResponseRequired",
    label: "measurements.IS-RESPONSE-REQUIRED",
    type: "checkbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "isOpenEventFmOnOutOfSpec",
    label: "measurements.IS-OPEN-EVENT-FM-ON-OUT-OF-SPEC",
    type: "checkbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "formula",
    label: "measurements.FORMULA",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "secondFormula",
    label: "measurements.SECOND-FORMULA",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "measuredFormula",
    label: "measurements.MEASURED-VALUE-FORMULA",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "mandatory",
    label: "measurements.MANDATORY",
    type: "checkbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "inventoryForFirstActionWithMaterialObject",
    label: "measurements.INVENTORY-SELECT-FOR-FIRST-TYPE",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "firstActionUnitObject.name",
    label: "measurements.UNIT-FOR-FIRST-TYPE",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "inventoryForSecondActionWithMaterialObject",
    label: "measurements.INVENTORY-SELECT-FOR-SECOND-TYPE",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "secondActionUnitObject.name",
    label: "measurements.UNIT-FOR-SECOND-TYPE",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "comment",
    label: "measurements.COMMENT",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "updatedAt",
    label: "measurements.UPDATED-AT",
    type: "datepicker",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "updatedBy.displayName",
    label: "measurements.UPDATED-BY",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "isDeleted",
    label: "general.DELETED",
    type: "checkbox",
    required: false,
    readonly: false,
    section,
  },
];

export default {
  FieldDefinition,
};
