<div class="cookie" *ngIf="display">
  <div class="cookie_data">
    <p>
      {{ 'general.COOKIE-STATEMENT-TEXT' | translate }}
      <a [routerLink]="'/terms-of-use'" [attr.route]="'/terms-of-use'">
        {{ ('general.COOKIE-STATEMENT' | translate) + '.' }}
      </a>
    </p>
    <button class="blue_btn" (click)="close($event)">
      {{ 'general.CLOSE' | translate }}
    </button>
  </div>
</div>
