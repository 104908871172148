import { AbstractControl, ValidatorFn } from "@angular/forms";

const regex = /^(?=.*[A-Z]).*$/;

const mustHaveUppercaseValidator = (): ValidatorFn => {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const forbidden = mustHaveUppercase(control.value);
      return forbidden ? null : {mustHaveUppercase: {value: control.value}};
    };
  }

  const mustHaveUppercase = value =>  regex.test(value);

  export default{
    mustHaveUppercase,
    mustHaveUppercaseValidator
  }