import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

@Component({
  selector: 'tooltip-component',
  template: ` <div class="custom-tooltip" [style.background-color]="color">
    <p>
      <span>{{ data }}</span>
    </p>
  </div>`,
  styles: [
    `
      :host {
        position: absolute;
        width: 300px;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip p {
        margin: 5px;
        padding: 10px;
        word-break: break-word;
        color: white;
        font-family: Montserrat, Rubik !important;
        line-height: 24px !important;
      }
    `,
  ],
})
export class CustomTooltipComponent implements ITooltipAngularComp {
  private params!: { color: string } & ITooltipParams;
  public data!: any;
  public color!: string;

  agInit(params: { color: string } & ITooltipParams): void {
    this.params = params;
    this.data = params.value;
    this.color = 'black';
  }
}