<div class="wrapper">
  <div class="block-center">
    <!-- START card-->
    <div class="logo text-center">
      <img
        src="assets/img/Logo land - Regular White@3x.png"
        alt="logo"
        class="brand-logo"
      />
    </div>
    <div class="card card-flat">
      <div class="">
        <div class="sign-up-header">
          <p class="sign-up-paragraph text-center">Sign up</p>
        </div>
        <div class="line"></div>
        <re-captcha
          #captchaRef="reCaptcha"
          (resolved)="resolved($event)"
          (error)="onError($event)"
          errorMode="handled"
          size="invisible"
        ></re-captcha>
        <form
          [formGroup]="valForm"
          class="signup-form form-validate mb-3"
          role="form"
          name="registerForm"
          novalidate=""
          (submit)="submitForm($event, valForm.value)"
        >
          <div class="form-group">
            <p class="text-muted mb-1">Company name</p>
            <div class="input-group with-focus">
              <input
                class="form-control"
                type="text"
                autofocus
                name="company"
                placeholder="Company Name"
                autocomplete="off"
                formControlName="company"
                required=""
                (keypress)="commonService.typeA($event)"
              />
            </div>
            <div
              class="text-danger"
              *ngIf="
                valForm.get('company').hasError('required') &&
                (valForm.get('company').dirty || valForm.get('company').touched)
              "
            >
              This field is required
            </div>
            <div
              class="text-danger"
              *ngIf="valForm.get('company').hasError('companyExists')"
            >
              This company is already registered
            </div>
          </div>
          <div class="form-group">
            <p class="text-muted mb-1">
              Username<img
                src="assets/img/info_icon_transparent.png"
                [tooltip]="
                  'toasters.USER.PLEASE-ENTER-VALID-USERNAME' | translate
                "
                class="ml-1 mr-1"
                style="width: 20px"
                alt=""
              />
            </p>
            <div class="input-group with-focus">
              <input
                class="form-control"
                type="text"
                name="account_username"
                placeholder="Enter username"
                autocomplete="off"
                formControlName="username"
                maxlength="15"
                pattern="^[a-zA-Z0-9-_]+$"
                required=""
                (keypress)="commonService.typeB($event)"
              />
            </div>
            <div
              class="text-danger"
              *ngIf="
                valForm.get('username').hasError('required') &&
                (valForm.get('username').dirty ||
                  valForm.get('username').touched)
              "
            >
              This field is required
            </div>
            <div
              class="text-danger"
              *ngIf="
                valForm.get('username').hasError('pattern') &&
                (valForm.get('username').dirty ||
                  valForm.get('username').touched)
              "
            >
              {{ "toasters.USER.PLEASE-ENTER-VALID-USERNAME" | translate }}
            </div>
          </div>
          <div class="form-group">
            <p class="text-muted mb-1">Your email</p>
            <div class="input-group with-focus">
              <input
                class="form-control"
                type="email"
                name="account_email"
                placeholder="Enter email"
                autocomplete="off"
                formControlName="email"
                required=""
                (keypress)="commonService.typeA($event)"
              />
            </div>
            <div
              class="text-danger"
              *ngIf="
                valForm.get('email').hasError('required') &&
                (valForm.get('email').dirty || valForm.get('email').touched)
              "
            >
              This field is required
            </div>
            <div
              class="text-danger"
              *ngIf="
                valForm.get('email').hasError('pattern') &&
                (valForm.get('email').dirty || valForm.get('email').touched)
              "
            >
              This field must be a valid email address
            </div>
          </div>
          <div class="form-group search_select dropdown-down">
            <p class="text-muted mb-1">Country code</p>
            <ngx-select
              id="defaultPhoneCode"
              [allowClear]="false"
              autocomplete="off"
              [items]="phoneCodes"
              [placeholder]="
                'maintenance.form-labels.SELECT-PLACEHOLDER' | translate
              "
              name="defaultPhoneCode"
              formControlName="defaultPhoneCode"
            ></ngx-select>
            <div
              class="text-danger"
              *ngIf="
                valForm.get('defaultPhoneCode').hasError('required') &&
                (valForm.get('defaultPhoneCode').dirty || valForm.get('defaultPhoneCode').touched)
              "
            >
              This field is required
            </div>
          </div>
          <div class="form-group">
            <p class="text-muted mb-1">Phone number</p>
            <div class="input-group with-focus">
              <input
                class="form-control"
                type="phone"
                name="account_phone"
                placeholder="Enter phone number"
                autocomplete="off"
                (keypress)="commonService.typeE($event)"
                formControlName="phone"
              />
            </div>
            <div
              class="text-danger"
              *ngIf="
                valForm.get('phone').hasError('pattern') &&
                (valForm.get('phone').dirty || valForm.get('phone').touched)
              "
            >
              Phone number should only contains numeric value and plus(+)
            </div>
          </div>
          <div class="form-group">
            <p class="text-muted mb-1">Password</p>
            <div class="input-group with-focus">
              <input
                class="form-control"
                id="id-password"
                type="password"
                name="password"
                formControlName="password"
                [formControl]="valForm.get('password')"
                (keypress)="commonService.typeA($event)"
              />
            </div>
            <div
              class="text-danger"
              *ngIf="
                valForm.get('password').hasError('required') &&
                (valForm.get('password').dirty ||
                  valForm.get('password').touched)
              "
            >
              This field is required
            </div>
            <div
              class="text-danger"
              *ngIf="
                valForm.get('password').hasError('pattern') &&
                (valForm.get('password').dirty ||
                  valForm.get('password').touched)
              "
            >
              Input should match 'a-zA-Z0-9' and 6-10 length
            </div>
          </div>

          <div class="password-requirements text-muted pl-2">
            <p>Passwords must contain:</p>
            <ul>
              <li>
                <ng-container
                  [ngTemplateOutlet]="passwordRule"
                  [ngTemplateOutletContext]="{
                    passed: !valForm.get('password').hasError('mustHaveNumber')
                  }"
                  ]
                >
                  1 number
                </ng-container>
              </li>
              <li>
                <ng-container
                  [ngTemplateOutlet]="passwordRule"
                  [ngTemplateOutletContext]="{
                    passed: !valForm
                      .get('password')
                      .hasError('mustHaveUppercase')
                  }"
                  ]
                >
                  1 uppercase
                </ng-container>
              </li>
              <li>
                <ng-container
                  [ngTemplateOutlet]="passwordRule"
                  [ngTemplateOutletContext]="{
                    passed: !valForm
                      .get('password')
                      .hasError('mustHaveLowercase')
                  }"
                  ]
                >
                  1 lowercase
                </ng-container>
              </li>
              <li>
                <ng-container
                  [ngTemplateOutlet]="passwordRule"
                  [ngTemplateOutletContext]="{
                    passed: !valForm
                      .get('password')
                      .hasError('mustHaveSpecialCharacter')
                  }"
                  ]
                >
                  1 special character (@#$%^&+*!=)
                </ng-container>
              </li>
              <li>
                <ng-container
                  [ngTemplateOutlet]="passwordRule"
                  [ngTemplateOutletContext]="{
                    passed:
                      !valForm.get('password').hasError('required') &&
                      !valForm.get('password').hasError('minlength')
                  }"
                  ]
                >
                  6 characters
                </ng-container>
              </li>
            </ul>
          </div>

          <div class="text-center">
            <a target="_blank" [routerLink]="['/terms-of-use']">Terms of use</a>
          </div>
          <button
            class="signup-button btn btn-block btn-primary mt-3"
            [disabled]="valForm.status !== 'VALID'"
            type="submit"
          >
            Create account
          </button>
        </form>
        <div class="line"></div>
        <p class="have-an-account-q text-center">Already have an account?</p>
        <div
          class="log-in-button-div login-now-button btn btn-block btn-secondary"
          [routerLink]="'/login'"
        >
          <a class="">Log in</a>
        </div>
      </div>
    </div>
  </div>

  <ng-template #passwordRule let-passed="passed">
    <i class="fas fa-check mr-2 text-primary" *ngIf="passed"></i>
    <i class="fas fa-times mr-2 text-danger" *ngIf="!passed"></i>
  </ng-template>

  <ng-template #termsAndAgreement>
    <div class="modal-header">
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <h2 class="text-center">TERMS OF SERVICE</h2>
          <app-terms-and-agreement-content></app-terms-and-agreement-content>
          <h2 class="text-center">Service Level Agreement (SLA)</h2>
          <app-sla-content></app-sla-content>
          <h2 class="text-center">Data Processing Addendum</h2>
          <app-dpa-content></app-dpa-content>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <div class="checkbox c-checkbox">
              <label>
                <input
                  type="checkbox"
                  name="account_agreed"
                  id="account_agreed"
                  [(ngModel)]="eulaAccepted"
                  (change)="accountAgreedChange()"
                />
                <span class="fa fa-check"></span>
                I agree with the the terms
              </label>
            </div>
            <div>
              <span class="text-danger" *ngIf="showAccountAgreedError">
                You must agree the terms
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row" *ngIf="eulaAccepted">
        <div class="col-12">
          <div class="d-flex align-item-center justify-content-between">
            <span>Username: {{ termAndCondition.username }}</span>
            <span>IP: {{ termAndCondition.ip }}</span>
            <span>Signed on: {{ eulaAcceptedAt | date:'dd/MM/yyyy HH:mm' }}</span>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-6">
          <button
            class="btn btn-block btn-secondary mt-3"
            (click)="modalRef.hide()"
          >
            I do not agree
          </button>
        </div>
        <div class="col-6 text-right">
          <button
            class="signup-button btn btn-block btn-primary mt-3"
            (click)="createAccount($event)"
          >
            Create account
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<app-cookie-banner></app-cookie-banner>
