import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-toggle-renderer',
  template:  `
  <ui-switch [(ngModel)]="value" (change)="change($event)"></ui-switch>
  `
})
export class ToggleEditorComponent implements ICellEditorAngularComp {
  params;
  label: string;
  value: boolean;
  OnBeforeChange: Function;
  OnChanged: Function;

  agInit(params): void {
    this.params = params;
    this.value = params.value || false;
    this.OnBeforeChange = params.beforeChange || null;
    this.OnChanged = params.OnChanged || null;
  }

  beforeChange = new Observable<boolean>(observer =>{
    if (this.OnBeforeChange && observer) this.OnBeforeChange(this.params, observer)
    else
      observer.next(true);
  });

  getValue() {
    return this.value;
  }

  change = event =>{
    if (this.OnChanged) this.OnChanged(event, this.params);  
  }

}