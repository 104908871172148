<div class="wrapper">
  <div class="block-center">
    <div class="logo text-center">
      <img
        src="assets/img/Logo land - Regular White@3x.png"
        alt="logo"
        class="brand-logo"
      />
    </div>
    <div class="main-card card card-flat">
      <div class="row">
        <div class="card-body text-center">
          <h2 class="mb-2">Sign up successful!</h2>
          <p class="mb-2">We will send you a verification email.</p>
          <div class="mb-3">
            <img class="check-icon" src="./assets/img/check-icon.jpg" alt="" />
          </div>
          <p><a href="#" routerLink="/login">Login</a></p>
        </div>
      </div>
    </div>
  </div>
</div>
