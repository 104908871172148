import { User } from "../../routes/user/login.response";
import { ChecklistItem } from "./checklist-item";
import { Company } from "./company";
import { Tool } from "./tool";
import { ToolEvent } from "./tool-event";
export class TaskTransaction {
    _id?: string;
    company?: string | Company;
    event?: string | ToolEvent;
    task?: string | ChecklistItem;
    worker?: string | User;
    user?: string | User;
    tool?: string | Tool;
    transactionValue?: string;
    comment?: string;
    attachments?: any;
    id_num: Number;
    createdAt?: Date;
    updatedAt?: Date;
    completedAt?: Date;

    constructor(options: {
        _id?: string,
        company?: string | Company,
        event?: string | ToolEvent,
        task?: string | ChecklistItem,
        worker?: string | User,
        user?: string | User,
        tool?: string | Tool,
        transactionValue?: string,
        comment?: string,
        attachments?: any,
        id_num: Number,
        createdAt?: Date,
        updatedAt?: Date,
        completedAt?: Date,
    }) {

        this._id = options._id || undefined;
        this.company = options.company || null;
        this.event = options.event || null;
        this.task = options.task || null;
        this.worker = options.worker || null;
        this.user = options.user || null;
        this.tool = options.tool || null;
        this.transactionValue = options.transactionValue || null;
        this.comment = options.comment || null;
        this.attachments = options.attachments || null;
        this.id_num = options.id_num || null;
        this.createdAt = options.createdAt || new Date();
        this.updatedAt = options.updatedAt || new Date();
        this.completedAt = options.completedAt || null;
    }
}