import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DrawingTool } from "../../interfaces/drawing-tool";

@Injectable({
  providedIn: "root",
})
export class DrawingToolsBackendService {
  url = "/api/drawing-tools";

  constructor(private http: HttpClient) {}

  get(groupId: string = null): Observable<Array<DrawingTool>> {
    return this.http.get<Array<DrawingTool>>(
      groupId ? `${this.url}/${groupId}` : this.url
    );
  }

  multiple(drawingTools: DrawingTool[]): Observable<DrawingTool[]> {
    return this.http.post<Array<DrawingTool>>(`${this.url}/multiple`, { drawingTools });
  }
}
