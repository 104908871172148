import {
  AfterViewInit,
  Component,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

const CUSTOM_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatetimeComponent),
  multi: true
};


@Component({
  selector: 'app-datetime',
  providers: [CUSTOM_ACCESSOR],
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss']
})
export class DatetimeComponent implements ControlValueAccessor, AfterViewInit {
  @Input() timeOptions: any = {};
  @Input() dateOptions: any = {};
  @Input() hasClearButton: boolean;
  @Input() readonly: boolean;
  @Input() required: boolean;
  @Input() tabindex: string;

  date: Date; // ngModel
  dateModel: Date;
  timeModel: Date;

  onChange = (_: any) => {
  };

  @HostListener('blur')
  onTouched = () => {
  };

  @HostBinding('attr.tabindex')
  get tabindexAttr(): string | undefined {
    return this.tabindex === undefined ? '-1' : undefined;
  }

  ngAfterViewInit(): void {
    this.updateModel(this.date);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(value: any) {
      this.date = value;
      if (isDate(this.date)) {
          setTimeout(() => {
              this.updateModel(this.date);
          }, 0);
      } else {
          this.clearModels();
      }
  }

  updateModel(date: Date) {
    setTimeout(() => {
      this.dateModel = new Date(date);
      this.timeModel = new Date(date);
    });
  }

  clearModels() {
      this.onChange(undefined);
  }

  checkEmptyValue(e: any) {
  }

  onValChange(event) {
  }
}

function isDate(obj: any) {
    return Object.prototype.toString.call(obj) === '[object Date]';
}
