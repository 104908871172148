<span class="zt_avatar-container" [ngClass]="{xl: size==='xl'}">
  <ng-container *ngIf="user$ | async as user">
      <ng-container *ngIf="user.image; else noImage">
        <app-image-presentation
          [fileId]="user.image"
          alt="name"
          size="mini"
          class="zt_avatar-photo"
          [ngClass]="{xl: size==='xl'}"
        ></app-image-presentation>
      </ng-container>
  </ng-container>
</span>

<ng-template #noImage>
  <img [src]="imagePath" alt="name" class="zt_avatar-photo" [ngClass]="{xl: size==='xl'}"/>
</ng-template>