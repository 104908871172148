import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslatorService} from '../core/translator/translator.service';
import {MenuService} from '../core/menu/menu.service';
import {SharedModule} from '../shared/shared.module';

import {menu} from './menu';
import rootMenu from './root-menu';

import {routes} from './routes';
import {UserModule} from './user/user.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthService } from './user/auth.service';
import { NotificationComponent } from './notification/notification.component';
import { SubscriptionPaymentComponent } from './settings/subscriptions/subscription-payment/subscription-payment.component';

@NgModule({
    imports: [
        SharedModule,
        UserModule,
        RouterModule.forRoot(routes),
    ],
    declarations: [NotFoundComponent, NotificationComponent],
    exports: [
        RouterModule
    ],
    providers: []
})

export class RoutesModule {
  constructor(public menuService: MenuService, tr: TranslatorService, public auth: AuthService) {

    this.auth.user$.subscribe(user=>{
      if (user && user.roles){
        if (user.roles.includes("root")){
          this.menuService.setMenu(rootMenu);
        }
        else{
          this.menuService.setMenu(menu);
        }
      }else{
        this.menuService.setMenu([]);
      }
    });
  }
}
