import { FieldBase } from './field-base';

export class DynamicField extends FieldBase<string> {
  controlType: string;
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.controlType = options['controlType'] || '';
  }
}