export const userPermissions = [
  { key: 'failure_reporting', value: "users.permissions.FAILURE-REPORTING" },
  { key: 'define_operational', value: "users.permissions.DEFINE-TOOL-AS-OPERATIONAL" },
  { key: 'report_maintenance', value: "users.permissions.REPORT-MAINTENANCE" },
  { key: 'create_maintenance', value: "users.permissions.CREATE-MAINTENANCE" },
  { key: 'inventory', value: "users.permissions.INVENTORY" },
  { key: 'dispatch_orders', value: "users.permissions.DISPATCH-ORDERS" },
  { key: 'moderator', value: "users.permissions.MODERATOR" },
  { key: 'qa', value: "users.permissions.QA" },
  { key: 'safety', value: "users.permissions.SAFETY" },
  { key: 'edit_measurements', value: "users.permissions.EDIT-MEASUREMENTS" },
  { key: 'report_measurements', value: "users.permissions.REPORT-MEASUREMENTS" },
  { key: 'tool_admin', value: "users.permissions.MAINTENANCE-ADMINISTRATOR" },
  { key: 'admin', value: "users.permissions.IT-ADMINISTRATOR" },
  { key: 'billing', value: "users.permissions.BILLING" },
  { key: 'developer', value: "users.permissions.DEVELOPER" }
];