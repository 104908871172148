import { FileUploaderOptions, FileUploader as NgFileUploader } from 'ng2-file-upload';

export class FileUploader extends NgFileUploader {

  constructor(options: FileUploaderOptions) {
    super(options);
    this.baseUrlToApiPath();
  }

  setOptions(options: FileUploaderOptions): void {
    super.setOptions(options);
    this.baseUrlToApiPath();
  }

  private baseUrlToApiPath () {
    if ( process.env.NG_APP_API_URL && this.options.url.startsWith('/api/')) {
      this.options.url =  process.env.NG_APP_API_URL + this.options.url;
    }
  }

  _onBeforeUploadItem(item) {
    item.withCredentials = false;
    super._onBeforeUploadItem(item);
  }
}
