<div class="wrapper">
  <div class="block-center">
    <div class="logo text-center">
      <img
        src="assets/img/Logo land - Regular White@3x.png"
        alt="logo"
        class="brand-logo"
      />
    </div>
    <div class="card card-flat">
      <div class="">
        <div class="log-in-header">
          <p class="log-in-paragraph text-center">Verify OTP</p>
        </div>
        <div class="line"></div>
        <p class="text-center">
          Security code we sent to {{ mobileNumberDisplay() }} to access your
          account.
        </p>
        <form
          class="login-form form-validate"
          role="form"
          name="loginForm"
          novalidate=""
          (submit)="submitForm()"
        >
          <div class="row">
            <div class="col-12">
              <div class="form-group mt-3 row-style mb-3">
                <div class="input-group with-focus">
                  <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:6, allowNumbersOnly: true}"></ng-otp-input>
                </div>
              </div>
              <div class="clearfix text-center text-danger" *ngIf="errorMessage">
                {{ errorMessage }}
              </div>
              <div class="clearfix text-center" *ngIf="resendSeconds > 0 && !errorMessage">
                Resend OTP in {{ resendSecondText }}
              </div>
              <div class="clearfix text-center" *ngIf="resendSeconds <= 0 && !errorMessage && displayReset">
                <a href="#" (click)="resendOTP($event)">Resend OTP</a>
              </div>
            </div>
          </div>
          <button
            class="login-button btn btn-block btn-primary mt-3"
            type="submit"
          >
            Verify
          </button>
        </form>
        <div class="line"></div>
      </div>
    </div>
  </div>
</div>

<app-cookie-banner></app-cookie-banner>
