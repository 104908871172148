import { IField } from "../../interfaces/field";
import { userPermissions } from '../user-permissions';
const FieldDefinition: IField[] = [
    {
        name: 'id_num',
        label: 'table.users.table-title.USER-ID',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'users'
    },
    {
        name: 'username',
        label: 'table.users.table-title.USERNAME',
        type: 'textbox',
        required: true,
        readonly: false,
        section: 'users'
    },
    {
        name: 'company.name',
        label: 'table.users.table-title.COMPANY',
        type: 'textbox',
        required: true,
        readonly: true,
        section: 'users'
    },
    {
        name: 'password',
        label: 'table.users.table-title.PASSWORD',
        type: 'textbox',
        required: true,
        readonly: false,
        section: 'users'
    },
    {
        name: 'email',
        label: 'table.users.table-title.EMAIL',
        type: 'textbox',
        required: true,
        readonly: false,
        section: 'users'
    },
    {
        name: 'firstName',
        label: 'table.users.table-title.FIRST-NAME',
        type: 'textbox',
        required: true,
        readonly: false,
        section: 'users'
    },
    {
        name: 'lastName',
        label: 'table.users.table-title.LAST-NAME',
        type: 'textbox',
        required: false,
        readonly: false,
        section: 'users'
    },
    {
        name: 'fullName',
        label: 'table.users.table-title.FULL-NAME',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'users'
    },
    {
        name: 'phone',
        label: 'table.users.table-title.PHONE',
        type: 'textbox',
        required: false,
        readonly: false,
        section: 'users'
    },
    {
        name: 'roles',
        label: 'table.users.table-title.PERMISSIONS',
        type: 'multiselect',
        options: userPermissions,
        required: true,
        readonly: false,
        section: 'users'
    },
    {
        name: 'language',
        label: 'offsidebar.setting.LANGUAGE',
        type: 'selectdropdown',
        options: [],
        required: false,
        readonly: false,
        section: 'users'
    },
    {
        name: 'suspendMessageLogout',
        label: "table.users.table-title.SUSPENDS-MESSAGES-WHEN-LOGGED-OUT",
        type: "checkbox",
        readonly: false,
        section: 'users'
    },
    {
        name: 'suspendMessageWorkingHours',
        label: "table.users.table-title.SUSPEND-OUT-OF-FACTORY-WORKING-HOURS",
        type: "checkbox",
        readonly: false,
        section: 'users'
    },
    {
        name: 'workShift',
        label: "table.users.table-title.WORK-SHIFT",
        type: 'selectdropdown',
        options: [],
        readonly: false,
        section: 'users'
    },
    {
        name: 'noEmailNotification',
        label: "table.users.table-title.DONT-SEND-EMAIL",
        type: "checkbox",
        readonly: false,
        section: 'users'
    },
    {
        name: 'dontAssignEvents',
        label: "table.users.table-title.DONT-ASSIGN-EVENTS",
        type: "checkbox",
        readonly: false,
        section: 'users'
    },
    {
        name: 'departmentObject.id_num',
        label: 'table.users.table-title.DEPARTMENT-ID',
        type: 'textbox',
        required: false,
        readonly: true,
        hide: true,
        valueGetter: params => (params.data) && params.data.departmentObject && Object.keys(params.data.departmentObject).length > 0 ? params.data.departmentObject.map(d => d.id_num).join(',') : '',
        section: 'users'
    },
    {
        name: 'department',
        label: 'table.users.table-title.DEPARTMENT',
        type: 'multipledropdown',
        options: [],
        required: false,
        readonly: false,
        section: 'users'
    },
    {
        name: 'siteObject.id_num',
        label: 'table.users.table-title.SITE-ID',
        type: 'textbox',
        required: false,
        readonly: true,
        hide: true,
        valueGetter: params => (params.data) && params.data.siteObject && Object.keys(params.data.siteObject).length > 0 ? params.data.siteObject.map(s => s.id_num).join(',') : '',
        section: 'users'
    },
    {
        name: 'site',
        label: 'table.users.table-title.SITE',
        type: 'multipledropdown',
        options: [],
        required: false,
        readonly: false,
        section: 'users'
    },
    {
        name: 'blockedForWhatsapp',
        label: 'table.users.table-title.BLOCKED',
        type: "checkbox",
        readonly: false,
        isAdmin: true,
        section: 'users'
    },
    {
        name: 'isDeleted',
        label: 'general.DELETED',
        type: "checkbox",
        readonly: false,
        isAdmin: true,
        section: 'users'
    }
];

export default
    {
        FieldDefinition
    }