import { IField } from "../../interfaces/field";

const FieldDefinition: IField[] = [
    {
        name: 'id_num',
        label: 'general.ID',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'company.name',
        label: 'general.COMPANY',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'name',
        label: 'general.NAME',
        type: 'textbox',
        required: true,
        readonly: false
    },
    {
        name: 'isDeleted',
        label: 'general.DELETED',
        type: "checkbox",
        readonly: false,
        isAdmin: true,
    }
]

export default {
    FieldDefinition
}