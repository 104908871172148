import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '../../window.providers';

@Injectable()
export class WindowsService {

  constructor(@Inject(WINDOW) private window: Window) {
  }

  getHostname(): string {
    return this.window.location.hostname;
  }

  getOrigin(): string {
    return window.location.origin;
  }

  getWindowSize(): number {
    return this.window.innerWidth;
  }

  isMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem) && true;
    });
  }

  isAndroid() {
    const toMatch = [
      /Android/i,
    ];
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem) && true;
    });
  }

  detectBrowserName() {
    const agent = this.window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  isSafari() {
    return this.detectBrowserName() === 'safari';
  }

  browser(){
    return this.window.navigator.userAgent.toLowerCase()
  }

}