import { SilentSignatureRendererComponent } from "./components/grid-component/silent-signature-renderer.component";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { AgGridModule } from "ag-grid-angular";
import "ag-grid-community";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";

import { AccordionModule } from "ngx-bootstrap/accordion";
import { AlertModule } from "ngx-bootstrap/alert";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { RatingModule } from "ngx-bootstrap/rating";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { FlotDirective } from "./directives/flot/flot.directive";
import { SparklineDirective } from "./directives/sparkline/sparkline.directive";
import { ColorsService } from "./colors/colors.service";
import { CheckallDirective } from "./directives/checkall/checkall.directive";
import { VectormapDirective } from "./directives/vectormap/vectormap.directive";
import { NowDirective } from "./directives/now/now.directive";
import { ScrollableDirective } from "./directives/scrollable/scrollable.directive";
import { JqcloudDirective } from "./directives/jqcloud/jqcloud.directive";
import { ChecklistComponent } from "./components/checklist/checklist.component";
import { NgTempusdominusBootstrapModule } from "ngx-tempusdominus-bootstrap";
import { DateFnsModule } from "ngx-date-fns";

import { DatetimeComponent } from "./components/datetime/datetime.component";
import { NgxSelectModule } from "ngx-select-ex";
import { TimeUntilPipe } from "./pipes/time-until.pipe";
import { ToolImageComponent } from "./components/tool-image/tool-image.component";
import { ClickStopPropagationDirective } from "./directives/click-stop-propagation.directive";
import { DynamicTableComponent } from "./components/dynamic-table/dynamic-table.component";
import { DynamicFormComponent } from "./components/dynamic-form/dynamic-form.component";
import { DynamicFieldComponent } from "./components/dynamic-field/dynamic-field.component";
import { ToolbarComponent } from "./components/toolbar/toolbar.component";
import { NumericEditorComponent } from "./components/grid-component/numeric-editor.component";
import { MultiselectEditorComponent } from "./components/grid-component/multiselect-editor.component";
import { LinkRendererComponent } from "./components/grid-component/link-renderer.component";
import { DynamicToolbarComponent } from "./components/dynamic-toolbar/dynamic-toolbar.component";
import { ButtonRendererComponent } from "./components/grid-component/button-renderer.component";
import { ToggleEditorComponent } from "./components/grid-component/toggle-editor.component";
import { UiSwitchModule } from "ngx-ui-switch";
import { CheckboxRendererComponent } from "./components/grid-component/checkbox-renderer.component";
import { NumberOnlyDirective } from "./directives/number-only.directive";
import { RequestAccessMessageComponent } from "./components/request-access-message/request-access-message.component";
import { SubscriptionPaymentFormComponent } from "./components/form/subscription-payment-form/subscription-payment-form.component";
// import { NgxPayPalModule } from "ngx-paypal";
import { AddToCalendarComponent } from "./components/add-to-calendar/add-to-calendar.component";
import { SelectDropdownEditorComponent } from "./components/grid-component/select-dropdown-editor.component";
import { ChecklistEditorComponent } from "./components/grid-component/checklist-editor.component";
import { DatePickerEditorComponent } from "./components/grid-component/date-picker-editor.component";
import { DynamicDropdownEditorComponent } from "./components/grid-component/dynamic-dropdown-editor-component";
import { DatePickerModule } from "@syncfusion/ej2-angular-calendars";
import { TimePickerModule } from "@syncfusion/ej2-angular-calendars";
import { CookieBannerComponent } from "./components/cookie-banner/cookie-banner.component";
import { ResizeBorderComponent } from "./components/resize-border/resize-border.component";
import { WorkerConfirmComponent } from "./components/worker-confirm/worker-confirm.component";
import { AddColumnComponent } from "./components/add-column/add-column.component";
import { SmartDropdownEditorComponent } from "./components/grid-component/smart-dropdown-editor.component";
import { MultipleSelectDropdownEditorComponent } from "./components/grid-component/multiple-select-dropdown-editor.component";
import { MalihuScrollbarModule } from "ngx-malihu-scrollbar";
import { AgGridMobileDoubleClickDirective } from "./directives/aggrid/ag-grid-mobile-double-click.directive";
import { CustomClickRendererComponent } from "./components/grid-component/custom-click-renderer.component";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { EditColumnNameComponent } from "./components/edit-column-name/edit-column-name.component";
import { UnhideColumnComponent } from "./components/unhide-column/unhide-column.component";
import { CustomTooltipComponent } from "./components/grid-component/custom-tooltip.component";
import { ChartComponent } from "./components/dynamic-table/chart/chart.component";
import { AgGridEnterpriseTableComponent } from "./components/ag-grid-enterprise-table/ag-grid-enterprise-table.component";
import { FileRendererComponent } from "./components/grid-component/file-renderer.component";
import { SelectFileComponent } from "./components/select-file/select-file.component";
import { ResizableDirective } from "./directives/resizable.directive";
import { SelectColumnComponent } from "./components/dynamic-table/select-column/select-column.component";
import { BooleanRendererComponent } from "./components/grid-component/boolean-renderer.component";
import { ChipRendererComponent } from "./components/grid-component/chip-renderer.component";
import { BarRendererComponent } from "./components/grid-component/bar-render.component";
import { RemoveTextEditorComponent } from "./components/grid-component/remove-text-editor.component";
import { SideMenuComponent } from "./components/side-menu/side-menu.component";
import { PermissionsComponent } from "./components/permissions/permissions.component";
import { AssignColumnComponent } from "./components/assign-column/assign-column.component";
import { ChatGptComponent } from "./components/chat-gpt/chat-gpt.component";
import { ZoomComponent } from "./components/zoom/zoom.component";
import { RelatedInventoryComponent } from "./components/dynamic-field/related-inventory/related-inventory.component";
import { ImagePresentationComponent } from "./components/image-presentation/image-presentation.component";
import { FileRedirectionRendererComponent } from "./components/grid-component/file-redirection-renderer.component";
import { CustomMultipleSelectDropdownEditorComponent } from "./components/grid-component/custom-multiple-select-dropdown-editor.component";
import { SelectDetailedItemComponent } from "./components/checkbox/select-detailed-item/select-detailed-item.component";
import { CheckboxComponent } from "./components/checkbox/checkbox.component";
import { StatusRendererComponent } from "./components/grid-component/status-renderer.component";
import { NgbDropdownModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { StatusTypeRendererComponent } from "./components/grid-component/status-type-renderer.component";
import { AiMaintenanceAssistantModalComponent } from './components/ai-maintenance-assistant-modal/ai-maintenance-assistant-modal.component';
import { NgxFileDropModule } from "ngx-file-drop";
import { AiMaintenancePartsAssistantModalComponent } from './components/ai-maintenance-parts-assistant-modal/ai-maintenance-parts-assistant-modal.component';
LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-069896}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{AnyMaint}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{17_November_2025}____[v3]_[01]_MTc2MzMzNzYwMDAwMA==49d7791339d3834af8870d6b9db78de2"
);
@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    UiSwitchModule.forRoot({}),
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    AgGridModule.withComponents([]),
    NgTempusdominusBootstrapModule,
    DateFnsModule.forRoot(),
    NgxSelectModule.forRoot({ keepSelectedItems: false }),
    // NgxPayPalModule,
    DatePickerModule,
    TimePickerModule,
    MalihuScrollbarModule.forRoot(),
    NgbModule,
    NgbDropdownModule,
    NgxFileDropModule,
  ],
  providers: [
    ColorsService,
  ],
  declarations: [
    FlotDirective,
    SparklineDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    ChecklistComponent,
    DatetimeComponent,
    TimeUntilPipe,
    SafeHtmlPipe,
    ToolImageComponent,
    ClickStopPropagationDirective,
    DynamicTableComponent,
    DynamicFormComponent,
    DynamicFieldComponent,
    DynamicToolbarComponent,
    ToolbarComponent,
    NumericEditorComponent,
    MultiselectEditorComponent,
    SelectDropdownEditorComponent,
    ChecklistEditorComponent,
    ButtonRendererComponent,
    FileRendererComponent,
    ChipRendererComponent,
    BarRendererComponent,
    BooleanRendererComponent,
    SilentSignatureRendererComponent,
    RemoveTextEditorComponent,
    CustomClickRendererComponent,
    FileRedirectionRendererComponent,
    CheckboxRendererComponent,
    DatePickerEditorComponent,
    DynamicDropdownEditorComponent,
    ToggleEditorComponent,
    LinkRendererComponent,
    DynamicToolbarComponent,
    NumberOnlyDirective,
    ResizableDirective,
    RequestAccessMessageComponent,
    SubscriptionPaymentFormComponent,
    AddToCalendarComponent,
    CookieBannerComponent,
    ResizeBorderComponent,
    WorkerConfirmComponent,
    AddColumnComponent,
    SmartDropdownEditorComponent,
    MultipleSelectDropdownEditorComponent,
    AgGridMobileDoubleClickDirective,
    EditColumnNameComponent,
    UnhideColumnComponent,
    CustomTooltipComponent,
    ChartComponent,
    AgGridEnterpriseTableComponent,
    SelectFileComponent,
    SelectColumnComponent,
    SideMenuComponent,
    PermissionsComponent,
    AssignColumnComponent,
    ChatGptComponent,
    ZoomComponent,
    RelatedInventoryComponent,
    ImagePresentationComponent,
    CustomMultipleSelectDropdownEditorComponent,
    CheckboxComponent,
    SelectDetailedItemComponent,
    StatusRendererComponent,
    StatusTypeRendererComponent,
    AiMaintenanceAssistantModalComponent,
    AiMaintenancePartsAssistantModalComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CarouselModule,
    CollapseModule,
    BsDatepickerModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    RatingModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    PopoverModule,
    TypeaheadModule,
    FlotDirective,
    SparklineDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    NumberOnlyDirective,
    ResizableDirective,
    ChecklistComponent,
    AgGridModule,
    NgTempusdominusBootstrapModule,
    DateFnsModule,
    DatetimeComponent,
    NgxSelectModule,
    TimeUntilPipe,
    SafeHtmlPipe,
    ToolImageComponent,
    ClickStopPropagationDirective,
    DynamicTableComponent,
    DynamicFormComponent,
    DynamicFieldComponent,
    DynamicToolbarComponent,
    ToolbarComponent,
    RequestAccessMessageComponent,
    SubscriptionPaymentFormComponent,
    AddToCalendarComponent,
    CookieBannerComponent,
    ResizeBorderComponent,
    WorkerConfirmComponent,
    AddColumnComponent,
    AgGridEnterpriseTableComponent,
    SideMenuComponent,
    PermissionsComponent,
    AssignColumnComponent,
    ChatGptComponent,
    ZoomComponent,
    ImagePresentationComponent,
    CheckboxComponent,
    SelectDetailedItemComponent,
  ],
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
