
<div class="modal-body text-center" [dir]="translationService.dir$ | async" [ngClass]="options.isDelete ? 'danger-line': ''">
  <div class="modal-close">
    <img src="assets/img/close 2.svg" alt="img" (click)="cancel()" class="cursor-pointer"/>
  </div>
  <div class="mt-4 font-weight-bold modal_contant">
    <div class="image_div" *ngIf="options.isDelete">
      <img src="assets/img/big-warning-triangle.png">
    </div>
    <div class="para">
      <p *ngFor="let message of messageLines()">{{ message | translate}}</p>
    </div>
  </div>

  <div class="buttons-row mt-5">
    <button type="button" class="btn white_btn" (click)="decline()" [ngClass]="{'ml-2':(translationService.rtl$ | async)}" *ngIf="showDecline()">{{options.declineText}}</button>
    <button type="button" class="btn {{ options.defaultBtnClass }}" [ngClass]="{'fill_btn':options.isDelete, 'save_btn': !options.isDelete, 'w-auto': !showDecline()}" (click)="confirm()" *ngIf="showConfirm()">{{options.confirmText}} <span *ngIf="isTimer()"> ({{options.timer - (timer$ | async) }})</span></button>
  </div>

</div>
