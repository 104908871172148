import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { CustomSmartDropdownService } from "../../../core/services/custom-smart-dropdown.service";

@Component({
    selector: 'editor-cell',
    template: `
        <div #container class="search_select">
            <ngx-select
                [allowClear]="true"
                [multiple]="multiple"
                autocomplete="off"
                [items]="values"
                [placeholder]='"maintenance.form-labels.SELECT-PLACEHOLDER" | translate'
                [(ngModel)]="value"
                [disabled]="false"
                >
            </ngx-select>
        </div>
    `,
    styles: [`
        .search_select, .search_select .ngx-select{
            min-width: 280px;
        }
        @media (max-width:1199px){
            .search_select, .search_select .ngx-select{
                min-width: 212px;
            }
        }
        .search_select ngx-select{
            width: 100%;
        }
        .search_select .ngx-select__search{
            min-width: 280px;
        }
        @media (max-width:1199px){
            .search_select .ngx-select__search{
                min-width: 212px;
            }
        }
    `]
})
export class SmartDropdownEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public values: any;
    public value: any;
    public multiple: boolean = false;
    @ViewChild('container', { read: ViewContainerRef }) public container;

    constructor(
        public customSmartDropdownService: CustomSmartDropdownService,
    ) {
    }

    ngAfterViewInit() {
    }

    async agInit(params: any,) {
        this.params = params;
        this.multiple = this.params.colDef.multipleSelection;
        this.values = await this.customSmartDropdownService.getCustomValues(this.params.colDef.sectionName, this.params.colDef.fieldName);
        this.value = this.params.data.customFields ? this.params.data.customFields[this.params.colDef.field] : null;
    }

    getValue(): any {
        return this.value;
    }

    isPopup(): boolean {
        return true;
    }
}