import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MaintenanceType } from '../../interfaces/maintenance-type';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceTypesBackendService {
  url = '/api/maintenance-types';

  constructor(private http: HttpClient) { }

  list(){
    return this.http.get<Array<MaintenanceType>>(this.url)
  }

  listAll(){
    return this.http.get<Array<MaintenanceType>>(`${this.url}?show=all`)
  }

  search(search: object): Observable<MaintenanceType[]> {
    return this.http.post<MaintenanceType[]>(`${this.url}/search`, search);
  }

  create(data: MaintenanceType){
    return this.http.post(this.url, data);
  }

  update(data: MaintenanceType){
    return this.http.put(`${this.url}/${data._id}`, data);
  }

  delete(data: MaintenanceType){
    return this.http.delete(`${this.url}/${data._id}`);
  }
}
