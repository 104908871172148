import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';

@Pipe({
  name: 'timeUntil'
})
export class TimeUntilPipe implements PipeTransform {

  transform(value: Date, args?: any): any {
    if (!value) {
      return 'Now';
    }
    return formatDistanceToNow(new Date(value));
  }

}
