import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { MeasurementsStoreService } from "../../../core/services/measurements-store.service";
import { isObject } from "lodash";

@Component({
    selector: 'editor-cell',
    template: `
        <div #container class="search_select">
            <ngx-select
                [allowClear]="true"
                [multiple]="true"
                autocomplete="off"
                [items]="values"
                [placeholder]='"maintenance.form-labels.SELECT-PLACEHOLDER" | translate'
                [(ngModel)]="value"
                [disabled]="false"
                >
            </ngx-select>
        </div>
    `,
    styles: [`
        .search_select, .search_select .ngx-select{
            min-width: 280px;
        }
        @media (max-width:1199px){
            .search_select, .search_select .ngx-select{
                min-width: 212px;
            }
        }
        .search_select ngx-select{
            width: 100%;
        }
        .search_select .ngx-select__search{
            min-width: 280px;
        }
        @media (max-width:1199px){
            .search_select .ngx-select__search{
                min-width: 212px;
            }
        }
    `]
})
export class MultipleSelectDropdownEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public values: any = [];
    public value: any;
    public model: any;
    public disable: any;
    public change: Function = null;
    @ViewChild('container', { read: ViewContainerRef }) public container;
    public happy: boolean = false;

    constructor(
        private measurementsStoreService: MeasurementsStoreService
    ) { }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {

    }

    agInit(params: any,): void {
        this.params = params;
        if (this.params.colDef.colId === "measurements") {
            const tool = this.params.data.tool && isObject(this.params.data.tool) ? this.params.data.tool._id : this.params.data.tool;
            if (tool) {
                this.measurementsStoreService.optionsByTool(tool).subscribe((res) => {
                    this.values = res.map((item) => {
                        return {
                            id: item._id,
                            text: item.name,
                        };
                    });
                });
            }
        } else {
            if (params.values && params.values.length > 0 && typeof params.values[0].company != 'undefined' && params.data.company) {
                this.values = params.values.filter(val => val.company && val.company._id == params.data.company._id);
            } else {
                this.values = params.values;
            }
        }
        this.value = typeof params.data[params.colDef.field] != 'undefined' ? params.data[params.colDef.field] : '';
        this.change = params.onChange || null;
    }

    getValue(): any {
        return this.value;
    }

    isPopup(): boolean {
        return true;
    }
}