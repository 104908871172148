import { timeCalculation } from "../../../shared/comparators/date-comparator";
import { IField } from "../../interfaces/field";
import { taskKind } from "../taskKind";
import { taskType } from "../taskType";

const FieldDefinition: IField[] = [
    {
        name: 'id_num',
        label: 'table.task-transaction.table-title.ID',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'company.name',
        label: 'table.task-transaction.table-title.COMPANY',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'isCompleted',
        label: 'table.task-transaction.table-title.DONE',
        type: "checkbox",
        required: false,
        readonly: true
    },
    {
        name: 'comment',
        label: 'table.task-transaction.table-title.COMMENT',
        type: 'textbox',
        required: false,
        readonly: true
    },
    {
        name: 'attachments',
        label: 'table.task-transaction.table-title.ATTACHMENTS',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'task.id_num',
        label: 'table.task-transaction.table-title.TASK-ID',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'task.name',
        label: 'table.task-transaction.table-title.TASK-NAME',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'transactionValue',
        label: "table.checklist.table-title.VALUE",
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'task.description',
        label: 'table.checklist.table-title.DESCRIPTION',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'task.link',
        label: 'table.checklist.table-title.LINK',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'task.procedure',
        label: "table.checklist.table-title.PROCEDURE-ID",
        type: 'textbox',
        required: false,
        readonly: true
    },
    {
        name: 'task.procedureName',
        label: "table.checklist.table-title.PROCEDURE-NAME",
        type: 'textbox',
        required: false,
        readonly: true
    },
    {
        name: 'task.tool.id_num',
        label: "table.checklist.table-title.RELATED-TOOL-ID",
        type: 'textbox',
        required: false,
        readonly: true
    },
    {
        name: 'task.tool.name',
        label: "table.checklist.table-title.RELATED-TOOL-NAME",
        type: 'textbox',
        required: false,
        readonly: true
    },
    {
        name: 'task.group.id_num',
        label: "table.checklist.table-title.RELATED-GROUP-ID",
        type: 'textbox',
        required: false,
        readonly: true
    },
    {
        name: 'task.group.name',
        label: "table.checklist.table-title.RELATED-GROUP-NAME",
        type: 'textbox',
        required: false,
        readonly: true
    },
    {
        name: 'task.taskKind',
        label: "table.checklist.table-title.TASK-KIND",
        type: 'dropdown',
        options: taskKind,
        required: false,
        readonly: true
    },
    {
        name: 'task.taskType',
        label: "table.checklist.table-title.TASK-TYPE",
        type: 'dropdown',
        options: taskType,
        required: false,
        readonly: true,
    },
    {
        name: 'task.must',
        label: "table.checklist.table-title.MUST",
        type: "checkbox",
        required: false,
        readonly: true
    },
    {
        name: 'event.id_num',
        label: 'table.task-transaction.table-title.EVENT-ID',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'tool.id_num',
        label: 'table.task-transaction.table-title.TOOL-ID',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'tool.name',
        label: 'table.task-transaction.table-title.TOOL-NAME',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'worker.id_num',
        label: 'table.task-transaction.table-title.WORKER-ID',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'worker.displayName',
        label: 'table.task-transaction.table-title.WORKER-NAME',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'user.id_num',
        label: 'table.task-transaction.table-title.USER-ID',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'user.displayName',
        label: 'table.task-transaction.table-title.USER-NAME',
        type: 'textbox',
        required: true,
        readonly: true,
        minWidth: 200
    },
    {
        name: 'updatedAt',
        label: 'table.task-transaction.table-title.UPDATED-AT',
        type: 'datepicker',
        required: true,
        readonly: true,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.updatedAt ? nodeA.data.updatedAt : null, nodeB && nodeB.data.updatedAt ? nodeB.data.updatedAt : null, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'isDeleted',
        label: 'general.DELETED',
        type: "checkbox",
        readonly: true,
        isAdmin: true,
    }
]

export default {
    FieldDefinition
}