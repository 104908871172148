import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { AuthService } from '../../routes/user/auth.service';
import { CustomFieldsStoreService } from './custom-fields-store.service';
import FieldDefinition from '../constants/field-definitions/task-transaction';
import { map, shareReplay } from 'rxjs/operators';
import { TaskTransactionBackendService } from './backend/task-transaction.backend.service';
import { TaskTransaction } from '../interfaces/task-transaction';
import { CommonService } from './common.service';
import { Router } from '@angular/router';
import { timeCalculation } from '../../shared/comparators/date-comparator';
import { ConfirmType } from '../constants/confirm-result.enum';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmService } from '../utils/confirm/confirm.service';
import { TranslationsStoreService } from './translations-store.service';
import { ToolsStoreService } from './tools-store.service';
import { isObject } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TaskTransactionStoreService {

  protected taskTransactionsSubject = new BehaviorSubject(List([]));
  public readonly taskTransactions$: Observable<List<any>> = this.taskTransactionsSubject.asObservable();
  public columns$: Observable<any[]>;
  constructor(
    protected backend: TaskTransactionBackendService,
    protected customFields: CustomFieldsStoreService,
    protected auth: AuthService,
    protected commonService: CommonService,
    private translationsStoreService: TranslationsStoreService,
    private router: Router,
    private translate: TranslateService,
    private confirm: ConfirmService,
    private toolsStoreService: ToolsStoreService,
  ) {
    this.onInit()
  }

  onInit() {
    this.columns$ = combineLatest([this.auth.user$, this.customFields.get('task-transactions'), this.translationsStoreService.languageCode$]).pipe(
      map(([user, columns, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(col => (col.name != "company.name" || user.isRoot()) && (col.name != "isDeleted" || user.isAdmin()));
        const fields = columns.toArray();
        const col = this.customFields.toColumnDef(defaults, {
          rowSelector: "id_num",
          format: this.formatCell.bind(this)
        });
        const custom = this.customFields.toColumnDef(fields, {
          format: this.customFields.formatCellCustomField.bind(this)
        });

        return col.concat(custom);
      })
    );
  }

  load() {
    return this.backend.list().pipe(
      map((result: Array<TaskTransaction>) => {
        this.taskTransactionsSubject.next(List(result));
        return result
      })
    )
  }

  getListByTool(tool:string) {
    return this.backend.listByTool(tool).pipe(
      map(taskTransactions => List(taskTransactions))
    );
  }

  toolTransactionCount(toolId: string) {
    return this.backend.toolTransactionCount(toolId);
  }

  getToolTransaction(toolId: string, page: number, limit: number) {
    return this.backend.toolTransaction(toolId, page, limit).pipe(
      shareReplay(1)
    );
  }

  listByEvent(eventId: string) {
    return this.backend.listByEvent(eventId);
  }

  update(data: TaskTransaction) {
    return this.backend.update(data);
  }

  getList() {
    return this.taskTransactionsSubject.getValue();
  }

  getEvent(params) {
    if(params.rowData.tool && params.rowData.tool?.isDeleted){
      this.confirm.show(this.translate.instant('confirm.TOOL.TOOL_DELETED'), { type: ConfirmType.CONFIRM_ONLY, confirmText: this.translate.instant('shared.OK'), defaultBtnClass: 'btn-danger' }).subscribe(() => {})
    }else{
      localStorage.setItem('_event_id', params.rowData.event.id_num);
      this.router.navigate(['/main', 'maintenance', 'tool', params.rowData && params.rowData.tool ? params.rowData.tool.id_num : ""]);
    }
  }

  toolLink(params) {
    if(params.rowData.tool){
      const tool = this.toolsStoreService.getToolById(isObject(params.rowData.tool) ? params.rowData.tool._id : params.rowData.tool);
      if(tool){
        if (tool.isDeleted) {
          this.confirm.show(this.translate.instant('confirm.TOOL.TOOL_DELETED'), { type: ConfirmType.CONFIRM_ONLY, confirmText: this.translate.instant('shared.OK'), defaultBtnClass: 'btn-danger' }).subscribe(() => { })
        } else {
          const url = this.router.serializeUrl(
            this.router.createUrlTree([`/main/maintenance/tool/${tool.id_num}`])
          );
          window.open(url, '_blank');
        }
      }
    }
  }

  taskToolLink(params) {
    if(params.rowData.task.tool){
      const tool = this.toolsStoreService.getToolById(isObject(params.rowData.task.tool) ? params.rowData.task.tool._id : params.rowData.task.tool);
      if(tool){
        if (tool.isDeleted) {
          this.confirm.show(this.translate.instant('confirm.TOOL.TOOL_DELETED'), { type: ConfirmType.CONFIRM_ONLY, confirmText: this.translate.instant('shared.OK'), defaultBtnClass: 'btn-danger' }).subscribe(() => { })
        } else {
          const url = this.router.serializeUrl(
            this.router.createUrlTree([`/main/maintenance/tool/${tool.id_num}`])
          );
          window.open(url, '_blank');
        }
      }
    }
  }

  taskGroupLink(params) {
    if(params.rowData.task.group){
      const tool = this.toolsStoreService.getToolById(isObject(params.rowData.task.group) ? params.rowData.task.group._id : params.rowData.task.group);
      if(tool){
        if (tool.isDeleted) {
          this.confirm.show(this.translate.instant('confirm.TOOL.TOOL_DELETED'), { type: ConfirmType.CONFIRM_ONLY, confirmText: this.translate.instant('shared.OK'), defaultBtnClass: 'btn-danger' }).subscribe(() => { })
        } else {
          const url = this.router.serializeUrl(
            this.router.createUrlTree([`/main/maintenance/${tool.id_num}`])
          );
          window.open(url, '_blank');
        }
      }
    }
  }

  getProcedure = (params, field) => {
    return !params.data.task || !params.data.task.procedure ? "" : params.data.task.procedure.map(p => p[field]).join(", ");
  }

  private formatCell = (col, field) => {
    switch (field.name) {
      case "tool.name":
        return {
          ...col,
          cellRenderer: 'customClick',
          cellRendererParams: {
            onClick: this.toolLink.bind(this),
            field: field.name
          }
        }
      case "task.tool.name":
        return {
          ...col,
          cellRenderer: 'customClick',
          cellRendererParams: {
            onClick: this.taskToolLink.bind(this),
            field: field.name
          }
        }
      case "task.group.name":
        return {
          ...col,
          cellRenderer: 'customClick',
          cellRendererParams: {
            onClick: this.taskGroupLink.bind(this),
            field: field.name
          }
        }
      case "attachments":
        return {
          ...col,
          cellRenderer: 'fileRedirection',
          cellRendererParams: {
            isShowMultiple: true,
          }
        }
      case "event.id_num":
        return {
          ...col,
          cellRenderer: 'customClick',
          cellRendererParams: {
            onClick: this.getEvent.bind(this),
            field: field.name
          }
        }
      case "task.procedure":
        return {
          ...col,
          valueGetter: params => this.getProcedure(params, "id_num"),
          valueFormatter: params => this.getProcedure(params, "id_num"),
          tooltipValueGetter: params => this.getProcedure(params, "id_num"),
        }
      case "task.procedureName":
        return {
          ...col,
          valueGetter: params => this.getProcedure(params, "name"),
          valueFormatter: params => this.getProcedure(params, "name"),
          tooltipValueGetter: params => this.getProcedure(params, "name"),
        }
      case "updatedAt":
        return {
          ...col,
          valueFormatter: (params) => params.data[field.name] && this.commonService.getDateTime(params.data[field.name]),
          valueGetter: (params) => params.data[field.name] && this.commonService.getDateTime(params.data[field.name]),
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.updatedAt ? nodeA.data.updatedAt : null, nodeB && nodeB.data.updatedAt ? nodeB.data.updatedAt : null, nodeA, nodeB, isInverted);
          }
        }
      default:
        return col;
    }
  }
}
