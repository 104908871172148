<div class="btn-group" dropdown (click)="onClick($event)">
  <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-secondary dropdown-toggle"
          aria-controls="dropdown-basic">
    {{label || ("general.ADD-TO-CALENDAR" | translate)}}<span class="caret"></span>
  </button>
  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
      role="menu" aria-labelledby="button-basic">
    <li role="menuitem"><a class="dropdown-item w-100 d-block" [href]="googleCalendarEventUrl" target="_blank">{{ "general.GOOGLE" | translate }}</a></li>
    <li role="menuitem"><a class="dropdown-item w-100 d-block" [href]="iCalendarEventUrl" target="_blank">{{ "general.ICALENDAR" | translate }}</a></li>
    <li role="menuitem"><a class="dropdown-item w-100 d-block" [href]="outlookCalendarEventUrl" target="_blank">{{ "general.OUTLOOK" | translate }}</a></li>

  </ul>
</div>