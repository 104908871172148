import { Directive, OnInit, ElementRef, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { AuthService } from '../../routes/user/auth.service';
import { User } from '../../routes/user/login.response';

@Directive({
  selector: '[showUnauthorized]'
})
export class ShowUnauthorizedDirective implements OnInit {
  private user:User;
  private permissions:[] = [];

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private auth: AuthService,
  ) {

  }

  ngOnInit(){
    this.auth.user$.subscribe(user =>{
      this.user = user;
      this.updateView();
    })
  }

  @Input()
  set hideUnauthorized(val) {
    this.permissions = val;
    this.updateView();
  }

  private updateView = () =>{

    const match = this.permissions.some(
      permission=>this.user && this.user.roles.some(role => permission===role)
    );

    if(match)
      this.viewContainer.createEmbeddedView(this.templateRef);
    else
      this.viewContainer.clear();
  }

}
