import { isObject } from "lodash";
import { timeCalculation } from "../../../shared/comparators/date-comparator";
import { IField } from "../../interfaces/field";
import { eventType } from "../event-type";
import { Warehouse } from "../../interfaces/warehouse";
const FieldDefinition: IField[] = [
    {
        name: 'id_num',
        label: 'table.events.table-title.EVENT-ID',
        type: 'textbox',
        required: false,
        readonly: true,
        isAdminEditable: false,
        section: 'events',
    },
    {
        name: 'company.name',
        label: 'table.events.table-title.COMPANY',
        type: 'textbox',
        required: false,
        readonly: true,
        isAdminEditable: false,
        section: 'events',
    },
    {
        name: 'tool.name',
        label: 'table.events.table-title.TOOL-NAME',
        type: 'textbox',
        required: false,
        readonly: true,
        isAdminEditable: false,
        section: 'events',
    },
    {
        name: 'tool.id_num',
        label: 'table.pm.table-title.TOOL-ID',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'events',
    },
    {
        name: 'typeId',
        label: 'table.events.table-title.TYPE-ID',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'events',
        hide: true,
    },
    {
        name: 'type',
        label: 'table.events.table-title.TYPE',
        type: 'dynamicdropdown',
        options: [],
        required: true,
        readonly: true,
        isAdminEditable: true,
        section: 'events',
    },
    {
        name: 'maintenanceTypeObject.pmKind',
        label: 'table.events.table-title.PM-KIND',
        type: 'textbox',
        required: true,
        readonly: true,
        isAdminEditable: false,
        section: 'events',
    },
    {
        name: 'maintenanceObject.id_num',
        label: 'PM ID',
        type: 'textbox',
        required: true,
        readonly: true,
        section: 'events',
        hide: true,
    },
    {
        name: 'worker.id_num',
        label: 'table.events.table-title.WORKER-ID',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'events',
        hide: true,
        valueGetter: params => {
            return params.data.worker ? params.data.worker.id_num : '';
        },
    },
    {
        name: 'worker',
        label: 'table.events.table-title.WORKER',
        type: 'selectdropdown',
        required: false,
        readonly: true,
        isAdminEditable: true,
        section: 'events',
    },
    {
        name: 'start',
        label: 'table.events.table-title.START',
        type: 'datepicker',
        required: false,
        readonly: true,
        isAdminEditable: true,
        section: 'events',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.start ? nodeA.data.start : null, nodeB && nodeB.data.start ? nodeB.data.start : null, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'finish',
        label: 'table.events.table-title.FINISH',
        type: 'datepicker',
        required: false,
        readonly: true,
        isAdminEditable: true,
        section: 'events',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.finish ? nodeA.data.finish : null, nodeB && nodeB.data.finish ? nodeB.data.finish : null, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'scheduleWork',
        label: 'maintenance.form-labels.SCHEDULE-WORK',
        type: 'datepicker',
        required: false,
        readonly: true,
        isAdminEditable: true,
        section: 'events',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.scheduleWork ? nodeA.data.scheduleWork : null, nodeB && nodeB.data.scheduleWork ? nodeB.data.scheduleWork : null, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'downTimeHours',
        label: 'table.events.table-title.CALCULATED-DOWNTIME-HOURS',
        type: 'textbox',
        required: false,
        readonly: true,
        isAdminEditable: false,
        section: 'events'
    },
    {
        name: 'downtime',
        label: 'table.events.table-title.DOWNTIME-HOURS',
        type: 'numeric',
        required: false,
        readonly: false,
        isAdminEditable: true,
        section: 'events'
    },
    {
        name: 'problemDesc',
        label: 'table.events.table-title.PROBLEM',
        type: 'textbox',
        required: false,
        readonly: true,
        isAdminEditable: true,
        section: 'events',
    },
    {
        name: 'solutionDesc',
        label: 'table.events.table-title.SOLUTION',
        type: 'textbox',
        required: false,
        readonly: true,
        isAdminEditable: true,
        section: 'events',
    },
    {
        name: 'parts',
        label: 'table.events.table-title.PARTS-REPLACED',
        type: 'textbox',
        required: false,
        readonly: true,
        valueGetter: params => {
            return params.data && params.data.parts && params.data.parts.length ? params.data.parts.filter(part => part.part && part.part.name).map(part => {
                const warehouse = part?.part?.warehouse && isObject(part?.part?.warehouse) ? part?.part?.warehouse : part?.part?.warehouseObject;
                return `${part?.part?.catalogNumber || ""} | ${part?.part?.name || ""}${warehouse && isObject(warehouse) && (warehouse as Warehouse)?.name ? ` | ${(warehouse as Warehouse)?.name}` : ''} (${part.quantity})`
            }).join("; ") : ""
        },
        tooltipValueGetter: params => {
            return params.data && params.data.parts && params.data.parts.length ? params.data.parts.filter(part => part.part && part.part.name).map(part => {
                const warehouse = part?.part?.warehouse && isObject(part?.part?.warehouse) ? part?.part?.warehouse : part?.part?.warehouseObject;
                return `${part?.part?.catalogNumber || ""} | ${part?.part?.name || ""}${warehouse && isObject(warehouse) && (warehouse as Warehouse)?.name ? ` | ${(warehouse as Warehouse)?.name}` : ''} (${part.quantity})`
            }).join("; ") : ""
        },
        isAdminEditable: false,
        section: 'events',
    },
    {
        name: 'eventType',
        label: 'table.events.table-title.PM/FM',
        type: 'pm-dropdown',
        options: eventType,
        required: false,
        readonly: true,
        isAdminEditable: true,
        section: 'events'
    },
    {
        name: 'responsiblePerson.id_num',
        label: 'table.pm.table-title.RESPONSIBLE-PERSON-ID',
        type: 'textbox',
        required: false,
        readonly: true,
        hide: true,
        section: 'events',
        valueGetter: params => {
            return params.data.responsiblePersonObject ? params.data.responsiblePersonObject.id_num : '';
        },
    },
    {
        name: 'responsiblePerson',
        label: 'table.pm.table-title.RESPONSIBLE-PERSON',
        type: 'selectdropdown',
        required: false,
        readonly: true,
        isAdminEditable: true,
        section: 'events',
    },
    {
        name: 'department.id_num',
        label: 'table.pm.table-title.DEPARTMENT-ID',
        type: 'textbox',
        required: true,
        readonly: true,
        hide: true,
        valueGetter: params => {
            return params.data.departmentObject ? params.data.departmentObject.id_num : '';
        },
        isAdminEditable: false,
    },
    {
        name: 'department',
        label: 'table.users.table-title.DEPARTMENT',
        type: 'selectdropdown',
        options: [],
        required: false,
        readonly: false,
        isAdminEditable: true,
    },
    {
        name: 'file.id_num',
        label: 'table.pm.table-title.FILE-ID',
        type: 'textbox',
        required: true,
        readonly: true,
        hide: true,
        valueGetter: params => {
            return params.data.file && params.data.file.length > 0 ? params.data.file[0]['id_num'] : '';
        },
        section: 'events',
        isAdminEditable: false,
    },
    {
        name: 'file',
        label: 'table.pm.table-title.FILE-NAME',
        type: 'textbox',
        required: true,
        readonly: true,
        valueGetter: params => {
            return params.data.file && params.data.file.length > 0 ? params.data.file[0]['name'] : '';
        },
        section: 'events',
        isAdminEditable: false,
    },
    {
        name: 'isDeleted',
        label: 'general.DELETED',
        type: "checkbox",
        readonly: false,
        isAdmin: true,
        section: 'events',
        isAdminEditable: true,
    },
    {
        name: 'isWaReporter',
        label: 'table.pm.table-title.WA-REPORTER',
        type: "checkbox",
        readonly: true,
        isAdmin: true,
        section: 'events',
        isAdminEditable: true,
    },
    {
        name: 'updatedBy.displayName',
        label: 'table.pm.table-title.WHO-DID-CHANGE',
        type: "textbox",
        readonly: true,
        section: 'events',
        valueGetter: params => {
            return params.data.updatedBy ? params.data.updatedBy.displayName : (params.data.createdBy ? params.data.createdBy.displayName : '');
        },
    },
    {
        name: 'signaturePersonObject.displayName',
        label: 'table.pm.table-title.SIGNATURE-PERSON',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'events',
    },
    {
        name: 'hideDataInGraphs',
        label: 'table.tools.table-title.HIDE-DATA-IN-GRAPHS',
        type: 'checkbox',
        required: false,
        readonly: false,
        section: 'events',
        isAdminEditable: true,
    },
    {
        name: 'taskProcedure.id_num',
        label: 'table.pm.table-title.PROCEDURE',
        type: "textbox",
        readonly: true,
        section: 'events',
        isAdminEditable: false,
        valueGetter: params => {
            return params.data.taskProcedure ? `${params.data.taskProcedure?.id_num} (${params.data.taskProcedure?.name})` : '';
        },
    },
    {
        name: 'status',
        label: 'table.pm.table-title.STATUS',
        type: 'selectdropdown',
        options: [],
        readonly: true,
        section: 'events',
        isAdminEditable: false
    },
    {
        name: 'qaApprovalObject.displayName',
        label: 'table.events.table-title.QA-APPROVED-PERSON',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'events',
    },
    {
        name: 'qaApprovalTime',
        label: 'table.events.table-title.QA-APPROVED-TIME',
        type: 'datepicker',
        required: false,
        readonly: true,
        section: 'events',
    },
    {
        name: 'parentEvent.id_num',
        label: 'table.events.table-title.FATHER-EVENT-ID',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'events',
    },
    {
        name: 'severity',
        label: 'table.events.table-title.SEVERITY',
        type: 'textbox',
        required: false,
        readonly: true,
        hide: true,
        section: 'events',
    },
];

export default
    {
        FieldDefinition
    }