import { Injectable } from '@angular/core';
import {ConfirmComponent} from './confirm.component';
import {Observable} from 'rxjs';
import { ConfirmResult, ConfirmType } from '../../constants/confirm-result.enum';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  constructor(private modal: BsModalService) { }

  public show(question: String | String[], options: any = {}):Observable<ConfirmResult> {
    return new Observable<ConfirmResult>(observer => {
      this.modal.show(ConfirmComponent, {
        keyboard: false,
        ignoreBackdropClick: true,
        class: 'modal-sm delete-modal',
        initialState: {question: question, observer: observer, options: options}});
    });
  }
}
