import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router } from '@angular/router';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'app-link-renderer',
  template: `<div *ngIf="isExternal">
    <a href (click)="navigate($event)">{{params.value}}</a>
  </div>
  <div *ngIf="!isExternal">
    <a [routerLink]="link">{{params.value}}</a>
  </div>`,
  styles: []
})
export class LinkRendererComponent {

  params: any;
  link: any;
  isExternal:boolean  = false;
  @ViewChild('container', {read: ViewContainerRef}) public container;

  constructor(private router: Router) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.link = params['linkCreator'](params);
    this.isExternal = !!params['isExternal']
  }


  navigate(event){
    event.preventDefault();
    event.stopPropagation();

    if (this.isExternal){
      window.open(this.link, "_blank");
    }else{
      this.router.navigate(this.link);

    }
  }

  refresh(params: any): boolean {
    return false;
  }
}
