import { MaintenanceType } from './maintenance-type';
import { User } from '../../routes/user/login.response';
import { Tool } from './tool';
import { subDays, isBefore, differenceInDays, isEqual, differenceInMinutes, addDays } from 'date-fns';
import { Department } from './department';
import moment from 'moment-timezone';
import { Company } from './company';
import { ChecklistItem } from './checklist-item';
import { Procedure } from './procedure';
import differenceInHours from 'date-fns/differenceInHours';
import { Measurement } from './measurement';
import { Part } from './part';

export interface GroupUpdateMaintenance {
  maintenances: string[];
  start: Date;
  finish: Date;
  hideDataInGraphs: boolean;
  downtime: number;
}

export class Maintenance {
  _id?: string;
  id_num?: Number;
  name?: string;
  company?: string;
  tool?: Tool;
  type?: MaintenanceType;
  period?: number;
  grayLine?: number;
  redLine?: number;
  notifyBefore?: number;
  worker?: any;
  workerObject?: User | Department;
  workerDepartment?: Department;
  file?: [];
  description?: string;
  isActive?: boolean;
  lastMaintenance?: any;
  nextMaintenance?: any;
  isDeleted?: boolean;
  isCompleted?: boolean;
  dueDate?: Date;
  isFreeze: boolean;
  isAutomaticNextPM: boolean;
  automaticNextPMDay: number;
  nextPM?: Date;
  lastPM?: Date;
  customFields?: Object;
  department?: Department | string;
  departmentObject?: Department;
  maintenanceTime?: string;
  isRedLine?: boolean;
  isGrayLine?: boolean;
  isPMCalender?: boolean;
  checklistItems?: ChecklistItem[];
  procedures?: Procedure[];
  isEdit?: boolean;
  isLogNeeded?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  taskProcedure?: string | Procedure;
  taskProcedureObject?: Procedure;
  closeAfter?: number;
  measurements?: string[];
  measurementsObject?: Measurement[];
  inventoryParts?: string[];
  inventoryPartsObject?: Part[];
  breadcrumbs?: string;
  groupName?: string;

  constructor(options: {
    _id?: string,
    id_num?: Number,
    name?: string,
    company?: string,
    tool?: Tool,
    type?: MaintenanceType,
    period?: number,
    grayLine?: number,
    redLine?: number,
    notifyBefore?: number,
    worker?: any,
    workerObject?: User | Department,
    workerDepartment?: Department,
    file?: [],
    description?: string;
    isActive?: boolean,
    lastMaintenance?: any,
    nextMaintenance?: any,
    isDeleted?: boolean,
    isCompleted?: boolean,
    dueDate?: Date,
    nextPM?: Date,
    lastPM?: Date,
    isFreeze?: boolean,
    isAutomaticNextPM?: boolean,
    automaticNextPMDay?: number,
    customFields?: Object;
    department?: Department | string,
    departmentObject?: Department,
    maintenanceTime?: string,
    isRedLine?: boolean,
    isGrayLine?: boolean,
    isPMCalender?: boolean,
    checklistItems?: ChecklistItem[],
    procedures?: Procedure[],
    isEdit?: boolean,
    isLogNeeded?: boolean,
    createdAt?: Date,
    updatedAt?: Date,
    taskProcedure?: string | Procedure,
    taskProcedureObject?: Procedure,
    closeAfter?: number,
    measurements?: string[],
    measurementsObject?: Measurement[],
    inventoryParts?: string[],
    inventoryPartsObject?: Part[],
    breadcrumbs?: string,
    groupName?: string,
  }) {
    this._id = options._id || undefined;
    this.id_num = options.id_num || 0;
    this.name = options.name || "";
    this.company = options.company || null;
    this.tool = options.tool || null;
    this.type = options.type || null;
    this.period = options.period || 0;
    this.grayLine = options.grayLine || 0;
    this.redLine = options.redLine || 0;
    this.notifyBefore = options.notifyBefore || null;
    this.worker = options.worker || null;
    this.workerObject = options.workerObject || null;
    this.workerDepartment = options.workerDepartment || null;
    this.file = options.file || null;
    this.description = options.description || null;
    this.isActive = options.isActive || null;
    this.lastMaintenance = options.lastMaintenance || null;
    this.nextMaintenance = options.nextMaintenance || null;
    this.isDeleted = options.isDeleted || false;
    this.isCompleted = options.isCompleted || null;
    this.dueDate = options.dueDate || null;
    this.nextPM = options.nextPM || null;
    this.lastPM = options.lastPM || null;
    this.isFreeze = options.isFreeze || false;
    this.isAutomaticNextPM = options.isAutomaticNextPM || false;
    this.automaticNextPMDay = options.automaticNextPMDay || 1;
    this.customFields = options.customFields;
    this.department = options.department || null;
    this.departmentObject = options.departmentObject || null;
    this.maintenanceTime = options.maintenanceTime || "00:00";
    this.isRedLine = options.isRedLine || false;
    this.isGrayLine = options.isGrayLine || false;
    this.isPMCalender = options.isPMCalender || false;
    this.checklistItems = options.checklistItems || [];
    this.procedures = options.procedures || [];
    this.isEdit = options.isEdit || false;
    this.isLogNeeded = options.isLogNeeded || false;
    this.createdAt = options.createdAt || null;
    this.updatedAt = options.updatedAt || null;
    this.taskProcedure = options.taskProcedure || null;
    this.taskProcedureObject = options.taskProcedureObject || null;
    this.closeAfter = options.closeAfter || null;
    this.measurements = options.measurements || [];
    this.measurementsObject = options.measurementsObject || [];
    this.inventoryParts = options.inventoryParts || null;
    this.inventoryPartsObject = options.inventoryPartsObject || null;
    this.breadcrumbs = options.breadcrumbs || null;
    this.groupName = options.groupName || null;
  }

  getPeriod = () => {
    return this.type ? this.type.period : (this.period || 0);
  }

  showNotification = (company:Company) => {
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    const nextMaintenance = new Date(this.nextMaintenance)
    nextMaintenance.setHours(0, 0, 0, 0)

    let check:boolean = false;
    if(this.getPeriod() == 1){
      const timezone = company && company.timezone ? company.timezone : moment.tz.guess();
      if(company && !company.daysOff.includes(moment().tz(timezone).format('dddd'))){
        if(!this.nextMaintenance){
          check = true;
        }else{
          const date = moment(moment(this.nextMaintenance).format('YYYY-MM-DD')+' '+ this.maintenanceTime).subtract((this.grayLine || (this.type ? this.type.grayLine : 0)), 'hours').format('YYYY-MM-DD HH:mm:ss')
          check = new Date(moment().format('YYYY-MM-DD HH:mm:ss')) >= new Date(date);
        }
      }
    }else{
      check = (
        !this.nextMaintenance ||
        (
          this.notifyBefore && (
            isBefore(subDays(nextMaintenance, this.notifyBefore), date) ||
            isEqual(subDays(nextMaintenance, this.notifyBefore), date)
          )
        ) ||
        [1, 3].includes(Maintenance.getMaintenanceSeverity(this))
      );
    }

    return this.isFreeze ? false :
      (
        !this.isActive &&
        !this.isDeleted &&
        check
      );
  }

  public static getMaintenanceUsage = (maint: Maintenance): number => {
    if (!maint.nextMaintenance) {
      return 0;
    }
    if(maint.getPeriod() > 1){
      const diff = differenceInDays(maint.nextMaintenance, new Date);
      const passed = maint.getPeriod() - diff;
      return Math.max(0, Math.ceil((passed / maint.getPeriod()) * 100));
    }else{
      const nextMaintenance = new Date(moment(maint.nextMaintenance).format('YYYY-MM-DD')+" "+(maint.maintenanceTime ? maint.maintenanceTime : "00:00"))
      const diff = differenceInHours(nextMaintenance, new Date);
      const passed = 24 - diff;
      return Math.max(0, Math.ceil((passed / 24) * 100));
    }
  }

  public static getMaintenanceSeverity = (maint: Maintenance): number => {
    const usage = Maintenance.getMaintenanceUsage(maint);
    if (usage >= 100) return 3;
    else if (usage >= 90 && usage < 100) return 1;
    else return 0;
  }

  public static getMaintenanceBadgeSeverity = (maint: Maintenance): number => {
    if(!maint.nextMaintenance){
      return 0;
    }

    if(maint.getPeriod() > 1){
      const date = new Date()
      date.setHours(0, 0, 0, 0)
      const nextMaintenance = new Date(maint.nextMaintenance)
      nextMaintenance.setHours(0, 0, 0, 0)
      const notifyBefore = maint.notifyBefore || 0;
      const notifyBeforeDate  = new Date(moment(maint.nextMaintenance).subtract(notifyBefore, "days").format("YYYY-MM-DD HH:mm:ss"))
      notifyBeforeDate.setHours(0, 0, 0, 0)
      let diff = differenceInDays(nextMaintenance, date);
      let notifyDiff = differenceInDays(date, notifyBeforeDate);

      if(diff < 0){
        const redLine = maint.redLine ? maint.redLine : maint.type?.redLine;
        const grayLine = maint.grayLine ? maint.grayLine : maint.type?.grayLine;
        diff = Math.abs(diff);
        if(maint.isRedLine && (diff >= redLine || redLine === 0)){
          return 3;
        }else if(maint.isGrayLine && diff >= grayLine){
          return 1;
        }
      }
      if(notifyDiff >= 0){
        return 2;
      }
      return 0;
    }else{
      const date = new Date()
      const nextMaintenance = new Date(moment(maint.nextMaintenance).format('YYYY-MM-DD')+" "+(maint.maintenanceTime ? maint.maintenanceTime : "00:00"))
      let diff = differenceInMinutes(nextMaintenance, date);
      if(diff < 0){
        diff = Math.abs(diff);
        diff = Number((diff / 60).toFixed(2));
        if(!maint.isGrayLine && !maint.isRedLine){
          return 2;
        }else if((maint.redLine == 0 || !maint.redLine) && maint.isRedLine){
          return 3;
        }else if(diff < maint.grayLine && maint.isGrayLine){
          return 0;
        }else if(diff >= maint.grayLine && diff < maint.redLine && maint.isGrayLine && maint.isRedLine){
          return 2;
        }else{
          if(maint.isRedLine && diff >= maint.redLine){
            return 3;
          }else if(maint.isGrayLine && diff >= maint.grayLine){
            return 2;
          }else{
            return 0;
          }
        }
      }else{
        return 0;
      }
    }
  }

  public static nextPMDate = (maint: Maintenance, company:Company) => {
    var nextPM = maint.nextPM ? maint.nextPM : (maint.lastPM ? addDays(new Date(maint.lastPM), maint.getPeriod()) : null);
    if(nextPM && maint.getPeriod() === 1){
      return Maintenance.nextPMDateFromDate(maint, company, nextPM);
    }
    return nextPM ? new Date(nextPM) : nextPM;
  }

  public static nextPMDateFromDate = (maint: Maintenance, company:Company, date) => {
    var nextPM = date;
    if(nextPM && maint.getPeriod() === 1){
      const timezone = company && company.timezone ? company.timezone : moment.tz.guess();
      if(company && company.daysOff && company.daysOff.length > 0 && company.daysOff.length < 7){
        while(company.daysOff.includes(moment(nextPM).tz(timezone).format('dddd'))){
          nextPM = moment(nextPM).add(maint.getPeriod(), "days").toDate();
        }
      }
    }
    return nextPM ? new Date(nextPM) : nextPM;
  }
}
