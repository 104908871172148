import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DynamicDropdown } from '../../interfaces/dynamic-dropdown';

@Injectable({
  providedIn: 'root'
})
export class DynamicDropdownBackendService {

  url = '/api/dynamic-dropdown';

  constructor(private http: HttpClient) { }

  // list() {
  //   return this.http.get<Array<DynamicDropdown>>(this.url)
  // }

  listByKey(key:string) {
    return this.http.get<Array<any>>(`${this.url}/${key}`);
  }

  create(data: DynamicDropdown) {
    return this.http.post(this.url, data);
  }

  getById(id: string) {
    return this.http.get<DynamicDropdown>(`${this.url}/id/${id}`);
  }

  update(data: DynamicDropdown) {
    return this.http.put(`${this.url}/${data._id}`, data);
  }

  delete(data: DynamicDropdown) {
    return this.http.delete(`${this.url}/${data._id}`);
  }
}
