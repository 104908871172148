import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup-complete',
  templateUrl: './signup-complete.component.html',
  styleUrls: ['./signup-complete.component.scss']
})
export class SignupCompleteComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    localStorage.removeItem('_token');
    localStorage.removeItem('_company');
    localStorage.removeItem('_sign_out_counter');
    localStorage.removeItem('_active_events_filters');
    localStorage.removeItem('_dashboard_filters');
  }

}
