<div class="wrapper">
  <div class="block-center">
    <!-- START card-->
    <div class="logo text-center">
      <img
        src="assets/img/Logo land - Regular White@3x.png"
        alt="logo"
        class="brand-logo"
      />
      <!--./assets/img/logo2.png.jpg-->
    </div>
    <div class="card card-flat">
      <!--<div class="card-header text-center">-->
      <!--<h2>Zorgtech</h2>-->
      <!--</div>-->
      <div class="">
        <div class="log-in-header">
          <p class="log-in-paragraph text-center">Log in</p>
        </div>
        <div class="line"></div>
        <re-captcha
          #captchaRef="reCaptcha"
          (resolved)="resolved($event)"
          (error)="onError($event)"
          errorMode="handled"
          size="invisible"
        ></re-captcha>
        <form
          [formGroup]="valForm"
          class="login-form form-validate"
          role="form"
          name="loginForm"
          novalidate=""
          (submit)="submitForm($event, valForm.value)"
        >
          <div class="form-group">
            <p>Company name</p>
            <div class="input-group with-focus">
              <input
                class="form-control"
                id="inputCompany"
                type="text"
                name="company"
                placeholder="Company name"
                autocomplete="company"
                formControlName="company"
                required=""
                autofocus
                (keypress)="commonService.typeA($event)"
              />
              <!-- (keypress)="omit_special_char($event)"
                (paste)="on_Paste($event)" -->
              <!--<div class="input-group-append">-->
              <!--<span class="input-group-text fa fa-tag text-muted bg-transparent border-left-0"></span>-->
              <!--</div>-->
            </div>
            <div
              class="text-danger"
              *ngIf="
                valForm.get('company').hasError('required') &&
                (valForm.get('company').dirty || valForm.get('company').touched)
              "
            >
              This field is required
            </div>
          </div>
          <div class="form-group">
            <p>
              Username<img
                src="assets/img/info_icon_transparent.png"
                [tooltip]="
                  'toasters.USER.PLEASE-ENTER-VALID-USERNAME' | translate
                "
                class="ml-1 mr-1"
                style="width: 20px"
                alt=""
              />
            </p>
            <div class="input-group with-focus">
              <input
                class="form-control"
                id="username-input"
                type="text"
                name="username"
                placeholder="Enter username"
                autocomplete="username"
                maxlength="15"
                formControlName="username"
                required=""
                (keypress)="commonService.typeB($event)"
              />
              <!--<div class="input-group-append">-->
              <!--<span class="input-group-text fa fa-envelope text-muted bg-transparent border-left-0"></span>-->
              <!--</div>-->
            </div>
            <div
              class="text-danger"
              *ngIf="
                valForm.get('username').hasError('required') &&
                (valForm.get('username').dirty ||
                  valForm.get('username').touched)
              "
            >
              This field is required
            </div>
            <div
              class="text-danger"
              *ngIf="
                valForm.get('username').hasError('pattern') &&
                (valForm.get('username').dirty ||
                  valForm.get('username').touched)
              "
            >
              {{ "toasters.USER.PLEASE-ENTER-VALID-USERNAME" | translate }}
            </div>
            <div class="text-right">
              <a href="#" (click)="handleOtpLogin($event)"> Login with OTP </a>
            </div>
          </div>
          <div class="form-group">
            <p>Password</p>
            <div class="input-group with-focus">
              <input
                class="form-control"
                id="exampleInputPassword1"
                [type]="isPasswordShow ? 'text' : 'password'"
                name="password"
                autocomplete="new-password"
                placeholder="Password"
                formControlName="password"
                required=""
                (keypress)="commonService.typeA($event)"
              />
              <div
                class="input-group-append cursor-pointer"
                (click)="showPassword($event)"
              >
                <span
                  class="input-group-text text-muted bg-transparent border-left-0"
                >
                  <i
                    class="far font-16"
                    [ngClass]="!isPasswordShow ? 'fa-eye' : 'fa-eye-slash'"
                  ></i>
                </span>
              </div>
            </div>
            <div
              class="text-danger"
              *ngIf="
                valForm.get('password').hasError('required') &&
                (valForm.get('password').dirty ||
                  valForm.get('password').touched)
              "
            >
              This field is required
            </div>
          </div>
          <div class="clearfix">
            <div class="checkbox c-checkbox float-left mt0">
              <label>
                <input
                  type="checkbox"
                  name="remember"
                  formControlName="remember"
                />
                <span class="fa fa-check"></span>
                Remember me
              </label>
            </div>
            <div class="float-right">
              <a class="text-muted forgot-password-q" [routerLink]="'/recover'">
                Forgot password?
              </a>
            </div>
          </div>
          <div class="clearfix text-center text-danger" *ngIf="errorMessage">
            {{ errorMessage }}
          </div>

          <div
            class="clearfix text-center"
            *ngIf="showResendVerification && resendVerificationId"
          >
            <a href="#" (click)="sendVerification()">
              click here to resend verification email
            </a>
          </div>
          <button
            class="login-button btn btn-block btn-primary mt-3"
            type="submit"
          >
            Login
          </button>

          <!-- <div class="ad-block" [routerLink]="'/login-ad'">
            <span>
              Login with <img src="assets/img/ad.svg" alt="Active directory" class="active-directory">
            </span>
          </div> -->
          <div class="anymaint-block" [routerLink]="'/login-saml'">
            <span>
              Login with
              <img
                src="assets/img/anymaint-ad-saml.png"
                alt="Logo"
                class="active-anymaint-directory"
              />
            </span>
          </div>
        </form>
        <div class="line"></div>
        <!-- <div class="alert alert-danger text-center" ></div> -->
        <p class="text-center not-our-customer">Not our customer?</p>
        <div
          class="sign-up-button-div register-now-button btn btn-block btn-secondary"
          [routerLink]="'/signup'"
        >
          <a class="">Sign Up</a>
        </div>
      </div>
    </div>

    <!-- END card-->
    <!--<div class="p-3 text-center">-->
    <!--<span>&copy;</span>-->
    <!--<span>{{ settings.getAppSetting('year') }}</span>-->
    <!--<span class="mx-2">-</span>-->
    <!--<span>{{ settings.getAppSetting('name') }}</span>-->
    <!--<br/>-->
    <!--<span>{{ settings.getAppSetting('description') }}</span>-->
    <!--</div>-->
  </div>
</div>

<!-- Cookie banner -->
<app-cookie-banner></app-cookie-banner>
