import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { RecoverComponent } from './recover/recover.component';
import { TermsAndAgreementComponent } from './terms-and-agreement/terms-and-agreement.component';
import { ServiceLevelAgreementComponent } from './service-level-agreement/service-level-agreement.component';
import { VerifyComponent } from './verify/verify.component';
import { SignupCompleteComponent } from './signup-complete/signup-complete.component';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { DpaComponent } from './dpa/dpa.component';
import { SlaComponent } from './sla/sla.component';
import { LoginAdComponent } from './login-ad/login-ad.component';
import { NotFoundComponent } from '../not-found/not-found.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login-ad', component: LoginAdComponent },
  { path: 'login-saml', component: LoginAdComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'verify-otp', component: VerifyOtpComponent },
  { path: 'terms-of-use', component: TermsAndAgreementComponent },
  { path: 'service-level-agreement', component: ServiceLevelAgreementComponent },
  { path: 'recover', component: RecoverComponent },
  { path: 'verify', component: VerifyComponent },
  { path: 'registration-complete', component: SignupCompleteComponent },
  { path: 'dpa', component: DpaComponent },
  { path: 'sla', component: SlaComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
