import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if(this.authService.getToken()){
      this.router.navigate(['/main']);
    } else{
      const id = this.route.snapshot.params.id;
      this.authService.getSamlToken(id).subscribe(
        (res: {token: string, company: string}) => {
          if (res && res.token) {
            this.authService.setToken(res.token);
            this.authService.setCompany(res.company);
            location.reload();
          }
        },
        () => {
          this.router.navigate(['/login-saml']);
        }
      )
    }
  }

}
