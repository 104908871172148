import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { NavsearchComponent } from './header/navsearch/navsearch.component';
import { FooterComponent } from './footer/footer.component';

import { SharedModule } from '../shared/shared.module';
import { AvatarComponent } from './header/avatar/avatar.component';
import { CompanyAvatarComponent } from './company-avatar/company-avatar.component';
import { DirectivesModule } from '../core/directives/directives.module';
import { MenuPopupComponent } from './header/menu-popup/menu-popup.component';
import { MobileHeaderComponent } from './header/mobile-header/mobile-header.component';
import { MobileNavSearchComponent } from './header/mobile-nav-search/mobile-nav-search.component';
import { ScannerComponent } from './header/mobile-header/scanner/scanner.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AddNewMenuComponent } from './sidebar/add-new-menu/add-new-menu.component';
import { AddNewReportComponent } from './sidebar/add-new-report/add-new-report.component';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';

@NgModule({
    imports: [
        SharedModule,
        TranslateModule,
        DirectivesModule,
        ZXingScannerModule,
        NgxDaterangepickerMd.forRoot(),
        MalihuScrollbarModule.forRoot(),
        NgbToastModule,
        NgxBootstrapSliderModule,
    ],
    declarations: [
        LayoutComponent,
        SidebarComponent,
        HeaderComponent,
        NavsearchComponent,
        FooterComponent,
        AvatarComponent,
        CompanyAvatarComponent,
        MenuPopupComponent,
        MobileHeaderComponent,
        MobileNavSearchComponent,
        ScannerComponent,
        AddNewMenuComponent,
        AddNewReportComponent
    ],
    exports: [
        LayoutComponent,
        SidebarComponent,
        HeaderComponent,
        NavsearchComponent,
        FooterComponent,
        AvatarComponent,
    ]
})
export class LayoutModule {
}
