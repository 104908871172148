export const onboardingWizard = {
    // "TIMEZONE": false,
    // "CREDIT-CARD": false,
    "USERS": false,
    "WORKERS": false,
    // "PM-KINDS": false,
    // "MACHINE-STATUS": false,
    "TRANSLATIONS": false,
    // "DEPARTMENTS": false,
    "TOOLS": false,
    "PM": false,
    "INVENTORY": false,
    "HISTORY": false,
    "TASKS": false,
    "CUSTOM-TABLE": false,
    "DRAWING-DEFINITIONS": false,
    "PM_CALENDAR": false,
    "TOOL_TRANSACTION": false,
    "WAREHOUSES": false,
    // "FILES": false,
}