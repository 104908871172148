import { format } from "date-fns";
import moment from "moment";
import { IField } from "../../interfaces/field";

const FieldDefinition: IField[] = [
    {
        name: 'company.name',
        label: 'table.subscription-payments.table-title.COMPANY',
        type: 'textbox',
        required: true,
        readonly: true,
    },
    {
        name: 'paypalSubscriptionId',
        label: 'table.subscription-payments.table-title.ID',
        type: 'textbox',
        required: true,
        readonly: true,
    },
    {
        name: 'regularUsers',
        label: 'table.subscription-payments.table-title.REGULAR-USERS',
        type: 'numeric',
        required: true,
        readonly: true,
    },
    {
        name: 'failureReportUsers',
        label: 'table.subscription-payments.table-title.FAILURE-REPORT',
        type: 'numeric',
        required: true,
        readonly: true,
    },
    {
        name: 'amount',
        label: "table.subscription-payments.table-title.AMOUNT",
        type: "numeric",
        required: true,
        readonly: true,
    },
    {
        name: 'comments',
        label: "table.subscription-payments.table-title.COMMENTS",
        type: "textbox",
        required: true,
        readonly: true,
    },
    {
        name: 'startDate',
        label: 'table.subscription-payments.table-title.INITIAL-DATE',
        type: "textbox",
        required: true,
        readonly: true,
        valueGetter: params => {
            if (!params.data.startDate) return ""
            return moment(params.data.startDate).format('DD.MM.YYYY');
        },
    },
    {
        name: 'nextPaymentDate',
        label: 'table.subscription-payments.table-title.NEXT-BILLING-DATE',
        type: "textbox",
        required: true,
        readonly: true,
        valueGetter: params => {
            if (!params.data.nextPaymentDate) return ""
            return moment(params.data.nextPaymentDate).format('DD.MM.YYYY');
        },
    },
    {
        name: 'endDate',
        label: 'table.subscription-payments.table-title.EXPIRY-DATE',
        type: "textbox",
        required: true,
        readonly: true,
        valueGetter: params => {
            if (!params.data.endDate) return ""
            return moment(params.data.endDate).format('DD.MM.YYYY');
        },
    },
    {
        name: 'status',
        label: 'table.subscription-payments.table-title.STATUS',
        type: "textbox",
        required: true,
        readonly: true,
        valueGetter: params => params.data.status(),
    },
    {
        name: "subscription Status",
        label: "table.subscription-payments.table-title.SUBSCRIPTION-STATUS",
        type: "textbox",
        required: true,
        readonly: true,
        valueGetter: params => params.data.subscriptionStatus()
    }
]

export default
{
    FieldDefinition
}