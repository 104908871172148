export const weekDays = [
    {
        label: 'days.SUNDAY',
        value: 'Sunday'
    },
    {
        label: 'days.MONDAY',
        value: 'Monday'
    },
    {
        label: 'days.TUESDAY',
        value: 'Tuesday'
    },
    {
        label: 'days.WEDNESDAY',
        value: 'Wednesday'
    },
    {
        label: 'days.THURSDAY',
        value: 'Thursday'
    },
    {
        label: 'days.FRIDAY',
        value: 'Friday'
    },
    {
        label: 'days.SATURDAY',
        value: 'Saturday'
    }
];

export const weekDaysByNumber = [
    {
        label: 'days.SUNDAY',
        value: 0
    },
    {
        label: 'days.MONDAY',
        value: 1
    },
    {
        label: 'days.TUESDAY',
        value: 2
    },
    {
        label: 'days.WEDNESDAY',
        value: 3
    },
    {
        label: 'days.THURSDAY',
        value: 4
    },
    {
        label: 'days.FRIDAY',
        value: 5
    },
    {
        label: 'days.SATURDAY',
        value: 6
    }
];