<div class="wrapper">
  <div class="block-center">
    <div class="logo text-center">
      <img
        src="assets/img/Logo land - Regular White@3x.png"
        alt="logo"
        class="brand-logo"
      />
    </div>
    <div class="main-card card card-flat">
      <div class="row">
        <div class="col-8 offset-2">
          <div class="content-container">
            <div class="content card card-flat">
              <ng-container
                *ngIf="!passwordSent && (!contacts || contacts.count() < 0)"
              >
                <div class="card-body">
                  <p class="py-2" style="color: #aaa !important;">
                    Forgot password?
                  </p>
                  <form
                    [formGroup]="valForm"
                    class="form-validate"
                    role="form"
                    name="recoverForm"
                    novalidate=""
                    (submit)="submitForm($event, valForm.value)"
                  >
                    <div class="form-group">
                      <label style="color: #aaa !important;">Company</label>
                      <div class="input-group with-focus">
                        <input
                          class="form-control"
                          type="textbox"
                          name="company"
                          placeholder="Company"
                          autocomplete="off"
                          formControlName="company"
                          style="border-color: #aaa !important;"
                          (keypress)="commonService.typeA($event)"
                        />
                      </div>
                      <div
                        class="text-danger"
                        *ngIf="
                          valForm.controls['company'].hasError('required') &&
                          (valForm.controls['company'].dirty ||
                            valForm.controls['company'].touched)
                        "
                      >
                        This field is required
                      </div>
                    </div>

                    <div class="form-group">
                      <label style="color: #aaa !important;">Email</label>
                      <div class="input-group with-focus">
                        <input
                          class="form-control"
                          type="email"
                          name="email"
                          placeholder="Email"
                          autocomplete="off"
                          formControlName="email"
                          style="border-color: #aaa !important;"
                          (keypress)="commonService.typeA($event)"
                        />
                      </div>
                      <div
                        class="text-danger"
                        *ngIf="
                          valForm.controls['email'].hasError('required') &&
                          (valForm.controls['email'].dirty ||
                            valForm.controls['email'].touched)
                        "
                      >
                        This field is required
                      </div>
                      <div
                        class="text-danger"
                        *ngIf="
                          valForm.controls['email'].hasError('pattern') &&
                          (valForm.controls['email'].dirty ||
                            valForm.controls['email'].touched)
                        "
                      >
                        This field must be a valid email address
                      </div>
                    </div>
                    <div class="text-center">
                      <button
                        class="btn btn-info"
                        style="width: 100px !important;"
                        type="submit"
                      >
                        <b>Send</b>
                      </button>
                    </div>
                  </form>
                </div>
              </ng-container>
              <ng-container *ngIf="passwordSent">
                <div class="card-body">
                  <p class="text-center">
                    Please check your registered password for instruction
                  </p>
                  <div class="text-center">
                    <button class="btn btn-primary" routerLink="/login">
                      Login
                    </button>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="!passwordSent && contacts && contacts.count() > 0"
              >
                <div class="card-body">
                  <p>
                    Please contact your administrator to reset your password
                  </p>

                  <div class="mt-1 mb-3" *ngFor="let contact of contacts">
                    <div>
                      <strong>
                        {{ contact.firstName + ' ' + contact.lastName }}
                      </strong>
                    </div>
                    <div *ngIf="contact.phone">
                      {{ contact.phone }}
                    </div>
                    <div *ngIf="contact.email">
                      {{ contact.email }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-cookie-banner></app-cookie-banner>
