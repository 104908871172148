import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { List } from "immutable";
import { AuthService } from "../../routes/user/auth.service";
import { map } from "rxjs/operators";
import { CustomFieldsStoreService } from "./custom-fields-store.service";
import FieldDefinition from "../constants/field-definitions/department";
import { DepartmentsbackendService } from "./backend/departments.backend.service";
import { Department } from "../interfaces/department";
import { TranslationsStoreService } from "./translations-store.service";

@Injectable({
  providedIn: "root",
})
export class DepartmentsStoreService {
  protected departmentsSubject = new BehaviorSubject(List([]));
  public readonly departments$: Observable<List<any>> =
    this.departmentsSubject.asObservable();
  public columns$: Observable<any[]>;

  constructor(
    protected backend: DepartmentsbackendService,
    protected customFields: CustomFieldsStoreService,
    public translationsStoreService: TranslationsStoreService,
    protected auth: AuthService
  ) {
    this.columns$ = combineLatest([
      this.auth.user$,
      this.customFields.get("departments"),
      this.translationsStoreService.languageCode$,
    ]).pipe(
      map(([user, columns, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(
          (col) =>
            (col.name != "company.name" || user.isRoot()) &&
            (col.name != "isDeleted" || user.isAdmin())
        );
        const fields = columns.toArray();
        const col = this.customFields.toColumnDef(defaults, {
          rowSelector: "id_num",
        });
        const custom = this.customFields.toColumnDef(fields, {
          format: this.customFields.formatCellCustomField.bind(this),
        });

        return col.concat(custom);
      })
    );
  }

  load() {
    return this.backend.list().pipe(
      map((result: Array<Department>) => {
        this.departmentsSubject.next(List(result));
        return result;
      })
    );
  }

  search(search: object) {
    return this.backend.search(search).pipe(
      map((result: Array<Department>) => {
        return result;
      })
    );
  }

  create(data: Department) {
    return this.backend.create(data);
  }

  update(data: Department) {
    return this.backend.update(data);
  }

  delete(data: Department) {
    return this.backend.delete(data);
  }

  getList() {
    return this.departmentsSubject.getValue();
  }
}
