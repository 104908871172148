import { User } from "../../routes/user/login.response";
import { Company } from "./company";
import { SignatureChecklistItem } from "./signature-checklist";
import { ToolEvent } from "./tool-event";
export class SignatureAuditTrail {
    _id?: string;
    company?: string | Company;
    event?: string | ToolEvent | null;
    user?: string | User;
    id_num: Number;
    signedOn: string;
    customFields?: any;
    isDeleted?: Boolean;
    createdAt?: Date;
    updatedAt?: Date;
    controlButton?: string;
    url?: string;
    checklistItems?: SignatureChecklistItem[];

    constructor(options: {
        _id?: string,
        company?: string | Company,
        event?: string | ToolEvent | null,
        user?: string | User,
        id_num: Number,
        signedOn: string,
        customFields?: any,
        isDeleted?: Boolean;
        createdAt?: Date,
        updatedAt?: Date,
        controlButton?: string,
        url?: string,
        checklistItems?: SignatureChecklistItem[],
    }) {

        this._id = options._id || undefined;
        this.company = options.company || null;
        this.event = options.event || null;
        this.user = options.user || null;
        this.id_num = options.id_num || null;
        this.signedOn = options.signedOn || null;
        this.customFields = options.customFields || null;
        this.isDeleted = options.isDeleted || false;
        this.createdAt = options.createdAt || new Date();
        this.updatedAt = options.updatedAt || new Date();
        this.controlButton = options.controlButton || null;
        this.url = options.url || null;
        this.checklistItems = options.checklistItems || [];
    }
}