<form
  class="navbar-form"
  role="search"
  action="search.html"
  *ngIf="visible"
  (submit)="handleForm()"
>
  <div class="form-group">
    <input
      [(ngModel)]="term"
      name="term"
      [typeahead]="dataSource$"
      [typeaheadAsync]="true"
      typeaheadOptionField="name"
      (typeaheadOnSelect)="onSelect($event)"
      (change)="onSelect($event)"
      [typeaheadScrollable]="true"
      [typeaheadOptionsInScrollableView]="20"
      class="form-control"
      type="text"
      placeholder="{{ 'header.search.SEARCH' | translate }}"
    />
  </div>
</form>
