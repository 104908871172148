import { Injectable } from "@angular/core";
import { catchError, delay, flatMap, map, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of, throwError, timer } from "rxjs";
import { LoginResponse, User } from "./login.response";
import { environment } from "../../../environments/environment";
import CryptoJS from "crypto-js";
import { RecoveryContact } from "../../core/interfaces/recovery-contact";
import { LoaderService } from "../../core/utils/loader.service";
import { decryptField } from "../../core/utils/general";
import { Router } from "@angular/router";
import moment from "moment";
import { Company } from "../../core/interfaces/company";
import {
  BrowserCacheLocation,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export const loggerCallback = (logLevel: LogLevel, message: string) => {
  console.log(message);
};

@Injectable({
  providedIn: "root",
})
export class AuthService {
  url: string;
  public uniqueTab: BehaviorSubject<number> = new BehaviorSubject(Math.random());
  public user$: BehaviorSubject<User> = new BehaviorSubject(null);
  public roleUpdate$: BehaviorSubject<User> = new BehaviorSubject(null);
  public refreshUser$: BehaviorSubject<User> = new BehaviorSubject(null);
  public otpUser$: BehaviorSubject<User> = new BehaviorSubject(null);
  public isDevConnected$: BehaviorSubject<Boolean> = new BehaviorSubject(null);
  public isTestConnected$: BehaviorSubject<Boolean> = new BehaviorSubject(null);
  public version$: BehaviorSubject<string> = new BehaviorSubject("");
  public signupLimit$: BehaviorSubject<string> = new BehaviorSubject("");
  public openAiModel$: BehaviorSubject<string> = new BehaviorSubject(null);
  public developerSMSNumber$: BehaviorSubject<string> = new BehaviorSubject("");
  public customVariables$: BehaviorSubject<any> = new BehaviorSubject(null);
  private user: User;
  private isDevConnected = false;
  private isTestConnected = false;
  private allowedFileExtentions = "";
  private allowedImageFileExtentions = "";
  private version = "";
  public columnStates$: BehaviorSubject<object> = new BehaviorSubject({});
  public columnStatesUpdated$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  constructor(
    private http: HttpClient,
    private loader: LoaderService,
    private router: Router
  ) {
    this.url = "/api";
    // this.checkSession();
  }

  public initAuth() {
    return this.checkSession();
  }

  private _setRenewTimer(interval) {
    of(null)
      .pipe(
        delay(interval - 30000),
        flatMap(() => {
          return this.renewToken();
        })
      )
      .subscribe(() => {});
  }

  getTimesInMinutesFromNow(date, timezone): number {
    timezone = timezone || "UTC";
    const lastDate = moment().tz(timezone).format("YYYY-MM-DD HH:mm:ss");
    const logoutDate = moment(date).tz(timezone).format("YYYY-MM-DD HH:mm:ss");
    const finalMinutes = moment
      .duration(moment(lastDate).diff(moment(logoutDate)))
      .asMinutes();
    return finalMinutes;
  }

  private _processLogin(
    req: Observable<LoginResponse>,
    remember?,
    isAd = false,
    isLogin = false,
  ) {
    this.loader.add();
    return req.pipe(
      tap(() => {
        this.loader.remove();
      }),
      map((resp: LoginResponse) => {
        this.user = new User(resp.user);
        if (resp.token) {
          this.setToken(resp.token);
          this.setCompany(resp.user.company._id);
          if (!this.user.language) this.user.language = "English";
          this.user.remember = remember;
          this.roleUpdate$.next(this.user);
          this.refreshUser$.next(this.user);
          this.user$.next(this.user);
          this.columnStates$.next(this.user.columnStates);
          this._setRenewTimer(resp.expiresIn);
          this.isDevConnected = resp.isDevConnected;
          this.isDevConnected$.next(this.isDevConnected);
          this.isTestConnected = resp.isTestConnected;
          this.allowedFileExtentions = resp.allowedFileExtentions;
          this.allowedImageFileExtentions = resp.allowedImageFileExtentions;
          this.isTestConnected$.next(this.isTestConnected);
          this.version = resp.version;
          this.version$.next(this.version);
          this.signupLimit$.next(resp.signupLimit);
          this.openAiModel$.next(this.user.settings?.openAiModel || null);
          this.customVariables$.next(resp.customVariables);
          localStorage.removeItem("_at_pusher");
        } else {
          this.otpUser$.next(this.user);
          this.developerSMSNumber$.next(decryptField(resp.developerSMSNumber));
          localStorage.setItem("_user", JSON.stringify(this.user));
          localStorage.setItem(
            "_developer_sms_number",
            resp.developerSMSNumber
          );
          localStorage.removeItem("_tab_open");
        }
        return true;
      }),
      catchError((err, status) => {
        this.loader.remove();
        if(!isAd) {
          this.removeSelectedLocalStorage();
          this.user$.next(null);
        }
        if(!isLogin && err.status === 401) {
          location.reload();
        }
        return throwError(err);
      })
    );
  }

  checkSession(isAd = false) {
    const token = this.getToken();
    const location = window.location.href;
    if(location.includes('user-login') && !token){
      return of(null);
    }
    if (!token) {
      return of(null);
    }
    return this._processLogin(
      this.http.get<LoginResponse>(this.url + "/renew_token"),
      null,
      isAd
    );
  }

  login(
    data: { company: string; email: string; password: string },
    remember: string
  ) {
    return this._processLogin(
      this.http.post<LoginResponse>(this.url + "/login", this.encrypt(data)),
      remember,
      false,
      true
    );
  }

  checkEligibleForLoginOtp(data: {company: string, username: string}) {
    return this.http.post<boolean>(`${this.url}/check-eligible-for-login-otp`, this.encrypt(data));
  }

  otpLogin(
    data: { company: string; username: string; },
  ) {
    return this._processLogin(
      this.http.post<LoginResponse>(this.url + "/otp-login", this.encrypt(data)),
      null,
      false,
      true
    );
  }

  loginAd(data: { company: string }, isSaml = false) {
    return this.http.get<Company>(`${this.url}/${isSaml ? 'saml': 'ad'}?name=${data.company}`);
  }

  register(user_data) {
    return this._processLogin(
      this.http.post<LoginResponse>(
        this.url + "/register",
        this.encrypt(user_data)
      )
    );
  }

  verify(code: String) {
    return this.http.post(`${this.url}/verify`, { code });
  }

  getSamlToken(id: string) {
    return this.http.post(`/saml/token/${id}`, {});
  }

  sendVerification(id?: String) {
    return this.http.post<LoginResponse>(
      `${this.url}/send-verification/${id ? id : ""}`,
      {}
    );
  }

  renewToken() {
    return this._processLogin(
      this.http.get<LoginResponse>(this.url + "/renew_token"),
      null,
      true
    );
  }

  verifyOTP(data) {
    return this._processLogin(
      this.http.post<LoginResponse>(
        `${this.url}/verify-otp`,
        this.encrypt(data)
      )
    );
  }

  resendOTP(data) {
    return this.http.post(`${this.url}/resend-otp`, data);
  }

  addOTPClick(data) {
    return this.http.post(`${this.url}/add-click`, {
      ...data,
      url: this.router.url,
    });
  }

  renewUser() {
    this.http
      .get<User>(this.url + "/user/" + this.user._id)
      .subscribe((data: User) => {
        this.user = new User(data);
        if (!this.user.language) this.user.language = "English";
        this.roleUpdate$.next(this.user);
        this.refreshUser$.next(this.user);
      });
  }

  checkCompany(name) {
    return timer(600) // Debounce time
      .pipe(
        flatMap(() =>
          this.http.get(this.url + "/check_company/" + encodeURIComponent(name))
        ),
        map((resp: any) => resp.found)
      );
  }

  recoveryContact(company, email) {
    return timer(600) // Debounce time
      .pipe(
        flatMap(() =>
          this.http.get<RecoveryContact>(
            `${this.url}/recovery_contact/${encodeURIComponent(
              company
            )}/${encodeURIComponent(email)}`
          )
        )
      );
  }

  getUser() {
    return this.refreshUser$.getValue();
  }

  getUserLanguage() {
    return this.user.language || "English";
  }

  updateLogout = (type: number) => {
    return this.http.post<any>(this.url + "/logout", { type: type });
  };

  removeSelectedLocalStorage = () => {
    localStorage.removeItem("_token");
    localStorage.removeItem("_company");
    localStorage.removeItem("_sign_out_counter");
    localStorage.removeItem("_active_events_filters");
    localStorage.removeItem("_dashboard_filters");
    localStorage.removeItem("_am_down_type_filter");
    localStorage.removeItem("_tab_open");
    localStorage.removeItem("_calendar_filters");
    this.user = null;
  };

  logout(type = 1) {
    const token = localStorage.getItem("_token");
    if (token) {
      this.loader.add();
      this.updateLogout(type).subscribe(
        () => {
          if (this.user.company && this.user.company.isActiveDirectory) {
            this.loginAd({ company: this.user.company.name }).subscribe(
              async (company) => {
                this.loader.remove();
                await this.initialMsal(company);
              },
              (err) => {
                this.removeSelectedLocalStorage();
                location.reload();
              }
            );
          } else {
            this.removeSelectedLocalStorage();
            location.reload();
          }
        },
        () => {
          this.removeSelectedLocalStorage();
          location.reload();
        }
      );
    }
  }

  initialMsal = async (company: Company) => {
    const authority = decryptField(company.activeDirectory.authority);
    const url = new URL(authority);
    const msalConfig = {
      auth: {
        clientId: decryptField(company.activeDirectory.clientId),
        authority: decryptField(company.activeDirectory.authority),
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        knownAuthorities: [`${url.protocol}//${url.host}`],
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Warning,
          piiLoggingEnabled: false,
        },
      },
    };
    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    msalInstance
      .logoutPopup();
    this.removeSelectedLocalStorage();
    setTimeout(() => {
      location.reload();
    }, 2000);
  };

  // noinspection JSMethodCanBeStatic

  setToken(token: string) {
    return localStorage.setItem("_token", token);
  }

  setCompany(_id: string) {
    return localStorage.setItem("_company", _id);
  }

  getToken() {
    return localStorage.getItem("_token");
  }

  getCompany() {
    return localStorage.getItem("_company");
  }

  getUniqueTab() {
    return this.uniqueTab.getValue().toString();
  }

  updateVersionAndLimit = (version: string, signup: string) => {
    this.version = version;
    this.version$.next(this.version);
    this.signupLimit$.next(signup);
  };

  updateOpenAiModel = (model: string) => {
    this.openAiModel$.next(model);
  }

  getId() {
    return this.user && this.user._id;
  }

  getCompanyId() {
    return this.user && this.user.company && this.user.company._id;
  }

  getAllowedFileExtentions() {
    return this.allowedFileExtentions;
  }

  getAllowedImageFileExtentions() {
    return this.allowedImageFileExtentions;
  }

  getAllowedImageFileExtentionsAccept() {
    return this.allowedImageFileExtentions
      .split(",")
      .map((s) => `.${s}`)
      .join(", ");
  }

  isOTPUser() {
    return this.otpUser$.getValue();
  }

  isRoot = () => {
    return this.hasPermission(["root"]);
  };

  isAdmin = () => {
    return this.hasPermission(["admin"]);
  };

  isModerator = () => {
    return (
      this.hasPermission(["moderator"]) &&
      this.user.company &&
      this.user.company.freemiumPermissions &&
      this.user.company.freemiumPermissions.moderator
    );
  };

  isQa = () => {
    return (
      this.hasPermission(["qa"]) &&
      this.user.company &&
      this.user.company.freemiumPermissions &&
      this.user.company.freemiumPermissions.qaSignature
    );
  };

  isSafety = () => {
    return this.hasPermission(["safety"]) &&
    this.user.company &&
    this.user.company.freemiumPermissions &&
    this.user.company.freemiumPermissions.safetySignature;
  };

  isDeveloper = () => {
    return this.hasPermission(["developer"]);
  };

  hasPermission = (permissions: string[]) =>
    this.user &&
    permissions.some((permission) =>
      this.user.roles.some((role) => role === permission)
    );

  encrypt = (data: any): any => ({
    ...data,
    password: data.password
      ? CryptoJS.AES.encrypt(
          data.password,
          environment.encryptionPhase
        ).toString()
      : undefined,
    email: data.email
      ? CryptoJS.AES.encrypt(data.email, environment.encryptionPhase).toString()
      : undefined,
    username: data.username
      ? CryptoJS.AES.encrypt(
          data.username,
          environment.encryptionPhase
        ).toString()
      : undefined,
    company: data.company
      ? CryptoJS.AES.encrypt(
          data.company,
          environment.encryptionPhase
        ).toString()
      : undefined,
    phone: data.phone
      ? CryptoJS.AES.encrypt(data.phone, environment.encryptionPhase).toString()
      : undefined,
    otp: data.otp
      ? CryptoJS.AES.encrypt(data.otp, environment.encryptionPhase).toString()
      : undefined,
    url: this.router.url,
  });

  getColumnState = (name: string) => {
    const v = this.columnStates$.getValue();
    return v[name];
  };

  updateColumnState = (name: string, value: any) => {
    const v = this.columnStates$.getValue();
    this.columnStates$.next({
      ...v,
      [name]: value,
    });
    this.columnStatesUpdated$.next(true);
  };

  updateStateUser = () => {
    const c = this.columnStatesUpdated$.getValue();
    if (c) {
      this.http
        .put<any>(this.url + "/user/update-state/" + this.user._id, {
          columnStates: this.columnStates$.getValue(),
        })
        .subscribe(() => {});
      this.columnStatesUpdated$.next(false);
    }
  };

  getCustomVariable = (key: string) => {
    return this.customVariables$.getValue()
      ? this.customVariables$.getValue()[key]
      : false;
  };
}
