import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Observable, Observer, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { FilesStoreService } from "../../../core/services/files-store.service";
import { TranslationsStoreService } from "../../../core/services/translations-store.service";
import { CommonService } from "../../../core/services/common.service";

@Component({
  selector: "app-select-file",
  templateUrl: "./select-file.component.pug",
  styleUrls: ["./select-file.component.scss"],
})
export class SelectFileComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  observer: Observer<string>;
  usedForAi: boolean = false;
  tool: string = null;
  form: UntypedFormGroup;
  files$: Observable<{ id: string; text: string }[]> =
    this.filesStoreService.files$.pipe(
      map((files) =>
        files
          .filter(
            (file) =>
              (file.entity === "Tool" ||
                file.entity === "Setup-Wizard" ||
                file.entity === "Custom Files") &&
              !file.deleted &&
              (this.usedForAi
                ? this.commonService.checkAiAssistanceFile(file, false)
                : true)
          )
          .sort((a, b) => {
            if (this.tool) {
              return a?.entityId === this.tool ? -1 : 1;
            }
            return a.id_num > b.id_num ? -1 : 1;
          })
          .map((f) => ({ id: f._id, text: `${f.id_num} - ${f.name}` }))
          .toArray()
      )
    );
  files = [];

  constructor(
    public translationService: TranslationsStoreService,
    public bsModalRef: BsModalRef,
    public fb: UntypedFormBuilder,
    public filesStoreService: FilesStoreService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.filesStoreService.load();
    this.form = this.fb.group({
      file: this.fb.control("", Validators.required),
    });
    this.subscriptions.push(
      this.files$.subscribe((files) => (this.files = files))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  save = () => {
    if (this.form.valid) {
      if (this.usedForAi) {
        const file = this.files.find((f) => f.id === this.form.value.file);
        if (!this.commonService.checkAiAssistanceFile({ name: file.text }))
          return;
      }
      this.bsModalRef.hide();
      this.observer.next(this.form.value.file);
    }
  };
}
