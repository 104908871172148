import { User } from "../../routes/user/login.response";
import { Company } from "./company";
import { SelectedTasks } from "./selected-tasks";
export class Procedure {
    _id?: string;
    company?: string | Company;
    name?: string;
    kind?: string;
    tasks?: SelectedTasks[];
    id_num?: Number;
    customFields?: any;
    isDeleted?: Boolean;
    signaturePerson?: string;
    url?: string;
    createdBy?: User;
    createdAt?: Date;
    updatedAt?: Date;

    constructor(options: {
        _id?: string,
        company?: string | Company,
        name?: string,
        kind?: string,
        tasks?: SelectedTasks[],
        id_num?: Number,
        customFields?: any,
        isDeleted?: Boolean,
        signaturePerson?: string,
        url?: string,
        createdBy?: User,
        createdAt?: Date,
        updatedAt?: Date,
    }) {

        this._id = options._id || undefined;
        this.company = options.company || null;
        this.name = options.name || null;
        this.kind = options.kind || null;
        this.tasks = options.tasks || [];
        this.id_num = options.id_num || null;
        this.customFields = options.customFields || null;
        this.isDeleted = options.isDeleted || false;
        this.signaturePerson = options.signaturePerson || null;
        this.url = options.url || null;
        this.createdBy = options.createdBy || null;
        this.createdAt = options.createdAt || new Date();
        this.updatedAt = options.updatedAt || new Date();
    }
}