import { CommonService } from './common.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { List } from 'immutable';
import { Observable, combineLatest } from 'rxjs';
import { CustomFieldsBackendService } from './backend/custom-fields.backend.service';
import { AuthService } from '../../routes/user/auth.service';
import { ICustomField } from '../interfaces/custom-field';
import { map } from 'rxjs/operators';
import { TextboxField } from '../../shared/models/textbox-field';
import { CheckboxField } from '../../shared/models/checkbox-field';
import { DropdownField } from '../../shared/models/dropdown-field';
import { ToasterService } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { isObject } from 'lodash';
import { HyperlinkField } from '../../shared/models/hyperlink-field';
import { DynamicField } from '../../shared/models/dynamic-field';
import { timeCalculation } from '../../shared/comparators/date-comparator';
import moment from 'moment';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EditColumnNameComponent } from '../../shared/components/edit-column-name/edit-column-name.component';
import { ROLES } from '../constants/enums/enums';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldsStoreService {
  protected customFieldsSubject = new BehaviorSubject(List([]));
  public readonly customFields$: Observable<List<ICustomField>> = this.customFieldsSubject.asObservable();

  constructor(protected backend: CustomFieldsBackendService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private commonService: CommonService,
    private modal: BsModalService,
    private router: Router,
    private auth: AuthService) {
    this.auth.user$.subscribe(user => {
      if (!user || !user._id) {
        return;
      }
      this.load();
    });
  }

  load() {
    this.backend.list()
      .pipe(
        map((customFields: Array<ICustomField>) => customFields.map(customField => new ICustomField(customField))),

      )
      .subscribe((customFields: Array<ICustomField>) => {
        return this.customFieldsSubject.next(List(customFields));
      })
  }

  get(table) {
    return this.customFields$.pipe(
      map(fields => fields.filter(field => field.table === table))
    )
  }

  getList() {
    return this.customFieldsSubject.getValue();
  }

  create(data: ICustomField) {
    if (data.name === "") {
      data.name = data.label.replace(/ /g, "_");
    }
    return this.backend.create(data)
  }

  update(data: ICustomField) {
    return this.backend.update(data);
  }

  getTokenString = () => {
    return `?access_token=${this.auth.getToken()}&company=${this.auth.getCompany()}`;
  }

  updateField(data: any) {
    return this.backend.updateField(data);
  }

  delete(data: { table: string, name: string }) {
    return this.backend.delete(data.table, data.name);
  }

  toColumnDef(fields: any[], options: any = {}) {
    const isAdmin = this.auth.hasPermission([ROLES.ADMIN, ROLES.TOOL_ADMIN]);
    if (isAdmin) {
      fields = fields.map(field => {
        if (field.section == 'events') field.readonly = !field.isAdminEditable
        return field;
      });
    }
    return fields.map(
      field => {
        if (options.table) {
          switch (options.table) {
            case 'pm':
              if (!this.auth.hasPermission([ROLES.CREATE_MAINTENANCE])) field.readonly = true;
              break;
            case 'part':
              if (!this.auth.hasPermission([ROLES.INVENTORY])) field.readonly = true;
              break;
            default:
              if (!this.auth.hasPermission([ROLES.ADMIN, ROLES.TOOL_ADMIN])) field.readonly = true;
              break;
          }
        }
        const section = field.section || '';

        const col: any = {
          colId: field.name,
          field: field.name,
          label: field.label,
          headerName: typeof field.translationLabel != 'undefined' ? (this.translate.instant(field.translationLabel) != field.translationLabel ? this.translate.instant(field.translationLabel) : field.label) : this.translate.instant(field.label),
          headerKey: typeof field.translationLabel != 'undefined' ? field.translationLabel : field.label,
          tooltip: (params) => params.value,
          editable: !field.readonly,
          cellClass: field.required ? 'required' : '',
          maxWidth: 400,
          hide: field.hide || false,
          valueGetter: field.valueGetter || null,
          tooltipValueGetter: field.tooltipValueGetter || null,
          comparator: field.comparator || undefined,
          minWidth: field.minWidth || undefined,
          section: section,
          fieldName: field.fieldName || '',
          sectionName: field.sectionName || '',
          multipleSelection: field.multipleSelection || false,
          required: field.required || false,
          filter: 'agSetColumnFilter',
          aggFunc: field.aggFunc || null,
          isCustom: false,
          isPmCustom: false,
          chartDataType: field.chartDataType,
          rules: field.rules || null,
          type: field.type,
          isRegularColumn: field.isRegularColumn || false,
        };

        if(!col.comparator && field.section !== "translations"){
          col.comparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
            if(typeof valueA == "undefined") valueA = 'null';
            if(typeof valueB == "undefined") valueB = 'null';
            if (valueA === 'null' || (nodeA && nodeA.data && nodeA.data.$row_status == "new")) {
              return isInverted ?-1: 1;
            } else if (valueB === 'null' || (nodeA && nodeA.data && nodeA.data.$row_status == "new")) {
              return isInverted ? 1: -1;
            } else if(valueA === valueB){
              return 0;
            } else {
              return !this.commonService.checkIsNumber(valueA) && typeof valueA === 'string' ? valueA.localeCompare(valueB) : (Number(valueA) > Number(valueB) ? 1 : (Number(valueA) < Number(valueB) ? -1 : 0));
            }
          }
        }

        if (((section == 'users' || section == 'workers') && field.name == 'username') || ((section == 'users' || section == 'workers') && field.name == 'password')) {
          col.headerComponentParams = {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span> <img src="assets/img/info_icon_transparent.png" class="ml-1 mr-1" style="width:20px;" tooltip="tooltip.duplicateRow" placement="bottom" alt="">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ><i class="fa fa-sort-alpha-asc" aria-hidden="true"></i></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ><i class="fa fa-sort-alpha-desc" aria-hidden="true"></i></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>'
          };

          if((section == 'users' || section == 'workers') && field.name == 'username'){
            col.headerTooltip = this.translate.instant('toasters.USER.PLEASE-ENTER-VALID-USERNAME');
          }else{
            col.headerTooltip = section == 'users' ? this.translate.instant('users.PASSWORD-CONTAINS-MSG') : this.translate.instant('workers.PASSWORD-CONTAINS-MSG');
          }
          col.tooltipComponent = 'customToolTip';
        }else{
          col.headerComponentParams = {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ><i class="fa fa-sort-alpha-asc" aria-hidden="true"></i></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ><i class="fa fa-sort-alpha-desc" aria-hidden="true"></i></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>'
          };
        }

        if (section == 'checklist' && field.name == 'taskTypeValue') {
          col.editable = (params) => params.data['taskType'] == 'measurement' ? true : false;
        }

        if (options.rowSelector && field.name === options.rowSelector) {
          col.headerCheckboxSelection = true;
          col.headerCheckboxSelectionFilteredOnly = true;
          col.checkboxSelection = true;
        }

        if (field.type === "dropdown") {
          col.cellEditor = "select";
          col.cellEditorParams = {
            values: field.options.map(opt => opt.value)
          }

          col.valueGetter = (params: any) => {
            if (field.name.includes('.')) {
              const event = { ...params.data }
              const props = field.name.split('.');
              const value = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', event);
              return value;
            } else {
              const colValue = params.data[field.name];
              const display = field.options.find(opt => opt.key === colValue);
              return display ? display.value : colValue;
            }
          }

          col.valueSetter = (params) => {
            const value = field.options.find(opt => opt.value === params.newValue);
            if (value) {
              if (section == 'checklist' && field.name == 'taskType') {
                if (value.key == 'checklist') params.data['taskTypeValue'] = '';
              }
              return params.data[field.name] = value.key;
            } else {
              return false;
            }
          }
        } else if (field.type === "multiselect") {
          col.cellEditor = "multiselect";
          col.cellEditorParams = {
            values: field.options,
            onChange: field.onChange
          }

          col.valueFormatter = (params: any) => {
            const props = field.name.split('.');
            const colValue = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', params.data);
            return colValue ? colValue.map(val => {
              const display = field.options.find(opt => opt.key === val);
              const k = display ? display.value : val;
              return k ? this.translate.instant(k) : '';
            }).join(', ') : null
          }
          col.valueGetter = (params: any) => {
            const props = field.name.split('.');
            const colValue = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', params.data);
            return colValue ? colValue.map(val => {
              const display = field.options.find(opt => opt.key === val);
              const k = display ? display.value : val;
              return k ? this.translate.instant(k) : '';
            }).join(', ') : null
          }
          col.valueSetter = (params) => {
            params.data[field.name] = params.newValue;
            return params;
          }
          col.tooltipValueGetter = (params: any) => {
            const props = field.name.split('.');
            const colValue = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', params.data);
            return colValue ? colValue.map(val => {
              const display = field.options.find(opt => opt.key === val);
              const k = display ? display.value : val;
              return k ? this.translate.instant(k) : '';
            }).join('\n') : null
          }

        } else if (field.type === "numeric") {
          col.cellEditor = "numeric";
        } else if (field.type === "timestamp") {
          col.editable = false;
        } else if (field.type === "file") {
          col.cellRenderer = "file"
          col.cellEditor = "file"
        } else if (field.type === "toolStatus") {
          col.cellRenderer = "toolStatus"
          col.cellEditor = "toolStatus"
        } else if (field.type === "toolStatusType") {
          col.cellRenderer = "toolStatusType"
        } else if (field.type === "boolean") {
          col.cellRenderer = "boolean"
        } else if (field.type === "silent-signature") {
          col.cellRenderer = "silentSignature"
          col.editable = false
        } else if (field.type === "textarea") {
          col.cellEditor = "agLargeTextCellEditor";
          col.cellEditorPopup = true;
          col.cellEditorParams = {
            maxLength: 100,
            rows: 10,
            cols: 50
          }
        } else if (field.type === "remove-text") {
          col.cellEditor = "removeText";
          col.valueSetter = (params) => {
            if(!params.newValue){
              params.data[field.name] = params.newValue;
            }
            return params;
          }
        } else if (field.type === "checkbox") {
          col.cellRenderer = "checkbox"
          col.cellEditor = "checkbox";
        } else if (field.type === "selectdropdown") {
          if(field.section == "pm-calender" && field.name == "status"){
            col.cellRenderer = "chip";
          }
          col.cellEditor = "selectdropdown";
          col.cellEditorParams = {
            values: field.options,
            id: field.id,
            onChange: field.onChange
          }

          col.valueGetter = (params: any) => {
            if (typeof col.cellEditorParams.values != 'undefined' && col.cellEditorParams.values.length > 0) {
              if (field.section == 'events' && field.name == 'worker' && isObject(params.data[field.name])) {
                const event = { ...params.data }
                const props = 'worker._id'.split('.');
                const value = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', event);
                const data = col.cellEditorParams.values.filter((data: any) => data.id == value);
                return data.length > 0 ? data[0]['text'] : null;
              } else {
                const data = col.cellEditorParams.values.filter((data: any) => data.id == params.data[field.name]);
                return data.length > 0 ? data[0]['text'] : null;
              }
            } else {
              if(field.name !== 'taskProcedure') {
                let fieldName = field.name;
                if (field.name == 'worker' && field.section == 'events') {
                  fieldName = 'worker.displayName';
                } else if (field.name == 'worker') {
                  fieldName = 'workerObject.displayName';
                } else if (field.name == 'department') {
                  fieldName = 'departmentObject.name';
                } else if (field.name == 'responsiblePerson') {
                  fieldName = 'responsiblePersonObject.displayName';
                } else if (field.name == 'tool') {
                  fieldName = 'toolObject.name';
                } else if (field.name == 'group') {
                  fieldName = 'groupObject.name';
                } else if (field.name == 'site') {
                  fieldName = 'siteObject.name';
                } else if (field.name == 'warehouse') {
                  fieldName = 'warehouseObject.name';
                } else if (field.name == 'unit') {
                  fieldName = 'unitObject.name';
                } else if (field.name == 'chapter') {
                  fieldName = 'chapterObject.name';
                }
                const event = { ...params.data }
                const props = fieldName.split('.');
                const value = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', event);
                return value;
              } else {
                const event = { ...params.data }
                return event.taskProcedure && event.taskProcedureObject ? `${event.taskProcedureObject.id_num} (${event.taskProcedureObject.name})` : '';
              }
            }
          }

          col.valueSetter = (params) => {
            params.data[field.name] = params.newValue;
            return params;
          }
        } else if (field.type === "checklist") {
          col.cellEditor = "checklist";
          col.valueGetter = (params: any) => {
            return params.data.checklist != null && params.data.checklist != '' && params.data.checklist.length > 0 ? params.data.checklist.map(item => item.value).join('; ') + ';' : "";
          }
          col.valueSetter = (params: any) => {
            params.data[field.name] = params.newValue;
            return params;
          }
        } else if (field.type === "datepicker") {
          col.cellEditor = "datepicker";
          col.valueGetter = (params: any) => {
            return params.data[field.name] && this.commonService.getDateTime(params.data[field.name]);
          }
          col.valueSetter = (params: any) => {
            params.data[field.name] = params.newValue;
            return params;
          }
        } else if (field.type === "dateonlypicker") {
          col.cellEditor = "datepicker";
          col.cellEditorParams = {
            visible: false,
          }
          col.valueGetter = (params: any) => {
            return params.data[field.name];
          }
          col.valueSetter = (params: any) => {
            params.data[field.name] = params.newValue;
            return params;
          }
        } else if (field.type === "timeonlypicker") {
          col.cellEditor = "datepicker";
          col.cellEditorParams = {
            time: false,
          }
          col.valueGetter = (params: any) => {
            return params.data[field.name];
          }
          col.valueSetter = (params: any) => {
            params.data[field.name] = params.newValue;
            return params;
          }
        } else if (field.type === "pm-dropdown") {
          col.cellEditor = "select";
          col.cellEditorParams = {
            values: field.options.map(opt => opt.value)
          }

          col.valueGetter = (params: any) => {
            return typeof params.data[field.name] != 'undefined' && ['FM', 'PM'].indexOf(params.data[field.name]) != -1 ? params.data[field.name] : (params.data.maintenance ? "PM" : "FM");
          }

          col.valueSetter = (params) => {
            if (params.newValue != params.oldValue) {
              params.data['type'] = '';
            }
            params.data[field.name] = params.newValue;
            return params;
          }
        } else if (field.type === "dynamicdropdown") {
          col.cellEditor = "dynamicdropdown";
          col.cellEditorParams = {
            values: field.options,
            machineStatusValues: field.options
          }

          col.valueGetter = (params: any) => {
            if (field.name == 'type') {
              if (typeof col.cellEditorParams.values != 'undefined' && col.cellEditorParams.values.length > 0) {
                const match = col.cellEditorParams.values.find(v => v.id == params.data[field.name]);
                if (match) {
                  return match['text'];
                } else {
                  const match = col.cellEditorParams.machineStatusValues.find(v => v.id == params.data[field.name]);
                  if (match) {
                    return match['text'];
                  }
                }
              } else {
                return params.data.maintenanceTypeObject ? params.data.maintenanceTypeObject.name : (params.data.machineStatusObject ? params.data.machineStatusObject.title : '');
              }
            }
            return params.data[field.name];
          }

          col.valueSetter = (params) => {
            params.data[field.name] = params.newValue;
            return params;
          }
        } else if (field.type === "multipledropdown") {
          col.cellEditor = "multipledropdown";
          col.cellEditorParams = {
            values: field.options,
            id: field.id,
            onChange: field.onChange
          }

          col.valueGetter = (params: any) => {
            if (typeof col.cellEditorParams.values != 'undefined' && col.cellEditorParams.values.length > 0 && params.data[field.name]) {
              const data = col.cellEditorParams.values.filter((data: any) => data.id && params.data[field.name] && params.data[field.name].includes(data.id));
              return data.length > 0 ? data.map(d => d.text).join(', ') : null;
            } else {
              let fieldName = field.name;
              let secondName = 'name';
              if (field.name == 'department') {
                fieldName = 'departmentObject';
              } else if (field.name == 'site') {
                fieldName = 'siteObject';
              } else if (field.name == 'tool') {
                fieldName = 'toolObject';
              } else if (field.name == 'group') {
                fieldName = 'groupObject';
              } else if (field.name == 'sendTo') {
                fieldName = 'sendToObject';
                secondName = 'displayName';
              } else if (field.name == 'customTable') {
                fieldName = 'customTableObject';
                secondName = 'name';
              } else if (field.name == 'keywords') {
                fieldName = 'keywordsObject';
                secondName = 'name';
              }
              if(field.name == 'inventoryParts'){
                const event = { ...params.data }
                const value = event['inventoryPartsObject'] ? event['inventoryPartsObject'].map(m => m && typeof m['catalogNumber'] != "undefined" ? `${m['catalogNumber']} | ${m['name']}` : "").join(', ') : '';
                return value;
              } else {
                const event = { ...params.data }
                const value = event[fieldName] ? event[fieldName].map(m => m && typeof m[secondName] != "undefined" ? m[secondName] : "").join(', ') : '';
                return value;
              }
            }
          }

          col.valueSetter = (params) => {
            params.data[field.name] = params.newValue;
            return params;
          }
        } else if (field.type === "customMultipleDropdown") {
          col.cellEditor = "customMultipleDropdown";
          col.cellEditorParams = {
            values: field.options,
            id: field.id,
            onChange: field.onChange
          }

          col.valueGetter = (params: any) => {
            if (
              typeof col.cellEditorParams.values != 'undefined' &&
              col.cellEditorParams.values.length > 0 &&
              params.data[field.name]
            ) {
              const data = col.cellEditorParams.values.filter(
                (data: any) =>
                  data.id &&
                  params.data[field.name] &&
                  params.data[field.name].includes(data.id)
              );
              return data.length > 0 ? data.map((d) => d.text).join(', ') : null;
            } else {
              let fieldName = field.name;
              let secondName = 'name';
              fieldName = `${field.name}Object`;
              const event = { ...params.data };
              const value = event[fieldName]
                ? event[fieldName]
                    .map((m) =>
                      m && typeof m[secondName] != 'undefined'
                        ? m[secondName]
                        : ''
                    )
                    .join(', ')
                : '';
              return value;
            }
          };

          col.valueSetter = (params) => {
            params.data[field.name] = params.newValue;
            return params;
          };
        } else if (field.type === "customClick") {
          col.cellRenderer = "customClick";
        } else if (field.type === "fileRedirection") {
          col.cellRenderer = "fileRedirection";
        }

        if (options.format) {
          return options.format(col, field);
        }

        return col;
      });
  }

  toColumnDefPivot(fields: any[], options: any = {}) {
    const isAdmin = this.auth.hasPermission([ROLES.ADMIN, ROLES.TOOL_ADMIN]);
    if (isAdmin) {
      fields = fields.map(field => {
        if (field.section == 'events') field.readonly = !field.isAdminEditable
        return field;
      });
    }
    return fields.map(
      field => {
        if (options.table) {
          switch (options.table) {
            case 'pm':
              if (!this.auth.hasPermission([ROLES.CREATE_MAINTENANCE])) field.readonly = true;
              break;
            case 'part':
              if (!this.auth.hasPermission([ROLES.INVENTORY])) field.readonly = true;
              break;
            default:
              if (!this.auth.hasPermission([ROLES.ADMIN, ROLES.TOOL_ADMIN])) field.readonly = true;
              break;
          }
        }
        const section = field.section || '';

        const col: any = {
          colId: field.name,
          field: field.name,
          label: field.label,
          headerName: typeof field.translationLabel != 'undefined' ? (this.translate.instant(field.translationLabel) != field.translationLabel ? this.translate.instant(field.translationLabel) : field.label) : this.translate.instant(field.label),
          tooltip: (params) => params.value,
          editable: !field.readonly,
          cellClass: field.required ? 'required' : '',
          maxWidth: 400,
          type: field.type,
          hide: field.hide || false,
          valueGetter: field.valueGetter || null,
          tooltipValueGetter: field.tooltipValueGetter || null,
          comparator: field.comparator || undefined,
          minWidth: field.minWidth || undefined,
          section: section,
          fieldName: field.fieldName || '',
          sectionName: field.sectionName || '',
          multipleSelection: field.multipleSelection || false,
          required: field.required || false,
          filter: 'agSetColumnFilter',
          rowGroup: field.rowGroup || false,
          enableRowGroup: field.enableRowGroup || false,
          pivot: field.pivot || false,
          aggFunc: field.aggFunc || null,
          isCustom: false,
          isPmCustom: false,
          chartDataType: field.chartDataType,
          isRegularColumn: field.isRegularColumn || false,
        };
        if (((section == 'users' || section == 'workers') && field.name == 'username') || ((section == 'users' || section == 'workers') && field.name == 'password')) {
          col.headerComponentParams = {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span> <img src="assets/img/info_icon_transparent.png" class="ml-1 mr-1" style="width:20px;" tooltip="tooltip.duplicateRow" placement="bottom" alt="">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ><i class="fa fa-sort-alpha-asc" aria-hidden="true"></i></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ><i class="fa fa-sort-alpha-desc" aria-hidden="true"></i></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>'
          };

          if((section == 'users' || section == 'workers') && field.name == 'username'){
            col.headerTooltip = this.translate.instant('toasters.USER.PLEASE-ENTER-VALID-USERNAME');
          }else{
            col.headerTooltip = section == 'users' ? this.translate.instant('users.PASSWORD-CONTAINS-MSG') : this.translate.instant('workers.PASSWORD-CONTAINS-MSG');
          }
          col.tooltipComponent = 'customToolTip';
        }else{
          col.headerComponentParams = {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ><i class="fa fa-sort-alpha-asc" aria-hidden="true"></i></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ><i class="fa fa-sort-alpha-desc" aria-hidden="true"></i></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>'
          };
        }

        if (section == 'checklist' && field.name == 'taskTypeValue') {
          col.editable = (params) => params.data['taskType'] == 'measurement' ? true : false;
        }

        if (options.rowSelector && field.name === options.rowSelector) {
          col.headerCheckboxSelection = true;
          col.headerCheckboxSelectionFilteredOnly = true;
          col.checkboxSelection = true;
        }

        if (field.type === "dropdown") {
          col.cellEditor = "select";
          col.cellEditorParams = {
            values: field.options.map(opt => opt.value)
          }

          col.valueGetter = (params: any) => {
            if (field.name.includes('.')) {
              const event = { ...params.data }
              const props = field.name.split('.');
              const value = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', event);
              return value;
            } else {
              const colValue = params.data[field.name];
              const display = field.options.find(opt => opt.key === colValue);
              return display ? display.value : colValue;
            }
          }

          col.valueSetter = (params) => {
            const value = field.options.find(opt => opt.value === params.newValue);
            if (value) {
              if (section == 'checklist' && field.name == 'taskType') {
                if (value.key == 'checklist') params.data['taskTypeValue'] = '';
              }
              return params.data[field.name] = value.key;
            } else {
              return false;
            }
          }
        } else if (field.type === "multiselect") {
          col.cellEditor = "multiselect";
          col.cellEditorParams = {
            values: field.options,
            onChange: field.onChange
          }

          col.valueFormatter = (params: any) => {
            const props = field.name.split('.');
            const colValue = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', params.data);
            return colValue ? colValue.map(val => {
              const display = field.options.find(opt => opt.key === val);
              const k = display ? display.value : val;
              return k ? this.translate.instant(k) : '';
            }).join(', ') : null
          }
          col.valueGetter = (params: any) => {
            const props = field.name.split('.');
            const colValue = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', params.data);
            return colValue ? colValue.map(val => {
              const display = field.options.find(opt => opt.key === val);
              const k = display ? display.value : val;
              return k ? this.translate.instant(k) : '';
            }).join(', ') : null
          }
          col.valueSetter = (params) => {
            params.data[field.name] = params.newValue;
            return params;
          }
          col.tooltipValueGetter = (params: any) => {
            const props = field.name.split('.');
            const colValue = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', params.data);
            return colValue ? colValue.map(val => {
              const display = field.options.find(opt => opt.key === val);
              const k = display ? display.value : val;
              return k ? this.translate.instant(k) : '';
            }).join('\n') : null
          }

        } else if (field.type === "numeric") {
          col.cellEditor = "numeric";
        } else if (field.type === "checkbox") {
          col.cellRenderer = "checkbox"
          col.cellEditor = "checkbox";
        } else if (field.type === "selectdropdown") {
          col.cellEditor = "selectdropdown";
          col.cellEditorParams = {
            values: field.options,
            id: field.id,
            onChange: field.onChange
          }

          col.valueGetter = (params: any) => {
            if (typeof col.cellEditorParams.values != 'undefined' && col.cellEditorParams.values.length > 0) {
              if (field.section == 'events' && field.name == 'worker' && isObject(params.data[field.name])) {
                const event = { ...params.data }
                const props = 'worker._id'.split('.');
                const value = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', event);
                const data = col.cellEditorParams.values.filter((data: any) => data.id == value);
                return data.length > 0 ? data[0]['text'] : null;
              } else {
                const data = col.cellEditorParams.values.filter((data: any) => data.id == params.data[field.name]);
                return data.length > 0 ? data[0]['text'] : null;
              }
            } else {
              let fieldName = field.name;
              if (field.name == 'worker' && field.section == 'events') {
                fieldName = 'worker.displayName';
              } else if (field.name == 'worker') {
                fieldName = 'workerObject.displayName';
              } else if (field.name == 'department') {
                fieldName = 'departmentObject.name';
              } else if (field.name == 'responsiblePerson') {
                fieldName = 'responsiblePersonObject.displayName';
              } else if (field.name == 'tool') {
                fieldName = 'toolObject.name';
              } else if (field.name == 'group') {
                fieldName = 'groupObject.name';
              } else if (field.name == 'site') {
                fieldName = 'siteObject.name';
              }
              const event = { ...params.data }
              const props = fieldName.split('.');
              const value = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', event);
              return value;
            }
          }

          col.valueSetter = (params) => {
            params.data[field.name] = params.newValue;
            return params;
          }
        } else if (field.type === "checklist") {
          col.cellEditor = "checklist";
          col.valueGetter = (params: any) => {
            return params.data.checklist != null && params.data.checklist != '' && params.data.checklist.length > 0 ? params.data.checklist.map(item => item.value).join('; ') + ';' : "";
          }
          col.valueSetter = (params: any) => {
            params.data[field.name] = params.newValue;
            return params;
          }
        } else if (field.type === "datepicker") {
          col.cellEditor = "datepicker";
          col.valueGetter = (params: any) => {
            return params.data[field.name];
          }
          col.valueSetter = (params: any) => {
            params.data[field.name] = params.newValue;
            return params;
          }
        } else if (field.type === "dateonlypicker") {
          col.cellEditor = "datepicker";
          col.cellEditorParams = {
            visible: false,
          }
          col.valueGetter = (params: any) => {
            return params.data[field.name];
          }
          col.valueSetter = (params: any) => {
            params.data[field.name] = params.newValue;
            return params;
          }
        } else if (field.type === "pm-dropdown") {
          col.cellEditor = "select";
          col.cellEditorParams = {
            values: field.options.map(opt => opt.value)
          }

          col.valueGetter = (params: any) => {
            return typeof params.data[field.name] != 'undefined' && ['FM', 'PM'].indexOf(params.data[field.name]) != -1 ? params.data[field.name] : (params.data.maintenance ? "PM" : "FM");
          }

          col.valueSetter = (params) => {
            if (params.newValue != params.oldValue) {
              params.data['type'] = '';
            }
            params.data[field.name] = params.newValue;
            return params;
          }
        } else if (field.type === "dynamicdropdown") {
          col.cellEditor = "dynamicdropdown";
          col.cellEditorParams = {
            values: field.options,
            machineStatusValues: field.options
          }

          col.valueGetter = (params: any) => {
            if (field.name == 'type') {
              if (typeof col.cellEditorParams.values != 'undefined' && col.cellEditorParams.values.length > 0) {
                const match = col.cellEditorParams.values.find(v => v.id == params.data[field.name]);
                if (match) {
                  return match['text'];
                } else {
                  const match = col.cellEditorParams.machineStatusValues.find(v => v.id == params.data[field.name]);
                  if (match) {
                    return match['text'];
                  }
                }
              } else {
                return params.data.maintenanceTypeObject ? params.data.maintenanceTypeObject.name : (params.data.machineStatusObject ? params.data.machineStatusObject.title : '');
              }
            }
            return params.data[field.name];
          }

          col.valueSetter = (params) => {
            params.data[field.name] = params.newValue;
            return params;
          }
        } else if (field.type === "multipledropdown") {
          col.cellEditor = "multipledropdown";
          col.cellEditorParams = {
            values: field.options,
            id: field.id,
            onChange: field.onChange
          }

          col.valueGetter = (params: any) => {
            if (typeof col.cellEditorParams.values != 'undefined' && col.cellEditorParams.values.length > 0 && params.data[field.name]) {
              const data = col.cellEditorParams.values.filter((data: any) => data.id && params.data[field.name] && params.data[field.name].includes(data.id));
              return data.length > 0 ? data.map(d => d.text).join(', ') : null;
            } else {
              let fieldName = field.name;
              let secondName = 'name';
              if (field.name == 'department') {
                fieldName = 'departmentObject';
              } else if (field.name == 'site') {
                fieldName = 'siteObject';
              } else if (field.name == 'tool') {
                fieldName = 'toolObject';
              } else if (field.name == 'group') {
                fieldName = 'groupObject';
              } else if (field.name == 'sendTo') {
                fieldName = 'sendToObject';
                secondName = 'displayName';
              } else if (field.name == 'keywords') {
                fieldName = 'keywordsObject';
                secondName = 'name';
              }
              const event = { ...params.data }
              const value = event[fieldName] ? event[fieldName].map(m => m[secondName]).join(', ') : '';
              return value;
            }
          }

          col.valueSetter = (params) => {
            params.data[field.name] = params.newValue;
            return params;
          }
        } else if (field.type === "customClick") {
          col.cellRenderer = "customClick";
        } else if (field.type === "fileRedirection") {
          col.cellRenderer = "fileRedirection";
        }

        if (options.format) {
          return options.format(col, field);
        }

        return col;
      });
  }

  toFormControl(fields: any[]) {

    return fields.map(field => {
      switch (field.type) {
        case 'textbox':
          return new TextboxField({
            key: field.name,
            label: field.translationLabel ? field.translationLabel : field.label,
            options: field.options,
            required: field.required,
            readonly: field.readonly,
            validators: field.validators,
            multiple: field.multiple || false,
            dontPresentOnModal: field.dontPresentOnModal || false,
            preventEditOnModal: field.preventEditOnModal || false,
            type: field.type,
          })
        case 'hyperlink':
          return new HyperlinkField({
            key: field.name,
            label: field.translationLabel ? field.translationLabel : field.label,
            options: field.options,
            required: field.required,
            readonly: field.readonly,
            validators: field.validators,
            multiple: field.multiple || false,
            dontPresentOnModal: field.dontPresentOnModal || false,
            preventEditOnModal: field.preventEditOnModal || false,
            type: field.type,
          })
        case 'checkbox':
          return new CheckboxField({
            key: field.name,
            label: field.translationLabel ? field.translationLabel : field.label,
            options: field.options,
            required: field.required,
            readonly: field.readonly,
            validators: field.validators,
            multiple: field.multiple || false,
            dontPresentOnModal: field.dontPresentOnModal || false,
            preventEditOnModal: field.preventEditOnModal || false,
            type: field.type,
          });
        case 'dropdown':
        case 'selectdropdown':
          return new DropdownField({
            key: field.name,
            label: field.translationLabel ? field.translationLabel : field.label,
            options: field.options,
            required: field.required,
            readonly: field.readonly,
            validators: field.validators,
            multiple: field.multiple || field.multipleSelection || false,
            dontPresentOnModal: field.dontPresentOnModal || false,
            preventEditOnModal: field.preventEditOnModal || false,
            type: field.type,
          });
        case 'multipledropdown':
          return new DropdownField({
            key: field.name,
            label: field.translationLabel ? field.translationLabel : field.label,
            options: field.options,
            required: field.required,
            readonly: field.readonly,
            validators: field.validators,
            multiple: true,
            dontPresentOnModal: field.dontPresentOnModal || false,
            preventEditOnModal: field.preventEditOnModal || false,
            type: field.type,
          });
        case 'smart-dropdown':
          return new DropdownField({
            key: field.name,
            label: field.translationLabel ? field.translationLabel : field.label,
            options: field.options,
            required: field.required,
            readonly: field.readonly,
            validators: field.validators,
            multiple: field.multiple || false,
            dontPresentOnModal: field.dontPresentOnModal || false,
            preventEditOnModal: field.preventEditOnModal || false,
            type: field.type,
          });
        default:
          return new DynamicField({
            controlType: field.type,
            key: field.name,
            label: field.translationLabel ? field.translationLabel : field.label,
            options: field.options,
            required: field.required,
            readonly: (field.type == "timestamp" || field.type == "silent-signature") ? true : field.readonly ,
            validators: field.validators,
            multiple: field.multiple || false,
            dontPresentOnModal: field.dontPresentOnModal || false,
            preventEditOnModal: field.preventEditOnModal || false,
            type: field.type,
          })
      }
    });
  }

  insertCol(table, customField: any) {
    this.create(customField).subscribe(
      () => {
        this.load();
        this.toaster.pop('success', `${this.translate.instant(table == 'part' ? 'toasters.CUSTOM_FIELDS.OK_NEW_FIELD_SAVED_INVENTORY' : 'toasters.CUSTOM_FIELDS.OK_NEW_FIELD_SAVED')} [${customField.label}]`);
      },
      (err) => {
        this.toaster.pop('error', this.translate.instant('toasters.CUSTOM_FIELDS.ERR_NEW_FIELD_SAVED'));
      },
      () => {
      }
    );
  }

  showDeleteCol(table: string, fields: any[]) {
    combineLatest(fields.map(field => this.delete(field))).subscribe(() => {
      this.load();
      this.toaster.pop("success", this.translate.instant('toasters.CUSTOM_FIELDS.OK_DELETE'));
    },
      () => {
        this.toaster.pop("error", this.translate.instant('toasters.CUSTOM_FIELDS.ERR_DELETE'));
      },
      () => {
      });
  }

  formatCellCustomField = (col, field) => {
    const commonFormatter = {
      isCustom: true,
      isPmCustom: false,
      headerName: `${col.headerName}${col.required ? " *" : ""}`,
      type: field.type,
      chartDataType: col.chartDataType ? col.chartDataType : col.colId.toLowerCase() === "price" ? "series" : null,
      valueGetter: row => {
        return row.data.customFields ? row.data.customFields[field.name] : ""
      },
      valueSetter: (row) => {
        let cf: any[string] = [];
        cf[row.colDef.colId] = row.newValue;
        row.data.customFields = { ...row.data.customFields, ...cf };
        return row;
      }
    };

    switch (field.type) {
      case "timestamp":
        return {
          ...col,
          required: false,
          valueFormatter: (params) => {
            const value = params.data.updatedAt ? params.data.updatedAt : null;
            return value && this.commonService.getDateTime(value);
          },
          valueGetter: (params) => {
            const value = params.data.updatedAt ? params.data.updatedAt : null;
            return value && this.commonService.getDateTime(value);
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const aValue = nodeA.data.updatedAt ? nodeA.data.updatedAt: new Date();
            const bValue = nodeB.data.updatedAt ? nodeB.data.updatedAt: new Date();
            return timeCalculation(aValue, bValue, nodeA, nodeB, isInverted);
          },
        };
      case "silent-signature":
        return {
          ...col,
          required: false,
        };
      case "timeonlypicker":
        return {
          ...col,
          valueFormatter: (params) => {
            const value = params.value ? params.value : params.data.customFields && params.data.customFields[field.name] ? params.data.customFields[field.name] : null;
            return value && this.commonService.getTime(value);
          },
          valueGetter: (params) => {
            const value = params.value ? params.value : params.data.customFields && params.data.customFields[field.name] ? params.data.customFields[field.name] : null;
            return value && this.commonService.getTime(value);
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const aValue = valueA ? moment(moment().format("YYYY-MM-DD")+" "+ moment(valueA).tz(this.commonService.getTimezone()).format("HH:mm")) : null;
            const bValue = valueB ? moment(moment().format("YYYY-MM-DD")+" "+ moment(valueB).tz(this.commonService.getTimezone()).format("HH:mm")) : null;
            return timeCalculation(aValue, bValue, nodeA, nodeB, isInverted);
          },
          ...commonFormatter
        };
      case "dateonlypicker":
        return {
          ...col,
          valueFormatter: (params) => {
            const value = params.value ? params.value : params.data.customFields && params.data.customFields[field.name] ? params.data.customFields[field.name] : null;
            return value && this.commonService.getDate(value);
          },
          valueGetter: (params) => {
            const value = params.value ? params.value : params.data.customFields && params.data.customFields[field.name] ? params.data.customFields[field.name] : null;
            return value && this.commonService.getDate(value);
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const aValue = nodeA.data.customFields && nodeA.data.customFields[field.name] ? nodeA.data.customFields[field.name] : null;
            const bValue = nodeB.data.customFields && nodeB.data.customFields[field.name] ? nodeB.data.customFields[field.name] : null;
            return timeCalculation(aValue, bValue, nodeA, nodeB, isInverted);
          },
          ...commonFormatter
        };
      case "datepicker":
        return {
          ...col,
          valueFormatter: (params) => {
            const value = params.value ? params.value : params.data.customFields && params.data.customFields[field.name] ? params.data.customFields[field.name] : null;
            return value && this.commonService.getDateTime(value);
          },
          valueGetter: (params) => {
            const value = params.value ? params.value : params.data.customFields && params.data.customFields[field.name] ? params.data.customFields[field.name] : null;
            return value && this.commonService.getDateTime(value);
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const aValue = nodeA.data.customFields && nodeA.data.customFields[field.name] ? nodeA.data.customFields[field.name] : null;
            const bValue = nodeB.data.customFields && nodeB.data.customFields[field.name] ? nodeB.data.customFields[field.name] : null;
            return timeCalculation(aValue, bValue, nodeA, nodeB, isInverted);
          },
          ...commonFormatter
        };
      case "hyperlink":
        return {
          ...col,
          cellRenderer: row => {
            return row.data.customFields && row.data.customFields[field.name] ? '<a href="' + row.data.customFields[field.name] + '" target="_blank">' + row.data.customFields[field.name] + '</a>' : ""
          },
          ...commonFormatter
        };
      case "smart-dropdown":
        return {
          ...col,
          cellEditor: "smartdropdown",
          ...commonFormatter
        };
      default:
        return {
          ...col,
          ...commonFormatter
        };
    }
  }

  formatCellPmCustomField = (col, field) => {
    const commonFormatter = {
      isCustom: true,
      isPmCustom: true,
      headerName: `${col.headerName}${col.required ? " *" : ""}`,
      type: field.type,
      valueGetter: row => {
        return row.data.pmCustomFields ? row.data.pmCustomFields[field.name] : ""
      },
      valueSetter: (row) => {
        let cf: any[string] = [];
        cf[row.colDef.colId] = row.newValue;
        row.data.pmCustomFields = { ...row.data.pmCustomFields, ...cf };
        return row;
      }
    }
    switch (field.type) {
      case "timestamp":
        return {
          ...col,
          required: false,
          valueFormatter: (params) => {
            const value = params.data.updatedAt ? params.data.updatedAt : null;
            return value && this.commonService.getDateTime(value);
          },
          valueGetter: (params) => {
            const value = params.data.updatedAt ? params.data.updatedAt : null;
            return value && this.commonService.getDateTime(value);
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const aValue = nodeA.data.updatedAt ? nodeA.data.updatedAt: new Date();
            const bValue = nodeB.data.updatedAt ? nodeB.data.updatedAt: new Date();
            return timeCalculation(aValue, bValue, nodeA, nodeB, isInverted);
          },
        };
      case "silent-signature":
        return {
          ...col,
          required: false,
        };
      case "timeonlypicker":
        return {
          ...col,
          valueFormatter: (params) => {
            const value = params.value ? params.value : params.data.pmCustomFields && params.data.pmCustomFields[field.name] ? params.data.pmCustomFields[field.name] : null;
            return value && this.commonService.getTime(value);
          },
          valueGetter: (params) => {
            const value = params.value ? params.value : params.data.pmCustomFields && params.data.pmCustomFields[field.name] ? params.data.pmCustomFields[field.name] : null;
            return value && this.commonService.getTime(value);
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const aValue = valueA ? moment(moment().format("YYYY-MM-DD")+" "+ moment(valueA).tz(this.commonService.getTimezone()).format("HH:mm")) : null;
            const bValue = valueB ? moment(moment().format("YYYY-MM-DD")+" "+ moment(valueB).tz(this.commonService.getTimezone()).format("HH:mm")) : null;
            return timeCalculation(aValue, bValue, nodeA, nodeB, isInverted);
          },
          ...commonFormatter
        };
      case "dateonlypicker":
        return {
          ...col,
          valueFormatter: (params) => {
            const value = params.value ? params.value : params.data.pmCustomFields && params.data.pmCustomFields[field.name] ? params.data.pmCustomFields[field.name] : null;
            return value && this.commonService.getDate(value);
          },
          valueGetter: (params) => {
            const value = params.value ? params.value : params.data.pmCustomFields && params.data.pmCustomFields[field.name] ? params.data.pmCustomFields[field.name] : null;
            return value && this.commonService.getDate(value);
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const aValue = nodeA.data.pmCustomFields && nodeA.data.pmCustomFields[field.name] ? nodeA.data.pmCustomFields[field.name] : null;
            const bValue = nodeB.data.pmCustomFields && nodeB.data.pmCustomFields[field.name] ? nodeB.data.pmCustomFields[field.name] : null;
            return timeCalculation(aValue, bValue, nodeA, nodeB, isInverted);
          },
          ...commonFormatter
        };
      case "datepicker":
        return {
          ...col,
          valueFormatter: (params) => {
            const value = params.value ? params.value : params.data.pmCustomFields && params.data.pmCustomFields[field.name] ? params.data.pmCustomFields[field.name] : null;
            return value && this.commonService.getDateTime(value);
          },
          valueGetter: (params) => {
            const value = params.value ? params.value : params.data.pmCustomFields && params.data.pmCustomFields[field.name] ? params.data.pmCustomFields[field.name] : null;
            return value && this.commonService.getDateTime(value);
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const aValue = nodeA.data.pmCustomFields && nodeA.data.pmCustomFields[field.name] ? nodeA.data.pmCustomFields[field.name] : null;
            const bValue = nodeB.data.pmCustomFields && nodeB.data.pmCustomFields[field.name] ? nodeB.data.pmCustomFields[field.name] : null;
            return timeCalculation(aValue, bValue, nodeA, nodeB, isInverted);
          },
          ...commonFormatter
        };
      case "hyperlink":
        return {
          ...col,
          cellRenderer: row => {
            return row.data.pmCustomFields && row.data.pmCustomFields[field.name] ? '<a href="' + row.data.pmCustomFields[field.name] + '" target="_blank">' + row.data.pmCustomFields[field.name] + '</a>' : ""
          },
          ...commonFormatter
        };
      case "smart-dropdown":
        return {
          ...col,
          cellEditor: "smartdropdown",
          ...commonFormatter
        };
      default:
        return {
          ...col,
          ...commonFormatter
        };
    }
  }

  includeUrl = (data) => {
    return {
      ...data,
      url: this.router.url,
    }
  }

  editColumn = (table: any, customFields: any[], columnDefs: any[], tableName: string) => {
    if (!table.gridApi) return;
    const sort = table.gridApi.getSortModel();
    if (!sort.length) return;

    if (sort.length > 0) {
      let fields = customFields.find(field => field.name === sort[0].colId)
      if (fields && fields.hide) {
        this.toaster.pop("error", this.translate.instant('toasters.GENERAL.ERR_COLUMN_UPDATE'));
        return false;
      }
      const f = columnDefs.find(field => field.colId === sort[0].colId)
      if (!fields) {
        if (f) {
          fields = new ICustomField({
            name: f.colId,
            label: f.headerName,
            table: tableName,
            type: "textbox",
            isRegularColumn: true,
          })
        }
      }
      if (!fields) {
        this.toaster.pop("error", this.translate.instant('toasters.GENERAL.ERR_COLUMN_UPDATE'));
      } else {
        this.modal.show(EditColumnNameComponent,
          {
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: {
              table: tableName,
              field: {
                ...fields,
                translationLabel: f.label,
                translationKey: f.headerKey,
              },
            },
            class: "modal-lg min-width-modal modal-full modal-big-width-on-small"
          })
      }
    }
  }

}