import { AiHelper } from './../../interfaces/ai-helper';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KeyWord } from '../../interfaces/keywords';



@Injectable({
  providedIn: 'root'
})
export class AiHelperBackendService {
  url = '/api/ai-helper';

  constructor(private http: HttpClient) { }

  list(skip: number, limit: number, sortColumn: string, sortType: string) {
    return this.http.get<Array<AiHelper>>(`${this.url}?skip=${skip * limit}&limit=${limit}&sortColumn=${sortColumn}&sortType=${sortType}`)
  }

  create(data: AiHelper) {
    return this.http.post(this.url, data);
  }

  update(data: AiHelper) {
    return this.http.put(`${this.url}/${data._id}`, data);
  }

  delete(data: AiHelper) {
    return this.http.delete(`${this.url}/${data._id}`);
  }

  keyWordOptions(): Observable<Array<KeyWord>> {
    return this.http.get<Array<KeyWord>>(`${this.url}/keywords`);
  }

  syncTool() {
    return this.http.post(`${this.url}/sync-tool`, {});
  }

  generateFineTuningFile() {
    return this.http.post(`${this.url}/generate-fine-tuning-file`, {});
  }

  generateFineTuning() {
    return this.http.post(`${this.url}/generate-fine-tuning`, {});
  }

  exportToExcel() {
    return this.http.post(`${this.url}/export-to-excel`, {});
  }

  checkFineTuneStatus() {
    return this.http.post<{ status: string }>(`${this.url}/check-fine-tune-status`, {});
  }

  checkSuggestions() {
    return this.http.post(`${this.url}/check-suggestions`, {});
  }

  checkFineTuneResult(ids: string[]) {
    return this.http.post<Array<{ aiHelper: AiHelper; text: number; }>>(`${this.url}/check-fine-tune-result`, { ids });
  }

  count(): Observable<{ total: number }> {
    return this.http.get<{ total: number }>(`${this.url}/count`);
  }
}
