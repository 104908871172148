import { AbstractControl, ValidatorFn } from "@angular/forms";

const regex = /^(?=.*[a-z]).*$/;

const mustHaveLowercaseValidator = (): ValidatorFn => {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const forbidden = mustHaveLowercase(control.value);
      return forbidden ? null : {mustHaveLowercase: {value: control.value}}
    };
  }

const mustHaveLowercase = value => regex.test(value)

export default {
  mustHaveLowercaseValidator,
  mustHaveLowercase
}