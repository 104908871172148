
const dashboard = {
  text: 'menu.DASHBOARD',
  link: '/main/dashboard',
  icon: 'fas fa-bars',
  img: 'dashboard.svg',
};

const activeWall = {
  text: 'menu.WORK-ORDERS',
  link: '/main/work-orders',
  icon: 'fas fa-calendar-check',
  img: 'work-orders.svg',
};

const calendar = {
  text: 'menu.CALENDAR',
  link: '/main/calendar',
  icon: 'fas fa-calendar-check',
  img: 'calendar.svg',
};

const maintenance = {
  text: 'menu.MAINTENANCE',
  link: '/main/maintenance',
  icon: 'fas fa-wrench',
  img: 'maintenance.svg',
};

const tables = {
  text: 'menu.TABLES',
  link: '/tables',
  icon: 'fa fa-table',
  defaultOpen: true,
  submenu: [
    {
      text: 'menu.STATUS',
      link: '/tables/status',
    },
    {
      text: 'menu.TOOLS',
      link: '/tables/tools',
    },
    {
      text: 'menu.PM',
      link: '/tables/pm',
    },
    {
      text: 'menu.HISTORY',
      link: '/tables/history',
    },
    {
      text: 'menu.REPORT-SUMMARY',
      link: '/tables/report-summary',
    },
    {
      text: 'menu.INVENTORY',
      link: '/tables/inventory',
    },
    {
      text: 'menu.FILES',
      link: '/tables/files',
    },
    {
      text: 'menu.MEASUREMENTS',
      link: '/tables/measurements',
    },
    {
      text: 'menu.MEASUREMENT-RESULTS',
      link: '/tables/measurement-results',
    },
  ]
}

const dataTables = {
  text: 'menu.ADMIN-TABLES',
  link: '/admin',
  icon: 'icon-settings',
  defaultOpen: false,
  permissions: ['admin', 'tool_admin'],
  submenu: [
    {
      text: 'menu.USERS',
      link: '/admin/users',
      permissions: ['admin', 'tool_admin'],
    },
    {
      text: 'menu.WORKERS',
      link: '/admin/workers',
      permissions: ['admin', 'tool_admin'],
    },
    {
      text: 'menu.PM-KINDS',
      link: '/admin/pm-kinds',
      permissions: ['admin', 'tool_admin'],
    },
    {
      text: 'menu.MACHINE-STATUS',
      link: '/admin/machine-status',
      permissions: ['admin', 'tool_admin'],
    },
    {
      text: 'menu.TRANSLATION',
      link: '/admin/translation',
      permissions: ['admin', 'tool_admin'],
    },
    {
      text: 'menu.DEPARTMENT',
      link: '/admin/departments',
      permissions: ['admin', 'tool_admin'],
    },
    {
      text: 'menu.PROCEDURES',
      link: '/admin/procedures',
      permissions: ['admin', 'tool_admin'],
    },
    {
      text: 'menu.PROCEDURES-SUGGESTIONS',
      link: '/admin/procedures-suggestions',
      permissions: ['admin', 'tool_admin'],
    },
    {
      text: 'menu.SUBSYSTEM-TABLE',
      link: '/admin/subsystem-table',
      permissions: ['admin', 'tool_admin'],
    },
    {
      text: 'menu.TASKS',
      link: '/admin/tasks',
      permissions: ['admin', 'tool_admin'],
    },
    {
      text: 'menu.IP-ADDRESSES',
      link: '/admin/ip-addresses',
      permissions: ['admin', 'tool_admin'],
    },
    {
      text: 'menu.SITES',
      link: '/admin/sites',
      permissions: ['admin', 'tool_admin'],
    },
    {
      text: 'menu.DRAWING-DEFINITIONS',
      link: '/admin/drawing-definitions',
      permissions: ['admin', 'tool_admin'],
    },
    {
      text: 'menu.GENERAL-LOGS',
      link: '/admin/general-logs',
      permissions: ['admin', 'tool_admin'],
    },
    {
      text: 'menu.WAREHOUSES',
      link: '/admin/warehouses',
      permissions: ['admin', 'tool_admin'],
    },
    {
      text: 'menu.WHATSAPP-SPAM-NUMBERS',
      link: '/admin/whatsapp-spam-numbers',
      permissions: ['admin', 'tool_admin'],
    },
    {
      text: 'menu.UNITS',
      link: '/admin/units',
      permissions: ['admin', 'tool_admin'],
    },
    {
      text: 'menu.DATA-FORMATS',
      link: '/admin/data-formats',
      permissions: ['admin', 'tool_admin'],
    }
  ]
};

const smartDropdown = {
  text: 'menu.SMART-DROPDOWN',
  link: '/smart-dropdown',
  icon: 'icon-settings',
  defaultOpen: false,
  permissions: ['admin', 'tool_admin'],
  submenu: [
    {
      text: 'menu.ADD-NEW-MENU',
      link: '#',
      click: 'addNewSubMenu',
      permissions: ['admin'],
    },
    {
      text: 'menu.ADD-NEW-REPORT',
      link: '#',
      click: 'addNewReport',
      permissions: ['admin'],
    },
  ]
};

export const menu = [
  dashboard,
  activeWall,
  calendar,
  maintenance,
  tables,
  dataTables,
  smartDropdown
];
