import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserLogs } from '../../interfaces/user-logs';

@Injectable({
  providedIn: 'root'
})
export class UserLogsBackendService {
  url = '/api/user-logs';

  constructor(private http: HttpClient) { }

  listByLimit(skip: number, limit: number) {
    return this.http.get<Array<UserLogs>>(`${this.url}?skip=${skip * limit}&limit=${limit}`);
  }

  loginListByLimit(skip: number, limit: number) {
    return this.http.get<Array<UserLogs>>(`${this.url}/login-logs?skip=${skip * limit}&limit=${limit}`);
  }

  list() {
    return this.http.get<Array<UserLogs>>(this.url)
  }

  listAll() {
    return this.http.get<Array<UserLogs>>(`${this.url}?show=all`)
  }

  count(): Observable<{ total: Number }> {
    return this.http.get<{ total: Number }>(`${this.url}/count`);
  }

  countLogin(): Observable<{ total: number }> {
    return this.http.get<{ total: number }>(`${this.url}/login-logs/count`);
  }
}
