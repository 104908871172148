import { IField } from "../../interfaces/field";

const FieldDefinition: IField[] = [
  {
    name: 'tool',
    label: 'table.transaction.table-title.TOOL-ID',
    type: 'textbox',
    required: false,
    readonly: true,
  },
  {
    name: 'part',
    label: 'table.transaction.table-title.PART-ID',
    type: 'textbox',
    required: false,
    readonly: true,
  },
];

export default
  {
    FieldDefinition
  }