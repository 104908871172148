import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observer } from 'rxjs';
import { TranslationsStoreService } from '../../../../core/services/translations-store.service';
import { AuthService } from '../../../../routes/user/auth.service';

@Component({
  selector: 'app-select-column',
  templateUrl: './select-column.component.pug',
  styleUrls: ['./select-column.component.scss']
})
export class SelectColumnComponent implements OnInit {

  observer: Observer<string[]>;
  @Input('columns') columns: any;
  @Input('tableName') tableName: string;
  filteredColumns: any;

  constructor(
    public bsModalRef: BsModalRef,
    public fb: UntypedFormBuilder,
    public translationService: TranslationsStoreService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.filteredColumns = this.columns.filter(c => !c.hide && c.headerName);
    const name = `ag-grid-select-column-${this.tableName}`;
    let value = localStorage.getItem(name) ? localStorage.getItem(name) : this.authService.getColumnState(name);
    if(value){
      value = JSON.parse(value);
      this.filteredColumns.map((f) => {
        f.checked = value.includes(f.colId)
        return f;
      })
    }
  }

  checkValue = ($event, item) => {
    const exist = this.filteredColumns.findIndex(f => f.colId === item.colId);
    if(exist > -1){
      this.filteredColumns[exist] = {
        ...this.filteredColumns[exist],
        checked: $event.target.checked
      }
    }
  }

  save = () => {
    const selected = this.filteredColumns.filter(f => f.checked).map(f => f.colId);
    const name = `ag-grid-select-column-${this.tableName}`;
    const value = JSON.stringify(selected);
    localStorage.setItem(name, value);
    this.authService.updateColumnState(name, value);
    if(selected.length > 0){
      this.bsModalRef.hide();
      this.observer.next(selected);
    }else{
      this.toaster.pop('error', this.translate.instant('toasters.GENERAL.PLEASE-CHOOSE-AT-LEAST-ONE-COLUMN'));
      return false;
    }
  }

}
