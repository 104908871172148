import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FileUploader } from "../../utils/ng2-file-upload";
import { Observable } from "rxjs";
import { AuthService } from "../../../routes/user/auth.service";
import { Part } from "../../interfaces/part";

@Injectable({
  providedIn: "root",
})
export class PartsBackendService {
  url = "/api/parts";

  constructor(private http: HttpClient, private auth: AuthService) {}

  list(lastFetched: Date): Observable<Part[]> {
    const url = lastFetched
      ? `${this.url}?time=${lastFetched.getTime()}`
      : this.url;
    return this.http.get<Array<Part>>(url);
  }

  listWithPagination(
    skip: number,
    limit: number,
    sortColumn: string,
    sortType: string
  ) {
    return this.http.get<Part[]>(
      `${this.url}?skip=${
        skip * limit
      }&limit=${limit}&sortColumn=${sortColumn}&sortType=${sortType}`
    );
  }

  search(search: object): Observable<Part[]> {
    return this.http.post<Part[]>(`${this.url}/search`, search);
  }

  checkCatalogNumbers(catalogNumbers: string[]) {
    return this.http.post<Part[]>(`${this.url}/check-catalog-numbers`, {
      catalogNumbers,
    });
  }

  options(lastFetched: Date) {
    const url = lastFetched
      ? `${this.url}/options?time=${lastFetched.getTime()}`
      : `${this.url}/options`;
    return this.http.get<Array<Part>>(url);
  }

  listEvents(search: string = null, page: number = 0) {
    return this.http.get<Part[]>(
      `${this.url}/list-events?search=${search}&page=${page}`
    );
  }

  multiple(parts: string[]) {
    return this.http.get<Part[]>(`${this.url}/multiple?ids=${parts.join(",")}`);
  }

  createMultiple(parts: Part[], toolId: string | null) {
    return this.http.post<Part[]>(`${this.url}/create-multiple`, { parts, tool: toolId });
  }

  create(part: Part) {
    return this.http.post<Part>(this.url, part);
  }

  update(part: Part) {
    return this.http.put<Part>(`${this.url}/${part._id}`, part);
  }

  delete(part: Part) {
    return this.http.delete(`${this.url}/${part._id}`);
  }

  getById(id: string) {
    return this.http.get<Part>(`${this.url}/${id}`);
  }

  duplicate(id: string[]) {
    return this.http.post<Part[]>(`${this.url}/duplicate`, { id: id });
  }

  uploadPicture = (part, files) => {
    return new Observable((observer) => {
      const uploader = new FileUploader({
        url: `${this.url}/${part._id}/uploadPicture`,
        method: "POST",
        authToken: "Bearer " + this.auth.getToken(),
        headers: [
          { name: "Company", value: this.auth.getCompany() },
        ],
        autoUpload: true,
      });

      uploader.onCompleteItem = (item, response, status) => {
        if (status > 400) {
          return observer.error(
            new HttpErrorResponse({ error: response, status: status })
          );
        }
        observer.next(JSON.parse(response));
        observer.complete();
      };

      uploader.addToQueue(files);
    });
  };

  count(): Observable<{ total: number }> {
    return this.http.get<{ total: number }>(`${this.url}/count`);
  }
}
