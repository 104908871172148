import { environment } from './../../../environments/environment';
import CryptoJS from 'crypto-js';

export const encryptField = (str: string | number) => {
  return CryptoJS.AES.encrypt(str as string, environment.encryptionPhase).toString()
}

export const decryptField = (str: string) => {
  if(!str) return str;
  const de = CryptoJS.AES.decrypt(str, environment.encryptionPhase).toString(CryptoJS.enc.Utf8);
  return de ? de : str
}

export const weekDaysNumber = {
  'Sunday': 0,
  'Monday': 1,
  'Tuesday': 2,
  'Wednesday': 3,
  'Thursday': 4,
  'Friday': 5,
  'Saturday': 6,
}