import { HostListener, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { Tool } from '../../core/interfaces/tool';
import { ToolsStoreService } from '../../core/services/tools-store.service';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, ResolveEnd, Router } from '@angular/router';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ToolService } from './tool/tool.service';
import { AuthService } from '../user/auth.service';
import { List } from 'immutable';
import { ToolEvent } from '../../core/interfaces/tool-event';
import { DynamicListService } from '../smart-dropdown/dynamic-list/dynamic-list.service';
import { DynamicReportService } from '../smart-report/dynamic-report/dynamic-report.service';
import { WindowsService } from '../../core/services/windows.service';
import { EventsStoreService } from '../../core/services/events-store.service';
import { takeRight } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NavService implements OnDestroy {

  public title$: BehaviorSubject<string>;
  public backLink$: BehaviorSubject<string>;
  public filter$: BehaviorSubject<object>;
  public searchHighlightSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public searchHighlight$: Observable<string> = this.searchHighlightSubject.pipe(
    map(value => value),
    shareReplay(1)
  );
  public addToolSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public addTool$: Observable<string> = this.addToolSubject.pipe(
    map(value => value)
  );
  public isDrawingEditSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isDrawingEdit$: Observable<boolean> = this.isDrawingEditSubject.pipe(
    map(value => value),
    shareReplay(1)
  );
  public isDrawingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isDrawing$: Observable<boolean> = this.isDrawingSubject.pipe(
    map(value => value),
    shareReplay(1)
  );
  public drawingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public toolDrawing$: Observable<boolean> = this.drawingSubject.pipe(
    map(value => value),
    shareReplay(1)
  );
  public isRevertChangesSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isRevertChanges$: Observable<boolean> = this.isRevertChangesSubject.pipe(
    map(value => value),
    shareReplay(1)
  );
  public revertChangesSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public revertChanges$: Observable<boolean> = this.revertChangesSubject.pipe(
    map(value => value),
    shareReplay(1)
  );
  public toolEventsSubject: BehaviorSubject<List<ToolEvent>> = new BehaviorSubject(List([]));
  public toolEvents$: Observable<List<ToolEvent>> = this.toolEventsSubject.pipe(
    map(events => events),
    shareReplay(1)
  );
  public openReportSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public openReport$: Observable<boolean> = this.openReportSubject.pipe(
    map(value => value),
    shareReplay(1)
  );
  public fontSizeSubject: BehaviorSubject<number> = new BehaviorSubject<number>(14);
  public fontSize$: Observable<number> = this.fontSizeSubject.pipe(
    shareReplay(1)
  );
  public activeEventsFilter$: BehaviorSubject<object>;
  public doExportActiveEvent$: BehaviorSubject<boolean>;
  public generatePmCalendar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public downloadFiles$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public groupUpdatePm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public disableGroupUpdatePm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private onDestroy$ = new Subject<boolean>();
  public path = [];
  public length: any;
  public route: any;
  public baseFilter = {
    'type': 'All',
    'responsiblePerson': '',
    'department': '',
    'done': '',
    'site': '',
    'group': [],
    'timePeriodUpdated': false,
    'isKpi': false,
    'timePeriod': {
      start: moment().subtract(1, 'months').startOf('day'), end: moment()
    },
    'fm': '',
    'mtbf': '',
    'mttr': '',
    'down': '',
    'sla': '',
    'downHours': false,
    'downTypeFilter': 'percent',
    'monthlyDowntime': '',
    'pmFmWorkHours': '',
    'pmPlannedVsActual': '',
  }
  public filters: any = Object.assign({},this.baseFilter);
  screen_width: any;

  monthlyComparison = {
    years: [],
    field: null,
    fieldHeader: null
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screen_width = window.innerWidth;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private windowsService: WindowsService,
    public translate: TranslateService,
    private toolsStore: ToolsStoreService,
    private dynamicListService: DynamicListService,
    private dynamicReportService: DynamicReportService,
    private authService: AuthService,
    private toolService: ToolService,
    public eventsService: EventsStoreService,
    private location: Location) {
    this.title$ = new BehaviorSubject<string>('No title');
    this.backLink$ = new BehaviorSubject<string>(null);
    this.filter$ = new BehaviorSubject<object>({});
    this.doExportActiveEvent$ = new BehaviorSubject<boolean>(false);
    this.activeEventsFilter$ = new BehaviorSubject<object>({});

    combineLatest([this.router.events
      .pipe(
        takeUntil(this.onDestroy$),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === PRIMARY_OUTLET)
      ), this.toolsStore.toolsUpdated])
      .subscribe(([route, toolsUpdated]: any) => {
        this.path = [];
        if (route.snapshot.data.title) {
          if (route.snapshot.data.parent) {
            this.path.push({
              title: route.snapshot.data.parent.title,
              link: route.snapshot.data.parent.link,
            });
          } else {
            this.path.push({
              title: route.snapshot.data.title,
              link: route.snapshot.data.url || route.snapshot.url
            });
          }
          if (['maintenance', 'maintenance/:parent', 'maintenance/tool/:id'].indexOf(route.snapshot.routeConfig.path) >= 0) {
            this.route = route.snapshot;
            const parentItem =
            this.toolsStore.getToolList().find((t) => t.id_num === parseInt(route.snapshot.params.parent || route.snapshot.params.id, 10));
            this.path = this.path.concat(this.buildToolPath(parentItem));
          }
        }
        if (route.snapshot.routeConfig.path == 'maintenance/tool/:id') {
          const tool = parseInt(route.snapshot.params.id, 10);
          if (tool != this.toolService.getTool()) {
            this.toolService.setTool(tool)
          }
        }
        if(route.snapshot.routeConfig.path == ':key'){
          const key = route.snapshot.params.key;
          if (key != this.dynamicListService.getKey()) {
            this.dynamicListService.setKey(key);
          }
        }
        if(route.snapshot.routeConfig.path == ':report'){
          const key = route.snapshot.params.report;
          if (key != this.dynamicReportService.getKey()) {
            this.dynamicReportService.setKey(key);
          }
        }
        if (this.path.length > 1 || route.snapshot.routeConfig.path == 'line-measurements/:id') {
          const back = this.path[this.path.length - 1];
          if (back)
            this.backLink$.next(back.link);
        } else {
          this.backLink$.next(null);
        }
      });

    this.firstSetDashboardFilter();
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  getPageSlug() {
    const isUrl = !!this.route && !!this.route.url;
    if (isUrl) {
      const parsedUrl = this.route.url;
      const lastSegmentName = parsedUrl[parsedUrl.length - 1].path;
      return lastSegmentName;
    }
  }

  getPath() {
    if((this.screen_width && this.screen_width <= 600) || this.windowsService.getWindowSize() <= 600){
      const path = takeRight(this.path, this.path.length > 1 ? 2 : 1);
      return path.map((p, i) => {
        p.title = `${this.translate.instant(p.title).length > 15 ? `${this.translate.instant(p.title).substring(0, 15)}...` : p.title}`
        return p;
      })
    }else{
      return this.path;
    }
  }

  isPm = () => {
    let p = this.path;
    return p && p[0] && p[0].link[0] === 'pm';
  }

  isStatus = () => {
    let p = this.path;
    return p && p[0] && p[0].link[0] === 'status';
  }

  isPmCalendar = () => {
    let p = this.path;
    return p && p[0] && p[0].link[0] === 'pm-calendar';
  }

  isOnlyCalendarView = () => {
    let p = this.path;
    return p && p[0] && p[0].link[0] === 'calendar-view';
  }

  isCalendarView = () => {
    let p = this.path;
    return p && p[0] && (p[0].link[0] === 'calendar-view' || p[0].link[0] === 'calendar');
  }

  isLineMeasurement = () => {
    let p = this.path;
    return p && p[0] && p[0].link[0] === 'line-measurements';
  }

  isWall = () => {
    let p = this.path;
    return p && p[0] && (p[0].link[0] === 'dashboard');
  }

  isHeaderSticky = () => {
    return (this.isWall() || this.isMaintenance() || this.isActiveEvents() || this.isGroupDetail());
  }

  isMaintenance = () => {
    let p = this.path;
    return p && p.length == 1 && p[0] && (p[0].link[0] === 'maintenance');
  }

  isMaintenanceAll = () => {
    let p = this.path;
    return p && p.length > 0 && p[0] && (p[0].link[0] === 'maintenance');
  }

  isGroupDetail = () => {
    return this.router.url.includes('/main/maintenance/') && !this.isToolDetail();
  }

  isToolDetail = () => {
    return this.router.url.includes('/main/maintenance/tool/');
  }

  isReportSummary = () => {
    let p = this.path;
    return p && p.length == 1 && p[0] && (p[0].link[0] === 'report-summary');
  }

  isTool = () => {
    let p = this.path;
    return p && p.length == 1 && p[0] && (p[0].link[0] === 'tools');
  }

  isToolDrawing = () => {
    return this.drawingSubject.getValue();
  }

  isActiveEvents = () => {
    let p = this.path;
    return p && p[0] && (p[0].link[0] === 'work-orders');
  }

  isHistoryAt = () => {
    let p = this.path;
    return p && p.length == 1 && p[0] && (p[0].link[0] === 'history-audit-trail');
  }

  isGeneralLogs = () => {
    let p = this.path;
    return p && p.length == 1 && p[0] && (p[0].link[0] === 'general-logs');
  }

  isUserLogs = () => {
    let p = this.path;
    return p && p.length == 1 && p[0] && (p[0].link[0] === 'users-logs');
  }

  getFilter() {
    return this.filter$;
  }

  getDashboardFilter = () => {
    return localStorage.getItem('_dashboard_filters') ? JSON.parse(localStorage.getItem('_dashboard_filters')) : this.filters;
  }

  firstSetDashboardFilter = () => {
    const fontSize = localStorage.getItem('_chart_font_size');
    if (fontSize) {
      this.fontSizeSubject.next(parseInt(fontSize));
    }
    const result = this.getDashboardFilter();
    if (result && Object.keys(result).length) {
      this.filters = result;
    } else {
      const user = this.authService.getUser()
      if (user.dashboardTimePicker) {
        this.filters = user.dashboardTimePicker;
      }
    }
    this.filters['timePeriod'] = { start: moment(this.filters['timePeriod']['start']), end: moment(this.filters['timePeriod']['end']) };
  }

  setFilter(filter: any, isGraphReset = 1, resetDownHours = 1) {
    const f = JSON.parse(JSON.stringify(filter));
    if(isGraphReset){
      if(f['monthlyDowntime'] || f['pmFmWorkHours'] || f['pmPlannedVsActual']){
        f['timePeriodUpdated'] = true;
      }
      f['down'] = "";
      f['mttr'] = "";
      f['mtbf'] = "";
      f['fm'] = "";
      f['sla'] = "";
      f['monthlyDowntime'] = "";
      f['pmFmWorkHours'] = "";
      f['pmPlannedVsActual'] = "";
    }
    if(resetDownHours){
      f['downHours'] = false;
    }
    f.timePeriod = { start: moment(f.timePeriod.start), end: moment(f.timePeriod.end) };
    localStorage.setItem('_dashboard_filters', JSON.stringify(f));
    this.filter$.next(f);
  }

  setActiveEventsFilter(filter: any) {
    this.activeEventsFilter$.next(filter);
  }

  setExportActiveEvent(doExportActiveEvent: any) {
    this.doExportActiveEvent$.next(doExportActiveEvent);
  }

  getActiveEventsFilter = () => {
    return this.activeEventsFilter$;
  }

  updateGraphFilter = (type, value) => {
    this.filters['down'] = type == "down" ? value : "";
    this.filters['mttr'] = type == "mttr" ? value : "";
    this.filters['mtbf'] = type == "mtbf" ? value : "";
    this.filters['fm'] = type == "fm" ? value : "";
    this.filters['sla'] = type == "sla" ? value : "";
    if(type == "monthlyDowntime" || type == "pmFmWorkHours" || type == "pmPlannedVsActual"){
      this.filters['monthlyDowntime'] = type == "monthlyDowntime" ? value : "";
      this.filters['pmFmWorkHours'] = type == "pmFmWorkHours" ? value : "";
      this.filters['pmPlannedVsActual'] = type == "pmPlannedVsActual" ? value : "";
      const start = moment(value, "MM/YYYY").startOf('month').unix() * 1000;
      const end = moment(value, "MM/YYYY").endOf('month').unix() * 1000;
      this.eventsService.getEventsByTime(start, end).subscribe(data => {
        this.toolEventsSubject.next(data);
      });
    }else{
      this.filters['monthlyDowntime'] = "";
      this.filters['pmFmWorkHours'] = "";
      this.filters['pmPlannedVsActual'] = "";
    }
    this.setFilter(this.filters, 0);
  }

  buildToolPath(tool: Tool) {
    const tools = this.toolsStore.getTools();
    const path = [];
    while (tool) {
      path.unshift({
        title: tool.name,
        link: tool.isGroup ? ['maintenance', tool.id_num] : ['maintenance', 'tool', tool.id_num],
        type: tool.isGroup ? 'group' : 'tool'
      });
      if(tool.isSubSystem){
        tool = tool.tool && tool._id != tool.tool && tools.find((t) => t._id === tool.tool);
      }else{
        tool = tool.group && tool._id != tool.group._id && tools.find((t) => t._id === tool.group._id);
      }
    }
    return path;
  }

  goBack() {
    this.location.back();
  }
}
