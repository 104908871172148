import { User } from "../../routes/user/login.response";

export class UserLogs{
    _id?: String;
    id_num?: Number;
    company?: String;
    user?: User;
    login?: Date;
    loginType?: Number;
    logout?: Date;
    logoutType?: Number;
    ip?: String;
    browser?: String;
    location?: String;
    isDeleted?: Boolean;
    createdAt?: Date;
    updatedAt?: Date;

    constructor(options:{
        _id?: String,
        id_num?: Number,
        company?: String,
        user?: User,
        login?: Date,
        loginType?: Number,
        logout?: Date,
        logoutType?: Number,
        ip?: String,
        browser?: String,
        location?: String,
        isDeleted?: Boolean,
        createdAt?: Date,
        updatedAt?: Date,
    }){
        this._id = options._id || null;
        this.id_num = options.id_num || 0;
        this.company = options.company || null;
        this.user = options.user || null;
        this.login = options.login || null;
        this.loginType = options.loginType || null;
        this.logout = options.logout || null;
        this.logoutType = options.logoutType || null;
        this.ip = options.ip || null;
        this.browser = options.browser || null;
        this.location = options.location || null;
        this.isDeleted = options.isDeleted || false;
        this.createdAt = options.createdAt || null
        this.updatedAt = options.updatedAt || null
    }
}
