import { Injectable } from "@angular/core";
import { List } from "immutable";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { finalize, map, tap } from "rxjs/operators";
import { AuthService } from "../../routes/user/auth.service";
import { Part } from "../interfaces/part";
import { PartsBackendService } from "./backend/parts.backend.service";
import { CustomFieldsStoreService } from "./custom-fields-store.service";
import FieldDefinition from "../constants/field-definitions/part";
import { LoaderService } from "../utils/loader.service";
import { CommonService } from "./common.service";
import { timeCalculation } from "../../shared/comparators/date-comparator";
import { isObject, uniqBy } from "lodash";
import { TranslationsStoreService } from "./translations-store.service";

@Injectable({
  providedIn: "root",
})
export class PartsStoreService {
  private skip = 0;
  private limit = 0;
  private allFetched: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private totalSubject: BehaviorSubject<number> = new BehaviorSubject(0);

  private partsSubject: BehaviorSubject<List<Part>> = new BehaviorSubject(
    List([])
  );
  public parts$: Observable<List<Part>> = this.partsSubject.asObservable();
  public readonly columns$: Observable<any[]>;
  private lastFetched: Date = null;

  private partOptionList = [];
  private lastFetchedOption: Date = null;
  public partOptionUpdated: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  constructor(
    private backend: PartsBackendService,
    private customFields: CustomFieldsStoreService,
    private loader: LoaderService,
    private translationsStoreService: TranslationsStoreService,
    private commonService: CommonService,
    private auth: AuthService
  ) {
    auth.user$.subscribe((user) => {
      if (!user || !user._id || user.isRoot()) {
        this.partsSubject.next(List([]));
        return;
      }
    });

    this.columns$ = combineLatest([
      this.auth.user$,
      this.customFields.get("part"),
      this.translationsStoreService.languageCode$,
    ]).pipe(
      map(([user, columns, languageCode]) => {
        if (!user) return [];

        const defaults = FieldDefinition.FieldDefinition.filter(
          (col) =>
            (col.name != "company.name" || user.isRoot()) &&
            (col.name != "isDeleted" || user.isAdminOrToolAdmin())
        ).map((field) => ({
          ...field,
          readonly: !this.auth.hasPermission(["inventory"]) || field.readonly,
        }));

        const fields = columns.toArray().map((field) => ({
          ...field,
          readonly: !this.auth.hasPermission(["inventory"]) || field.readonly,
        }));

        const col = this.customFields.toColumnDef(defaults, {
          rowSelector: "id_num",
          table: "part",
          format: this.formatCell.bind(this),
        });
        const custom = this.customFields.toColumnDef(fields, {
          format: this.customFields.formatCellCustomField.bind(this),
        });
        return col.concat(custom);
      })
    );
  }

  load = (force: boolean = false) =>
    this.backend.list(force ? null : this.lastFetched).pipe(
      map((parts) => parts.map((part) => new Part(part))),
      map((parts) => parts.map(this.remapPart)),
      tap((parts) => {
        if (!this.lastFetched) {
          this.partsSubject.next(List(parts));
        } else {
          var partsList = this.partsSubject.getValue();
          parts.forEach((part) => {
            const idx = partsList.findIndex((p: Part) => p._id === part._id);
            if (idx < 0) {
              partsList = partsList.unshift(part);
            } else {
              partsList = partsList.set(idx, part);
            }
          });

          this.partsSubject.next(partsList);
        }
        this.lastFetched = new Date();
      })
    );

  getTotalCount = () => {
    return this.totalSubject.getValue();
  };

  listEvents = (search: string = null, page: number = 0) => {
    return this.backend.listEvents(search, page);
  };

  multiple = (ids: string[]) => {
    return this.backend.multiple(ids).pipe(
      map((parts) => parts.map((part) => new Part(part))),
      map((parts) => parts.map(this.remapPart))
    );
  };

  options = () => {
    const time = new Date();
    return this.backend.options(this.lastFetchedOption).pipe(
      tap((parts) => {
        if (!this.lastFetchedOption) {
          this.partOptionList = parts;
        } else {
          const prev = [...this.partOptionList];
          this.partOptionList = uniqBy([...parts, ...prev], "_id");
        }
        this.lastFetchedOption = time;
        this.partOptionUpdated.next(true);
      })
    );
  };

  getOptionList = () => {
    return this.partOptionList;
  };

  count = () => {
    if (!this.getTotalCount()) {
      this.backend
        .count()
        .pipe(
          tap((count) => {
            this.totalSubject.next(count.total);
          })
        )
        .subscribe();
    }
  };

  getAllFetched = () => {
    return this.allFetched.getValue();
  };

  updateLimit = (limit: number) => {
    this.skip = 0;
    this.limit = limit;
    this.allFetched.next(false);
    this.partsSubject.next(List([]));
    this.count();
  };

  loadParts = (sortColumn: string, sortType: string) =>
    this.backend
      .listWithPagination(this.skip++, this.limit, sortColumn, sortType)
      .pipe(
        map((parts) => parts.map((part) => new Part(part))),
        map((parts) => parts.map(this.remapPart)),
        tap((parts) => {
          if (parts.length < this.limit || this.limit === 0)
            this.allFetched.next(true);
          if (this.skip === 0 || this.limit == 0) {
            this.partsSubject.next(List(parts));
          } else {
            const prev = this.partsSubject.getValue().toArray();
            const data = uniqBy([...parts, ...prev], "_id");
            this.partsSubject.next(List(data));
          }
        })
      );

  search = (search: object) =>
    this.backend.search(search).pipe(
      map((parts) => parts.map((part) => new Part(part))),
      tap((parts) => parts)
    );

  checkCatalogNumbers = (catalogNumbers: string[]) =>
    this.backend.checkCatalogNumbers(catalogNumbers);

  createMultiple = (parts: Part[], toolId: string | null) =>
    this.backend.createMultiple(parts, toolId).pipe(
      map((parts) => parts.map((part) => new Part(part))),
      map((parts) => parts.map(this.remapPart)),
      tap((parts) => {
        parts.forEach((part) => {
          this.partsSubject.next(this.partsSubject.getValue().unshift(part));
        });
      })
    );

  save = (part: Part) => (part._id ? this.update(part) : this.create(part));

  update = (part: Part): Observable<Part> =>
    this.backend.update(part).pipe(
      map((data) => this.remapPart(data)),
      tap((data: Part) => {
        const parts = this.partsSubject.getValue();
        const idx = parts.findIndex((p: Part) => p._id === data._id);
        this.partsSubject.next(parts.set(idx, data));
      })
    );

  create = (part: Part) =>
    this.backend.create(part).pipe(
      map((data) => this.remapPart(data)),
      tap((data) => {
        const parts = this.partsSubject.getValue();
        this.partsSubject.next(parts.unshift(data));
      })
    );

  delete = (part: Part) =>
    this.backend.delete(part).pipe(
      tap((part: Part) => {
        const parts = this.partsSubject.getValue();
        const idx = parts.findIndex((p: Part) => p._id === part._id);
        this.partsSubject.next(parts.splice(idx, 1));
      })
    );

  syncPart(id: string) {
    return this.backend.getById(id).pipe(
      map((part) => new Part(part)),
      map((part) => this.remapPart(part)),
      tap((part) => {
        const parts = this.partsSubject.getValue();
        const idx = parts.findIndex((p: Part) => p._id === id);
        if (idx > -1) {
          this.partsSubject.next(parts.set(idx, part));
        } else {
          this.partsSubject.next(parts.unshift(part));
        }
      })
    );
  }

  duplicate = (id: string[]) =>
    this.backend.duplicate(id).pipe(
      map((parts) => parts.map((part) => new Part(part))),
      map((parts) => parts.map(this.remapPart)),
      tap((parts) => {
        parts.forEach((part) => {
          this.partsSubject.next(this.partsSubject.getValue().unshift(part));
        });
      })
    );

  uploadPicture = (part, files) => {
    this.loader.add();
    return this.backend.uploadPicture(part, files).pipe(
      tap((data: Part) => {
        const parts = this.partsSubject.getValue();
        const idx = parts.findIndex((p: Part) => p._id === data._id);
        this.partsSubject.next(parts.set(idx, data));
        this.loader.remove();
      }),

      finalize(() => this.loader.remove())
    );
  };

  public remapPart(part) {
    if (part.warehouse && isObject(part.warehouse)) {
      part.warehouseObject = part.warehouse || null;
      part.warehouse = part.warehouseObject ? part.warehouseObject._id : null;
    }

    if (part.unit && isObject(part.unit)) {
      part.unitObject = part.unit || null;
      part.unit = part.unitObject ? part.unitObject._id : null;
    }
    return part;
  }

  getList() {
    return this.partsSubject.getValue();
  }

  formatCell = (col, field) => {
    switch (field.name) {
      case "image":
        return {
          ...col,
          cellRenderer: "fileRedirection",
        };
      case "updatedAt":
        return {
          ...col,
          valueFormatter: (params) =>
            params.data[field.name] &&
            this.commonService.getDateTime(params.data[field.name]),
          valueGetter: (params) =>
            params.data[field.name] &&
            this.commonService.getDateTime(params.data[field.name]),
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(
              nodeA && nodeA.data[field.name] ? nodeA.data[field.name] : null,
              nodeB && nodeB.data[field.name] ? nodeB.data[field.name] : null,
              nodeA,
              nodeB,
              isInverted
            );
          },
        };
      case "expiration":
        return {
          ...col,
          valueFormatter: (params) =>
            params.data[field.name] &&
            this.commonService.getDate(params.data[field.name]),
          valueGetter: (params) =>
            params.data[field.name] &&
            this.commonService.getDate(params.data[field.name]),
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(
              nodeA && nodeA.data[field.name] ? nodeA.data[field.name] : null,
              nodeB && nodeB.data[field.name] ? nodeB.data[field.name] : null,
              nodeA,
              nodeB,
              isInverted
            );
          },
        };
      default:
        return col;
    }
  };
}
