export class Translation{
    _id?: String;
    company?: String;
    name?: String;
    rtl?: Boolean;
    comments?: String;
    data?: any;

    constructor(options: {
        _id?: String,
        company?: String,
        name?: String,
        rtl?: Boolean,
        comments?: String,
        data?: any
    }){
        this._id = options._id || undefined;
        this.company = options.company || undefined;
        this.name = options.name || undefined;
        this.comments = options.comments || "";
        this.rtl = options.rtl || false;
        this.data = options.data || undefined;
    }
}