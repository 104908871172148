<div class="form-inline app-datetime">
  <div class="form-group input-group date" data-target-input="nearest" NgTempusdominusBootstrap>
    <input
           name="eventStart"
           autocomplete="off"
           NgTempusdominusBootstrapInput
           type="text" class="form-control"
           [(ngModel)]="dateModel"
           (ngModelChange)="onValChange($event)"
           [attr.readonly]="readonly"
           [attr.required]="required"
           [attr.placeholder]="dateOptions.placeholder || 'Choose date'"
           [attr.tabindex]="tabindex"
           [options]="dateOptions"
           (blur)="onTouched()"
           (keyup)="checkEmptyValue($event)"
    />

    <div class="input-group-append" NgTempusdominusBootstrapToggle>
      <div class="input-group-text"><i class="fas fa-calendar"></i></div>
    </div>
  </div>
  <div id="fld-start-time" class="form-group input-group date" data-target-input="nearest" NgTempusdominusBootstrap>
    <input
      name="eventStartTime"
      NgTempusdominusBootstrapInput
      type="text" class="form-control"
      [(ngModel)]="timeModel"
      (ngModelChange)="onValChange($event)"
      [options]="timeOptions"
      [attr.readonly]="readonly"
      [attr.required]="required"
      [attr.placeholder]="timeOptions.placeholder || 'Choose date'"
      [attr.tabindex]="tabindex"
      (blur)="onTouched()"
      (keyup)="checkEmptyValue($event)"
    />
    <div class="input-group-append" NgTempusdominusBootstrapToggle>
      <div class="input-group-text"><i class="fas fa-clock"></i></div>
    </div>
  </div>
</div>
<div>
  date: {{dateModel}} time: {{timeModel}}
</div>
