import { AbstractControl, ValidatorFn } from "@angular/forms";

const regex = /^(?=.*[@#$%^&+*\!=]).*$/;

const mustHaveSpecialCharacterValidator = (): ValidatorFn => {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const forbidden = mustHaveSpecialCharacter(control.value);
      return forbidden ? null : {mustHaveSpecialCharacter: {value: control.value}};
    };
  }

  
  const mustHaveSpecialCharacter = value => regex.test(value)

  export default {
    mustHaveSpecialCharacter,
    mustHaveSpecialCharacterValidator
  }