import { Component, OnInit, ViewChild } from "@angular/core";
import { SettingsService } from "../../../core/settings/settings.service";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { AuthService } from "../auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { LoaderService } from "../../../core/utils/loader.service";
import { finalize } from "rxjs/operators";
import { ToasterService } from "angular2-toaster";
import { TranslateService } from "@ngx-translate/core";
import { RecaptchaErrorParameters } from "ng-recaptcha";
import { CommonService } from "../../../core/services/common.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  valForm: UntypedFormGroup;
  errorMessage: string | null = null;
  showResendVerification: boolean = false;
  resendVerificationId: string = "";
  isPasswordShow: boolean = false;
  location: string = null;
  ip: string = null;
  fValue: any;
  public captchaResponse = "";
  @ViewChild("captchaRef") captchaRef;

  constructor(
    public settings: SettingsService,
    private translate: TranslateService,
    private userService: AuthService,
    private router: Router,
    fb: UntypedFormBuilder,
    private loader: LoaderService,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private toaster: ToasterService
  ) {
    this.getClockChrome().then((data: any) => {
      if (data) {
        this.ip = data.ip;
      }
    });
    this.valForm = fb.group({
      company: [
        localStorage.getItem("company") || null,
        Validators.compose([Validators.required]),
      ],
      username: [
        localStorage.getItem("user") || null,
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[a-zA-Z0-9-_]+$/),
        ]),
      ],
      remember: [localStorage.getItem("remember") || null],
      password: [null, Validators.required],
    });
    if (localStorage.getItem("remember") === "true") {
      this.router.navigate(["/main"]);
    }
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams["reason"]) {
      this.toaster.pop("error", queryParams["reason"]);
    }

    this.valForm.get("company").updateValueAndValidity();
  }

  handleOtpLogin = ($event: Event) => {
    $event.preventDefault();
    $event.stopPropagation();
    const that = this;
    const company = this.valForm.value.company;
    const username = this.valForm.value.username;
    if (company && username) {
      this.loader.add();
      this.userService
        .checkEligibleForLoginOtp({ company, username })
        .subscribe({
          next(data) {
            if(data) {
              that.userService
                .otpLogin({ company, username })
                .pipe(finalize(() => {}))
                .subscribe(
                  () => {
                    that.loader.remove();
                    if (that.userService.isOTPUser()) {
                      that.router.navigate(["/verify-otp"]);
                    }
                  },
                  (err) => {
                    that.loader.remove();
                    if (!(err instanceof HttpErrorResponse) || err.status !== 401) {
                      switch (err.error.status) {
                        case "blocked":
                          that.errorMessage =
                            "Company access has been blocked. Please contact customer service for more information";
                          break;
                        case "deleted":
                          that.errorMessage = "This user is deleted";
                          break;
                        case "login-attemp":
                          that.errorMessage = `Login is blocked for ${
                            err.error.minutes ? err.error.minutes : 1
                          } minutes. Please try again after.`;
                          break;
                        case "deactivated":
                          that.errorMessage =
                            "Company is inactive. Please contact customer service for more information";
                          break;
                        case "unverified":
                          that.errorMessage =
                            "Company not yet verified, please check your email and click the verify link";
                          that.showResendVerification = true;
                          that.resendVerificationId = err.error.id || "";
                          break;
                        default:
                          that.errorMessage = "Server error";
                          break;
                      }
                      return;
                    }
                    that.errorMessage = "Invalid username or password";
                  }
                );
            } else {
              that.toaster.pop(
                "error",
                "Login with OTP is not available for this user"
              );
              that.loader.remove();
            }
          },
          error() {
            that.toaster.pop(
              "error",
              "Something went wrong. Please try again later"
            );
            that.loader.remove();
          },
        })
    } else {
      if (!company)
        this.valForm.get("company").setErrors({ required: true });
      if (!username)
        this.valForm.get("username").setErrors({ required: true });
    }
  };

  public resolved(captchaResponse: string): void {
    this.submitData(this.fValue, captchaResponse);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    this.captchaResponse += `ERROR; error details (if any) have been logged to console\n`;
    this.errorMessage = errorDetails.join(",");
    this.loader.remove();
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  trimString = (value) => {
    // return value.replace(/[&\/\\#,+\-()!^;@$~%.'":*?<>{} ]/g, '').toLowerCase();
    return value.trim();
  };

  getClockChrome = async () => {
    let that = this;
    navigator.geolocation.getCurrentPosition(function (pos) {
      that.location = pos.coords.latitude + "," + pos.coords.longitude;
    });
    return new Promise(async (resolve, reject) => {
      let res = await fetch("https://api.ipify.org/?format=json");
      res = await res.json();
      resolve(res);
    });
  };

  submitForm($ev, value: any) {
    $ev.preventDefault();
    this.loader.add();
    this.captchaRef.execute();
    this.fValue = value;
  }

  submitData = (value, captcha) => {
    for (const c in this.valForm.controls) {
      if (this.valForm.controls.hasOwnProperty(c)) {
        this.valForm.controls[c].markAsTouched();
      }
    }
    if (this.valForm.valid) {
      this.showResendVerification = false;
      this.resendVerificationId = "";
      value.remember = value.remember ? "true" : "false";

      const login = {
        ...value,
        username: value.username.trim(),
        company: this.trimString(value.company),
        location: this.location,
        browser: navigator.userAgent,
        ip: this.ip,
        captcha: captcha,
      };
      this.userService
        .login(login, value.remember)
        .pipe(finalize(() => {}))
        .subscribe(
          (data) => {
            this.loader.remove();
            if (this.userService.isOTPUser()) {
              this.router.navigate(["/verify-otp"]);
            } else {
              localStorage.setItem("company", value.company);
              localStorage.setItem("user", login.username);
              localStorage.setItem("remember", login.remember);
              this.router.navigate(["/main"]);
            }
          },
          (err) => {
            this.loader.remove();
            if (!(err instanceof HttpErrorResponse) || err.status !== 401) {
              switch (err.error.status) {
                case "blocked":
                  this.errorMessage =
                    "Company access has been blocked. Please contact customer service for more information";
                  break;
                case "deleted":
                  this.errorMessage = "This user is deleted";
                  break;
                case "login-attemp":
                  this.errorMessage = `Login is blocked for ${
                    err.error.minutes ? err.error.minutes : 1
                  } minutes. Please try again after.`;
                  break;
                case "deactivated":
                  this.errorMessage =
                    "Company is inactive. Please contact customer service for more information";
                  break;
                case "unverified":
                  this.errorMessage =
                    "Company not yet verified, please check your email and click the verify link";
                  this.showResendVerification = true;
                  this.resendVerificationId = err.error.id || "";
                  break;
                default:
                  this.errorMessage = "Server error";
                  break;
              }
              return;
            }
            this.errorMessage = "Invalid username or password";
          }
        );
    } else {
      this.loader.remove();
    }
  };

  ngOnInit() {
    localStorage.removeItem("_sign_out_counter");
    localStorage.removeItem("_tab_open");
  }

  sendVerification() {
    this.userService.sendVerification(this.resendVerificationId).subscribe(
      () => {
        this.toaster.pop(
          "success",
          this.translate.instant("toasters.USER.OK_VERIFICATION_EMAIL_RESENT")
        );
        this.showResendVerification = false;
        this.resendVerificationId = "";
        this.errorMessage = "";
      },
      () => {
        this.toaster.pop(
          "error",
          this.translate.instant("toasters.USER.ERR_VERIFICATION_EMAIL_RESENT")
        );
      }
    );
  }

  showPassword = ($event) => {
    this.isPasswordShow = !this.isPasswordShow;
  };
}
