import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '../../interfaces/company';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SubscriptionPayment } from '../../interfaces/subscription-payment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPaymentsBackendService {

  url = '/api/subscription-payments/';

  constructor(private http: HttpClient) {
  }

  list(): Observable<SubscriptionPayment[]> {
    return this.http.get<SubscriptionPayment[]>(this.url).pipe(
      map(
        payments => payments.map(payment => new SubscriptionPayment(payment))
      )
    )
  }

  create(payment: SubscriptionPayment) {
    return this.http.post<SubscriptionPayment>(this.url, payment);
  }

  cancel(payment: SubscriptionPayment) {
    return this.http.post<SubscriptionPayment>(`${this.url}/${payment._id}/cancel`, {});
  }

  update(payment: SubscriptionPayment) {
    return this.http.put<SubscriptionPayment>(`${this.url}/${payment._id}`, payment);
  }

  sync() {
    return this.http.post(`${this.url}sync-payments`, {});
  }
}
