import { Injectable } from "@angular/core";
import { ColDef } from "ag-grid-community";
import { List } from "immutable";
import { BehaviorSubject, Observable, combineLatest, map, tap } from "rxjs";
import {
  Measurement,
  MeasurementChangeRow,
  MeasurementRow,
} from "../interfaces/measurement";
import { MeasurementsBackendService } from "./backend/measurements.backend.service";
import { AuthService } from "../../routes/user/auth.service";
import { CustomFieldsStoreService } from "./custom-fields-store.service";
import { TranslationsStoreService } from "./translations-store.service";
import FieldDefinition from "../constants/field-definitions/measurement";
import { isNumber, isObject } from "lodash";
import { CommonService } from "./common.service";
import moment, { Moment } from "moment-timezone";
import {
  LSL_KIND,
  MEASUREMENT_EDIT_TYPE,
  MEASUREMENT_STATUS,
  MEASUREMENT_TYPE,
  USL_KIND,
} from "../constants/enums/enums";
import { TranslateService } from "@ngx-translate/core";
import {
  MeasurementResult,
  MeasurementResultMaterial,
} from "../interfaces/measurement-result";
import { timeCalculation } from "../../shared/comparators/date-comparator";
import { ConfirmService } from "../utils/confirm/confirm.service";
import { ConfirmType } from "../constants/confirm-result.enum";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class MeasurementsStoreService {
  public columns$: Observable<ColDef[]>;
  private measurementsSubject: BehaviorSubject<List<Measurement>> =
    new BehaviorSubject(List([]));
  public readonly measurements$: Observable<List<Measurement>> =
    this.measurementsSubject.asObservable();

  public statusText = {
    [MEASUREMENT_STATUS.MISSING_VALUE]: this.translateService.instant(
      "measurements.MISSING-VALUE"
    ),
    [MEASUREMENT_STATUS.WARNING]: this.translateService.instant(
      "measurements.WARNING"
    ),
    [MEASUREMENT_STATUS.IN_TOLERANCE]: this.translateService.instant(
      "measurements.IN-TOLERANCE"
    ),
  };

  public frequencies = [
    {
      id: 30,
      text: "00:30",
    },
    {
      id: 60,
      text: "01:00",
    },
    {
      id: 90,
      text: "01:30",
    },
    {
      id: 120,
      text: "02:00",
    },
    {
      id: 150,
      text: "02:30",
    },
    {
      id: 180,
      text: "03:00",
    },
    {
      id: 210,
      text: "03:30",
    },
    {
      id: 240,
      text: "04:00",
    },
    {
      id: 480,
      text: "08:00",
    },
    {
      id: 1440,
      text: `24:00 (1 ${this.translateService.instant("general.DAY")})`,
    },
    {
      id: 2880,
      text: `48:00 (2 ${this.translateService.instant("general.DAYS")})`,
    },
    {
      id: 7200,
      text: `120:00 (5 ${this.translateService.instant("general.DAYS")})`,
    },
    {
      id: 10080,
      text: `168:00 (7 ${this.translateService.instant("general.DAYS")})`,
    },
    {
      id: 20160,
      text: `336:00 (14 ${this.translateService.instant("general.DAYS")})`,
    },
  ];

  public measurementTypes = [
    {
      id: MEASUREMENT_TYPE.AUTO_SCHEDULED,
      text: this.translateService.instant("measurements.AUTO-SCHEDULED"),
    },
    {
      id: MEASUREMENT_TYPE.MANUALLY,
      text: this.translateService.instant("measurements.MANUAL"),
    },
  ];

  constructor(
    private backend: MeasurementsBackendService,
    private authService: AuthService,
    private customFieldsService: CustomFieldsStoreService,
    private commonService: CommonService,
    private translateService: TranslateService,
    private confirmService: ConfirmService,
    private router: Router,
    private translationsStoreService: TranslationsStoreService
  ) {
    this.columns$ = combineLatest([
      this.authService.user$,
      this.translationsStoreService.languageCode$,
    ]).pipe(
      map(([user, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition;
        const col = this.customFieldsService.toColumnDef(defaults, {
          rowSelector: "id_num",
          format: this.formatCell.bind(this),
        });
        return col;
      })
    );
  }

  public list() {
    return this.backend.list().pipe(
      map((measurements) =>
        measurements.map((measurement: Measurement) =>
          this.remapMeasurement(measurement)
        )
      ),
      tap((measurements) => this.measurementsSubject.next(List(measurements)))
    );
  }

  public options() {
    return this.backend.options();
  }

  public get(id: string) {
    return this.backend.get(id);
  }

  public optionsByTool(id: string) {
    return this.backend.optionsByTool(id);
  }

  public listByTool(tools: string[]) {
    return this.backend.listByTool(tools);
  }

  public manualListByTool(tools: string[]) {
    return this.backend.manualListByTool(tools);
  }

  public manualMeasurements(toolId: string) {
    return this.backend.manualMeasurements(toolId);
  }

  public create(measurement: Measurement) {
    return this.backend.create(measurement).pipe(
      map((measurement) => this.remapMeasurement(measurement)),
      tap((measurement) => {
        this.measurementsSubject.next(
          this.measurementsSubject.getValue().push(measurement)
        );
      })
    );
  }

  public update(measurement: Measurement, measurementId: string) {
    return this.backend.update(measurement, measurementId).pipe(
      map((measurement) => this.remapMeasurement(measurement)),
      tap((measurement) => {
        const measurements = this.measurementsSubject.getValue();
        const index = measurements.findIndex((u) => u._id === measurementId);
        if (index > -1) {
          this.measurementsSubject.next(measurements.set(index, measurement));
        }
      })
    );
  }

  public delete(measurementId: string) {
    return this.backend.delete(measurementId).pipe(
      tap(() => {
        const measurements = this.measurementsSubject.getValue();
        const index = measurements.findIndex((u) => u._id === measurementId);
        if (index > -1) {
          const measurement = measurements.get(index);
          measurement.isDeleted = true;
          this.measurementsSubject.next(measurements.set(index, measurement));
        }
      })
    );
  }

  public duplicate = (id: string[]) =>
    this.backend.duplicate(id).pipe(
      map((measurements) => measurements.map(this.remapMeasurement)),
      tap((measurements) => {
        measurements.forEach((measurement) => {
          this.measurementsSubject.next(
            this.measurementsSubject.getValue().unshift(measurement)
          );
        });
      })
    );

  remapMeasurement = (measurement: Measurement) => {
    if (measurement.tool && isObject(measurement.tool)) {
      measurement.toolObject = measurement.tool;
      measurement.tool = measurement.tool._id;
    }
    if (measurement.unit && isObject(measurement.unit)) {
      measurement.unitObject = measurement.unit;
      measurement.unit = measurement.unit._id;
    }
    if (measurement.format && isObject(measurement.format)) {
      measurement.formatObject = measurement.format;
      measurement.format = measurement.format._id;
    }
    if (
      measurement.inventoryForFirstActionWithMaterial &&
      isObject(measurement.inventoryForFirstActionWithMaterial)
    ) {
      measurement.inventoryForFirstActionWithMaterialObject =
        measurement.inventoryForFirstActionWithMaterial;
      measurement.inventoryForFirstActionWithMaterial = measurement
        .inventoryForFirstActionWithMaterial._id as string;
    }
    if (
      measurement.inventoryForSecondActionWithMaterial &&
      isObject(measurement.inventoryForSecondActionWithMaterial)
    ) {
      measurement.inventoryForSecondActionWithMaterialObject =
        measurement.inventoryForSecondActionWithMaterial;
      measurement.inventoryForSecondActionWithMaterial = measurement
        .inventoryForSecondActionWithMaterial._id as string;
    }
    if (measurement.firstActionUnit && isObject(measurement.firstActionUnit)) {
      measurement.firstActionUnitObject = measurement.firstActionUnit;
      measurement.firstActionUnit = measurement.firstActionUnit._id;
    }
    if (
      measurement.secondActionUnit &&
      isObject(measurement.secondActionUnit)
    ) {
      measurement.secondActionUnitObject = measurement.secondActionUnit;
      measurement.secondActionUnit = measurement.secondActionUnit._id;
    }
    return measurement;
  };

  getReMeasurement = async (
    id: string,
    measurementResults: MeasurementResult[]
  ): Promise<MeasurementResult[]> => {
    return new Promise((resolve) => {
      const response = [];
      const childMeasurement = measurementResults.findIndex(
        (mr) => mr.parentMeasurementResult === id
      );
      if (childMeasurement > -1) {
        response.push(measurementResults[childMeasurement]);
        this.getReMeasurement(
          measurementResults[childMeasurement]._id,
          measurementResults
        ).then((res) => {
          response.push(...res);
          resolve(response);
        });
      } else {
        resolve(response);
      }
    });
  };

  getReMeasurementText = (
    material: MeasurementResultMaterial,
    bold: boolean = false,
    unit: string = null
  ) => {
    if (material) {
      return `${this.translateService.instant(
        "measurements.APPLY-MATERIAL-OF-INVENTORY",
        {
          inventory: material.name,
          value: bold
            ? `&nbsp;<strong>${material.amount}${
                unit ? ` ${unit}` : ""
              }</strong>&nbsp;`
            : ` ${material.amount} `,
        }
      )}${
        material.batch
          ? ` (${this.translateService.instant("measurements.BATCH", {
              name: material.batch,
            })})`
          : ""
      }`;
    }
    return null;
  };

  getReMeasurementTooltip = (time: string) => {
    return this.translateService.instant(
      "measurements.RE-MEASUREMENT-OF-DATE",
      {
        date: time,
      }
    );
  };

  getDuplicateTooltip = (time: string) => {
    return this.translateService.instant("measurements.DUPLICATE-OF-DATE", {
      date: time,
    });
  };

  constructMeasurementRecord = (
    measurement: Measurement,
    result: MeasurementResult | null,
    firstDate: Moment,
    timezone: string,
    parentDate = null,
    parentTime = null,
    old: MeasurementChangeRow[]
  ) => {
    const record = {
      _id: result ? result._id : null,
      measurement: measurement.name,
      measurementId: measurement._id,
      measurementNumber: measurement.id_num,
      frequency: measurement.frequency,
      time: result ? result.time : firstDate.format("HH:mm"),
      date: result
        ? new Date(result.date)
        : moment.tz(firstDate.format("YYYY-MM-DD HH:mm:ss"), timezone).toDate(),
      current: false,
      future: false,
      measuredValue: result ? result.measuredValue : null,
      value: result ? result.value : null,
      userAddedValue: result ? result.userAddedValue : null,
      updatedValue: result ? result.value : null,
      status: result ? result.status : MEASUREMENT_STATUS.MISSING_VALUE,
      operator:
        result && result.user && isObject(result.user)
          ? result.user.displayName
          : null,
      operatorImage:
        result && result.user && isObject(result.user)
          ? result.user.image
          : null,
      comment: result ? result.comment : null,
      updatedComment: result ? result.comment : null,
      target: measurement.target,
      lsl: measurement.lsl,
      usl: measurement.usl,
      lslKind: measurement.lslKind,
      uslKind: measurement.uslKind,
      lcl: measurement.lcl,
      ucl: measurement.ucl,
      lclKind: measurement.lclKind,
      uclKind: measurement.uclKind,
      format: measurement.format as string,
      isOutOfLclUcl: result ? result.isOutOfLclUcl : false,
      statusText: null,
      updatedAt: result ? result.updatedAt : null,
      edited: false,
      editedComment: false,
      editHours: measurement.editHours,
      type: measurement.type,
      hasError: false,
      formula: result ? result.formula : [],
      measuredFormula: measurement.measuredFormula || [],
      material: result && result.isResponseRequired ? result.material : null,
      isResponseRequired: result ? result.isResponseRequired : false,
      isChildRecord:
        result && !result.reMeasurementType
          ? !!result.parentMeasurementResult
          : false,
      reMeasurementType: result ? result.reMeasurementType : null,
      canEdited: true,
      showReMeasurement:
        result &&
        result.isResponseRequired &&
        !result.value &&
        !result.comment &&
        !isNumber(result.userAddedValue),
      reMeasurementText:
        result && !!result.parentMeasurementResult && parentDate && parentTime
          ? this.getReMeasurementTooltip(
              `${this.commonService.getDate(parentDate)} ${parentTime}`
            )
          : null,
      parentDateTime: parentDate ? new Date(parentDate) : null,
      duplicateText:
        result && result.reMeasurementType === 1
          ? this.getDuplicateTooltip(
              `${this.commonService.getDate(result.date)} ${result.time}`
            )
          : null,
      measurementComment: measurement.comment,
      lastMeasurementResult: measurement.lastMeasurementResult,
    };
    record.statusText = this.statusText[record.status];
    if (old && old.length > 0) {
      const mmIndex = old.findIndex((m) => {
        if (m._id) {
          return m._id === record._id;
        }
        return (
          record.measurementId === m.measurementId &&
          record.time === m.time &&
          moment(record.date).isSame(moment(m.date), "day")
        );
      });
      if (mmIndex > -1) {
        const m = old[mmIndex];
        record.updatedValue = m.updatedValue;
        if (!record.value) record.value = m.updatedValue;
        if(record.updatedComment !== m.updatedComment) record.editedComment = true;
        record.updatedComment = m.updatedComment;
      }
    }
    record.hasError = this.checkUslLsl(record);
    return record;
  };

  generateTimeRecords = async (
    measurements: Measurement[],
    old: MeasurementChangeRow[]
  ) => {
    const totalRecords = [];
    const timezone = this.commonService.getTimezone();
    const daysOff = this.commonService.getDaysOff();
    const todayDay = moment().tz(timezone).format("YYYY-MM-DD");
    const timeNow = moment().tz(timezone);
    const companyTime = this.commonService.getCompanyStartAndEndTime();
    const startTime = moment.tz(`${todayDay} ${companyTime.start}`, timezone);
    const endTime = moment.tz(`${todayDay} ${companyTime.end}`, timezone);
    for (let i = 0; i < measurements.length; i++) {
      const measurement = measurements[i];
      const measurementResult = measurement.measurementResults;
      const totalMinutes = measurement.frequency;
      const firstDateString = moment(measurement.createdAt)
        .subtract(3 * totalMinutes, "minutes")
        .startOf("day")
        .tz(timezone)
        .format("YYYY-MM-DD HH:mm");
      const firstDate = moment.tz(firstDateString, timezone);
      const startDate = moment()
        .subtract(3 * totalMinutes, "minutes")
        .tz(timezone)
        .startOf("day");
      const difference = startDate.diff(firstDate, "minutes");
      if (difference > 0) {
        const minutes = Math.floor(difference / totalMinutes);
        firstDate.add(minutes * totalMinutes, "minutes");
      }
      let endDate = moment()
        .tz(timezone)
        .add(4 * totalMinutes, "minutes");
      if (timeNow.isBefore(startTime)) {
        const difference = startTime.diff(firstDate, "minutes");
        if (difference > 0) {
          const minutes = Math.floor(difference / totalMinutes);
          firstDate.add(minutes * totalMinutes, "minutes");
          endDate = firstDate.clone().add(7 * totalMinutes, "minutes");
        }
      } else if (timeNow.isAfter(endTime)) {
        const difference = endTime.diff(firstDate, "minutes");
        if (difference > 0) {
          const minutes = Math.floor(difference / totalMinutes);
          firstDate.subtract(minutes * totalMinutes, "minutes");
        }
      }
      const records = [];
      const reMeasurements = {};
      const alreadyMappedMeasurements = [];
      let previousTime = null;
      let isCurrentTimeDefined = false;
      do {
        const time = firstDate.format("HH:mm");
        const mapTime = moment.tz(`${todayDay} ${time}`, timezone);
        if (
          !daysOff.includes(firstDate.format("dddd")) &&
          mapTime.isSameOrAfter(startTime) &&
          mapTime.isSameOrBefore(endTime)
        ) {
          const result = measurementResult.find(
            (result) =>
              result.time === time &&
              !result.parentMeasurementResult &&
              moment(result.date).isSame(firstDate, "day")
          );
          const record = this.constructMeasurementRecord(
            measurement,
            result,
            firstDate,
            timezone,
            null,
            null,
            old
          );
          const nextTimeIsGreaterThanCurrentTime = firstDate
            .clone()
            .add(totalMinutes, "minutes");
          if (
            previousTime &&
            previousTime.unix() <= moment().tz(timezone).unix() &&
            nextTimeIsGreaterThanCurrentTime.unix() >
              moment().tz(timezone).unix()
          ) {
            if (!isCurrentTimeDefined) {
              record.current = true;
              if (!record.value) {
                record.edited = true;
                record.editedComment = true;
              }
              isCurrentTimeDefined = true;
            } else {
              record.future = true;
            }
          }
          if (result) {
            alreadyMappedMeasurements.push(result._id);
            const mappedReMeasurement = [];
            const reMeasurement = await this.getReMeasurement(
              result._id,
              measurementResult
            );
            const isCurrent = record.current;
            for (let j = 0; j < reMeasurement.length; j++) {
              const isLast = j === reMeasurement.length - 1;
              const rm = reMeasurement[j];
              alreadyMappedMeasurements.push(rm._id);
              const reRecord = this.constructMeasurementRecord(
                measurement,
                rm,
                firstDate,
                timezone,
                record.date,
                record.time,
                old
              );
              if (isCurrent) {
                record.current = false;
                record.edited = false;
                record.editedComment = false;
                if (isLast && !reRecord.value) {
                  reRecord.current = true;
                  reRecord.edited = true;
                  reRecord.editedComment = true;
                }
              }
              mappedReMeasurement.push(reRecord);
            }
            reMeasurements[result._id] = mappedReMeasurement;
          }
          records.push(record);
        }
        previousTime = firstDate;
        firstDate.add(totalMinutes, "minutes");
      } while (firstDate.isBefore(endDate));
      const finalRecords = records.slice(Math.max(records.length - 7, 0));
      if (finalRecords.length > 0) {
        const startDate = moment().format("YYYY-MM-DD");
        const startTime = "00:00";
        const start = moment.tz(`${startDate} ${startTime}`, timezone);
        const lastRecord = finalRecords[finalRecords.length - 1];
        const endDate = moment(lastRecord.date).format("YYYY-MM-DD");
        const endTime = lastRecord.time;
        const end = moment.tz(`${endDate} ${endTime}`, timezone);
        const initialMeasurement = measurementResult.filter((result) => {
          const sDate = moment(result.date).format("YYYY-MM-DD");
          const s = moment.tz(`${sDate} ${result.time}`, timezone);
          return (
            !alreadyMappedMeasurements.includes(result._id) &&
            s.isSameOrAfter(start) &&
            s.isSameOrBefore(end) &&
            !result.parentMeasurementResult
          );
        });
        const addedMeasurement = [];
        for (let n = 0; n < initialMeasurement.length; n++) {
          const initMeasurement = initialMeasurement[n];
          alreadyMappedMeasurements.push(initMeasurement._id);
          const iMeasurement = this.constructMeasurementRecord(
            measurement,
            initMeasurement,
            start,
            timezone,
            null,
            null,
            old
          );
          const reMeasurement = await this.getReMeasurement(
            initMeasurement._id,
            measurementResult
          );
          if (reMeasurement.length > 0) {
            addedMeasurement.push(iMeasurement);
            for (let k = 0; k < reMeasurement.length; k++) {
              alreadyMappedMeasurements.push(initMeasurement._id);
              const rm = reMeasurement[k];
              const reRecord = this.constructMeasurementRecord(
                measurement,
                rm,
                firstDate,
                timezone,
                iMeasurement.date,
                iMeasurement.time,
                old
              );
              addedMeasurement.push(reRecord);
            }
          } else {
            addedMeasurement.push(iMeasurement);
          }
        }
        const mRecords = [];
        for (let r = 0; r < finalRecords.length; r++) {
          const finalRecord = finalRecords[r];
          const cRecord = [finalRecord].concat(
            reMeasurements[finalRecord._id] || []
          );
          mRecords.push(...cRecord);
        }
        const sortedRecords = [...mRecords, ...addedMeasurement].sort(
          (a, b) => {
            return (
              (a.parentDateTime || a.date).getTime() -
              (b.parentDateTime || b.date).getTime()
            );
          }
        );
        totalRecords.push(...sortedRecords);
      }
    }
    return totalRecords;
  };

  generateTimeRecordByMeasurementResult = (
    measurementResults: MeasurementResult[],
    manualMeasurements: Measurement[],
    old: MeasurementChangeRow[]
  ) => {
    const timezone = this.commonService.getTimezone();
    const totalRecords = [];
    const measurementIds = [];
    const timeNow = moment().tz(timezone).format("HH:mm");
    const dateNow = moment().tz(timezone).format("YYYY-MM-DD HH:mm");
    const daysOff = this.commonService.getDaysOff();
    const todayDay = moment().tz(timezone).format("YYYY-MM-DD");
    const companyTime = this.commonService.getCompanyStartAndEndTime();
    const startTime = moment.tz(`${todayDay} ${companyTime.start}`, timezone);
    const endTime = moment.tz(`${todayDay} ${companyTime.end}`, timezone);
    for (let i = 0; i < measurementResults.length; i++) {
      const measurementResult = measurementResults[i];
      const measurement =
        measurementResult.measurementObject &&
        isObject(measurementResult.measurementObject)
          ? measurementResult.measurementObject
          : null;
      const existedMaintenance = measurementResult.measurementObject
        ? manualMeasurements.find(
            (m) => m._id === measurementResult.measurementObject._id
          )
        : null;
      const dateString = moment(measurementResult.date)
        .tz(timezone)
        .format("YYYY-MM-DD HH:mm");
      let isCurrent = false;
      if (
        measurementResult.time === timeNow &&
        moment
          .tz(dateNow, timezone)
          .isSame(moment.tz(dateString, timezone), "day")
      ) {
        measurementIds.push(measurementResult.measurement);
        isCurrent = true;
      }
      const isChildRecord =
        !!measurementResult.childMeasurementResult &&
        !measurementResult.reMeasurementType;
      const hasParentRecord =
        !!measurementResult.parentMeasurementResult &&
        !measurementResult.reMeasurementType;
      const parentRecord = hasParentRecord
        ? measurementResults.find(
            (m) => m._id === measurementResult.parentMeasurementResult
          )
        : null;
      const isEditEnabled = isChildRecord
        ? isCurrent && !measurementResult.value && !measurementResult.comment
        : isCurrent;
      const record = {
        _id: measurementResult._id,
        measurement: measurement ? measurement.name : "",
        measurementId: (measurement ? measurement._id : measurement) as string,
        measurementNumber: measurement ? measurement.id_num : null,
        time: measurementResult.time,
        date: new Date(measurementResult.date),
        current: isCurrent,
        future: false,
        measuredValue: measurementResult.measuredValue,
        value: measurementResult.value,
        userAddedValue: measurementResult.userAddedValue,
        updatedValue: measurementResult.value,
        status: measurementResult.status,
        operator:
          measurementResult.user && isObject(measurementResult.user)
            ? measurementResult.user.displayName
            : null,
        operatorImage:
          measurementResult.user && isObject(measurementResult.user)
            ? measurementResult.user.image
            : null,
        comment: measurementResult.comment,
        updatedComment: measurementResult.comment,
        target: measurement ? measurement.target : null,
        lsl: measurement ? measurement.lsl : null,
        usl: measurement ? measurement.usl : null,
        lslKind: measurement ? measurement.lslKind : null,
        uslKind: measurement ? measurement.uslKind : null,
        lcl: measurement ? measurement.lcl : null,
        ucl: measurement ? measurement.ucl : null,
        lclKind: measurement ? measurement.lclKind : null,
        uclKind: measurement ? measurement.uclKind : null,
        format: measurement ? (measurement.format as string) : null,
        isOutOfLclUcl: measurementResult.isOutOfLclUcl,
        statusText: this.statusText[measurementResult.status],
        updatedAt: measurementResult.updatedAt,
        edited: isEditEnabled && !measurementResult.value,
        editedComment: isEditEnabled && !measurementResult.value,
        editHours: measurement ? measurement.editHours : null,
        type: measurement ? measurement.type : null,
        hasError: false,
        formula: measurementResult.formula || [],
        measuredFormula: measurement ? measurement.measuredFormula : [],
        material:
          measurementResult && measurementResult.isResponseRequired
            ? measurementResult.material
            : null,
        isResponseRequired: measurementResult.isResponseRequired,
        isChildRecord: hasParentRecord,
        reMeasurementType: measurementResult.reMeasurementType,
        canEdited: true,
        showReMeasurement:
          measurementResult &&
          measurementResult.isResponseRequired &&
          !measurementResult.value &&
          !measurementResult.comment &&
          !isNumber(measurementResult.userAddedValue),
        reMeasurementText:
          hasParentRecord && parentRecord
            ? this.getReMeasurementTooltip(
                `${this.commonService.getDate(parentRecord.date)} ${
                  parentRecord.time
                }`
              )
            : null,
        parentDateTime: parentRecord ? new Date(parentRecord.date) : null,
        duplicateText:
          measurementResult.reMeasurementType === 1
            ? this.getDuplicateTooltip(
                `${this.commonService.getDate(measurementResult.date)} ${
                  measurementResult.time
                }`
              )
            : null,
        measurementComment: measurement ? measurement.comment?.trim() : null,
        lastMeasurementResult:
          existedMaintenance && existedMaintenance.lastMeasurementResult
            ? existedMaintenance.lastMeasurementResult
            : null,
      };
      if (old && old.length > 0) {
        const mmIndex = old.findIndex((m) => {
          if (m._id) {
            return m._id === record._id;
          }
          return (
            record.measurementId === m.measurementId &&
            moment(record.date).isSame(moment(m.date), "day")
          );
        });
        if (mmIndex > -1) {
          const m = old[mmIndex];
          record.updatedValue = m.updatedValue;
          if (!record.value) record.value = m.updatedValue;
          if(record.updatedComment !== m.updatedComment) record.editedComment = true;
          record.updatedComment = m.updatedComment;
        }
      }
      record.hasError = this.checkUslLsl(record);
      totalRecords.push(record);
    }
    for (let i = 0; i < manualMeasurements.length; i++) {
      const measurement = manualMeasurements[i];
      const mapTime = moment.tz(`${todayDay} ${timeNow}`, timezone);
      if (
        !measurementIds.includes(measurement._id) &&
        !daysOff.includes(moment().tz(timezone).format("dddd")) &&
        mapTime.isSameOrAfter(startTime) &&
        mapTime.isSameOrBefore(endTime)
      ) {
        const record = {
          _id: null,
          measurement: measurement.name,
          frequency: measurement.frequency,
          measurementId: measurement._id,
          measurementNumber: measurement.id_num,
          time: timeNow,
          date: moment().tz(timezone).toDate(),
          current: true,
          future: false,
          measuredValue: null,
          value: null,
          userAddedValue: null,
          updatedValue: null,
          status: MEASUREMENT_STATUS.MISSING_VALUE,
          operator: null,
          operatorImage: null,
          comment: null,
          updatedComment: null,
          target: measurement.target,
          lsl: measurement.lsl,
          usl: measurement.usl,
          lslKind: measurement.lslKind,
          uslKind: measurement.uslKind,
          lcl: measurement.lcl,
          ucl: measurement.ucl,
          lclKind: measurement.lclKind,
          uclKind: measurement.uclKind,
          format: measurement.format as string,
          isOutOfLclUcl: false,
          statusText: this.statusText[MEASUREMENT_STATUS.MISSING_VALUE],
          updatedAt: null,
          edited: true,
          editedComment: true,
          editHours: measurement.editHours,
          type: measurement.type,
          hasError: false,
          formula: [],
          measuredFormula: measurement.measuredFormula || [],
          material: null,
          isResponseRequired: false,
          isChildRecord: false,
          reMeasurementType: 0,
          canEdited: true,
          showReMeasurement: false,
          reMeasurementText: null,
          parentDateTime: null,
          measurementComment: measurement.comment?.trim(),
          lastMeasurementResult: measurement.lastMeasurementResult,
        };
        if (old && old.length > 0) {
          const mmIndex = old.findIndex((m) => {
            if (m._id) {
              return m._id === record._id;
            }
            return (
              record.measurementId === m.measurementId &&
              moment(record.date).isSame(moment(m.date), "day")
            );
          });
          if (mmIndex > -1) {
            const m = old[mmIndex];
            record.updatedValue = m.updatedValue;
            if (!record.value) record.value = m.updatedValue;
            if(record.updatedComment !== m.updatedComment) record.editedComment = true;
            record.updatedComment = m.updatedComment;
          }
        }
        record.hasError = this.checkUslLsl(record);
        totalRecords.push(record);
      }
    }
    return totalRecords;
  };

  checkMeasurementCanBeUpdated = (measurementRow: MeasurementRow) => {
    if (
      measurementRow.editHours &&
      measurementRow.editHours === MEASUREMENT_EDIT_TYPE.NO_LIMIT
    ) {
      return true;
    } else if (
      measurementRow.editHours &&
      measurementRow.editHours === MEASUREMENT_EDIT_TYPE.NO_EDIT
    ) {
      return measurementRow.current;
    } else {
      const timezone = this.commonService.getTimezone();
      const hours = parseInt(measurementRow.editHours);
      const timeNowString = moment().tz(timezone).format("YYYY-MM-DD HH:mm:ss");
      const time = moment
        .tz(timeNowString, timezone)
        .subtract(hours, "hours")
        .unix();
      const dateOnly = moment(measurementRow.date)
        .tz(timezone)
        .format("YYYY-MM-DD");
      const timeOnly = measurementRow.time;
      const mDate = moment.tz(`${dateOnly} ${timeOnly}`, timezone).unix();
      return mDate >= time;
    }
  };

  checkUslLsl = (value: MeasurementRow) => {
    const numericValue = value && value.value ? Number(value.value) : 0;
    if (numericValue) {
      const lsl = value.lsl;
      const lslKind = value.lslKind;
      const usl = value.usl;
      const uslKind = value.uslKind;
      if (
        lsl &&
        lslKind &&
        usl &&
        uslKind &&
        ((numericValue <= lsl && lslKind === LSL_KIND.GREATER) ||
          (numericValue < lsl && lslKind === LSL_KIND.GREATER_EQUAL) ||
          (numericValue >= usl && uslKind === USL_KIND.LESSER) ||
          (numericValue > usl && uslKind === USL_KIND.LESSER_EQUAL))
      ) {
        return true;
      } else if (
        lsl &&
        lslKind &&
        ((numericValue <= lsl && lslKind === LSL_KIND.GREATER) ||
          (numericValue < lsl && lslKind === LSL_KIND.GREATER_EQUAL))
      ) {
        return true;
      } else if (
        usl &&
        uslKind &&
        ((numericValue >= usl && uslKind === USL_KIND.LESSER) ||
          (numericValue > usl && uslKind === USL_KIND.LESSER_EQUAL))
      ) {
        return true;
      }
    }
    return false;
  };

  checkUclLcl = (value: MeasurementRow) => {
    const numericValue = value && value.value ? Number(value.value) : 0;
    if (numericValue) {
      const lcl = value.lcl;
      const lclKind = value.lclKind;
      const ucl = value.ucl;
      const uclKind = value.uclKind;
      if (
        lcl &&
        lclKind &&
        ucl &&
        uclKind &&
        ((numericValue <= lcl && lclKind === LSL_KIND.GREATER) ||
          (numericValue < lcl && lclKind === LSL_KIND.GREATER_EQUAL) ||
          (numericValue >= ucl && uclKind === USL_KIND.LESSER) ||
          (numericValue > ucl && uclKind === USL_KIND.LESSER_EQUAL))
      ) {
        return true;
      } else if (
        lcl &&
        lclKind &&
        ((numericValue <= lcl && lclKind === LSL_KIND.GREATER) ||
          (numericValue < lcl && lclKind === LSL_KIND.GREATER_EQUAL))
      ) {
        return true;
      } else if (
        ucl &&
        uclKind &&
        ((numericValue >= ucl && uclKind === USL_KIND.LESSER) ||
          (numericValue > ucl && uclKind === USL_KIND.LESSER_EQUAL))
      ) {
        return true;
      }
    }
    return false;
  };

  toolLink(params) {
    if (params.rowData.toolObject && params.rowData.toolObject?.isDeleted) {
      this.confirmService
        .show(this.translateService.instant("confirm.TOOL.TOOL_DELETED"), {
          type: ConfirmType.CONFIRM_ONLY,
          confirmText: this.translateService.instant("shared.OK"),
          defaultBtnClass: "btn-danger",
        })
        .subscribe(() => {});
    } else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          `/main/maintenance/tool/${
            params.rowData && params.rowData.toolObject
              ? params.rowData.toolObject.id_num
              : ""
          }`,
        ])
      );
      window.open(url, "_blank");
    }
  }

  getMeasurementTypeText = (params) => {
    return this.measurementTypes.find((type) => type.id === params.data.type)
      ?.text;
  };

  getMeasurementEditHoursText = (params) => {
    const value = params.data.editHours;
    if (value === MEASUREMENT_EDIT_TYPE.NO_EDIT) {
      return this.translateService.instant("measurements.NO-EDIT");
    } else if (value === MEASUREMENT_EDIT_TYPE.NO_LIMIT) {
      return this.translateService.instant("measurements.NO-LIMIT");
    }
    return value;
  };

  getFrequencyText = (params) => {
    return params.data.frequency
      ? this.frequencies.find(
          (frequency) => frequency.id === params.data.frequency
        )?.text
      : null;
  };

  private getFormulaText(param, field) {
    if (param.data && param.data[field] && param.data[field].length > 0) {
      return param.data[field]
        .map((formula) => this.commonService.getTextFromFormula(formula))
        .join(" ");
    }
    return null;
  }

  private getInventoryName(part) {
    if (part) {
      return `${part.catalogNumber}${part.catalogNumber ? " | " : ""}${
        part.name
      }`;
    }
    return null;
  }

  private formatCell = (col, field) => {
    switch (field.name) {
      case "toolObject.name":
        return {
          ...col,
          cellRenderer: "customClick",
          cellRendererParams: {
            onClick: this.toolLink.bind(this),
            field: field.name,
          },
        };
      case "inventoryForFirstActionWithMaterialObject":
      case "inventoryForSecondActionWithMaterialObject":
        return {
          ...col,
          valueFormatter: (params) =>
            params.data[field.name] &&
            this.getInventoryName(params.data[field.name]),
          valueGetter: (params) =>
            params.data[field.name] &&
            this.getInventoryName(params.data[field.name]),
          tooltipValueGetter: (params) =>
            params.data[field.name] &&
            this.getInventoryName(params.data[field.name]),
        };
      case "formula":
      case "secondFormula":
      case "measuredFormula":
        return {
          ...col,
          valueFormatter: (params) => this.getFormulaText(params, field.name),
          valueGetter: (params) => this.getFormulaText(params, field.name),
          tooltipValueGetter: (params) =>
            this.getFormulaText(params, field.name),
        };
      case "type":
        return {
          ...col,
          valueFormatter: (params) => this.getMeasurementTypeText(params),
          valueGetter: (params) => this.getMeasurementTypeText(params),
        };
      case "frequency":
        return {
          ...col,
          valueFormatter: (params) => this.getFrequencyText(params),
          valueGetter: (params) => this.getFrequencyText(params),
        };
      case "editHours":
        return {
          ...col,
          valueFormatter: (params) => this.getMeasurementEditHoursText(params),
          valueGetter: (params) => this.getMeasurementEditHoursText(params),
        };
      case "updatedAt":
        return {
          ...col,
          valueFormatter: (params) =>
            params.data.updatedAt &&
            this.commonService.getDateTime(params.data.updatedAt),
          valueGetter: (params) =>
            params.data.updatedAt &&
            this.commonService.getDateTime(params.data.updatedAt),
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(
              nodeA && nodeA.data.updatedAt ? nodeA.data.updatedAt : null,
              nodeB && nodeB.data.updatedAt ? nodeB.data.updatedAt : null,
              nodeA,
              nodeB,
              isInverted
            );
          },
        };
      default:
        return col;
    }
  };
}
