import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmService } from '../../../core/utils/confirm/confirm.service';
import { ConfirmResult } from '../../../core/constants/confirm-result.enum';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';

@Component({
  selector: 'app-remove-text-editor',
  template: `<div style="display: flex; align-items: center;" [dir]="dir">
    <input class="form-control" type="text" [value]="value" [disabled]="true"/>
    <img src="assets/img/close.png" alt="remove" class="remove-image" (click)="handleRemoveProcedure($event)" *ngIf="value"/>
  </div>`,
  styles: [`
  .remove-image{
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
  div[dir="rtl"] .remove-image{
    right: unset;
    left: 20px;
  }
  `]
})

export class RemoveTextEditorComponent implements ICellEditorAngularComp {
  params;
  value = "";
  text;
  dir = "ltr";

  constructor(
    private translate: TranslateService,
    private confirm: ConfirmService,
    public translationService: TranslationsStoreService,
  ) {
  }

  agInit(params): void {
    this.params = params;
    if(this.params.colDef.section === "pm" && this.params.colDef.colId === "taskProcedure"){
      this.value = this.params.data.taskProcedure ? `${this.params.data.taskProcedure?.id_num} (${this.params.data.taskProcedure?.name})` : ''
    }
    this.translationService.dir$.subscribe((dir) => {
      this.dir = dir as string;
    })
  }

  isPopup(): boolean {
    return true;
  }

  handleRemoveProcedure = ($event) => {
    $event.stopPropagation();
    $event.preventDefault();
    this.confirm.show(
      this.translate.instant("toasters.EVENT.REMOVE-PROCEDURE-MESSAGE"),
      { 'confirmText': this.translate.instant('Delete'), 'declineText': this.translate.instant('Cancel'), 'isDelete': true }
    ).subscribe(
      result => {
        if (result === ConfirmResult.CONFIRM) {
          this.value = null;
        }
      }
    )
  }

  getValue(): any {
    return this.value;
  }
}