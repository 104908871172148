import { AbstractControl, ValidatorFn } from "@angular/forms";

const regex = /^(?=.*[0-9]).*$/;

const mustHaveNumberValidator = (): ValidatorFn =>{
    return (control: AbstractControl): {[key: string]: any} | null => {
      const forbidden = mustHaveNumber(control.value)
      return forbidden ? null : {mustHaveNumber: {value: control.value}}
    };
  }

  const mustHaveNumber = value => regex.test(value)

  export default{
    mustHaveNumber,
    mustHaveNumberValidator
  }