import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ICalendarEvent, NgAddToCalendarService } from '@trademe/ng-add-to-calendar';

@Component({
  selector: 'app-add-to-calendar',
  templateUrl: './add-to-calendar.component.html',
  styleUrls: ['./add-to-calendar.component.scss']
})
export class AddToCalendarComponent implements OnInit {

  @Input("label") label:string;
  @Input("calendarEvent") calendarEvent:ICalendarEvent;

  public googleCalendarEventUrl: SafeUrl;
  public iCalendarEventUrl: SafeUrl;
  public outlookCalendarEventUrl: SafeUrl;
  public newEvent: ICalendarEvent;

  constructor( private _addToCalendarService: NgAddToCalendarService,
    private _sanitizer: DomSanitizer) { }

  ngOnInit() {

    if (this.calendarEvent.start){
      this.googleCalendarEventUrl = this._sanitizer.bypassSecurityTrustUrl(
        this._addToCalendarService.getHrefFor(this._addToCalendarService.calendarType.google, this.calendarEvent)
      );
      this.iCalendarEventUrl = this._sanitizer.bypassSecurityTrustUrl(
        this._addToCalendarService.getHrefFor(this._addToCalendarService.calendarType.iCalendar, this.calendarEvent)
      );
      this.outlookCalendarEventUrl = this._sanitizer.bypassSecurityTrustUrl(
        this._addToCalendarService.getHrefFor(this._addToCalendarService.calendarType.outlook, this.calendarEvent)
      );
    }
}

  onClick = event => event.stopPropagation();
}
