import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MeasurementResult, MeasurementResultEvent } from "../../interfaces/measurement-result";
import { Part } from "../../interfaces/part";

@Injectable({
  providedIn: "root",
})
export class MeasurementResultsBackendService {
  url = "/api/measurement-results";
  constructor(private http: HttpClient) {}

  list() {
    return this.http.get<Array<MeasurementResult>>(`${this.url}`);
  }

  listByTool(toolId: string) {
    return this.http.get<Array<MeasurementResult>>(`${this.url}/${toolId}`);
  }

  relatedInventory(id: string) {
    return this.http.get<Array<Part>>(`${this.url}/related-inventory/${id}`);
  }

  resultsByEvent(id: string) {
    return this.http.get<Array<MeasurementResult>>(`${this.url}/results-by-event/${id}`);
  }

  listByToolManual(toolId: string) {
    return this.http.get<Array<MeasurementResult>>(
      `${this.url}/manual/${toolId}`
    );
  }

  measureAgain(id: string) {
    return this.http.post<MeasurementResult>(
      `${this.url}/measure-again/${id}`,
      {}
    );
  }

  addNewMeasurement(id: string) {
    return this.http.post<MeasurementResult>(
      `${this.url}/add-new-measurement/${id}`,
      {}
    );
  }

  openEvent(data: MeasurementResultEvent) {
    return this.http.post(
      `${this.url}/open-event`,
      data
    );
  }

  create(measurementResult: MeasurementResult) {
    return this.http.post<MeasurementResult>(`${this.url}`, measurementResult);
  }

  update(
    measurementResult: MeasurementResult,
    measurementResultId: string,
    isTable = false,
    isUserValue = false
  ) {
    return this.http.put<MeasurementResult>(
      isUserValue
        ? `${this.url}/user-value/${measurementResultId}`
        : isTable
        ? `${this.url}/table/${measurementResultId}`
        : `${this.url}/${measurementResultId}`,
      measurementResult
    );
  }

  delete(measurementResultId: string) {
    return this.http.delete(`${this.url}/${measurementResultId}`);
  }
}
