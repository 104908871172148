import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

  constructor() { }

  copy(content: any){
    return navigator.clipboard.writeText(content);
  }

  paste(){
    return navigator.clipboard.readText();
  }
}
