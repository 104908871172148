import { Component, OnInit, OnDestroy } from "@angular/core";
import { TranslationsStoreService } from "../../../core/services/translations-store.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { OpenaiStoreService } from "../../../core/services/openai-store.service";
import { LoaderService } from "../../../core/utils/loader.service";
import { ToasterService } from "angular2-toaster";
import { TranslateService } from "@ngx-translate/core";
import { CompaniesStoreService } from "../../../core/services/companies-store.service";
import { AuthService } from "../../../routes/user/auth.service";
import { PusherService } from "../../../core/services/pusher.service";
import { Company } from "../../../core/interfaces/company";
import { AiAssistantText } from "../../../core/interfaces/ai-assistant-text";
import { combineLatest } from "rxjs";
import { NgxFileDropEntry } from "ngx-file-drop";
import { CommonService } from "../../../core/services/common.service";
import { Tool } from "../../../core/interfaces/tool";
import { Subscription } from "rxjs";
import * as cheerio from "cheerio";
import { PartsStoreService } from "../../../core/services/parts-store.service";
import { ToolService } from "../../../routes/main/tool/tool.service";
import { ToolsStoreService } from "../../../core/services/tools-store.service";
const marked = require("marked");
const extendedTables = require("marked-extended-tables");

@Component({
  selector: "app-ai-maintenance-parts-assistant-modal",
  templateUrl: "./ai-maintenance-parts-assistant-modal.component.html",
  styleUrls: ["./ai-maintenance-parts-assistant-modal.component.scss"],
})
export class AiMaintenancePartsAssistantModalComponent
  implements OnInit, OnDestroy
{
  subscriptions: Subscription[] = [];
  tool: Tool = null;
  isPM: boolean = false;
  eventName: string = null;
  file: File;
  company: Company;
  maintenanceSimulatorResult: string = null;
  data = [];
  dataColumns = {
    catalogNumber: null,
    name: null,
    stockQuantity: null,
  };
  dataColumnsText = {
    catalogNumber: null,
    name: null,
    stockQuantity: null,
  };
  htmlBlocks = [];
  formSubmitted = false;

  constructor(
    public translationService: TranslationsStoreService,
    public bsModalRef: BsModalRef,
    private openaiStoreService: OpenaiStoreService,
    private loaderService: LoaderService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private companiesStoreService: CompaniesStoreService,
    private partsStoreService: PartsStoreService,
    private auth: AuthService,
    private pusherService: PusherService,
    private commonService: CommonService,
    private toolService: ToolService,
    private toolsStoreService: ToolsStoreService
  ) {}

  ngOnInit(): void {
    this.pusherService.channel.bind(
      "ai-assistant-maintenance",
      (data: { aIAssistantTextId: string; user: string; company: string }) => {
        if (data.user && data.user.toString() && this.auth.getId().toString()) {
          this.loaderService.remove();
          if (data.aIAssistantTextId) {
            this.openaiStoreService
              .aiAssistantText(data.aIAssistantTextId)
              .subscribe({
                next: async (result: AiAssistantText) => {
                  if (result?.text) {
                    this.processHtmlText(result.text);
                  } else {
                    this.toaster.pop(
                      "error",
                      this.translate.instant(
                        "toasters.GENERAL.SOMETHING-WENT-WRONG-WHILE-FETCHING-CHATGPT"
                      )
                    );
                  }
                },
                error: () => {
                  this.toaster.pop(
                    "error",
                    this.translate.instant(
                      "toasters.GENERAL.SOMETHING-WENT-WRONG-WHILE-FETCHING-CHATGPT"
                    )
                  );
                },
              });
          } else {
            this.toaster.pop(
              "error",
              this.translate.instant(
                "toasters.GENERAL.SOMETHING-WENT-WRONG-WHILE-FETCHING-CHATGPT"
              )
            );
          }
        }
      }
    ),
      this.subscriptions.push(
        combineLatest([
          this.auth.user$,
          this.companiesStoreService.companies$,
        ]).subscribe(([user, companies]) => {
          if (!user) return;
          const company = companies.find((c) => c._id === user.company._id);
          if (!company) return;
          this.company = company;
          this.checkForAiMaintenanceAssistance();
        })
      );
  }

  async processHtmlText(text: string) {
    this.formSubmitted = false;
    this.data = [];
    this.dataColumns = {
      catalogNumber: null,
      name: null,
      stockQuantity: null,
    };
    this.htmlBlocks = [];
    marked.use(extendedTables);
    this.maintenanceSimulatorResult = await marked.parse(text, {
      async: true,
    });
    const ch = cheerio.load(this.maintenanceSimulatorResult, null, false);
    const table = ch("table").html();
    if (table) {
      ch("table > thead > tr").each((i, el) => {
        const th = ch(el).find("th");
        if (th.length > 0) {
          th.each((i, el) => {
            const text = ch(el).text();
            if (text) {
              const lowerText = text?.toLowerCase()?.trim();
              if (lowerText.includes("catalog")) {
                this.dataColumns.catalogNumber = i;
                this.dataColumnsText.catalogNumber = text;
              } else if (
                lowerText.includes("name") ||
                lowerText.includes("description")
              ) {
                this.dataColumns.name = i;
                this.dataColumnsText.name = text;
              } else if (
                lowerText.includes("stock quantity") ||
                lowerText.includes("quantity")
              ) {
                this.dataColumns.stockQuantity = i;
                this.dataColumnsText.stockQuantity = text;
              }
            }
          });
          if (
            this.dataColumns.catalogNumber !== null &&
            this.dataColumns.name !== null &&
            this.dataColumns.stockQuantity !== null
          ) {
            ch("table > tbody > tr").each((i, el) => {
              const td = ch(el).find("td");
              if (td.length > 0) {
                const catalogNumber = ch(
                  td[this.dataColumns.catalogNumber]
                ).text();
                const name = ch(td[this.dataColumns.name]).text();
                const stockQuantity = ch(
                  td[this.dataColumns.stockQuantity]
                ).text();
                if (catalogNumber && name && stockQuantity) {
                  this.data.push({
                    catalogError: false,
                    checked: false,
                    catalogNumber: catalogNumber,
                    name: name,
                    stockQuantity: stockQuantity,
                  });
                }
              }
            });
            ch("table").replaceWith("TABLE_CONTENT_REMOVED");
            const remainingHtml = ch.html().toString();
            this.htmlBlocks = remainingHtml.split("TABLE_CONTENT_REMOVED");
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.pusherService.channel.unbind("ai-assistant-maintenance");
  }

  checkCatalogAlreadyExist() {
    const catalogNumbers = this.data
      .filter((d) => d.checked)
      .map((d) => d.catalogNumber);
    if (catalogNumbers.length > 0) {
      this.partsStoreService
        .checkCatalogNumbers(catalogNumbers)
        .subscribe((parts) => {
          if (parts.length > 0) {
            const existedCatalogNumbers = parts.map((p) => p.catalogNumber);
            for (const part of this.data) {
              part.catalogError = existedCatalogNumbers.includes(
                part.catalogNumber
              );
            }
          }
        });
    } else {
      for (const part of this.data) {
        part.catalogError = false;
      }
    }
  }

  onCheckChange(event, index: number) {
    this.data[index].checked = event.target.checked;
    this.checkCatalogAlreadyExist();
  }

  checkForAiMaintenanceAssistance() {
    if (
      !this.company ||
      !this.company?.aiMaintenanceAssistant?.spareParts?.start ||
      !this.company?.aiMaintenanceAssistant?.spareParts?.rule
    ) {
      this.toaster.pop(
        "error",
        this.translate.instant(
          "maintenance.START-OR-RULE-OR-FINISH-OR-EXTRACTION-RULE-NOT-FOUND"
        )
      );
      return false;
    }
    return true;
  }

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        if (this.commonService.checkAiAssistanceFile(file)) {
          this.file = file;
        }
      });
    }
  }

  viewMaintenanceFile = ($event: Event) => {
    $event.preventDefault();
    $event.stopPropagation();
    window.open(URL.createObjectURL(this.file), "_blank");
  };

  removeMaintenanceFile = ($event: Event) => {
    $event.preventDefault();
    $event.stopPropagation();
    this.file = null;
  };

  handleSubmit($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    const filteredData = this.data.filter((d) => d.checked);
    if (filteredData.length > 0) {
      this.formSubmitted = true;
      const hasError = filteredData.filter((d) => {
        return !d.catalogNumber || !d.name || !d.stockQuantity;
      });

      if (hasError.length > 0) {
        this.toaster.pop(
          "error",
          this.translate.instant("parts.PLEASE-FILL-REQUIRED-DATA-TO-ADD-PARTS")
        );
        return;
      }

      const catalogNumbers = filteredData.map((d) => d.catalogNumber);
      this.partsStoreService
        .checkCatalogNumbers(catalogNumbers)
        .subscribe((parts) => {
          if (parts.length > 0) {
            const existedCatalogNumbers = parts.map((p) => p.catalogNumber);
            this.toaster.pop(
              "error",
              this.translate.instant(
                "parts.PARTS-ALREADY-EXIST-WITH-CATALOG-NUMBER",
                {
                  catalogNumbers: existedCatalogNumbers.join(", "),
                }
              )
            );
          } else {
            const toolId = this.toolService.toolId$.getValue();
            const tool = toolId
              ? this.toolsStoreService.getToolByIdNum(toolId)
              : null;
            this.partsStoreService
              .createMultiple(filteredData, tool ? tool._id : null)
              .subscribe({
                next: () => {
                  this.toaster.pop(
                    "success",
                    this.translate.instant("parts.PARTS-ADDED-SUCCESSFULLY")
                  );
                  this.bsModalRef.hide();
                },
                error: () => {
                  this.toaster.pop(
                    "error",
                    this.translate.instant(
                      "toasters.GENERAL.SOMETHING-WENT-WRONG"
                    )
                  );
                },
              });
          }
        });
    } else {
      this.toaster.pop(
        "error",
        this.translate.instant("parts.PLEASE-SELECT-AT-LEAST-ONE-PART")
      );
    }
  }

  getAiMaintenanceAssistanceFromFile($event: Event) {
    $event.stopPropagation();
    $event.preventDefault();
    if (!this.file) {
      this.toaster.pop(
        "error",
        this.translate.instant("maintenance.FILE-NOT-FOUND")
      );
      return;
    }
    if (!this.checkForAiMaintenanceAssistance()) {
      return;
    }

    this.loaderService.add();
    const formData = new FormData();
    formData.append("file", this.file);
    this.openaiStoreService
      .aiMaintenancePartsAssistanceForFile(formData)
      .subscribe({
        next: () => {
          setTimeout(() => {
            this.loaderService.remove();
          }, 500000);
        },
        error: () => {
          this.loaderService.remove();
          this.toaster.pop(
            "error",
            this.translate.instant(
              "toasters.GENERAL.SOMETHING-WENT-WRONG-WHILE-FETCHING-CHATGPT"
            )
          );
        },
      });
  }
}
