const Home = {
  text: 'menu.DASHBOARD',
  link: '/root/dashboard',
  icon: 'icon-graph'
}

const MasterUser = {
  text: 'menu.MASTER-USER',
  heading: true
};

const UserAccess = {
  text: 'menu.COMPANIES',
  link: '/root/companies',
  icon: 'icon-people',

};

const Users = {
  text: 'menu.USERS',
  link: '/root/users',
  icon: 'icon-people',
};

const whatsAppNumbers = {
  text: 'menu.WHATSAPP-NUMBERS',
  link: '/root/whatsapp-numbers',
  icon: 'icon-people',
};

const userLoginLogs = {
  text: 'menu.USER-LOGIN-LOGS',
  link: '/root/user-login-logs',
  icon: 'icon-people',
};

const translationCorrections = {
  text: 'menu.TRANSLATION-CORRECTIONS',
  link: '/root/translation-corrections',
  icon: '',
};

const MasterTable = {
  text: 'menu.MASTER-TABLE',
  link: '/root/master-table',
  icon: 'icon-folder',
};

const globalSearch = {
  text: 'menu.GLOBAL-SEARCH',
  link: '/search/global',
  icon: '',
};

// const Subscriptions = {
//   text: 'menu.SUBSCRIPTIONS',
//   link: '/root/subscriptions',
//   icon: 'icon-folder',

// };

const RootTables = {
  text: 'menu.ROOT-TABLES',
  link: '/admin',
  icon: 'icon-settings',
  defaultOpen: false,
  submenu: [
    {
      text: 'menu.PM-KINDS',
      link: '/admin/pm-kinds',
      permissions: ['root'],
    },
    {
      text: 'menu.MACHINE-STATUS',
      link: '/admin/machine-status',
      permissions: ['root'],
    },
    {
      text: 'menu.TRANSLATION',
      link: '/admin/translation',
      permissions: ['root'],
    },
    {
      text: 'menu.DEPARTMENT',
      link: '/admin/departments',
      permissions: ['root'],
    },
  ],
};
const aiTables = {
  text: 'menu.AI',
  link: '/admin',
  icon: 'icon-settings',
  defaultOpen: false,
  submenu: [
    {
      text: 'menu.KEYWORDS',
      link: '/root/keywords',
      permissions: ['root'],
    },
    {
      text: 'menu.AI-HELPER',
      link: '/root/ai-helper',
      permissions: ['root'],
    },
    {
      text: 'menu.COLLABORATIVE-MAINTEANCE',
      link: '/root/collaborative-maintenance',
      permissions: ['root'],
    },
    {
      text: 'menu.NORMALIZED-NAMES-WITH-SUBSYSTEMS',
      link: '/root/names-subs',
      permissions: ['root'],
    },
    {
      text: 'menu.NORMALIZED-REPLACEMENT',
      link: '/root/normalized-replacement',
      permissions: ['root'],
    },
  ],
};

export default [
  Home,
  MasterUser,
  UserAccess,
  Users,
  whatsAppNumbers,
  translationCorrections,
  MasterTable,
  // Subscriptions,
  globalSearch,
  userLoginLogs,
  RootTables,
  aiTables,
]
