import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Translation } from '../../interfaces/translation';

@Injectable({
  providedIn: 'root'
})
export class TranslationsBackendService {

  url = '/api/translations';
  constructor(private http: HttpClient) { }

  list() {
    return this.http.get<Array<Translation>>(this.url)
  }

  get(name: string) {
    return this.http.get<Array<Translation>>(`${this.url}/language/${name}`)
  }

  listLanguages() {
    return this.http.get<Array<Translation>>(`${this.url}/list-languages`)
  }

  listAll() {
    return this.http.get<Array<Translation>>(`${this.url}?show=all`)
  }

  create(data: Translation) {
    return this.http.post(this.url, data);
  }

  update(data: Translation) {
    return this.http.put(`${this.url}/${data._id}`, data);
  }

  delete(data: Translation) {
    return this.http.delete(`${this.url}/${data._id}`)
  }

  generateCustomFields = () => this.http.post(`${this.url}/generate-custom-fields`, {})

  forceUpdatetoAllCompanies = (keys) => this.http.post(`${this.url}/force-update-to-all-companies`, keys)

  translateToNative = (values: string[], lang: string = '') => this.http.post(`${this.url}/translate-to-native`, { sentences: values, lang: lang })

}
