import { timeCalculation } from "../../../shared/comparators/date-comparator";
import { IField } from "../../interfaces/field";
import { procedureKind } from "../procedure-kind";

const FieldDefinition: IField[] = [
    {
        name: 'id_num',
        label: 'table.procedures.table-title.ID',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'company.name',
        label: 'table.procedures.table-title.COMPANY',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'name',
        label: 'table.procedures.table-title.NAME',
        type: 'textbox',
        required: false,
        readonly: false,
    },
    {
        name: 'kind',
        label: 'table.procedures.table-title.PROCEDURE-KIND',
        type: 'dropdown',
        options: procedureKind,
        required: false,
        readonly: false,
    },
    {
        name: 'tasks.id_num',
        label: 'table.procedures.table-title.TASK-ID',
        type: 'textbox',
        required: true,
        readonly: true,
        valueGetter: params => params.data && params.data.tasks && params.data.tasks.length ? params.data.tasks.filter(task => task.checklistItem.id_num).map(task => task.checklistItem.id_num).join(", ") : "",
        tooltipValueGetter: params => params.data && params.data.tasks && params.data.tasks.length ? params.data.tasks.filter(task => task.checklistItem.id_num).map(task => task.checklistItem.id_num).join(", ") : "",
    },
    {
        name: 'tasks.name',
        label: 'table.procedures.table-title.TASK-NAME',
        type: 'textbox',
        required: true,
        readonly: true,
        valueGetter: params => params.data && params.data.tasks && params.data.tasks.length ? params.data.tasks.filter(task => task.checklistItem.name).map(task => task.checklistItem.name).join(", ") : "",
        tooltipValueGetter: params => params.data && params.data.tasks && params.data.tasks.length ? params.data.tasks.filter(task => task.checklistItem.name).map(task => task.checklistItem.name).join(", ") : "",
    },
    {
        name: 'createdBy.displayName',
        label: 'table.procedures.table-title.DEFINED-BY',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'updatedAt',
        label: 'table.procedures.table-title.UPDATED-AT',
        type: 'datepicker',
        required: true,
        readonly: true,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.updatedAt ? nodeA.data.updatedAt : null, nodeB && nodeB.data.updatedAt ? nodeB.data.updatedAt : null, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'isDeleted',
        label: 'general.DELETED',
        type: "checkbox",
        readonly: false,
        isAdmin: true,
    }
]

export default {
    FieldDefinition
}