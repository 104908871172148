import { CommonService } from "./../../core/services/common.service";
import { Injectable } from "@angular/core";
import FileSaver from "file-saver";
import moment from "moment";
import * as XLSX from "xlsx";
import Excel from "exceljs";
import { isArray } from "lodash";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable({
  providedIn: "root",
})
export class ExcelService {
  constructor(private commonService: CommonService) {}

  public exportAsExcelFile(
    json: any[],
    excelFileName: string,
    sheet: string = "data"
  ): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {
      cellDates: true,
      dateNF: this.commonService.getDateFormat().toLowerCase(),
    });
    const workbook: XLSX.WorkBook = {
      Sheets: { [sheet]: worksheet },
      SheetNames: [sheet],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      type: "array",
      bookSST: true,
      compression: true
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelFileDateTime(
    json: any[],
    excelFileName: string,
    sheet: string = "data"
  ): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {
      cellDates: true,
      dateNF: this.commonService.getDateTimeFormat().toLowerCase(),
    });
    const workbook: XLSX.WorkBook = {
      Sheets: { [sheet]: worksheet },
      SheetNames: [sheet],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      type: "array",
      bookSST: true,
      compression: true
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_" + moment().format("DD-MM-YY_HH-mm-ss") + EXCEL_EXTENSION
    );
  }

  public excelExportToBlob(json: any[], sheet: string = "data") {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {
      cellDates: true,
      dateNF: this.commonService.getDateFormat().toLowerCase(),
    });
    const workbook: XLSX.WorkBook = {
      Sheets: { [sheet]: worksheet },
      SheetNames: [sheet],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      type: "array",
      bookSST: true,
      compression: true
    });
    const data: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE,
    });
    return data;
  }

  public excelExportToBase64(json: any[], sheet: string = "data") {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {
      cellDates: true,
      dateNF: this.commonService.getDateFormat().toLowerCase(),
    });
    const workbook: XLSX.WorkBook = {
      Sheets: { [sheet]: worksheet },
      SheetNames: [sheet],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      type: "base64",
      bookSST: true,
      compression: true
    });
    return excelBuffer;
  }

  public saveBlobAsExcelFile(blob: Blob, fileName: string): void {
    FileSaver.saveAs(
      blob,
      fileName + "_" + moment().format("DD-MM-YY_HH-mm-ss") + EXCEL_EXTENSION
    );
  }

  public excelMultipleExportToBlob(json: any[], sheet: string[]) {
    let sheets: any = {};
    const sheetNames = [];
    for (let i in sheet) {
      sheets[sheet[i]] = XLSX.utils.json_to_sheet(json[i], {
        cellDates: true,
        dateNF: this.commonService.getDateFormat().toLowerCase(),
      });
      sheetNames.push(sheet[i]);
    }
    const workbook: XLSX.WorkBook = { Sheets: sheets, SheetNames: sheetNames };
    const excelBuffer: any = XLSX.write(workbook, {
      type: "array",
      bookSST: true,
      compression: true
    });
    const data: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE,
    });
    return data;
  }

  public excelMultipleExportToBase64(json: any[], sheet: string[]) {
    let sheets: any = {};
    const sheetNames = [];
    for (let i in sheet) {
      sheets[sheet[i]] = XLSX.utils.json_to_sheet(json[i], {
        cellDates: true,
        dateNF: this.commonService.getDateFormat().toLowerCase(),
      });
      sheetNames.push(sheet[i]);
    }
    const workbook: XLSX.WorkBook = { Sheets: sheets, SheetNames: sheetNames };
    const excelBuffer: any = XLSX.write(workbook, {
      type: "base64",
      bookSST: true,
      compression: true
    });
    return excelBuffer;
  }

  public excelToSheetAgGrid(gridApi, headers: any[], sheetName: string) {
    const that = this;
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet("Sheet");
    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 1,
        visibility: "visible",
      },
    ];
    const columns = [];
    for (let i in headers) {
      if (headers[i].type === "datepicker") {
        columns.push({
          header: headers[i].headerName,
          key: headers[i].colId,
          style: {
            numFmt: this.commonService.getDateTimeFormat(),
          },
        });
      } else if (headers[i].type === "dateonlypicker") {
        columns.push({
          header: headers[i].headerName,
          key: headers[i].colId,
          style: { numFmt: this.commonService.getDateFormat() },
        });
      } else {
        columns.push({
          header: headers[i].headerName,
          key: headers[i].colId,
        });
      }
    }

    sheet.columns = columns;

    gridApi.forEachNodeAfterFilter((node) => {
      sheet
        .addRow(
          headers.reduce((acc, curr) => {
            if (!curr.field || !curr.headerName) return acc;
            let value: Date | string | number = "";
            if (["datepicker", "dateonlypicker"].includes(curr.type)) {
              if (
                typeof node.data.customFields != "undefined" &&
                node.data.customFields &&
                typeof node.data.customFields[curr.field] != "undefined"
              ) {
                value = isArray(node.data.customFields[curr.field])
                  ? node.data.customFields[curr.field].join(", ")
                  : node.data.customFields[curr.field];
              } else if (
                typeof node.data.pmCustomFields != "undefined" &&
                node.data.pmCustomFields &&
                typeof node.data.pmCustomFields[curr.field] != "undefined"
              ) {
                value = isArray(node.data.pmCustomFields[curr.field])
                  ? node.data.pmCustomFields[curr.field].join(", ")
                  : node.data.pmCustomFields[curr.field];
              } else if (curr.field.includes(".")) {
                const props = curr.field.split(".");
                value = props.reduce(
                  (acc, curr) => (acc ? acc[curr] : null),
                  node.data
                );
              } else {
                value = node.data[curr.field];
              }
              if (value) {
                if (curr.type === "dateonlypicker") {
                  value = new Date(this.commonService.getConvertTableDate(value));
                } else if (curr.type === "datepicker") {
                  value = new Date(this.commonService.getConvertTableDateTime(value));
                }
              }
            } else if (
              typeof node.data.customFields != "undefined" &&
              node.data.customFields &&
              typeof node.data.customFields[curr.field] != "undefined"
            ) {
              value = isArray(node.data.customFields[curr.field])
                ? node.data.customFields[curr.field].join(", ")
                : node.data.customFields[curr.field];
            } else if (
              typeof node.data.pmCustomFields != "undefined" &&
              node.data.pmCustomFields &&
              typeof node.data.pmCustomFields[curr.field] != "undefined"
            ) {
              value = isArray(node.data.pmCustomFields[curr.field])
                ? node.data.pmCustomFields[curr.field].join(", ")
                : node.data.pmCustomFields[curr.field];
            } else if (curr.valueFormatter) value = curr.valueFormatter(node);
            else if (curr.valueGetter) value = curr.valueGetter(node);
            else if (curr.field.includes(".")) {
              const props = curr.field.split(".");
              value = props.reduce(
                (acc, curr) => (acc ? acc[curr] : null),
                node.data
              );
            } else value = node.data[curr.field];
            if(value && curr.type === "timeonlypicker") value = this.commonService.getTime(value);
            return { ...acc, [curr.colId]: value };
          }, {})
        )
        .commit();
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      that.saveAsExcelFile(data, sheetName);
    });
  }
}
