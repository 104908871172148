import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Tool } from '../../../core/interfaces/tool';
import { List } from 'immutable';
import { ToolEvent } from '../../../core/interfaces/tool-event';
import { Maintenance } from '../../../core/interfaces/maintenance';
import { MaintenanceType } from '../../../core/interfaces/maintenance-type';
import { map, mergeMap, shareReplay } from 'rxjs/operators';
import { EventsStoreService } from '../../../core/services/events-store.service';
import { PreventiveStoreService } from '../../../core/services/preventive-store.service';
import { ToolsStoreService } from '../../../core/services/tools-store.service';
import { MaintenanceTypesStoreService } from '../../../core/services/maintenance-types-store.service';
import { CommonService } from '../../../core/services/common.service';
import { Router } from '@angular/router';
import { Part } from '../../../core/interfaces/part';
import { ConfirmService } from '../../../core/utils/confirm/confirm.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmResult, ConfirmType } from '../../../core/constants/confirm-result.enum';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EVENT_STATUS } from '../../../core/constants/enums/enums';
import { AuthService } from '../../user/auth.service';
import { GeneralService } from '../../../core/services/general.service';
import { PmCalenderStoreService } from '../../../core/services/pm-calender-store.service';
import { PartsStoreService } from '../../../core/services/parts-store.service';
import { MeasurementsStoreService } from '../../../core/services/measurements-store.service';
import { Measurement, MeasurementRow } from '../../../core/interfaces/measurement';
import { MeasurementResult } from '../../../core/interfaces/measurement-result';
import { MeasurementResultsStoreService } from '../../../core/services/measurement-results-store.service';
import { FilesStoreService } from '../../../core/services/files-store.service';
import { isObject } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ToolService {

  toolId$: BehaviorSubject<number> = new BehaviorSubject(0);
  tool$: Observable<Tool>;
  public eventHistorySubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public toolEventsFetchSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public toolEventsFetch$: Observable<boolean> = this.toolEventsFetchSubject.pipe(
    shareReplay(1)
  );
  public toolEventsSubject: BehaviorSubject<List<ToolEvent>> = new BehaviorSubject(List([]));
  public toolEvents$: Observable<List<ToolEvent>> = this.toolEventsSubject.pipe(
    map(parts => parts),
    shareReplay(1)
  );
  public toolPartsSubject: BehaviorSubject<List<Part>> = new BehaviorSubject(List([]));
  public toolParts$: Observable<List<Part>> = this.toolPartsSubject.pipe(
    map(parts => parts),
    shareReplay(1)
  );
  public partsSubject: BehaviorSubject<List<Part>> = new BehaviorSubject(List([]));
  public parts$: Observable<List<Part>> = this.partsSubject.pipe(
    map(parts => parts),
    shareReplay(1)
  );
  public measurementsSubject: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  public measurements$: Observable<Boolean> = this.measurementsSubject.pipe(
    shareReplay(1)
  );
  events$: Observable<List<ToolEvent>>;
  maints$: Observable<List<Maintenance>>;
  activeMaints$: Observable<List<Maintenance>>;
  maintenanceTypes$: Observable<List<MaintenanceType>>;
  notifications$: Observable<List<Maintenance>>;
  severity$: Observable<number>;
  public search$: BehaviorSubject<string>;
  measurements: Measurement[] = [];
  manualMeasurements: Measurement[] = [];
  measurementManual: MeasurementResult[] = [];
  generatedRecords: MeasurementRow[] = [];
  generatedManualRecords: MeasurementRow[] = [];
  interval

  constructor(public toolsService: ToolsStoreService,
    public eventsService: EventsStoreService,
    public commonService: CommonService,
    private confirm: ConfirmService,
    public preventiveService: PreventiveStoreService,
    private translate: TranslateService,
    public maintenanceTypesService: MaintenanceTypesStoreService,
    private modal: BsModalService,
    private authService: AuthService,
    private generalService: GeneralService,
    private pmCalenderStoreService: PmCalenderStoreService,
    private measurementsStoreService: MeasurementsStoreService,
    private measurementResultsStoreService: MeasurementResultsStoreService,
    private partsStoreService: PartsStoreService,
    private fileService: FilesStoreService,
    private router: Router) {

    this.search$ = new BehaviorSubject<string>('');

    // Load tool
    this.tool$ = this.toolId$.pipe(
      mergeMap(toolId => this.toolsService.getTool(toolId)),
    );

    combineLatest([this.toolsService.toolsUpdated, this.toolId$]).subscribe(([toolsUpdated, toolId]) => {
      const tools = this.toolsService.getToolList();
      if(toolId != 0 && tools.length > 0){
        const toolIndex = tools.findIndex(t => t.id_num == toolId);
        const tool = toolIndex > -1 ? tools[toolIndex] : null;
        if(!tool){
          this.router.navigate(['main', 'dashboard']);
        }else{
          if (tool && tool.isDeleted) {
            if(this.modal.getModalsCount() == 0){
              this.confirm.show(tool ? this.translate.instant('confirm.TOOL.TOOL_DELETED') : this.translate.instant('confirm.TOOL.TOOL_NOT_EXISTS'), { type: ConfirmType.CONFIRM_ONLY, confirmText: this.translate.instant('shared.OK'), defaultBtnClass: 'btn-danger' }).subscribe((result: ConfirmResult) => {
                setTimeout(() => {
                  this.router.navigate(['main', 'maintenance']);
                }, 500);
              })
            }
          } else if (tool && tool.isGroup) {
            if(this.modal.getModalsCount() == 0){
              this.confirm.show(this.translate.instant('confirm.TOOL.IS_NOT_TOOL'), { type: ConfirmType.CONFIRM_ONLY, confirmText: this.translate.instant('shared.OK'), defaultBtnClass: 'btn-danger' }).subscribe((result: ConfirmResult) => {
                setTimeout(() => {
                  this.router.navigate(['main', 'maintenance']);
                }, 500);
              })
            }
          }
        }
      }
    })

    this.toolId$.subscribe(t => {
      if(t){
        this.updateParts(t);
        this.updateEvents(t);
        this.fileService.load();
        this.toolsService.toolsUpdated.subscribe(() => {
          const tool = this.toolsService.getToolByIdNum(t);
          if(tool && !tool.isDeleted && this.commonService.hasMeasurementsPermission()){
            this.getMeasurements(tool._id);
            this.getActualManualMeasurements(tool._id);
            this.getManualMeasurements(tool._id);
          }
        });
      }
    })

    // Load maintenance schedule
    this.maints$ = combineLatest([this.tool$, this.preventiveService.maints$, this.pmCalenderStoreService.pmCalenders$]).pipe(
      map(([tool, maints, pmCalenders]) => maints.filter(maint => !maint.isDeleted && maint.tool && tool && ((!this.commonService.hasSubSystems() && (maint.tool._id === tool._id)) || (this.commonService.hasSubSystems() && (maint.tool._id === tool._id || maint.tool.tool === tool._id))))),
      shareReplay(1)
    );

    this.activeMaints$ = this.maints$.pipe(
      map(maints => maints.filter(maint => !maint.isDeleted)),
    );

    this.events$ = combineLatest([this.toolEvents$, this.maints$]).pipe(
      map(([events, maints]) => events.filter(e => !e.done && !e.isDeleted && (!e.maintenance || (maints.find(maint => maint._id === e.maintenance))) && this.generalService.checkEventToDisplay(e.status))),
    );

    const maintRemnant$ = combineLatest([this.events$, this.maints$]).pipe(
      map(([events, maints]) => maints.filter(m => !m.isFreeze && !m.isDeleted).filter(maint => !!events.find(event => maint._id === event.maintenance && maint.isDeleted)))
    )

    this.severity$ = combineLatest([this.tool$, this.activeMaints$, maintRemnant$, this.events$]).pipe(map(([tool, maints, remnants, events]) => {
      maints = maints.filter(m => !m.isFreeze && !m.isDeleted && m.showNotification(this.commonService.getCompany()));
      const maintsSeverity: any = maints.toArray().reduce((acc, curr) => Math.max(acc, Maintenance.getMaintenanceBadgeSeverity(curr)), 0);
      const remnantSeverity = remnants.toArray().reduce((acc, curr) => Math.max(acc, Maintenance.getMaintenanceBadgeSeverity(curr)), 0);
      const eventSeverity = events.toArray().filter(e => e.status === EVENT_STATUS.ACTIVE).reduce((acc, curr) => Math.max(acc, ToolEvent.getSeverity(curr)), 0);
      const severity = Math.max(maintsSeverity, remnantSeverity, eventSeverity);
      return severity > 0 ? severity : (events.filter(e => !e.maintenance && e.status === EVENT_STATUS.ACTIVE).count() > 0 ? 0 : null);
    }));

    this.maintenanceTypes$ = combineLatest([this.activeMaints$, this.maintenanceTypesService.maintenanceTypes$]).pipe(
      map(([maints, types]) => types.filter(t => !t.isDeleted))
    );

    // Get maintenance notifications
    // TODO: Should become events created automatically on backend
    this.notifications$ = combineLatest([this.activeMaints$, this.events$]).pipe(map(([maints, events]) => {
      const maintenanceIds = events.filter(e => e.maintenance).map(e => e.maintenance).toArray();
      return maints.filter(maint => !maint.isFreeze && !maint.isDeleted && maint.showNotification(this.commonService.getCompany()) && !maintenanceIds.includes(maint._id.toString()));
    }));
    // this.notifications$ = this.activeMaints$.pipe(
    //   map(maints => maints.filter(maint => !maint.isFreeze && !maint.isDeleted && maint.showNotification(this.commonService.getCompany()))))
  }

  setTool(toolId) {
    this.toolId$.next(toolId);
    this.toolEventsFetchSubject.next(false);
  }

  updatePartEvent = () => {
    if(this.toolId$.getValue()) {
      this.updateParts(this.toolId$.getValue());
    }
  }

  updateParts = (toolId:number) => {
    this.toolsService.getParts(toolId).subscribe((parts) => {
      this.toolPartsSubject.next(parts);
    })
    this.partsStoreService.options().subscribe((parts) => {
      this.partsSubject.next(List(this.partsStoreService.getOptionList()));
    });
  }
  updateEvents = (toolId:number) => {
    this.eventsService.getToolEvents(toolId).subscribe((events) => {
      this.toolEventsSubject.next(events);
      this.toolEventsFetchSubject.next(true);
    })
  }

  getMeasurements = (toolId:string) => {
    this.measurementsStoreService.listByTool([toolId]).subscribe({
      next: (measurements) => {
        this.measurements = measurements;
        this.generateRecords();
      },
    });
  }

  getActualManualMeasurements = (toolId:string) => {
    if(this.interval) clearInterval(this.interval);
    this.measurementsStoreService.manualListByTool([toolId]).subscribe({
      next: (measurements) => {
        this.manualMeasurements = measurements;
        if(this.manualMeasurements.length > 0) {
          this.generateManualRecords();
          this.interval = setInterval(() => {
            this.generateManualRecords();
          }, 30000);
        }
      },
    });
  }

  getManualMeasurements = (toolId:string) => {
    this.measurementResultsStoreService.listByToolManual(toolId).subscribe({
      next: (measurements) => {
        this.measurementManual = measurements;
        this.generateManualRecords();
      },
    });
  }

  generateRecords = async() => {
    this.generatedRecords = await this.measurementsStoreService.generateTimeRecords(this.measurements);
    this.measurementsSubject.next(true);
  }

  generateManualRecords = () => {
    this.generatedManualRecords = this.measurementsStoreService.generateTimeRecordByMeasurementResult(this.measurementManual, this.manualMeasurements);
    this.measurementsSubject.next(true);
  }

  updateMeasurementRecords = (result : MeasurementResult, value: MeasurementRow) => {
    const measurementId = isObject(result.measurement) && result.measurement._id ? result.measurement._id : result.measurement;
    const measurementIndex = this.measurements.findIndex((m) => m._id === measurementId);
    if (measurementIndex > -1) {
      const measurement = this.measurements[measurementIndex];
      const measurementResultIndex = measurement.measurementResults.findIndex((mr) => mr._id === result._id);
      if(measurementResultIndex > -1) {
        this.measurements[measurementIndex].measurementResults[measurementResultIndex] = result;
      } else {
        this.measurements[measurementIndex].measurementResults.push(result);
      }
      this.generateRecords();
    }
  }

  updateManualMeasurementRecords = (result : MeasurementResult) => {
    const measurementIndex = this.measurementManual.findIndex((m) => m._id === result._id);
    if (measurementIndex > -1) {
      this.measurementManual[measurementIndex] = result;
    } else {
      this.measurementManual.push(result);
    }
    this.generateManualRecords();
  }

  getMeasurementRecords = () => {
    return this.generatedRecords.concat(this.generatedManualRecords)
    .sort(
      (a, b) => {
        return (
          (a.parentDateTime || a.date).getTime() -
          (b.parentDateTime || b.date).getTime()
        );
      }
    );
  }

  updateMeasurementRecordsEdit = (value: MeasurementRow) => {
    const index = this.generatedRecords.findIndex((r) => r.measurementId === value.measurementId && r.time === value.time && (!value._id || r._id === value._id));
    if(index > -1){
      this.generatedRecords[index] = value;
    }
  }

  updateMeasurementManualRecordsEdit = (value: MeasurementRow) => {
    const index = this.generatedManualRecords.findIndex((r) => r.measurementId === value.measurementId && r.time === value.time && (!value._id || r._id === value._id));
    if(index > -1){
      this.generatedManualRecords[index] = value;
    }
  }

  getTool() {
    return this.toolId$.getValue();
  }

  getToolEvents() {
    return this.toolEventsSubject.getValue();
  }

  uploadPicture(files) {
    this.toolsService.uploadPicture(this.toolId$.getValue(), files);
  }


}
