<resize-border [dragHolder]="draggableHandle">
  <div [dir]="translationService.dir$ | async">
    <div class="card shadow-none parts_popup">
      <div class="card-header bb" #draggableHandle>
        <h2 class="w-fit-content" [ngClass]="!(translationService.rtl$ | async) ? 'mr-4' : ''">
          {{ "maintenance.AI-MAINTENANCE-ASSISTANCE" | translate }}
        </h2>
        <div class="modal-close">
          <img class="cursor-pointer" src="assets/img/close 2.svg" alt="img" (click)="bsModalRef.hide()" />
        </div>
      </div>
      <div class="card-body bt">
        <div class="form-group form-value">
          <label class="form-label no-changes" for="file">
            {{ "offsidebar.setting.FILE" | translate }}
          </label>
          <div class="t-presentation" *ngIf="file">
            <a href="#" (click)="viewMaintenanceFile($event)">
              <span class="font-italic link">{{ file?.name }}</span>
            </a>
            <a href="#" (click)="removeMaintenanceFile($event)">
              <img
                src="assets/img/close-back.svg"
                alt="remove"
                [ngClass]="(translationService.rtl$ | async) ? 'mr-2' : 'ml-2'"
                style="width: 15px"
              />
            </a>
          </div>
          <div class="d-flex align-item-center t-presentation">
            <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" multiple="false" class="w-100">
              <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <div class="text-center">
                  <div>
                    {{ "shared.DROP-FILE-HERE" | translate }}
                  </div>
                  <div>
                    <button type="button" class="btn btn-secondary base_btn" (click)="openFileSelector()">
                      +
                      {{ "maintenance.form-labels.ADD-FILE" | translate }}
                    </button>
                  </div>
                </div>
              </ng-template>
            </ngx-file-drop>
          </div>
        </div>
      </div>
      <div class="bt pt-2">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <button class="btn btn-primary blue_btn" [ngClass]="(translationService.rtl$ | async) ? '' : 'mr-3'"
                (click)="getAiMaintenanceAssistanceFromFile($event)">
                {{ "maintenance.GENERATE" | translate }}
              </button>
              <button class="btn btn-secondary base_btn" (click)="bsModalRef.hide()"
                [ngClass]="(translationService.rtl$ | async) ? '' : 'mr-3'">
                {{ "general.CLOSE" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</resize-border>

<ng-template #chatGPTNoResults>
  <div class="chatgpt-no-result">
    <img src="assets/img/chat-gpt-no-result.svg" />
    <p>
      {{ "offsidebar.setting.CHATGPT-NO-RESULT" | translate }}
    </p>
  </div>
</ng-template>