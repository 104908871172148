import {AfterViewInit, Component, ViewChild, ViewContainerRef} from "@angular/core";
import {ICellEditorAngularComp} from "ag-grid-angular";

@Component({
    selector: 'editor-cell',
    template: `
        <div #container class="search_select">
            <ngx-select
                [allowClear]="true"
                autocomplete="off"
                [items]="values"
                [placeholder]='"maintenance.form-labels.SELECT-PLACEHOLDER" | translate'
                [(ngModel)]="value"
                [disabled]="false"
                >
            </ngx-select>
        </div>
    `,
    styles: [`
        .search_select, .search_select .ngx-select{
            min-width: 280px;
        }
        @media (max-width:1199px){
            .search_select, .search_select .ngx-select{
                min-width: 212px;
            }
        }
        .search_select ngx-select{
            width: 100%;
        }
        :host ::ng-deep a.ngx-select__clear.btn.btn-link{
            display:none;
        }
        .search_select .ngx-select__search{
            min-width: 280px;
        }
        @media (max-width:1199px){
            .search_select .ngx-select__search{
                min-width: 212px;
            }
        }
    `]
})
export class DynamicDropdownEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public values: any;
    public value: any;
    public model: any;
    public disable: any;
    public change: Function = null;
    @ViewChild('container', {read: ViewContainerRef}) public container;
    public happy: boolean = false;

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {

    }

    agInit(params: any,): void {
        this.params = params;
        if(params.values && params.values.length > 0 && typeof params.values[0].company != 'undefined' && params.data.company){
            this.params.values = params.values.filter(val => val.company && val.company._id == params.data.company._id).map(opt => opt.value);
        }
        if(params.machineStatusValues && params.machineStatusValues.length > 0 && typeof params.machineStatusValues[0].company != 'undefined' && params.data.company){
            this.params.machineStatusValues = params.machineStatusValues.filter(val => val.company && val.company._id == params.data.company._id).map(opt => opt.value);
        }
        this.params.machineStatusValues = this.params.machineStatusValues.filter(m => !m.isDeleted);
        this.params.values = this.params.values.filter(m => !m.isDeleted);
        if(typeof params.data['eventType'] != 'undefined'){
            if(params.data['eventType'] == 'PM'){
                this.values = this.params.values;
            }else{
                this.values = this.params.machineStatusValues;
            }
        }else{
            if(params.data.maintenance){
                this.values = this.params.values;
            }else{
                this.values = this.params.machineStatusValues;
            }
        }
        this.value = params.data[params.colDef.field];
        this.change = params.onChange || null;
    }

    getValue(): any {
        return this.value;
    }

    isPopup(): boolean {
        return true;
    }
}