import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { ICellEditorAngularComp } from "ag-grid-angular";
import { BsModalService } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { ModalResult } from "../../models/modal-result";
import { PermissionsComponent } from "../permissions/permissions.component";

@Component({
    selector: 'editor-cell',
    template: `
    `,
    styles: [`
    `]
})
export class MultiselectEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public value: any[];
    public model: any[];
    public disable: any[];
    @ViewChild('container', { read: ViewContainerRef }) public container;
    public happy: boolean = false;

    constructor(
        private translate: TranslateService,
        private modal: BsModalService,
    ) {
    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
    }

    agInit(params: any): void {
        this.params = params;
        this.model = params.values.map(val => {
            return { ...val, disabled: !!val.disabled, value: this.translate.instant(val.value) }
        })
        this.value = this.params.data[this.params.colDef.colId];
        const observable = new Observable<ModalResult>(observer => {
            this.modal.show(
                PermissionsComponent,
                {
                    initialState: {
                        values: this.model,
                        user: this.params.data,
                        column: this.params.colDef.colId,
                        observer: observer,
                    },
                    class: "modal-lg event-modal-content modal-full modal-big-width-on-small"
                }
            )
          });

          observable.subscribe((result: ModalResult) => {
            if (result.result) {
                this.value = result.payload[this.params.colDef.colId];
                this.params.api.stopEditing();
            }
        })
    }

    getValue(): any {
        return this.value;
    }

    isPopup(): boolean {
        return true;
    }

    setHappy(happy: boolean): void {
        // this.happy = happy;
    }

    toggleMood(): void {
        // this.setHappy(!this.happy);
    }

    onClick(happy: boolean) {
        // this.setHappy(happy);
        // this.params.api.stopEditing();
    }

    onKeyDown(event): void {
        // let key = event.which || event.keyCode;
        // if (key == 37 ||  // left
        //     key == 39) {  // right
        //     this.toggleMood();
        //     event.stopPropagation();
        // }
    }
}