import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BodyOutputType, Toast, ToasterService } from 'angular2-toaster';
import { keyBy, uniqBy } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observer } from 'rxjs';
import { eventNumberRange, historyAtNumberRange, inventoryNumberRange, pmNumberRange, reportRangeSectionKeywords, reportSection, reportSectionKeywords, signatureAtNumberRange, slaNumberRange, taskTransactionNumberRange, toolNumberRange, transactionNumberRange, userLogsNumberRange } from '../../../core/constants/report-section';
import { CommonService } from '../../../core/services/common.service';
import { CustomSmartDropdownService } from '../../../core/services/custom-smart-dropdown.service';
import { MenuStoreService } from '../../../core/services/menu-store.service';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';
import { DynamicReportService } from '../../../routes/smart-report/dynamic-report/dynamic-report.service';
import { AuthService } from '../../../routes/user/auth.service';

@Component({
  selector: 'app-add-new-report',
  templateUrl: './add-new-report.component.pug',
  styleUrls: ['./add-new-report.component.scss']
})
export class AddNewReportComponent implements OnInit {

  observer: Observer<any>
  form: any;
  lightScrollbarOptions = this.commonService.getLightScrollbarOptions();
  sections: any;
  keywords: any;
  fields: any = [];
  isEdit:boolean = false;
  menu:any;
  searchReport:any;
  sectionTitle:string = '';

  constructor(
    public bsModalRef: BsModalRef,
    private commonService: CommonService,
    private translate: TranslateService,
    private toaster: ToasterService,
    public authService: AuthService,
    public translationService: TranslationsStoreService,
    private menuStoreService: MenuStoreService,
    private dynamicReportService: DynamicReportService,
    public customSmartDropdownService: CustomSmartDropdownService,
    private router: Router,
  ) { }

  ngOnInit() {

    this.sections = Object.keys(reportSection).reduce((acc, key) => {
      acc.push({ id: key, text: this.translate.instant(reportSection[key]) });
      return acc;
    }, []);
    this.keywords = Object.keys(reportSectionKeywords).reduce((acc, key) => {
      acc.push({ id: key, text: this.translate.instant(reportSectionKeywords[key]) });
      return acc;
    }, []);

    if (!this.form) {
      this.form = {
        name: '',
        reportType: 'fields',
        qry: '',
        table: '',
        row: 200,
        search: [{
          field: '',
          type: 'none',
          text: '',
          secondText: '',
        }],
      }
    } else {
      this.tableChange(this.form.table);
    }

    if(this.searchReport && this.searchReport.criteria){
      this.form = this.searchReport.criteria;
      this.form.name = this.menu.name;
      this.form.reportType = this.searchReport.reportType || 'fields';
      if(this.searchReport.qry) this.form.qry = JSON.parse(this.searchReport.qry);
      this.tableChange(this.form.table);
    }
  }


  tableChange = async (table) => {
    this.fields = await this.customSmartDropdownService.getCustomColumnName(table);
  }

  addSearch = ($event) => {
    this.form.search.push({
      field: '',
      type: 'none',
      text: '',
      secondText: '',
    });
  }

  updateSearch = (index, item) => {
    this.form.search[index] = item;
  }

  removeSearch = ($event, index) => {
    this.form.search.splice(index, 1);
  }

  omitSpecialString = ($event) => {
    var key = String.fromCharCode(!$event.charCode ? $event.which : $event.charCode);
    if (!/^[a-zA-Z0-9-_ ]+$/.test(key)) {
      $event.preventDefault();
      return false;
    }
  }

  save = async() => {
    var regExp = /[a-zA-Z]/g;
    let errors = [];
    if(!regExp.test(this.form.name)) errors.push(this.translate.instant("ADD-NEW-REPORT.REPORT-NAME-MUST-CONTAIN-STRING-CHARACTER"))
    if (!this.form.name) errors.push(this.translate.instant("ADD-NEW-REPORT.REPORT-NAME-IS-REQUIRED"))
    if (!this.form.table) errors.push(this.translate.instant("ADD-NEW-REPORT.TABLE-NAME-IS-REQUIRED"))
    if(this.form.reportType == 'query'){
      if (!this.form.qry) errors.push("Query is required.")
    }else{
      if (!this.form.row) errors.push(this.translate.instant("ADD-NEW-REPORT.MAX-ROWS-QUANTITY-IS-REQUIRED"))
      const fields = keyBy(this.fields, 'id');
      this.form.search.forEach((element) => {
        if (!element.field) errors.push(this.translate.instant("ADD-NEW-REPORT.COLUMN-IS-REQUIRED"))
        if (element.type == 'range') {
          if (!element.text) errors.push(this.translate.instant("ADD-NEW-REPORT.SEARCH-FIRST-PARAMETER-IS-REQUIRED"))
          if (!element.secondText) errors.push(this.translate.instant("ADD-NEW-REPORT.SEARCH-SECOND-PARAMETER-IS-REQUIRED"))
        } else if (element.type != 'none') {
          if (!element.text) errors.push(this.translate.instant("ADD-NEW-REPORT.SEARCH-PARAMETER-IS-REQUIRED"))
        }

        switch (this.form.table) {
          case 'tool':
            if (reportRangeSectionKeywords.includes(element.type) && !toolNumberRange.includes(element.field)) errors.push(this.translate.instant("ADD-NEW-REPORT.FIELD-SHOULD-NOT-USE-FILTER", {field: fields[element.field] ? fields[element.field].text : null }));
            break;
          case 'pm':
            if (reportRangeSectionKeywords.includes(element.type) && !pmNumberRange.includes(element.field)) errors.push(this.translate.instant("ADD-NEW-REPORT.FIELD-SHOULD-NOT-USE-FILTER", {field: fields[element.field] ? fields[element.field].text : null }));
            break;
          case 'history':
            if (reportRangeSectionKeywords.includes(element.type) && !eventNumberRange.includes(element.field)) errors.push(this.translate.instant("ADD-NEW-REPORT.FIELD-SHOULD-NOT-USE-FILTER", {field: fields[element.field] ? fields[element.field].text : null }));
            break;
          case 'inventory':
            if (reportRangeSectionKeywords.includes(element.type) && !inventoryNumberRange.includes(element.field)) errors.push(this.translate.instant("ADD-NEW-REPORT.FIELD-SHOULD-NOT-USE-FILTER", {field: fields[element.field] ? fields[element.field].text : null }));
            break;
          case 'transaction':
            if (reportRangeSectionKeywords.includes(element.type) && !transactionNumberRange.includes(element.field)) errors.push(this.translate.instant("ADD-NEW-REPORT.FIELD-SHOULD-NOT-USE-FILTER", {field: fields[element.field] ? fields[element.field].text : null }));
            break;
          case 'task-transaction':
            if (reportRangeSectionKeywords.includes(element.type) && !taskTransactionNumberRange.includes(element.field)) errors.push(this.translate.instant("ADD-NEW-REPORT.FIELD-SHOULD-NOT-USE-FILTER", {field: fields[element.field] ? fields[element.field].text : null }));
            break;
          case 'user-logs':
            if (reportRangeSectionKeywords.includes(element.type) && !userLogsNumberRange.includes(element.field)) errors.push(this.translate.instant("ADD-NEW-REPORT.FIELD-SHOULD-NOT-USE-FILTER", {field: fields[element.field] ? fields[element.field].text : null }));
            break;
          case 'signature-at':
            if (reportRangeSectionKeywords.includes(element.type) && !signatureAtNumberRange.includes(element.field)) errors.push(this.translate.instant("ADD-NEW-REPORT.FIELD-SHOULD-NOT-USE-FILTER", {field: fields[element.field] ? fields[element.field].text : null }));
            break;
          case 'history-at':
            if (reportRangeSectionKeywords.includes(element.type) && !historyAtNumberRange.includes(element.field)) errors.push(this.translate.instant("ADD-NEW-REPORT.FIELD-SHOULD-NOT-USE-FILTER", {field: fields[element.field] ? fields[element.field].text : null }));
            break;
          case 'sla':
            if (reportRangeSectionKeywords.includes(element.type) && !slaNumberRange.includes(element.field)) errors.push(this.translate.instant("ADD-NEW-REPORT.FIELD-SHOULD-NOT-USE-FILTER", {field: fields[element.field] ? fields[element.field].text : null }));
            break;
          default:
            break;
        }
      });
    }

    if (errors.length == 0) {
      this.form.search = uniqBy(this.form.search, 'field');
      if(this.isEdit){
        this.menuStoreService.update({ ...this.form, _id: this.menu._id }).subscribe(
          (res:any) => {
            this.bsModalRef.hide();
            this.menuStoreService.load().subscribe(() => {
              setTimeout(() => {
                this.dynamicReportService.key$.next(res.key);
                this.router.navigate(['/smart-report', res.key]);
                this.toaster.pop('success', this.translate.instant("toasters.GENERAL.OK_SAVE"));
              }, 250);
            });
          },
          (err) => {
            this.toaster.pop('error', this.translate.instant("toasters.GENERAL.ERR_SAVE"));
          },
          () => {
          }
        )
      }else{
        this.menuStoreService.create({ ...this.form, type: 2 }).subscribe(
          () => {
            this.bsModalRef.hide();
            this.toaster.pop('success', this.translate.instant("SMART-DROPDOWN.MENU-CREATED-SUCCESSFULLY"));
          },
          (err) => {
            this.toaster.pop('error', this.translate.instant("SMART-DROPDOWN.SOMETHING-WENT-WRONG-WHILE-ADDING-MENU"));
          },
          () => {
          }
        )
      }
    } else {
      const toast: Toast = {
        type: 'error',
        title: '',
        body: errors.join(' <br/> '),
        bodyOutputType: BodyOutputType.TrustedHtml,
        timeout: 5000
      };
      this.toaster.pop(toast);
    }
  }
}
