import { IField } from "../../interfaces/field";
import { userPermissions } from '../user-permissions';
const FieldDefinition: IField[] = [
    {
        name: 'id_num',
        label: 'general.ID',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'user-logs'
    },
    {
        name: 'user.username',
        label: 'table.users.table-title.USERNAME',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'user-logs'
    },
    {
        name: 'login',
        label: 'table.user-logs.table-title.LOGIN',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'user-logs'
    },
    {
        name: 'loginType',
        label: 'table.user-logs.table-title.LOGIN-TYPE',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'user-logs'
    },
    {
        name: 'logout',
        label: 'table.user-logs.table-title.LOGOUT',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'user-logs'
    },
    {
        name: 'logoutType',
        label: 'table.user-logs.table-title.LOGOUT-TYPE',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'user-logs'
    },
    {
        name: 'ip',
        label: 'table.user-logs.table-title.IP',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'user-logs'
    },
    {
        name: 'browser',
        label: 'table.user-logs.table-title.BROWSER',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'user-logs'
    },
    {
        name: 'location',
        label: 'table.user-logs.table-title.LOCATION',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'user-logs'
    },
    {
        name: 'user.email',
        label: 'table.users.table-title.EMAIL',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'user-logs'
    },
    {
        name: 'user.firstName',
        label: 'table.users.table-title.FIRST-NAME',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'user-logs'
    },
    {
        name: 'user.lastName',
        label: 'table.users.table-title.LAST-NAME',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'user-logs'
    },
    {
        name: 'user.phone',
        label: 'table.users.table-title.PHONE',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'user-logs'
    },
    {
        name: 'user.roles',
        label: 'table.users.table-title.PERMISSIONS',
        type: 'multiselect',
        options: userPermissions,
        required: false,
        readonly: true,
        section: 'user-logs'
    },
    {
        name: 'user.noEmailNotification',
        label: "table.users.table-title.DONT-SEND-EMAIL",
        type: "checkbox",
        readonly: true,
        section: 'user-logs'
    },
    {
        name: 'user.department',
        label: 'table.users.table-title.DEPARTMENT',
        type: 'multipledropdown',
        options: [],
        required: false,
        readonly: true,
        section: 'user-logs'
    },
    {
        name: 'user.site',
        label: 'table.users.table-title.SITE',
        type: 'multipledropdown',
        options: [],
        required: false,
        readonly: true,
        section: 'user-logs'
    },
];

export default
    {
        FieldDefinition
    }