import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observer } from 'rxjs';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent implements OnInit {

  observer: Observer<string>;
  selected$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
  }

  onCodeResult(resultString: string) {
    this.selected$.next(resultString);
    this.bsModalRef.hide();
    this.observer.next(this.selected$.getValue());
  }

}
