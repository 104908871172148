import { IField } from "../../interfaces/field";
const FieldDefinition: IField[] = [
  {
    name: 'id_num',
    label: 'table.drawing.table-title.TOOL-ID',
    type: 'textbox',
    required: false,
    readonly: true,
  },
  {
    name: 'name',
    label: 'table.tools.table-title.TOOL-NAME',
    type: 'textbox',
    required: true,
    readonly: false,
  },
  {
    name: 'group.id_num',
    label: 'table.tools.table-title.GROUP-ID',
    type: 'textbox',
    required: false,
    readonly: true,
  },
  {
    name: 'group.name',
    label: 'table.tools.table-title.GROUP',
    type: 'textbox',
    required: false,
    readonly: true,
  },
  {
    name: 'top',
    label: 'table.drawing.table-title.TOP',
    type: 'numeric',
    required: false,
    readonly: false,
  },
  {
    name: 'left',
    label: 'table.drawing.table-title.LEFT',
    type: 'numeric',
    required: false,
    readonly: false,
  },
  {
    name: 'height',
    label: 'table.drawing.table-title.HEIGHT',
    type: 'numeric',
    required: false,
    readonly: false,
  },
  {
    name: 'width',
    label: 'table.drawing.table-title.WIDTH',
    type: 'numeric',
    required: false,
    readonly: false,
  },
  {
    name: 'color',
    label: 'table.drawing.table-title.COLOR',
    type: 'textbox',
    required: false,
    readonly: false,
  },
  {
    name: 'textColor',
    label: 'table.drawing.table-title.TEXT-COLOR',
    type: 'textbox',
    required: false,
    readonly: false,
  },
  {
    name: 'borderColor',
    label: 'table.drawing.table-title.BORDER-COLOR',
    type: 'textbox',
    required: false,
    readonly: false,
  },
  {
    name: 'opacity',
    label: 'table.drawing.table-title.OPACITY',
    type: 'numeric',
    required: false,
    readonly: false,
  },
  {
    name: 'transparent',
    label: 'table.drawing.table-title.TRANSPARENT',
    type: 'checkbox',
    required: false,
    readonly: false,
  },
  {
    name: 'isTextOnTransparent',
    label: 'table.drawing.table-title.SEE-TEXT-ON-TRANSPARENT',
    type: 'checkbox',
    required: false,
    readonly: false,
  }
];

export default
  {
    FieldDefinition
  }