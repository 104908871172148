import moment from 'moment';

// DATE COMPARATOR FOR SORTING
const comparator = format => (date1, date2) => {
  var date1Number = date1 && moment(date1).unix();
  var date2Number = date2 && moment(date2).unix();
  if (!date1Number && !date2Number) return 0;

  if (!date1Number) return -1;
  if (!date2Number) return 1;
  return date1Number - date2Number;
}

export function timeCalculation(date1, date2, nodeA, nodeB, isInverted) {
  if (date1 === 'null' || (nodeA && nodeA.data && nodeA.data.$row_status == "new")) {
    return isInverted ?-1: 1;
  } else if (date2 === 'null' || (nodeA && nodeA.data && nodeA.data.$row_status == "new")) {
    return isInverted ? 1: -1;
  } else if(date1 === date2){
    return 0;
  } else {
    const date1Number = date1 && moment(date1).unix();
    const date2Number = date2 && moment(date2).unix();
    if (!date1Number && !date2Number) return 0;

    if (!date1Number) return -1;
    if (!date2Number) return 1;
    return date1Number - date2Number;
  }
}

export default comparator;