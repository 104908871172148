import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToolEvent } from '../../interfaces/tool-event';
import { Tool } from '../../interfaces/tool';
import { EventAuditTrail } from '../../interfaces/event-audit-trail';

@Injectable({
  providedIn: 'root'
})
export class EventsBackendService {
  url = '/api/events/';

  constructor(private http: HttpClient) {
  }

  listAll(skip: number, limit: number, isPm: boolean): Observable<ToolEvent[]> {
    return this.http.get<ToolEvent[]>(`${this.url}?skip=${skip * limit}&limit=${limit}&isPm=${isPm}`);
  }

  listHistory(skip: number, limit: number, isPm: boolean, sortColumn: string, sortType: string, lastFetched: string = ''): Observable<ToolEvent[]> {
    return this.http.get<ToolEvent[]>(`${this.url}history?skip=${skip * limit}&limit=${limit}&isPm=${isPm}&sortColumn=${sortColumn}&sortType=${sortType}&lastFetched=${lastFetched}`);
  }

  listByTool(toolId, active = 1) {
    return this.http.get<Array<any>>(`${this.url}${toolId}?active=${active}`);
  }

  listByToolHistory(toolId, page, limit, isHistory = false) {
    return this.http.get<Array<any>>(`${this.url}${toolId}?active=0&skip=${page * limit}&limit=${limit}&history=${isHistory}`);
  }

  getHistoryEvents(start: string, end: string) {
    return this.http.get<Array<ToolEvent>>(`${this.url}events-history?start=${start}&end=${end}`);
  }

  getHistoryEventsForCalendar(start: string, end: string) {
    return this.http.get<Array<ToolEvent>>(`${this.url}events-history-for-calendar?start=${start}&end=${end}`);
  }

  listByToolHistoryCount(toolId): Observable<{ history: Number }> {
    return this.http.get<{ history: Number }>(`${this.url}events-count/${toolId}`);
  }

  listByTime(start, end, lastMonth = false) {
    return this.http.get<ToolEvent[]>(`${this.url}history?start=${start}&end=${end}&lastMonth=${lastMonth}`);
  }

  getEventByIdNum(id_num: string) {
    return this.http.get<ToolEvent[]>(`${this.url}?id_num=${id_num}`);
  }

  getEventById(id: string) {
    return this.http.get<ToolEvent>(`${this.url}get/${id}`);
  }

  getHistoryById(id: string) {
    return this.http.get<ToolEvent>(`${this.url}get-history/${id}`);
  }

  listBySeverity(severity: number) {
    return this.http.get<ToolEvent[]>(`${this.url}?severity=${severity}`);
  }

  listForActiveEvents() {
    return this.http.get<ToolEvent[]>(`${this.url}?activeEvents=1`);
  }

  activeEventList(): Observable<ToolEvent[]> {
    return this.http.get<ToolEvent[]>(`${this.url}active-events`);
  }

  create(event: ToolEvent) {
    return this.http.post<ToolEvent>(this.url, event);
  }

  globalSearch(search: object): Observable<ToolEvent[]> {
    return this.http.post<ToolEvent[]>(`${this.url}/global-search`, search);
  }

  sendEmail(data: { email: string, event: any, eventId: string, files: string[] }) {
    return this.http.post(`${this.url}send-email`, data);
  }

  update(event: any) {
    return this.http.put<ToolEvent>(`${this.url}${event._id}`, event);
  }

  delete(event: ToolEvent) {
    const toolId = ((event.tool as Tool) ? (event.tool as Tool)._id : null) || event.tool;
    return this.http.delete(`${this.url}${toolId}/${event._id}`);
  }

  deleteBulk(events: string[]) {
    return this.http.post<string[]>(`${this.url}delete-bulk`, { events: events });
  }

  download() {
    return this.http.get(`${this.url}csv`, { responseType: 'blob' })
  }

  getAuditTrail(event: ToolEvent) {
    return this.http.get<EventAuditTrail[]>(`${this.url}audit-trail/${event._id}`)
  }

  upload(data: any, tool: Tool) {
    return this.http.post(`${this.url}${tool._id}/upload`, data);
  }

  search = (search: string) => this.http.get<ToolEvent[]>(`${this.url}/search/${encodeURIComponent(search)}`)

  count(): Observable<{ total: number, pmHistoryTotal: number, eventHistoryTotal: number }> {
    return this.http.get<{ total: number, pmHistoryTotal: number, eventHistoryTotal: number }>(`${this.url}count`);
  }

  lastMonthCount(): Observable<{ pm: Number, fm: Number }> {
    return this.http.get<{ pm: Number, fm: Number }>(`${this.url}last-month-count`);
  }

  searchProblemDescription(search: string, autoCompleteTool: string) {
    return this.http.post(`${this.url}search-problem-description`, { search: search, autoCompleteTool: autoCompleteTool });
  }

  updateBulk(events: ToolEvent[]) {
    return this.http.post<ToolEvent[]>(`${this.url}update-bulk`, { events: events });
  }

  updateMultiple(events: ToolEvent[]) {
    return this.http.post<ToolEvent[]>(`${this.url}update-multiple`, { events: events });
  }

}
