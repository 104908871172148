<p><span><span>This Data Processing Addendum (&ldquo;</span><span><strong>DPA</strong></span><span>&rdquo;)
      is incorporated by reference into AnyMaint&rsquo;s Terms of Service (the
      &ldquo;</span><span><strong>Agreement</strong></span><span>&rdquo;) entered by and between you, the
      Customer (as defined in the Agreement)
      (collectively,&nbsp;&ldquo;</span><span><strong>you</strong></span><span>&rdquo;,&nbsp;&ldquo;</span><span><strong>your</strong></span><span>&rdquo;,
      &ldquo;</span><span><strong>Customer</strong></span><span>&rdquo;), and AnyMaint Ltd.
      (&ldquo;</span><span><strong>AnyMaint</strong></span><span>&rdquo;, the
      &ldquo;</span><span><strong>Company</strong></span><span>&rdquo;,
      &ldquo;</span><span><strong>we</strong></span><span>&rdquo;,
      &ldquo;</span><span><strong>us</strong></span><span>&rdquo; or
      &ldquo;</span><span><strong>our</strong></span><span>&rdquo;) to reflect the Parties&rsquo; agreement
      with regard to the Processing of Personal Data by the Company solely on behalf of the Customer.
    </span></span>
</p>
<p class="mt-2">Capitalized terms not defined herein shall have the meanings assigned to such terms in the
  Agreement.
</p>
<p class="mt-2">By using the Service, you accept this DPA. If you cannot, or do not agree to, comply with
  and be bound by the terms of this DPA, or do not have the authority to bind the Customer or any other
  entity, please do not provide any Personal Data to us.
</p>
<ol class="normal-numbers remove-block-start">
  <li>
    <strong>Definitions.</strong>
    <ol class="normal-numbers">
      <li>
        <span>&ldquo;<span><strong>Affiliate</strong>&rdquo; means a person or entity controlling,
            controlled by or under the common control with the Company or Customer (as applicable); the term
            &ldquo;<em>control</em>&rdquo;, for the purpose of this definition, means an ownership, voting,
            or similar interest representing fifty percent (50%) or more of the total interests then
            outstanding of the entity in question.</span></span>
      </li>
      <li>
        <span>&ldquo;<strong>Affiliate</strong>&rdquo; means a person or entity controlling, controlled by
          or
          under the common control with the Company or Customer (as applicable); the term
          &ldquo;<em>control</em>&rdquo;,
          for the purpose of this definition, means an ownership, voting, or similar interest representing
          fifty percent
          (50%) or more of the total interests then outstanding of the entity in question.</span>
      </li>
      <li>
        <span>&ldquo;<strong>Authorized Affiliate</strong>&rdquo; means any of Customer&rsquo;s Affiliate(s)
          which (i) is subject to the Data Protection Laws, and (ii) is permitted to use the Service
          pursuant to the
          Agreement between Customer and the Company but has not signed its own Order Form with the Company
          and is not a
          &ldquo;Customer&rdquo; as defined under the Agreement.</span>
      </li>
      <li>
        <span>&ldquo;<strong>CCPA</strong>&rdquo; means the California Consumer Privacy Act of 2018, Cal.
          Civ.
          Code &sect;&sect; 1798.100 et. seq. and its implementing regulations.</span>
      </li>
      <li>
        <span>&ldquo;<strong>Data Protection Laws</strong>&rdquo; means all applicable and binding privacy
          and
          data protection laws and regulations, including such laws and regulations of the European Union,
          the European
          Economic Area and their Member States, Switzerland, the United Kingdom, Canada, Israel and the
          United States of
          America, as applicable with regard to the Processing of Personal Data and on the free movement of
          such data
          including (without limitation) the GDPR, the UK GDPR, and the CCPA.</span>
      </li>
      <li>
        <span>&ldquo;<strong>Data Subject</strong>&rdquo; means an identified or identifiable person to whom
          the Personal Data relates.</span>
      </li>
      <li>
        <span>&ldquo;<strong>GDPR</strong>&rdquo; means Regulation 2016/679 of the European Parliament and
          of
          the Council of 27 April 2016 on the protection of natural persons with regard to the processing of
          personal data
          and on the free movement of such data and repealing Directive 95/46/EC (General Data Protection
          Regulation).</span>
      </li>
      <li>
        <span>&ldquo;<strong>Personal Data</strong>&rdquo; means any information that identifies, relates
          to,
          describes, is capable of being associated with, or could reasonably be linked, directly or
          indirectly, to or with
          Data Subject, which is processed by the Company under this DPA and the Agreement.</span>
      </li>
      <li>
        <span>&ldquo;<strong>Service</strong>&rdquo; means all services provided by the Company in
          accordance
          with, and as defined in, the Agreement.</span>
      </li>
      <li>
        <span>&ldquo;<strong>Sensitive Data</strong>&rdquo; means Personal Data that is protected under
          special
          legislation and requires unique treatment, such as &ldquo;special categories of data&rdquo;,
          &ldquo;sensitive
          data&rdquo; or other materially similar terms under applicable Data Protection Laws, which may
          include any of the
          following: (a) social security number, tax file number, passport number, driver&rsquo;s license
          number, or similar
          identifier (or any portion thereof); (b) credit or debit card number; (c) financial, credit,
          genetic, biometric or
          health information; (d) information revealing racial or ethnic origin, political opinions,
          religious or
          philosophical beliefs, or trade union membership, genetic data or biometric data for the purpose
          of uniquely
          identifying a natural person, data concerning health or a person&rsquo;s sex life or sexual
          orientation, or data
          relating to criminal convictions and offenses.</span>
      </li>
      <li>
        <span>&ldquo;<strong>Sub-processor</strong>&rdquo; means any third party engaged by the Company in
          the
          Processing of Personal Data in connection with the Service.</span>
      </li>
      <li>
        <span>&ldquo;<strong>Standard Contractual Clauses</strong>&rdquo; means the standard contractual
          clauses of Commission Implementing Decision (EU) 2021/914 of 4 June 2021.</span>
      </li>
      <li>
        <span>&ldquo;<strong>UK GDPR</strong>&rdquo; means all data protection laws and regulations
          applicable
          to the United Kingdom, including the Data Protection Act 2018, as well as the GDPR as it forms
          part of the law of
          England and Wales, Scotland and Northern Ireland by virtue of section 3 of the European Union
          (Withdrawal) Act
          2018 and as amended by the Data Protection, Privacy and Electronic Communications (Amendments
          etc.) (EU Exit)
          Regulations 2019 (SI 2019/419).</span>
      </li>
    </ol>

    <p class="mt-2"><span>The terms &ldquo;<strong>Business</strong>&rdquo;,
        &ldquo;<strong>Controller</strong>&rdquo;, &ldquo;<strong>European Commission</strong>&rdquo;,
        &ldquo;<strong>Member State</strong>&rdquo;, &ldquo;<strong>Processor</strong>&rdquo;,
        &ldquo;<strong>Process</strong>&rdquo;, &ldquo;<strong>Processing</strong>&rdquo;,
        &ldquo;<strong>Service Provider</strong>&rdquo; and &ldquo;<strong>Supervisory
          Authority</strong>&rdquo; shall have the meanings ascribed to them in the Data Protection Laws, as
        applicable.</span></p>
  </li>
  <li>
    <strong>Application of this DPA.</strong>
    <ol class="normal-numbers">
      <li>
        <span>This DPA will only apply to the extent all of the following conditions are met:</span>
        <ol class="normal-numbers">
          <li><span>Company processes Personal Data that is made available by the Customer in connection
              with the Service (whether directly by the Customer or indirectly by a third party retained by
              and operating for the benefit of the Customer);</span></li>
          <li><span>The Data Protection Laws apply to the processing of Personal Data.</span></li>
          <li><span>This DPA will only apply to the Service for which the parties agreed to in the
              Agreement, which incorporates this DPA by reference.</span></li>
        </ol>
      </li>
    </ol>
  </li>
  <li>
    <strong>Processing of Personal Data.</strong>
    <ol class="normal-numbers">
      <li><span>This DPA applies when Personal Data is Processed by the Company strictly on behalf of
          Customer, as part of the provision of the Service. In this context and for the purposes of the
          GDPR or any similar Data Protection Laws, Customer is the Data Controller and the Company is the
          Data Processor; and for the purposes of the CCPA (to the extent applicable), Customer is the
          Business and the Company is the Service Provider. and for the duration
          The terms “Controller” and “Processor” below hereby relate to the Customer and the Company,
          respectively.</span></li>
      <li><span>Customer, in its use of the Service, and Customer’s instructions to the Processor, shall
          comply with Data Protection Laws. Customer shall establish and have any and all required legal
          bases in order to collect, Process and transfer to Data Processor the Personal Data, and to
          authorize the Processing by Data Processor, and for Data Processor’s Processing activities on
          Customer’s behalf, including the pursuit of ‘business purposes as defined under the CCPA.</span>
      </li>
      <li><span>If Customer is a Processor, Customer warrants to Company that Customer’s instructions and
          actions with respect to the Personal Data, including its appointment of Company as another
          Processor and concluding the Standard Contractual Clauses, have been authorized by the relevant
          Controller.</span></li>
    </ol>
  </li>
  <li>
    <strong>Processing Purpose.</strong>
    <ol class="normal-numbers">
      <li><span>Subject to the Agreement, Company shall Process Personal Data only as necessary for the
          performance of the Service and for the performance of the Agreement and this DPA. The duration of
          the Processing, the nature and purposes of the Processing, as well as the types of Personal Data
          Processed and categories of Data Subjects under this DPA are further specified in <strong>Schedule
            1</strong> (Details of the Processing) to this DPA.</span></li>
      <li><span>Processor acknowledges and confirms that it does not receive or process any Personal Data as
          consideration for any service or other items that Processor provides to Customer under the
          Agreement. Processor shall not have, derive, or exercise any rights or benefits regarding Personal
          Data Processed on Customer’s behalf, and may use and disclose Personal Information solely for the
          purposes for which such Personal Data was provided to it, as stipulated in the Agreement and this
          DPA. Processor certifies that it understands the rules, requirements and definitions of the CCPA
          and agrees to refrain from selling (as such term is defined in the CCPA) any Personal Data
          Processed hereunder without Customer’s prior written consent, nor take any action that would cause
          any transfer of Personal Data to or from Processor under the Agreement or this DPA to qualify as
          “selling” such Personal Data under the CCPA. For the avoidance of doubt, Processor will not use,
          retain or disclose Personal Data for any purpose other than providing the Service.</span></li>
      <li><span>The Parties agree that the Service are not intended for the Processing of Sensitive Data,
          and that if Customer wishes to use the Service to Process Sensitive Data, it must first obtain the
          Processor’s explicit prior written consent and enter into any additional agreements as may be
          required by the Company.</span></li>
    </ol>
  </li>
  <li>
    <strong>Data Subject Requests; Assistance.</strong>
    <ol class="normal-numbers">
      <li><span>Taking into account the nature of the Processing, Processor will reasonably assist Customer
          by appropriate technical and organizational measures, insofar as this is possible, for the
          fulfilment of Customer’s obligation to respond to requests for exercising the Data Subjects’
          rights under the GDPR or other Data Protection Laws, to request access, rectification or deletion
          of Personal Data, to restrict or object to further processing of such data, to receive a portable
          copy thereof, or to request not to be subject to automated individual decision-making. Processor
          will further reasonably assist Customer, upon Customer’s reasonable request, in ensuring
          compliance with Customer’s obligations in connection with the security of Processing, notification
          of a Personal Data Breach to supervisory authorities and affected Data Subjects, Customer’s data
          protection impact assessments and Customer’s prior consultation with supervisory authorities,
          insofar as it relates to Processor’s Processing of Personal Data under this DPA, and to the extent
          Customer does not otherwise have access to the relevant information, and that such information is
          available to Processor. Except for negligible costs, Customer will promptly reimburse Processor
          with costs and expenses incurred by Processor in connection with the provision of assistance to
          Customer under this DPA.</span></li>
    </ol>
  </li>
  <li>
    <strong>Personnel; Confidentiality.</strong>
    <ol class="normal-numbers">
      <li><span>Processor will ensure that its access to Personal Data is limited to those Personnel who
          require such access to perform the Agreement.</span></li>
      <li><span>Processor will impose appropriate contractual obligations upon its Personnel engaged in the
          Processing of Personal Data, including relevant obligations regarding confidentiality, data
          protection, and data security. Processor will ensure that its Personnel engaged in the Processing
          of Personal Data are informed of the confidential nature of the Personal Data, have received
          appropriate training in their responsibilities, and have executed written confidentiality
          agreements.</span></li>
    </ol>
  </li>
  <li>
    <strong>Sub-Processors.</strong>
    <ol class="normal-numbers">
      <li><span>Processor may engage Sub-Processors to Process Personal Data on behalf of Customer. Customer
          hereby provides Processor with a general authorization to engage the Sub-Processors listed at the
          Platform. Where a Sub-Processor fails to fulfil its data protection obligations in connection with
          the Processing of Personal Data under this DPA, Processor will remain fully liable to Customer for
          the performance of that Sub-Processor’s obligations.</span></li>
    </ol>
  </li>
  <li>
    <strong>Cross-Border Data Transfers.</strong>
    <ol class="normal-numbers">
      <li>
        <span>Transfers from the EEA, Switzerland and the United Kingdom to countries that offer adequate
          level or data protection. Personal Data may be transferred from EU Member States, the three EEA
          member countries (Norway, Liechtenstein and Iceland) (collectively,
          &ldquo;<strong>EEA</strong>&rdquo;), Switzerland and the United Kingdom
          (&ldquo;<strong>UK</strong>&rdquo;) to countries that offer an adequate level of data protection
          under or pursuant to the adequacy decisions published by the relevant data protection authorities
          of the EEA, the European Union, the Member States or the European Commission, Switzerland, and/or
          the UK as relevant (&ldquo;<strong>Adequacy Decisions</strong>&rdquo;), as applicable, without any
          further safeguard being necessary. </span>
      </li>
      <li>
        <span>Transfers from the EEA, Switzerland and the United Kingdom to other countries. If the
          Processing of Personal Data by AnyMaint includes transfers (either directly or via onward
          transfer) from the EEA, Switzerland (collectively &ldquo;<strong>EEA Transfer</strong>&rdquo;)
          and/or the UK (&ldquo;<strong>UK Transfer</strong>&rdquo;) to other countries which have not been
          subject to a relevant Adequacy Decision, and such transfers are not performed through an
          alternative recognized compliance mechanism as may be adopted by Processor for the lawful transfer
          of personal data (as defined in the GDPR) outside the EEA, Switzerland or the UK, as applicable,
          then (i) the terms set forth in Part 1 of Schedule 1 (EEA Cross Border Transfers) shall apply to
          any such EEA Transfer; (ii) the terms set forth in Part 2 of Schedule 1 (UK Cross Border
          Transfers) shall apply to any such UK Transfer; and (iii) the terms set forth in Part 3 of
          Schedule 1 (Additional Safeguards) shall apply to such an EEA Transfer and a UK Transfer.</span>
      </li>
      <li>
        <span>Transfers from other countries: If the Processing of Personal Data by Processor includes a
          transfer of Personal Data by and/or mandated by Customer to Processor from any other jurisdiction
          which mandates a particular compliance mechanism for the lawful transfer of such data be
          established, Customer shall notify Processor of such applicable requirements, and the Parties may
          seek to make any necessary amendments to this DPA. </span>
      </li>
    </ol>
  </li>
  <li>
    <strong>Security.</strong>
    <ol class="normal-numbers">
      <li>
        <span>Processor shall maintain industry-standard technical and organizational measures for
          protection of Personal Data Processed hereunder, pursuant to the Processor information security
          policy. Processor regularly monitors its compliance with these safeguards. Processor will not
          materially decrease the overall security of the Service during the term of the Agreement.</span>
      </li>
      <li>
        <span>Upon Customer’s 14 days prior written request at reasonable intervals (no more than once every
          12 months), and subject to strict confidentiality undertakings by Customer, Processor shall make
          available to Customer that is not a competitor of Processor (or Customer’s independent, reputable,
          third-party auditor that is not a competitor of Processor and not in conflict with Processor,
          subject to their confidentiality and non-compete undertakings) information necessary to
          demonstrate compliance with this DPA, and allow for and contribute to audits, including
          inspections, conducted by them (provided, however, that such information, audits, inspections and
          the results therefrom, including the documents reflecting the outcome of the audit and/or the
          inspections, shall only be used by Customer to assess compliance with this DPA, and shall not be
          used for any other purpose or disclosed to any third party without Processor’s prior written
          approval. Upon Processor’s first request, Customer shall return all records or documentation in
          Customer’s possession or control provided by Processor in the context of the audit and/or the
          inspection). In the event of an audit or inspections as set forth above, Customer shall ensure
          that it (and each of its mandated auditors) will not cause (or, if it cannot avoid, minimize) any
          damage, injury or disruption to Processor’s premises, equipment, personnel and business, as
          applicable, while conducting such audit or inspection. The audit rights set forth in ‎9.2 above,
          shall only apply to the extent that the Agreement does not otherwise provide Customer with audit
          rights that meet the relevant requirements of Data Protection Laws.</span>
      </li>
    </ol>
  </li>
  <li>
    <strong>Data Incident Management and Notification.</strong>
    <ol class="normal-numbers">
      <li>
        <span>Processor maintains reasonable security incident management policies and procedures and, to
          the extent required under applicable Data Protection Laws, shall notify Customer without undue
          delay after becoming aware of the accidental or unlawful destruction, loss, alteration,
          unauthorized disclosure of, or access to Personal Data Processed by Processor on behalf of the
          Customer (each, a “<strong>Data Incident</strong>”). Processor shall make reasonable efforts to
          identify and take those steps as Processor deems necessary and reasonable to remediate and/or
          mitigate the cause of such Data Incident to the extent the remediation and/or mitigation is within
          Processor’s reasonable control. The obligations herein shall not apply to incidents that are
          caused by Customer, its Users or anyone who uses the Service on Customer’s behalf. Customer will
          not make, disclose, release or publish any finding, admission of liability, communication, notice,
          press release or report concerning any Data Incident which directly or indirectly identifies
          Processor (including in any legal proceeding or in any notification to regulatory or supervisory
          authorities or affected individuals) without Processor’s prior written approval, unless, and
          solely to the extent that, Customer is compelled to do so pursuant to applicable Data Protection
          Laws. In the latter case, unless prohibited by such laws, Customer shall provide Processor with
          reasonable prior written notice to provide Processor with the opportunity to object to such
          disclosure and in any case, Customer will limit the disclosure to the minimum scope
          required.</span>
      </li>
    </ol>
  </li>
  <li>
    <strong>Return and Deletion of Personal Data.</strong>
    <ol class="normal-numbers">
      <li>
        <span>Following termination of the Agreement and cessation of the Service, at the choice of
          Customer, Processor shall return or to the fullest extent technically feasible delete all Customer
          Data in its possession or control. This requirement shall not apply to the extent Processor is
          required by applicable law to retain some or all of the Customer Data, or to Customer Data it has
          archived on back-up systems (e.g., in the form of audit logs), which Customer Data Processor shall
          securely isolate and protect from any further Processing, except to the extent required by
          applicable law. To the extent authorized or required by applicable law, Processor may also retain
          one copy of the Personal Data solely for evidence purposes and/or for the establishment, exercise
          or defense of legal claims and/or for compliance with legal obligations.</span>
      </li>
    </ol>
  </li>
  <li>
    <strong>Term.</strong>
    <ol class="normal-numbers">
      <li>
        <span>This DPA will commence and become legally binding on the earlier of (i) the date of its
          execution, (ii) the effective date of the Agreement to which it relates, or (iii) the initiation
          of Processor’s Processing of Personal Data on behalf of Customer; and will continue until the
          Agreement expires or is terminated.</span>
      </li>
    </ol>
  </li>
  <li>
    <strong>Authorized Affiliates.</strong>
    <ol class="normal-numbers">
      <li>
        <span>The Parties acknowledge and agree that, by executing the DPA, the Customer enters into the DPA
          on behalf of itself and, as applicable, in the name and on behalf of its Authorized Affiliates, in
          which case each Authorized Affiliate agrees to be bound by the Customer’s obligations under this
          DPA, if and to the extent that Processor Processes Personal Data on the behalf of such Authorized
          Affiliates, thus qualifying them as the “Controller”. All access to and use of the Service by
          Authorized Affiliates must comply with the terms and conditions of the Agreement and this DPA and
          any breach of the terms and conditions therein by an Authorized Affiliate shall be deemed a breach
          by Customer.</span>
      </li>
      <li>
        <span>Customer shall remain responsible for coordinating all communication with Processor under the
          Agreement and this DPA and shall be entitled to make and receive any communication in relation to
          this DPA on behalf of its Authorized Affiliates.</span>
      </li>
    </ol>
  </li>
  <li>
    <strong>Other Provisions.</strong>
    <ol class="normal-numbers">
      <li>
        <span><strong>Modifications</strong>. Each Party may by at least forty-five (45) calendar days’
          prior written notice to the other Party, request in writing any variations to this DPA if they are
          required as a result of any change in any Data Protection Laws to allow Processing of Customer
          Personal Data to be made (or continue to be made) without breach of those Data Protection Laws.
          Pursuant to such notice: (i) the Parties shall use commercially reasonable efforts to accommodate
          such required modification; and (ii) Customer shall not unreasonably withhold or delay agreement
          to any consequential variations to this DPA proposed by Processor to protect the Processor against
          additional risks, or to indemnify and compensate Processor for any further steps and costs
          associated with the variations made herein at Customer’s request. The Parties shall promptly
          discuss the proposed variations and negotiate in good faith with a view to agreeing and
          implementing those or alternative variations designed to address the requirements identified in
          Customer’s or Processor’s notice as soon as is reasonably practicable. In the event that the
          Parties are unable to reach such an agreement within 30 days of such notice, then Customer or
          Processor may, by written notice to the other Party, with immediate effect, terminate the
          Agreement or DPA to the extent that it relates to the elements of the Service which are affected
          by the proposed variations (or lack thereof). Customer will have no further claims against
          Processor (including, without limitation, requesting refunds for the Service) pursuant to the
          termination of the Agreement and the DPA as described in this Section.</span>
      </li>
      <li>
        <span><strong>Data Protection Impact Assessment and Prior Consultation</strong>. Upon Customer’s
          reasonable request, Processor shall provide Customer, at Customer’s cost, with reasonable
          cooperation and assistance needed to fulfil Customer’s obligation under the GDPR or the UK GDPR
          (as applicable) to carry out a data protection impact assessment related to Customer’s use of the
          Service, to the extent Customer does not otherwise have access to the relevant information, and to
          the extent such information is available to Processor. Processor shall provide, at Customer’s
          cost, reasonable assistance to Customer in the cooperation or prior consultation with the
          Supervisory Authority in the performance of its tasks relating to this Section 15.2, to the extent
          required under the GDPR or the UK GDPR, as applicable.</span>
      </li>
      <li>
        <span><strong>Conflict</strong>. In the event of any conflict or inconsistency between certain
          provisions of this DPA and the provisions of the Agreement, the provisions of this DPA shall
          prevail over the conflicting provisions of the Agreement solely with respect to the Processing of
          Personal Data. In the event of any conflict between certain provisions of this DPA and any of its
          Schedules and the Standard Contractual Clauses, the Standard Contractual Clauses shall
          prevail.</span>
      </li>
    </ol>
  </li>
</ol>

<h4 class="text-center"><u>SCHEDULE 1 – DETAILS OF THE PROCESSING</u></h4>
<ol class="roman-numbers small-block-start" type="I">
  <li>
    <span><strong>Nature and Purpose of Processing</strong></span>
    <ul>
      <li><span>Providing the Service to Customer in accordance with the Agreement.</span></li>
      <li><span>Processing to comply with other reasonable instructions provided by Customer where such
          instructions are consistent with the Agreement.</span></li>
      <li><span>Sharing Personal Data with third parties in accordance with Customer’s instructions and/or
          pursuant to Customer’s use of the Service (e.g., integrations between the Service and any
          services provided by third parties, as configured by or on behalf of Customer to facilitate the
          sharing of Personal Data between the Service and such third party services).</span></li>
      <li><span>Rendering Personal Data fully and irrevocably anonymous and non-personal, in accordance
          with applicable standards recognized by Data Protection Laws and guidance issued
          thereunder.</span></li>
      <li><span>Complying with applicable laws and regulations.</span></li>
      <li><span>All tasks related with any of the above.</span></li>
    </ul>
  </li>
  <li>
    <span><strong>Duration of Processing</strong></span>
    <p>Subject to any section of the DPA and/or the Agreement dealing with the duration of the Processing
      and the consequences of the expiration or termination thereof, Processor will Process Personal Data
      pursuant to the DPA and Agreement for the duration of the Agreement, unless otherwise agreed upon in
      writing.</p>
  </li>
  <li>
    <span><strong>Type of Personal Data</strong></span>
    <p>Customer may submit Personal Data to the Service, the extent of which is determined and controlled
      by Customer in its sole discretion. The Company has no control over the identity of the Data
      Subjects whose Personal Data is processed on behalf of Customer and over the types of Personal Data
      Processed. </p>
  </li>
  <li>
    <span><strong>Categories of Data Subjects</strong></span>
    <p>Customer may submit Personal Data to the Service which may include, but is not limited to, Personal
      Data relating to the following categories of Data Subjects:</p>
    <ul>
      <li><span>Employees, agents, advisors, freelancers of Customer (who are natural persons).</span>
      </li>
      <li><span>Prospects, customers, business partners and vendors of Customer (who are natural
          persons)</span></li>
      <li><span>Any other third party individual with whom Customer decides to communicate through the
          Service.</span></li>
    </ul>
  </li>
</ol>