import { IField } from "../../interfaces/field";
import { machineStatusTypes } from "../machine-statuses";
import { pmKind } from "../pmKind";

const FieldDefinition: IField[] = [
    {
        name: 'id_num',
        label: 'table.maintenance-type.table-title.MAINTENANCE-TYPE-ID',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'company.name',
        label: 'table.maintenance-type.table-title.COMPANY',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'name',
        label: 'table.maintenance-type.table-title.NAME',
        type: 'textbox',
        required: true,
        readonly: false
    },
    {
        name: 'period',
        label: 'table.maintenance-type.table-title.PERIOD',
        type: 'numeric',
        required: true,
        readonly: false
    },
    {
        name: 'grayLine',
        label: 'table.maintenance-type.table-title.GREY-LINE',
        type: 'numeric',
        required: true,
        readonly: false
    },
    {
        name: 'redLine',
        label: 'table.maintenance-type.table-title.RED-LINE',
        type: 'numeric',
        required: true,
        readonly: false
    },
    {
        name: 'pmKind',
        label: 'table.maintenance-type.table-title.PM-KIND',
        type: 'selectdropdown',
        required: true,
        readonly: false,
        minWidth: 200
    },
    {
        name: 'isDeleted',
        label: 'general.DELETED',
        type: "checkbox",
        readonly: false,
        isAdmin: true,
    }
]

export default {
    FieldDefinition
}