import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { List } from 'immutable';
import { AuthService } from '../../routes/user/auth.service';
import { finalize, map, tap } from 'rxjs/operators';
import { CustomFieldsStoreService } from './custom-fields-store.service';
import FieldDefinition from '../constants/field-definitions/checklist';
import { ChecklistItemsBackendService } from './backend/checklist-items.backend.service.service';
import { ChecklistItem } from '../interfaces/checklist-item';
import { LoaderService } from '../utils/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsStoreService } from './translations-store.service';
import { Router } from '@angular/router';
import { ConfirmService } from '../utils/confirm/confirm.service';
import { ToolsStoreService } from './tools-store.service';

@Injectable({
  providedIn: 'root'
})
export class ChecklistItemsService {
  protected checklistItemsSubject = new BehaviorSubject(List([]));
  protected toolChecklistItemsSubject = new BehaviorSubject(List([]));
  protected toolPmChecklistItemsSubject = new BehaviorSubject(List([]));
  protected chapterChecklistItemsSubject = new BehaviorSubject(List([]));
  private isToolListCalled: BehaviorSubject<Number> = new BehaviorSubject(0);
  private isChapterListCalled: BehaviorSubject<Number> = new BehaviorSubject(0);
  private isListCalled: BehaviorSubject<Number> = new BehaviorSubject(0);
  public readonly checklistItems$: Observable<List<ChecklistItem>> = this.checklistItemsSubject.asObservable();
  public readonly toolPmChecklistItems$: Observable<List<ChecklistItem>> = this.toolPmChecklistItemsSubject.asObservable();
  public readonly toolChecklistItems$: Observable<List<ChecklistItem>> = this.toolChecklistItemsSubject.asObservable();
  public readonly chapterChecklistItems$: Observable<List<ChecklistItem>> = this.chapterChecklistItemsSubject.asObservable();
  public columns$: Observable<any[]>;
  public selectedProcedure: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(protected backend: ChecklistItemsBackendService,
    protected customFields: CustomFieldsStoreService,
    protected loader: LoaderService,
    private translate: TranslateService,
    private translationsStoreService: TranslationsStoreService,
    private router: Router,
    private confirm: ConfirmService,
    private toolsStoreService: ToolsStoreService,
    protected auth: AuthService) {
    this.onInit()
  }

  onInit() {
    this.columns$ = combineLatest([this.auth.user$, this.customFields.get('checklistItems'), this.translationsStoreService.languageCode$]).pipe(
      map(([user, columns, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(col => (col.name != "company.name" || user.isRoot()) && (col.name != "isDeleted" || user.isAdminOrToolAdmin()));
        const fields = columns.toArray();
        const col = this.customFields.toColumnDef(defaults, {
          rowSelector: "id_num",
          format: this.formatCell.bind(this)
        });
        const custom = this.customFields.toColumnDef(fields, {
          format: this.customFields.formatCellCustomField.bind(this)
        });

        return col.concat(custom);
      })
    );
  }

  load() {
    return this.backend.list().pipe(
      map(checklists => checklists.map((checklist) => this.remapChecklist(checklist))),
      map((result: Array<ChecklistItem>) => {
        this.checklistItemsSubject.next(List(result));
        this.isListCalled.next(1);
        return result
      })
    )
  }

  resetToolCalled = () => {
    this.isToolListCalled.next(0);
  }
  getToolListCalled = () => {
    return this.isToolListCalled.getValue();
  }

  getListCalled = () => {
    return this.isListCalled.getValue();
  }

  chapters = (isForce = false) => {
    if(this.isChapterListCalled.getValue() == 0 || isForce){
      return this.backend.chapters().pipe(
        map((result: Array<ChecklistItem>) => {
          this.chapterChecklistItemsSubject.next(List(result));
          this.isChapterListCalled.next(1);
          return result
        })
      ).subscribe()
    }else{
      return of(false);
    }
  }

  loadTool() {
    if(this.isToolListCalled.getValue() == 0){
      return this.backend.listTool().pipe(
        map((result: Array<ChecklistItem>) => {
          this.toolChecklistItemsSubject.next(List(result));
          this.toolPmChecklistItemsSubject.next(List(result.filter(r => r.taskKind == 'PM')));
          this.isToolListCalled.next(1);
          return result
        })
      ).subscribe()
    }else{
      return of(false);
    }
  }

  remapChecklist = (checklist) => {
    checklist.toolObject = checklist.tool || {};
    checklist.tool = checklist.toolObject ? checklist.toolObject._id : null;
    checklist.groupObject = checklist.group || {};
    checklist.group = checklist.groupObject ? checklist.groupObject._id : null;
    checklist.chapterObject = checklist.chapter || null;
    checklist.chapter = checklist.chapterObject ? checklist.chapterObject._id : null;
    return checklist;
  }

  create = (data: ChecklistItem) => this.backend.create(this.formatData(data))
    .pipe(
      tap(result => {
        this.checklistItemsSubject.next(this.checklistItemsSubject.getValue().unshift(result))
        this.toolChecklistItemsSubject.next(this.toolChecklistItemsSubject.getValue().unshift(result))
        if(data.taskKind == 'PM') this.toolPmChecklistItemsSubject.next(this.toolPmChecklistItemsSubject.getValue().unshift(result))
      })
    )

  updateProcedureTasks = (checklists: ChecklistItem[], isPM: boolean) => {
    checklists.forEach(c => {
      this.checklistItemsSubject.next(this.checklistItemsSubject.getValue().unshift(c))
      this.toolChecklistItemsSubject.next(this.toolChecklistItemsSubject.getValue().unshift(c))
      if(isPM) this.toolPmChecklistItemsSubject.next(this.toolPmChecklistItemsSubject.getValue().unshift(c));
    })
  }

  update(data: ChecklistItem) {
    return this.backend.update(this.formatData(data));
  }

  delete(data: ChecklistItem) {
    return this.backend.delete(data);
  }

  getList() {
    return this.checklistItemsSubject.getValue();
  }

  uploadPicture = (checklistItem, files) => {
    this.loader.add()
    return this.backend.uploadPicture(checklistItem, files)
      .pipe(
        tap((data: ChecklistItem) => {
          const items = this.checklistItemsSubject.getValue()
          const idx = items.findIndex((c: ChecklistItem) => c._id === data._id)
          this.checklistItemsSubject.next(items.set(idx, data))
          this.loader.remove()
        }),

        finalize(() => this.loader.remove())

      )
  }

  getProcedure = (params, field) => {
    return !params.data.procedure ? "" : params.data.procedure.map(p => p[field]).join(", ");
  }

  formatCell = (col, field) => {
    switch (field.name) {
      case "image":
        return {
          ...col,
          cellRenderer: 'fileRedirection',
        }
      case "procedure":
        return {
          ...col,
          valueGetter: params => this.getProcedure(params, "id_num"),
          valueFormatter: params => this.getProcedure(params, "id_num"),
          tooltipValueGetter: params => this.getProcedure(params, "id_num"),
        }
      case "procedureName":
        return {
          ...col,
          valueGetter: params => this.getProcedure(params, "name"),
          valueFormatter: params => this.getProcedure(params, "name"),
          tooltipValueGetter: params => this.getProcedure(params, "name"),
        }
      default:
        return col;
    }
  }

  formatData = (data: ChecklistItem) => {
    if (typeof data.group != "undefined" && !data.group) delete data.group;
    if (typeof data.tool != "undefined" && !data.tool) delete data.tool;
    return data;
  }
}
