import { IField } from "../../interfaces/field";

const section = "measurement-results";

const FieldDefinition: IField[] = [
  {
    name: "id_num",
    label: "general.ID",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "measurementObject.name",
    label: "measurements.NAME",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "measurementObject.type",
    label: "measurements.TYPE",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "toolObject.id_num",
    label: "table.measurement-results.table-title.TOOL-ID",
    type: "numeric",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "toolObject.name",
    label: "table.measurement-results.table-title.TOOL-NAME",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "measurementObject.id_num",
    label: "table.measurement-results.table-title.MEASUREMENT-ID",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "time",
    label: "table.measurement-results.table-title.TIME",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "date",
    label: "table.measurement-results.table-title.DATE",
    type: "dateonlypicker",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "measuredValue",
    label: "table.measurement-results.table-title.MEASURED-VALUE",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "value",
    label: "table.measurement-results.table-title.CALCULATED-VALUE",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "measuredFormula",
    label: "table.measurement-results.table-title.MEASURED-VALUE-FORMULA",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "status",
    label: "table.measurement-results.table-title.STATUS",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "comment",
    label: "table.measurement-results.table-title.COMMENT",
    type: "textarea",
    required: false,
    readonly: false,
    section,
  },
  {
    name: "measurementObject.target",
    label: "table.measurement-results.table-title.TARGET",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "measurementObject.frequency",
    label: "table.measurement-results.table-title.FREQUENCY",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "measurementObject.lslKind",
    label: "table.measurement-results.table-title.LSL-KIND",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "measurementObject.lsl",
    label: "table.measurement-results.table-title.LSL",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "measurementObject.uslKind",
    label: "table.measurement-results.table-title.USL-KIND",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "measurementObject.usl",
    label: "table.measurement-results.table-title.USL",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "measurementObject.lclKind",
    label: "table.measurement-results.table-title.LCL-KIND",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "measurementObject.lcl",
    label: "table.measurement-results.table-title.LCL",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "measurementObject.uclKind",
    label: "table.measurement-results.table-title.UCL-KIND",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "measurementObject.ucl",
    label: "table.measurement-results.table-title.UCL",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "childMeasurementResult.id_num",
    label: "table.measurement-results.table-title.CHILD-MEASUREMENT-RESULT",
    type: "textbox",
    required: false,
    readonly: true,
    hide: true,
    section,
  },
  {
    name: "childMeasurementResult.value",
    label: "table.measurement-results.table-title.CHILD-MEASUREMENT-RESULT-VALUE",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "parentMeasurementResult.id_num",
    label: "table.measurement-results.table-title.PARENT-MEASUREMENT-RESULT",
    type: "textbox",
    required: false,
    readonly: true,
    hide: true,
    section,
  },
  {
    name: "parentMeasurementResult.value",
    label: "table.measurement-results.table-title.PARENT-MEASUREMENT-RESULT-VALUE",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "formula",
    label: "measurements.FORMULA",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "material",
    label: "table.measurement-results.table-title.RESPONSE-KIND-WHAT-TO-DO",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "userAddedValue",
    label: "table.measurement-results.table-title.HOW-MUCH-MATERIAL-YOU-ADDED",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "material.name",
    label: "table.measurement-results.table-title.MATERIAL-ADDED",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "material.part.batchNumber",
    label: "table.measurement-results.table-title.BATCH-OF-MATERIAL-ADDED",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "updatedAt",
    label: "table.measurement-results.table-title.LAST-UPDATED",
    type: "datepicker",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "user.displayName",
    label: "table.measurement-results.table-title.UPDATED-BY",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "isDeleted",
    label: "general.DELETED",
    type: "checkbox",
    required: false,
    readonly: false,
    section,
  },
];

export default {
  FieldDefinition,
};
