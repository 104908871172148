import { IField } from "../../interfaces/field";

const section = 'data-formats';

const FieldDefinition: IField[] = [
  {
    name: 'id_num',
    label: "general.ID",
    type: 'textbox',
    required: false,
    readonly: true,
    section,
  },
  {
    name: 'formatText',
    label: "table.data-formats.table-title.FORMAT-TEXT",
    type: 'textbox',
    required: false,
    readonly: false,
    section,
  },
  {
    name: 'isDeleted',
    label: "general.DELETED",
    type: 'checkbox',
    required: false,
    readonly: false,
    section,
  },
];

export default {
  FieldDefinition,
};