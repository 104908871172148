import { Injectable } from '@angular/core';
import { AI_MAINTENANCE_ASSISTANCE, BASE_CHAT_GPT } from '../interfaces/general';
import { OpenaiBackendService } from './backend/openai.backend.service';

@Injectable({
  providedIn: 'root'
})
export class OpenaiStoreService {
  constructor(
    private backend: OpenaiBackendService
  ) { }

  aiAssistantText = (id: string) => {
    return this.backend.aiAssistantText(id);
  }
  
  fetchAllToolImages = (toolId: string) => {
    return this.backend.fetchAllToolImages(toolId);
  }

  ask = (search: string, tool: string) => {
    return this.backend.ask(search, tool);
  }

  simulator = (sentence: BASE_CHAT_GPT) => {
    return this.backend.simulator(sentence);
  }

  aiMaintenanceAssistance = (sentence: FormData) => {
    return this.backend.aiMaintenanceAssistance(sentence);
  }
  
  aiMaintenanceAssistanceForFile = (sentence: FormData) => {
    return this.backend.aiMaintenanceAssistanceForFile(sentence);
  }
  
  aiMaintenancePartsAssistanceForFile = (sentence: FormData) => {
    return this.backend.aiMaintenancePartsAssistanceForFile(sentence);
  }

  aiMaintenanceAssistanceFromFile = (fileId: string) => {
    return this.backend.aiMaintenanceAssistanceFromFile(fileId);
  }
}
