import { animate, query, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  animations: [
    trigger("slideInOut", [
      transition(":enter", [
        query(
          ".modal-dialog-ltr",
          [
            style({ transform: "translateX(100%)" }),
            animate("200ms ease-in", style({ transform: "translateX(0%)" })),
          ],
          { optional: true }
        ),
      ]),
      transition(":leave", [
        query(
          ".modal-dialog-ltr",
          [animate("200ms ease-in", style({ transform: "translateX(100%)" }))],
          { optional: true }
        ),
      ]),

      transition(":enter", [
        query(
          ".modal-dialog-rtl",
          [
            style({ transform: "translateX(-100%)" }),
            animate("200ms ease-in", style({ transform: "translateX(0%)" })),
          ],
          { optional: true }
        ),
      ]),
      transition(":leave", [
        query(
          ".modal-dialog-rtl",
          [animate("200ms ease-in", style({ transform: "translateX(-100%)" }))],
          { optional: true }
        ),
      ]),
    ]),

    trigger("fadeInOut", [
      state(
        "void",
        style({
          opacity: 0,
        })
      ),
      transition("void <=> *", animate(500)),
    ]),
  ],
})
export class SideMenuComponent implements OnInit {
  @Input() title: string = "";
  @Input() show: boolean = false;
  @Input() showResetFilter: boolean = false;
  @Output() onClose = new EventEmitter();
  @Output() onResetFilter = new EventEmitter();
  @Input() size: "xs" | "sm" | "md" | "lg" | "xl" = "md";

  constructor(
    public translationService: TranslationsStoreService
  ) { }

  ngOnInit(): void { }

  close = () => this.onClose.emit();

  resetFilter = ($event) => {
    $event.stopPropagation();
    $event.preventDefault();
    this.onResetFilter.emit();
  }
}
