import { FilesStoreService } from './../../../core/services/files-store.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-image-presentation',
  templateUrl: './image-presentation.component.html',
  styleUrls: ['./image-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagePresentationComponent implements OnChanges {
  @Input() fileId: string;
  @Input() alt: string = '';
  @Input() size: string = null;
  @Input() class: string = null;

  @Output('load') load: EventEmitter<boolean> = new EventEmitter();

  imageUrl: string = '';

  constructor(
    private filesStoreService: FilesStoreService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileId) {
      if(changes.fileId.currentValue){
        this.filesStoreService.signedUrl(changes.fileId.currentValue, this.size).then((url: string) => {
          this.imageUrl = url;
          this.changeDetectorRef.detectChanges();
        });
      } else {
        this.imageUrl = '';
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  handleOnLoad(){
    this.load.emit(true);
  }
}
