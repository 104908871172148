import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  ElementRef,
} from "@angular/core";
import { Observable, Observer, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ToolsStoreService } from "../../../core/services/tools-store.service";
import { Router } from "@angular/router";
import { Tool } from "../../../core/interfaces/tool";
import { isArray } from "lodash";

declare var $: any;

@Component({
  selector: "app-navsearch",
  templateUrl: "./navsearch.component.html",
  styleUrls: ["./navsearch.component.scss"],
})
export class NavsearchComponent implements OnInit {
  @Output() onclose = new EventEmitter<boolean>();
  term: string;

  dataSource$: Observable<Tool[]>;

  constructor(
    public elem: ElementRef,
    private toolsService: ToolsStoreService,
    private router: Router
  ) {}

  ngOnInit() {
    this.dataSource$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.term);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          const search = query ? query.toLowerCase() : "";
          const tools = this.toolsService.getToolList().filter((t) => {
            const group = t.group && t.group.name ? t.group.name : '';
            const priorityOrder = t.priorityOrder && t.priorityOrder >= 0 ? t.priorityOrder.toString() : '';
            const site = t.site && t.siteObject ? t.siteObject.name : '';
            const critical = t.critical ? t.critical.toString() : '';
            const safety = t.safety ? t.safety.toString() : '';
            const sendTo = t.sendTo && t.sendToObject && isArray(t.sendToObject) && t.sendToObject.length > 0 ? t.sendToObject.map(s => s?.displayName).join(',') : '';
            const customTable = t.customTable && t.customTableObject && isArray(t.customTableObject) && t.customTableObject.length > 0 ? t.customTableObject.map(s => s ? s.name : '').join(',') : '';
            const inventoryParts = t.inventoryParts && t.inventoryPartsObject && isArray(t.inventoryPartsObject) && t.inventoryPartsObject.length > 0 ? t.inventoryPartsObject.map(s => `${s.catalogNumber} | ${s.name}`).join(',') : '';
            const customFields = t.customFields ? Object.keys(t.customFields).map(key => t.customFields[key]).join(',') : '';
            return (
              !t.isDeleted &&
              (
                t.name.toLowerCase().indexOf(search) > -1 ||
                t.id_num.toString().toLowerCase().indexOf(search) > -1 ||
                group.toLowerCase().indexOf(search) > -1 ||
                priorityOrder.toLowerCase().indexOf(search) > -1 ||
                site.toLowerCase().indexOf(search) > -1 ||
                critical.toLowerCase().indexOf(search) > -1 ||
                safety.toLowerCase().indexOf(search) > -1 ||
                sendTo.toLowerCase().indexOf(search) > -1 ||
                customTable.toLowerCase().indexOf(search) > -1 ||
                inventoryParts.toLowerCase().indexOf(search) > -1 ||
                customFields.toLowerCase().indexOf(search) > -1 ||
                (t.code ? t.code.toLowerCase().indexOf(search) > -1 : false)
              )
            );
          });
          return of(tools);
        }
        return of([]);
      })
    );

    $(document).on("keyup", (event) => {
      if (event.keyCode === 27) {
        this.closeNavSearch();
      }
    });
  }

  closeNavSearch() {
    this.onclose.emit();
  }

  handleForm() {}

  onSelect($event) {
    const tool = this.toolsService
      .getTools()
      .find(
        (t) =>
          $($event.target).val() &&
          t.name.toLowerCase() == $($event.target).val().toLowerCase()
      );
    if (tool) {
      this.term = null;
      this.closeNavSearch();
      this.removeSearchFocus();
      if (tool.isGroup) {
        this.router.navigate(["main", "maintenance", tool.id_num]);
      } else {
        this.router.navigate(["main", "maintenance", "tool", tool.id_num]);
      }
    } else {
      this.term = null;
      if (typeof $event.item != "undefined") {
        if ($event.item.isGroup) {
          this.router.navigate(["main", "maintenance", $event.item.id_num]);
          this.term = null;
          this.closeNavSearch();
          this.removeSearchFocus();
        } else {
          this.router.navigate([
            "main",
            "maintenance",
            "tool",
            $event.item.id_num,
          ]);
          this.term = null;
          this.closeNavSearch();
          this.removeSearchFocus();
        }
      } else {
        $(".navbar-form").addClass("navbar-search-focus");
        setTimeout(() => {
          $(".navbar-form").addClass("navbar-search-focus");
        }, 0);
      }
    }
  }

  addClassToFormGroup = (event) => {
    event.stopPropagation();
    $(".navbar-form").addClass("navbar-search-focus");
  };

  removeClassToFormGroup = (event) => {
    event.stopPropagation();
    this.removeSearchFocus();
  };

  removeSearchFocus = () => {
    $(".navbar-form").removeClass("navbar-search-focus");
  };
}
