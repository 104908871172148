import { Directive, OnDestroy } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ToasterService } from 'angular2-toaster';
import { Subscription } from 'rxjs';

@Directive({
  selector: 'ag-grid-angular'
})

/**
 * Safari Mobile will not emit the double click event if there are certain CSS styles in use, this
 * directive will attempt to emulate the double click event by subscribing to the click event and
 * keeping track of the time between the clicks.
 *
 * https://github.com/ag-grid/ag-grid/issues/2707
 */
export class AgGridMobileDoubleClickDirective implements OnDestroy {
  private readonly doubleClickTime: number = 200; // time in ms

  private onRowClickSubscription: Subscription;
  private lastClickTime: number;
  private lastRowIndex: number;

  constructor(agGrid: AgGridAngular) {
    // only add subsciption if we are on mobile safari, otherwise it will fire the double click twice
    this.onRowClickSubscription = agGrid.cellClicked.subscribe(
      event => {
        const currentTime = (new Date()).getTime();
        if (this.lastClickTime && (currentTime - this.lastClickTime) <= this.doubleClickTime && event.rowIndex === this.lastRowIndex) {
          agGrid.rowDoubleClicked.emit(event);
          this.lastClickTime = null;
        } else {
          this.lastClickTime = currentTime;
          this.lastRowIndex = event.rowIndex;
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.onRowClickSubscription)
      this.onRowClickSubscription.unsubscribe();
  }
}