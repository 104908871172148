import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Site } from '../../interfaces/site';

@Injectable({
  providedIn: 'root'
})
export class SitesBackendService {

  url = '/api/sites';

  constructor(private http: HttpClient) { }

  list() {
    return this.http.get<Array<Site>>(this.url)
  }

  listAll() {
    return this.http.get<Array<Site>>(`${this.url}?show=all`)
  }

  create(data: Site) {
    return this.http.post(this.url, data);
  }

  update(data: Site) {
    return this.http.put(`${this.url}/${data._id}`, data);
  }

  delete(data: Site) {
    return this.http.delete(`${this.url}/${data._id}`);
  }

}
