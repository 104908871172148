export enum ConfirmResult{
    CONFIRM,
    DECLINE,
    CANCEL
}

export enum ConfirmType{
    DEFAULT,
    CONFIRM_ONLY,
    TIMER
}