import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ICreateMachineStatus } from '../../interfaces/machine-status';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MachineStatusesBackendService {
  url = '/api/machine-statuses';

  constructor(private http: HttpClient) { }

  list() {
    return this.http.get(this.url);
  }

  listAll() {
    return this.http.get(`${this.url}?show=all`);
  }

  search(search: object): Observable<ICreateMachineStatus[]> {
    return this.http.post<ICreateMachineStatus[]>(`${this.url}/search`, search);
  }

  create(data: ICreateMachineStatus) {
    return this.http.post(this.url, data);
  }

  delete(statusId: number) {
    return this.http.delete(`${this.url}/${statusId}`);
  }


  update(data){
    return this.http.put(`${this.url}/${data._id}`, data)
  }
}
