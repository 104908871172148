<div class="wrapper">
  <div class="block-center">
    <!-- START card-->
    <div class="logo text-center">
      <img
        src="assets/img/Logo land - Regular White@3x.png"
        alt="logo"
        class="brand-logo"
      />
      <!--./assets/img/logo2.png.jpg-->
    </div>
    <div class="card card-flat">
      <!--<div class="card-header text-center">-->
      <!--<h2>Zorgtech</h2>-->
      <!--</div>-->
      <div class="">
        <div class="log-in-header">
          <p class="log-in-paragraph text-center">Log in</p>
        </div>
        <div class="line"></div>
        <re-captcha
          #captchaRef="reCaptcha"
          (resolved)="resolved($event)"
          (error)="onError($event)"
          errorMode="handled"
          size="invisible"
        ></re-captcha>
        <form
          [formGroup]="valForm"
          class="login-form form-validate"
          role="form"
          name="loginForm"
          novalidate=""
        >
          <div class="form-group">
            <p>Company name</p>
            <div class="input-group with-focus">
              <input
                class="form-control"
                id="inputCompany"
                type="text"
                name="company"
                placeholder="Company name"
                autocomplete="company"
                formControlName="company"
                required=""
                autofocus
                (keypress)="commonService.typeA($event)"
              />
            </div>
            <div
              class="text-danger"
              *ngIf="
                valForm.get('company').hasError('required') &&
                (valForm.get('company').dirty || valForm.get('company').touched)
              "
            >
              This field is required
            </div>
          </div>
          <div class="clearfix text-center text-danger" *ngIf="errorMessage">
            {{ errorMessage }}
          </div>

          <div
            class="clearfix text-center"
            *ngIf="showResendVerification && resendVerificationId"
          >
            <a href="#" (click)="sendVerification()">
              click here to resend verification email
            </a>
          </div>

          <div class="anymaint-block" (click)="submitForm($event, valForm.value)">
            <span>
              Login with <img src="assets/img/anymaint-ad-saml.png" alt="Logo" class="active-anymaint-directory">
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Cookie banner -->
<app-cookie-banner></app-cookie-banner>
