import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { merge } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observer } from 'rxjs';
import { Tool } from '../../../core/interfaces/tool';
import { ToolEvent } from '../../../core/interfaces/tool-event';
import { CommonService } from '../../../core/services/common.service';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';
import { UsersStoreService } from '../../../core/services/users-store.service';
import { AuthService } from '../../../routes/user/auth.service';
import { WorkerSignature } from '../../models/worker-signature';

@Component({
  selector: 'app-worker-confirm',
  templateUrl: './worker-confirm.component.pug',
  styleUrls: ['./worker-confirm.component.scss']
})
export class WorkerConfirmComponent implements OnInit {

  form: UntypedFormGroup;
  @Input() type: string = '';
  @Input() button: string = '';
  @Input() event: ToolEvent = null;
  @Input() tool: Tool = null;
  @Input() eventFinish: boolean = false;
  observer: Observer<WorkerSignature>

  constructor(
    public bsModalRef: BsModalRef,
    public fb: UntypedFormBuilder,
    private translate: TranslateService,
    private toaster: ToasterService,
    private usersStoreService: UsersStoreService,
    public authService: AuthService,
    public translationService: TranslationsStoreService,
    public commonService: CommonService,
    private router: Router
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      username: this.fb.control('', Validators.required),
      password: this.fb.control('', Validators.required),
    });
  }

  checkRequireTwoSignature = () => {
    return this.event.checklistItems.filter(ck => ck.checklistItem.twoSignature).length > 0;
  }

  save = () => {
    if (this.form.valid) {
      if(this.eventFinish && this.checkRequireTwoSignature()){
        if(this.event.firstSignaturePerson){
          this.usersStoreService.validateUser(this.form.value).subscribe(
            (data:any) => {
              if(data.user._id.toString() == this.event.firstSignaturePerson.toString()){
                this.toaster.pop("error", this.translate.instant("toasters.EVENT.SIGNATURE-CAN-NOT-BE-SAME"));
              }else{
                this.doSignature();
              }
            },
            (err) => {
              this.toaster.pop("error", this.translate.instant('worker.SIGNATURE-NOT-VERIFIED'));
            },
          )
        }else{
          this.doSignature();
        }
      }else{
        this.doSignature();
      }
    }
  }

  doSignature(){
    this.usersStoreService.validate(merge({ signedOn: this.type, event: this.event ? this.event._id : null, controlButton: this.button, url: this.router.url }, this.form.value)).subscribe(
      (data: any) => {
        const result = {
          result: true,
          payload: merge(this.form.value, { _id: data.user._id, signatureId: data.signatureAuditTrail })
        }
        this.toaster.pop("success", this.translate.instant('worker.SIGNATURE-VERIFIED'));
        this.bsModalRef.hide();
        this.observer.next(result);
      },
      (err) => {
        this.toaster.pop("error", this.translate.instant('worker.SIGNATURE-NOT-VERIFIED'));
      },
    )
  }

}
