import { timeCalculation } from "../../../shared/comparators/date-comparator";
import { IField } from "../../interfaces/field";
const FieldDefinition: IField[] = [
    {
        name: 'company.name',
        label: 'table.events.table-title.COMPANY',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'sla',
    },
    {
        name: 'id_num',
        label: 'table.events.table-title.Event-ID',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'sla',
    },
    {
        name: 'tool.name',
        label: 'table.events.table-title.TOOL-NAME',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'sla',
    },
    {
        name: 'tool.id_num',
        label: 'table.pm.table-title.TOOL-ID',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'sla',
    },
    {
        name: 'type',
        label: 'table.events.table-title.TYPE',
        type: 'textbox',
        required: true,
        readonly: true,
        section: 'sla',
    },
    {
        name: 'worker.displayName',
        label: 'table.events.table-title.WORKER',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'sla',
    },
    {
        name: 'start',
        label: 'table.events.table-title.START',
        type: 'datepicker',
        required: false,
        readonly: true,
        section: 'sla',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.start ? nodeA.data.start : null, nodeB && nodeB.data.start ? nodeB.data.start : null, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'finish',
        label: 'table.events.table-title.FINISH',
        type: 'datepicker',
        required: false,
        readonly: true,
        section: 'sla',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.finish ? nodeA.data.finish : null, nodeB && nodeB.data.finish ? nodeB.data.finish : null, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'problemDesc',
        label: 'table.events.table-title.PROBLEM',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'sla'
    },
    {
        name: 'solutionDesc',
        label: 'table.events.table-title.SOLUTION',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'sla',
    },
    {
        name: 'parts',
        label: 'table.events.table-title.PARTS-REPLACED',
        type: 'textbox',
        required: false,
        readonly: true,
        valueGetter: params => params.data && params.data.parts && params.data.parts.length ? params.data.parts.filter(part => part.part && part.part.name).map(part => part.part.catalogNumber + " | " + part.part.name + " (" + part.quantity + ")").join("; ") : "",
        tooltipValueGetter: params => params.data && params.data.parts && params.data.parts.length ? params.data.parts.filter(part => part.part && part.part.name).map(part => part.part.catalogNumber + " | " + part.part.name + " (" + part.quantity + ")").join("\n") : "",
        section: 'sla',
    },
    {
        name: 'eventType',
        label: 'table.events.table-title.PM/FM',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'even-logs',
        valueGetter: params => params.data.maintenance ? "PM" : "FM"
    },
    {
        name: 'responsiblePersonObject.displayName',
        label: 'table.pm.table-title.RESPONSIBLE-PERSON',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'sla',
    },
    {
        name: 'departmentObject.name',
        label: 'table.users.table-title.DEPARTMENT',
        type: 'textbox',
        options: [],
        required: false,
        readonly: true,
    },
    {
        name: 'file',
        label: 'table.pm.table-title.FILE-NAME',
        type: 'textbox',
        required: true,
        readonly: true,
        section: 'sla',
    },
    {
        name: 'isDeleted',
        label: 'general.DELETED',
        type: "checkbox",
        readonly: true,
        section: 'sla',
    },
    {
        name: 'isWaReporter',
        label: 'table.pm.table-title.WA-REPORTER',
        type: "checkbox",
        readonly: true,
        section: 'sla',
    },
    {
        name: 'updatedAt',
        label: 'table.pm.table-title.TIME-OF-CHANGE',
        type: 'datepicker',
        readonly: true,
        section: 'sla',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.updatedAt ? nodeA.data.updatedAt : null, nodeB && nodeB.data.updatedAt ? nodeB.data.updatedAt : null, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'signaturePersonObject.displayName',
        label: 'table.pm.table-title.SIGNATURE-PERSON',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'sla',
    },
    {
        name: 'updatedBy',
        label: 'table.pm.table-title.WHO-DID-CHANGE',
        type: "textbox",
        readonly: true,
        section: 'sla',
        valueGetter: params => {
            return params.data.updatedBy ? params.data.updatedBy.displayName : (params.data.createdBy ? params.data.createdBy.displayName : '');
        },
    },
    {
        name: 'event.createdAt',
        label: 'table.pm.table-title.HOW-MUCH-TIME-FROM-START',
        type: 'textbox',
        readonly: true,
        section: 'sla',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.createdAt, nodeA && nodeA.data.firstChangedAt ? nodeA.data.firstChangedAt : null, nodeA, nodeB, isInverted) - timeCalculation(nodeB && nodeB.data.createdAt, nodeB && nodeB.data.firstChangedAt ? nodeB.data.firstChangedAt : null, nodeA, nodeB, isInverted)
        }
    },
    {
        name: 'qaApprovalObject.displayName',
        label: 'table.events.table-title.QA-APPROVED-PERSON',
        type: 'textbox',
        required: false,
        readonly: true,
        section: 'events',
    },
    {
        name: 'qaApprovalTime',
        label: 'table.events.table-title.QA-APPROVED-TIME',
        type: 'datepicker',
        required: false,
        readonly: true,
        section: 'events',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.qaApprovalTime ? nodeA.data.qaApprovalTime : null, nodeB && nodeB.data.qaApprovalTime ? nodeB.data.qaApprovalTime : null, nodeA, nodeB, isInverted);
        }
    },
];

export default {
    FieldDefinition
}