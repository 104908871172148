import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../../routes/user/auth.service';
import { Translation } from '../interfaces/translation';
import { TranslationsBackendService } from './backend/translations-backend.service';
import { CustomFieldsStoreService } from './custom-fields-store.service';
import FieldDefinition from '../../core/constants/field-definitions/translation';
import * as en from '../../../assets/i18n/English.json';
import { TranslateService } from '@ngx-translate/core';
import { flatMap, get, isObject, set } from 'lodash';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class TranslationsStoreService {
  protected translationsSubject: BehaviorSubject<List<Translation>> = new BehaviorSubject(List([]));
  protected companyTranslationsSubject: BehaviorSubject<Translation> = new BehaviorSubject(null);
  public readonly translations$: Observable<List<Translation>> = this.translationsSubject.asObservable();
  protected translationsLanguageSubject: BehaviorSubject<List<Translation>> = new BehaviorSubject(List([]));
  public readonly translationsLanguage$: Observable<List<Translation>> = this.translationsLanguageSubject.asObservable();
  public readonly translationsGrid$: Observable<List<any>>;
  public readonly translationsMasterGrid$: Observable<List<any>>;
  public readonly translation$: Observable<Translation>;
  public readonly dir$: Observable<String>;
  public readonly rtl$: Observable<boolean>;
  public readonly languageCode$: Observable<string>;
  public columns$: Observable<any[]>;
  public columnMaster$: Observable<any[]>;
  public schema: Array<String> = [];
  public language$: Observable<String> = of("English");
  user: any;

  constructor(protected backend: TranslationsBackendService,
    protected customFields: CustomFieldsStoreService,
    protected translate: TranslateService,
    protected auth: AuthService) {

    this.onInit()

    this.schema = this.getSchema((en as any).default);

    this.translationsGrid$ = this.translationsSubject.pipe(
      map((translations: List<Translation>) => {
        const english = translations.find(translation => translation.name === "English");
        if (english) {
          this.schema = this.getSchema(english.data);
        }
        let data: any = this.schema.reduce((acc, curr: string) => ({ ...acc, [curr]: { key: curr } }), {});
        translations.map((translation) => {
          this.schema.map((row: string) => {
            data[row][translation.name as string] = get(translation.data, row)
          })
        });
        return List(Object.keys(data).map(row => data[row]))
      }),
    )

    this.translationsMasterGrid$ = this.translationsSubject.pipe(
      map((translations: List<Translation>) => {
        translations = translations.filter(translation => isObject(translation.company));
        const companyWiseTranslation = translations.reduce((h, obj: any) => Object.assign(h, { [obj.company._id]: (h[obj.company._id] || []).concat(obj) }), {})

        this.schema = this.getSchema((en as any).default);

        let translationsData: any = {};
        for (let i in companyWiseTranslation) {
          let translationsDataCompany: any = this.schema.reduce((acc, curr: string) => ({ ...acc, [i + '-' + curr]: { key: curr } }), {});
          companyWiseTranslation[i].map((company) => {
            this.schema.map((row: string) => {
              translationsDataCompany[company.company._id + '-' + row]['company'] = company.company;
              translationsDataCompany[company.company._id + '-' + row][company.name as string] = get(company.data, row)
            })
            translationsData = { ...translationsData, ...translationsDataCompany };
          });
        }
        return List(Object.keys(translationsData).map(row => translationsData[row]))
      }),
    )

    this.columnMaster$ = this.translations$.pipe(
      map(translations => {
        return this.customFields.toColumnDef(
          [...FieldDefinition.FieldDefinition.filter(col => col.name != "company.name" || (this.user && this.user.isRoot())), ...translations.filter(translation => translation.name !== "English").filter((thing: any, index, self) => index === self.findIndex((t: any) => (t.name === thing.name))).map(translation => (
            {
              name: translation.name,
              label: translation.name,
              type: 'textbox',
              required: false,
              readonly: false,
            }
          )).toArray()]
        )
      })
    )

    this.columns$ = this.translations$.pipe(
      map(translations => {
        return this.customFields.toColumnDef(
          [...FieldDefinition.FieldDefinition.filter(col => col.name != "company.name" || (this.user && this.user.isRoot())), ...translations.filter(translation => translation.name !== "English").map(translation => (
            {
              name: translation.name,
              label: translation.name,
              type: 'textbox',
              required: false,
              readonly: false,
              section: 'translations',
            }
          )).toArray()],
          {
            rowSelector: 'key'
          })
      })
    )

    this.translations$.subscribe(translations => {
      translations.forEach(translation => {
        translate.setTranslation(translation.name as string, translation.data, true);
      })
    });


    this.language$ = combineLatest([this.translations$, this.auth.user$]).pipe(
      map(([translations, user]) => {
        if (!translations || !translations.count || !user || !user._id || !user.language || !translations.some(t => t.name === user.language)) return "English";

        return translations.find(t => t.name === user.language).name
      }),
      tap(language => this.translate.use(language as string))
    )

    this.dir$ = combineLatest([this.translations$, this.auth.user$]).pipe(
      map(([translations, user]) => {
        try {
          const translation: Translation = !user ? translations.first() : translations.find(t => t.name === user.language) || translations.first()
          return translation.rtl ? "rtl" : "ltr"
        } catch (e) {
          return "ltr"
        }
      })
    )

    this.rtl$ = combineLatest([this.translations$, this.auth.user$]).pipe(
      map(([translations, user]) => {
        try {
          const translation: Translation = !user ? translations.first() : translations.find(t => t.name === user.language) || translations.first()
          return translation.rtl ? true : false
        } catch (e) {
          return false
        }
      })
    )

    this.languageCode$ = combineLatest([this.translations$, this.auth.user$]).pipe(
      map(([translations, user]) => {
        try {
          let code = 'en';
          const translation: Translation = !user ? translations.first() : translations.find(t => t.name === user.language) || translations.first()
          switch (translation.name.toLowerCase()) {
            case 'עברית' || 'he' || 'hebrew':
              code = 'he';
              break;
            case 'russian' || 'русский':
              code = 'ru';
              break;
            case 'turkish' || 'türk':
              code = 'tr';
              break;
            case 'chineese' || '中国人':
              code = 'zh';
              break;
            case 'german' || 'deutsch':
              code = 'de';
              break;
            // case 'عربى':
            //   code = 'ar';
            //   break;
            case 'espanyol' || 'spanish' || 'español':
              code = 'es';
              break;
            default:
              break;
          }
          return code;
        } catch (e) {
          return 'en';
        }
      })
    )
  }

  onInit() {
    this.auth.user$.subscribe(user => {
      this.user = user;
      if (!user || !user._id) {
        return
      }
      this.loadFirstLanguage(this.user.language ? this.user.language : "English").subscribe();
      this.loadLanguages().subscribe();
    });

  }

  loadFirstLanguage = (lang: string) => {
    return this.loadOne(lang)
    .pipe(
      tap((translations: Array<Translation>) => this.translationsSubject.next(List(translations)))
    );
  }

  loadCompanyLanguage = (lang: string) => {
    return this.loadOne(lang)
    .pipe(
      tap((translations: Array<Translation>) => {
        const t = translations.find(translation => translation.name === lang);
        this.companyTranslationsSubject.next(t);
      })
    );
  }

  getCompanyLanguageRtl = () => {
    if(this.companyTranslationsSubject.getValue()){
      return this.companyTranslationsSubject.getValue().rtl;
    }else{
      const t = this.translationsSubject.getValue().find(t => t.name === this.auth.getUserLanguage());
      return (t ? t.rtl : false);
    }
  }

  getCompanyLanguage = () => {
    return this.companyTranslationsSubject.getValue();
  }

  loadOne(lang: string) {
    return this.backend.get(lang)
      .pipe(
        map((translations: Array<Translation>) => {
          const trans = translations.filter(tran => tran.name !== "English");
          const english = translations.find(tran => tran.name === "English");

          let defaultTranslation: Translation = new Translation({ name: 'English', data: this.getDefault(), company: english && typeof english.company != 'undefined' ? english.company : null, _id: english && typeof english._id != 'undefined' ? english._id : null })
          if (english) {
            const d =this.getSchema(defaultTranslation.data)
            let dData: any = d.reduce((acc:any, curr: string) => ({ ...acc, [curr]: { key: curr } }), {});
            d.map((row: string) => {
              dData[row] = get(defaultTranslation.data, row)
            })
            const e = this.getSchema(english.data)
            let eData: any = e.reduce((acc:any, curr: string) => ({ ...acc, [curr]: { key: curr } }), {});
            e.map((row: string) => {
              eData[row] = get(english.data, row)
            })
            let newData = {};
            const mergedValue = {...dData, ...eData};
            for(let m in mergedValue){
              newData = set(newData, m, mergedValue[m]);
            }
            defaultTranslation.data = newData;
          }
          return [...trans, defaultTranslation]
        })
      );
  }

  loadLanguages(){
    return this.backend.listLanguages()
    .pipe(
      tap((translations: Array<Translation>) => this.translationsLanguageSubject.next(List(translations)))
    )
  }

  load() {
    return this.backend.list()
      .pipe(
        map((translations: Array<Translation>) => {
          const trans = translations.filter(tran => tran.name !== "English");
          const english = translations.find(tran => tran.name === "English");

          let defaultTranslation: Translation = new Translation({ name: 'English', data: this.getDefault(), company: english && typeof english.company != 'undefined' ? english.company : null, _id: english && typeof english._id != 'undefined' ? english._id : null })
          if (english) {
            const d =this.getSchema(defaultTranslation.data)
            let dData: any = d.reduce((acc:any, curr: string) => ({ ...acc, [curr]: { key: curr } }), {});
            d.map((row: string) => {
              dData[row] = get(defaultTranslation.data, row)
            })
            const e = this.getSchema(english.data)
            let eData: any = e.reduce((acc:any, curr: string) => ({ ...acc, [curr]: { key: curr } }), {});
            e.map((row: string) => {
              eData[row] = get(english.data, row)
            })
            let newData = {};
            const mergedValue = {...dData, ...eData};
            for(let m in mergedValue){
              newData = set(newData, m, mergedValue[m]);
            }
            defaultTranslation.data = newData;
          }
          return [...trans, defaultTranslation]
        }),
        tap((translations: Array<Translation>) => this.translationsSubject.next(List(translations)))
      );
  }

  public create(translation: Translation) {
    return this.backend.create(translation)
      .pipe(
        tap(translation => {
          this.translationsSubject.next(this.translationsSubject.getValue().unshift(translation));
        }));
  }

  public update(translation: Translation) {
    return this.backend.update(translation).pipe(
      tap((translation: Translation) => {
        const tools = this.translationsSubject.getValue();
        const idx = tools.findIndex((t: Translation) => t._id === translation._id);
        this.translationsSubject.next(tools.set(idx, translation));
      })
    )
  }

  public delete(translation: Translation) {
    return this.backend.delete(translation).pipe(
      tap(() => {
        const translations = this.translationsSubject.getValue();
        const idx = translations.findIndex((t: Translation) => t._id === translation._id);
        this.translationsSubject.next(translations.splice(idx, 1));
      }));
  }

  getDefault() {
    return (en as any).default;
  }


  flatten = (object: any, parent: String) => {
    const result = this.getSchema(object)
  }

  getSchema = (val, keys = []) =>
    isObject(val) ? // if it's an object or array
      flatMap(val, (v, k) => this.getSchema(v, [...keys, k])) // iterate it and call fn with the value and the collected keys
      :
      keys.join('.') // return the joined keys

  convertFromGrid = (data: any[]) =>
    this.translationsSubject.getValue().reduce((acc, curr) => {
      const lang = curr.name as string;
      let changed: boolean = false;

      data.forEach(field => {
        let value = get(curr.data, field.key);
        let newVal = field[lang];
        if (newVal !== value) {
          curr.data = set(curr.data, field.key, newVal ? newVal : null);
          changed = true;
        }
      })
      if (changed) {
        acc = [...acc, curr]
      }

      return acc
    }, []);

  convertMasterFromGrid = (data: any[]) =>
    this.translationsSubject.getValue().reduce((acc, curr: any) => {
      const lang = curr.name as string;
      let changed: boolean = false;
      data.forEach(field => {
        if (field.company && curr.company && field.company._id == curr.company._id) {
          let value = get(curr.data, field.key);
          let newVal = field[lang];
          if (newVal !== value) {
            curr.data = set(curr.data, field.key, newVal ? newVal : null);
            changed = true;
          }
        }
      })
      if (changed) {
        acc = [...acc, curr]
      }
      return acc
    }, []);

  Languages = () => this.translationsSubject.getValue()

  generateCustomFields = () => this.backend.generateCustomFields()

  forceUpdatetoAllCompanies = (keys) => this.backend.forceUpdatetoAllCompanies(keys)

  translateToNative = (values: string[], lang: string = '') => this.backend.translateToNative(values, lang)
}
