import { ToolEvent } from './tool-event';
import { User } from '../../routes/user/login.response';
import { Tool } from './tool';
import { Maintenance } from './maintenance';

export class PmCalender {
  _id?: string;
  id_num?: Number;
  company?: string;
  tool?: Tool;
  maintenance?: Maintenance;
  dueDate?: Date;
  actualDate?: Date;
  event?: ToolEvent;
  worker?: User;
  isDeleted?: Boolean;
  status?: string;
  customFields?: Object;
  updatedAt?: Date;
  createdAt?: Date;
  tempEvent?: string;
  breadcrumbs?: string;
  groupName?: string;

  constructor(options: {
    _id?: string,
    id_num?: Number,
    company?: string,
    tool?: Tool,
    maintenance?: Maintenance,
    dueDate?: Date,
    actualDate?: Date,
    event?: ToolEvent,
    worker?: User,
    isDeleted?: Boolean,
    status?: string,
    customFields?: Object,
    updatedAt?: Date,
    createdAt?: Date,
    tempEvent?: string,
    breadcrumbs?: string,
    groupName?: string,
  }) {
    this._id = options._id || undefined;
    this.id_num = options.id_num || 0;
    this.company = options.company || null;
    this.tool = options.tool || null;
    this.maintenance = options.maintenance || null;
    this.dueDate = options.dueDate || null;
    this.actualDate = options.actualDate || null;
    this.event = options.event || null;
    this.worker = options.worker || null;
    this.isDeleted = options.isDeleted || false;
    this.status = options.status || null;
    this.customFields = options.customFields || null;
    this.createdAt = options.createdAt || new Date;
    this.updatedAt = options.updatedAt || new Date;
    this.tempEvent = options.tempEvent || null;
    this.breadcrumbs = options.breadcrumbs || null;
    this.groupName = options.groupName || null;
  }
}
