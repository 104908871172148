import { Company } from "./company";
import { Tool } from "./tool";
import { ToolEvent } from "./tool-event";
export class EventLogs {
    _id?: string;
    company?: string | Company;
    tool?: string | Tool;
    event?: string | ToolEvent;
    changedData?: any;
    currentData?: ToolEvent;
    type?: string;
    createdAt?: Date;

    constructor(options: {
        _id?: string;
        company?: string | Company;
        tool?: string | Tool;
        event?: string | ToolEvent;
        changedData?: any;
        currentData?: ToolEvent;
        type?: string;
        createdAt?: Date;
    }) {

        this._id = options._id || undefined;
        this.company = options.company || null;
        this.tool = options.tool || null;
        this.event = options.event || null;
        this.changedData = options.changedData || null;
        this.currentData = options.currentData || null;
        this.type = options.type || null;
        this.createdAt = options.createdAt || new Date();
    }
}