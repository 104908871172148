export class Site {
    _id?: string;
    id_num?: Number;
    company?: string;
    name?: string;
    customFields?: Object;
    isDeleted?: boolean;
    createdAt?: Date;
    updatedAt?: Date;

    constructor(options: {
        _id?: string,
        id_num?: Number,
        company?: string,
        name?: string,
        customFields?: Object,
        isDeleted?: boolean,
        createdAt?: Date,
        updatedAt?: Date,
    }) {
        this._id = options._id || undefined;
        this.id_num = options.id_num || null;
        this.company = options.company || null;
        this.name = options.name || null;
        this.customFields = options.customFields || null;
        this.isDeleted = options.isDeleted || false;
        this.createdAt = options.createdAt || null;
        this.updatedAt = options.updatedAt || null;
    }
}
