import { Injectable } from '@angular/core';
import {AuthService} from '../routes/user/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(private auth: AuthService) { }

  initApplication() {
    return this.auth.initAuth().toPromise();
  }
}
