import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Tool } from '../../../../core/interfaces/tool';
import { FilesStoreService } from '../../../../core/services/files-store.service';
import { ToolsStoreService } from '../../../../core/services/tools-store.service';
import { TranslationsStoreService } from '../../../../core/services/translations-store.service';
import { AuthService } from '../../../user/auth.service';

@Component({
  selector: 'app-assign-to-group',
  templateUrl: './assign-to-group.component.pug',
  styleUrls: ['./assign-to-group.component.scss']
})
export class AssignToGroupComponent implements OnInit {

  form: UntypedFormGroup;
  groups: any;
  @Input() tool: Tool


  constructor(
    public bsModalRef: BsModalRef,
    public fb: UntypedFormBuilder,
    private translate: TranslateService,
    private toolsService: ToolsStoreService,
    private toaster: ToasterService,
    public authService: AuthService,
    public fileService: FilesStoreService,
    public translationService: TranslationsStoreService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      group: this.fb.control(this.tool.group ? this.tool.group._id : null),
      name: this.fb.control(this.tool.name),
    });
    this.toolsService.toolsUpdated.subscribe((data) => {
      this.groups = this.toolsService.getGroups().filter(group => !group.isDeleted && group._id.toString() != this.tool._id.toString()).map(group => ({ id: group._id, text: group.name }));
    });
  }

  save = () => {
    if (this.form.valid) {
      this.tool.group = this.form.value.group
      this.toolsService.update(this.tool).subscribe(
        () => {
          this.toaster.pop("success", this.translate.instant('toasters.GENERAL.OK_SAVE'));
          this.bsModalRef.hide();
        },
        () => {
          this.toaster.pop("error", this.translate.instant('toasters.GENERAL.ERR_SAVE'));
          this.bsModalRef.hide();
        }
      )
    }
  }

}
