import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CheckboxItem} from './checkboxitem';

@Component({
  selector: 'app-checklist',
  templateUrl: 'checklist.component.html'
})

export class ChecklistComponent implements OnInit {

  @Input() options = Array<CheckboxItem>();
  @Input() selectedValues: string[];
  @Output() toggle = new EventEmitter<any[]>();

  constructor() {
  }

  ngOnInit() {
  }

  onToggle() {
    const checkedOptions = this.options.filter(x => x.checked);
    this.selectedValues = checkedOptions.map(x => x.value);
    this.toggle.emit(checkedOptions.map(x => x.value));
   }
}
