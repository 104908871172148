import { IField } from "../../interfaces/field";
const FieldDefinition: IField[] = [
  {
    name: "id_num",
    label: "table.tools.table-title.ID",
    type: "textbox",
    required: false,
    readonly: true,
  },
  {
    name: "company.name",
    label: "table.tools.table-title.COMPANY",
    type: "textbox",
    required: false,
    readonly: true,
  },
  {
    name: "code",
    label: "table.tools.table-title.CODE",
    type: "textbox",
    required: false,
    readonly: false,
  },
  {
    name: "name",
    label: "table.tools.table-title.TOOL-NAME",
    type: "textbox",
    required: true,
    readonly: false,
  },
  {
    name: "group",
    label: "table.tools.table-title.GROUP",
    type: "dropdown",
    options: [],
    required: false,
    readonly: false,
  },
  {
    name: "groupId",
    label: "table.tools.table-title.GROUP-ID",
    type: "textbox",
    required: false,
    readonly: true,
    hide: true,
  },
  {
    name: "tool",
    label: "table.tools.table-title.TOOL",
    type: "selectdropdown",
    options: [],
    required: false,
    readonly: false,
    section: "tools",
  },
  {
    name: "toolObject.id_num",
    label: "table.tools.table-title.TOOL-ID",
    type: "textbox",
    required: false,
    readonly: true,
    hide: true,
  },
  {
    name: "isGroup",
    label: "table.tools.table-title.TYPE",
    type: "textbox",
    required: false,
    readonly: true,
  },
  {
    name: "priorityOrder",
    label: "table.tools.table-title.PRIORITY-ORDER",
    type: "numeric",
    required: false,
    readonly: false,
  },
  {
    name: "siteObject.id_num",
    label: "table.tools.table-title.SITE-ID",
    type: "textbox",
    required: false,
    readonly: true,
    hide: true,
  },
  {
    name: "site",
    label: "table.tools.table-title.SITE",
    type: "selectdropdown",
    options: [],
    required: false,
    readonly: false,
  },
  {
    name: "critical",
    label: "table.tools.table-title.CRITICAL",
    type: "checkbox",
    required: false,
    readonly: false,
    section: "tools",
  },
  {
    name: "safety",
    label: "table.tools.table-title.SAFETY",
    type: "checkbox",
    required: false,
    readonly: false,
    section: "tools",
  },
  {
    name: "sendToObject.id_num",
    label: "table.tools.table-title.SEND-TO-ID",
    type: "textbox",
    required: false,
    readonly: true,
    hide: true,
    valueGetter: (params) =>
      params.data &&
      params.data.sendToObject &&
      params.data.sendToObject.length > 0
        ? params.data.sendToObject
            .filter((s) => s && s.id_num)
            .map((s) => (s && s.id_num ? s.id_num : ""))
            .join(",")
        : "",
  },
  {
    name: "sendTo",
    label: "table.tools.table-title.SEND-TO",
    type: "multipledropdown",
    options: [],
    required: false,
    readonly: false,
  },
  {
    name: "drawing",
    label: "table.tools.table-title.DRAWING",
    type: "checkbox",
    required: false,
    readonly: false,
  },
  {
    name: "customTable",
    label: "table.tools.table-title.CUSTOM-TABLE",
    type: "multipledropdown",
    options: [],
    required: false,
    readonly: false,
  },
  {
    name: "hideDataInGraphs",
    label: "table.tools.table-title.HIDE-DATA-IN-GRAPHS",
    type: "checkbox",
    required: false,
    readonly: false,
  },
  {
    name: "inventoryPartsObject.id_num",
    label: "table.tools.table-title.INVENTORY-PARTS-ID",
    type: "textbox",
    required: false,
    readonly: true,
    hide: true,
    valueGetter: (params) =>
      params.data &&
      params.data.inventoryPartsObject &&
      params.data.inventoryPartsObject.length > 0
        ? params.data.inventoryPartsObject
            .filter((s) => s && s.id_num)
            .map((s) => (s && s.id_num ? s.id_num : ""))
            .join(",")
        : "",
  },
  {
    name: "inventoryParts",
    label: "table.tools.table-title.INVENTORY-PARTS",
    type: "multipledropdown",
    options: [],
    required: false,
    readonly: false,
  },
  {
    name: "pointersObject.id_num",
    label: "table.tools.table-title.POINTER-TOOL-IDS",
    type: "textbox",
    required: false,
    readonly: true,
    hide: true,
    valueGetter: (params) =>
      params.data &&
      params.data.pointersObject &&
      params.data.pointersObject.length > 0
        ? params.data.pointersObject
            .filter((s) => s && s.id_num)
            .map((s) => (s && s.id_num ? s.id_num : ""))
            .join(", ")
        : "",
  },
  {
    name: "pointersObject.name",
    label: "table.tools.table-title.POINTER-TOOLS",
    type: "textbox",
    required: false,
    readonly: true,
    valueGetter: (params) =>
      params.data &&
      params.data.pointersObject &&
      params.data.pointersObject.length > 0
        ? params.data.pointersObject
            .filter((s) => s && s.name)
            .map((s) => (s && s.name ? s.name : ""))
            .join(", ")
        : "",
  },
  {
    name: "isDeleted",
    label: "general.DELETED",
    type: "checkbox",
    required: false,
    readonly: false,
    isAdmin: true,
  },
];

export default {
  FieldDefinition,
};
