import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, combineLatest, of } from "rxjs";
import { List } from "immutable";
import { ToolEvent } from "../interfaces/tool-event";
import { EventsBackendService } from "./backend/events-backend.service";
import { flatMap, map, shareReplay, tap } from "rxjs/operators";
import { AuthService } from "../../routes/user/auth.service";
import { CustomFieldsStoreService } from "./custom-fields-store.service";
import FieldDefinition from "../constants/field-definitions/events";
import { format } from "date-fns";
import { Tool } from "../interfaces/tool";
import { saveAs } from "file-saver";
import { CommonService } from "./common.service";
import { groupBy, isArray, isObject, uniqBy } from "lodash";
import { EventAuditTrail } from "../interfaces/event-audit-trail";
import { TranslateService } from "@ngx-translate/core";
import { LoaderService } from "../utils/loader.service";
import { Router } from "@angular/router";
import { timeCalculation } from "../../shared/comparators/date-comparator";
import { TranslationsStoreService } from "./translations-store.service";
import { EVENT_OBJECT } from "../interfaces/general";
import { EVENT_STATUS } from "../constants/enums/enums";
import { ConfirmService } from "../utils/confirm/confirm.service";
import { ConfirmType } from "../constants/confirm-result.enum";
import { pmKind } from "../constants/pmKind";
import moment from "moment-timezone";
import { MaintenanceTypesStoreService } from "./maintenance-types-store.service";
import { CompaniesStoreService } from "./companies-store.service";

@Injectable({
  providedIn: "root",
})
export class EventsStoreService {
  private skip = 0;
  private limit = 0;

  private eventsSubject: BehaviorSubject<List<ToolEvent>> = new BehaviorSubject(
    List([])
  );
  private activeEventsSubject: BehaviorSubject<List<ToolEvent>> =
    new BehaviorSubject(List([]));
  private totalSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  private pmHistoryTotalSubject: BehaviorSubject<number> = new BehaviorSubject(
    0
  );
  private eventHistoryTotalSubject: BehaviorSubject<number> =
    new BehaviorSubject(0);
  public toolWiseEventSubject: BehaviorSubject<EVENT_OBJECT> =
    new BehaviorSubject({});
  private allFetched: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private lastFetchedTime: BehaviorSubject<string> = new BehaviorSubject(null);

  private statusTotalWithModeratorSubject: BehaviorSubject<List<number>> =
    new BehaviorSubject(List([]));
  public readonly statusWithModeratorTotalCounts$: Observable<List<number>> =
    this.statusTotalWithModeratorSubject.asObservable();

  public readonly events$: Observable<List<ToolEvent>> =
    this.eventsSubject.pipe(
      map((events) => this.normalizeData(events)),
      shareReplay(1)
    );
  public readonly activeEvents$: Observable<List<ToolEvent>> =
    this.activeEventsSubject.pipe(
      map((events) => this.normalizeData(events)),
      shareReplay(1)
    );

  public readonly columns$: Observable<any[]>;

  constructor(
    private backend: EventsBackendService,
    private customFields: CustomFieldsStoreService,
    private auth: AuthService,
    private translate: TranslateService,
    private maintenanceTypesStoreService: MaintenanceTypesStoreService,
    private commonService: CommonService,
    private loader: LoaderService,
    private router: Router,
    private translationsStoreService: TranslationsStoreService,
    private confirm: ConfirmService,
    private companiesStoreService: CompaniesStoreService
  ) {
    auth.user$.subscribe((user) => {
      if (!user || !user._id || user.isRoot()) {
        if (user) {
          this.limit = this.auth.getUser().isRoot() ? 250 : 0;
        }
        this.eventsSubject.next(List([]));
        return;
      }
      this.loader.add();
      this.activeEventList().subscribe((data) => {
        this.loader.remove();
      });
    });

    this.columns$ = combineLatest([
      this.auth.user$,
      this.customFields.get("events"),
      this.customFields.get("pm"),
      this.translationsStoreService.languageCode$,
      this.companiesStoreService.companies$,
    ]).pipe(
      map(([user, columns, pmColumns, languageCode, companies]) => {
        if (!user) return [];
        const hiddenRegularColumns = columns
          .filter((c) => c.isRegularColumn)
          .toArray();
        const defaults = FieldDefinition.FieldDefinition.filter(
          (col) =>
            (col.name != "company.name" || user.isRoot()) &&
            (col.name != "isDeleted" || user.isAdminOrToolAdmin()) &&
            (col.name != "scheduleWork" ||
              this.commonService.hasSmartSchedulePermission()) &&
            (col.name != "hideDataInGraphs" ||
              this.commonService.hasAllowHideDataInGraphs()) &&
            (col.name != "taskProcedure.id_num" ||
              this.commonService.hasProceduresAndTasksPermission()) &&
            ((col.name != "qaApprovalObject.displayName" &&
              col.name != "qaApprovalTime") ||
              this.commonService.hasQaSignature() ||
              this.commonService.hasSafetySignature()) &&
            (col.name != "parentEvent.id_num" ||
              this.commonService.hasConnectedEvents()) &&
              (col.name != "parts" ||
                this.commonService.hasInventoryPermission())
        ).map((col) => {
          if (col.name === "group") {
            col.name = "group.name";
            col.readonly = true;
            col.type = "textbox";
          }
          const f = hiddenRegularColumns.find((c) => c.name === col.name);
          if (f) {
            col.hide = f.hide;
            col.required = f.required;
            col.dontPresentOnModal = f.dontPresentOnModal;
            col.preventEditOnModal = f.preventEditOnModal;
            col.chartDataType = f.chartDataType;
            col.rules = f.rules;
            col.isRegularColumn = f.isRegularColumn;
          }
          return col;
        });
        const fields = columns.toArray().filter((c) => !c.isRegularColumn);
        const pmFields = pmColumns.toArray().filter((c) => !c.isRegularColumn);

        const col = this.customFields.toColumnDef(defaults, {
          rowSelector: "id_num",
          table: "event",
          format: this.formatCell.bind(this),
        });

        const custom = this.customFields.toColumnDef(fields, {
          format: this.customFields.formatCellCustomField.bind(this),
        });

        const pmCustom = this.customFields.toColumnDef(pmFields, {
          format: this.customFields.formatCellPmCustomField.bind(this),
        });

        return col.concat(custom).concat(pmCustom);
      })
    );

    this.mapToolWiseEvent();
  }

  load(isPm = false) {
    this.loader.add();
    return this.backend
      .listAll(this.auth.getUser().isRoot() ? this.skip++ : 0, this.limit, isPm)
      .pipe(
        map((events) => events.map((event) => new ToolEvent(event))),
        map((events) => events.map((event) => this.remapEvent(event))),
        tap((events) => {
          if (this.skip === 0 || this.limit == 0) {
            this.eventsSubject.next(List(events));
          } else {
            const prev = this.eventsSubject.getValue().toArray();
            const data = uniqBy([...events, ...prev], "_id");
            this.eventsSubject.next(List(data));
          }
        }),
        flatMap(() => {
          return this.backend.count().pipe(
            tap((count) => {
              this.totalSubject.next(count.total);
              this.pmHistoryTotalSubject.next(count.pmHistoryTotal);
              this.eventHistoryTotalSubject.next(count.eventHistoryTotal);
              this.loader.remove();
            })
          );
        })
      );
  }

  loadExportHistory() {
    return this.backend.listHistory(0, 0, false, "id_num", "desc").pipe(
      map((events) => events.map((event) => new ToolEvent(event))),
      map((events) => events.map((event) => this.remapEvent(event)))
    );
  }

  getTotalCount = () => {
    return this.totalSubject.getValue();
  };

  getPmTotalCount = () => {
    return this.pmHistoryTotalSubject.getValue();
  };

  getAllFetched = () => {
    return this.allFetched.getValue();
  };

  getList = () => {
    return this.eventsSubject.getValue();
  };

  count = () => {
    if (!this.getTotalCount()) {
      this.backend
        .count()
        .pipe(
          tap((count) => {
            this.totalSubject.next(count.total);
            this.pmHistoryTotalSubject.next(count.pmHistoryTotal);
            this.eventHistoryTotalSubject.next(count.eventHistoryTotal);
          })
        )
        .subscribe();
    }
  };

  updateLimit = (limit: number) => {
    this.skip = 0;
    this.limit = limit;
    this.allFetched.next(false);
    if (limit != 0) this.eventsSubject.next(List([]));
    this.count();
  };

  loadHistory(isPm = false, sortColumn: string, sortType: string) {
    this.loader.add();
    const time = moment().tz("UTC").format("YYYY-MM-DD HH:mm:ss");
    return this.backend
      .listHistory(
        this.skip++,
        this.limit,
        isPm,
        sortColumn,
        sortType,
        this.limit == 0 && !isPm ? this.lastFetchedTime.getValue() : null
      )
      .pipe(
        map((events) => events.map((event) => new ToolEvent(event))),
        map((events) => events.map((event) => this.remapEvent(event))),
        tap((events) => {
          if (events.length < this.limit || this.limit === 0)
            this.allFetched.next(true);
          if (
            (this.skip === 0 || this.limit == 0) &&
            !this.lastFetchedTime.getValue()
          ) {
            this.eventsSubject.next(List(events));
            if (this.limit == 0 && !isPm) this.lastFetchedTime.next(time);
          } else {
            const prev = this.eventsSubject.getValue().toArray();
            const data = uniqBy([...events, ...prev], "_id");
            this.eventsSubject.next(List(data));
            if (!isPm) this.lastFetchedTime.next(time);
          }
          if (isPm) this.lastFetchedTime.next(null);
          this.loader.remove();
        })
      );
  }

  updateBulk(events: ToolEvent[]) {
    return this.backend.updateBulk(events).pipe(
      map((events) => events.map((event: ToolEvent) => new ToolEvent(event))),
      map((events) => events.map((event: ToolEvent) => this.remapEvent(event))),
      tap((events: Array<ToolEvent>) => {
        events.forEach((event) => {
          const ets = this.eventsSubject.getValue();
          const idx = ets.findIndex((t: ToolEvent) => t._id === event._id);
          if (idx > -1) this.eventsSubject.next(ets.set(idx, event));
          const aEvents = this.activeEventsSubject.getValue();
          const aIdx = aEvents.findIndex((t: ToolEvent) => t._id === event._id);
          if (aIdx > -1)
            this.activeEventsSubject.next(aEvents.set(aIdx, event));
        });
      })
    );
  }

  updateMultiple(events: ToolEvent[]) {
    return this.backend.updateMultiple(events).pipe(
      map((events) => events.map((event: ToolEvent) => new ToolEvent(event))),
      map((events) => events.map((event: ToolEvent) => this.remapEvent(event))),
      tap((events: Array<ToolEvent>) => {
        events.forEach((event) => {
          const ets = this.eventsSubject.getValue();
          const idx = ets.findIndex((t: ToolEvent) => t._id === event._id);
          if (idx > -1) this.eventsSubject.next(ets.set(idx, event));
          const aEvents = this.activeEventsSubject.getValue();
          const aIdx = aEvents.findIndex((t: ToolEvent) => t._id === event._id);
          if (aIdx > -1) {
            this.activeEventsSubject.next(aEvents.set(aIdx, event));
          } else if (!event.isDeleted && !event.done) {
            this.activeEventsSubject.next(aEvents.unshift(event));
          }
        });
      })
    );
  }

  activeEventList() {
    return this.backend.activeEventList().pipe(
      map((events) => events.map((event) => new ToolEvent(event))),
      map((events) => events.map((event) => this.remapEvent(event))),
      tap((events) => {
        this.activeEventsSubject.next(List(events));
      })
    );
  }

  mapToolWiseEvent = () => {
    this.activeEvents$.subscribe(async (events) => {
      const statusTotalCountersWithModerator = [];
      let toolEvents: any = {};
      const groupedEvents = groupBy(
        events
          .filter((e) => {
            const t = e.tool as Tool;
            const m = e.maintenanceObject;
            return (
              !e.isDeleted &&
              !e.done &&
              !t.isDeleted &&
              (!e.maintenance || !m.isDeleted)
            );
          })
          .toArray(),
        "tool._id"
      );
      for (let i in groupedEvents) {
        const severity = [];
        const es = groupedEvents[i];
        const maintenances = [];
        let moderateEvents = 0;
        let qaEvents = 0;
        let safetyEvents = 0;
        const [status] = es.reduce(
          (result, event) => {
            const s = ToolEvent.getSeverity(event);
            if (event.status === EVENT_STATUS.ACTIVE) {
              result[0] = Math.max(s, result[0]);
              statusTotalCountersWithModerator[s] =
                (statusTotalCountersWithModerator[s] || 0) + 1;
              severity[s] = (severity[s] || 0) + 1;
            } else if (
              this.auth.isModerator() &&
              event.status === EVENT_STATUS.PENDING_MODERATION
            ) {
              moderateEvents += 1;
            } else if (
              this.auth.isQa() &&
              event.status === EVENT_STATUS.WAITING_FOR_QA
            ) {
              qaEvents += 1;
            } else if (
              this.auth.isSafety() &&
              event.status === EVENT_STATUS.WAITING_FOR_SAFETY
            ) {
              safetyEvents += 1;
            }
            if (event.maintenance && !maintenances.includes(event.maintenance))
              maintenances.push(event.maintenance);
            return result;
          },
          [0]
        );
        toolEvents[i] = {
          status: status,
          severity: severity,
          moderateEvents: moderateEvents,
          qaEvents: qaEvents,
          safetyEvents: safetyEvents,
          maintenances: maintenances,
        };
      }
      this.statusTotalWithModeratorSubject.next(
        List(statusTotalCountersWithModerator)
      );
      this.toolWiseEventSubject.next(toolEvents);
    });
  };

  getToolEvents(toolId) {
    return this.backend.listByTool(toolId).pipe(
      map((events) => events.map((event) => new ToolEvent(event))),
      map((events) => events.map((event) => this.remapEvent(event))),
      tap((events: Array<ToolEvent>) => {
        let eventsValue = this.activeEventsSubject.getValue();
        let isNewEvent = false;
        events.forEach((event) => {
          if (!event.done) {
            const idx = eventsValue.findIndex(
              (e: ToolEvent) => e._id === event._id
            );
            if (idx === -1) {
              eventsValue = eventsValue.push(event);
              isNewEvent = true;
            }
          }
        });
        if (isNewEvent) this.activeEventsSubject.next(eventsValue);
      }),
      map((events) => List(events)),
      shareReplay(1)
    );
  }

  getToolHistoryEvents(toolId, page, limit, isHistory = false) {
    return this.backend.listByToolHistory(toolId, page, limit, isHistory).pipe(
      map((events) => events.map((event) => new ToolEvent(event))),
      map((events) => events.map((event) => this.remapEvent(event))),
      map((events) => List(events)),
      shareReplay(1)
    );
  }

  getHistoryEvents(start: string, end: string) {
    return this.backend.getHistoryEvents(start, end).pipe(
      map((events) => events.map((event) => new ToolEvent(event))),
      map((events) => events.map((event) => this.remapEvent(event))),
      shareReplay(1)
    );
  }

  getHistoryEventsForCalendar(start: string, end: string) {
    return this.backend.getHistoryEventsForCalendar(start, end).pipe(
      map((events) => events.map((event) => new ToolEvent(event))),
      map((events) => events.map((event) => this.remapEvent(event))),
      shareReplay(1)
    );
  }

  getToolHistoryEventsCount(toolId: string) {
    return this.backend.listByToolHistoryCount(toolId);
  }

  sendEmail(data: {
    emailPersons: {
      email: string;
      name: string;
    }[];
    event: any;
    eventId: string;
    files: string[];
    translations: object;
  }) {
    return this.backend.sendEmail(data);
  }

  getEventsByTime(start, end, lastMonth = false) {
    return this.backend.listByTime(start, end, lastMonth).pipe(
      map((events) => events.map((event) => new ToolEvent(event))),
      map((events) => events.map((event) => this.remapEvent(event))),
      map((events) => List(events))
    );
  }

  getEventByIdNum(id_num: string) {
    return this.backend.getEventByIdNum(id_num).pipe(
      map((events) => events.map((event) => new ToolEvent(event))),
      map((events) => events.map((event) => this.remapEvent(event))),
      map((events) => List(events))
    );
  }

  getEventById(id: string) {
    return this.backend.getEventById(id).pipe(
      map((event) => new ToolEvent(event)),
      map((event) => this.remapEvent(event))
    );
  }

  getLocalEventById(eventId: string) {
    const activeEvents = this.getActiveEvents().toArray();
    const activeEventIndex = activeEvents.findIndex(
      (event) => event._id === eventId
    );
    if (activeEventIndex > -1) {
      return activeEvents[activeEventIndex];
    } else {
      const events = this.getEvents().toArray();
      const eventIndex = events.findIndex((event) => event._id === eventId);
      return eventIndex > -1 ? events[eventIndex] : null;
    }
  }

  syncEvent(id: string) {
    return this.backend.getEventById(id).pipe(
      map((event) => new ToolEvent(event)),
      map((event) => this.remapEvent(event)),
      tap((event) => {
        const events = this.eventsSubject.getValue();
        const idx = events.findIndex((e: ToolEvent) => e._id === event._id);
        event.custom = event.custom || {};
        if (idx > -1) {
          this.eventsSubject.next(events.set(idx, event));
        } else {
          this.eventsSubject.next(events.unshift(event));
        }
        const activeEvents = this.activeEventsSubject.getValue();
        const activeEventsIdx = activeEvents.findIndex(
          (e: ToolEvent) => e._id === event._id
        );
        if (activeEventsIdx > -1) {
          if (event.done || event.isDeleted) {
            this.activeEventsSubject.next(
              activeEvents.splice(activeEventsIdx, 1)
            );
          } else {
            this.activeEventsSubject.next(
              activeEvents.set(activeEventsIdx, event)
            );
          }
        } else {
          if (!event.done && !event.isDeleted) {
            this.activeEventsSubject.next(activeEvents.unshift(event));
          }
        }
      })
    );
  }

  getEventTooltipById(id: string) {
    return this.backend.getHistoryById(id).pipe(
      map((event) => new ToolEvent(event)),
      map((event) => this.remapEvent(event)),
      tap((event) => {
        const events = this.eventsSubject.getValue();
        const idx = events.findIndex((e: ToolEvent) => e._id === event._id);
        if (idx > -1) {
          this.eventsSubject.next(events.set(idx, event));
        } else {
          this.eventsSubject.next(events.unshift(event));
        }
      })
    );
  }

  getEventsBySeverity(severity: number) {
    return this.backend.listBySeverity(severity).pipe(
      map((events) => events.map((event) => new ToolEvent(event))),
      map((events) => events.map((event) => this.remapEvent(event))),
      map((events) => List(events))
    );
  }

  getEventsForActiveEvents() {
    return this.backend.listForActiveEvents().pipe(
      map((events) => events.map((event) => new ToolEvent(event))),
      map((events) => events.map((event) => this.remapEvent(event))),
      map((events) => List(events))
    );
  }

  lastMonthCount() {
    return this.backend.lastMonthCount();
  }

  searchProblemDescription(search: string, autoCompleteTool: string) {
    return this.backend.searchProblemDescription(search, autoCompleteTool);
  }

  globalSearch(search: object) {
    return this.backend.globalSearch(search).pipe(
      map((events) => events.map((event) => new ToolEvent(event))),
      map((events) => events.map((event) => this.remapEvent(event)))
    );
  }

  fetch = () => {
    return this.backend.listAll(0, 0, false).pipe(
      map((events) => events.map((event) => new ToolEvent(event))),
      map((events) => events.map((event) => this.remapEvent(event))),
      tap((events) => {
        if (this.skip === 0 || this.limit == 0) {
          this.eventsSubject.next(List(events));
        } else {
          const prev = this.eventsSubject.getValue().toArray();
          this.eventsSubject.next(List([...prev, ...events]));
        }
      })
    );
  };

  search = (search: string) =>
    this.backend.search(search).pipe(
      map((events) => events.map((event) => new ToolEvent(event))),
      map((events) => events.map((event) => this.remapEvent(event))),
      tap((events: Array<ToolEvent>) => {
        let eventsValue = this.eventsSubject.getValue();
        events.forEach((event) => {
          const idx = eventsValue.findIndex(
            (e: ToolEvent) => e._id === event._id
          );
          if (~idx) {
            eventsValue = eventsValue.set(idx, event);
          } else {
            eventsValue = eventsValue.push(event);
          }
        });

        this.eventsSubject.next(eventsValue);
      })
    );

  create(event: ToolEvent) {
    return this.backend.create({ ...event, fromSystem: true }).pipe(
      map((ev) => new ToolEvent(ev)),
      map((ev) => this.remapEvent(ev)),
      tap((ev) => {
        this.eventsSubject.next(this.eventsSubject.getValue().unshift(ev));
        if (!ev.done) {
          this.activeEventsSubject.next(
            this.activeEventsSubject.getValue().unshift(ev)
          );
        }
      })
    );
  }

  delete(event: ToolEvent) {
    return this.backend.delete(event).pipe(
      tap(() => {
        const events = this.eventsSubject.getValue();
        const idx = events.findIndex((e: ToolEvent) => e._id === event._id);
        this.eventsSubject.next(events.splice(idx, 1));
        const activeEvents = this.activeEventsSubject.getValue();
        const activeEventsIdx = activeEvents.findIndex(
          (e: ToolEvent) => e._id === event._id
        );
        this.activeEventsSubject.next(activeEvents.splice(activeEventsIdx, 1));
      })
    );
  }

  deleteBulk(data: string[]) {
    return this.backend.deleteBulk(data).pipe(
      tap(() => {
        data.forEach((event) => {
          const idx = this.eventsSubject
            .getValue()
            .findIndex((e: ToolEvent) => e._id === event);
          if (idx > -1) {
            const e = this.eventsSubject.getValue().get(idx);
            e.isDeleted = true;
            this.eventsSubject.next(this.eventsSubject.getValue().set(idx, e));
          }
          const activeEventsIdx = this.activeEventsSubject
            .getValue()
            .findIndex((e: ToolEvent) => e._id === event);
          if (activeEventsIdx > -1) {
            const ae = this.activeEventsSubject.getValue().get(activeEventsIdx);
            ae.isDeleted = true;
            this.activeEventsSubject.next(
              this.activeEventsSubject.getValue().set(activeEventsIdx, ae)
            );
          }
        });
      })
    );
  }

  update(event: ToolEvent) {
    if (!event) {
      const events = this.eventsSubject.getValue();
      this.eventsSubject.next(events);
      return of(null);
    }
    return this.backend.update(event).pipe(
      map((ev) => new ToolEvent(ev)),
      map((ev) => this.remapEvent(ev)),
      tap((ev) => {
        const events = this.eventsSubject.getValue();
        const idx = events.findIndex((e: ToolEvent) => e._id === event._id);
        ev.custom = ev.custom || {};
        this.eventsSubject.next(events.set(idx, ev));
        const activeEvents = this.activeEventsSubject.getValue();
        const activeEventsIdx = activeEvents.findIndex(
          (e: ToolEvent) => e._id === event._id
        );
        if (ev.done) {
          this.activeEventsSubject.next(
            activeEvents.splice(activeEventsIdx, 1)
          );
        } else {
          this.activeEventsSubject.next(activeEvents.set(activeEventsIdx, ev));
        }
      })
    );
  }

  patchEvents(tool: Tool) {
    const events = this.eventsSubject.getValue();
    events
      .filter(
        (event) =>
          event &&
          event.tool &&
          (tool._id === (event.tool as Tool)._id || tool._id === event.tool)
      )
      .forEach((event) => (event.tool = tool));
    this.eventsSubject.next(events);
  }

  download() {
    return this.backend.download().subscribe((res) => {
      //window.open(window.URL.createObjectURL(res));
      const date = format(new Date(), "MMDDYYYY");

      saveAs(res, `event-history-${date}.csv`);
    });
  }

  save(event: ToolEvent) {
    if (event._id) {
      return this.update(event);
    }
    return this.create(event);
  }

  public normalizeData(events) {
    return events.map((ev) => {
      ev.custom = ev.custom || {};
      ev.start = new Date(ev.start);
      ev.finish = ev.finish ? new Date(ev.finish) : null;
      ev.createdAt = ev.createdAt ? new Date(ev.createdAt) : null;
      ev.updatedAt = ev.updatedAt ? new Date(ev.updatedAt) : null;
      ev.scheduleWork = ev.scheduleWork ? new Date(ev.scheduleWork) : null;
      ev.firstChangedAt = ev.firstChangedAt
        ? new Date(ev.firstChangedAt)
        : null;
      return ev;
    });
  }

  private formatCell = (col, field) => {
    switch (field.name) {
      case "id_num":
        return {
          ...col,
          cellRenderer: "customClick",
          cellRendererParams: {
            onClick: this.getEvent.bind(this),
            field: field.name,
          },
        };
      case "start":
      case "finish":
      case "scheduleWork":
      case "qaApprovalTime":
        return {
          ...col,
          valueFormatter: (params) =>
            params.data[field.name] &&
            this.commonService.getDateTime(params.data[field.name]),
          valueGetter: (params) =>
            params.data[field.name] &&
            this.commonService.getDateTime(params.data[field.name]),
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(
              nodeA && nodeA.data[field.name] ? nodeA.data[field.name] : null,
              nodeB && nodeB.data[field.name] ? nodeB.data[field.name] : null,
              nodeA,
              nodeB,
              isInverted
            );
          },
        };
      case "file":
        return {
          ...col,
          cellRenderer: "fileRedirection",
          cellRendererParams: {
            isMultiple: true,
            isShowMultiple: true,
          },
        };
      case "type.id_num":
        return {
          ...col,
          cellRenderer: this.toolType.bind(this),
        };
      case "tool.name":
        return {
          ...col,
          cellRenderer: "customClick",
          cellRendererParams: {
            onClick: this.toolLink.bind(this),
            field: field.name,
          },
        };
      case "downTimeHours":
        return {
          ...col,
          valueGetter: (params) => {
            if (params.data.start && params.data.finish) {
              const time = this.commonService.dateTimeBetweenTwoDates(
                params.data.start,
                params.data.finish,
                "hours"
              );
              return time > 0 ? time.toFixed(2) : time;
            }
            return 0;
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return Number(valueA) - Number(valueB);
          },
        };
      case "maintenanceTypeObject.pmKind":
        return {
          ...col,
          valueFormatter: (params) =>
            params.data.maintenanceTypeObject &&
            params.data.maintenanceTypeObject.pmKind
              ? this.translate.instant(
                  pmKind[params.data.maintenanceTypeObject.pmKind]
                )
              : null,
          valueGetter: (params) =>
            params.data.maintenanceTypeObject &&
            params.data.maintenanceTypeObject.pmKind
              ? this.translate.instant(
                  pmKind[params.data.maintenanceTypeObject.pmKind]
                )
              : null,
        };
      default:
        return col;
    }
  };

  public remapEvent(event: any) {
    event.customTableObject = event.customTable || null;
    event.customTable = event.customTable ? event.customTable._id : null;
    event.qaApprovalObject = event.qaApproval || null;
    event.qaApproval = event.qaApproval ? event.qaApproval._id : null;
    if (event.qaApprovalObject && isObject(event.qaApprovalObject))
      event.qaApprovalObject.displayName = `${
        event.qaApprovalObject.firstName || ""
      } ${event.qaApprovalObject.lastName || ""} (${
        event.qaApprovalObject.username
      })`;
    event.signaturePersonObject = event.signaturePerson || null;
    event.signaturePerson = event.signaturePerson
      ? event.signaturePerson._id
      : null;
    if (event.signaturePersonObject && isObject(event.signaturePersonObject))
      event.signaturePersonObject.displayName = `${
        event.signaturePersonObject.firstName || ""
      } ${event.signaturePersonObject.lastName || ""} (${
        event.signaturePersonObject.username
      })`;
    if (
      isObject(event.responsibleDepartment) &&
      Object.keys(event.responsibleDepartment).length > 0
    ) {
      event.responsiblePersonObject = event.responsibleDepartment || null;
      if (
        event.responsiblePersonObject &&
        isObject(event.responsiblePersonObject)
      )
        event.responsiblePersonObject.displayName = `${
          event.responsiblePersonObject.name || ""
        }`;
    } else {
      event.responsiblePersonObject = event.responsiblePerson || null;
      if (
        event.responsiblePersonObject &&
        isObject(event.responsiblePersonObject)
      )
        event.responsiblePersonObject.displayName = `${
          event.responsiblePersonObject.firstName || ""
        } ${event.responsiblePersonObject.lastName || ""} (${
          event.responsiblePersonObject.username
        })`;
    }
    event.responsiblePerson = event.responsiblePersonObject
      ? event.responsiblePersonObject._id
      : null;
    if (
      isObject(event.department) &&
      Object.keys(event.department).length > 0
    ) {
      event.departmentObject = event.department || null;
      event.department = event.departmentObject._id;
    } else {
      event.departmentObject = event.department || null;
      event.department = null;
    }
    if (event.departmentObject && isObject(event.departmentObject))
      event.departmentObject.displayName = `${
        event.departmentObject.name || ""
      }`;
    if (event.finish) {
      event.isDirtyFinishDate = true;
    }

    if (event.worker && isObject(event.worker))
      event.worker.displayName = `${event.worker.firstName || ""} ${
        event.worker.lastName || ""
      } (${event.worker.username})`;
    if (event.createdBy && isObject(event.createdBy))
      event.createdBy.displayName = `${event.createdBy.firstName || ""} ${
        event.createdBy.lastName || ""
      } (${event.createdBy.username})`;
    if (event.updatedBy && isObject(event.updatedBy))
      event.updatedBy.displayName = `${event.updatedBy.firstName || ""} ${
        event.updatedBy.lastName || ""
      } (${event.updatedBy.username})`;

    if (event.machineStatus && isObject(event.machineStatus)) {
      event.machineStatusObject = event.machineStatus;
      event.machineStatus = event.machineStatusObject._id;
    }

    if (event.maintenanceType && isObject(event.maintenanceType)) {
      event.maintenanceTypeObject = event.maintenanceType;
      event.maintenanceType = event.maintenanceTypeObject._id;
    }

    event.typeId = event.machineStatusObject
      ? event.machineStatusObject.id_num
      : event.maintenanceTypeObject
      ? event.maintenanceTypeObject.id_num
      : null;
    event.type = event.machineStatusObject
      ? event.machineStatusObject._id
      : event.maintenanceTypeObject
      ? event.maintenanceTypeObject._id
      : null;

    if (event.maintenance && isObject(event.maintenance)) {
      event.maintenanceObject = event.maintenance;
      event.maintenance = event.maintenance._id;
    }

    if (
      !event.machineStatus &&
      !event.maintenanceType &&
      event.maintenanceObject &&
      isObject(event.maintenanceObject) &&
      event.maintenanceObject.type
    ) {
      const typeId = isObject(event.maintenanceObject.type)
        ? event.maintenanceObject.type._id
        : event.maintenanceObject.type;
      console.log("typeId", typeId);
      if (typeId) {
        event.maintenanceTypeObject =
          this.maintenanceTypesStoreService.getById(typeId);
        event.maintenanceType = typeId;
        event.typeId = event.maintenanceTypeObject
          ? event.maintenanceTypeObject.id_num
          : null;
        event.type = event.maintenanceTypeObject
          ? event.maintenanceTypeObject._id
          : null;
      }
    }
    event.severity = event.machineStatus
      ? this.commonService.getSeverityText(ToolEvent.getSeverity(event))
      : null;
    return event;
  }

  public getEvents = () => {
    return this.eventsSubject.getValue();
  };

  public getExistEventById = (eventId: string) => {
    const events = this.getEvents().toArray();
    const eventIndex = events.findIndex((event) => event._id === eventId);
    if (eventIndex > -1) {
      return events[eventIndex];
    }
    const activeEvents = this.getActiveEvents().toArray();
    const activeEventIndex = activeEvents.findIndex(
      (event) => event._id === eventId
    );
    return activeEventIndex > -1 ? activeEvents[activeEventIndex] : null;
  };

  public getActiveEvents = () => {
    return this.activeEventsSubject.getValue();
  };

  toolType = (params) => {
    return params.data.typeId;
  };

  getEvent(params) {
    if (params.rowData.tool && params.rowData.tool?.isDeleted) {
      this.confirm
        .show(this.translate.instant("confirm.TOOL.TOOL_DELETED"), {
          type: ConfirmType.CONFIRM_ONLY,
          confirmText: this.translate.instant("shared.OK"),
          defaultBtnClass: "btn-danger",
        })
        .subscribe(() => {});
    } else {
      localStorage.setItem("_event_id", params.rowData.id_num);
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          `/main/maintenance/tool/${
            params.rowData && params.rowData.tool
              ? params.rowData.tool.id_num
              : ""
          }`,
        ])
      );
      window.open(url, "_blank");
    }
  }

  toolLink(params) {
    if (params.rowData.tool && params.rowData.tool?.isDeleted) {
      this.confirm
        .show(this.translate.instant("confirm.TOOL.TOOL_DELETED"), {
          type: ConfirmType.CONFIRM_ONLY,
          confirmText: this.translate.instant("shared.OK"),
          defaultBtnClass: "btn-danger",
        })
        .subscribe(() => {});
    } else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          `/main/maintenance/tool/${
            params.rowData && params.rowData.tool
              ? params.rowData.tool.id_num
              : ""
          }`,
        ])
      );
      window.open(url, "_blank");
    }
  }

  pmHistoryTotalSubjectCount() {
    return this.pmHistoryTotalSubject.getValue();
  }

  eventHistoryTotalSubjectCount() {
    return this.eventHistoryTotalSubject.getValue();
  }

  getAuditTrail(event: ToolEvent) {
    return new Promise((resolve, reject) => {
      this.backend.getAuditTrail(event).subscribe(
        (data: EventAuditTrail[]) => {
          let audits: EventAuditTrail[] = data
            .map((d) => {
              d.createdAt = new Date(d.createdAt);
              const signed = d.isSignaturePerson
                ? "[" + this.translate.instant("audit-trail.SIGNED") + "]"
                : "";
              switch (d.type) {
                case 1:
                  d.message = this.translate.instant(
                    d.isMaintenance
                      ? "audit-trail.CREATED-MAINTENANCE"
                      : "audit-trail.CREATED-EVENT",
                    { name: d.data.name + signed }
                  );
                  break;
                case 2:
                  d.message = this.translate.instant(
                    d.isMaintenance
                      ? "audit-trail.FINISHED-MAINTENANCE"
                      : "audit-trail.FINISHED-EVENT",
                    { name: d.data.name + signed }
                  );
                  break;
                case 3:
                  d.message = this.translate.instant(
                    d.isMaintenance
                      ? "audit-trail.ASSINGED-MAINTENANCE"
                      : "audit-trail.ASSINGED-EVENT",
                    { name: d.data.name + signed, assigned: d.data.assigned }
                  );
                  break;
                case 4:
                  d.message = this.translate.instant(
                    "audit-trail.TYPE-UPDATE-EVENT",
                    {
                      name: d.data.name + signed,
                      fromEvent: d.data.fromEvent,
                      event: d.data.event,
                    }
                  );
                  break;
                case 5:
                  d.message = this.translate.instant(
                    "audit-trail.TOOL_UPDATE-EVENT",
                    {
                      name: d.data.name + signed,
                      fromTool: d.data.fromTool,
                      tool: d.data.tool,
                    }
                  );
                  break;
                case 6:
                  d.message = this.translate.instant(
                    d.isMaintenance
                      ? "audit-trail.DELETED-MAINTENANCE"
                      : "audit-trail.DELETED-EVENT",
                    { name: d.data.name + signed }
                  );
                  break;
                case 7:
                  d.message = this.translate.instant(
                    "audit-trail.CHECKLIST-UPDATE-MAINTENANCE",
                    { name: d.data.name + signed, checklist: d.data.checklist }
                  );
                  break;
                case 8:
                  d.message = this.translate.instant(
                    d.isMaintenance
                      ? "audit-trail.REACTIVATED-MAINTENANCE"
                      : "audit-trail.REACTIVATED-EVENT",
                    { name: d.data.name + signed }
                  );
                  break;
                case 9:
                  d.message = this.translate.instant(
                    d.isMaintenance
                      ? "audit-trail.FINISHED-MAINTENANCE-WAIT-FOR-QA"
                      : "audit-trail.FINISHED-EVENT-WAIT-FOR-QA",
                    { name: d.data.name + signed }
                  );
                  break;
                case 10:
                  d.message = this.translate.instant(
                    d.isMaintenance
                      ? "audit-trail.FINISHED-MAINTENANCE-QA"
                      : "audit-trail.FINISHED-EVENT-QA",
                    { name: d.data.name + signed }
                  );
                  break;
                case 11:
                  d.message = this.translate.instant(
                    "audit-trail.EVENT-START-DATE-UPDATED",
                    {
                      name: d.data.name + signed,
                      startDate: this.commonService.getDateTime(
                        d.data.newValue
                      ),
                    }
                  );
                  break;
                case 13:
                  d.message = this.translate.instant(
                    "audit-trail.EVENT-DOWN-TIME-UPDATED",
                    { name: d.data.name + signed, downtime: d.data.newValue }
                  );
                  break;
                case 14:
                  d.message = this.translate.instant(
                    "audit-trail.EVENT-NO-DOWNTIME-UPDATED",
                    { name: d.data.name + signed, downtime: d.data.newValue }
                  );
                  break;
                case 15:
                  d.message = this.translate.instant(
                    "audit-trail.EVENT-PROBLEM-DESCRIPTION-UPDATED",
                    { name: d.data.name + signed, problemDesc: d.data.newValue }
                  );
                  break;
                case 16:
                  d.message = this.translate.instant(
                    "audit-trail.EVENT-SOLUTION-DESCRIPTION-UPDATED",
                    {
                      name: d.data.name + signed,
                      solutionDesc: d.data.newValue,
                    }
                  );
                  break;
                case 17:
                  const events =
                    d.data.newValue && isArray(d.data.newValue)
                      ? d.data.newValue.map((e) => e.id_num).join(", ")
                      : "";
                  d.message = this.translate.instant(
                    "audit-trail.EVENT-CHILD-EVENT-UPDATED",
                    { name: d.data.name + signed, events: events }
                  );
                  break;
                case 18:
                  const removed =
                    d.data.newValue && isArray(d.data.newValue)
                      ? d.data.newValue
                          .filter((e) => e.isRemoved)
                          .map((e) => e.name)
                      : [];
                  const updated =
                    d.data.newValue && isArray(d.data.newValue)
                      ? d.data.newValue
                          .filter((e) => e.isUpdated)
                          .map((e) => e.name)
                      : [];
                  const message =
                    removed.length > 0 && updated.length > 0
                      ? "EVENT-PARTS-UPDATED-REMOVED"
                      : updated.length > 0
                      ? "EVENT-PARTS-UPDATED"
                      : removed.length > 0
                      ? "EVENT-PARTS-REMOVED"
                      : "";
                  if (message) {
                    d.message = this.translate.instant(
                      "audit-trail." + message,
                      {
                        name: d.data.name + signed,
                        removedParts: removed.join(", "),
                        updatedParts: updated.join(", "),
                      }
                    );
                  }
                  break;
                case 19:
                case 20:
                  const name =
                    typeof d.data.newValue.name != "undefined"
                      ? this.translate.instant(d.data.newValue.name) !=
                        d.data.newValue.name
                        ? this.translate.instant(d.data.newValue.name)
                        : d.data.newValue.label
                      : this.translate.instant(d.data.newValue.label);
                  const type = d.data.newValue.type ? d.data.newValue.type : "";
                  let value = d.data.newValue.value
                    ? d.data.newValue.value
                    : "-";
                  if (value) {
                    if (type === "datepicker") {
                      value = this.commonService.getDateTime(value);
                    } else if (type === "dateonlypicker") {
                      value = this.commonService.getDate(value);
                    } else if (type === "timeonlypicker") {
                      value = this.commonService.getTime(value);
                    }
                  }
                  d.message = this.translate.instant(
                    "audit-trail.EVENT-CUSTOM-FIELD-UPDATED",
                    { name: d.data.name + signed, field: name, value: value }
                  );
                  break;
                case 21:
                  const pm = d.data.newValue
                    ? `${d.data.newValue.id_num} (${this.commonService.getDate(
                        d.data.newValue.dueDate
                      )})`
                    : "-";
                  d.message = this.translate.instant(
                    "audit-trail.EVENT-PM-CALENDAR-UPDATED",
                    { name: d.data.name + signed, calendar: pm }
                  );
                  break;
                case 22:
                  const procedure = d.data.newValue
                    ? `${d.data.newValue.id_num} (${d.data.newValue.name})`
                    : "-";
                  d.message = this.translate.instant(
                    "audit-trail.EVENT-TASK-PROCEDURE-UPDATED",
                    { name: d.data.name + signed, procedure: procedure }
                  );
                  break;
                case 23:
                  const parent = d.data.newValue ? d.data.newValue.id_num : "";
                  console.log("parent", d.data);
                  d.message = this.translate.instant(
                    "audit-trail.EVENT-PARENT-EVENT-UPDATED",
                    { name: d.data.name + signed, event: parent }
                  );
                  break;
                case 24:
                  d.message = this.translate.instant(
                    d.isMaintenance
                      ? "audit-trail.FINISHED-MAINTENANCE-WAIT-FOR-SAFETY"
                      : "audit-trail.FINISHED-EVENT-WAIT-FOR-SAFETY",
                    { name: d.data.name + signed }
                  );
                  break;
                case 25:
                  d.message = this.translate.instant(
                    d.isMaintenance
                      ? "audit-trail.FINISHED-MAINTENANCE-SAFETY"
                      : "audit-trail.FINISHED-EVENT-SAFETY",
                    { name: d.data.name + signed }
                  );
                  break;
              }
              return d;
            })
            .filter((d) => d.message);
          resolve(audits);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
