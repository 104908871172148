import { UsersStoreService } from './../../../core/services/users-store.service';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CommonService } from '../../../core/services/common.service';

@Component({
  selector: 'app-file-renderer',
  template: `
    <div style="display: flex; align-items: center;">
      {{text}}
    </div>
    `
})

export class SilentSignatureRendererComponent implements ICellRendererAngularComp {
  params;
  value;
  text;
  @ViewChild('uploadctl') uploadctl: ElementRef;

  constructor(
    private usersStoreService: UsersStoreService,
    public commonService: CommonService,
  ) {
  }

  agInit(params): void {
    this.params = params;
    this.value = params.data[params.colDef.field] ? params.data[params.colDef.field] : (typeof params.data.customFields != "undefined" && params.data.customFields && params.data.customFields[params.colDef.field] ? params.data.customFields[params.colDef.field] : (typeof params.data.pmCustomFields != "undefined" && params.data.pmCustomFields && params.data.pmCustomFields[params.colDef.field] ? params.data.pmCustomFields[params.colDef.field] : null));
    if(this.value){
      const user = this.usersStoreService.getList().find(u => u._id === this.value)
      if(user){
        this.text = user.displayName;
      }
    }
  }

  refresh(params?: any): boolean {
    return false;
  }

  getValue(): any {
    return this.params.value;
  }
}