import { Directive, TemplateRef, ElementRef, ViewContainerRef, Input, Renderer2 } from '@angular/core';
import { User } from '../../routes/user/login.response';
import { AuthService } from '../../routes/user/auth.service';

@Directive({
  selector: '[disableUnauthorized]'
})
export class DisableUnauthorizedDirective {
  private user:User;
  private permissions:[] = [];

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private auth: AuthService,
  ) {
  }

  ngOnInit(){
    this.auth.user$.subscribe(user =>{
      this.user = user;
      this.updateView();
    })
  }

  @Input()
  set disableUnauthorized(val) {
    this.permissions = val;
    this.updateView();
  }

  private updateView = () =>{

    const match = this.permissions.some(
      permission=>this.user && this.user.roles.some(role => permission===role)
    );
    setTimeout(()=>{
      if (match)
        this.element.nativeElement.disabled = undefined;
      else
        this.element.nativeElement.disabled = "disabled";
    })
  }
}
