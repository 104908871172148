import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
import moment from 'moment-timezone';
import { timezone } from '../../../core/constants/timezone';
import { CompaniesStoreService } from '../../../core/services/companies-store.service';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';
import { UsersStoreService } from '../../../core/services/users-store.service';
import { AuthService } from '../../user/auth.service';
import { onboardingWizard } from '../../../core/constants/onboarding-wizard';
import { merge } from 'lodash';

@Component({
  selector: 'app-timezone-setting',
  templateUrl: './timezone-setting.component.html',
  styleUrls: ['./timezone-setting.component.scss']
})
export class TimezoneSettingComponent implements OnInit {

  company: any;
  form: any;
  timezone: any = timezone;
  timeFormat: string = '24';
  constructor(
    private fb: UntypedFormBuilder,
    public auth: AuthService,
    private companiesStoreService: CompaniesStoreService,
    private translate: TranslateService,
    public translationService: TranslationsStoreService,
    private toaster: ToasterService,
    private router: Router
  ) {
    this.getClockChrome().then((data: string) => {
      this.timeFormat = data;
    });
  }

  ngOnInit() {
    this.timezone = Object.keys(this.timezone).reduce((acc, key) => {
      acc.push({ id: key, text: this.timezone[key] });
      return acc;
    }, []);
    this.form = this.fb.group({
      timezone: [""],
    });

    this.auth.user$.subscribe(user => {
      if (!user.company) return;
      if (user.company.timezone) {
        this.router.navigate(['/main']);
      } else {
        this.form.controls['timezone'].setValue(moment.tz.guess());
      }
      this.company = user.company;
    })
  }

  getClockChrome = async () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(async function (pos) {
        const url = "http://nominatim.openstreetmap.org/reverse?format=json&lat=" + pos.coords.latitude + "&lon=" + pos.coords.longitude + "&addressdetails=1&accept-language=en_US";
        let res = await fetch(url);
        res = await res.json();
        const dateCountryCode = ['US', 'GB', 'PH', 'CA', 'AU', 'NZ', 'IN', 'EG', 'SA', 'CO', 'PK', 'MY'];
        if (dateCountryCode.indexOf(res['address']['country_code'].toUpperCase()) > -1) {
          resolve('12');
        } else {
          resolve('24');
        }
      });
    })
  }

  onSubmit = () => {
    this.company = merge(this.company, this.form.value);
    this.company.shortDateFormat = this.company.timezone.includes('America') ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
    this.company.timeFormat = this.timeFormat;
    this.company.longDateFormat = 'DD MMM YYYY';
    this.company.whatsAppMessageOn = ['New FM Entering'];
    this.company.onboardingWizard = onboardingWizard;
    this.company.dailyWorkHours = {
      start: '08:00',
      end: '17:00',
    }
    this.company.freemiumPermissions = {
      signature: false,
      limitedIP: false,
      whatsApp: true,
      smartDropDown: true,
      bigTeam: true,
      sla: true,
      auditTrail: false,
      otp: false,
    }
    this.company.whatsAppMessageFormat = ["toolName", "id_num"];
    this.companiesStoreService.update(this.company).subscribe(
      company => {
        this.toaster.pop("success", this.translate.instant('toasters.GENERAL.OK_UPDATE'));
        location.reload();
      },
      err => {
        this.toaster.pop("error", this.translate.instant('toasters.GENERAL.ERR_UPDATE'))
      }
    )
  }
}
