import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_CHAT_GPT } from '../../interfaces/general';
import { AiAssistantText } from '../../interfaces/ai-assistant-text';

@Injectable({
  providedIn: 'root'
})
export class OpenaiBackendService {
  url = '/api/openai';

  constructor(
    private http: HttpClient
  ) { }

  aiAssistantText = (id: string) => {
    return this.http.get<AiAssistantText>(`${this.url }/ai-assistant-text/${id}`);
  }

  fetchAllToolImages = (toolId: string) => {
    return this.http.post(`${this.url}/fetch-all-tool-images/${toolId}`, {});
  }

  ask = (search: string, tool: string) => {
    return this.http.post(`${this.url}/ask`, { search: search, tool: tool });
  }

  simulator = (sentence: BASE_CHAT_GPT) => {
    return this.http.post(`${this.url}/simulator`, sentence);
  }

  aiMaintenanceAssistance = (sentence: FormData) => {
    return this.http.post(`${this.url}/ai-maintenance-assistance`, sentence);
  }

  aiMaintenanceAssistanceForFile = (sentence: FormData) => {
    return this.http.post(`${this.url}/ai-maintenance-assistance-for-file`, sentence);
  }

  aiMaintenancePartsAssistanceForFile = (sentence: FormData) => {
    return this.http.post(`${this.url}/ai-maintenance-parts-assistance-for-file`, sentence);
  }

  aiMaintenanceAssistanceFromFile = (fileId: string) => {
    return this.http.post(`${this.url}/ai-maintenance-assistance-from-file/${fileId}`, {});
  }
}
