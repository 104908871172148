import { IField } from "../../interfaces/field";
import SUBSCRIPTION_PLANS from "../subscription-plans";
import { timeCalculation } from "../../../shared/comparators/date-comparator";
import { addMonths } from "date-fns/esm";
import moment from "moment";

const FieldDefinition: IField[] = [
    {
        name: 'name',
        label: 'table.company.table-title.Company',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'lastPayment.plan',
        label: 'table.company.table-title.Plan',
        type: 'textbox',
        required: false,
        readonly: true,
        valueGetter: params => {
            if (!params.data.lastPayment || !params.data.lastPayment._id) return "";
            const plan = SUBSCRIPTION_PLANS.find(plan => plan.key === params.data.lastPayment.plan)
            return plan ? plan.value : "";
        }
    },
    {
        name: 'lastPayment.paymentDate',
        label: 'table.company.table-title.Last-Payment',
        type: 'textbox',
        required: false,
        readonly: true,
        valueGetter: params => {
            if (!params.data.lastPayment || !params.data.lastPayment._id) return "";
            const { paymentDate } = params.data.lastPayment;
            return moment(new Date(paymentDate)).format('DD.MM.YYYY');
        },
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.lastPayment ? nodeA.data.lastPayment.paymentDate : null, nodeB && nodeB.data.lastPayment ? nodeB.data.lastPayment.paymentDate : null, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'dueDate',
        label: 'table.company.table-title.Due-Date',
        type: 'textbox',
        required: false,
        readonly: true,
        valueGetter: params => {
            if (!params.data.lastPayment || !params.data.lastPayment._id) return "";
            const { startDate, period } = params.data.lastPayment
            return moment(addMonths(new Date(startDate), parseInt(period))).format('DD.MM.YYYY');
        },
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const aValue = nodeA && nodeA.data.lastPayment ? addMonths(nodeA.data.lastPayment.startDate, parseInt(nodeA.data.lastPayment.period)) : null;
            const bValue = nodeB && nodeB.data.lastPayment ? addMonths(nodeB.data.lastPayment.startDate, parseInt(nodeB.data.lastPayment.period)) : null;
            return timeCalculation(aValue, bValue, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'eula Accepted Date',
        label: 'table.company.table-title.Eula-Accepted',
        type: 'textbox',
        required: false,
        readonly: true,
        valueGetter: params => {
            if (!params.data.eulaAccepted || !params.data.eulaAcceptedAt) return "Not Accepted"
            return moment(new Date(params.data.eulaAcceptedAt)).format('DD.MM.YYYY hh:mm:ss')

        },
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.eulaAcceptedAt ? nodeA.data.eulaAcceptedAt : null, nodeB && nodeB.data.eulaAcceptedAt ? nodeB.data.eulaAcceptedAt : null, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'backupSchedule',
        label: 'table.company.table-title.Backup-Schedule',
        type: 'dropdown',
        options: [
            { key: 1, value: 'Daily' },
            { key: 2, value: 'Weekly' },
            { key: 3, value: 'Monthly' }
        ],
        required: true,
        readonly: false
    },
    {
        name: 'whatsAppFrom',
        label: 'table.company.table-title.whatsapp-number',
        type: 'textbox',
        required: true,
        readonly: false
    },
    {
        name: 'isActiveDirectory',
        label: 'table.company.table-title.ACTIVE-DIRECTORY',
        type: 'textbox',
        required: true,
        readonly: false
    },
    {
        name: 'activeDirectory.clientId',
        label: 'table.company.table-title.AD-CLIENT-ID',
        type: 'textbox',
        required: false,
        readonly: false
    },
    {
        name: 'activeDirectory.authority',
        label: 'table.company.table-title.AD-AUTHORITY',
        type: 'textbox',
        required: false,
        readonly: false
    },
    {
        name: 'activeDirectory.policy',
        label: 'table.company.table-title.AD-POLICY',
        type: 'textbox',
        required: false,
        readonly: false
    },
    {
        name: 'activeDirectory.tenant',
        label: 'table.company.table-title.AD-TENANT-NAME',
        type: 'textbox',
        required: false,
        readonly: false
    },
    {
        name: 'isSaml',
        label: 'table.company.table-title.SAML-SSO',
        type: 'textbox',
        required: true,
        readonly: false
    },
    {
        name: 'saml.entryPoint',
        label: 'table.company.table-title.SAML-ENTRY-POINT',
        type: 'textbox',
        required: false,
        readonly: false
    },
    {
        name: 'saml.issuer',
        label: 'table.company.table-title.SAML-ISSUER',
        type: 'textbox',
        required: false,
        readonly: false
    },
    {
        name: 'saml.cert',
        label: 'table.company.table-title.SAML-CERT',
        type: 'textbox',
        required: false,
        readonly: false
    },
];

export default
    {
        FieldDefinition
    }