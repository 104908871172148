import { IField } from "../../interfaces/field";

const FieldDefinition: IField[] = [
  {
    name: 'id_num',
    label: 'general.ID',
    type: 'textbox',
    required: false,
    readonly: true,
  },
  {
    name: 'company.name',
    label: 'table.tools.table-title.COMPANY',
    type: 'textbox',
    required: false,
    readonly: true,
  },
  {
    name: 'toolObject.id_num',
    label: "table.ai-helper.table-title.ITEM-ID",
    type: 'textbox',
    required: false,
    readonly: true
  },
  {
    name: 'itemName',
    label: "table.ai-helper.table-title.ITEM-NAME",
    type: 'textbox',
    required: false,
    readonly: true
  },
  {
    name: 'itemTable',
    label: "table.ai-helper.table-title.ITEM-TABLE",
    type: 'selectdropdown',
    options: [],
    required: false,
    readonly: false
  },
  {
    name: 'descriptionInNative',
    label: "table.ai-helper.table-title.DESCRIPTION-IN-NATIVE-LANGUAGE",
    type: 'textbox',
    required: false,
    readonly: false
  },
  {
    name: 'descriptionForAI',
    label: "table.ai-helper.table-title.DESCRIPTION-FOR-AI",
    type: 'textbox',
    required: false,
    readonly: false
  },
  {
    name: 'keywords',
    label: "table.ai-helper.table-title.KEYWORDS",
    type: 'multipledropdown',
    options: [],
    required: false,
    readonly: false
  },
  {
    name: 'suggestions',
    label: "table.ai-helper.table-title.SUGGESTIONS",
    type: 'textbox',
    required: false,
    readonly: false
  },
  {
    name: 'labels',
    label: "table.ai-helper.table-title.LABELS",
    type: 'textbox',
    required: false,
    readonly: false
  },
  {
    name: 'aiResponse',
    label: "table.ai-helper.table-title.AI-RESPONSE",
    type: 'textbox',
    required: false,
    readonly: false
  },
  {
    name: 'groupAction',
    label: "table.ai-helper.table-title.SELECT-FOR-GROUP-ACTION",
    type: 'checkbox',
    required: false,
    readonly: false
  },
  {
    name: 'completeSelectedImage',
    label: "table.ai-helper.table-title.COMPLETE-SELECTED-IMAGE",
    type: 'button',
    required: false,
    readonly: false
  },
  {
    name: 'responseId',
    label: "table.ai-helper.table-title.RESPONSE-ID",
    type: 'textbox',
    required: false,
    readonly: false
  },
  {
    name: 'implementSelectedImage',
    label: "table.ai-helper.table-title.IMPLEMENT-SELECTED-IMAGE",
    type: 'button',
    required: false,
    readonly: false
  },
  {
    name: 'isDeleted',
    label: 'general.DELETED',
    type: "checkbox",
    readonly: false,
    isAdmin: true,
  }
]

export default { FieldDefinition }