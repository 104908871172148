import { loggerCallback } from './../auth.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { SettingsService } from "../../../core/settings/settings.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";
import { LoaderService } from "../../../core/utils/loader.service";
import { CommonService } from "../../../core/services/common.service";
import { ToasterService } from "angular2-toaster";
import { RecaptchaErrorParameters } from "ng-recaptcha";
import { finalize } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import {
  BrowserCacheLocation,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";
import { decryptField } from "../../../core/utils/general";
import { Company } from "../../../core/interfaces/company";

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

@Component({
  selector: "app-login-ad",
  templateUrl: "./login-ad.component.html",
  styleUrls: ["./login-ad.component.scss"],
})
export class LoginAdComponent implements OnInit {
  valForm: UntypedFormGroup;
  errorMessage: string | null = null;
  showResendVerification: boolean = false;
  resendVerificationId: string = "";
  fValue: any;
  public captchaResponse = "";
  @ViewChild("captchaRef") captchaRef;
  isSaml = false;

  constructor(
    public settings: SettingsService,
    private translate: TranslateService,
    private userService: AuthService,
    private router: Router,
    fb: UntypedFormBuilder,
    private loader: LoaderService,
    public commonService: CommonService,
    private toaster: ToasterService
  ) {
    this.isSaml = this.router.url === "/login-saml";
    this.valForm = fb.group({
      company: [
        localStorage.getItem("company") || null,
        Validators.compose([Validators.required]),
      ],
    });
    if (localStorage.getItem("remember") === "true") {
      this.router.navigate(["/main"]);
    }
  }

  public resolved(captchaResponse: string): void {
    this.submitData(this.fValue, captchaResponse);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    this.captchaResponse += `ERROR; error details (if any) have been logged to console\n`;
    this.errorMessage = errorDetails.join(",");
    this.loader.remove();
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    if (this.valForm.valid) {
      this.loader.add();
      this.captchaRef.execute();
      this.fValue = value;
    }
  }

  submitData = (value, captcha) => {
    for (const c in this.valForm.controls) {
      if (this.valForm.controls.hasOwnProperty(c)) {
        this.valForm.controls[c].markAsTouched();
      }
    }
    if (this.valForm.valid) {
      this.showResendVerification = false;
      this.resendVerificationId = "";
      value.remember = value.remember ? "true" : "false";

      const login = { ...value, captcha: captcha };
      this.userService
        .loginAd(login, this.isSaml)
        .pipe(finalize(() => {}))
        .subscribe(
          async (company) => {
            this.loader.remove();
            if(this.isSaml) {
              this.initialSaml(company);
            } else {
              await this.initialMsal(company);
            }
          },
          (err) => {
            this.loader.remove();
            if (!(err instanceof HttpErrorResponse) || err.status !== 401) {
              switch (err.error.status) {
                case "blocked":
                  this.errorMessage =
                    "Company access has been blocked. Please contact customer service for more information";
                  break;
                case "deleted":
                  this.errorMessage = "This user is deleted";
                  break;
                case "login-attemp":
                  this.errorMessage = `Login is blocked for ${
                    err.error.minutes ? err.error.minutes : 1
                  } minutes. Please try again after.`;
                  break;
                case "deactivated":
                  this.errorMessage =
                    "Company is inactive. Please contact customer service for more information";
                  break;
                case "unverified":
                  this.errorMessage =
                    "Company not yet verified, please check your email and click the verify link";
                  this.showResendVerification = true;
                  this.resendVerificationId = err.error.id || "";
                  break;
                default:
                  this.errorMessage = "Server error";
                  break;
              }
              return;
            }
            this.errorMessage = this.isSaml ? "Invalid company for SAML SSO, please choose the regular login method." : "Invalid company for Microsoft Active Directory, please choose the regular login method.";
            this.captchaRef.reset();
          }
        );
    } else {
      this.loader.remove();
    }
  };

  ngOnInit() {
    localStorage.removeItem("_sign_out_counter");
    localStorage.removeItem("_tab_open");
  }

  initialMsal = async (company: Company) => {
    const authority = decryptField(company.activeDirectory.authority);
    const url = new URL(authority);
    const msalConfig = {
      auth: {
        clientId: decryptField(company.activeDirectory.clientId),
        authority: decryptField(company.activeDirectory.authority),
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        knownAuthorities: [`${url.protocol}//${url.host}`],
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Warning,
          piiLoggingEnabled: false,
        },
      },
    };
    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    msalInstance
      .loginPopup()
      .then(async (response) => {
        this.userService.setToken(response.idToken)
        this.userService.setCompany(company._id)
        this.userService.checkSession(true).subscribe(
          () => {
            this.router.navigate(["/main"]);
          },
          () => {
            msalInstance.logoutPopup()
            this.captchaRef.reset();
            this.userService.removeSelectedLocalStorage();
            this.errorMessage = "Invalid user";
            this.loader.remove();
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  initialSaml = (company: Company) => {
    window.location.href = `${process.env.NG_APP_API_URL}/saml/login?company=${company._id}`;
  }

  sendVerification() {
    this.userService.sendVerification(this.resendVerificationId).subscribe(
      () => {
        this.toaster.pop(
          "success",
          this.translate.instant("toasters.USER.OK_VERIFICATION_EMAIL_RESENT")
        );
        this.showResendVerification = false;
        this.resendVerificationId = "";
        this.errorMessage = "";
      },
      () => {
        this.toaster.pop(
          "error",
          this.translate.instant("toasters.USER.ERR_VERIFICATION_EMAIL_RESENT")
        );
      }
    );
  }
}
