import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventLogs } from '../../interfaces/event-logs';

@Injectable({
  providedIn: 'root'
})
export class EventLogsBackendService {

  url = '/api/event-logs/';

  constructor(private http: HttpClient) {
  }

  listAll(skip: number, limit: number): Observable<EventLogs[]> {
    return this.http.get<EventLogs[]>(`${this.url}?skip=${skip * limit}&limit=${limit}`);
  }

  listSLAAll(skip: number, limit: number): Observable<EventLogs[]> {
    return this.http.get<EventLogs[]>(`${this.url}sla/?skip=${skip * limit}&limit=${limit}`);
  }

  update(event: any) {
    return this.http.put<EventLogs>(`${this.url}${event._id}`, event);
  }

  search = (search: string) => this.http.get<EventLogs[]>(`${this.url}/search/${encodeURIComponent(search)}`)

  count(): Observable<{ total: Number }> {
    return this.http.get<{ total: Number }>(`${this.url}count`);
  }

  countSLA(): Observable<{ total: Number }> {
    return this.http.get<{ total: Number }>(`${this.url}countSLA`);
  }

}
