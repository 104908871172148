import { CommonService } from './common.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { of } from 'rxjs';
import { catchError, concatMap, finalize, map, tap } from 'rxjs/operators';
import { AuthService } from '../../routes/user/auth.service';
import { EVENT_STATUS, FORMULA_MEASUREMENT_TYPE, FUNCTION_TYPE } from '../constants/enums/enums';
import { FilesBackendService } from './backend/files-backend.service';
import { isString } from 'lodash';
import { MeasurementRow } from '../interfaces/measurement';
import { DataFormatsStoreService } from './data-formats-store.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private filesBackendService: FilesBackendService,
    private commonService: CommonService,
    private dataFormatsStoreService: DataFormatsStoreService,
  ) { }

  combineUpdate = (combine) => {
    if (combine) {
      return combine.pipe(
        tap(() => {
          this.toaster.pop("success", this.translate.instant('toasters.GENERAL.OK_UPDATE'));
        }),
        concatMap(result => of(true)),
        map(result => true),
        finalize(() => {
        }),
        catchError((err) => {
          this.toaster.pop("error", this.translate.instant('toasters.GENERAL.ERR_UPDATE'));
          throw err;
        }),
      );
    } else return of(false);
  }

  checkEventToDisplay = (status: EVENT_STATUS) => {
    if(status === EVENT_STATUS.WAITING_FOR_QA) return this.authService.isQa();
    if(status === EVENT_STATUS.WAITING_FOR_SAFETY) return this.authService.isSafety();
    if(status === EVENT_STATUS.PENDING_MODERATION) return this.authService.isModerator();
    return true;
  }

  signedUrl(id: string, type: string | null = null): Promise<string> {
    return new Promise((resolve, reject) => {
      this.filesBackendService.signUrl(id, type).subscribe({
        next: (url) => {
          resolve(url);
        },
        error: (err) => {
          reject(err);
        }
      })
    });
  }

  getFileBufferById(id: string, type: string | null = null): Promise<Blob> {
    return new Promise((resolve, reject) => {
      this.filesBackendService.fileBuffer(id, type).subscribe({
        next: (buffer) => {
          resolve(buffer);
        },
        error: (err) => {
          reject(err);
        }
      })
    });
  }

  viewFile = async(id, type: string | null = null) => {
    const file = await this.getFileBufferById(id, type).catch(err => {
      console.log(err);
    });
    if(file){
      const mime = await this.commonService.getMimeType(file);
      let blob = new Blob([file]);
      if(mime && isString(mime)){
        blob = new Blob([file], { type: mime });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank').focus();
      } else {
        const url = await this.signedUrl(id, type).catch(err => {
          console.log(err);
        });
        if(url && isString(url)){
          window.open(url as string, '_blank').focus();
        }
      }
    }
  }

  downloadFile = async(file, type: string | null = null) => {
    const url = await this.signedUrl(file._id, type).catch(err => {
      console.log(err);
    });
    if(url && isString(url)){
      window.open(url as string, '_blank').focus();
    }
  }

  locations(substring: string, string: string) {
    const a = [];
    let i = -1;
    while ((i = string.indexOf(substring, i + 1)) >= 0) a.push(i);
    return a;
  };

  calculateFormulaMeasurement = (
    kind: FORMULA_MEASUREMENT_TYPE,
    measurement: MeasurementRow
  ) => {
    switch (kind) {
      case FORMULA_MEASUREMENT_TYPE.TARGET:
        return measurement.target || 0;
      case FORMULA_MEASUREMENT_TYPE.RESULT:
        return measurement.updatedValue || 0;
      case FORMULA_MEASUREMENT_TYPE.LSL:
        return measurement.lsl || 0;
      case FORMULA_MEASUREMENT_TYPE.USL:
        return measurement.usl || 0;
      case FORMULA_MEASUREMENT_TYPE.LCL:
        return measurement.lcl || 0;
      case FORMULA_MEASUREMENT_TYPE.UCL:
        return measurement.ucl || 0;
      default:
        return 0;
    }
  };

  measureValueFromFormula = (measurement: MeasurementRow) => {
    const format = measurement.format;
    let numbers = 3
    if (format) {
      const formatData = this.dataFormatsStoreService.getFormat(format);
      if(formatData) {
        numbers = formatData;
      }
    }
    if (!measurement.measuredFormula || (measurement.measuredFormula && measurement.measuredFormula.length === 0)) {
      return measurement.updatedValue ? Number(Number(measurement.updatedValue).toFixed(numbers)) : measurement.updatedValue;
    } else {
      const measuredFormula = measurement.measuredFormula;
      let result = measuredFormula
        .map((f) => {
          if (f.function) {
            return f.function;
          } else if (f.measurement) {
            return this.calculateFormulaMeasurement(f.measurement, measurement);
          } else {
            return f.text;
          }
        })
        .join(' ');
      result = this.calculateFormula(result);
      try {
        const value = eval(result);
        return value ? Number(Number(value).toFixed(numbers)) : value;
      } catch (error) {
        return 0;
      }
    }
  };

  calculateFormula = (result: string) => {
    try {
      console.log('result', result);
      const averageValue = this.locations(FUNCTION_TYPE.AVERAGE, result);
      for (const j in averageValue) {
        const start = result.indexOf('(', averageValue[j]);
        const end = result.indexOf(')', start);
        const sString = result.substring(start + 1, end);
        const values = sString.split(' ').filter((v) => v);
        const sum = values.reduce((a, b) => Number(a) + Number(b), 0);
        result = result.replace(result.substring(averageValue[j], end + 1), `${sum / values.length}`);
      }
      console.log('result1', result);
      const mMax = this.locations(FUNCTION_TYPE.MAX, result);
      for (const j in mMax) {
        const start = result.indexOf('(', mMax[j]);
        const end = result.indexOf(')', start);
        const sString = result.substring(start + 1, end);
        const values = sString.split(' ').filter((v) => v);
        const max = Math.max(...values.map((v) => Number(eval(v))));
        result = result.replace(result.substring(mMax[j], end + 1), `${max}`);
      }
      console.log('result2', result);
      const mMin = this.locations(FUNCTION_TYPE.MIN, result);
      for (const j in mMin) {
        const start = result.indexOf('(', mMin[j]);
        const end = result.indexOf(')', start);
        const sString = result.substring(start + 1, end);
        const values = sString.split(' ').filter((v) => v);
        console.log('values', values);
        const min = Math.min(...values.map((v) => Number(eval(v))));
        result = result.replace(result.substring(mMin[j], end + 1), `${min}`);
      }
      console.log('result3', result);
      const mRange = this.locations(FUNCTION_TYPE.RANGE, result);
      for (const j in mRange) {
        const start = result.indexOf('(', mRange[j]);
        const end = result.indexOf(')', start);
        const sString = result.substring(start + 1, end);
        const values = sString.split(' ').filter((v) => v);
        const numbers = values.map((v) => Number(eval(v)));
        const smallest = Math.min(...numbers);
        const largest = Math.max(...numbers);
        result = result.replace(result.substring(mRange[j], end + 1), `${largest - smallest}`);
      }
      console.log('result4', result);
      const mSqrt = this.locations(FUNCTION_TYPE.SQRT, result);
      for (const j in mSqrt) {
        const start = result.indexOf('(', mSqrt[j]);
        const end = result.indexOf(')', start);
        const sString = result.substring(start + 1, end);
        const value = Math.sqrt(Number(eval(sString)));
        result = result.replace(result.substring(mSqrt[j], end + 1), `${value}`);
      }
      console.log('result5', result);
      const mStDev = this.locations(FUNCTION_TYPE.ST_DEV, result);
      for (const j in mStDev) {
        const start = result.indexOf('(', mStDev[j]);
        const end = result.indexOf(')', start);
        const sString = result.substring(start + 1, end);
        const values = sString.split(' ').filter((v) => v);
        const mean = values.reduce((a, b) => Number(a) + Number(b), 0) / values.length;
        const variance = values.reduce((a, b) => a + Math.pow(Number(b) - mean, 2), 0) / values.length;
        const stDev = Math.sqrt(variance);
        result = result.replace(result.substring(mStDev[j], end + 1), `${stDev}`);
      }
      console.log('result6', result);
      const mAbs = this.locations(FUNCTION_TYPE.ABS, result);
      console.log( 'mAbs', mAbs );
      for (const j in mAbs) {
        const start = result.indexOf('(', mAbs[j]);
        const end = result.indexOf(')', start);
        const sString = result.substring(start + 1, end);
        const value = Math.abs(Number(eval(sString)));
        result = result.replace(result.substring(mAbs[j], end + 1), `${value}`);
      }
      console.log('result7', result);
      return result;
    } catch (error) {
      console.log( 'calculateFormula error', error );
      return null;
    }
  };
}
