import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { CommonService } from "../../../core/services/common.service";

@Component({
    selector: 'editor-cell',
    template: `
        <div #container>
            <form class="checkbox_form checklist" (click)="updateBox($event)">
                <ul [ngClass]="model.length == 0 ? 'blank-ul' : ''" malihu-scrollbar [scrollbarOptions]="lightScrollbarOptions">
                    <li *ngFor="let item of model; let idx=index" [attr.data-index]="idx">
                        <div class="ml-3 added_items" *ngIf="editIdx !== idx">
                            <span> {{item.value}} </span>&nbsp;
                            <div>
                                <img src="assets/img/edit.png" alt="icons" (click)="editCheckItem($event, idx)">
                                <img src="assets/img/trash.png" alt="icons" (click)="removeCheckItem($event, idx)">
                           </div>
                        </div>
                        <div class="input-group border-bottom" *ngIf="editIdx === idx">
                            <input class="form-control" id="fld-check-item" type="text" name="listitem" [(ngModel)]="item.value" (keydown.enter)="updateCheckItem(idx, item)"
                                (keydown.escape)="cancelCheckEdit($event)" autocomplete="off"/>
                        </div>
                    </li>
                </ul>
                <label class="button btn base_btn" (click)="addCheckItem($event)">
                    <i class="fas fa-plus"></i>
                    {{"maintenance.form-labels.ADD-ITEM" | translate}}
                </label>
            </form>
        </div>
    `,
    styles: [`
        .checkbox_form{ width:100%; max-width:500px; padding:10px; min-width:500px; }
        .checkbox_form ul{padding-left:0px; }
        :host ::ng-deep .form-control{
            width: 100% !important;
            height: 40px !important;
            display: inline-block !important;
            border: 1px solid #BBBDBF !important;
            border-radius: 7px !important;
            background: #fff !important;
            color: #242627 !important;
            font-family: Montserrat !important;
            font-size: 14px !important;
            line-height: 16px !important;
            display: flex !important;
            align-items: center !important;
          }
        .checkbox_form ul.blank-ul {
            border: unset;
            margin-bottom: 0px;
        }
        .checkbox_form ul {
            border-radius: 7px;
            border: 1px solid #bbbdbf;
            overflow-y:scroll;
            height: 100%;
            max-height: 200px;
            width: 100%;
        }
        .checkbox_form ul li:first-child div.added_items {
            border-top-right-radius: 7px;
            border-top-left-radius: 7px;
        }
        .checkbox_form ul li div.added_items {
            width: 100%;
            min-height: 40px;
            display: inline-block;
            border-bottom: 1px solid #bbbdbf;
            display: flex;
            align-items: center;
            margin-left: 0px !important;
            padding: 9px 10px;
            justify-content: space-between;
            color: #1d2b30;
            font-family: Montserrat, Rubik;
            font-size: 16px;
            border-radius: 0px;
        }
        .checkbox_form ul li div.added_items span {
            max-width: 400px !important;
        }
        .checkbox_form ul li div.added_items:hover {
            background-color: #f2f2f2;
        }
        .checkbox_form ul li:last-child div.added_items {
           border-bottom: 0px;
        }
        .checkbox_form ul li:last-child div.added_items:hover {
            border-bottom-right-radius: 7px;
            border-bottom-left-radius: 7px;
         }
        .checkbox_form ul li div.added_items i {
            color: #76848b;
        }
        ul {
            padding: 0px;
        }
        ul li {
            list-style: none;
        }
        ul li .input-group {
            position: relative;
        }
        ul li .input-group .form-control {
            color: #1d2b30 !important;
            font-family: Montserrat, Rubik !important;
            font-size: 16px !important;
            font-weight: 500;
            border: unset !important;
        }
        ul li .input-group .input-group-append {
            position: absolute;
            top: 0px;
            bottom: 0px;
            right: 8px;
            margin: 5px;
            z-index: 11;
        }
        ul li .input-group .input-group-append button {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #74787a;
            font-family: Montserrat, Rubik;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            height: 30px;
            width: 70px;
            border-radius: 7px;
            box-shadow: 0 3px 4px -2px rgba(118, 132, 139, 0.29);
        }
        .checkbox_form li div.added_items > div{opacity: 0;}
        .checkbox_form li:hover div.added_items > div{opacity: 1;}

    `]
})
export class ChecklistEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public model: any;
    editIdx = -1;
    lightScrollbarOptions = this.commonService.getLightScrollbarOptions();
    @ViewChild('container', { read: ViewContainerRef }) public container;

    constructor(
        private commonService: CommonService,
      ) { }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
    }

    agInit(params: any,): void {
        this.params = params;
        this.model = params.data.checklist ? params.data.checklist.map(val => {
            return { ...val }
        }) : [];
    }

    getValue(): any {
        return this.model;
    }

    isPopup(): boolean {
        return true;
    }

    addCheckItem($event) {
        $event.stopPropagation();
        this.model = this.model || [];

        if (!this.model.length || this.model[this.model.length - 1].value) {
            this.model.push({
                key: null,
                value: ''
            });
            this.editIdx = this.model.length - 1;
        }
    }

    updateCheckItem(idx, item) {
        if (!item.key) item.key = this.randomString(16);
        this.model[idx] = item != '' ? item : null;
        this.editIdx = -1;
    }

    randomString(length: number) {
        let result = '';
        let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    removeCheckItem($event, idx) {
        $event.stopPropagation();
        this.model.splice(idx, 1);
        this.editIdx = -1;
    }

    editCheckItem($event, idx) {
        $event.stopPropagation();
        this.editIdx = idx;
    }

    cancelCheckEdit($event) {
        $event.stopPropagation();
        this.editIdx = -1;
    }

    updateBox = (event) => {
        if ($(event.target).closest('li').length == 0) {
            this.editIdx = -1;
        }
    }
}