import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { User, UserEmail } from "../../../routes/user/login.response";
import { Observable } from "rxjs";
import { FileUploader } from "../../utils/ng2-file-upload";
import { AuthService } from "../../../routes/user/auth.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class UsersBackendService {
  url = "/api/user";

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router
  ) {}

  uploadPicture(files) {
    return new Observable((observer) => {
      const uploader = new FileUploader({
        url: `${this.url}/uploadPicture`,
        method: "POST",
        authToken: "Bearer " + this.authService.getToken(),
        headers: [
          { name: "Company", value: this.authService.getCompany() },
        ],
        autoUpload: true,
      });
      uploader.onCompleteItem = (item, response, status) => {
        if (status > 400) {
          return observer.error(
            new HttpErrorResponse({ error: response, status: status })
          );
        }
        observer.next(JSON.parse(response));
        observer.complete();
      };
      uploader.addToQueue(files);
    });
  }

  uploadPptxTemplate(files) {
    return new Observable((observer) => {
      const uploader = new FileUploader({
        url: `${this.url}/uploadPptxTemplate`,
        method: "POST",
        authToken: "Bearer " + this.authService.getToken(),
        headers: [
          { name: "Company", value: this.authService.getCompany() },
        ],
        autoUpload: true,
      });
      uploader.onCompleteItem = (item, response, status) => {
        if (status > 400) {
          return observer.error(
            new HttpErrorResponse({ error: response, status: status })
          );
        }
        observer.next(JSON.parse(response));
        observer.complete();
      };
      uploader.addToQueue(files);
    });
  }

  list(lastFetched: string = "") {
    return this.http.get<Array<User>>(`${this.url}?lastFetched=${lastFetched}`);
  }

  search(search: object): Observable<User[]> {
    return this.http.post<User[]>(`${this.url}/search`, search);
  }

  create(user: User) {
    return this.http.post<User>(this.url, user);
  }

  update(user: User) {
    return this.http.put<User>(`${this.url}/${user._id}`, user);
  }

  get(id: string) {
    return this.http.get<User>(`${this.url}/${id}`);
  }

  delete(user: User) {
    return this.http.delete(`${this.url}/${user._id}`);
  }

  sendEmail(type: string) {
    return this.http.post(`${this.url}/send-email/${type}`, {});
  }

  sendEmailTable(type: string) {
    return this.http.post(`${this.url}/send-email-table/${type}`, {});
  }

  sendClosePm() {
    return this.http.post(`${this.url}/send-close-pm`, {});
  }

  sendChangeEmails(users: UserEmail[]) {
    return this.http.post(`${this.url}/send-change-emails`, { users: users });
  }

  // getTomorrowsData(data) {
  //   return this.http.post(`${this.url}/get-tomorrow-data`, data);
  // }

  validate(data: Worker) {
    return this.http.post(`${this.url}/validate`, data);
  }

  validateUser(data: Worker) {
    return this.http.post(`${this.url}/validate-user`, data);
  }

  blockUser(wp: string) {
    return this.http.post(`${this.url}/block-user`, {
      wp: wp,
      url: this.router.url,
    });
  }

  sendPush(message: string) {
    return this.http.post(`${this.url}/send-push`, { message: message });
  }
}
