import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Department } from '../../interfaces/department';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DepartmentsbackendService {

  url = '/api/departments';

  constructor(private http: HttpClient) { }

  list() {
    return this.http.get<Array<Department>>(this.url)
  }

  listAll() {
    return this.http.get<Array<Department>>(`${this.url}?show=all`)
  }

  search(search: object): Observable<Department[]> {
    return this.http.post<Department[]>(`${this.url}/search`, search);
  }

  create(data: Department) {
    return this.http.post(this.url, data);
  }

  update(data: Department) {
    return this.http.put(`${this.url}/${data._id}`, data);
  }

  delete(data: Department) {
    return this.http.delete(`${this.url}/${data._id}`);
  }
}
