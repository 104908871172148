import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../routes/user/auth.service';
import { SignatureAuditTrail } from '../interfaces/signature-audit-trail';
import { SignatureAuditTrailsBackendService } from './backend/signature-audit-trails.backend.service';
import { CustomFieldsStoreService } from './custom-fields-store.service';
import FieldDefinition from '../constants/field-definitions/signature-audit-trails';
import { CommonService } from './common.service';
import { ToolsStoreService } from './tools-store.service';
import { upperFirst } from 'lodash';
import { timeCalculation } from '../../shared/comparators/date-comparator';
import { TranslationsStoreService } from './translations-store.service';

@Injectable({
  providedIn: 'root'
})
export class SignatureAuditTrailsService {
  protected signatureAuditTrailsSubject = new BehaviorSubject(List([]));
  public readonly signatureAuditTrails$: Observable<List<SignatureAuditTrail>> = this.signatureAuditTrailsSubject.asObservable();
  public columns$: Observable<any[]>;
  constructor(
    protected backend: SignatureAuditTrailsBackendService,
    protected customFields: CustomFieldsStoreService,
    protected commonService: CommonService,
    protected toolsStoreService: ToolsStoreService,
    private translationsStoreService: TranslationsStoreService,
    protected auth: AuthService
  ) {
    this.onInit()
  }

  onInit() {
    this.columns$ = combineLatest([this.auth.user$, this.customFields.get('signature-audit-trails'), this.translationsStoreService.languageCode$]).pipe(
      map(([user, columns, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(col => (col.name != "company.name" || user.isRoot()) && (col.name != "isDeleted" || user.isAdminOrToolAdmin()));
        const fields = columns.toArray();
        const col = this.customFields.toColumnDef(defaults, {
          rowSelector: "id_num",
          format: this.formatCell.bind(this)
        });
        const custom = this.customFields.toColumnDef(fields, {
          format: this.customFields.formatCellCustomField.bind(this)
        });

        return col.concat(custom);
      })
    );
  }

  load() {
    return this.backend.list().pipe(
      map((result: Array<SignatureAuditTrail>) => {
        this.signatureAuditTrailsSubject.next(List(result));
        return result
      })
    )
  }

  create(data: SignatureAuditTrail) {
    return this.backend.create(data);
  }

  update(data: SignatureAuditTrail) {
    return this.backend.update(data);
  }

  delete(data: SignatureAuditTrail) {
    return this.backend.delete(data);
  }

  getList() {
    return this.signatureAuditTrailsSubject.getValue();
  }

  link(params) {
    return params.data['url'];
  }

  private formatCell = (col, field) => {
    switch (field.name) {
      case "url":
        return {
          ...col,
          cellRenderer: "linkCell",
          cellRendererParams: {
            linkCreator: this.link.bind(this)
          },
        }
      case "signedOn":
        return {
          ...col,
          valueFormatter: (params) => params.data[field.name] && upperFirst(params.data[field.name])
        }
      case "createdAt":
        return {
          ...col,
          valueFormatter: (params) => params.data[field.name] && this.commonService.getDateTime(params.data[field.name]),
          valueGetter: (params) => params.data[field.name] && this.commonService.getDateTime(params.data[field.name]),
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.createdAt ? nodeA.data.createdAt : null, nodeB && nodeB.data.createdAt ? nodeB.data.createdAt : null, nodeA, nodeB, isInverted);
          }
        }
      case "checklistItems._id":
        return {
          ...col,
          valueFormatter: (params) => params.data['checklistItems'] && params.data['checklistItems'].map(ck => ck.checklistItem && ck.checklistItem.id_num).join(', ')
        }
      case "tool.id_num":
        return {
          ...col,
          valueFormatter: (params) => {
            if(params.data['event']){
              const tool = this.toolsStoreService.getTools().find(t => t._id == params.data['event']['tool']);
              if(tool){
                return tool.id_num
              }
            }
            return null;
          }
        }
      case "tool.name":
        return {
          ...col,
          valueFormatter: (params) => {
            if(params.data['event']){
              const tool = this.toolsStoreService.getTools().find(t => t._id == params.data['event']['tool']);
              if(tool){
                return tool.name
              }
            }
            return null;
          }
        }
      case "checklistItems.name":
        return {
          ...col,
          valueFormatter: (params) => params.data['checklistItems'] && params.data['checklistItems'].length > 0 ? params.data['checklistItems'].map(ck => ck.checklistItem && ck.checklistItem.name).join(', ') : null
        }
      case "checklistItems.taskTransaction":
        return {
          ...col,
          valueFormatter: (params) => params.data['checklistItems'] && params.data['checklistItems'].length > 0 ? params.data['checklistItems'].map(ck => ck.taskTransaction && ck.taskTransaction.id_num).join(', ') : null
        }
      case "checklistItems.done":
        return {
          ...col,
          valueFormatter: (params) => params.data['checklistItems'] && params.data['checklistItems'].length > 0 ? params.data['checklistItems'].map(ck => ck.completed ? 'true' : 'false').join(', ') : null
        }
      case "checklistItems.value":
        return {
          ...col,
          valueFormatter: (params) => params.data['checklistItems'] && params.data['checklistItems'].length > 0 ? params.data['checklistItems'].map(ck => ck.value).join(', ') : null
        }
      default:
        return col;
    }
  }

}
