import { Component, OnInit, Input, ComponentRef, ElementRef, TemplateRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { toolbarIcons } from '../../../core/constants/images';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';
import { AuthService } from '../../../routes/user/auth.service';
import { DynamicTableComponent } from '../dynamic-table/dynamic-table.component';
declare var $: any;
@Component({
  selector: 'app-dynamic-toolbar',
  templateUrl: './dynamic-toolbar.component.pug',
  styleUrls: ['./dynamic-toolbar.component.scss']
})
export class DynamicToolbarComponent implements OnInit {
  @Input() table: DynamicTableComponent;

  public toolbarIcons = toolbarIcons;
  public tooltip: any = {};
  @Input() additionalItems: TemplateRef<any>;
  showDeleted: any = false;
  searchText:string = '';

  @Output() deleteToggle = new EventEmitter<boolean>();
  constructor(
    public translationService: TranslationsStoreService,
    private translate: TranslateService,
    public auth: AuthService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.searchText = params.search;
        if(this.searchText){
          setTimeout(() => {
            $('#search').val(this.searchText)
            this.table.quickSearchByText(this.searchText);
          }, 500)
        }
      }
    );
    this.tooltip = {
      save: this.table.disableSave instanceof TemplateRef ? this.table.disableSave : this.translate.instant('table.SAVE-CHANGES'),
      savePlacement: this.table.disableSave instanceof TemplateRef ? 'right' : 'bottom',
      cut: this.table.disableCut instanceof TemplateRef ? this.table.disableCut : this.translate.instant('table.CUT'),
      insertRow: this.table.disableInsertRow instanceof TemplateRef ? this.table.disableInsertRow : this.translate.instant('table.INSERT-ROW'),
      deleteRow: this.table.disableDeleteRow instanceof TemplateRef ? this.table.disableDeleteRow : this.translate.instant('table.DELETE-ROW'),
      insertCol: this.table.disableInsertCol instanceof TemplateRef ? this.table.disableInsertCol : this.translate.instant('table.INSERT-COLUMN'),
      deleteCol: this.table.disableDeleteCol instanceof TemplateRef ? this.table.disableDeleteCol : this.translate.instant('table.DELETE-COLUMN'),
      unhideCol: this.table.disableUnhideColumn instanceof TemplateRef ? this.table.disableDeleteCol : this.translate.instant('table.UNHIDE-COLUMN'),
      editCol: this.table.disableEditColumn instanceof TemplateRef ? this.table.disableDeleteCol : this.translate.instant('table.EDIT-COLUMN'),
      showDeleted: this.translate.instant('table.SHOW-DELETED'),
      duplicateRow: this.translate.instant('general.DUPLICATE'),
      graphCol: this.table.disableGraphColumn instanceof TemplateRef ? this.table.disableGraphColumn : this.translate.instant('general.GENERATE-GRAPH'),
      generateGraphCol: this.table.disableGraphColumn instanceof TemplateRef ? this.table.disableGraphColumn : this.translate.instant('general.GENERATE-COLUMN-GRAPH'),
      resetAllFilter: this.translate.instant('general.RESET-ALL-FILTERS'),
      assignColumn: this.table.disableAssignColumn instanceof TemplateRef ? this.table.disableAssignColumn : this.translate.instant('general.ASSIGN-COLUMN'),
    }

  }

  showDeletedChange = ($event) => {
    this.showDeleted = !this.showDeleted;
    this.deleteToggle.emit(this.showDeleted);
  }

}
