import { ToolsStoreService } from '../../../../core/services/tools-store.service';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { chunk, uniq } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observer } from 'rxjs';
import { TranslationsStoreService } from '../../../../core/services/translations-store.service';
import { CommonService } from '../../../../core/services/common.service';

@Component({
  selector: 'app-select-detailed-item',
  templateUrl: './select-detailed-item.component.pug',
  styleUrls: ['./select-detailed-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectDetailedItemComponent implements OnInit {
  @Input() type = null;
  @Input() isRtl = false;
  observer: Observer<string[]>
  items = [];
  chunkedItems = [];
  selected = [];
  lightScrollbarOptions = this.commonService.getLightScrollbarOptions();
  search = '';

  constructor(
    private toolsStoreService: ToolsStoreService,
    private bsModalRef: BsModalRef,
    private commonService: CommonService,
    public translationService: TranslationsStoreService,
  ) { }

  ngOnInit(): void {
    switch (this.type) {
      case 'tools':
        this.items = this.toolsStoreService.getOnlyTools().map(item => ({
          id: item._id,
          label: item.name,
          checked: this.selected.includes(item._id),
        }));
        this.chunkedItems = chunk(this.items, 2);
        break;
    }
  }

  close($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.bsModalRef.hide();
  }

  handleCheckAll = ($event) => {
    $event.stopPropagation();
    $event.preventDefault();
    this.selected = this.items.map(item => item.id);
    this.updateItems();
  }

  handleUnCheckAll = ($event) => {
    $event.stopPropagation();
    $event.preventDefault();
    this.selected = [];
    this.updateItems();
  }

  handleChange = ($event, id: string) => {
    $event.preventDefault();

    const checked = $event.target.checked;
    if (checked) {
      this.selected.push(id);
    } else {
      this.selected = this.selected.filter(item => item !== id);
    }
    this.updateItems();
  }

  updateItems = () => {
    const items = this.items.map(item => ({
      ...item,
      checked: this.selected.includes(item.id),
    }));
    this.chunkedItems = chunk(items, 2);
  }

  save($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.observer.next(uniq(this.selected));
    this.bsModalRef.hide();
  }

  onDrag = ($event) => {
    if ($event) {
      $(".come-from-modal.right.modal-dialog").css("position", "unset");
    }
  }

  handleSearch = ($event) => {
    const lower = $event.toLowerCase();
    console.log( 'lower', lower );
    const items = this.items.filter(item => item.label.toLowerCase().includes(lower)).map(item => ({
      ...item,
      checked: this.selected.includes(item.id),
    }));
    this.chunkedItems = chunk(items, 2);
  }

}
