import { LoaderService } from "./../../../core/utils/loader.service";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { SettingsService } from "../../../core/settings/settings.service";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";
import { finalize, map } from "rxjs/operators";
import mustHaveNumber from "../../../shared/validators/mustHaveNumber";
import mustHaveLowercase from "../../../shared/validators/mustHaveLowercase";
import mustHaveUppercase from "../../../shared/validators/mustHaveUppercase";
import mustHaveSpecialCharacter from "../../../shared/validators/mustHaveSpecialCharacter";
import { ToasterService } from "angular2-toaster";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { forIn } from "lodash";
import { RecaptchaErrorParameters } from "ng-recaptcha";
import { CommonService } from "../../../core/services/common.service";
import { phoneCodes } from "../../../core/constants/phone-code";
import { defaultPhoneCode, emailRegex } from "../../../core/constants/variable";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  @ViewChild("termsAndAgreement")
  modal_termsAndAgreement: TemplateRef<any>;

  eulaAccepted: Boolean;
  eulaAcceptedAt: Date = null;
  showAccountAgreedError: Boolean;

  valForm: UntypedFormGroup;
  modalRef: BsModalRef;
  public captchaResponse = "";
  @ViewChild("captchaRef") captchaRef;
  termAndCondition = {
    username: null,
    ip: null,
  };
  phoneCodes = phoneCodes.map(code => ({ id: code, text: `+${code}` }));

  constructor(
    public settings: SettingsService,
    public modalService: BsModalService,
    public user_service: AuthService,
    public router: Router,
    fb: UntypedFormBuilder,
    private toaster: ToasterService,
    private translate: TranslateService,
    private loader: LoaderService,
    public commonService: CommonService
  ) {
    const password = new UntypedFormControl(
      "",
      Validators.compose([
        Validators.required,
        Validators.minLength(6),
        mustHaveNumber.mustHaveNumberValidator(),
        mustHaveUppercase.mustHaveUppercaseValidator(),
        mustHaveLowercase.mustHaveLowercaseValidator(),
        mustHaveSpecialCharacter.mustHaveSpecialCharacterValidator(),
      ])
    );
    this.valForm = fb.group({
      company: [
        null,
        Validators.compose([Validators.required]),
        this.validateCompanyUnique.bind(this),
      ],
      username: [null, Validators.compose([Validators.required])],
      email: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(emailRegex)
        ]),
      ],
      phone: [null, Validators.pattern("^[0-9+]+$")],
      password: password,
      defaultPhoneCode: [defaultPhoneCode, Validators.required],
    });
    this.valForm.get("username").valueChanges.subscribe((next) => {
      this.termAndCondition.username = next;
    });
    this.getIpClient().then((ip) => {
      if (ip) {
        this.termAndCondition.ip = ip;
      }
    });
  }

  getIpClient = async () => {
    return new Promise(async (resolve, reject) => {
      let res = await fetch("http://api.ipify.org/?format=json");
      let ipVar = await res.json();
      resolve(ipVar.ip);
    });
  };

  public resolved(captchaResponse: string): void {
    const newResponse = captchaResponse
      ? `${captchaResponse.substr(0, 7)}...${captchaResponse.substr(-7)}`
      : captchaResponse;
    this.captchaResponse += `${JSON.stringify(newResponse)}\n`;
    this.submitData();
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    this.captchaResponse += `ERROR; error details (if any) have been logged to console\n`;
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
    this.toaster.pop("error", errorDetails.join(","));
    this.loader.remove();
  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    forIn(this.valForm.controls, (c) => c.markAsTouched());
    if (this.valForm.valid) {
      this.openModal(this.modal_termsAndAgreement);
    }
  }

  trimString = (value) => {
    // return value.replace(/[&\/\\#,+\-()!^;@$~%.'":*?<>{} ]/g, '').toLowerCase();
    return value.trim();
  };

  validateCompanyUnique(control: AbstractControl) {
    return this.user_service
      .checkCompany(this.trimString(control.value))
      .pipe(map((taken: boolean) => (taken ? { companyExists: true } : null)));
  }

  ngOnInit() {}

  openModal(template) {
    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true,
      keyboard: false,
      class: "modal-lg",
    });
  }

  startUsing() {
    this.modalRef.hide();
    this.router.navigate(["/setup"]);
  }

  createAccount = ($event) => {
    $event.preventDefault();
    this.loader.add();
    this.captchaRef.execute();
  };

  submitData = () => {
    if (this.eulaAccepted) {
      this.showAccountAgreedError = false;
      if (this.valForm.valid) {
        this.user_service
          .register({
            ...this.valForm.value,
            eulaAccepted: this.eulaAccepted,
            eulaAcceptedAt: this.eulaAcceptedAt,
            company: this.trimString(this.valForm.value.company),
            termAndCondition: this.termAndCondition,
          })
          .pipe(finalize(() => {}))
          .subscribe(
            () => {
              this.user_service.sendVerification().subscribe(
                () => {
                  this.loader.remove();
                  this.modalRef.hide();
                  this.router.navigate(["/registration-complete"]);
                },
                (err) => {
                  this.loader.remove();
                  this.captchaRef.reset();
                  this.toaster.pop(
                    "error",
                    this.translate.instant(
                      "toasters.GENERAL.ERR_ERROR_TRY_AGAIN"
                    )
                  );
                }
              );
            },
            (err) => {
              this.loader.remove();
              if (err.status == 402) {
                this.toaster.pop(
                  "error",
                  "Signup limit exceeded. Please contact administrator for more detail."
                );
              }
              this.captchaRef.reset();
            }
          );
      }
    } else {
      this.showAccountAgreedError = true;
      this.loader.remove();
    }
  };

  accountAgreedChange = () => {
    this.showAccountAgreedError = !this.eulaAccepted;
    if (this.eulaAccepted) {
      this.eulaAcceptedAt = new Date();
    } else {
      this.eulaAcceptedAt = null;
    }
  };
}
