import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../../routes/user/auth.service';
import { Procedure } from '../interfaces/procedure';
import { ProcedureBackendService } from './backend/procedure.backend.service';
import { CustomFieldsStoreService } from './custom-fields-store.service';
import FieldDefinition from '../constants/field-definitions/procedure';
import { CommonService } from './common.service';
import { timeCalculation } from '../../shared/comparators/date-comparator';
import { ChecklistItemsService } from './checklist-items.service.service';
import { TranslationsStoreService } from './translations-store.service';

@Injectable({
  providedIn: 'root'
})
export class ProcedureStoreService {

  protected proceduresSubject = new BehaviorSubject(List([]));
  protected pmProceduresSubject = new BehaviorSubject(List([]));
  public readonly procedures$: Observable<List<Procedure>> = this.proceduresSubject.asObservable();
  public readonly pmProcedures$: Observable<List<Procedure>> = this.pmProceduresSubject.asObservable();
  public columns$: Observable<any[]>;
  constructor(
    protected backend: ProcedureBackendService,
    protected customFields: CustomFieldsStoreService,
    protected checklistItemsService: ChecklistItemsService,
    protected auth: AuthService,
    private translationsStoreService: TranslationsStoreService,
    protected commonService: CommonService
  ) {
    this.onInit()
  }

  onInit() {
    this.auth.user$.subscribe(user => {
      if (!user || !user._id) {
        return;
      }
      this.load().subscribe();
    });

    this.columns$ = combineLatest([this.auth.user$, this.customFields.get('procedures'), this.translationsStoreService.languageCode$]).pipe(
      map(([user, columns, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(col => (col.name != "company.name" || user.isRoot()) && (col.name != "isDeleted" || user.isAdminOrToolAdmin()));
        const fields = columns.toArray();
        const col = this.customFields.toColumnDef(defaults, {
          rowSelector: "id_num",
          format: this.formatCell.bind(this)
        });
        const custom = this.customFields.toColumnDef(fields, {
          format: this.customFields.formatCellCustomField.bind(this)
        });

        return col.concat(custom);
      })
    );
  }

  load() {
    return this.backend.list().pipe(
      map((result: Array<Procedure>) => {
        this.proceduresSubject.next(List(result));
        this.pmProceduresSubject.next(List(result.filter(r => r.kind == 'PM')));
        return result
      })
    )
  }

  create(data: Procedure) {
    return this.backend.create(this.customFields.includeUrl(data))
      .pipe(
        map((procedure) => new Procedure(procedure)),
        tap(procedure => {
          this.proceduresSubject.next(this.proceduresSubject.getValue().unshift(procedure));
          if(data.kind == 'PM') this.pmProceduresSubject.next(this.pmProceduresSubject.getValue().unshift(procedure));
        })
      );
  }

  copyPasteProcedure(data: {name: string, tasks: string, isPM: boolean}) {
    return this.backend.copyPasteProcedure(this.customFields.includeUrl(data))
    .pipe(
      map((procedure) => new Procedure(procedure)),
      tap(procedure => {
        this.checklistItemsService.updateProcedureTasks(procedure.tasks.map(t => t.checklistItem), data.isPM)
        this.proceduresSubject.next(this.proceduresSubject.getValue().unshift(procedure));
        if(data.isPM) this.pmProceduresSubject.next(this.pmProceduresSubject.getValue().unshift(procedure));
      })
    );
  }

  update(data: Procedure) {
    return this.backend.update(this.customFields.includeUrl(data))
      .pipe(
        map((procedure) => new Procedure(procedure)),
        tap(procedure => {
          const procedures = this.proceduresSubject.getValue();
          const idx = procedures.findIndex((p: Procedure) => p._id === data._id);
          this.proceduresSubject.next(procedures.set(idx, procedure));
          if(data.kind == 'PM') this.pmProceduresSubject.next(procedures.set(idx, procedure));
        })
      );
  }

  delete(data: Procedure) {
    return this.backend.delete(data);
  }

  getList() {
    return this.proceduresSubject.getValue();
  }

  options() {
    return this.backend.options();
  }

  private formatCell = (col, field) => {
    switch (field.name) {
      case "updatedAt":
        return {
          ...col,
          valueFormatter: (params) => params.data[field.name] && this.commonService.getDateTime(params.data[field.name]),
          valueGetter: (params) => params.data[field.name] && this.commonService.getDateTime(params.data[field.name]),
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.updatedAt ? nodeA.data.updatedAt : null, nodeB && nodeB.data.updatedAt ? nodeB.data.updatedAt : null, nodeA, nodeB, isInverted);
          }
        }
      default:
        return col;
    }
  }
}
