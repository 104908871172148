import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

const browser = require('jquery.browser');
declare var $: any;

import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { AuthService } from '../../routes/user/auth.service';
import { User } from '../../routes/user/login.response';
import { combineLatest, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NavService } from '../../routes/main/nav.service';
import { Location } from '@angular/common';
import { CompaniesStoreService } from '../../core/services/companies-store.service';
import { map } from 'rxjs/operators';
import { UsersStoreService } from '../../core/services/users-store.service';
import { TranslationsStoreService } from '../../core/services/translations-store.service';
import { WindowsService } from '../../core/services/windows.service';
import { MenuPopupComponent } from './menu-popup/menu-popup.component';
import { LoaderService } from '../../core/utils/loader.service';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  // navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout
  screenWidth: any;
  @Input() openMenu: boolean = true;
  public user: User;
  public user$: User;
  private userSub: Subscription;
  @Output('onOpenMenuClick') onOpenMenuClick: EventEmitter<boolean> = new EventEmitter();

  isNavSearchVisible: boolean;
  @ViewChild('fsbutton') fsbutton;  // the fullscreen button
  isRTL:boolean = false;

  constructor(public menu: MenuService,
    public settings: SettingsService,
    public userService: AuthService,
    private usersService: UsersStoreService,
    public companiesService: CompaniesStoreService,
    private router: Router,
    public service: NavService,
    public location: Location,
    public windowsService: WindowsService,
    public translationService: TranslationsStoreService,
    private modal: BsModalService,
    private loader: LoaderService
  ) {

    this.translationService.rtl$.subscribe(rtl => {
      this.isRTL = rtl;
    })

    // show only a few items on demo
    //this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout

    menu.menuItems$.subscribe(items=>{
      this.menuItems = items.slice(0,4);
    })

  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  goBack() {
    this.location.back();
  }

  ngOnInit() {
    this.isNavSearchVisible = false;
    if (browser.msie) { // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }
    this.userSub = this.userService.user$.subscribe(user => {
      if (this.user && !user) {
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['/']);
      }
      this.user = user;
    });

    // @ts-ignore
    this.user$ = combineLatest([this.userService.user$, this.usersService.users$]).pipe(
      map(([user, users])=> {
        if (!user || !users) return null;

        return users.find(u => u._id === user._id);

      })
    )
  }

  ngAfterViewChecked() {
    const windowsHeight = $(window).width();
    if (windowsHeight > 600) {
      const w = $('.header-user-name').width() + 40;
      if(this.isRTL){
        $('.navbar-form').css('left', w);
        $('.navbar-form').css('right', 'unset');
      }else{
        $('.navbar-form').css('right', w);
        $('.navbar-form').css('left', 'unset');
      }
    }
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting('offsidebarOpen');
  }

  toggleCollapsedSideabar() {
    this.settings.toggleLayoutSetting('isCollapsed');
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  // closeMenu() {
  //   this.onOpenMenuClick.emit(!this.openMenu);
  // }

  menuPopup($event){
    if(this.windowsService.getWindowSize() <= 600){
      this.modal.show(MenuPopupComponent, {initialState: {}, class: "menu-popup"});
    }
  }

  startLoader = ($event) => {
    this.loader.add();
  }

}
