const imageRoot = "assets/img";
const toolbarPath = `${imageRoot}/toolbar`;

export const toolbarIcons = {
    save: `${toolbarPath}/save.svg`,
    cut: `${toolbarPath}/Cut.png`,
    copy: `${toolbarPath}/Copy.png`,
    paste: `${toolbarPath}/Paste.png`,
    insertRow: `${toolbarPath}/insert-row.png`,
    deleteRow: `${toolbarPath}/delete-row.png`,
    insertCol: `${toolbarPath}/insert-column.png`,
    deleteCol: `${toolbarPath}/delete-column.png`,
    excel: `${toolbarPath}/Excel.png`,
    duplicateRow: `${toolbarPath}/duplicate.png`,
    editCol: `${toolbarPath}/edit-column.png`,
    unhideCol: `${toolbarPath}/unhide.png`,
    graphCol: `${toolbarPath}/generate-chart.png`,
    generateGraphCol: `${toolbarPath}/generate-g-chart.png`,
    resetAllFilter: `${toolbarPath}/reset-filter.png`,
    assignColumn: `${toolbarPath}/add.svg`,
}