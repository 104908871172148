import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BodyOutputType, Toast, ToasterService } from 'angular2-toaster';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Menu } from '../../../core/interfaces/menu';
import { MenuStoreService } from '../../../core/services/menu-store.service';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';

@Component({
  selector: 'app-add-new-menu',
  templateUrl: './add-new-menu.component.pug',
  styleUrls: ['./add-new-menu.component.scss']
})
export class AddNewMenuComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    public fb: UntypedFormBuilder,
    public translationService: TranslationsStoreService,
    private translate: TranslateService,
    private menuStoreService: MenuStoreService,
    private toaster: ToasterService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      name: this.fb.control('', Validators.required),
    });
  }

  save = () => {
    if (this.form.valid) {
      var regExp = /[a-zA-Z]/g;
      let errors = [];
      if (!regExp.test(this.form.value.name)) errors.push(this.translate.instant("ADD-NEW-REPORT.REPORT-NAME-MUST-CONTAIN-STRING-CHARACTER"))
      if (errors.length == 0) {
        const menus = this.menuStoreService.getList().toArray();
        if (menus.filter(m => m.key == this.form.value.name.replace(/ /g, '').toLowerCase()).length == 0) {
          this.menuStoreService.create({ ...this.form.value, type: 1 }).subscribe(
            (menu: Menu) => {
              this.bsModalRef.hide();
              this.toaster.pop('success', this.translate.instant("SMART-DROPDOWN.MENU-CREATED-SUCCESSFULLY"));
            },
            (err) => {
              this.toaster.pop('error', this.translate.instant("SMART-DROPDOWN.SOMETHING-WENT-WRONG-WHILE-ADDING-MENU"));
            },
            () => {
            }
          )
        } else {
          this.toaster.pop('error', this.translate.instant("SMART-DROPDOWN.MENU-CAN-NOT-BE-SAME"));
        }
      } else {
        const toast: Toast = {
          type: 'error',
          title: '',
          body: errors.join(' <br/> '),
          bodyOutputType: BodyOutputType.TrustedHtml,
          timeout: 5000
        };
        this.toaster.pop(toast);
      }
    }
  }

}
