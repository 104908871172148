import { Component, ElementRef, ViewChild } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ToolsStoreService } from "../../../core/services/tools-store.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-status-type-renderer",
  template: `<div>
    <ng-container *ngFor="let value of values; let last = last">
      <a href="#" (click)="handleFilter($event, value)">{{ value }}</a>
      <span *ngIf="!last">, </span>
    </ng-container>
  </div>`,
  styles: [``],
})
export class StatusTypeRendererComponent implements ICellRendererAngularComp {
  params;
  item;
  values: string[];
  @ViewChild("uploadctl") uploadctl: ElementRef;
  show = false;

  constructor(
    private toolsStoreService: ToolsStoreService,
    private router: Router
  ) {}

  agInit(params): void {
    this.params = params;
    this.item = this.params.data;
    this.values = this.params.value ? this.params.value.split(", ") : [];
  }

  refresh(params?: any): boolean {
    return true;
  }

  async handleFilter($event: Event, value: string) {
    $event.preventDefault();
    $event.stopPropagation();
    const type = value.split(" (")[0];
    let groups = [];
    if (this.item.isGroup) {
      const relatedGroups = (await this.toolsStoreService.getRelatedGroupIds([
        this.item._id,
      ])) as string[];
      groups = [this.item._id].concat(relatedGroups);
    }
    const filter = {
      status: type,
      tool: !this.item.isGroup ? this.item._id : null,
      group: groups,
    };
    localStorage.setItem("_badge_filter", JSON.stringify(filter));
    this.router.navigate(["/main/work-orders"]);
  }
}
