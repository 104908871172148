import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../../routes/user/auth.service';
import { DynamicDropdown } from '../interfaces/dynamic-dropdown';
import { DynamicDropdownBackendService } from './backend/dynamic-dropdown.backend.service';
import { CustomFieldsStoreService } from './custom-fields-store.service';
import FieldDefinition from '../constants/field-definitions/dynamic-dropdown';
import { TranslationsStoreService } from './translations-store.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicDropdownStoreService {

  protected dynamicDropdownSubject = new BehaviorSubject(List([]));
  public readonly dynamicDropdowns$: Observable<List<any>> = this.dynamicDropdownSubject.asObservable();
  public columns$: Observable<any[]>;

  constructor(
    protected backend: DynamicDropdownBackendService,
    protected customFields: CustomFieldsStoreService,
    private translationsStoreService: TranslationsStoreService,
    protected auth: AuthService
  ) {
    this.onInit()
  }

  onInit() {
    this.columns$ = combineLatest([this.auth.user$, this.translationsStoreService.languageCode$]).pipe(
      map(([user, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(col => (col.name != "company.name" || user.isRoot()) && (col.name != "isDeleted" || user.isAdminOrToolAdmin()));
        const col = this.customFields.toColumnDef(defaults, {
          rowSelector: "id_num"
        });
        return col;
      })
    );
  }

  getListByKey(key:string) {
    if(key){
      return this.backend.listByKey(key).pipe(
        map(values => values.map(value => new DynamicDropdown(value))),
        map(values => List(values)),
        shareReplay(1)
      );
    }else{
      return of([]).pipe(
        map(values => List(values)),
        shareReplay(1),
      );
    }
  }

  getById(id: string) {
    return this.backend.getById(id).pipe(
      map(value => new DynamicDropdown(value)),
      shareReplay(1)
    );
  }

  create(data: DynamicDropdown) {
    return this.backend.create(data);
  }

  update(data: DynamicDropdown) {
    return this.backend.update(data);
  }

  delete(data: DynamicDropdown) {
    return this.backend.delete(data);
  }

  getList() {
    return this.dynamicDropdownSubject.getValue();
  }

}
