<div id="container">
  <!-- <header>
      <nav>
        <ul>
          <li><a href="">zorgtech-website</a></li>
          <li><a href="tel:+97252-6174879">Schedule a demo: (+972)&#8209;[0]52&#8209;6174879</a></li>
          <li id="hebrew"><a href="">Hebrew</a></li>
        </ul>
      </nav>
    </header> -->

  <div id="content-container">
    <section id="section1">
      <nav>
        <ul>
          <li id="logo">
            <img src="assets/img/Logo land - Regular White@3x.png" alt="logo" class="brand-logo">
          </li>
          <div id="li-btns">
            <li><a id="log-in" [routerLink]="'/login'">Log in</a></li>
            <li><a id="sign-up" [routerLink]="'/signup'">Sign up</a></li>
          </div>
        </ul>
      </nav>

      <div id="top-banner">
        <div id="banner-text-wrapper">
          <h1>Simple&nbsp;factory maintenance software</h1>
          <span>Anymaint is a computerizes maintenance management 
            system that allows you to take control of your factory</span>
        </div>
        <img src="assets/img/laptop-mockup.png" alt="laptop">
      </div>

      <div id="sub-header-wrapper">
        <h2 id="sub-header">CMMS - Computerized Maintenance<br> Management System<br>
          Control everything in one place</h2>
      </div>
    </section>
    <section id="section2">
      <div id="frames" class="sec2-child"><img src="assets/img/frames.png" alt="screen frames"></div>
      <div id="arrows" class="sec2-child"><img src="assets/img/arrows.svg" alt="arrows"></div>
      <ul id="checkbox-list" class="sec2-child">
        <li>Preventive maintenance</li>
        <li>All documents in one place</li>
        <li>Tool history</li>
        <li>Maintenance statistics, KPI and analysis</li>
      </ul>
    </section>
    <div id="contact-banner">
      <h2>See how it works</h2>
      <a href="">Launch demo</a>
    </div>
    <section id="card-section">
      <h2>Why work with us?</h2>

      <div id="cards-wrapper">

        <div class="card-units">
          <h4>The CMMS is</h4>
          <p>Built for managers of small and
            medium factories, who are tired
            of running corrective
            maintenance in firefighting
            mode</p>
        </div>

        <div class="card-units">
          <h4>What is the purpose of CMMS?</h4>
          <p>Our maintenance management
            software will help you to
            implement Preventive
            Maintenance</p>
        </div>

        <div class="card-units">
          <h4>Anymaint is simple and visual</h4>
          <p>Unlike competitor's products, that are
            recently too complicated and expensive, we
            provide a visual, simple and lean product,
            designed for end users to do simple things in
            simple way</p>
        </div>

        <div class="card-units">
            <h4>What different in  Anymaint</h4>
          <p>We offer what the SMB factories
            are striving for:<br>
            Simple enough for every worker<br>
            Visual<br>
            Easy to setup<br>
            Lean</p>
        </div>

        <div class="card-units">
          <h4>Why Preventive maintenance?</h4>
          <p>Preventive Maintenance costs
            ×3 less than failure mode
            maintenance, but a lot of
            factories still do not
            implement PM</p>
        </div>

        <div class="card-units">
            <h4>How will we start?</h4>
          <p>Anymaint allows fast
            setup, easy updating, powerful
            follow up, and no overhead!</p>
        </div>

      </div>

    </section>


    <section id="slider-section">
      <h2>Some screenshots</h2>
      <button id="prev" onclick="this.nextElementSibling.scrollLeft -= this.nextElementSibling.offsetWidth"></button>
      <div id="img-slider">
        <div class="img-slide"><img src="assets/img/screenshot6.png" alt="CMMS wall dashboard"><span>CMMS wall dashboard</span></div>
        <div class="img-slide"><img src="assets/img/screenshot1.png" alt=" CMMS maintenance map"><span>Maintenance map</span></div>
        <div class="img-slide"><img src="assets/img/screenshot2.png" alt="CMMS maintenance map, drawing"><span>CMMS maintenance map, drawing</span></div>
        <div class="img-slide"><img src="assets/img/screenshot3.png" alt="CMMS tool card"><span>CMMS tool card</span></div>
        <div class="img-slide"><img src="assets/img/screenshot4.png" alt="CMMS equipment list"><span>CMMS equipment list</span></div>
        <div class="img-slide"><img src="assets/img/screenshot5.png" alt="CMMS users management"><span>CMMS users management</span></div>
      </div>
      <button id="next"
        onclick="this.previousElementSibling.scrollLeft += this.previousElementSibling.offsetWidth"></button>
    </section>

    <footer>
      <ul>
        <li><a href="">Anymaint homepage</a>
          <!--  <br><a href="">Hebrew website</a> --></li>
        <li><!-- <a href="tel:+972-52-6174879">Phone: (+972)-[0]52-6174879</a><br> -->
          <a href="mailto:Sales@ZorgTech.co.il"><b>Contact</b>&nbsp;&nbsp; info@zorgtech.co.il</a></li>
      </ul>
      <!-- <div id="social-links">
        <a id="facebook" href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-square"
            target="_blank"></i></a>
        <a id="linkedin" href="https://www.linkedin.com/" target="_blank"><i class="fab fa-linkedin"></i></a>
      </div> -->
    </footer>

    <app-cookie-banner></app-cookie-banner>

  </div>
</div>