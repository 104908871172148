// Author: T4professor

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-button-renderer',
  template: `<div class="d-flex gap-2">
      <button *ngIf="visible && !removeButton" [class]="class" type="button" (click)="onClick($event)" [innerHtml]="label"></button>
      <a href="#" *ngIf="visible && removeButton && label" (click)="onClick($event)" [innerHtml]="label"></a>
      <img src="assets/img/close-back.svg" alt="remove" *ngIf="visible && removeButton && label" style="width:20px;cursor:pointer;" (click)="onRemoveFile($event)"/>
    </div>`
})

export class ButtonRendererComponent implements ICellRendererAngularComp {

  params;
  label: string;
  visible: boolean;
  removeButton: boolean = false;
  class: string = 'btn btn-primary';


  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
    this.visible = this.params.visible ? this.params.visible(this.params.node.data) : true;
    if(this.params.class){
      this.class = this.params.class;
    }
    if(this.params.removeButton){
      this.removeButton = this.params.removeButton;
    }
    if(this.params.field && !this.label){
      if(this.params.field.includes('.')){
        const data = { ...params.data }
        const props = this.params.field.split('.');
        this.label = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', data);
      }else{
        this.label = this.params.data[this.params.field] || null;
      }
    }
  }

  refresh(params?: any): boolean {
    return true;
  }


  onClick($event) {
    if (this.params.onClick instanceof Function) {
      const params = {
        event: $event,
        rowData: this.params.node.data
      }
      this.params.onClick(params);

    }
  }

  onRemoveFile($event) {
    if (this.params.onRemoveFile instanceof Function) {
      const params = {
        event: $event,
        rowData: this.params.node.data
      }
      this.params.onRemoveFile(params);

    }
  }
}