import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToolPointer } from "../../interfaces/tool-pointer";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ToolPointersBackendService {
  url = "/api/tool-pointers";

  constructor(private http: HttpClient) {}

  get(): Observable<ToolPointer> {
    return this.http.get<ToolPointer>(this.url);
  }

  create(toolPointer: ToolPointer) {
    return this.http.post<ToolPointer>(this.url, toolPointer);
  }
}
