<div class="wrapper">
  <div class="block-center">
    <div class="logo text-center">
      <img src="assets/img/Logo land - Regular White@3x.png" alt="logo" class="brand-logo">
    </div>
      <div class="main-card card card-flat">

          <div class="row">
              <div class="col-8 offset-2">
                  <div class="content-container">
                      <div class="content card card-flat">
                          <ng-container *ngIf="completed">
                              <div class="card-body">
                                <ng-container *ngIf="success">
                                  <p class="text-center">Account successfully verified</p>
                                  
                                </ng-container>

                                <ng-container *ngIf="!success">
                                  <p class="text-center">Cannot verify account, account may not exist or is already verified</p>
                                </ng-container>

                                <p class="text-center"><a routerLink="/login">Login</a></p>

                                
                              </div>
                          </ng-container>
                      </div>
                  </div>
              </div>
          </div>
      
      
      </div>
  </div>
</div>


