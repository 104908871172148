import { Component, OnInit } from '@angular/core';
import { Observer } from 'rxjs';
import { UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { FieldBase } from '../../models/field-base';
import { ModalResult } from '../../models/modal-result';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.pug',
  styleUrls: ['./dynamic-form.component.scss']
})

export class DynamicFormComponent implements OnInit {
  observer: Observer<ModalResult>;
  question: string;
  controlDefs: FieldBase<any>[] = [];
  form: UntypedFormGroup;
  custom: UntypedFormGroup;
  tableName:string ="";
  value: {};
  preSave: Function;

  constructor(
    private bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
    this.form.patchValue(this.value);
    if($('.wrapper').attr('dir') == 'rtl'){
      $('.dynamic-form').attr('dir', 'rtl');
      $('.dynamic-form .white_btn').addClass('ml-2');
    }
  }

  cancel(event) {
    event.preventDefault();
    const result = {
      result: false,
      payload: null
    }
    this.bsModalRef.hide();
    this.observer.next(result);
  }

  onSubmit() {
    const payLoad = JSON.stringify(this.form.value);
    if (this.form.invalid) return false;

    if (this.preSave && this.preSave instanceof Function){
      if (!this.preSave(this.form.value)) return false;
    }
    const result = {
      result: true,
      payload: this.form.value
    }
    this.bsModalRef.hide();
    this.observer.next(result);
  }
}
