import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GroupUpdateMaintenance, Maintenance } from '../../interfaces/maintenance';
import { MaintenanceType } from '../../interfaces/maintenance-type';
import { isArray } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PreventiveBackendService {
  url = '/api/maintenance';

  constructor(private http: HttpClient) {
  }

  list(lastFetched: string = ''): Observable<Maintenance[]> {
    return this.http.get<Maintenance[]>(`${this.url}?lastFetched=${lastFetched}`);
  }

  listDeleted(): Observable<Maintenance[]> {
    return this.http.get<Maintenance[]>(`${this.url}?deleted=true`);
  }

  search(search: object): Observable<Maintenance[]> {
    return this.http.post<Maintenance[]>(`${this.url}/search`, search);
  }

  /*
    getOne(toolId, id) {
      return this.http.get<Maintenance>(`${this.url}${toolId}/${id}`);
    }
  */

  create(event: Maintenance) {
    return this.http.post<Maintenance>(this.url, event);
  }

  update(event: any) {
    return this.http.put<Maintenance>(`${this.url}/${event._id}`, event);
  }

  getById(id: string) {
    return this.http.get<Maintenance>(`${this.url}/${id}`);
  }

  updatePmDate(maintenances: Maintenance[]) {
    return this.http.post(`${this.url}/update-next-pms`, { maintenances: maintenances });
  }

  generatePmCalender() {
    return this.http.post(`${this.url}/generate-pm-calender`, { generate: 1 });
  }

  generatePMCalenderTillDate(endDate: string) {
    return this.http.post(`${this.url}/generate-pm-calender-till-date`, { endDate });
  }

  delete(ids: any) {
    if (!isArray(ids)) {
      ids = [ids];
    }
    return this.http.delete(`${this.url}`, { params: { ids: ids } });
  }

  listTypes() {
    return this.http.get<MaintenanceType[]>(this.url + '/types');
  }

  updateBulk(maintenances: Maintenance[]) {
    return this.http.post<Maintenance[]>(`${this.url}/update-bulk`, { maintenances: maintenances });
  }

  groupUpdatePM(group: GroupUpdateMaintenance) {
    return this.http.post<Maintenance[]>(`${this.url}/group-update-pm`, group);
  }
}
