import { ChangeDetectorRef, Injectable } from "@angular/core";
import { MachineStatusesBackendService } from "./backend/machine-statuses.backend.service";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { AuthService } from "../../routes/user/auth.service";
import { List } from "immutable";
import { ICreateMachineStatus } from "../interfaces/machine-status";
import { machineStatusTypes } from "../constants/machine-statuses";
import { map, tap } from "rxjs/operators";
import { CustomFieldsStoreService } from "./custom-fields-store.service";
import FieldDefinition from "../constants/field-definitions/machine-status";
import { isObject } from "lodash";
import { TranslationsStoreService } from "./translations-store.service";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "./common.service";

@Injectable({
  providedIn: "root",
})
export class MachineStatusesStoreService {
  protected machineStatusesSubject = new BehaviorSubject(List([]));
  public machineStatuses$: Observable<List<any>> =
    this.machineStatusesSubject.asObservable();
  public columns$: Observable<any[]>;
  public readonly customFields$: Observable<any[]>;

  constructor(
    protected backend: MachineStatusesBackendService,
    protected customFields: CustomFieldsStoreService,
    protected commonService: CommonService,
    public translationsStoreService: TranslationsStoreService,
    protected auth: AuthService,
    protected translateService: TranslateService
  ) {
    this.auth.user$.subscribe((user) => {
      if (!user || !user._id) {
        return;
      }
      this.load().subscribe();
    });

    this.columns$ = combineLatest([
      this.auth.user$,
      this.customFields.get("machineStatuses"),
      this.translationsStoreService.languageCode$,
    ]).pipe(
      map(([user, columns, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(
          (col) =>
            (col.name != "company.name" || user.isRoot()) &&
            (col.name != "isDeleted" || user.isAdmin())
        );
        const fields = columns.toArray();
        const col = this.customFields.toColumnDef(defaults, {
          rowSelector: "id_num",
        });
        const custom = this.customFields.toColumnDef(fields, {
          format: this.customFields.formatCellCustomField.bind(this),
        });

        return col.concat(custom);
      })
    );
  }

  load() {
    this.machineStatusesSubject.next(List([]));
    return this.backend.list().pipe(
      map((machineStatuses: Array<any>) =>
        machineStatuses.map((machineStatuse) =>
          this.remapMachineStatus(machineStatuse)
        )
      ),
      tap((machineStatuse: Array<any>) => {
        const statuses = List(machineStatuse);
        const statusesWithTypes = statuses.map((status) => {
          const statusWithType = {
            ...status,
            type: machineStatusTypes
              .find((type) => type.severity === status.severity)
              .name.toUpperCase(),
            id: status.name,
          };
          return statusWithType;
        });
        this.machineStatusesSubject.next(statusesWithTypes);
      })
    );
  }

  search(search: object) {
    return this.backend.search(search).pipe(
      map((machineStatuses: Array<any>) =>
        machineStatuses.map((machineStatuse) =>
          this.remapMachineStatus(machineStatuse)
        )
      ),
      map((machineStatuse: Array<any>) => {
        return machineStatuse;
      })
    );
  }

  getName = (machineStatus: ICreateMachineStatus) => {
    return machineStatus.translationKey &&
      this.commonService.hasTranslateAlwaysTogglePermission() &&
      this.translateService.instant(machineStatus.translationKey)
      ? this.translateService.instant(machineStatus.translationKey)
      : machineStatus.title;
  };

  remapMachineStatus = (machineStatuse) => {
    machineStatuse.toolObject = machineStatuse.tool || [];
    machineStatuse.tool =
      machineStatuse.toolObject.length > 0
        ? machineStatuse.toolObject.map((t) => t._id)
        : null;
    machineStatuse.groupObject = machineStatuse.group || [];
    machineStatuse.group =
      machineStatuse.groupObject.length > 0
        ? machineStatuse.groupObject.map((g) => g._id)
        : null;
    if (
      machineStatuse.workerDepartment &&
      Object.keys(machineStatuse.workerDepartment).length > 0
    ) {
      machineStatuse.workerObject = machineStatuse.workerDepartment || null;
    } else {
      machineStatuse.workerObject = machineStatuse.worker || null;
    }
    machineStatuse.worker = machineStatuse.workerObject
      ? machineStatuse.workerObject._id
      : null;
    return machineStatuse;
  };

  create(data: ICreateMachineStatus) {
    if (!data.name) {
      data.name = data.title.replace(" ", "_");
    }
    return this.backend.create(data).pipe(
      map((ev) => this.remapMachineStatus(ev)),
      tap((ev) => {
        this.machineStatusesSubject.next(
          this.machineStatusesSubject.getValue().unshift(ev)
        );
      })
    );
  }

  delete(statusId: number) {
    return this.backend.delete(statusId).pipe(
      tap(() => {
        const machineStatuses = this.machineStatusesSubject.getValue();
        const idx = machineStatuses.findIndex((e) => e._id === statusId);
        const value = machineStatuses.get(idx);
        value.isDeleted = true;
        this.machineStatusesSubject.next(machineStatuses.set(idx, value));
      })
    );
  }

  getList() {
    return this.machineStatusesSubject.getValue();
  }

  getMachineStatusById(id: string) {
    return this.getList().find((tool) => tool["_id"] === id);
  }

  update(data: ICreateMachineStatus) {
    return this.backend.update(data).pipe(
      map((ev) => this.remapMachineStatus(ev)),
      tap((ev) => {
        const machineStatuses = this.machineStatusesSubject.getValue();
        const idx = machineStatuses.findIndex((e) => e._id === ev._id);
        this.machineStatusesSubject.next(machineStatuses.set(idx, ev));
      })
    );
  }
}
