<div class="card mb-0">
  <div class="card-body bt 55">
    <div class="py-3">
      <h3 class="text-center mb-2">Let's setup the payment.</h3>
      <div class="mb-5 text-center">
        You will be not charged during trial period (30 days)
      </div>
      <div class="text-right my-2">
        <app-add-to-calendar
          [calendarEvent]="calendarEvent"
        ></app-add-to-calendar>
      </div>
      <app-subscription-payment-form
        (onComplete)="nextStep()"
      ></app-subscription-payment-form>
    </div>

    <div class="py-3 text-center">
      <a href="#" class="btn blue_btn" (click)="nextStep()">Cancel</a>
    </div>
  </div>
</div>

<app-cookie-banner></app-cookie-banner>
