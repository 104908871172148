import { timeCalculation } from "../../../shared/comparators/date-comparator";
import { IField } from "../../interfaces/field";

const FieldDefinition: IField[] = [
    {
        name: 'id_num',
        label: 'table.signature-at.table-title.ID',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'company.name',
        label: 'table.signature-at.table-title.COMPANY',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'tool.id_num',
        label: 'table.signature-at.table-title.TOOL-ID',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'tool.name',
        label: 'table.signature-at.table-title.TOOL-NAME',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'event.id_num',
        label: 'table.signature-at.table-title.EVENT-ID',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'checklistItems._id',
        label: 'table.signature-at.table-title.TASK-ID',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'checklistItems.name',
        label: 'table.signature-at.table-title.TASK-NAME',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'checklistItems.taskTransaction',
        label: 'table.signature-at.table-title.TASK-TRANSACTION-ID',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'checklistItems.done',
        label: 'table.signature-at.table-title.TASK-DONE',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'checklistItems.value',
        label: 'table.signature-at.table-title.TASK-VALUE',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'controlButton',
        label: 'table.signature-at.table-title.SIGNATURE-CONTROL-NAME',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'user.id_num',
        label: 'table.signature-at.table-title.SIGNATURE-PERSON-ID',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'user.displayName',
        label: 'table.signature-at.table-title.SIGNATURE-PERSON-NAME',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'createdAt',
        label: 'table.signature-at.table-title.SIGNATURE-AT',
        type: 'datepicker',
        required: true,
        readonly: true,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.createdAt ? nodeA.data.createdAt : null, nodeB && nodeB.data.createdAt ? nodeB.data.createdAt : null, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'signedOn',
        label: 'table.signature-at.table-title.SIGNATURE-ON',
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'url',
        label: 'table.signature-at.table-title.URL',
        type: 'textbox',
        required: true,
        readonly: true,
        minWidth: 300
    },
    {
        name: 'isDeleted',
        label: 'general.DELETED',
        type: "checkbox",
        readonly: false,
        isAdmin: true,
    }
]

export default {
    FieldDefinition
}