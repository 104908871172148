import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { pmCalendarStatus, pmCalendarStatusColors } from '../../../core/constants/pmCalendarStatus';
import { CommonService } from '../../../core/services/common.service';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';

@Component({
  selector: 'app-chip-renderer',
  template: `
    <span class="v-chip v-chip--label v-chip--pill v-chip--removable theme--light v-size--default yellow" [dir]="translationService.dir$ | async" *ngIf="value">
      <div class="dot" [ngStyle]="{'background-color': color}"></div>
      <span class="v-chip__content">
        {{ value }}
      </span>
    </span>
  `,
  styles: [`
    .dot{
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #29B466;
      display: inline-block;
      margin-right: 3px;
    }
    span[dir="rtl"] .dot{
      margin-right: 0px;
      margin-left: 3px;
    }
    .v-chip {
      align-items: center;
      cursor: default;
      display: inline-flex;
      max-width: 100%;
      outline: none;
      overflow: hidden;
      padding: 8px;
      position: relative;
      text-decoration: none;
      transition-duration: .28s;
      transition-timing-function: cubic-bezier(.4,0,.2,1);
      vertical-align: middle;
      white-space: nowrap;
      background: #F4F4F4;
      border-radius: 4px;
    }
    .theme--light.v-chip {
      border-color: #F4F4F4;
      color: #1D2B30;
    }
    .v-chip.v-size--default {
      font-size: 14px;
      height: 22px;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
    }
    .v-chip:before {
      background-color: currentColor;
      bottom: 0;
      border-radius: inherit;
      content: "";
      left: 0;
      opacity: 0;
      position: absolute;
      pointer-events: none;
      right: 0;
      top: 0;
    }
    :after, :before {
      text-decoration: inherit;
      vertical-align: inherit;
    }
    *, :after, :before {
      background-repeat: no-repeat;
      box-sizing: inherit;
    }
  `]
})

export class ChipRendererComponent implements ICellRendererAngularComp {
  params;
  value;
  color;

  constructor(
    public commonService: CommonService,
    private translate: TranslateService,
    public translationService: TranslationsStoreService,
  ) {
  }

  agInit(params): void {
    this.params = params;
    this.value = this.params.value;
    if(this.value){
      const records =  Object.keys(pmCalendarStatus).reduce((acc, key) => {
        acc[this.translate.instant(pmCalendarStatus[key])] = key;
        return acc;
      }, {});
      this.color = pmCalendarStatusColors[records[this.value]];
    }
  }

  refresh(params?: any): boolean {
    return false;
  }

  getValue(): any {
    return this.params.value;
  }
}