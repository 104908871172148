import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { INgxSelectOption } from "ngx-select-ex";

@Component({
  selector: "editor-cell",
  template: `
    <div
      #container
      class="search_select p-2 d-flex gap-1 align-items-center justify-content-center"
    >
      <ngx-select
        [allowClear]="true"
        [multiple]="true"
        autocomplete="off"
        [items]="values"
        [placeholder]="'maintenance.form-labels.SELECT-PLACEHOLDER' | translate"
        [(ngModel)]="value"
        (typed)="onSearch($event)"
        [disabled]="false"
      >
      </ngx-select>
      <button class="btn btn-primary save_btn" (click)="handleSelectAll($event)" *ngIf="values && values.length > 0">
        {{ "general.SELECT-ALL" | translate }}
      </button>
      <button class="btn btn-primary save_btn" (click)="handleDeSelectAll($event)" *ngIf="value && value.length > 0">
        {{ "general.DESELECT-ALL" | translate }}
      </button>
    </div>
  `,
  styles: [
    `
      .search_select,
      .search_select .ngx-select {
        min-width: 280px;
      }
      .save_btn {
        font-size: 14px;
        height: 35px;
      }
      @media (max-width: 1199px) {
        .search_select,
        .search_select .ngx-select {
          min-width: 212px;
        }
      }
      .search_select ngx-select {
        width: 100%;
      }
      .search_select .ngx-select__search {
        min-width: 280px;
      }
      @media (max-width: 1199px) {
        .search_select .ngx-select__search {
          min-width: 212px;
        }
      }
    `,
  ],
})
export class CustomMultipleSelectDropdownEditorComponent
  implements ICellEditorAngularComp, AfterViewInit
{
  private params: any;
  public values: any;
  public value: any;
  public model: any;
  public disable: any;
  public change: Function = null;
  @ViewChild("container", { read: ViewContainerRef }) public container;
  public happy: boolean = false;
  private search = "";

  ngAfterViewInit() {}

  agInit(params: any): void {
    this.params = params;
    if (
      params.values &&
      params.values.length > 0 &&
      typeof params.values[0].company != "undefined" &&
      params.data.company
    ) {
      this.values = params.values.filter(
        (val) => val.company && val.company._id == params.data.company._id
      );
    } else {
      this.values = params.values;
    }
    this.value =
      typeof params.data[params.colDef.field] != "undefined"
        ? params.data[params.colDef.field]
        : "";
    this.change = params.onChange || null;
  }

  getValue(): any {
    return this.value;
  }

  onSearch = (search: string) => {
    this.search = search;
  };

  handleSelectAll = (event: Event) => {
    event.preventDefault();
    const search = this.search ? this.search.toLowerCase() : "";
    const newValue = this.values.filter((val) => val.text ? val.text.toLowerCase().indexOf(search) > -1 : false).map((val) => val.id);
    this.value = this.value ? this.value.concat(newValue) : newValue;
  }

  handleDeSelectAll = (event: Event) => {
    event.preventDefault();
    this.value = [];
  }

  isPopup(): boolean {
    return true;
  }
}
