import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { List } from 'immutable';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AuthService } from '../../routes/user/auth.service';
import { Menu } from '../interfaces/menu';
import { MenuBackendService } from './backend/menu.backend.service';
import { CustomFieldsStoreService } from './custom-fields-store.service';

@Injectable({
  providedIn: 'root'
})
export class MenuStoreService {

  public menuFetched = new BehaviorSubject(false);
  protected menusSubject = new BehaviorSubject(List([]));
  public readonly menus$: Observable<List<any>> = this.menusSubject.pipe(
    map(menus => menus),
    shareReplay(1)
  );
  public columns$: Observable<any[]>;

  constructor(
    protected backend: MenuBackendService,
    protected customFields: CustomFieldsStoreService,
    protected auth: AuthService,
    private translate: TranslateService,
  ) {
    this.onInit()
  }

  onInit() {
    this.auth.user$.subscribe(user => {
      if (!user || !user._id) {
        return;
      }
      this.load().subscribe();
    });
  }

  load() {
    return this.backend.list().pipe(
      map(menus => menus.map(menu => new Menu(menu))),
      map(menus => menus.map(this.remapMenu)),
      tap((menus) => {
        this.menusSubject.next(List(menus));
        this.menuFetched.next(true);
      })
    );
  }

  remapMenu = (menu:Menu) => {
    menu.translationKey = this.translate.instant('MENUS.'+menu.key);
    return menu;
  }

  getMenu(key:string): Observable<Menu> {
    return this.menus$.pipe(
      map(menus => menus.find(menu => menu.key === key)),
      shareReplay(1)
    );
  }

  create(data) {
    return this.backend.create(data).pipe(
      tap(() => {
        this.load().subscribe();
      })
    );
  }

  update(data) {
    return this.backend.update(data);
  }

  delete(data: Menu) {
    return this.backend.delete(data).pipe(
      tap(() => {
        const menus = this.menusSubject.getValue();
        const idx = menus.findIndex((e: Menu) => e._id === data._id);
        this.menusSubject.next(menus.splice(idx, 1));
      }));
  }

  getList() {
    return this.menusSubject.getValue();
  }
}
