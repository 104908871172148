import {AfterViewInit, Component, ViewChild, ViewContainerRef} from "@angular/core";

import {ICellEditorAngularComp} from "ag-grid-angular";

@Component({
    selector: 'numeric-cell.ag-input-text-wrapper',
    template: `<input #input type="number" (keydown)="onKeyDown($event)" [(ngModel)]="value" class="ag-cell-edit-input">`
})
export class NumericEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public value: number;
    private cancelBeforeStart: boolean = false;

    @ViewChild('input', {read: ViewContainerRef}) public input;


    agInit(params: any): void {
        this.params = params;
        this.value = this.params.value;
    }

    getValue(): any {
        return this.value;
    }

    onKeyDown = ($event) => {
        if($event.keyCode === 69) return false;
    }

    ngAfterViewInit() {
        setTimeout(()=>
        {this.input.element.nativeElement.focus();}
        ,0);
    }
}