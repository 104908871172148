import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ICalendarEvent } from '@trademe/ng-add-to-calendar';
import { ToasterService } from 'angular2-toaster';
import { format } from 'date-fns';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '../auth.service';


@Component({
  selector: 'app-setup-company',
  templateUrl: './setup-company.component.html',
  styleUrls: ['./setup-company.component.scss']
})
export class SetupCompanyComponent implements OnInit {
  public calendarEvent: ICalendarEvent;

  constructor(private toaster: ToasterService,
    private translate: TranslateService,
    private auth: AuthService,
    private router: Router,
    private modalRef: BsModalRef) { }

  ngOnInit() {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);

    this.calendarEvent = {
      title: `Reminder of Anymaint end of Trial`,
      start: date,
      duration: 120,
      description: `Your trial license will expire on ${format(date, 'DD.MM.YYYY')}`,
    };
  }

  nextStep = () => {
    this.auth.sendVerification().subscribe(
      () => {
        this.auth.logout();
        this.modalRef.hide();
        this.router.navigate(['/registration-complete']);
      },
      () => {
        this.toaster.pop("error", this.translate.instant('toasters.GENERAL.ERR_ERROR_TRY_AGAIN'))
      }
    );
  }
}
