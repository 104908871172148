import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company } from '../../core/interfaces/company';
import { CompaniesStoreService } from '../../core/services/companies-store.service';
import { AuthService } from '../../routes/user/auth.service';

@Component({
  selector: 'app-company-avatar',
  templateUrl: './company-avatar.component.html',
  styleUrls: ['./company-avatar.component.scss']
})
export class CompanyAvatarComponent implements OnInit {

  src;
  company$: Observable<Company>;

  constructor(private companies: CompaniesStoreService, private auth: AuthService) { }

  ngOnInit() {
    this.company$ = combineLatest([this.companies.companies$, this.auth.user$]).pipe(
      map(([companies, user]) => {
        if (!companies || !user) return null;

        return companies.find(company => company._id === user.company._id)
      })
    )
  }

}
