<p>Last Updated May 2023</p>
<p>
  Welcome to AnyMaint, a cloud-based machine maintenance platform (the “<strong>Platform</strong>”) provided by AnyMaint Ltd. (“<strong>AnyMaint</strong>", the “<strong>Company</strong>”, “<strong>we</strong>”, “<strong>us</strong>” or “<strong>our</strong>”). We, at AnyMaint, helping to improve maintenance processes by collection, management, and analyzing maintenance data in order to increase efficiency and productivity in operations (together with the Platform, the “<strong>Service”</strong>).
</p>

<p>
  These terms of service are a legal agreement between you, either an individual or other legal entity (“<strong>You</strong>” or “<strong>Customer</strong>”) and AnyMaint and sets forth the legally binding terms for your use of the Service (the “<strong>Agreement</strong>”).
</p>

<p>
  This Agreement refers to Company and Customer each as a “<strong>Party</strong>” and collectively as the “<strong>Parties</strong>”.
</p>
<p>
  The Term “You” refers to the individual who registered and/or provided in our website at: <a href="https://app.anymaint.com/" target="_blank">https://app.anymaint.com/</a> his or her credit card or other payment mechanisms for the Service, if the Service is being purchased on behalf of an entity by an individual authorized to purchase the Service on behalf of such entity, then “You” refers to such entity.
</p>

<p>
  <strong>
    Note that if you are entering into this Agreement on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind such entity to this Agreement. In such case, this Agreement shall also refer and applies to such entity, its personnel, and its affiliates, as applicable.
  </strong>
</p>
<p>
  <strong>
    If you do not have such authority or if you do not agree with this Agreement, do not use the Service. You acknowledge that this Agreement is a binding contract between you and the Company, even though it is electronic and is not physically signed by you and the Company, and it governs your use of the Service. For the avoidance of doubt, you acknowledge that this Agreement is binding, and you affirm and signify your consent to this Agreement, by either: (i) clicking on a button or checking a checkbox for the acceptance of these terms; or (ii) registering to, installing, using or accessing the Service, our website or mobile application.
  </strong>
</p>
<p>
  In the event of any conflict between the terms and conditions of this Agreement and the terms and conditions of any previous written and/or oral correspondence between the Parties (including, but not limited to, the price quote and/or the Customer’s order form), the terms and conditions of this Agreement shall prevail.
</p>

<ol>
  <li>
    <h3>Definitions.</h3>
    <p>
      <span>In this Agreement, the following expressions have the following meanings:</span>
    </p>

    <ol>
      <li>
        “<strong>Confidential Information</strong>” means any information or data disclosed, directly or indirectly, by either Party, including, but not limited to, information regarding business strategies and practices, methodologies, trade secrets, know-how, pricing, technology, software, product plans, services, relationships with any third party, client lists and any additional information regarding the disclosing party’s employees, clients, vendors, consultants and Affiliates regardless of whether such information is marked or otherwise designated as “confidential” or some other proprietary designation, but which by its nature and/or and the circumstances surrounding disclosure is information that would reasonably be considered to be confidential information of the disclosing party. However, “Confidential Information” will not include any information which (a) is in the public domain through no fault of receiving party; (b) was properly known to receiving party, without restriction, prior to disclosure by the disclosing party; (c) was properly disclosed to receiving party, without restriction, by another person with the legal authority to do so; or (d) is independently developed by the receiving party without use of or reference to the disclosing party’s Confidential Information. All information or data concerning or related to the Company and/or the Service is and shall remain Confidential Information of the Company.
      </li>
      <li>
        “<strong>Intellectual Property</strong>” means all algorithms, application programming interfaces (APIs), apparatus, concepts, Confidential Information, data, databases and data collections, data analysis, topography and semiconductor mask works, deliverables, designs, diagrams, documentation, drawings, flow charts, formulae, ideas and inventions (whether or not patentable or reduced to practice), know-how, utility models, materials, marketing and development plans, marks (including brand names, product names, logos and slogans), methods, models, procedures, processes, discoveries, schematics, software code (in any form including source code and executable or object code), internet domains, and other intellectual property (such as, but not limited to, software, inventions, improvements), products, specifications, subroutines, techniques, tools, uniform resource identifiers, user interfaces, works of authorship, and other forms of technology.
      </li>

      <li>
        “<strong>Intellectual Property Rights</strong>” means any and all past, present, and future worldwide rights, titles and interests in and to any of the following, which may exist or be created under the laws of any jurisdiction and anywhere in the world, whether registered or unregistered: (a) rights associated with works of authorship, including exclusive exploitation rights, copyrights, moral rights, and mask work rights; (b) trademark and trade name rights and similar rights; (c) trade secret rights; (d) patent and industrial property rights; (e) other proprietary rights in Intellectual Property of every kind and nature; and (f) rights in or relating to registrations, renewals, extensions, combinations, divisions, and reissues of, and applications for, any of the rights referred to in clauses (a) through (e) of this definition.
      </li>
      <li>
        “<strong>Service</strong>” means the Service provided by the Company including the Platform itself.
      </li>
      <li>
        “<strong>AnyMaint Platform</strong>” or “<strong>Platform</strong>” means the AnyMaint proprietary software platform including any offline components, as applicable and any upgrades, updates, enhancements, developments, amendments, new versions thereof.
      </li>
    </ol>
  </li>

  <li>
    <h3>Right to use the Service.</h3>

    <ol>
      <li>
        Pursuant to this Agreement, AnyMaint hereby grants Customer, in consideration for the payment of fees, a time-limited, non-transferable, non-exclusive right to use the Service. The rights of usage shall be solely for Customer’s business purposes, all pursuant and subject to the terms of this Agreement. All rights not expressly granted to Customer are reserved by the Company.
      </li>
      <li>
        Except as expressly provided herein, Customer may not and shall not, or permit anyone to directly or indirectly, (i) translate, modify, distribute, republish, download, display, copy, or otherwise reproduce the Service in whole or in part and/or transmit the Service in any form or by any means; (ii) reverse engineer, decompile, disassemble, or otherwise attempt to discover or derive the source code, object code, or underlying structure, ideas or algorithms of any part of the Service or any documentation or data related to or provided with the Service; (iii) permit any third party to gain unauthorized access to the Service; (iv) create derivative works based upon any or all of the Service or any of its components and/or outputs; or (v) interfere in any manner with the hosting of the Service.
      </li>
      <li>
        Customer also may not, unless explicitly provided herein, (i) assign, license, sublicense, pledge, lease, rent, transfer, publish, distribute, host, sell, market, or otherwise commercially exploit and/or share its rights under this Agreement; (ii) make the Service or materials resulting from the Service available in any manner to any third party for use in the third party’s business operations; (iii) use the Service and/or its outputs unlawfully or in any manner not expressly authorized by this Agreement; (iv) access or use the Service in order to build or support, and/or assist a third party in building or supporting, products or services similar and/or competitive to AnyMaint's products and services; (v) use the Service for timesharing or service bureau purposes or otherwise for the benefit of a third party. Customer shall not knowingly or willfully use the Service in any manner that could damage, disable, overburden, impair or otherwise interfere with AnyMaint's provision of the Service.
      </li>
      <li>
        The rights granted under this Agreement are limited, among others, to the Term. Customer agrees to make every reasonable effort to prevent unauthorized third parties from accessing the Service. Any future release, update, or other addition to functionality of the Service shall be subject to the terms of this Agreement.
      </li>
      <li>
        Customer will not delete or in any manner alter the copyright, trademark, or other proprietary rights notices of AnyMaint, if any, appearing on or in the Service, screen outputs and prints generated with the Service unless otherwise expressly agreed beforehand in writing by AnyMaint.
      </li>

      <li>
        The Company shall not be responsible for any degradation, downtime, or inoperability of the Service to the extent such is caused due to any of the following (the "<strong>Exceptions</strong>"): (i) Customer's misuse of the Service or in violation of the terms of the Agreement; (ii) failures of Customer’s internet or network connectivity; (iii) failure of the Service due to failure attributed to purchased cloud base hosting services; (vi) failure of the Service due to unauthorized changes or modification or additions made by Customer or in its behalf in the existing interfaces with the Service.
      </li>

      <li>
        The Customer may request the Company to provide and/or create modifications, customized features, add-ons or any related developments to the Platform and/or the Service (the “<strong>Developments</strong>”) for an additional payment or free of charge, as agreed between the Parties and subject to the Company’s sole discretion. For the avoidance of doubt, it should be clear that the Company shall own and retain all right, title and interest in and to the Developments and all improvements, enhancements or modifications thereto and may use or incorporate any such Developments into the Service and/or any of its current or future products or services in any manner it deems appropriate, including modifications to the Platform and/or the Service (without the Customer’s approval and without further consideration).
      </li>
      <li>
        During the Term the Company will make available to Customer as part of the Service, all updates that are made generally available to the Company's customers at no additional cost. However, some features may be licensed for an additional fee, as shall be agreed between the Parties. Subject to payment of the applicable fees, support will be provided to the Customer by the Company (the “<strong>Support Services</strong>”) in accordance with the service levels detailed in the Company’s standard Service Level Agreement (“<a href="/sla" target="_blank">SLA</a>”) attached hereto as Annex A to this Agreement.
      </li>
      <li>
        The Company reserves the right, at any time, to modify, suspend, or discontinue the Service or any feature,  functionality or any other tool, within the Service, with or without notice. You agree that we will not be liable to you or to any third party for any modification, suspension, or discontinuance of the Service or any part thereof. In such an event the Company will provide the Customer the Service until the lapse of any pre-paid service period.
      </li>
      <li>
        The Service provided under this Agreement are subject to reasonable use and any use limitations specified herein.
      </li>
      <li>
        The Service may use or include certain open-source components that are subject to open-source licenses (“<strong>Open Source Software</strong>”). Each item of Open-Source Software is licensed under its applicable license terms which accompany such Open Source Software. Nothing in this Agreement limits your rights under, nor grants you rights that supersede, the terms and conditions of any applicable license terms for the Open-Source Software. A list of the Open-Source Software is provided in the Service. The Company may, at any time and at our sole discretion, suspend, disable access to or remove from the Service, any Open Source Software – without any liability to you or to any other Users (as defined below).
      </li>
    </ol>
  </li>
  <li>
    <h3>Customer Responsibilities.</h3>
    <ol>
      <li>
        As part of the registration process, Customer will identify an administrative username and password for Customer’s account (the “<strong>Account</strong>”). Customer may use the administrative username and password to create standard users (each with a user password) of the Service (the “<strong>Users</strong>”). Customer shall be responsible for the acts or omissions of the Users or any person who accesses the Service using passwords or access procedures provided to or created by Customer. Customer shall be responsible for maintaining the confidentiality of the username and password for the Account.
      </li>
      <li>
        Customer shall abide by all applicable local, national and foreign laws, treaties and regulations in connection with Customer’s use of the Service, including those related to data privacy, international communications and the transmission of technical or personal data. Customer shall, without undue delay: (i) notify Company of any unauthorized use of the username or password for the Account or any other known or suspected breach of security; (ii) report to Company and use reasonable efforts to stop immediately any copying or distribution of that comes to Customer’s attention.
      </li>
    </ol>
  </li>

  <li>
    <h3>Account Information; Data Protection</h3>
    <ol>
      <li>
        Customer Data is any data, information (including information regarding equipment), file attachments, text, images, drawings, reports, personal information, or any other content, that is uploaded or submitted, transmitted or otherwise made available, to or through the Service by you or any User and is processed by us on your behalf (the “<strong>Customer Data</strong>”).
      </li>
      <li>
        Customer shall (i) have sole right, title and interest in Customer Data; (ii) have the responsibility for the accuracy, quality, integrity, legality, reliability, and appropriateness of the Customer Data and its use; (iii) obtain any licenses, permissions or authorizations required for any use of the Customer Data; Customer represents and warrants that Customer’s provision or the use of Customer Data shall not violate the rights of any third party, or any applicable law, rule, or regulation.
      </li>
      <li>
        Notwithstanding the aforementioned, Customer hereby grants the Company a worldwide,  royalty-free, limited license to use, process copy, distribute, perform, export, and display the Customer Data or any part thereof solely for Company’s legitimate internal business purposes, including (i) maintaining and providing the Service to Customer; (ii) preventing or handling technical or security issues; (iii) service or product enhancement or improvement, including research, testing, development, controls, and operations of the Service for the benefit of all customers; (iv)  to investigate a complaint alleging, that  such Customer Data is in violation of this Agreement; and (v) to comply with a valid legal subpoena, request,  or other lawful process. (the “<strong>Data Usage</strong>”). In no circumstances the identity of any customer or material and equipment information related to such customer will be revealed to others within Data Usage. At any time, Customer has the option to opt-out of sharing data.
      </li>
      <li>
        Further, the Company shall be entitled to use any non-identifiable Customer Data for its legitimate business purposes.
      </li>
      <li>
        To the extent that the Company processes Customer Personal Data, it shall do so only in accordance with the Data Processing Addendum, attached hereto as Annex B (the “<strong>DPA</strong>”), which is incorporated by reference into this Agreement. As used herein, “<strong>Customer Personal Data</strong>” shall be as defined in the DPA.
      </li>
      <li>
        The Company will maintain, throughout the Term appropriate technical and organizational safeguards in order to support the security, confidentiality and integrity of Customer Data and other proprietary information. The Company shall only use Customer Personal Data as permitted under this Agreement and in accordance with the DPA.
      </li>
    </ol>
  </li>
  <li>
    <h3>Intellectual Property Ownership.</h3>

    <ol>
      <li>
        Company alone (and its licensors, where applicable) shall exclusively own all right, title and interest, including all related Intellectual Property Rights, in and to Platform, Company technology, software, Service, and the Usage Data. This Agreement shall not constitute and is not a sale and does not convey to Customer (or any third party) any rights of ownership in or related to the Platform, the Service, the Company technology, or the Intellectual Property Rights owned by Company other than the right to access and use the Service pursuant to the terms of this Agreement. The Company name, the Company logo, and the product names associated with the Service are trademarks of Company or third parties, and no right is granted to use them.
      </li>
      <li>
        The Customer exclusively owns all right, title, and interest in and to Customer’s Personal Information and the Customer Data, including all Intellectual Property Rights therein, irrespective of whether such Customer Data is stored or processed through or in the Service.
      </li>
    </ol>
  </li>
  <li>
    <h3>Billing.</h3>

    <ol>
      <li>
        When Customer elects to purchase Service package, it may elect to provide a credit card or other payment mechanism (such as wire transfer or bank transfer). The Customer agrees that the Company shall charge its credit card or other payment mechanisms all amounts due and owing for the Service, including service fees, subscription fees or any other fee or charge associated with Customer’s use of the Service. Any annual, monthly or similar periodic fees will be billed automatically to the credit card or other payment mechanisms designated during the registration process for the Service, at the start of each renewal period, unless the Customer terminates its subscription before the relevant period begins. It should be clarified that the payment terms are subject to the price quote or Customer’s order form approved by the Company in writing.
      </li>
      <li>
        Prices of the Service are subject to change at any time. The Company will strive wherever possible to give advance notice. Such notice may be provided at any time by posting the changes on the Company’s website, direct e-mail sent to the Customer or in the Service itself.
      </li>
      <li>
        All fees are final. There is no refunding of fees, regardless of whether the Customer uses the Service or not.
      </li>
      <li>
        All fees for the Service are net and exclusive of any taxes, levies, duties or similar governmental assessments of any nature, including, for example, value-added, sales, use or withholding taxes, assessable by any jurisdiction whatsoever (collectively, “<strong>Taxes</strong>”). The Customer is responsible for paying all Taxes associated with purchasing the Service. AnyMaint are solely responsible for taxes assessable against its income, property and employees.
      </li>
      <li>
        If any fees are not received by AnyMaint by the due date, then without limiting AnyMaint’s rights or remedies, those charges may accrue late interest at the rate of the higher of: (i) 1.5% of the outstanding balance per month, and (ii) the maximum rate permitted by law.
      </li>
      <li>
        All fees charged under this Agreement are exclusive to the Customer and the Customer shall not disclose any information relating to them to any third party without the written consent of AnyMaint.
      </li>
    </ol>
  </li>
  <li>
    <h3>Term; Termination.</h3>
    <ol>
      <li>
        This Agreement and the license and rights granted to Customer under this Agreement shall be in full force and effect, subject to payment by Customer of the applicable fees, from the date first usage and shall remain in effect unless the Customer desire not to renew this Agreement.
      </li>
      <li>
        Either Party may terminate this Agreement by giving written notice to the other Party: (i) if the other Party is in breach of this Agreement provided that the alleging breaching Party fails or refuses to remedy such breach within thirty (30) days following notice of such breach from the non-breaching Party, or (ii) if the other Party becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, receivership, liquidation or assignment for the benefit of creditors (iii) for any reason, at its discretion without cause, upon ninety (90) days prior written notice to the other Party.
      </li>
      <li>
        Without derogating from the above, AnyMaint, at its sole discretion, may (i) suspend your rights to use the Platform and/or Service (including your Account) or (ii) immediately terminate this Agreement when detects any use of the Platform or Service in violation of this Agreement. For the avoidance of doubt, it should be noted that the Company’s entitlement to fees will remain in full force in such cases.
      </li>
      <li>
        If this Agreement is terminated for any reason in accordance to 7.2 and 7.3 (i) above, neither Party will be relieved of any obligation that accrued prior to the effective date of the termination, and Customer will pay Company for the value of all Service performed up to and including the effective date of any termination of this Agreement and Company will continue to provide to Costumer all Service up to and including the effective date of any termination of this Agreement.
      </li>
      <li>
        Upon termination of this Agreement, your right to access and use the Service will terminate immediately, provided, however that if relevant - Company shall allow orderly extraction by Customer of all Customer Data during a period of up to 90 days against payment of the fees due for the Service for such requested applicable period in accordance with the then-applicable pricing.
      </li>
    </ol>
  </li>
  <li>
    <h3>Indemnification.</h3>

    <ol>
      <li>
        You will indemnify and hold us (and our officers, employees, and agents) harmless, including costs and attorneys’ fees, from any claim, demand, suit, or proceeding (the “<strong>Claim</strong>”) made by any third party due to or arising out of (i) your use of the Platform or Service, (ii) your violation of this Agreement or any breach of the Restrictions; (iii) your violation of applicable laws or regulations (including any privacy laws); (iv) the Customer Data. We reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these Claims. You agree not to settle any matter without our prior written consent. We will use reasonable efforts to notify you of any such Claim, action or proceeding upon becoming aware of it. The Customer will indemnify the Company for any damages finally awarded against (or any approved settlement) the Company in connection with any such Claim.
      </li>
      <li>
        Customer’s indemnification obligations are conditioned upon the Company: (i) promptly notifying the Customer of any claim in writing; and (ii) cooperating with the Customer in the defense of any claim. The Company shall have the right to participate in the defense of any third-party claim with counsel selected by it subject to the Customer’s right to control the defense thereof. Notwithstanding any other provision of this Agreement, the Customer shall not enter into settlement of any third-party claim without the prior written consent of the Company, which shall not be unreasonably withheld.
      </li>
    </ol>
  </li>
  <li>
    <h3>Service Warranty.</h3>
    <ol>
      <li>
        The Company warrants that the Service will perform in all material respects in accordance with the terms of this Agreement. Company shall use reasonable efforts to maintain the Service in a manner which minimizes errors and interruptions in the Service (the “<strong>Service Warranty</strong>”). In the event of a breach of the Service Warranty, the Company will use commercially reasonable efforts to correct any non-conformity. In the event the Company determines corrections to be impracticable, the Company or Customer may terminate this Agreement. In the event this Agreement is terminated as provided herein, the Company will refund to Customer any pre-paid fees for use of the Service for the termination portion of the applicable Term. The Company’s sole liability and Customer’s sole exclusive remedy, for any breach of the Service Warranty are set forth in this section ‎9.1.
      </li>
      <li>
        The Service Warranty will not apply: (i) unless Customer makes a claim in writing, according to section ‎12.13 herein, within thirty (30) days of the date on which Customer noticed the non-conformity, (ii) if the non-conformity is caused by misuse, unauthorized modifications or other third-party products, services, software or equipment; (iii) any modifications were made to the Service by the Customer or any third party, without the Company’s prior consent.
      </li>

      <li>
        The Company does not warrant that the Service will be available on an uninterrupted, timely, secure, free of viruses or other harmful code, complete, accurate or error-free or meet Customer’s requirements; nor does it make any warranty as to the results that may be obtained from the use of the Service. The platform and Service are provided “as is” and “as available” and the Company expressly disclaims all warranties and conditions of any kind, whether express or implied, including, but not limited to, implied warranties or conditions of merchantability and fitness for a particular purpose, title, quiet enjoyment, quality and accuracy or non-infringement. The Company does not warrant that any information provided through the Service is accurate or complete.
      </li>

      <li>
        Customer acknowledges that an interruption in the Service due to circumstances beyond the reasonable control of the Company shall not be considered a Service outage or service deficiency for purposes of any remedy provided herein. If applicable law limits the application of the provisions of this section, the Company’s liability will be limited to the maximum extent permissible.
      </li>
    </ol>
  </li>
  <li>
    <h3>Limitation of Liability.</h3>

    <ol>
      <li>
        IN NO EVENT SHALL ANYMAINT (AND ITS SUPPLIERS) BE LIABLE UNDER THIS AGREEMENT TO YOU OR ANY THIRD PARTY FOR (i) INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THIS AGREEMENT OR YOUR USE OF, OR INABILITY TO USE, THE PLATFORM OR SERVICE EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR (ii) FOR LOSS OF USE, BUSINESS, REVENUES, OR PROFITS; IN EACH CASE, EVEN IF ANYMAINT KNEW OR SHOULD HAVE KNOWN THAT SUCH DAMAGES WERE POSSIBLE AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
      </li>
      <li>
        ACCESS TO, AND USE OF, THE PLATFORM AND SERVICE ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA RESULTING THEREFROM.
      </li>
      <li>
        NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR AGGREGATE LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT, THE PLATFORM, OR SERVICE (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), SHALL, AT ALL TIMES, NOT EXCEED THE AMOUNT PAID BY CUSTOMER DURING THE SIX (6) MONTHS PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY. THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THIS AGREEMENT, THE PLATFORM, OR SERVICE.
      </li>
      <li>
        THE PROVISIONS OF THIS SECTION 10 SHALL SURVIVE THE TERMINATION OF THIS AGREEMENT, AND, FOR THE AVOIDANCE OF DOUBT APPLY ALSO TO THE DPA AND ANY LIABILITY ARISING THEREFROM.
      </li>
    </ol>
  </li>
  <li>
    <h3>Confidentiality.</h3>

    <ol>
      <li>
        Each Party agrees that it will use the Confidential Information of the other Party solely in accordance with the provisions of this Agreement and it will not disclose, or permit to be disclosed, the same directly or indirectly, to any third party without the other Party’s prior written consent, except as otherwise permitted hereunder. However, either Party may disclose Confidential Information: (i) to its employees, officers, directors, attorneys or auditors who have a need to know and are legally bound to keep such information confidential by confidentiality obligations consistent with those of this Agreement; and (ii) as required by law (in which case the receiving party will provide the disclosing party with prior written notification thereof (to the extent legally permitted), will provide the disclosing party with the opportunity to contest such disclosure, and will use its reasonable efforts to minimize such disclosure to the extent permitted by applicable law. Each Party agrees to exercise due care in protecting the Confidential Information from unauthorized use and disclosure. In the event of actual or threatened breach of the provisions of this section the non-breaching Party will be entitled to seek immediate injunctive and other equitable relief, without waiving any other rights or remedies available to it. Each Party will promptly notify the other in writing if it becomes aware of any violations of the confidentiality obligations set forth in this Agreement.
      </li>
      <li>
        The parties agree that the terms and provisions of this Agreement shall be kept confidential and shall be disclosed only to those persons and entities as required by law or as permitted by the other party hereto.
      </li>
    </ol>
  </li>
  <li>
    <h3>Miscellaneous.</h3>
    <ol>
      <li>
        This Agreement is made under and shall be governed by and construed in accordance the laws of the State of Israel, without application of its principles of conflicts of law. The Parties irrevocably consent to the exclusive jurisdiction of the competent courts in Tel Aviv, Israel, to adjudicate all disputes arising from or related to this Agreement to the exclusion of the jurisdiction of any other court; however, the Company shall retain the right to institute proceedings, in any other territory.
      </li>
      <li>
        The Company may identify Customer on its website(s) and other marketing materials as a user of the Service.
      </li>
      <li>
        This Agreement is subject to occasional revision, and if we make any substantial changes, we may notify you by sending you an e-mail to the last e-mail address you provided to us (if any) and/or by prominently posting notice of the changes on the Platform. Any changes to this Agreement will be effective upon the earlier of thirty (30) calendar days following our dispatch of an e-mail notice to you (if applicable) or thirty (30) calendar days following our posting of notice of the changes on our Platform. These changes will be effective immediately for new users of our Service. You are responsible for providing us with your most current e-mail address. In the event that the last e-mail address that you have provided us is not valid, or for any reason is not capable of delivering to you the notice described above, our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice. Continued use of our Service following notice of such changes shall indicate your acknowledgment of such changes and agreement to be bound by the terms and conditions of such changes.
      </li>
      <li>
        Neither Party shall transfer, delegate, subcontract, assign or pledge in any manner whatsoever any of its rights or obligations under this Agreement without the prior written consent of the other Party, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. Without derogating from the above, a Party may assign this Agreement in connection with a merger, acquisition, sale of all or substantially all of its relevant assets or other such change of control or corporate reorganization. The terms of this Agreement shall be binding upon assignees.
      </li>
      <li>
        If any term, provision, covenant or restriction of this Agreement is held by a court of competent jurisdiction or other authority to be invalid, void, unenforceable or against regulatory or public policy, the remainder of the terms, provisions, covenants and restrictions of this Agreement shall in no way be affected, impaired or invalidated.
      </li>
      <li>
        The section titles in this Agreement are for convenience only and have no legal or contractual effect. The word including means including without limitation.
      </li>
      <li>
        AnyMaint will not be liable for any delay or non-performance of its obligations hereunder if such delay is caused by factors beyond its control, including without limitation acts of God, war, riot, fire, explosion, flood, earthquake or technical failure beyond the Company’s reasonable control (the “<strong>Force Majeure</strong>”). Subject to the Company promptly notifying the Customer in writing of the reasons for the delay (and the likely duration of the delay), the performance of such Company's obligations shall be suspended during the period of Force Majeure and the Company shall be granted an extension of time for performance equal to the period of the delay. Either Party may, if such delay continues for more than sixty (60) consecutive days terminate this Agreement forthwith on giving notice in writing to the other in which event neither Party shall be liable to the other by reason of such termination.
      </li>
      <li>
        The terms and provisions herein contained constitute the entire Agreement between the Parties with respect to the subject matters hereof and shall supersede all previous communications, oral or written, between the Parties hereto with respect to the subject matters hereof. No modification, amendment or correction to this Agreement shall be binding upon either of the Parties hereto unless in writing and signed by the duly authorized representatives of both Parties.
      </li>
      <li>
        Your relationship with us is that of an independent contractor, and neither party is an agent or partner of the other.
      </li>
      <li>
        No waiver of any rights by any Party hereto shall be construed as a waiver of the same or any other right at any prior or subsequent time. Furthermore, no waiver or delay on the part of a Party in exercising any power or right hereunder, shall in any way restrict or diminish the full rights and powers of that Party under this Agreement, or operate as a waiver of any breach by a Party of any of the terms or conditions of this Agreement.
      </li>
      <li>
        Customer consents to receive communications from the Company by email in accordance with this Agreement and applicable law. Customer acknowledges and agrees that all agreements, notices, disclosures and other communications that the Company provides to Customer electronically will satisfy any legal requirement that such communications be in writing.
      </li>
      <li>
        All notices permitted or required under this Agreement, unless specified otherwise in this Agreement, must be sent in writing as follows in order to be valid: (i) if to Customer, via email to the address associated with Customer's Account, and (ii) if to the Company, <a href="mailto:info@anymaint.com">info@anymaint.com</a>. Notices will be deemed given (a) if to Customer, when emailed, and (b) if to the Company, on receipt by the Company.
      </li>
      <li>
        To get in touch with our Customer Service, please Send an email message to: <a href="mailto:info@anymaint.com">info@anymaint.com</a>
      </li>
    </ol>
  </li>
</ol>