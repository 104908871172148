<div class="notification-bar">
  <ng-container
    *ngIf="payments.subscriptionPayments$ | async as subscriptionPayments"
  >
    <ng-container *ngIf="show$ | async">
      <ng-container
        *ngIf="subscriptionPayments.count() > 0"
      >
        <div class="notification">
          <div class="notifrication-message bg-danger">
            <i class="fas fa-exclamation-triangle mr-2">
              Company account is pending activation! Please contact
              <a [href]="customerServiceContact">customer service</a>
            </i>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isDevConnected">
    <div class="notification">
      <div class="notifrication-message bg-danger">
        <i class="fas fa-exclamation-triangle mr-2">
          You are connected to development db.
        </i>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isTestConnected">
    <div class="notification">
      <div class="notifrication-message bg-danger">
        <i class="fas fa-exclamation-triangle mr-2">
          You are connected to test db.
        </i>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #activateTrials>
  <div class="modal-backdrop fade show"></div>
  <div
    class="modal fade show"
    style="display: block;"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="w-100 text-center">Activate your 30 days trial</h2>
        </div>
        <div class="modal-body text-center">
          <p class="mb-3">
            In order to start your 30 days trial, please enter the payment
            method
          </p>
          <p class="mb-4">(we will not charge you until your trial ends)</p>
          <button class="btn btn-primary mx-1" (click)="addLicense()">
            Activate your 30 days free trial
          </button>
          <!-- <button class="btn btn-secondary mx-1" (click)="auth.logout()">
            Logout
          </button> -->
          <button class="btn btn-secondary mx-1" (click)="cancel($event)">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<router-outlet></router-outlet>

<!--
<ng-container>
  <div class="notification" *ngIf="show$ | async">
    <ng-container ngIf="subscriptionPayments.count() > 0">
      <div class="notifrication-message bg-danger">
        <i class="fas fa-exclamation-triangle mr-2">
          Company account is pending activation! Please contact <a [href]="customerServiceContact">customer service</a>
        </i>
      </div>
    </ng-container>
  </div>
</ng-container> -->
