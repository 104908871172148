import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { isArray } from 'lodash';
import { GeneralService } from '../../../core/services/general.service';

@Component({
  selector: 'app-custom-click-renderer',
  template: `
    <span *ngIf="isLog && !link && visible">{{ label }}</span>
    <a [href]="link" rel="noopener" *ngIf="isLog && link && visible" (click)="handleClick($event)">{{ label }}</a>
    <a href="#" (click)="onClick($event)" *ngIf="!isLog && visible">{{ label }}</a>
    `
})

export class CustomClickRendererComponent implements ICellRendererAngularComp {

  params;
  label: string;
  visible: boolean;
  isLog: boolean = false;
  link: string;
  id: string = null;
  isCustomLink: boolean = false;

  constructor(
    private generalService: GeneralService,
  ) { }

  agInit(params): void {
    this.params = params;
    this.isLog = this.params.isLog ? this.params.isLog : false;
    this.isCustomLink = this.params.isCustomLink ? this.params.isCustomLink : false;
    if(this.params.field == 'checklistItems'){
      this.label = this.params.data && this.params.data.checklistItems && this.params.data.checklistItems.length ? this.params.data.checklistItems.map(task => task.name).join("; ") + ';' : ""
    }else if(this.params.field == 'tasks'){
      this.label = this.params?.data?.tasks?.join("; ") + ';'
    }else if(this.params.field.includes('.')){
      const data = { ...params.data }
      const props = this.params.field.split('.');
      this.label = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', data);
    }else if(this.params.data[this.params.field] && isArray(this.params.data[this.params.field]) && this.params.field === 'file'){
      this.label = this.params.data[this.params.field][0] ? this.params.data[this.params.field][0].name : '';
    }else{
      this.label = this.params.data[this.params.field] || null;
    }
    this.visible = this.params.visible ? this.params.visible(this.params.node.data) : true
    if(this.isLog){
      if(this.isCustomLink && this.params.data.file){
        this.link = '#';
        this.id = params.data.file._id;
        this.label = this.params.data.file.name;
      } else if(this.params.data.oldFile) {
        this.link = '#';
        this.label = this.params.data.oldFile.name;
        this.id = params.data.oldFile._id;
      } else {
        const value = params.data[this.params.field];
        this.label = value ? JSON.stringify(value) : null;
      }
    }
  }

  refresh(params?: any): boolean {
    return true;
  }

  handleClick($event: Event) {
    $event.stopPropagation();
    $event.preventDefault();
    if(this.id) this.generalService.viewFile(this.id);
  }

  onClick($event) {
    $event.preventDefault();
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
      }
      this.params.onClick(params);

    }
  }
}