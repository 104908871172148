export const newFieldDropDown = {
    'textbox': 'new-fields.TEXTBOX',
    'hyperlink': 'new-fields.HYPERLINK',
    'smart-dropdown': 'new-fields.SMART-DROPDOWN',
    'textarea': 'new-fields.LARGE-TEXT-FIELD',
    'numeric': 'new-fields.NUMBER',
    'checkbox': 'new-fields.CHECKBOX',
    'dateonlypicker': 'new-fields.DATE-ONLY-PICKER',
    'datepicker': 'new-fields.DATE-TIME-PICKER',
    'timeonlypicker': 'new-fields.TIME-ONLY-PICKER',
    'timestamp': 'new-fields.TIMESTAMP',
    'file': 'new-fields.FILE',
    'silent-signature': 'new-fields.AUTO-USER-NAME',
};