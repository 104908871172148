import { IField } from "../../interfaces/field";
const section = "status";
const FieldDefinition: IField[] = [
  {
    name: "id_num",
    label: "table.tools.table-title.ID",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "code",
    label: "table.tools.table-title.CODE",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "name",
    label: "table.tools.table-title.TOOL-NAME",
    type: "textbox",
    required: true,
    readonly: false,
    isAdmin: true,
    section,
  },
  {
    name: "group.name",
    label: "table.tools.table-title.GROUP",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "isGroup",
    label: "table.tools.table-title.TYPE",
    type: "textbox",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "toolStatus",
    label: "table.tools.table-title.STATUS",
    type: "toolStatusType",
    required: false,
    readonly: true,
    section,
  },
  {
    name: "image",
    label: "table.tools.table-title.IMAGE",
    type: "textbox",
    required: false,
    readonly: true,
    minWidth: 150,
    section,
  },
  {
    name: "action",
    label: "table.tools.table-title.ACTION",
    type: "toolStatus",
    required: false,
    readonly: false,
    isAdmin: true,
    section,
  },
  {
    name: "isDeleted",
    label: "general.DELETED",
    type: "checkbox",
    required: false,
    readonly: false,
    isAdmin: true,
    section,
  },
];

export default {
  FieldDefinition,
};
