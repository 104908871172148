import { Component, OnInit } from '@angular/core';
import { UsersStoreService } from '../../../core/services/users-store.service';

@Component({
  selector: 'app-request-access-message',
  templateUrl: './request-access-message.component.pug',
  styleUrls: ['./request-access-message.component.scss']
})
export class RequestAccessMessageComponent implements OnInit {

  public name;
  public email;
  public phone;
  constructor(public service: UsersStoreService) { }

  ngOnInit() {
    this.service.admins$.subscribe(admin=>{
      if (!admin) return;
      const names = [admin.firstName, admin.lastName].filter(name=>!!name)
      this.name = names.join(' ');
      this.email = admin.email;
      this.phone = admin.phone;
    })
  }

}
