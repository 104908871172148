import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-level-agreement',
  templateUrl: './service-level-agreement.component.html',
  styleUrls: ['./service-level-agreement.component.scss']
})
export class ServiceLevelAgreementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
