import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
// import { TranslateService } from '@ngx-translate/core';
// import { ToasterService } from 'angular2-toaster';
// import { IPayPalConfig } from 'ngx-paypal';
// import { combineLatest, Observable } from 'rxjs';
// import { map, startWith } from 'rxjs/operators';
// import { environment } from '../../../../../environments/environment';
// import SUBSCRIPTION_PERIODS from '../../../../core/constants/subscription-periods';
// import SUBSCRIPTION_PLANS from '../../../../core/constants/subscription-plans';
// import { Company } from '../../../../core/interfaces/company';
// import { SubscriptionPayment } from '../../../../core/interfaces/subscription-payment';
// import { SubscriptionPaymentsStoreService } from '../../../../core/services/subscription-payments-store.service';
// import { LoaderService } from '../../../../core/utils/loader.service';

@Component({
  selector: 'app-subscription-payment-form',
  templateUrl: './subscription-payment-form.component.html',
  styleUrls: ['./subscription-payment-form.component.scss']
})
export class SubscriptionPaymentFormComponent implements OnInit {

  // @Output() onComplete = new EventEmitter<any>();
  // @Input() subscriptionPayment: SubscriptionPayment;
  // company: Company;
  // public plans = SUBSCRIPTION_PLANS;
  // public periods = SUBSCRIPTION_PERIODS;
  // public regularUsersPrice: Observable<string>;
  // public failureReportUsersAmount: Observable<string>;


  // dateOptions = {
  //   format: 'DD.MM.YYYY',
  //   sideBySide: true,
  //   allowInputToggle: true,
  // };

  // form: UntypedFormGroup;
  // payPalConfig: IPayPalConfig;
  // constructor(private fb: UntypedFormBuilder,
  //   private toaster: ToasterService,
  //   private translate: TranslateService,
  //   private loader: LoaderService,
  //   public service: SubscriptionPaymentsStoreService) { }

  ngOnInit() {
  //   this.loader.add();

  //   this.payPalConfig = {
  //     currency: 'USD',
  //     clientId: environment.paypal.clientId,
  //     createSubscription: (data, actions) => {

  //       const paymentPeriod = this.form.value.period === "12" ? "yearly" : "monthly";

  //       if (this.subscriptionPayment && !!this.subscriptionPayment._id) {

  //         return actions.subscription.create({
  //           plan_id: environment.paypal.plans[paymentPeriod].withoutTrial,
  //           //@ts-ignore
  //           start_time: new Date(this.subscriptionPayment.nextPaymentDate).toISOString(),
  //           plan: {
  //             "billing_cycles": [
  //               {
  //                 "sequence": 1,
  //                 "tenure_type": "REGULAR",
  //                 "total_cycles": 0,
  //                 "pricing_scheme": {
  //                   "fixed_price": {
  //                     "value": (this.form.value.amount * ((100 + environment.paypal.vat) / 100)).toFixed(2),
  //                     "currency_code": "USD"
  //                   }
  //                 },
  //               }
  //             ],
  //           }
  //         });

  //       } else {

  //         return actions.subscription.create({
  //           plan_id: environment.paypal.plans[paymentPeriod].withTrial,
  //           //@ts-ignore
  //           plan: {
  //             "billing_cycles": [
  //               {
  //                 "sequence": 2,
  //                 "total_cycles": 0,
  //                 "pricing_scheme": {
  //                   "fixed_price": {
  //                     "value": (this.form.value.amount * ((100 + environment.paypal.vat) / 100)).toFixed(2),
  //                     "currency_code": "USD"
  //                   }
  //                 },
  //               }
  //             ],
  //           }
  //         });
  //       }
  //     },

  //     advanced: {
  //       commit: 'true',
  //     },

  //     vault: 'true',
  //     style: {
  //       label: 'paypal',
  //       layout: 'vertical'
  //     },

  //     onInit: (data, actions) => {
  //       actions.disable();
  //       this.loader.remove()

  //       this.form.valueChanges.pipe(startWith(null)).subscribe(() => {
  //         if (this.form.valid) {
  //           actions.enable();
  //         } else {
  //           actions.disable();
  //         }
  //       })
  //     },

  //     onApprove: (data, actions) => {
  //       if (this.subscriptionPayment._id) {
  //         this.onUpdate(data, actions);
  //       } else {
  //         this.onAdd(data, actions);
  //       }

  //     },

  //     onCancel: (data, actions) => {
  //       this.toaster.pop("warning", this.translate.instant('toasters.SUBSCRIPTIONS.WARN_PAYMENT_CANCELLED'));
  //     },
  //     onError: err => {
  //       this.toaster.pop("error", this.translate.instant('toasters.SUBSCRIPTIONS.ERR_PAYMENT_ERROR'))
  //     },
    };



  //   if (!this.subscriptionPayment) {
  //     this.subscriptionPayment = new SubscriptionPayment({
  //       company: this.company,
  //       plan: 'custom',
  //       period: '12',
  //       regularUsers: 1,
  //       failureReportUsers: 0,
  //       startDate: new Date(),
  //       paymentDate: new Date()
  //     });
  //   }

  //   this.form = this.fb.group({
  //     plan: this.fb.control('', Validators.required),
  //     period: this.fb.control('', Validators.required),
  //     startDate: this.fb.control(null, Validators.required),
  //     paymentDate: this.fb.control(null, [Validators.required]),
  //     regularUsers: this.fb.control(1, [Validators.required, Validators.min(1)]),
  //     failureReportUsers: this.fb.control(0, [Validators.min(0)]),
  //     amount: this.fb.control(0, [Validators.min(0.1), Validators.required, Validators.pattern(/^\d+\.?\d{0,2}$/)]),
  //     comments: this.fb.control("")
  //   });

  //   if (!!this.subscriptionPayment._id) {
  //     this.form.get('period').disable();
  //     this.form.get('regularUsers').disable();
  //     this.form.get('failureReportUsers').disable();
  //   }

  //   this.form.patchValue(this.subscriptionPayment);

  //   combineLatest([
  //     this.form.get('period').valueChanges.pipe(startWith(this.subscriptionPayment.period)),
  //     this.form.get('regularUsers').valueChanges.pipe(startWith(this.subscriptionPayment.regularUsers)),
  //     this.form.get('failureReportUsers').valueChanges.pipe(startWith(this.subscriptionPayment.failureReportUsers))]).subscribe(

  //       ([period, regularUsers, failureReportUsers]) => {
  //         const paymentPeriod = period === "12" ? "yearly" : "monthly";
  //         const regularUsersAmount = (parseInt(regularUsers) || 0) * environment.licensePrice[paymentPeriod].regularUser;
  //         const failureReportUsersAmount = (parseInt(failureReportUsers) || 0) * environment.licensePrice[paymentPeriod].failureReportUser;
  //         // const total = ((regularUsersAmount + failureReportUsersAmount) * ((100 + environment.paypal.vat) / 100)).toFixed(2);
  //         const total = regularUsersAmount + failureReportUsersAmount;
  //         this.form.get('amount').setValue(total)
  //       }
  //     )

  //   this.regularUsersPrice = this.form.get('period').valueChanges.pipe(
  //     startWith(this.subscriptionPayment.period),
  //     map(
  //       period => {
  //         if (period === "12") {
  //           return (environment.licensePrice.yearly.regularUser / 12).toFixed(2);
  //         } else {
  //           return environment.licensePrice.monthly.regularUser.toFixed(2);
  //         }
  //       }
  //     )
  //   )

  //   this.failureReportUsersAmount = this.form.get('period').valueChanges.pipe(
  //     startWith(this.subscriptionPayment.period),
  //     map(
  //       period => {
  //         if (period === "12") {
  //           return (environment.licensePrice.yearly.failureReportUser / 12).toFixed(2);
  //         } else {
  //           return environment.licensePrice.monthly.failureReportUser.toFixed(2);
  //         }
  //       }
  //     )
  //   )
  // }


  // onSubmit() {
  //   if (!this.form.invalid) {
  //     const payment = new SubscriptionPayment(this.form.value);
  //     payment.company = this.company;

  //     this.service.create(payment).subscribe(() => {
  //       this.doComplete();
  //       this.toaster.pop('success', this.translate.instant('toasters.SUBSCRIPTIONS.OK_SUCESSFUL_PAYMENT'));
  //     },
  //       (err) => {
  //         this.toaster.pop('error', this.translate.instant('toasters.SUBSCRIPTIONS.ERR_PAYMENT_ERROR'));
  //       })
  //   }

  // }


  // doComplete = () => {
  //   if (this.onComplete) {
  //     this.onComplete.emit(null);
  //   }
  // }

  // onAdd = (data, actions) => {
  //   this.loader.add();

  //   for (const field in this.form.controls) {
  //     this.subscriptionPayment[field] = this.form.controls[field].value
  //   }

  //   this.subscriptionPayment.details = `Regular Users: x ${this.subscriptionPayment.regularUsers} at $50/user, Failure Reporting: x${this.subscriptionPayment.failureReportUsers} at $10/user`;

  //   this.subscriptionPayment.paypalSubscriptionId = data.subscriptionID;
  //   this.subscriptionPayment.paypalSubscriptionStatus = "PENDING";

  //   this.service.create(this.subscriptionPayment).subscribe(
  //     (payment) => {
  //       this.loader.add();
  //       actions.subscription.get().then(details => {

  //         this.service.sync().subscribe(() => {
  //           this.doComplete();
  //           this.toaster.pop("success", this.translate.instant('toasters.SUBSCRIPTIONS.OK_SUCESSFUL_PAYMENT'));
  //         },
  //           () => {

  //           },
  //           () => {
  //             this.loader.remove()
  //           }
  //         );
  //       });
  //     },

  //     () => { },
  //     () => {
  //       this.loader.remove()
  //     })
  // }

  // onUpdate = (data, actions) => {
  //   this.loader.add();

  //   for (const field in this.form.controls) {
  //     this.subscriptionPayment[field] = this.form.controls[field].value
  //   }

  //   this.subscriptionPayment.details = `Regular Users: x ${this.subscriptionPayment.regularUsers} at $50/user, Failure Reporting: x${this.subscriptionPayment.failureReportUsers} at $10/user`;
  //   this.subscriptionPayment.previousPaypalSubscriptionId.push(this.subscriptionPayment.paypalSubscriptionId)
  //   this.subscriptionPayment.paypalSubscriptionId = data.subscriptionID;
  //   this.subscriptionPayment.paypalSubscriptionStatus = "PENDING";

  //   this.service.update(this.subscriptionPayment).subscribe(
  //     (payment) => {
  //       this.loader.add();
  //       actions.subscription.get().then(details => {

  //         this.service.sync().subscribe(() => {
  //           this.doComplete();
  //           this.toaster.pop("success", this.translate.instant('toasters.SUBSCRIPTIONS.OK_PAYMENT_INFO_UPDATED'));
  //         },
  //           () => {

  //           },
  //           () => {
  //             this.loader.remove()
  //           }
  //         );
  //       });
  //     },

  //     () => { },
  //     () => {
  //       this.loader.remove()
  //     })
  // }

}
