import { Directive, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[appResizable]' // Attribute selector
})

export class ResizableDirective implements OnInit {


  @Input() resizableGrabWidth = 15;
  @Input() resizableMinWidth = 10;
  @Input() resizableMaxWidth = 1000;
  @Input() isRTL = false;

  dragging = false;

  constructor(private el: ElementRef) {

    const self = this;

    const EventListenerMode = { capture: true };

    function preventGlobalMouseEvents() {
      document.body.style['pointer-events'] = 'none';
    }

    function restoreGlobalMouseEvents() {
      document.body.style['pointer-events'] = 'auto';
    }


    const newWidth = (wid) => {
      let newWidth = Math.max(this.resizableMinWidth, wid);
      newWidth = Math.min(this.resizableMaxWidth, newWidth);
      el.nativeElement.style.width = (newWidth) + "px";
      if(this.isRTL){
        $(".section-container").css('margin-right', newWidth);
        $(".toggle-menu").css('right', newWidth - 31);
      }else{
        $(".section-container").css('margin-left', newWidth);
        $(".toggle-menu").css('left', newWidth - 31);
      }
      $(".section-container .filter-width").css('width', `calc(100% - ${newWidth}px)`);
      $(".section-container .maintenance-block").css('width', `calc(100% - ${newWidth}px)`);
    }


    const mouseMoveG = (evt) => {
      if (!this.dragging) {
        return;
      }
      if(this.isRTL){
        newWidth(window.innerWidth - evt.clientX);
      }else{
        newWidth(evt.clientX - el.nativeElement.offsetLeft)
      }
      evt.stopPropagation();
    };

    const dragMoveG = (evt) => {
      if (!this.dragging) {
        return;
      }
      const newWidth = Math.max(this.resizableMinWidth, (evt.clientX - el.nativeElement.offsetLeft)) + "px";
      el.nativeElement.style.width = (evt.clientX - el.nativeElement.offsetLeft) + "px";
      evt.stopPropagation();
    };

    const mouseUpG = (evt) => {
      if (!this.dragging) {
        return;
      }
      restoreGlobalMouseEvents();
      this.dragging = false;
      evt.stopPropagation();
    };

    const mouseDown = (evt) => {
      if (this.inDragRegion(evt)) {
        this.dragging = true;
        preventGlobalMouseEvents();
        evt.stopPropagation();
      }
    };


    const mouseMove = (evt) => {
      if (this.inDragRegion(evt) || this.dragging) {
        el.nativeElement.style.cursor = "col-resize";
      } else {
        el.nativeElement.style.cursor = "default";
      }
    }


    document.addEventListener('mousemove', mouseMoveG, true);
    document.addEventListener('mouseup', mouseUpG, true);
    el.nativeElement.addEventListener('mousedown', mouseDown, true);
    el.nativeElement.addEventListener('mousemove', mouseMove, true);
  }

  ngOnInit(): void {
    if(this.isRTL){
      this.el.nativeElement.style["border-left"] = this.resizableGrabWidth + "px solid darkgrey";
    }else{
      this.el.nativeElement.style["border-right"] = this.resizableGrabWidth + "px solid darkgrey";
    }
  }

  inDragRegion(evt) {
    if(this.isRTL){
      const v = ((window.innerWidth - evt.clientX) - this.el.nativeElement.clientWidth);
      return v > -15;
    }else{
      const v = this.el.nativeElement.clientWidth - evt.clientX + this.el.nativeElement.offsetLeft
      return v < 15;
    }
  }

}