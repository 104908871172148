import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PmCalender } from '../../interfaces/pm-calender';
import { ANY_OBJECT } from '../../interfaces/general';
import { EmailColumns } from '../../interfaces/email-columns';

@Injectable({
  providedIn: 'root'
})
export class PmCalenderBackendService {

  url = '/api/pm-calender';

  constructor(private http: HttpClient) {
  }

  list(lastFetched: string = ''): Observable<PmCalender[]> {
    return this.http.get<PmCalender[]>(`${this.url}?lastFetched=${lastFetched}`);
  }

  deletedList(): Observable<PmCalender[]> {
    return this.http.get<PmCalender[]>(`${this.url}?deleted=true`);
  }

  create(calendar: PmCalender) {
    return this.http.post<PmCalender>(this.url, calendar);
  }

  getById(id: string) {
    return this.http.get<PmCalender>(`${this.url}/${id}`);
  }

  update(calendar: PmCalender) {
    return this.http.put<PmCalender>(`${this.url}/${calendar._id}`, calendar);
  }

  updateBulk(calendars: PmCalender[]) {
    return this.http.post<PmCalender[]>(`${this.url}/update-bulk`, { calendars: calendars });
  }

  delete(calendar: PmCalender) {
    return this.http.delete(`${this.url}/${calendar._id}`);
  }

  sendEmail(data: { email: string, file: string, type: string, columnSelector: EmailColumns }) {
    return this.http.post(`${this.url}/send-email`, data);
  }

  sendReportEmail(report: EmailColumns) {
    return this.http.post(`${this.url}/send-report-email/${report._id}`, {});
  }
}
