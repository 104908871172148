import { CommonService } from './../../../core/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observer } from 'rxjs';
import { OpenaiStoreService } from '../../../core/services/openai-store.service';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';
import { ToasterService } from 'angular2-toaster';
import { PusherService } from '../../../core/services/pusher.service';
import { AuthService } from '../../../routes/user/auth.service';
import { LoaderService } from '../../../core/utils/loader.service';

declare var $: any;

@Component({
  selector: 'app-chat-gpt',
  templateUrl: './chat-gpt.component.pug',
  styleUrls: ['./chat-gpt.component.scss']
})
export class ChatGptComponent implements OnInit {

  observer: Observer<String[]>
  @Input() text: string = '';
  @Input() tool: string = '';
  items: string[] = [];
  selected: string[] = [];
  oldLang: string = "English";
  translatedText: string = "";
  isRtl: boolean = false
  translations: any = {
    'CHATGPT-SUGGESTED-SOLUTIONS': "maintenance.CHATGPT-SUGGESTED-SOLUTIONS",
    'CLOSE': "general.CLOSE",
    'SELECT': "general.SELECT",
  }

  constructor(
    public bsModalRef: BsModalRef,
    public translationService: TranslationsStoreService,
    private openaiStoreService: OpenaiStoreService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private commonService: CommonService,
    private pusherService: PusherService,
    private auth: AuthService,
    private loaderService: LoaderService,
  ) { }

  ngOnInit(): void {
    this.pusherService.channel.bind('chat-gpt', (data: { text: string, user: string }) => {
      if(data.user && data.user.toString() && this.auth.getId().toString()){
        this.loaderService.remove();
        if (data.text) {
          this.items = data.text.replace(new RegExp('\n\n', 'g'), '\n').split('\n').filter(t => t).map(t => t.substring(t.indexOf('.') + 1).trim());
        } else {
          this.toaster.pop("error", this.translate.instant('toasters.GENERAL.SOMETHING-WENT-WRONG-WHILE-FETCHING-CHATGPT'));
          this.bsModalRef.hide();
        }
      }
    });
    this.translationService.language$.subscribe(data => {
      this.oldLang = data as string;
    });
    const langs = this.translate.getLangs();
    const cLang = this.commonService.getDefaultLanguage();
    if (!langs.includes(cLang)) {
      this.translationService.loadCompanyLanguage(cLang).subscribe(
        () => {
          const lang = this.translationService.getCompanyLanguage();
          if (lang) {
            this.translate.setTranslation(lang.name as string, lang.data, true)
            this.updateTraslations(lang.name as string)
          }
        }
      );
    } else {
      this.updateTraslations(cLang)
    }
    this.loaderService.add();
    this.openaiStoreService.ask(this.text, this.tool).subscribe(
      () => {
        setTimeout(() => {
          this.loaderService.remove();
        }, 100000);
      },
      (error) => {
        this.toaster.pop("error", this.translate.instant('toasters.GENERAL.SOMETHING-WENT-WRONG-WHILE-FETCHING-CHATGPT'));
        this.bsModalRef.hide();
      }
    );
    this.translationService.translateToNative([this.text], cLang).subscribe(
      (data: { sentences: string[] }) => {
        this.translatedText = data.sentences[0];
      },
      (err) => {
        this.toaster.pop("error", err.error ? err.error : this.translate.instant('toasters.GENERAL.ERR_ERROR_TRY_AGAIN'));
      })
  }

  updateTraslations(lang: string): void {
    this.isRtl = this.translationService.getCompanyLanguageRtl() as boolean;
    this.translate.use(lang);
    this.translations = Object.keys(this.translations).reduce((acc, key) => {
      acc[key] = this.translate.instant(this.translations[key]);
      return acc;
    }, {});
    this.translate.use(this.oldLang as string);
  }

  ngOnDestroy(): void {
    this.translate.use(this.oldLang as string);
  }

  handleCheckboxClick = ($event, item) => {
    $event.stopPropagation();
    if ($event.target.checked) {
      if (!this.selected.includes(item)) this.selected.push(item);
    } else {
      this.selected = this.selected.filter(i => i !== item);
    }
  }

  save = ($event) => {
    $event.preventDefault();
    $event.stopPropagation();
    this.bsModalRef.hide();
    this.observer.next(this.selected);
  }

  onDrag = ($event) => {
    if ($event) {
      $(".come-from-modal.right.modal-dialog").css("position", "unset");
    }
  }

}
