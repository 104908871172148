import { Component, OnInit } from '@angular/core';
import { CompaniesStoreService } from '../../core/services/companies-store.service';
import { SubscriptionPaymentsStoreService } from '../../core/services/subscription-payments-store.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { UsersStoreService } from '../../core/services/users-store.service';
import { AuthService } from '../user/auth.service';
import { environment } from '../../../environments/environment';
import { ConfirmService } from '../../core/utils/confirm/confirm.service';
import { ConfirmType } from '../../core/constants/confirm-result.enum';
import { SubscriptionPaymentComponent } from '../settings/subscriptions/subscription-payment/subscription-payment.component';
import { map } from 'rxjs/operators';
import { Company } from '../../core/interfaces/company';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  public show$: Observable<boolean> = new BehaviorSubject<boolean>(false);
  public isDevConnected: boolean = false;
  public isTestConnected: boolean = false;
  company: Company;
  constructor(private companies: CompaniesStoreService,
    public auth: AuthService,
    private confirm: ConfirmService,
    private modal: BsModalService,
    private usersService: UsersStoreService,
    private companiesStoreService: CompaniesStoreService,
    public payments: SubscriptionPaymentsStoreService) { }
  public customerServiceContact;

  ngOnInit() {
    this.show$ = combineLatest([this.auth.user$, this.companies.companies$, this.payments.subscriptionPayments$, this.payments.loaded$, this.usersService.users$]).pipe(
      map(([user, companies, payments, paymentsLoaded, users]) => {
        let u: any = users.find(u => u._id === user._id);
        if (!u || !u.company) return;
        this.company = u.company;
        if (this.company.skipUpgradeBlocker) {
          return false;
        } else {
          const result = (user && !user.isRoot() && companies.count() > 0 && paymentsLoaded && payments.filter(payment => payment.isActive()).count() === 0);
          return result
        }
      })
    )

    this.auth.isDevConnected$.subscribe((isDevConnected: boolean) => {
      this.isDevConnected = isDevConnected;
    });

    this.auth.isTestConnected$.subscribe((isTestConnected: boolean) => {
      this.isTestConnected = isTestConnected;
    });

    this.auth.user$.subscribe(user => {
      if (user && user.isTemporaryPassword) {
        this.confirm.show(["Please change your password"], { type: ConfirmType.CONFIRM_ONLY, confirmText: "OK" }).subscribe()
      }
    })

    this.customerServiceContact = environment.customerServiceContact;
  }

  cancel = ($event) => {
    this.company.skipUpgradeBlocker = true;
    this.companiesStoreService.update(this.company).subscribe();
    this.show$ = new Observable(ob => { ob.next(false); })
  }

  addLicense = () => {
    const ref: BsModalRef = this.modal.show(SubscriptionPaymentComponent,
      {
        keyboard: false,
        ignoreBackdropClick: true,
        class: 'modal-lg',
        initialState: {}
      });
  }
}
