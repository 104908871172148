import { IField } from "../../interfaces/field";

const FieldDefinition: IField[] = [
  {
    name: "id_num",
    label: "table.pm.table-title.PM-ID",
    type: "textbox",
    required: true,
    readonly: true,
  },
  {
    name: "company.name",
    label: "table.pm.table-title.COMPANY",
    type: "textbox",
    required: true,
    readonly: true,
  },
  {
    name: "tool.id_num",
    label: "table.pm.table-title.TOOL-ID",
    type: "textbox",
    required: true,
    readonly: true,
  },
  {
    name: "tool.name",
    label: "table.pm.table-title.TOOL-NAME",
    type: "textbox",
    required: true,
    readonly: true,
  },
  {
    name: "tool.code",
    label: "table.pm.table-title.CODE",
    type: "textbox",
    required: true,
    readonly: true,
  },
  {
    name: "type.id_num",
    label: "table.pm.table-title.PM-KIND-ID",
    type: "textbox",
    required: true,
    readonly: true,
    hide: true,
    valueGetter: (params) => {
      return params.data.type ? params.data.type.id_num : "";
    },
  },
  {
    name: "name",
    label: "table.pm.table-title.PM-KIND-NAME",
    type: "textbox",
    required: true,
    readonly: true,
    valueGetter: (params) => {
      return params.data.type ? params.data.type.name : params.data.name;
    },
  },
  {
    name: "type.pmKind",
    label: "table.pm.table-title.PM-KIND",
    type: "textbox",
    required: true,
    readonly: true,
  },
  {
    name: "workerObject.id_num",
    label: "table.pm.table-title.RESPONSIBLE-PERSON-ID",
    type: "textbox",
    required: true,
    readonly: true,
    hide: true,
  },
  {
    name: "worker",
    label: "table.pm.table-title.RESPONSIBLE-PERSON",
    type: "selectdropdown",
    options: [],
    required: false,
    readonly: false,
  },
  {
    name: "department.id_num",
    label: "table.pm.table-title.DEPARTMENT-ID",
    type: "textbox",
    required: true,
    readonly: true,
    hide: true,
    valueGetter: (params) => {
      return params.data.departmentObject
        ? params.data.departmentObject.id_num
        : "";
    },
  },
  {
    name: "department",
    label: "table.users.table-title.DEPARTMENT",
    type: "selectdropdown",
    options: [],
    required: false,
    readonly: false,
  },
  {
    name: "checklistItems",
    label: "table.pm.table-title.TASKS",
    type: "textbox",
    required: true,
    readonly: true,
    valueGetter: (params) =>
      params.data &&
      params.data.checklistItems &&
      params.data.checklistItems.length
        ? params.data.checklistItems.map((task) => task.name).join("; ") + ";"
        : "",
    tooltipValueGetter: (params) =>
      params.data &&
      params.data.checklistItems &&
      params.data.checklistItems.length
        ? params.data.checklistItems.map((task) => task.name).join("; ") + ";"
        : "",
  },
  {
    name: "file.id_num",
    label: "table.pm.table-title.FILE-ID",
    type: "textbox",
    required: true,
    readonly: true,
    hide: true,
    valueGetter: (params) => {
      return params.data.file && params.data.file.length > 0
          ? params.data.file.map((s) => (s?.id_num || "")).join(",")
          : "";
    },
  },
  {
    name: "file",
    label: "table.pm.table-title.FILE-NAME",
    type: "textbox",
    required: true,
    readonly: true,
    valueGetter: (params) => {
      return params.data.file && params.data.file.length > 0
          ? params.data.file.map((s) => (s?.name || "")).join(",")
          : "";
    },
  },
  {
    name: "lastMaintenance",
    label: "maintenance.LAST-MAINTENANCE",
    type: "datepicker",
    required: true,
    readonly: true,
  },
  {
    name: "nextMaintenance",
    label: "table.pm.table-title.NEXT-MAINTENANCE",
    type: "datepicker",
    required: true,
    readonly: true,
  },
  {
    name: "nextMaintenanceWeek",
    label: "table.pm.table-title.PM-WEEK",
    type: "textbox",
    required: false,
    readonly: true,
  },
  {
    name: "nextMaintenanceMonth",
    label: "table.pm.table-title.PM-MONTH",
    type: "textbox",
    required: false,
    readonly: true,
  },
  {
    name: "isFreeze",
    label: "table.pm.table-title.FROZEN",
    type: "checkbox",
    required: true,
  },
  {
    name: "isAutomaticNextPM",
    label: "table.pm.table-title.ALWAYS-FIRST-DAY-OF-MONTH/WEEK",
    type: "checkbox",
    required: true,
  },
  {
    name: "isPMCalender",
    label: "table.pm.table-title.IS-PM-CALENDAR",
    type: "checkbox",
    required: false,
    readonly: false,
  },
  {
    name: "grayLine",
    label: "table.pm.table-title.GRAY-LINE",
    type: "textbox",
    required: false,
    readonly: false,
    valueGetter: (params) => {
      return params.data.grayLine
        ? params.data?.grayLine
        : params.data.type?.grayLine
        ? params.data.type?.grayLine
        : 0;
    },
  },
  {
    name: "redLine",
    label: "table.pm.table-title.RED-LINE",
    type: "textbox",
    required: false,
    readonly: false,
    valueGetter: (params) => {
      return params.data.redLine
        ? params.data.redLine
        : params.data.type?.redLine
        ? params.data.type?.redLine
        : 0;
    },
  },
  {
    name: "notifyBefore",
    label: "table.pm.table-title.NOTIFY-BEFORE",
    type: "textbox",
    required: false,
    readonly: false,
  },
  {
    name: "isDeleted",
    label: "general.DELETED",
    type: "checkbox",
    readonly: false,
    isAdmin: true,
  },
  {
    name: "taskProcedure",
    label: "table.pm.table-title.PROCEDURE",
    type: "selectdropdown",
    options: [],
    readonly: false,
    isAdmin: true,
    section: "pm",
  },
  {
    name: "closeAfter",
    label: "table.pm.table-title.CLOSE-AFTER",
    type: "numeric",
    required: false,
    readonly: false,
  },
  {
    name: "inventoryPartsObject.id_num",
    label: "table.pm.table-title.INVENTORY-PARTS-ID",
    type: "textbox",
    required: false,
    readonly: true,
    hide: true,
    valueGetter: (params) =>
      params.data &&
      params.data.inventoryPartsObject &&
      params.data.inventoryPartsObject.length > 0
        ? params.data.inventoryPartsObject
            .filter((s) => s && s.id_num)
            .map((s) => (s && s.id_num ? s.id_num : ""))
            .join(",")
        : "",
  },
  {
    name: "inventoryParts",
    label: "table.pm.table-title.INVENTORY-PARTS",
    type: "multipledropdown",
    options: [],
    required: false,
    readonly: false,
  },
  {
    name: "measurementsObject.id_num",
    label: "table.pm.table-title.MEASUREMENTS-ID",
    type: "textbox",
    required: false,
    readonly: true,
    hide: true,
    valueGetter: (params) =>
      params.data &&
      params.data.measurementsObject &&
      params.data.measurementsObject.length > 0
        ? params.data.measurementsObject
            .filter((s) => s && s.id_num)
            .map((s) => (s && s.id_num ? s.id_num : ""))
            .join(",")
        : "",
  },
  {
    name: "measurements",
    label: "table.pm.table-title.MEASUREMENTS",
    type: "multipledropdown",
    options: [],
    required: false,
    readonly: false,
  },
  {
    name: "groupName",
    label: "table.pm.table-title.GROUP",
    type: "textbox",
    required: true,
    readonly: true,
    hide: true,
  },
  {
    name: "breadcrumbs",
    label: "table.pm.table-title.BREADCRUMBS",
    type: "textbox",
    required: true,
    readonly: true,
    hide: true,
  },
];

export default {
  FieldDefinition,
};
