import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserRoutingModule } from './user-routing.module';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { RecoverComponent } from './recover/recover.component';
import { TermsAndAgreementComponent } from './terms-and-agreement/terms-and-agreement.component';
import { ServiceLevelAgreementComponent } from './service-level-agreement/service-level-agreement.component';
import { VerifyComponent } from './verify/verify.component';
import { SetupCompanyComponent } from './setup-company/setup-company.component';
import { SharedModule } from '../../shared/shared.module';
import { SignupCompleteComponent } from './signup-complete/signup-complete.component';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { RefreshComponent } from './refresh/refresh.component';
import { NgOtpInputModule } from  'ng-otp-input';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { environment } from '../../../environments/environment';
import { DpaComponent } from './dpa/dpa.component';
import { SlaComponent } from './sla/sla.component';
import { SlaContentComponent } from './sla/sla-content/sla-content.component';
import { DpaContentComponent } from './dpa/dpa-content/dpa-content.component';
import { TermsAndAgreementContentComponent } from './terms-and-agreement/terms-and-agreement-content/terms-and-agreement-content.component';
import { LoginAdComponent } from './login-ad/login-ad.component';
import { UserLoginComponent } from './user-login/user-login.component';

const globalSettings: RecaptchaSettings = { siteKey: environment.googleSiteKey };

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    UserRoutingModule,
    SharedModule,
    NgOtpInputModule,
    RecaptchaModule,
  ],
  declarations: [
    LoginComponent,
    SignupComponent,
    RecoverComponent,
    TermsAndAgreementComponent,
    ServiceLevelAgreementComponent,
    VerifyComponent,
    SetupCompanyComponent,
    SignupCompleteComponent,
    VerifyOtpComponent,
    RefreshComponent,
    DpaComponent,
    SlaComponent,
    SlaContentComponent,
    DpaContentComponent,
    TermsAndAgreementContentComponent,
    LoginAdComponent,
    UserLoginComponent,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: globalSettings,
    },
  ]
})
export class UserModule { }
