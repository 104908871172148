import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observer } from 'rxjs';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';
import { User } from '../../../routes/user/login.response';
import { ModalResult } from '../../models/modal-result';
import { AuthService } from '../../../routes/user/auth.service';
import { ROLES } from '../../../core/constants/enums/enums';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.pug',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {

  form: UntypedFormGroup;
  user: User;
  observer: Observer<ModalResult>
  values: any;
  column: string;

  constructor(
    public bsModalRef: BsModalRef,
    private authService: AuthService,
    public fb: UntypedFormBuilder,
    public translationService: TranslationsStoreService,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      permissions: this.fb.array(
        this.values.map(value => {
          return this.fb.control(this.user[this.column] ? this.user[this.column].includes(value.key): false);
        })
      ),
    });
    if(!this.authService.isRoot()) {
      const developerIndex = this.values.findIndex(v => v.key === ROLES.DEVELOPER);
      if(developerIndex > -1) {
        this.form.get('permissions').get(`${developerIndex}`).disable();
      }
    }
  }

  save = () => {
    if (this.form.valid) {
      const v = this.values.filter((v, i) => this.form.getRawValue().permissions[i]).map(v => v.key);
      const result = {
        result: true,
        payload: {
          [this.column]: v
        }
      }
      this.bsModalRef.hide();
      this.observer.next(result);
    }
  }

}
