export const ruleSelector = {
  '>': '>',
  '>=': '>=',
  '<': '<',
  '<=': '<=',
};

export const ruleSelectorColor = {
  'none': 'None',
  'gray': 'Gray',
  'yellow': 'Yellow',
  'red': 'Red',
};