import { loadCldr } from "@syncfusion/ej2-base";
import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { FieldBase } from "../../models/field-base";
import { UntypedFormGroup } from "@angular/forms";
import { TranslationsStoreService } from "../../../core/services/translations-store.service";
import { isEmpty, isObject } from "lodash";
import { CommonService } from "../../../core/services/common.service";
import moment from "moment";
import { FilesStoreService } from "../../../core/services/files-store.service";
import { enableRipple } from "@syncfusion/ej2-base";
import { Observable } from "rxjs";
import { SelectFileComponent } from "../select-file/select-file.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { RelatedInventoryComponent } from "./related-inventory/related-inventory.component";
import { GeneralService } from "../../../core/services/general.service";
import { AiMaintenancePartsAssistantModalComponent } from "../ai-maintenance-parts-assistant-modal/ai-maintenance-parts-assistant-modal.component";
import { WindowsService } from "../../../core/services/windows.service";
enableRipple(true);

@Component({
  selector: "app-dynamic-field",
  templateUrl: "./dynamic-field.component.pug",
  styleUrls: ["./dynamic-field.component.scss"],
})
export class DynamicFieldComponent {
  @Input() controlDef: FieldBase<any>;
  @Input() form: UntypedFormGroup;
  @Input() sameRow: boolean = false;
  @Input() section: string = "";
  edit: boolean = false;
  langCode: string = "en";
  timeFormat = { skeleton: "Hm" };
  dateFormat = "dd-MM-yyyy";
  globalDateFormat = "YYYY-MM-DD";
  globalTimeFormat = "HH:mm";
  fieldValues: any;
  scrollTo = null;
  isSelectFromFile: boolean = false;
  @ViewChild("uploadctl") uploadctl: ElementRef;
  timeStep: number = this.commonService.getTimeStep();
  firstDayOfWeek = this.commonService.getFirstDayOfWeek();

  constructor(
    public translationService: TranslationsStoreService,
    public commonService: CommonService,
    private filesStoreService: FilesStoreService,
    private modal: BsModalService,
    private windowsService: WindowsService,
    private generalService: GeneralService
  ) {
    this.translationService.languageCode$.subscribe((data) => {
      this.langCode = data;
    });
    if (this.langCode != "en") {
      let caGregorian = require("../../../../assets/cldr-data/" +
        this.langCode +
        "/ca-gregorian.json");
      caGregorian["main"][this.langCode]["dates"]["calendars"]["gregorian"][
        "dateFormats"
      ]["short"] = this.commonService.getDateFormatForPicker();
      loadCldr(
        caGregorian,
        require("../../../../assets/cldr-data/" +
          this.langCode +
          "/numbers.json"),
        require("../../../../assets/cldr-data/" +
          this.langCode +
          "/timeZoneNames.json"),
        require("../../../../assets/cldr-data/weekData.json") // To load the culture based first day of week
      );
    }
    this.dateFormat = this.commonService.getDateFormatForPicker();
    this.timeFormat.skeleton = this.commonService.getTimeFormatForPicker();
    this.scrollTo = this.commonService.getScrollToTime();
  }

  value() {
    return this.form.controls[this.controlDef.key]
      ? this.form.controls[this.controlDef.key].value
      : "";
  }

  get isValid() {
    return this.form.controls[this.controlDef.key]
      ? this.form.controls[this.controlDef.key].valid
      : true;
  }

  get getOptions() {
    return isEmpty(this.controlDef.options) ? [] : this.controlDef.options;
  }

  onChangeCheckbox = ($event) => {
    this.form.controls[this.controlDef.key].setValue($event.target.checked);
    this.form.controls[this.controlDef.key].markAsDirty();
  };

  checkboxValue = () => {
    return this.form.controls[this.controlDef.key].value;
  };

  onKeyDown = ($event) => {
    if ($event.keyCode === 69) return false;
  };

  getDateValue = (key) => {
    const value = this.form.controls[key].value;
    this.fieldValues = {
      ...this.fieldValues,
      [key + "-date"]: value,
      [key + "-time"]: value,
    };
    return value;
  };

  scrollDate = (event) => {
    this.scrollTo = this.commonService.getScrollToTime(event.value);
  };

  changeDate = (event, key, type) => {
    if (type === "date") {
      this.fieldValues[key + "-date"] = event.value;
    } else if (type === "time") {
      this.fieldValues[key + "-time"] = event.value;
      this.scrollTo = this.commonService.getScrollToTime(event.value);
    }
    this.form.controls[key].setValue(
      new Date(
        `${moment(
          this.fieldValues[key + "-date"]
            ? this.fieldValues[key + "-date"]
            : moment()
        ).format(this.globalDateFormat)} ${
          this.fieldValues[key + "-time"]
            ? moment(this.fieldValues[key + "-time"]).format(
                this.globalTimeFormat
              )
            : this.commonService.getCompanyStartHour()
        }`
      )
    );
    this.form.controls[key].markAsDirty();
  };

  onDatePickerFocus(args, id): void {
    let datepickerObject: any = document.getElementById(id);
    datepickerObject.ej2_instances[0].show();
  }

  onTimePickerFocus(args, id): void {
    let datepickerObject: any = document.getElementById(id);
    datepickerObject.ej2_instances[0].show();
  }

  getPicture = (key) => {
    if (isObject(this.form.controls[key].value)) {
      this.form.controls[key].setValue("");
      return "";
    }
    return this.form.controls[key].value;
  };

  onClick($event, id) {
    if (this.isSelectFromFile) {
      const observable = new Observable<string>((observer) => {
        this.modal.show(SelectFileComponent, {
          keyboard: false,
          ignoreBackdropClick: true,
          initialState: {
            observer: observer,
          },
          class: "modal-lg",
        });
      });
      observable.subscribe((result) => {
        if (result) {
          this.form.controls[id].setValue(result);
          this.form.controls[id].markAsDirty();
          $(".info_section").trigger("click");
        }
      });
    } else {
      $("#" + id).trigger("click");
    }
  }

  uploadPicture = (event, key) => {
    if (!event.target.files.length || !event.target.files[0].name) {
      return;
    }
    if (this.commonService.checkFileExtention(event)) {
      this.filesStoreService
        .uploadCustomFiles(event.target.files)
        .subscribe((data) => {
          if (data.file && data.file.id) {
            this.form.controls[key].setValue(data.file.id);
            this.form.controls[key].markAsDirty();
            $(".info_section").trigger("click");
          }
        });
    }
  };

  selectFromFile = ($event) => {
    this.isSelectFromFile = $event.target.checked;
  };

  handleRelatedInventories = ($event: Event) => {
    $event.preventDefault();
    $event.stopPropagation();

    this.modal.show(RelatedInventoryComponent, {
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: {
        selectedParts: this.form.controls[this.controlDef.key].value,
      },
      class: "modal-lg width-75 modal-full modal-big-width-on-small",
    });
  };

  viewFile = ($event: Event, id: string = null) => {
    $event.preventDefault();
    $event.stopPropagation();
    this.generalService.viewFile(id);
  };

  handleSparePartExtraction = ($event: Event) => {
    $event.preventDefault();
    $event.stopPropagation();

    this.modal.show(AiMaintenancePartsAssistantModalComponent, {
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: {},
      class: "modal-lg event-modal-content modal-full modal-big-width-on-small modal-e-lg",
    });
  };
}
