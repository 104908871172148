<ng-container *ngFor="let checklist of chapterChecklistItems; let i = index">
  <div class="form-check container" *ngIf="i < 3">
    <ng-container *ngIf="checklist.hasChapter">
      <div class="d-flex gap-2 align-items-center">
        <img
          src="assets/img/play.svg"
          class="collapse-img"
          alt="collapse"
        />
        <label
          class="form-check-label cursor-pointer label-data-value-text"
          [tooltip]="checklist.chapterName"
          [placement]="i == 0 ? 'bottom' : 'top'"
        >
          {{
            checklist?.showMore
              ? checklist.chapterName
              : getDisplayName(checklist.chapterName)
          }}
          <a
            href="#"
            *ngIf="needToDisplayMoreLess(checklist.chapterName)"
            (click)="changeChecklistShow($event, checklist)"
          >
            {{
              (checklist?.showMore ? "general.SHOW-LESS" : "general.SHOW-MORE")
                | translate
            }}
          </a>
        </label>
      </div>
    </ng-container>
    <ng-container *ngIf="!checklist.hasChapter">
      <ng-container *ngFor="let checklistItem of checklist.checklistItems">
        <ng-container
          [ngTemplateOutlet]="checklistItemBlock"
          [ngTemplateOutletContext]="{
            checklistItem: checklistItem?.checklistItem ? checklistItem.checklistItem : checklistItem,
            i: i,
            completed: checklistItem?.completed
          }"
        ></ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<a href="#" *ngIf="chapterChecklistItems?.length > 3">{{
  "maintenance.SHOW-MORE-ITEMS" | translate
}}</a>
<ng-template #checklistItemBlock let-checklistItem="checklistItem" let-completed="completed" let-i="i">
  <input
    class="form-check-input"
    type="checkbox"
    value=""
    name="checklist-all-tasks"
    [id]="'checklistItem-show-' + i"
    [checked]="completed"
    [disabled]="true"
  />
  <label
    class="form-check-label cursor-pointer label-data-value-text"
    [ngClass]="{checkmark: checklistItem?.taskType !== 'comment'}"
    [for]="'checklistItem-show-' + i"
    [tooltip]="checklistItem?.name"
    [placement]="i == 0 ? 'bottom' : 'top'"
  >
    {{
      checklistItem?.showMore
        ? checklistItem?.name
        : getDisplayName(checklistItem?.name)
    }}
    <a
      href="#"
      *ngIf="needToDisplayMoreLess(checklistItem?.name)"
      (click)="changeChecklistShow($event, checklistItem)"
    >
      {{
        (checklistItem?.showMore
          ? "general.SHOW-LESS"
          : "general.SHOW-MORE"
        ) | translate
      }}
    </a>
  </label>
  <a
    href="#"
    [ngClass]="{
      'mr-3': (translationService.rtl$ | async),
      'ml-3': !(translationService.rtl$ | async)
    }"
    (click)="viewFile($event, checklistItem.image)"
    *ngIf="checklistItem.image"
    >{{ getFileName(checklistItem.image) }}</a
  >
</ng-template>
