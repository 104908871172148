import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthService} from '../../routes/user/auth.service';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard {
  constructor(private auth: AuthService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.user$.pipe(
      map(user => {
        let remember = user.remember === 'true' ? true : false;
        if (!remember) {
          return false;
        }
        this.router.navigate(['/main']);
        return true;
      })
    );
  }
}
