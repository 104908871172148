import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { TranslationsStoreService } from "../../../core/services/translations-store.service";
import { uniq } from "lodash";
import { BsModalService } from "ngx-bootstrap/modal";
import { WindowsService } from "../../../core/services/windows.service";
import { SORT_BY } from "../../../core/constants/enums/enums";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.pug",
  styleUrls: ["./checkbox.component.scss"],
})
export class CheckboxComponent implements OnInit {
  @Input() columns: any[] = [];
  @Input() type: string;
  @Input() checked: string[] = [];
  @Input() showFilters: boolean = true;
  @Input() filter: {
    tools?: string[];
  } = {
    tools: [],
  };
  @Input() header: {
    primary?: string;
    secondary?: string;
    groupBy?: string;
    sortBy?: string;
  } = {};
  @Output("onChecked") onChecked: EventEmitter<any> = new EventEmitter();
  @Output("onFilterUpdate") onFilterUpdate: EventEmitter<any> =
    new EventEmitter();
  @Output("onHeaderUpdate") onHeaderUpdate: EventEmitter<any> =
    new EventEmitter();
  isRtl: boolean = false;

  public checkboxes = [];
  list = [];
  sortList = [
    { id: SORT_BY.ASC, text: this.translateService.instant("general.ASC") },
    { id: SORT_BY.DESC, text: this.translateService.instant("general.DESC") },
  ];

  constructor(
    public translationService: TranslationsStoreService,
    private modal: BsModalService,
    private translateService: TranslateService,
    private windowsService: WindowsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.checkboxes = this.columns.map((col) => {
      return col.map((item) => ({
        id: item.colId,
        label: item.headerName,
        checked: this.checked.includes(item.colId),
      }));
    });
    this.translationService.rtl$.subscribe((data: boolean) => {
      this.isRtl = data;
    });
  }

  ngOnInit(): void {
    for (const i in this.columns) {
      const cols = this.columns[i];
      for (const j in cols) {
        const col = cols[j];
        this.list.push({
          id: col.colId,
          text: col.headerName,
        });
      }
    }
  }

  handleUpdateHeader = () => {
    this.onHeaderUpdate.emit({
      type: this.type,
      header: this.header,
    });
  };

  handleChange = ($event, colId: string) => {
    $event.preventDefault();

    const checked = $event.target.checked;
    const checkedItems = uniq(
      this.checkboxes.reduce((acc, curr) => {
        return [
          ...acc,
          ...curr
            .filter((item) => item.checked && item.id !== colId)
            .map((item) => item.id),
        ];
      }, [])
    );

    if (checked) checkedItems.push(colId);
    this.onChecked.emit({
      type: this.type,
      checked: checkedItems,
    });
  };

  handleCheckAll = ($event: Event) => {
    $event.stopPropagation();
    $event.preventDefault();
    const checkedItems = [];
    this.columns.forEach((col) => {
      col.forEach((row) => {
        checkedItems.push(row.colId);
      });
    });
    this.onChecked.emit({
      type: this.type,
      checked: checkedItems,
    });
  };

  handleUnCheckAll = ($event: Event) => {
    $event.stopPropagation();
    $event.preventDefault();
    this.onChecked.emit({
      type: this.type,
      checked: [],
    });
  };

  handleUnCheckAllIds = ($event: Event) => {
    $event.stopPropagation();
    $event.preventDefault();
    const checked = this.checked.filter(
      (item) =>
        ![
          "id_num",
          "tool.id_num",
          "typeId",
          "maintenanceObject.id_num",
          "worker.id_num",
          "responsiblePerson.id_num",
          "department.id_num",
          "file.id_num",
          "parentEvent.id_num",
          "workerObject.id_num",
          "type.id_num",
          "inventoryPartsObject.id_num",
          "measurementsObject.id_num",
          "maintenance.id_num",
          "event.id_num",
        ].includes(item)
    );
    this.onChecked.emit({
      type: this.type,
      checked: checked
    });
  };

  getChecked = (colId: string) => {
    return this.checked ? this.checked.includes(colId) : false;
  };

  // handleSelectDetail = ($event: Event, item) => {
  //   $event.stopPropagation();
  //   $event.preventDefault();

  //   let type = "";
  //   let selected = [];
  //   switch (item.id) {
  //     case 'tool.name':
  //       type = 'tools';
  //       selected = this.filter.tools || [];
  //       break;
  //   }

  //   const isMobile = this.windowsService.getWindowSize() <= 600;
  //   const observable = new Observable<string[]>(observer => {
  //     this.modal.show(SelectDetailedItemComponent, {
  //       initialState: {
  //         observer: observer,
  //         selected: selected,
  //         type: type,
  //         isRtl: this.isRtl,
  //       },
  //       class: `${!isMobile ? 'come-from-modal tool-from-modal' : ''} ${this.isRtl ? 'left' : 'right'} modal-dialog-centered ${isMobile ? 'modal-full' : ''}`
  //     })
  //   });

  //   observable.subscribe((selected: string[]) => {
  //     this.onFilterUpdate.emit({
  //       field: type,
  //       checked: selected
  //     });
  //   })
  // }
}
