import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {Observer, Observable, BehaviorSubject} from 'rxjs';
import { ConfirmResult, ConfirmType } from '../../constants/confirm-result.enum';
import { TranslationsStoreService } from '../../services/translations-store.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  observer: Observer<ConfirmResult>;
  question: String | String[];
  type: ConfirmType;
  options: any;
  timer$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  defaultOptions: any = {
    type: ConfirmType.DEFAULT,
    confirmText: "Yes",
    declineText: "No",
    timer: 10,
    defaultBtnClass: "",
    isDelete: false,
  }


  constructor(
    public bsModalRef: BsModalRef,
    private translate: TranslateService,
    public translationService: TranslationsStoreService
  )
  {
    this.defaultOptions.confirmText = this.translate.instant('general.YES');
    this.defaultOptions.declineText = this.translate.instant('general.NO');
  }

  ngOnInit() {
    this.options = {...this.defaultOptions, ...this.options}

    if (this.options.type === ConfirmType.TIMER){
      this.timer$.subscribe(timer=>{
        if (timer === this.options.timer){
          this.confirm();
        }else{
          setTimeout(()=>{
            this.timer$.next(timer+1)
          },1000);
        }
      })
    }
  }

  confirm() {
    this.bsModalRef.hide();
    this.observer.next(ConfirmResult.CONFIRM);
  }

  decline() {
    this.bsModalRef.hide();
    this.observer.next(ConfirmResult.DECLINE);
  }

  cancel(){
    this.bsModalRef.hide();
    this.observer.next(ConfirmResult.CANCEL)
  }

  messageLines = () =>{
    if (Array.isArray(this.question)) {
      return this.question;
    }else{
      return [this.question];
    }
  }

  showConfirm = () => [ConfirmType.DEFAULT ,ConfirmType.CONFIRM_ONLY , ConfirmType.TIMER].indexOf(this.options.type) > -1;
  showDecline = () =>  [ConfirmType.DEFAULT].indexOf(this.options.type) > -1;
  isTimer = () => this.options.type === ConfirmType.TIMER;

}
