import { Injectable } from '@angular/core';
import { ToolPointersBackendService } from './backend/tool-pointers-backend.service';
import { ToolPointer } from '../interfaces/tool-pointer';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { AuthService } from '../../routes/user/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ToolPointersStoreService {

  private toolPointerSubject: BehaviorSubject<ToolPointer> = new BehaviorSubject(null);
  public readonly toolPointer$: Observable<ToolPointer> = this.toolPointerSubject.asObservable();

  constructor(
    private backend: ToolPointersBackendService,
    private authService: AuthService,
  ) {
    this.authService.user$.subscribe((user) => {
      if (!user || !user._id || user.isRoot()) {
        return;
      }
      this.get().subscribe();
    });
  }

  get() {
    return this.backend.get()
    .pipe(
      tap(toolPointer => this.toolPointerSubject.next(toolPointer))
    )
  }

  create(toolPointer: ToolPointer) {
    return this.backend.create(toolPointer)
    .pipe(
      tap(toolPointer => this.toolPointerSubject.next(toolPointer))
    )
  }
}
