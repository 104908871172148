<div class="wrapper" [dir]="translationService.dir$ | async" [ngClass]="{'main-background': nav.isCalendarView()}">
  <ngb-toast *ngIf="show" [header]="pusherMessage" [autohide]="false" (hidden)="show=false">
		<button class="btn base_btn" (click)="handleReload($event)">{{ "general.RELOAD" | translate }}</button>
	</ngb-toast>
  <!-- top navbar-->
  <app-header class="topnavbar-wrapper" [openMenu]="openMenu" (onOpenMenuClick)='onOpenMenuClick($event)'></app-header>
  <!-- sidebar-->
  <app-sidebar class="aside-container" *ngIf="openMenu"></app-sidebar>
  <div class="toggle-menu hide-on-sm" [ngClass]="{'closed-toggle-menu': !openMenu, 'toggle-menu-rtl': (translationService.rtl$ | async)}">
    <img src="assets/img/right-arrow.png" (click)="onOpenMenuClick(!openMenu)">
  </div>
  <!-- offsidebar-->

  <!-- <app-scanner></app-scanner> -->

  <app-mobile-header class="mobile-header" *ngIf="windowsService.isMobile()"></app-mobile-header>
  <!-- Main section-->
  <section class="section-container" [ngClass]="{'close-menu-section': !openMenu}">
    <!-- Page content-->
    <div
      class="content-wrapper"
      [ngClass]="{
        'mobile-background': nav.isMaintenanceAll(),
        maintenance_page_p: nav.isToolDetail(),
        'main-background': nav.isCalendarView()
      }"
    >
      <router-outlet></router-outlet>
    </div>
  </section>
  <!-- Page footer-->
  <footer class="footer-container" [ngClass]="{'close-menu-section': !openMenu}" app-footer></footer>
  <app-cookie-banner></app-cookie-banner>
</div>
