import { CHECKLIST_TYPE } from "./enums/enums";

export const taskType = [
  {
    key: CHECKLIST_TYPE.CHECKLIST,
    value: "table.checklist.table-title.CHECKLIST",
  },
  {
    key: CHECKLIST_TYPE.MEASUREMENT,
    value: "table.checklist.table-title.MEASUREMENT",
  },
  { key: CHECKLIST_TYPE.COMMENT, value: "table.checklist.table-title.COMMENT" },
  { key: CHECKLIST_TYPE.CHAPTER, value: "table.checklist.table-title.CHAPTER" },
];
