import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';
import { AuthService } from '../../../routes/user/auth.service';

@Component({
  selector: 'app-menu-popup',
  templateUrl: './menu-popup.component.pug',
  styleUrls: ['./menu-popup.component.scss']
})
export class MenuPopupComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef,
    public userService: AuthService,
    public translationService: TranslationsStoreService,
  ) { }

  ngOnInit() {
  }

}
