import { base } from './environment.base';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  ...base,
  production: false,
  reload_timeout: 3600000,
  BASE_URL: process.env.NG_APP_API_URL || null,
  customerServiceContact: 'mailto:vladimir@anymaint.com',
  // paypal: {
  //   vat: 17,
  //   clientId: 'ATTn5N7y8-0558xhc5ZNKfkHiMPheibGDGi73S9BZ2DwJwlIm4ZH1lwoWjgqsMCqr5xETGQudWEZFLRv',
  //   plans: {
  //     yearly: {
  //       withTrial: 'P-503835068K854971EMDM65WA',
  //       withoutTrial: 'P-5G271570BR881614BMDM7ILQ'
  //     },
  //     monthly: {
  //       withTrial: 'P-8XM36302GK986071RMC5IPKQ',
  //       withoutTrial: 'P-6LD11870CF579454CMDM7DBI'
  //     }
  //   }
  // },
  // licensePrice: {
  //   yearly: {
  //     regularUser: 600,
  //     failureReportUser: 120,
  //   },
  //   monthly: {
  //     regularUser: 60,
  //     failureReportUser: 12,
  //   },

  // },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.import { base } from './environment.base';