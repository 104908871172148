<div class="container">
  <div class="row center">
    <div class="col-12 mt-2">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div
          class="row pt-3 scroll-profile special-scrollbar"
          [dir]="translationService.dir$ | async"
        >
          <div class="col-md-12 col-lg-6 col-xl-6">
            <div class="col-12">
              <div class="form-group form-value search_select">
                <label class="form-label no-changes" for="timezone">
                  {{ 'offsidebar.setting.TIMEZONE' | translate }}
                </label>
                <ngx-select
                  id="timezone"
                  [allowClear]="false"
                  autocomplete="off"
                  [items]="timezone"
                  [placeholder]="
                    'maintenance.form-labels.SELECT-PLACEHOLDER' | translate
                  "
                  name="timezone"
                  formControlName="timezone"
                ></ngx-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-3" [dir]="translationService.dir$ | async">
          <div class="col-12 text-center bt form_btn">
            <button class="btn btn-primary blue_btn" type="submit">
              {{ 'Save' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<app-cookie-banner></app-cookie-banner>
