import { Component, ElementRef, ViewChild } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ToolsStoreService } from "../../../core/services/tools-store.service";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "angular2-toaster";
import { BsModalService } from "ngx-bootstrap/modal";
import { AssignToGroupComponent } from "../../../routes/main/maintenance/assign-to-group/assign-to-group.component";
import { ConfirmResult } from "../../../core/constants/confirm-result.enum";
import { WorkerSignature } from "../../models/worker-signature";
import { WorkerConfirmComponent } from "../worker-confirm/worker-confirm.component";
import { Observable } from "rxjs";
import { CommonService } from "../../../core/services/common.service";
import { ConfirmService } from "../../../core/utils/confirm/confirm.service";
import { AuthService } from "../../../routes/user/auth.service";

@Component({
  selector: "app-status-renderer",
  template: `<div>
    <span class="dropdown dropdown-list" dropdown #dropdown="bs-dropdown">
      <div class="box" dropdownToggle (click)="handleToggleClick(); dropdown.isOpen = !dropdown.isOpen">
        <img src="assets/img/dots.svg" alt="dots" class="dots">
      </div>
      <div class="toggle-box dropdown-menu dropdown-menu-left" *dropdownMenu>
        <ul>
          <li class="dropdown-item" (click)="renameTool($event)">
            <a href="#">{{ 'Rename' | translate }}</a>
          </li>
          <li class="dropdown-item" (click)="changePicture($event)">
            <a href="#">{{ "CHANGE-PICTURE" | translate }}</a>
          </li>
          <li
            class="dropdown-item"
            (click)="rotatePicture($event)"
            *ngIf="item.image"
          >
            <a href="#">{{ "ROTATE-PICTURE" | translate }}</a>
          </li>
          <li
            class="dropdown-item"
            (click)="assignToGroup($event)"
          >
            <a href="#">{{ "ASSIGN-TO-GROUP" | translate }}</a>
          </li>
          <li class="dropdown-item" (click)="handleDelete($event)">
            <a href="#" class="red-text">{{ "Delete" | translate }}</a>
          </li>
        </ul>
      </div>
    </span>
    <input
      class="hidden"
      type="file"
      hidden
      #uploadctl
      (change)="uploadPicture($event)"
      click-stop-propagation
      [accept]="auth.getAllowedImageFileExtentionsAccept()"
    />
  </div>`,
  styles: [`
    .box {
      color: #fff;
      background-color: #5d9cec;
      border-color: #5d9cec;
      font-size: 16px;
      border-radius: 4px;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      border: 1px solid transparent;
      padding: 0.375rem 1rem;
    }
    ul {
      margin-bottom: 0;
      margin-block-start: 0;
      padding-inline-start: 0;
    }
    ul .dropdown-item a {
      width: 100%;
      background: #fff;
      padding: 10px 15px;
      display: inline-block;
      color: #4f5253;
      font-size: 16px;
    }
    ul .dropdown-item a:hover, ul .dropdown-item a:focus {
      color: #16181b;
      text-decoration: none;
      background-color: #f8f9fa;
    }
    .red-text {
      color: red !important;
    }
    .dots {
      width: 20px;
    }
  `],
})
export class StatusRendererComponent implements ICellRendererAngularComp {
  params;
  item;
  @ViewChild("uploadctl") uploadctl: ElementRef;
  show = false;

  constructor(
    private toolsStoreService: ToolsStoreService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private modal: BsModalService,
    private commonService: CommonService,
    private confirm: ConfirmService,
    public auth: AuthService
  ) {}

  agInit(params): void {
    this.params = params;
    this.item = this.params.data;
  }

  handleToggleClick = () => {
    this.params.api.startEditingCell({
      rowIndex: this.params.rowIndex,
      colKey: this.params.colDef.field,
    });
    $('.ag-popup .box').trigger('click');
  };

  changePicture = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    this.params.api.stopEditing();
    this.uploadctl.nativeElement.click();
  };

  renameTool = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    this.params.api.stopEditing();
    this.params.api.startEditingCell({
      rowIndex: this.params.rowIndex,
      colKey: "name",
    });
  }

  uploadPicture = ($event) => {
    if (
      !this.item ||
      !$event.target.files.length ||
      !$event.target.files[0].name
    ) {
      return;
    }
    if (this.commonService.checkImageFileExtention($event)) {
      this.toolsStoreService.uploadPicture(
        this.item.id_num,
        $event.target.files
      );
    }
  };

  isPopup(): boolean {
    return true;
  }

  getValue() {
    return null;
  }

  rotatePicture = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    this.params.api.stopEditing();
    this.toolsStoreService.rotatePicture(this.item._id).subscribe(
      () => {
        this.toaster.pop(
          "success",
          this.translate.instant("toasters.GENERAL.OK_UPDATE")
        );
      },
      () => {
        this.toaster.pop(
          "error",
          this.translate.instant("toasters.GENERAL.ERR_UPDATE")
        );
      }
    );
  };

  assignToGroup = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    this.params.api.stopEditing();
    this.modal.show(AssignToGroupComponent, {
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: {
        tool: this.item,
      },
      class: "modal-lg",
    });
  };

  handleDelete = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    this.params.api.stopEditing();
    this.confirm
      .show(this.translate.instant("confirm.GENERAL.CONFIRM_DELETE_WITH_NAME", { name: this.item.name }))
      .subscribe((result: ConfirmResult) => {
        if (result === ConfirmResult.CONFIRM) {
          if (this.commonService.isSignatureRequired()) {
            const observable = new Observable<WorkerSignature>((observer) => {
              this.modal.show(WorkerConfirmComponent, {
                keyboard: false,
                ignoreBackdropClick: true,
                initialState: {
                  observer: observer,
                  type: this.item.isGroup ? "Delete group" : "Delete tool",
                },
                class: "",
              });
            });

            observable.subscribe(async (data: WorkerSignature) => {
              if (data.result) {
                this.signatureDelete();
              }
            });
          } else {
            this.signatureDelete();
          }
        }
      });
  };

  signatureDelete = () => {
    this.toolsStoreService.delete(this.item._id).subscribe(
      () => {
        this.toaster.pop(
          "success",
          this.translate.instant("toasters.GENERAL.OK_DELETE")
        );
      },
      () => {
        this.toaster.pop(
          "error",
          this.translate.instant("toasters.GENERAL.ERR_DELETE")
        );
      }
    );
  };

  refresh(params?: any): boolean {
    return true;
  }
}
