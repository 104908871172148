import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { concat, flatten } from 'lodash';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from '../../../core/services/common.service';
import { DepartmentsStoreService } from '../../../core/services/departments-store.service';
import { PreventiveStoreService } from '../../../core/services/preventive-store.service';
import { SitesStoreService } from '../../../core/services/sites-store.service';
import { ToolsStoreService } from '../../../core/services/tools-store.service';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';
import { UsersStoreService } from '../../../core/services/users-store.service';
import { WindowsService } from '../../../core/services/windows.service';
import { LoaderService } from '../../../core/utils/loader.service';
import { NavService } from '../../../routes/main/nav.service';
import { AuthService } from '../../../routes/user/auth.service';
import { ExcelService } from '../../../shared/services/excel.service';
import { ScannerComponent } from './scanner/scanner.component';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.pug',
  styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnInit {

  public is_active_events_all: boolean = false;
  public is_active_events_pm: boolean = false;
  public is_active_events_idle: boolean = false;
  public is_active_events_up: boolean = false;
  public is_active_events_down: boolean = false;
  public is_active_events_qa: boolean = false;
  public is_active_events_safety: boolean = false;
  public count: number = 0;
  public activeEventsResponsiblePerson: string = '';
  public responsiblePerson: string = '';
  isNavSearchVisible: boolean = true;
  isNavSearchOpen: boolean = false;
  isNavSettingOpen: boolean = false;
  public site: string = 'All'
  public group: string = 'All'
  public dashboardSite: string = 'All'
  public dashboardGroup: string = 'All'
  public baseActiveEventsFilters = {
    'type': 'All',
    'responsiblePerson': '',
    'department': '',
    'timePeriod': {
      start: moment().startOf('week').startOf('day'), end: moment().endOf('week')
    },
    'site': '',
    'group': [],
  };
  public activeEventsFilters = Object.assign({},this.baseActiveEventsFilters);
  usersDetails$: Observable<any> = this.usersStore.users$.pipe(
    map(users => users.filter(u => this.commonService.hasBigTeamPermission() ? (!u.isDeleted) : (!u.isDeleted && !u.isWorker)).sort((a, b) => Number(a.id_num) - Number(b.id_num)).toArray()),
  )
  departments$: Observable<{ id: string, text: string }[]> = this.departmentService.departments$.pipe(
    map(departments => departments.filter(d => !d.isDeleted && !d.dontAssignEvents).sort((a, b) => Number(a.id_num) - Number(b.id_num)).map(d => ({ id: d._id, text: d.name })).toArray()),
  )
  users: any[] = [];
  joined: any[] = [];
  joinedSite: any[] = []
  joinedGroup: any[] = []
  exportMaintenanceData: any[];
  maintenanceColumns: any[];
  sites$: Observable<{ id: string, text: string }[]> = this.sitesStoreService.sites$.pipe(
    map(sites => sites.filter(s => !s.isDeleted).map(s => ({ id: s._id, text: s.name })).toArray()),
  )
  timePeriod: any = { start: moment().subtract(1, 'months').startOf('day'), end: moment() }
  activeEventTimePeriod: any = { start: moment().subtract(1, 'months').startOf('day'), end: moment() }
  firstDayOfWeek = this.commonService.getFirstDayOfWeek();
  weekOffset = moment().weekday() - this.firstDayOfWeek;
  weekDayOffset = this.weekOffset < 0 ? 7 + this.weekOffset : this.weekOffset;
  ranges: any = {
    'dashboard.ALL': [moment('2000-01-01'), moment()],
    'dashboard.TODAY': [moment(), moment()],
    'dashboard.THIS-WEEK': [moment().subtract(this.weekDayOffset, 'days'), moment().subtract(this.weekDayOffset, 'days').add(6, 'days')],
    'dashboard.LAST-WEEK': [moment().subtract(1, 'week').subtract(this.weekDayOffset, 'days'), moment().subtract(1, 'week').subtract(this.weekDayOffset, 'days').add(6, 'days')],
    'dashboard.THIS-MONTH': [moment().startOf('month').startOf('day'), moment().endOf('month').startOf('day')],
    'dashboard.LAST-MONTH': [moment().subtract(1, 'months').startOf('day'), moment()],
    'dashboard.THIS-YEAR': [moment().startOf('year'), moment().endOf('year')],
    'dashboard.LAST-YEAR': [moment().subtract(1, 'years').startOf('year'), moment().subtract(1, 'years').endOf('year')],
  }
  activeEventRanges: any = {
    'dashboard.TODAY': [moment(), moment()],
    'active-events.YESTERDAY-TODAY': [moment().subtract(1, 'days'), moment()],
    'active-events.YESTERDAY-TOMORROW': [moment().subtract(1, 'days'), moment().add(1, 'days')],
    'active-events.THIS-WEEK': [moment().subtract(this.weekDayOffset, 'days'), moment().subtract(this.weekDayOffset, 'days').add(6, 'days')],
    'active-events.NEXT-WEEK': [moment().add(1, 'week').subtract(this.weekDayOffset, 'days'), moment().add(1, 'week').subtract(this.weekDayOffset, 'days').add(6, 'days')],
    'active-events.THIS-MONTH': [moment().startOf('month').startOf('day'), moment().endOf('month').startOf('day')],
    'active-events.NEXT-MONTH': [moment().add(1, 'month').startOf('month').startOf('day'), moment().add(1, 'month').endOf('month').startOf('day')],
  }
  customRanges: any
  activeEventCustomRanges: any
  locale: object = {
    format: 'DD/MM/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'DD/MM/YYYY', // default is format value
    direction: 'ltr', // could be rtl
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    customRangeLabel: 'Custom range',
    firstDay: this.firstDayOfWeek,
  }
  isToShowTimePeriod = false;
  fontSize: number = this.service.fontSizeSubject.getValue();

  constructor(
    public service: NavService,
    public pm: PreventiveStoreService,
    private departmentService: DepartmentsStoreService,
    private sitesStoreService: SitesStoreService,
    public translationService: TranslationsStoreService,
    public translate: TranslateService,
    private usersStore: UsersStoreService,
    private excel: ExcelService,
    public toolsService: ToolsStoreService,
    public commonService: CommonService,
    private auth: AuthService,
    private modal: BsModalService,
    public windowsService: WindowsService,
    private loader: LoaderService,
    private router: Router) { }

  ngOnInit() {
    this.toolsService.toolsUpdated.subscribe(() => {
      this.joinedGroup = [({ id: 'All', text: this.translate.instant('general.ALL') }), ...this.toolsService.getGroupSelectList()]
    })
    this.setActiveEventFilter();
    this.translationService.languageCode$.subscribe(data => {
      if (data != 'en') {
        let localization = require('moment/locale/' + data + '.js')
        moment.locale(data, localization)
        this.locale['daysOfWeek'] = moment.weekdaysMin()
        this.locale['monthNames'] = moment.monthsShort()
      }
      this.translationService.dir$.subscribe(data => {
        this.customRanges = Object.keys(this.ranges).reduce((acc, key) => {
          acc[this.translate.instant(key)] = this.ranges[key]
          return acc
        }, {})
        this.activeEventCustomRanges = Object.keys(this.activeEventRanges).reduce((acc, key) => {
          acc[this.translate.instant(key)] = this.activeEventRanges[key]
          return acc
        }, {})
        this.locale['customRangeLabel'] = this.translate.instant('dashboard.CUSTOM-RANGE')
        this.locale['direction'] = data
        this.locale['format'] = this.locale['displayFormat'] = this.commonService.getDateFormat()
        this.isToShowTimePeriod = true
      })
      moment.locale("en");
    })
    combineLatest([this.toolsService.toolsUpdated, this.pm.maints$])
      .pipe(
        map(([toolsUpdated, maints]) => maints.filter(maint => !maint.isDeleted && this.toolsService.getToolList().find(tool => maint.tool && tool && tool._id === maint.tool._id)))
      ).subscribe((maints) => {
        this.count = maints.count()
      });
    this.usersDetails$.subscribe(users => {
      this.users = users;
      const mappedUser = users.filter(u => (this.commonService.hasBigTeamPermission() ? true : !u.isWorker) && !u.dontAssignEvents).map((u: any) => ({ id: u._id, text: (u.firstName || u.lastName ? u.fullName : u.username) }));
      this.departments$.subscribe(departments => {
        this.joined = [({ id: 'All Users', text: this.translate.instant('general.ALL') }), ...mappedUser, ...departments, ({ id: 'Not Assigned', text: this.translate.instant('general.NOT-ASSIGNED') })];
        this.auth.user$.subscribe(user => {
          if (user) {
            this.activeEventsResponsiblePerson = !this.activeEventsResponsiblePerson ? user._id : this.activeEventsResponsiblePerson
            this.selectActiveEventsFilter(event, this.activeEventsResponsiblePerson)
            this.responsiblePerson = 'All Users';
          }
        });
      })
    })
    combineLatest([this.toolsService.columns$, this.toolsService.toolsUpdated])
      .subscribe(([columns, toolsUpdated]) => {
        this.maintenanceColumns = columns;
        this.exportMaintenanceData = this.toolsService.getToolList();
      })
    this.sites$.subscribe(sites => {
      this.joinedSite = [({ id: 'All', text: this.translate.instant('general.ALL') }), ...sites]
    })
    const filters = this.service.filters;
    if (filters.timePeriod) {
      this.timePeriod = {
        start: moment(filters.timePeriod.start),
        end: moment(filters.timePeriod.end).subtract(moment().utcOffset(), "minutes")
      };
    }
    if (filters.responsiblePerson != '') {
      this.responsiblePerson = filters.responsiblePerson;
    }
    if (filters.site != '') {
      this.dashboardSite = filters.site;
    }
    if (filters.group && filters.group.length > 0) {
      this.dashboardGroup = filters.group[0];
    }
    this.router.events.subscribe((val) => {
      this.isNavSearchVisible = true;
      this.isNavSearchOpen = false;
      this.isNavSettingOpen = false;
    })
    this.service.filters.timePeriodUpdated = true;
    this.service.setFilter(this.service.filters)
    this.service.fontSize$.subscribe((size) => {
      this.fontSize = size;
    });
  }

  handleFontChange = ($event) => {
    this.service.fontSizeSubject.next($event.newValue);
    this.fontSize = $event.newValue;
    this.commonService.setLocalStorage('_chart_font_size', $event.newValue?.toString());
  }

  getActiveEventFilter = () => {
    return localStorage.getItem('_active_events_filters') ? JSON.parse(localStorage.getItem('_active_events_filters')) : this.activeEventsFilters;
  }

  setActiveEventFilter = () => {
    const result = this.getActiveEventFilter();
    if (result && Object.keys(result).length) {
      this.activeEventsFilters = result;
    } else {
      const user = this.auth.getUser()
      if (user.activeEventTimePicker) {
        this.activeEventsFilters = user.activeEventTimePicker;
      }
    }
    this.activeEventTimePeriod = { start: moment(this.activeEventsFilters['timePeriod']['start']), end: moment(this.activeEventsFilters['timePeriod']['end']).subtract(moment().utcOffset(), "minutes") };
    this.site = this.activeEventsFilters['site'] ? this.activeEventsFilters['site'] : 'All';
    this.group = this.activeEventsFilters['group'] && this.activeEventsFilters['group'].length ? this.activeEventsFilters['group'][0] : 'All';
    this.activeEventsResponsiblePerson = this.activeEventsFilters['responsiblePerson'] ? this.activeEventsFilters['responsiblePerson'] : this.activeEventsFilters['department'] ? this.activeEventsFilters['department'] : '';
    const type = this.activeEventsFilters['type'];
    if (type == 'PM') {
      this.is_active_events_pm = true;
    } else if (type == 'IDLE') {
      this.is_active_events_idle = true;
    } else if (type == 'UP') {
      this.is_active_events_up = true;
    } else if (type == 'DOWN') {
      this.is_active_events_down = true;
    } else if (type == 'QA') {
      this.is_active_events_qa = true;
    } else if (type == 'SAFETY') {
      this.is_active_events_safety = true;
    } else {
      this.is_active_events_all = true;
    }
  }

  datesUpdated = ($event) => {
    this.service.filters['timePeriod'] = this.timePeriod
    this.service.filters['isKpi'] = false
    this.service.filters['timePeriodUpdated'] = true;
  }

  datesUpdatedActiveEvents = ($event) => {
    this.activeEventsFilters['timePeriod'] = this.activeEventTimePeriod
  }

  showScannerEvent = ($event) => {
    const observable = new Observable<string>(observer => {
      this.modal.show(ScannerComponent,
        {
          keyboard: false,
          ignoreBackdropClick: true,
          initialState: {
            observer: observer,
            selected$: new BehaviorSubject<string>('')
          },
          class: "modal-lg modal-full scanner-modal"
        })
    });
    observable.subscribe(async (data: string) => {
      if (data.includes(this.windowsService.getOrigin())) {
        this.router.navigate([data.replace(this.windowsService.getOrigin(), '')]);
      } else {
        window.location.href = data;
      }
    })
  }

  public async activeEventsSearch(event: any, type = '') {
    if (type == 'site') {
      this.activeEventsFilters['site'] = event && event != 'All' ? event : '';
    } else if (type == 'group') {
      if (event && event != 'All') {
        const groups = await this.getRelatedGroups([event]);
        this.activeEventsFilters['group'] = concat([event], groups);
      } else {
        this.activeEventsFilters['group'] = [];
      }
    } else {
      this.selectActiveEventsFilter(event, event)
    }
  }

  public async dashboardSearch(event: any, type = '') {
    if (type == 'site') {
      this.service.filters['site'] = event && event != 'All' ? event : '';
    } else if (type == 'group') {
      if (event && event != 'All') {
        const groups = await this.getRelatedGroups([event]);
        this.service.filters['group'] = concat([event], groups);
      } else {
        this.service.filters['group'] = [];
      }
    }
    this.service.filters['timePeriodUpdated'] = false;
  }

  getRelatedGroups = async (group) => {
    return new Promise(async (resolve, reject) => {
      let res = [];
      const groups = this.toolsService.getGroups();
      const grp = groups.filter(g => g.group && group.includes(g.group._id.toString())).filter(g => !g.isDeleted).map(g => g._id);
      if (grp.length > 0) {
        res = concat(res, grp);
        const insideGroup: any = await this.getRelatedGroups(grp);
        if (insideGroup.length > 0) {
          res = concat(res, insideGroup);
        }
      }
      resolve(res);
    })
  }

  public selectActiveEventsFilter(event: any, filter: string) {
    if (['All', 'PM', 'QA', 'SAFETY', 'IDLE', 'UP', 'DOWN'].indexOf(filter) != -1) {
      this.is_active_events_all = false;
      this.is_active_events_pm = false;
      this.is_active_events_up = false;
      this.is_active_events_down = false;
      this.is_active_events_idle = false;
      this.is_active_events_qa = false;
      this.is_active_events_safety = false;
      if (filter == 'All') {
        this.is_active_events_all = true;
      } else if (filter == 'PM') {
        this.is_active_events_pm = true;
      } else if (filter == 'IDLE') {
        this.is_active_events_idle = true;
      } else if (filter == 'UP') {
        this.is_active_events_up = true;
      } else if (filter == 'DOWN') {
        this.is_active_events_down = true;
      } else if (filter == 'QA') {
        this.is_active_events_qa = true;
      } else if (filter == 'SAFETY') {
        this.is_active_events_safety = true;
      }
      this.activeEventsFilters['type'] = filter;
    } else {
      this.activeEventsFilters['responsiblePerson'] = filter;
    }
    this.activeEventsFilters['department'] = flatten(this.users.filter(u => u._id == this.activeEventsFilters['responsiblePerson']).map(u => u.department ? u.department : [])).join(',');
  }

  public selectFilter(event: any, filter: string) {
    if (['All', 'PM', 'QA', 'SAFETY', 'IDLE', 'UP', 'DOWN'].indexOf(filter) != -1) {
      this.service.filters['type'] = filter;
      this.service.filters['done'] = '';
      this.service.filters['isKpi'] = false;
    } else {
      this.service.filters['responsiblePerson'] = filter;
    }
    this.service.filters['department'] = flatten(this.users.filter(u => u._id == this.service.filters['responsiblePerson']).map(u => u.department ? u.department : [])).join(',');
    this.service.filters['timePeriodUpdated'] = false;
  }

  updateActiveEventFilter = (activeEventsFilters) => {
    activeEventsFilters.timePeriod = { start: moment(activeEventsFilters.timePeriod.start.toString()), end: moment(activeEventsFilters.timePeriod.end.toString()) };
    localStorage.setItem('_active_events_filters', JSON.stringify(activeEventsFilters));
    this.service.setActiveEventsFilter(activeEventsFilters);
  }

  searchActiveEvents = ($event) => {
    this.updateActiveEventFilter(this.activeEventsFilters);
    this.closeNavSearchVisible($event);
  }

  clearActiveEvents = ($event) => {
    this.activeEventTimePeriod = this.baseActiveEventsFilters.timePeriod;
    this.site = 'All',
    this.group = 'All',
    this.activeEventsResponsiblePerson = 'All Users',
    this.is_active_events_all = true;
    this.is_active_events_pm = false;
    this.is_active_events_up = false;
    this.is_active_events_down = false;
    this.is_active_events_idle = false;
    this.activeEventsFilters = Object.assign({},this.baseActiveEventsFilters);
    this.updateActiveEventFilter(this.activeEventsFilters);
    this.closeNavSearchVisible($event);
  }

  searchDashboard = ($event) => {
    this.service.setFilter(this.service.filters);
    this.closeNavSearchVisible($event);
  }

  clearDahboard = ($event) => {
    this.timePeriod = this.service.baseFilter.timePeriod;
    this.dashboardSite = 'All',
    this.dashboardGroup = 'All',
    this.responsiblePerson = 'All Users',
    this.service.filters = Object.assign({},this.service.baseFilter);
    this.service.filters.timePeriodUpdated = true;
    this.service.setFilter(this.service.filters);
    this.closeNavSearchVisible($event);
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(!this.isNavSearchVisible);
    this.isNavSettingOpen = false;
  }

  setNavSearchVisible(stat: boolean) {
    this.isNavSearchVisible = stat;
  }

  closeNavSearchVisible($event) {
    this.isNavSearchVisible = !this.isNavSearchVisible;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleNavSearch(){
    this.isNavSearchOpen = !this.isNavSearchOpen;
    this.isNavSettingOpen = false;
  }

  toggleNavSetting(){
    this.isNavSettingOpen = !this.isNavSettingOpen;
  }

  doExportMaintenance() {
    const rows = this.exportMaintenanceData.length > 0 ? this.exportMaintenanceData : [{}];
    const data = rows.map(tool => {
      return this.maintenanceColumns.reduce((acc, curr) => {
        let value = "";
        if (curr.valueFormatter) value = curr.valueFormatter({ data: tool })
        else if (curr.valueGetter) value = curr.valueGetter({ data: tool })
        else if (curr.field.includes('.')) {
          const props = curr.field.split('.');
          value = props.reduce((acc, curr) => acc ? acc[curr] : {}, tool);
        }
        else value = tool[curr.field];
        return { ...acc, [curr.headerName]: value }
      }, {})
    })
    this.excel.exportAsExcelFile(data, "Maintenance");
  }

  getLink(link) {
    return "/main/" + link.join('/');
  }

}
