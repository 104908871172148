import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { List } from 'immutable';
import { UsersBackendService } from './backend/users-backend.service';
import { AuthService } from '../../routes/user/auth.service';
import { User, UserEmail } from '../../routes/user/login.response';
import { ToasterService } from 'angular2-toaster';
import { tap, map } from 'rxjs/operators';
import FieldDefinition from '../constants/field-definitions/users';
import WorkerFieldDefinition from '../constants/field-definitions/worker';
import { CustomFieldsStoreService } from './custom-fields-store.service';
import CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { DepartmentsStoreService } from './departments-store.service';
import { SitesStoreService } from './sites-store.service';
import { TranslationsStoreService } from './translations-store.service';
import moment from 'moment-timezone';
import { uniqBy } from 'lodash';
import { DataFormatsStoreService } from './data-formats-store.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class UsersStoreService {

  private usersSubject: BehaviorSubject<List<User>> = new BehaviorSubject(List([]));
  public readonly users$: Observable<List<User>> = this.usersSubject.asObservable();
  public readonly admins$: Observable<User> = this.users$.pipe(
    map(
      users => users.filter(u => !u.isDeleted).find(user => user.roles.includes('admin'))
    )
  );

  private lastFetchedTime: BehaviorSubject<string> = new BehaviorSubject(null);

  public columns$: Observable<any[]>;
  public workerColumns$: Observable<any[]>;

  constructor(
    private backend: UsersBackendService,
    private departmentsStoreService: DepartmentsStoreService,
    private sitesStoreService: SitesStoreService,
    private auth: AuthService,
    private customFields: CustomFieldsStoreService,
    private router: Router,
    private toasterService: ToasterService,
    private translationsStoreService: TranslationsStoreService,
    private dataFormatsStoreService: DataFormatsStoreService,
    private commonService: CommonService,
  ) {

    auth.user$.subscribe(user => {
      if (!user) {
        this.usersSubject.next(List([]));
        return;
      }
      this.load().subscribe();
      this.departmentsStoreService.load().subscribe();
      this.sitesStoreService.load().subscribe();
      if(this.commonService.hasMeasurementsPermission()) {
        this.dataFormatsStoreService.list().subscribe();
      }
    });

    this.columns$ = combineLatest([this.auth.user$, this.customFields.get('users'), this.translationsStoreService.languageCode$]).pipe(
      map(([user, columns, languageCode]) => {
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(col => (col.name != "company.name" || user.isRoot()) && (col.name != "isDeleted" || user.isAdmin()))
        const fields = columns.toArray();
        const col = this.customFields.toColumnDef(defaults, {
          rowSelector: "id_num",
          format: (col, field) => {
            switch (field.name) {
              case "permission":
                return { ...col, editable: (params) => params.data.$row_status === "new" }
              default:
                return col;
            }
          }
        });
        const custom = this.customFields.toColumnDef(fields, {
          format: this.customFields.formatCellCustomField.bind(this)
        });
        return col.concat(custom);
      })
    )

    this.workerColumns$ = combineLatest([this.auth.user$, this.customFields.get('users'), this.translationsStoreService.languageCode$]).pipe(
      map(([user, columns, languageCode]) => {
        if (!user) return [];
        const defaults = WorkerFieldDefinition.FieldDefinition.filter(col => (col.name != "company.name" || user.isRoot()) && (col.name != "isDeleted" || user.isAdmin()))
        const fields = columns.toArray();
        const col = this.customFields.toColumnDef(defaults, {
          rowSelector: "id_num",
          format: (col, field) => {
            switch (field.name) {
              case "permission":
                return { ...col, editable: (params) => params.data.$row_status === "new" }
              default:
                return col;
            }
          }
        });
        const custom = this.customFields.toColumnDef(fields, {
          format: this.customFields.formatCellCustomField.bind(this)
        });
        return col.concat(custom);
      })
    )
  }

  load() {
    const time = moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss');
    return this.backend.list(this.lastFetchedTime.getValue()).pipe(
      map(events => events.map(this.remapUser)),
      tap((users) => {
        this.lastFetchedTime.next(time);
        if(!this.lastFetchedTime.getValue()){
          this.usersSubject.next(List(users));
        } else {
          const prev = this.usersSubject.getValue().toArray();
          const data = uniqBy([...users, ...prev], '_id');
          this.usersSubject.next(List(data))
        }
      }),
      map(users => users)
    )
  }

  search(search: object) {
    return this.backend.search(search).pipe(
      map(users => users.map(this.remapUser)),
      map(users => users)
    )
  }

  getList() {
    return this.usersSubject.getValue();
  }

  getUsers() {
    return this.usersSubject.getValue().filter((u) => !u.isWorker);
  }

  getWorkers() {
    return this.usersSubject.getValue().filter((u) => u.isWorker);
  }

  save(user: User) {
    (user._id ? this.update(user) : this.create(user))
      .subscribe((u) => {
        if (u) {
          this.toasterService.pop('success', '', 'User saved successfully');
        }
      }, err => {
        this.toasterService.pop('error', '', err.error.message);
      });
  }

  public update(user: User): Observable<User> {
    return this.backend.update(this.encrypt(user))
      .pipe(
        map((data) => this.remapUser(data)),
        tap((data: User) => {
          const users = this.usersSubject.getValue();
          const idx = users.findIndex((u: User) => u._id === data._id);
          this.usersSubject.next(users.set(idx, data));
        })
      );
  }

  public create(user: User) {
    return this.backend.create(this.encrypt(user))
      .pipe(
        map((data) => this.remapUser(data)),
        tap(data => {
          this.usersSubject.next(this.usersSubject.getValue().unshift(data));
        })
      );
  }

  syncUser(id: string) {
    return this.backend.get(id)
      .pipe(
        map((user) => this.remapUser(user)),
        tap(user => {
          const users = this.usersSubject.getValue();
          const idx = users.findIndex((u: User) => u._id === id);
          if (idx > -1) {
            this.usersSubject.next(users.set(idx, user));
          } else {
            this.usersSubject.next(users.unshift(user));
          }
        }));
  }

  public delete(user: User) {
    return this.backend.delete(user)
  }

  public sendEmail(type: string) {
    return this.backend.sendEmail(type)
  }

  public sendEmailTable(type: string) {
    return this.backend.sendEmailTable(type)
  }

  public sendClosePm() {
    return this.backend.sendClosePm()
  }

  public sendChangeEmails(users: UserEmail[]) {
    return this.backend.sendChangeEmails(users)
  }

  private encrypt = (user) => {
    return {
      ...user,
      password: user.password ? CryptoJS.AES.encrypt(user.password, environment.encryptionPhase).toString() : undefined,
      email: user.email ? CryptoJS.AES.encrypt(user.email, environment.encryptionPhase).toString() : undefined,
      username: user.username ? CryptoJS.AES.encrypt(user.username, environment.encryptionPhase).toString() : undefined,
      phone: user.phone ? CryptoJS.AES.encrypt(user.phone, environment.encryptionPhase).toString() : undefined,
      url: this.router.url,
    };
  }

  public remapUser(user: User) {
    user.departmentObject = user.department || [];
    user.department = user.departmentObject.length > 0 ? user.departmentObject.map(d => d._id) : null;
    user.siteObject = user.site || [];
    user.site = user.siteObject.length > 0 ? user.siteObject.map(s => s._id) : null;
    user.fullName = `${user.firstName || ""} ${user.lastName || ""}`;
    user.language = user.language ? user.language : "English";
    return user;
  }

  uploadPicture(files) {
    this.backend.uploadPicture(files)
      .subscribe(() => {
        this.load().subscribe()
      });
  }

  uploadPptxTemplate(files) {
    return this.backend.uploadPptxTemplate(files)
  }

  validate(data: { id: string, password: string }) {
    return this.backend.validate(this.encrypt(data));
  }

  validateUser(data: { id: string, password: string }) {
    return this.backend.validateUser(this.encrypt(data));
  }

  blockUser = (wp: string) => {
    return this.backend.blockUser(wp);
  }

  sendPush = (message: string) => {
    return this.backend.sendPush(message);
  }
}
