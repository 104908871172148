import { IField } from "../../interfaces/field";

const FieldDefinition: IField[] = [
    {
        name: 'id_num',
        label: 'table.transaction.table-title.TRANSACTION-ID',
        type: 'textbox',
        required: false,
        readonly: true
    },
    {
        name: 'company.name',
        label: 'table.transaction.table-title.COMPANY',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'part.id_num',
        label: 'table.transaction.table-title.PART-ID',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'part.catalogNumber',
        label: 'table.transaction.table-title.CATALOG-NUMBER',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'part.name',
        label: 'table.transaction.table-title.PART-NAME',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'stockQuantity',
        label: 'table.transaction.table-title.STOCK-QUANTITY',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'changeStockQuantity',
        label: 'table.transaction.table-title.CHANGE-STOCK-QUANTITY',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'oldStockQuantity',
        label: 'table.transaction.table-title.OLD-STOCK-QUANTITY',
        type: 'textbox',
        required: false,
        readonly: true,
        valueGetter: params => (params.data) ? (params.data.stockQuantity || 0) - (params.data.changeStockQuantity || 0) : 0
    },
    {
        name: 'part.minimalQuantity',
        label: 'table.transaction.table-title.MINIMAL-QUANTITY',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'part.units',
        label: 'table.transaction.table-title.UNITS',
        type: 'textbox',
        required: false,
        readonly: true,
        chartDataType: "series",
    },
    {
        name: 'moveKind',
        label: 'table.transaction.table-title.MOVE-KIND',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'part.warehouse.name',
        label: 'table.inventory.table-title.WAREHOUSE',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'part.shelf',
        label: 'table.transaction.table-title.SHELF',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'part.fatherId',
        label: 'table.transaction.table-title.FATHER-ID',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'part.fatherName',
        label: 'table.transaction.table-title.FATHER-NAME',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'updatedAt',
        label: 'table.transaction.table-title.DATE-UPDATED',
        type: 'datepicker',
        required: false,
        readonly: true
    },
    {
        name: 'updatedBy.username',
        label: 'table.transaction.table-title.UPDATED-BY',
        type: 'textbox',
        required: false,
        readonly: true,
        valueGetter: params => !params.data.updatedBy ? "" : `${params.data.updatedBy.firstName} ${params.data.updatedBy.lastName} (${params.data.updatedBy.username})`
    },
    {
        name: 'tool.id_num',
        label: 'table.transaction.table-title.TOOL-ID',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'tool.name',
        label: 'table.transaction.table-title.TOOL-NAME',
        type: 'textbox',
        required: false,
        readonly: true,
    },
];

export default
    {
        FieldDefinition
    }